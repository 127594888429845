/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AuthContext
// ====================================================

export interface AuthContext_currentUserPrisma_currentOrganization_customer_atsIntegration {
  __typename: "AtsIntegration";
  id: string;
  atsType: string | null;
  displayName: string | null;
}

export interface AuthContext_currentUserPrisma_currentOrganization_customer_plan {
  __typename: "CustomerPlan";
  id: string;
  name: string;
}

export interface AuthContext_currentUserPrisma_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  trialStart: GraphQL_DateTime | null;
  trialDuration: number | null;
  isGreenhouseIntegrated: boolean;
  atsIntegration: AuthContext_currentUserPrisma_currentOrganization_customer_atsIntegration | null;
  plan: AuthContext_currentUserPrisma_currentOrganization_customer_plan;
}

export interface AuthContext_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  name: string;
  prospectOnboardingComplete: boolean;
  zeusStatus: ZeusStatusEnum;
  customer: AuthContext_currentUserPrisma_currentOrganization_customer;
}

export interface AuthContext_currentUserPrisma_currentUserMembership_highestRole {
  __typename: "Role";
  id: string;
}

export interface AuthContext_currentUserPrisma_currentUserMembership {
  __typename: "UserMembership";
  id: string;
  onboardingComplete: boolean;
  hasLimitedAccess: boolean;
  personalProfileId: string;
  highestRole: AuthContext_currentUserPrisma_currentUserMembership_highestRole | null;
}

export interface AuthContext_currentUserPrisma {
  __typename: "User";
  id: string;
  isStaff: boolean;
  isSuperuser: boolean;
  pitchPageOnboardingComplete: boolean;
  fullName: string;
  firstName: string;
  lastName: string;
  primaryEmail: string | null;
  imageUrl: string;
  createdAt: GraphQL_DateTime;
  currentOrganization: AuthContext_currentUserPrisma_currentOrganization | null;
  currentUserMembership: AuthContext_currentUserPrisma_currentUserMembership | null;
}

export interface AuthContext {
  currentUserPrisma: AuthContext_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConnectAtsAccount
// ====================================================

export interface ConnectAtsAccount_upsertAtssyncAccount_atssyncAccount {
  __typename: "AtssyncAccount";
  id: string;
  type: AtssyncAccountType;
  apiKeySuffix: string;
}

export interface ConnectAtsAccount_upsertAtssyncAccount {
  __typename: "UpsertAtssyncAccountResponse";
  success: boolean;
  code: string;
  message: string;
  atssyncAccount: ConnectAtsAccount_upsertAtssyncAccount_atssyncAccount | null;
}

export interface ConnectAtsAccount {
  upsertAtssyncAccount: ConnectAtsAccount_upsertAtssyncAccount;
}

export interface ConnectAtsAccountVariables {
  input: UpsertAtssyncAccountInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DisconnectAtsAccount
// ====================================================

export interface DisconnectAtsAccount_disconnectAtssyncAccount_atssyncAccount {
  __typename: "AtssyncAccount";
  id: string;
}

export interface DisconnectAtsAccount_disconnectAtssyncAccount {
  __typename: "DisconnectAtssyncAccountResponse";
  success: boolean;
  code: string;
  message: string;
  atssyncAccount: DisconnectAtsAccount_disconnectAtssyncAccount_atssyncAccount | null;
}

export interface DisconnectAtsAccount {
  disconnectAtssyncAccount: DisconnectAtsAccount_disconnectAtssyncAccount;
}

export interface DisconnectAtsAccountVariables {
  input: DisconnectAtssyncAccountInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: V2IntegrationsPage
// ====================================================

export interface V2IntegrationsPage_currentOrganization_customer_atssyncAccounts {
  __typename: "AtssyncAccount";
  id: string;
  type: AtssyncAccountType;
  apiKeySuffix: string;
}

export interface V2IntegrationsPage_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  atssyncAccounts: V2IntegrationsPage_currentOrganization_customer_atssyncAccounts[];
}

export interface V2IntegrationsPage_currentOrganization {
  __typename: "Organization";
  id: string;
  customer: V2IntegrationsPage_currentOrganization_customer;
}

export interface V2IntegrationsPage {
  currentOrganization: V2IntegrationsPage_currentOrganization | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CandidatePostMessageThread
// ====================================================

export interface CandidatePostMessageThread_guidePostById {
  __typename: "GuidePost";
  id: string;
  title: string;
  data: GraphQL_JSON | null;
}

export interface CandidatePostMessageThread {
  guidePostById: CandidatePostMessageThread_guidePostById | null;
}

export interface CandidatePostMessageThreadVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PostGuideReply
// ====================================================

export interface PostGuideReply_createGuidePost_guidePost_parent_replies {
  __typename: "GuidePost";
  id: string;
}

export interface PostGuideReply_createGuidePost_guidePost_parent {
  __typename: "GuidePost";
  id: string;
  replies: PostGuideReply_createGuidePost_guidePost_parent_replies[];
}

export interface PostGuideReply_createGuidePost_guidePost_guide_latestPost {
  __typename: "GuidePost";
  id: string;
}

export interface PostGuideReply_createGuidePost_guidePost_guide {
  __typename: "Guide";
  id: string;
  latestPost: PostGuideReply_createGuidePost_guidePost_guide_latestPost | null;
}

export interface PostGuideReply_createGuidePost_guidePost {
  __typename: "GuidePost";
  id: string;
  title: string;
  data: GraphQL_JSON | null;
  parent: PostGuideReply_createGuidePost_guidePost_parent | null;
  guide: PostGuideReply_createGuidePost_guidePost_guide;
}

export interface PostGuideReply_createGuidePost {
  __typename: "CreateGuidePostResponse";
  success: boolean;
  code: string;
  message: string;
  guidePost: PostGuideReply_createGuidePost_guidePost | null;
}

export interface PostGuideReply {
  createGuidePost: PostGuideReply_createGuidePost;
}

export interface PostGuideReplyVariables {
  input: CreateGuidePostInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuidePostToDelete
// ====================================================

export interface GuidePostToDelete_guidePostById_parent {
  __typename: "GuidePost";
  id: string;
}

export interface GuidePostToDelete_guidePostById {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  parent: GuidePostToDelete_guidePostById_parent | null;
}

export interface GuidePostToDelete {
  guidePostById: GuidePostToDelete_guidePostById | null;
}

export interface GuidePostToDeleteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteGuidePostFromModal
// ====================================================

export interface DeleteGuidePostFromModal_deleteGuidePost {
  __typename: "DeleteGuidePostResponse";
  success: boolean;
  code: string;
  message: string;
}

export interface DeleteGuidePostFromModal {
  deleteGuidePost: DeleteGuidePostFromModal_deleteGuidePost;
}

export interface DeleteGuidePostFromModalVariables {
  input: DeleteGuidePostInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuidePostToEdit
// ====================================================

export interface GuidePostToEdit_guidePostById_guide_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
  companyLogoUrl: string;
}

export interface GuidePostToEdit_guidePostById_guide_candidate {
  __typename: "Candidate";
  id: string;
  organization: GuidePostToEdit_guidePostById_guide_candidate_organization;
  name: string;
  imageUrl: string;
}

export interface GuidePostToEdit_guidePostById_guide_stages_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface GuidePostToEdit_guidePostById_guide_stages_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
}

export interface GuidePostToEdit_guidePostById_guide_stages {
  __typename: "Stage";
  id: string;
  configured: boolean;
  title: string;
  status: StageStatus;
  isAtsCurrent: boolean;
  hidden: boolean;
  hasAutomation: boolean;
  position: number;
  atsStage: GuidePostToEdit_guidePostById_guide_stages_atsStage | null;
  guide: GuidePostToEdit_guidePostById_guide_stages_guide;
}

export interface GuidePostToEdit_guidePostById_guide_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  hasAutomation: boolean;
  configured: boolean;
  title: string;
  status: StageStatus;
  position: number;
}

export interface GuidePostToEdit_guidePostById_guide_guideLexicalContext_recruiterName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuidePostToEdit_guidePostById_guide_guideLexicalContext_candidateName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuidePostToEdit_guidePostById_guide_guideLexicalContext_coordinatorName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuidePostToEdit_guidePostById_guide_guideLexicalContext {
  __typename: "GuideLexicalContext";
  id: GraphQL_UUID;
  href: string;
  recruiterName: GuidePostToEdit_guidePostById_guide_guideLexicalContext_recruiterName;
  candidateName: GuidePostToEdit_guidePostById_guide_guideLexicalContext_candidateName;
  candidatePhone: string | null;
  coordinatorName: GuidePostToEdit_guidePostById_guide_guideLexicalContext_coordinatorName | null;
  jobTitle: string;
  jobListingUrl: string | null;
  companyName: string;
}

export interface GuidePostToEdit_guidePostById_guide {
  __typename: "Guide";
  id: string;
  candidate: GuidePostToEdit_guidePostById_guide_candidate;
  guideVersion: number;
  stages: GuidePostToEdit_guidePostById_guide_stages[];
  status: GuideStatusEnum;
  sortedVisibleStages: GuidePostToEdit_guidePostById_guide_sortedVisibleStages[];
  guideLexicalContext: GuidePostToEdit_guidePostById_guide_guideLexicalContext;
}

export interface GuidePostToEdit_guidePostById_senderUser {
  __typename: "GuidePostUser";
  id: string;
  firstName: string;
  lastName: string;
}

export interface GuidePostToEdit_guidePostById {
  __typename: "GuidePost";
  id: string;
  data: GraphQL_JSON | null;
  threadId: string | null;
  guide: GuidePostToEdit_guidePostById_guide;
  senderUser: GuidePostToEdit_guidePostById_senderUser;
}

export interface GuidePostToEdit {
  guidePostById: GuidePostToEdit_guidePostById | null;
}

export interface GuidePostToEditVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGuidePostContent
// ====================================================

export interface UpdateGuidePostContent_updateGuidePostContent_guidePost_availabilityRequests_calendarEvents_createdBy {
  __typename: "UserMembership";
  id: string;
}

export interface UpdateGuidePostContent_updateGuidePostContent_guidePost_availabilityRequests_calendarEvents {
  __typename: "CalendarEvent";
  id: string;
  title: string;
  start: GraphQL_DateTime;
  end: GraphQL_DateTime;
  allDay: boolean;
  createdBy: UpdateGuidePostContent_updateGuidePostContent_guidePost_availabilityRequests_calendarEvents_createdBy | null;
}

export interface UpdateGuidePostContent_updateGuidePostContent_guidePost_availabilityRequests_candidateAvailabilities {
  __typename: "CandidateAvailability";
  id: string;
}

export interface UpdateGuidePostContent_updateGuidePostContent_guidePost_availabilityRequests {
  __typename: "AvailabilityRequest";
  id: string;
  title: string;
  description: string | null;
  createMultipleEventsOnDrag: boolean;
  forceResponsesInIncrements: boolean;
  overlayCalendars: boolean;
  calendars: string[];
  allowSelectionOutsideSuggestions: boolean;
  calendarEvents: UpdateGuidePostContent_updateGuidePostContent_guidePost_availabilityRequests_calendarEvents[];
  candidateAvailabilities: UpdateGuidePostContent_updateGuidePostContent_guidePost_availabilityRequests_candidateAvailabilities[];
}

export interface UpdateGuidePostContent_updateGuidePostContent_guidePost {
  __typename: "GuidePost";
  id: string;
  title: string;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  availabilityRequests: UpdateGuidePostContent_updateGuidePostContent_guidePost_availabilityRequests[];
}

export interface UpdateGuidePostContent_updateGuidePostContent {
  __typename: "UpdateGuidePostContentResponse";
  success: boolean;
  code: string;
  message: string;
  guidePost: UpdateGuidePostContent_updateGuidePostContent_guidePost | null;
}

export interface UpdateGuidePostContent {
  updateGuidePostContent: UpdateGuidePostContent_updateGuidePostContent;
}

export interface UpdateGuidePostContentVariables {
  input: UpdateGuidePostContentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MarkPostAsRead
// ====================================================

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_senderUser {
  __typename: "GuidePostUser";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  email: string;
  isCandidate: boolean;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
  firstName: string;
  lastName: string;
  imageUrl: string;
  email: string;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_replies_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_replies_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_replies_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: MarkPostAsRead_markGuidePostAsRead_guidePost_replies_replies_senderUser;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: MarkPostAsRead_markGuidePostAsRead_guidePost_replies_senderUser;
  title: string;
  data: GraphQL_JSON | null;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  threadId: string | null;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: MarkPostAsRead_markGuidePostAsRead_guidePost_replies_lastCandidateEmailEvent | null;
  replies: MarkPostAsRead_markGuidePostAsRead_guidePost_replies_replies[];
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_recipientProfiles {
  __typename: "GuidePostUser";
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  isCandidate: boolean;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_stage {
  __typename: "Stage";
  id: string;
  title: string;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_previousStage {
  __typename: "Stage";
  id: string;
  title: string;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_guide_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_guide {
  __typename: "Guide";
  id: string;
  candidate: MarkPostAsRead_markGuidePostAsRead_guidePost_guide_candidate;
  unreadPostCount: number;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_parent_senderUser {
  __typename: "GuidePostUser";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  email: string;
  isCandidate: boolean;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_parent_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_parent_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
  firstName: string;
  lastName: string;
  imageUrl: string;
  email: string;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_parent_replies_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_parent_replies_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_parent_replies_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: MarkPostAsRead_markGuidePostAsRead_guidePost_parent_replies_replies_senderUser;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_parent_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: MarkPostAsRead_markGuidePostAsRead_guidePost_parent_replies_senderUser;
  title: string;
  data: GraphQL_JSON | null;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  threadId: string | null;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: MarkPostAsRead_markGuidePostAsRead_guidePost_parent_replies_lastCandidateEmailEvent | null;
  replies: MarkPostAsRead_markGuidePostAsRead_guidePost_parent_replies_replies[];
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_parent_recipientProfiles {
  __typename: "GuidePostUser";
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  isCandidate: boolean;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_parent_stage {
  __typename: "Stage";
  id: string;
  title: string;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_parent_previousStage {
  __typename: "Stage";
  id: string;
  title: string;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_parent_guide_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_parent_guide {
  __typename: "Guide";
  id: string;
  candidate: MarkPostAsRead_markGuidePostAsRead_guidePost_parent_guide_candidate;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost_parent {
  __typename: "GuidePost";
  id: string;
  title: string;
  data: GraphQL_JSON | null;
  readAt: GraphQL_DateTime | null;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  threadId: string | null;
  senderUser: MarkPostAsRead_markGuidePostAsRead_guidePost_parent_senderUser;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: MarkPostAsRead_markGuidePostAsRead_guidePost_parent_lastCandidateEmailEvent | null;
  replies: MarkPostAsRead_markGuidePostAsRead_guidePost_parent_replies[];
  recipientProfiles: MarkPostAsRead_markGuidePostAsRead_guidePost_parent_recipientProfiles[];
  stage: MarkPostAsRead_markGuidePostAsRead_guidePost_parent_stage | null;
  previousStage: MarkPostAsRead_markGuidePostAsRead_guidePost_parent_previousStage | null;
  guide: MarkPostAsRead_markGuidePostAsRead_guidePost_parent_guide;
}

export interface MarkPostAsRead_markGuidePostAsRead_guidePost {
  __typename: "GuidePost";
  id: string;
  title: string;
  data: GraphQL_JSON | null;
  readAt: GraphQL_DateTime | null;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  threadId: string | null;
  senderUser: MarkPostAsRead_markGuidePostAsRead_guidePost_senderUser;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: MarkPostAsRead_markGuidePostAsRead_guidePost_lastCandidateEmailEvent | null;
  replies: MarkPostAsRead_markGuidePostAsRead_guidePost_replies[];
  recipientProfiles: MarkPostAsRead_markGuidePostAsRead_guidePost_recipientProfiles[];
  stage: MarkPostAsRead_markGuidePostAsRead_guidePost_stage | null;
  previousStage: MarkPostAsRead_markGuidePostAsRead_guidePost_previousStage | null;
  guide: MarkPostAsRead_markGuidePostAsRead_guidePost_guide;
  parent: MarkPostAsRead_markGuidePostAsRead_guidePost_parent | null;
}

export interface MarkPostAsRead_markGuidePostAsRead {
  __typename: "MarkGuidePostAsReadResponse";
  success: boolean;
  guidePost: MarkPostAsRead_markGuidePostAsRead_guidePost | null;
}

export interface MarkPostAsRead {
  markGuidePostAsRead: MarkPostAsRead_markGuidePostAsRead;
}

export interface MarkPostAsReadVariables {
  input: MarkGuidePostAsReadInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideHeadMetadata
// ====================================================

export interface GuideHeadMetadata_guideFindUniqueByShortId_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
  companyLogoUrl: string;
}

export interface GuideHeadMetadata_guideFindUniqueByShortId_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  organization: GuideHeadMetadata_guideFindUniqueByShortId_candidate_organization;
}

export interface GuideHeadMetadata_guideFindUniqueByShortId {
  __typename: "Guide";
  id: string;
  roleName: string;
  candidate: GuideHeadMetadata_guideFindUniqueByShortId_candidate;
}

export interface GuideHeadMetadata {
  guideFindUniqueByShortId: GuideHeadMetadata_guideFindUniqueByShortId | null;
}

export interface GuideHeadMetadataVariables {
  customerSlug: string;
  guideShortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideHeadPostTitle
// ====================================================

export interface GuideHeadPostTitle_guidePostById {
  __typename: "GuidePost";
  id: string;
  title: string;
}

export interface GuideHeadPostTitle {
  guidePostById: GuideHeadPostTitle_guidePostById | null;
}

export interface GuideHeadPostTitleVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideForAuth
// ====================================================

export interface GuideForAuth_guideFindUniqueByShortId_candidate_organization {
  __typename: "Organization";
  id: string;
}

export interface GuideForAuth_guideFindUniqueByShortId_candidate {
  __typename: "Candidate";
  id: string;
  organization: GuideForAuth_guideFindUniqueByShortId_candidate_organization;
}

export interface GuideForAuth_guideFindUniqueByShortId {
  __typename: "Guide";
  id: string;
  candidate: GuideForAuth_guideFindUniqueByShortId_candidate;
  candidateUserMembershipId: string | null;
  currentUserPermissions: string[];
}

export interface GuideForAuth {
  guideFindUniqueByShortId: GuideForAuth_guideFindUniqueByShortId | null;
}

export interface GuideForAuthVariables {
  customerSlug: string;
  guideShortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideV2ForShortIdPrisma
// ====================================================

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_candidate_organization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_candidate_organization {
  __typename: "Organization";
  guideDefaultBannerImageUrl: string;
  theme: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_candidate_organization_theme | null;
  id: string;
  name: string;
  defaultAvatarImageUrl: string;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
  organization: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_candidate_organization;
  firstName: string;
  lastName: string;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_currentStage {
  __typename: "Stage";
  id: string;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  hidden: boolean;
  title: string;
  description: string | null;
  needsFeedback: boolean;
  hasAutomation: boolean;
  configured: boolean;
  status: StageStatus;
  position: number;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_stages {
  __typename: "Stage";
  id: string;
  title: string;
  description: string | null;
  needsFeedback: boolean;
  hasAutomation: boolean;
  configured: boolean;
  status: StageStatus;
  position: number;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_hiringTeam_interviewer {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_hiringTeam {
  __typename: "HiringTeamMember";
  id: GraphQL_UUID;
  role: HiringTeamRoleEnum;
  isPointOfContact: boolean;
  hidden: boolean;
  interviewer: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_hiringTeam_interviewer;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_jobRoleGuideTemplate_pointOfContact {
  __typename: "Interviewer";
  id: string;
  email: string | null;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  pointOfContact: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_jobRoleGuideTemplate_pointOfContact | null;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_defaultContent {
  __typename: "GuideContent";
  id: string;
  data: GraphQL_JSON;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_guideLexicalContext_recruiterName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_guideLexicalContext_candidateName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_guideLexicalContext_coordinatorName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_guideLexicalContext {
  __typename: "GuideLexicalContext";
  id: GraphQL_UUID;
  href: string;
  recruiterName: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_guideLexicalContext_recruiterName;
  candidateName: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_guideLexicalContext_candidateName;
  candidatePhone: string | null;
  coordinatorName: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_guideLexicalContext_coordinatorName | null;
  jobTitle: string;
  jobListingUrl: string | null;
  companyName: string;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_upcomingInterviewsV2_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_upcomingInterviewsV2_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_upcomingInterviewsV2_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_upcomingInterviewsV2 {
  __typename: "Event";
  id: string;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_upcomingInterviewsV2_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_upcomingInterviewsV2_interviewers[];
  conferencePhone: string | null;
  location: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_upcomingInterviewsV2_location | null;
  collaborativeCodingUrl: string | null;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_senderUser {
  __typename: "GuidePostUser";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  email: string;
  isCandidate: boolean;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
  firstName: string;
  lastName: string;
  imageUrl: string;
  email: string;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_replies_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_replies_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_replies_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_replies_replies_senderUser;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_replies_senderUser;
  title: string;
  data: GraphQL_JSON | null;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  threadId: string | null;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_replies_lastCandidateEmailEvent | null;
  replies: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_replies_replies[];
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_recipientProfiles {
  __typename: "GuidePostUser";
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  isCandidate: boolean;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_stage {
  __typename: "Stage";
  id: string;
  title: string;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_previousStage {
  __typename: "Stage";
  id: string;
  title: string;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_guide_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_guide {
  __typename: "Guide";
  id: string;
  candidate: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_guide_candidate;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost {
  __typename: "GuidePost";
  id: string;
  title: string;
  data: GraphQL_JSON | null;
  readAt: GraphQL_DateTime | null;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  threadId: string | null;
  senderUser: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_senderUser;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_lastCandidateEmailEvent | null;
  replies: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_replies[];
  recipientProfiles: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_recipientProfiles[];
  stage: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_stage | null;
  previousStage: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_previousStage | null;
  guide: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost_guide;
}

export interface GuideV2ForShortIdPrisma_guideFindUniqueByShortId {
  __typename: "Guide";
  id: string;
  jobRoleGuideTemplateId: string | null;
  guideVersion: number;
  statusV2: GuideStatusV2Enum;
  hiringDecisionAt: GraphQL_DateTime | null;
  candidate: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_candidate;
  currentStage: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_currentStage | null;
  sortedVisibleStages: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_sortedVisibleStages[];
  stages: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_stages[];
  roleName: string;
  bannerImageUrl: string | null;
  candidateOpens: string[];
  hiringTeam: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_hiringTeam[];
  jobRoleGuideTemplate: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_jobRoleGuideTemplate | null;
  defaultContent: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_defaultContent | null;
  guideLexicalContext: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_guideLexicalContext;
  upcomingInterviewsV2: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_upcomingInterviewsV2[];
  latestPost: GuideV2ForShortIdPrisma_guideFindUniqueByShortId_latestPost | null;
  postCount: number;
}

export interface GuideV2ForShortIdPrisma {
  guideFindUniqueByShortId: GuideV2ForShortIdPrisma_guideFindUniqueByShortId | null;
}

export interface GuideV2ForShortIdPrismaVariables {
  customerSlug: string;
  guideShortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchV2GuideForCandidateGuide
// ====================================================

export interface FetchV2GuideForCandidateGuide_guideByShortId_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface FetchV2GuideForCandidateGuide_guideByShortId_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
}

export interface FetchV2GuideForCandidateGuide_guideByShortId_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: FetchV2GuideForCandidateGuide_guideByShortId_interviewPlan_items[];
}

export interface FetchV2GuideForCandidateGuide_guideByShortId {
  __typename: "GuideV2";
  id: string;
  currentInterviewPlanItemId: string | null;
  jobRoleGuideTemplateId: string;
  status: GuideStatusV2Enum;
  hiringDecisionAt: GraphQL_DateTime | null;
  candidate: FetchV2GuideForCandidateGuide_guideByShortId_candidate;
  interviewPlan: FetchV2GuideForCandidateGuide_guideByShortId_interviewPlan | null;
}

export interface FetchV2GuideForCandidateGuide {
  guideByShortId: FetchV2GuideForCandidateGuide_guideByShortId | null;
}

export interface FetchV2GuideForCandidateGuideVariables {
  customerSlug: string;
  guideShortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideUpdatesForShortIdPrisma
// ====================================================

export interface GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId_candidate {
  __typename: "Candidate";
  name: string;
  id: string;
}

export interface GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId_jobRoleGuideTemplate_pointOfContact {
  __typename: "Interviewer";
  id: string;
  email: string | null;
}

export interface GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  pointOfContact: GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId_jobRoleGuideTemplate_pointOfContact | null;
}

export interface GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId_posts_stage {
  __typename: "Stage";
  id: string;
  title: string;
}

export interface GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId_posts_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId_posts_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId_posts_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId_posts_replies_senderUser;
}

export interface GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId_posts_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
  imageUrl: string;
  firstName: string;
  lastName: string;
}

export interface GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId_posts {
  __typename: "GuidePost";
  id: string;
  title: string;
  createdAt: GraphQL_DateTime;
  stage: GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId_posts_stage | null;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId_posts_lastCandidateEmailEvent | null;
  replies: GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId_posts_replies[];
  readAt: GraphQL_DateTime | null;
  senderUser: GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId_posts_senderUser;
}

export interface GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId {
  __typename: "Guide";
  id: string;
  roleName: string;
  candidate: GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId_candidate;
  jobRoleGuideTemplate: GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId_jobRoleGuideTemplate | null;
  posts: GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId_posts[];
}

export interface GuideUpdatesForShortIdPrisma {
  guideFindUniqueByShortId: GuideUpdatesForShortIdPrisma_guideFindUniqueByShortId | null;
}

export interface GuideUpdatesForShortIdPrismaVariables {
  customerSlug: string;
  guideShortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideUpdateById
// ====================================================

export interface GuideUpdateById_guidePostById_senderUser {
  __typename: "GuidePostUser";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  email: string;
  isCandidate: boolean;
}

export interface GuideUpdateById_guidePostById_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface GuideUpdateById_guidePostById_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
  firstName: string;
  lastName: string;
  imageUrl: string;
  email: string;
}

export interface GuideUpdateById_guidePostById_replies_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface GuideUpdateById_guidePostById_replies_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface GuideUpdateById_guidePostById_replies_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: GuideUpdateById_guidePostById_replies_replies_senderUser;
}

export interface GuideUpdateById_guidePostById_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: GuideUpdateById_guidePostById_replies_senderUser;
  title: string;
  data: GraphQL_JSON | null;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  threadId: string | null;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: GuideUpdateById_guidePostById_replies_lastCandidateEmailEvent | null;
  replies: GuideUpdateById_guidePostById_replies_replies[];
}

export interface GuideUpdateById_guidePostById_recipientProfiles {
  __typename: "GuidePostUser";
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  isCandidate: boolean;
}

export interface GuideUpdateById_guidePostById_stage {
  __typename: "Stage";
  id: string;
  title: string;
}

export interface GuideUpdateById_guidePostById_previousStage {
  __typename: "Stage";
  id: string;
  title: string;
}

export interface GuideUpdateById_guidePostById_guide_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
}

export interface GuideUpdateById_guidePostById_guide_jobRoleGuideTemplate_pointOfContact {
  __typename: "Interviewer";
  id: string;
  email: string | null;
}

export interface GuideUpdateById_guidePostById_guide_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  pointOfContact: GuideUpdateById_guidePostById_guide_jobRoleGuideTemplate_pointOfContact | null;
}

export interface GuideUpdateById_guidePostById_guide {
  __typename: "Guide";
  id: string;
  candidate: GuideUpdateById_guidePostById_guide_candidate;
  jobRoleGuideTemplate: GuideUpdateById_guidePostById_guide_jobRoleGuideTemplate | null;
}

export interface GuideUpdateById_guidePostById {
  __typename: "GuidePost";
  id: string;
  title: string;
  data: GraphQL_JSON | null;
  readAt: GraphQL_DateTime | null;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  threadId: string | null;
  senderUser: GuideUpdateById_guidePostById_senderUser;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: GuideUpdateById_guidePostById_lastCandidateEmailEvent | null;
  replies: GuideUpdateById_guidePostById_replies[];
  recipientProfiles: GuideUpdateById_guidePostById_recipientProfiles[];
  stage: GuideUpdateById_guidePostById_stage | null;
  previousStage: GuideUpdateById_guidePostById_previousStage | null;
  guide: GuideUpdateById_guidePostById_guide;
}

export interface GuideUpdateById {
  guidePostById: GuideUpdateById_guidePostById | null;
}

export interface GuideUpdateByIdVariables {
  updateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideUpdatesForUpdatesPage
// ====================================================

export interface GuideUpdatesForUpdatesPage_guideFindUniqueByShortId_candidate {
  __typename: "Candidate";
  name: string;
}

export interface GuideUpdatesForUpdatesPage_guideFindUniqueByShortId_latestPost {
  __typename: "GuidePost";
  id: string;
  threadId: string | null;
}

export interface GuideUpdatesForUpdatesPage_guideFindUniqueByShortId {
  __typename: "Guide";
  id: string;
  roleName: string;
  candidate: GuideUpdatesForUpdatesPage_guideFindUniqueByShortId_candidate;
  latestPost: GuideUpdatesForUpdatesPage_guideFindUniqueByShortId_latestPost | null;
}

export interface GuideUpdatesForUpdatesPage {
  guideFindUniqueByShortId: GuideUpdatesForUpdatesPage_guideFindUniqueByShortId | null;
}

export interface GuideUpdatesForUpdatesPageVariables {
  customerSlug: string;
  guideShortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideInterviewsHeaderForShortIdPrisma
// ====================================================

export interface GuideInterviewsHeaderForShortIdPrisma_guideFindUniqueByShortId_candidate {
  __typename: "Candidate";
  name: string;
}

export interface GuideInterviewsHeaderForShortIdPrisma_guideFindUniqueByShortId {
  __typename: "Guide";
  id: string;
  roleName: string;
  candidate: GuideInterviewsHeaderForShortIdPrisma_guideFindUniqueByShortId_candidate;
}

export interface GuideInterviewsHeaderForShortIdPrisma {
  guideFindUniqueByShortId: GuideInterviewsHeaderForShortIdPrisma_guideFindUniqueByShortId | null;
}

export interface GuideInterviewsHeaderForShortIdPrismaVariables {
  customerSlug: string;
  guideShortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideUpcomingInterviewsForShortId
// ====================================================

export interface GuideUpcomingInterviewsForShortId_guideFindUniqueByShortId_candidate {
  __typename: "Candidate";
  name: string;
}

export interface GuideUpcomingInterviewsForShortId_guideFindUniqueByShortId_upcomingInterviewsV2_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface GuideUpcomingInterviewsForShortId_guideFindUniqueByShortId_upcomingInterviewsV2_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface GuideUpcomingInterviewsForShortId_guideFindUniqueByShortId_upcomingInterviewsV2_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface GuideUpcomingInterviewsForShortId_guideFindUniqueByShortId_upcomingInterviewsV2 {
  __typename: "Event";
  id: string;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: GuideUpcomingInterviewsForShortId_guideFindUniqueByShortId_upcomingInterviewsV2_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: GuideUpcomingInterviewsForShortId_guideFindUniqueByShortId_upcomingInterviewsV2_interviewers[];
  conferencePhone: string | null;
  location: GuideUpcomingInterviewsForShortId_guideFindUniqueByShortId_upcomingInterviewsV2_location | null;
  collaborativeCodingUrl: string | null;
}

export interface GuideUpcomingInterviewsForShortId_guideFindUniqueByShortId {
  __typename: "Guide";
  id: string;
  roleName: string;
  candidate: GuideUpcomingInterviewsForShortId_guideFindUniqueByShortId_candidate;
  upcomingInterviewsV2: GuideUpcomingInterviewsForShortId_guideFindUniqueByShortId_upcomingInterviewsV2[];
}

export interface GuideUpcomingInterviewsForShortId {
  guideFindUniqueByShortId: GuideUpcomingInterviewsForShortId_guideFindUniqueByShortId | null;
}

export interface GuideUpcomingInterviewsForShortIdVariables {
  customerSlug: string;
  guideShortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuidePastInterviewsForShortId
// ====================================================

export interface GuidePastInterviewsForShortId_guideFindUniqueByShortId_candidate {
  __typename: "Candidate";
  name: string;
}

export interface GuidePastInterviewsForShortId_guideFindUniqueByShortId_pastInterviews_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface GuidePastInterviewsForShortId_guideFindUniqueByShortId_pastInterviews_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface GuidePastInterviewsForShortId_guideFindUniqueByShortId_pastInterviews_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface GuidePastInterviewsForShortId_guideFindUniqueByShortId_pastInterviews {
  __typename: "Event";
  id: string;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: GuidePastInterviewsForShortId_guideFindUniqueByShortId_pastInterviews_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: GuidePastInterviewsForShortId_guideFindUniqueByShortId_pastInterviews_interviewers[];
  conferencePhone: string | null;
  location: GuidePastInterviewsForShortId_guideFindUniqueByShortId_pastInterviews_location | null;
  collaborativeCodingUrl: string | null;
}

export interface GuidePastInterviewsForShortId_guideFindUniqueByShortId {
  __typename: "Guide";
  id: string;
  roleName: string;
  candidate: GuidePastInterviewsForShortId_guideFindUniqueByShortId_candidate;
  pastInterviews: GuidePastInterviewsForShortId_guideFindUniqueByShortId_pastInterviews[];
}

export interface GuidePastInterviewsForShortId {
  guideFindUniqueByShortId: GuidePastInterviewsForShortId_guideFindUniqueByShortId | null;
}

export interface GuidePastInterviewsForShortIdVariables {
  customerSlug: string;
  guideShortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TrackGuideV2Open
// ====================================================

export interface TrackGuideV2Open_trackGuideOpenV2_guide {
  __typename: "Guide";
  id: string;
}

export interface TrackGuideV2Open_trackGuideOpenV2 {
  __typename: "TrackGuideOpenV2Response";
  success: boolean;
  guide: TrackGuideV2Open_trackGuideOpenV2_guide | null;
}

export interface TrackGuideV2Open {
  trackGuideOpenV2: TrackGuideV2Open_trackGuideOpenV2;
}

export interface TrackGuideV2OpenVariables {
  guideId: string;
  timezone?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideV2HeaderForShortId
// ====================================================

export interface GuideV2HeaderForShortId_guideFindUniqueByShortId_hiringDecisionMessage {
  __typename: "HiringDecisionMessage";
  threadId: string | null;
  postId: string | null;
}

export interface GuideV2HeaderForShortId_guideFindUniqueByShortId_candidate_organization_customer_plan {
  __typename: "CustomerPlan";
  id: string;
  name: string;
}

export interface GuideV2HeaderForShortId_guideFindUniqueByShortId_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  trialDuration: number | null;
  trialStart: GraphQL_DateTime | null;
  name: string;
  plan: GuideV2HeaderForShortId_guideFindUniqueByShortId_candidate_organization_customer_plan;
}

export interface GuideV2HeaderForShortId_guideFindUniqueByShortId_candidate_organization {
  __typename: "Organization";
  companyLogoUrl: string;
  id: string;
  name: string;
  createdAt: GraphQL_DateTime;
  zeusStatus: ZeusStatusEnum;
  planPricingModel: PlanPricingModelEnum | null;
  accountStatus: AccountStatusEnum | null;
  employeeCountEnum: CompanyEmployeeSizeEnum | null;
  talentTeamCountEnum: CompanyTalentTeamSizeEnum | null;
  contractStartDate: GraphQL_DateTime | null;
  customer: GuideV2HeaderForShortId_guideFindUniqueByShortId_candidate_organization_customer;
  defaultAvatarImageUrl: string;
}

export interface GuideV2HeaderForShortId_guideFindUniqueByShortId_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  organization: GuideV2HeaderForShortId_guideFindUniqueByShortId_candidate_organization;
}

export interface GuideV2HeaderForShortId_guideFindUniqueByShortId_jobRoleGuideTemplate_pointOfContact {
  __typename: "Interviewer";
  id: string;
  email: string | null;
}

export interface GuideV2HeaderForShortId_guideFindUniqueByShortId_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  pointOfContact: GuideV2HeaderForShortId_guideFindUniqueByShortId_jobRoleGuideTemplate_pointOfContact | null;
}

export interface GuideV2HeaderForShortId_guideFindUniqueByShortId_upcomingInterviewsV2 {
  __typename: "Event";
  id: string;
}

export interface GuideV2HeaderForShortId_guideFindUniqueByShortId {
  __typename: "Guide";
  id: string;
  guideVersion: number;
  statusV2: GuideStatusV2Enum;
  hiringDecisionAt: GraphQL_DateTime | null;
  hiringDecisionMessage: GuideV2HeaderForShortId_guideFindUniqueByShortId_hiringDecisionMessage | null;
  candidate: GuideV2HeaderForShortId_guideFindUniqueByShortId_candidate;
  unreadPostCount: number;
  atsUrl: string;
  jobRoleGuideTemplateId: string | null;
  atsApplicationId: string | null;
  jobRoleGuideTemplate: GuideV2HeaderForShortId_guideFindUniqueByShortId_jobRoleGuideTemplate | null;
  postCount: number;
  upcomingInterviewsV2: GuideV2HeaderForShortId_guideFindUniqueByShortId_upcomingInterviewsV2[];
}

export interface GuideV2HeaderForShortId {
  guideFindUniqueByShortId: GuideV2HeaderForShortId_guideFindUniqueByShortId | null;
}

export interface GuideV2HeaderForShortIdVariables {
  customerSlug: string;
  guideShortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchLatestAvailabilityForExtension
// ====================================================

export interface FetchLatestAvailabilityForExtension_guideLookup_mostRecentSubmittedAvailability_calendarEvents {
  __typename: "CalendarEvent";
  id: string;
  start: GraphQL_DateTime;
  end: GraphQL_DateTime;
}

export interface FetchLatestAvailabilityForExtension_guideLookup_mostRecentSubmittedAvailability_guidePost {
  __typename: "GuidePost";
  id: string;
  href: string;
}

export interface FetchLatestAvailabilityForExtension_guideLookup_mostRecentSubmittedAvailability {
  __typename: "CandidateAvailability";
  id: string;
  updatedAt: GraphQL_DateTime;
  calendarEvents: FetchLatestAvailabilityForExtension_guideLookup_mostRecentSubmittedAvailability_calendarEvents[];
  guidePost: FetchLatestAvailabilityForExtension_guideLookup_mostRecentSubmittedAvailability_guidePost | null;
}

export interface FetchLatestAvailabilityForExtension_guideLookup {
  __typename: "Guide";
  id: string;
  mostRecentSubmittedAvailability: FetchLatestAvailabilityForExtension_guideLookup_mostRecentSubmittedAvailability | null;
}

export interface FetchLatestAvailabilityForExtension {
  guideLookup: FetchLatestAvailabilityForExtension_guideLookup | null;
}

export interface FetchLatestAvailabilityForExtensionVariables {
  where: GuideLookupWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchEventOverviewForExtension
// ====================================================

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
}

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_interviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_interviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  displayImageUrl: string;
}

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_interviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
  personalProfile: FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_interviewParticipants_EventInterviewer_personalProfile;
}

export type FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_interviewParticipants = FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_interviewParticipants_Candidate | FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_interviewParticipants_EventInterviewer;

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_location {
  __typename: "Location";
  id: string;
}

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_stage_guide_candidate_organization_locations {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_stage_guide_candidate_organization {
  __typename: "Organization";
  id: string;
  locations: FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_stage_guide_candidate_organization_locations[];
}

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_stage_guide_candidate {
  __typename: "Candidate";
  id: string;
  organization: FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_stage_guide_candidate_organization;
}

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_stage_guide {
  __typename: "Guide";
  id: string;
  candidate: FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_stage_guide_candidate;
}

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_stage {
  __typename: "Stage";
  id: string;
  guide: FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_stage_guide;
}

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  label: string;
}

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event {
  __typename: "Event";
  id: string;
  hidden: boolean;
  interviewParticipants: FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_interviewParticipants[];
  rawTitle: string;
  replacementData: GraphQL_JSON;
  displayTitle: string;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  displayDescription: string;
  location: FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_location | null;
  stage: FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_stage;
  conferencePhone: string | null;
  prominentInstructions: FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event_prominentInstructions[];
}

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_defaultInterviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_defaultInterviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  displayImageUrl: string;
}

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_defaultInterviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
  personalProfile: FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_defaultInterviewParticipants_EventInterviewer_personalProfile;
}

export type FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_defaultInterviewParticipants = FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_defaultInterviewParticipants_Candidate | FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_defaultInterviewParticipants_EventInterviewer;

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_schedulerConfig_availability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
  slug: string;
}

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_schedulerConfig_interviewers {
  __typename: "Interviewer";
  id: string;
  name: string;
}

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_schedulerConfig {
  __typename: "EventTemplateInstallationSchedulerConfig";
  availability: FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_schedulerConfig_availability;
  interviewers: FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_schedulerConfig_interviewers[];
}

export interface FetchEventOverviewForExtension_eventTemplateInstallationFindUnique {
  __typename: "EventTemplateInstallation";
  id: string;
  eventTemplate: FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_eventTemplate;
  event: FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_event | null;
  defaultInterviewParticipants: FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_defaultInterviewParticipants[];
  hidden: boolean;
  schedulerConfig: FetchEventOverviewForExtension_eventTemplateInstallationFindUnique_schedulerConfig | null;
  isSchedulerManagementAllowed: boolean;
  description: string;
  title: string;
  replacementData: GraphQL_JSON;
  templateTitle: string;
  templateDescription: string;
  isOverridden: boolean;
}

export interface FetchEventOverviewForExtension {
  eventTemplateInstallationFindUnique: FetchEventOverviewForExtension_eventTemplateInstallationFindUnique;
}

export interface FetchEventOverviewForExtensionVariables {
  eventTemplateInstallationId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestProfileUpdateForExtension
// ====================================================

export interface RequestProfileUpdateForExtension_requestProfileUpdate_personalProfile_userMembership {
  __typename: "UserMembership";
  id: string;
}

export interface RequestProfileUpdateForExtension_requestProfileUpdate_personalProfile_invitation {
  __typename: "UserInvitation";
  id: string;
}

export interface RequestProfileUpdateForExtension_requestProfileUpdate_personalProfile {
  __typename: "Interviewer";
  id: string;
  lastRequestedUserUpdateAt: GraphQL_DateTime | null;
  lastUserUpdatedAt: GraphQL_DateTime | null;
  email: string | null;
  userMembership: RequestProfileUpdateForExtension_requestProfileUpdate_personalProfile_userMembership | null;
  invitation: RequestProfileUpdateForExtension_requestProfileUpdate_personalProfile_invitation | null;
}

export interface RequestProfileUpdateForExtension_requestProfileUpdate {
  __typename: "RequestProfileUpdateMutationResponse";
  success: boolean;
  message: string;
  code: string;
  personalProfile: RequestProfileUpdateForExtension_requestProfileUpdate_personalProfile | null;
}

export interface RequestProfileUpdateForExtension {
  requestProfileUpdate: RequestProfileUpdateForExtension_requestProfileUpdate;
}

export interface RequestProfileUpdateForExtensionVariables {
  input: RequestProfileUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InviteUserForExtension
// ====================================================

export interface InviteUserForExtension_sendInvitations_invitations {
  __typename: "UserInvitation";
  id: string;
}

export interface InviteUserForExtension_sendInvitations {
  __typename: "SendUserInvitationsMutationResponse";
  message: string;
  success: boolean;
  code: string;
  invitations: InviteUserForExtension_sendInvitations_invitations[] | null;
}

export interface InviteUserForExtension {
  sendInvitations: InviteUserForExtension_sendInvitations;
}

export interface InviteUserForExtensionVariables {
  input: SendUserInvitationsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchEventInterviewerOverviewForExtension
// ====================================================

export interface FetchEventInterviewerOverviewForExtension_eventInterviewerFindUnique_personalProfile_userMembership {
  __typename: "UserMembership";
  id: string;
}

export interface FetchEventInterviewerOverviewForExtension_eventInterviewerFindUnique_personalProfile_invitation {
  __typename: "UserInvitation";
  id: string;
}

export interface FetchEventInterviewerOverviewForExtension_eventInterviewerFindUnique_personalProfile {
  __typename: "Interviewer";
  id: string;
  lastRequestedUserUpdateAt: GraphQL_DateTime | null;
  lastUserUpdatedAt: GraphQL_DateTime | null;
  userMembership: FetchEventInterviewerOverviewForExtension_eventInterviewerFindUnique_personalProfile_userMembership | null;
  websiteUrls: string[];
  biography: string;
  title: string;
  pronoun: string | null;
  imageUrl: string;
  name: string;
  videoIntroductionUrl: string | null;
  email: string | null;
  invitation: FetchEventInterviewerOverviewForExtension_eventInterviewerFindUnique_personalProfile_invitation | null;
}

export interface FetchEventInterviewerOverviewForExtension_eventInterviewerFindUnique {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
  personalProfile: FetchEventInterviewerOverviewForExtension_eventInterviewerFindUnique_personalProfile;
}

export interface FetchEventInterviewerOverviewForExtension {
  eventInterviewerFindUnique: FetchEventInterviewerOverviewForExtension_eventInterviewerFindUnique;
}

export interface FetchEventInterviewerOverviewForExtensionVariables {
  eventInterviewerId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MoveGuideToStageWithoutMessage
// ====================================================

export interface MoveGuideToStageWithoutMessage_moveGuideToStage_guide {
  __typename: "GuideV2";
  id: string;
  currentInterviewPlanItemId: string | null;
}

export interface MoveGuideToStageWithoutMessage_moveGuideToStage {
  __typename: "MoveGuideToStageResponse";
  message: string;
  success: boolean;
  code: string;
  guide: MoveGuideToStageWithoutMessage_moveGuideToStage_guide;
}

export interface MoveGuideToStageWithoutMessage {
  moveGuideToStage: MoveGuideToStageWithoutMessage_moveGuideToStage;
}

export interface MoveGuideToStageWithoutMessageVariables {
  input: MoveGuideToStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StagesForGuideInterviewPlanModal
// ====================================================

export interface StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage_postTemplates_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage_postTemplates_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage_postTemplates_updatedBy_user;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage_postTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage_postTemplates_createdBy_personalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
  imageUrl: string;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage_postTemplates_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage_postTemplates_createdBy_personalProfile;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage_postTemplates_updatedBy;
  department: StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage_postTemplates_department | null;
  postTemplateSyncData: StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData | null;
  createdBy: StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage_postTemplates_createdBy;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  postTemplates: StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage_postTemplates[];
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_stages {
  __typename: "Stage";
  id: string;
  title: string;
  description: string | null;
  configured: boolean;
  status: StageStatus;
  position: number;
  hidden: boolean;
  jobRoleGuideTemplateStage: StagesForGuideInterviewPlanModal_guideFindUnique_stages_jobRoleGuideTemplateStage | null;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_updatedBy_user;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_createdBy_personalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
  imageUrl: string;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_createdBy_personalProfile;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_updatedBy;
  department: StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_department | null;
  postTemplateSyncData: StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData | null;
  createdBy: StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_createdBy;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  postTemplates: StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates[];
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  title: string;
  description: string | null;
  configured: boolean;
  status: StageStatus;
  position: number;
  hidden: boolean;
  jobRoleGuideTemplateStage: StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage | null;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
  defaultAvatarImageUrl: string;
  companyLogoUrl: string;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique_candidate {
  __typename: "Candidate";
  id: string;
  organization: StagesForGuideInterviewPlanModal_guideFindUnique_candidate_organization;
}

export interface StagesForGuideInterviewPlanModal_guideFindUnique {
  __typename: "Guide";
  id: string;
  isHiringProcessOverridden: boolean;
  stages: StagesForGuideInterviewPlanModal_guideFindUnique_stages[];
  sortedVisibleStages: StagesForGuideInterviewPlanModal_guideFindUnique_sortedVisibleStages[];
  candidate: StagesForGuideInterviewPlanModal_guideFindUnique_candidate;
}

export interface StagesForGuideInterviewPlanModal {
  guideFindUnique: StagesForGuideInterviewPlanModal_guideFindUnique;
}

export interface StagesForGuideInterviewPlanModalVariables {
  where: GuideWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGuideInterviewPlan
// ====================================================

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage_postTemplates_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage_postTemplates_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage_postTemplates_updatedBy_user;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage_postTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage_postTemplates_createdBy_personalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
  imageUrl: string;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage_postTemplates_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage_postTemplates_createdBy_personalProfile;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage_postTemplates_updatedBy;
  department: UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage_postTemplates_department | null;
  postTemplateSyncData: UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData | null;
  createdBy: UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage_postTemplates_createdBy;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  postTemplates: UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage_postTemplates[];
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_stages {
  __typename: "Stage";
  id: string;
  title: string;
  description: string | null;
  configured: boolean;
  status: StageStatus;
  position: number;
  hidden: boolean;
  jobRoleGuideTemplateStage: UpdateGuideInterviewPlan_updateGuidePlan_guide_stages_jobRoleGuideTemplateStage | null;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_updatedBy_user;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_createdBy_personalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
  imageUrl: string;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_createdBy_personalProfile;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_updatedBy;
  department: UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_department | null;
  postTemplateSyncData: UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData | null;
  createdBy: UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_createdBy;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  postTemplates: UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates[];
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  title: string;
  description: string | null;
  configured: boolean;
  status: StageStatus;
  position: number;
  hidden: boolean;
  jobRoleGuideTemplateStage: UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages_jobRoleGuideTemplateStage | null;
}

export interface UpdateGuideInterviewPlan_updateGuidePlan_guide {
  __typename: "Guide";
  id: string;
  isHiringProcessOverridden: boolean;
  stages: UpdateGuideInterviewPlan_updateGuidePlan_guide_stages[];
  sortedVisibleStages: UpdateGuideInterviewPlan_updateGuidePlan_guide_sortedVisibleStages[];
}

export interface UpdateGuideInterviewPlan_updateGuidePlan {
  __typename: "UpdateGuidePlanResponse";
  success: boolean;
  code: string;
  message: string;
  guide: UpdateGuideInterviewPlan_updateGuidePlan_guide | null;
}

export interface UpdateGuideInterviewPlan {
  updateGuidePlan: UpdateGuideInterviewPlan_updateGuidePlan;
}

export interface UpdateGuideInterviewPlanVariables {
  input: UpdateGuidePlanInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideForEditGuideInterviewPlanModalQuery
// ====================================================

export interface GuideForEditGuideInterviewPlanModalQuery_guideById_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
}

export interface GuideForEditGuideInterviewPlanModalQuery_guideById_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: GuideForEditGuideInterviewPlanModalQuery_guideById_interviewPlan_items[];
}

export interface GuideForEditGuideInterviewPlanModalQuery_guideById_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
  defaultAvatarImageUrl: string;
  companyLogoUrl: string;
}

export interface GuideForEditGuideInterviewPlanModalQuery_guideById_candidate {
  __typename: "Candidate";
  id: string;
  organization: GuideForEditGuideInterviewPlanModalQuery_guideById_candidate_organization;
}

export interface GuideForEditGuideInterviewPlanModalQuery_guideById {
  __typename: "GuideV2";
  id: string;
  interviewPlan: GuideForEditGuideInterviewPlanModalQuery_guideById_interviewPlan | null;
  candidate: GuideForEditGuideInterviewPlanModalQuery_guideById_candidate;
}

export interface GuideForEditGuideInterviewPlanModalQuery {
  guideById: GuideForEditGuideInterviewPlanModalQuery_guideById | null;
}

export interface GuideForEditGuideInterviewPlanModalQueryVariables {
  guideId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertGuideInterviewPlan
// ====================================================

export interface UpsertGuideInterviewPlan_upsertGuideInterviewPlan_guide_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
}

export interface UpsertGuideInterviewPlan_upsertGuideInterviewPlan_guide_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: UpsertGuideInterviewPlan_upsertGuideInterviewPlan_guide_interviewPlan_items[];
}

export interface UpsertGuideInterviewPlan_upsertGuideInterviewPlan_guide {
  __typename: "GuideV2";
  id: string;
  interviewPlan: UpsertGuideInterviewPlan_upsertGuideInterviewPlan_guide_interviewPlan | null;
}

export interface UpsertGuideInterviewPlan_upsertGuideInterviewPlan {
  __typename: "UpsertGuideInterviewPlanResponse";
  success: boolean;
  message: string;
  code: string;
  guide: UpsertGuideInterviewPlan_upsertGuideInterviewPlan_guide;
}

export interface UpsertGuideInterviewPlan {
  upsertGuideInterviewPlan: UpsertGuideInterviewPlan_upsertGuideInterviewPlan;
}

export interface UpsertGuideInterviewPlanVariables {
  input: UpsertGuideInterviewPlanInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideTemplateStagesModal
// ====================================================

export interface GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates_updatedBy_user;
}

export interface GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates_createdBy_personalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
  imageUrl: string;
}

export interface GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates_createdBy_personalProfile;
}

export interface GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates_updatedBy;
  department: GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates_department | null;
  postTemplateSyncData: GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates_postTemplateSyncData | null;
  createdBy: GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates_createdBy;
}

export interface GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplates: GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates[];
}

export interface GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById {
  __typename: "GuideTemplate";
  id: string;
  guideTemplateStages: GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById_guideTemplateStages[];
}

export interface GuideTemplateStagesModal_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  name: string;
  defaultAvatarImageUrl: string;
  companyLogoUrl: string;
  guideTemplateById: GuideTemplateStagesModal_currentUserPrisma_currentOrganization_guideTemplateById;
}

export interface GuideTemplateStagesModal_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: GuideTemplateStagesModal_currentUserPrisma_currentOrganization | null;
}

export interface GuideTemplateStagesModal {
  currentUserPrisma: GuideTemplateStagesModal_currentUserPrisma | null;
}

export interface GuideTemplateStagesModalVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGuideTemplateInterviewPlan
// ====================================================

export interface UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages_postTemplates_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages_postTemplates_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages_postTemplates_updatedBy_user;
}

export interface UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages_postTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages_postTemplates_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages_postTemplates_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages_postTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages_postTemplates_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages_postTemplates_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages_postTemplates_createdBy_personalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
  imageUrl: string;
}

export interface UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages_postTemplates_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages_postTemplates_createdBy_personalProfile;
}

export interface UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages_postTemplates_updatedBy;
  department: UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages_postTemplates_department | null;
  postTemplateSyncData: UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages_postTemplates_postTemplateSyncData | null;
  createdBy: UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages_postTemplates_createdBy;
}

export interface UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplates: UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages_postTemplates[];
}

export interface UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  guideTemplateStages: UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate_guideTemplateStages[];
}

export interface UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan {
  __typename: "UpdateGuideTemplateInterviewPlanResponse";
  success: boolean;
  code: string;
  message: string;
  guideTemplate: UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan_guideTemplate | null;
}

export interface UpdateGuideTemplateInterviewPlan {
  updateGuideTemplateInterviewPlan: UpdateGuideTemplateInterviewPlan_updateGuideTemplateInterviewPlan;
}

export interface UpdateGuideTemplateInterviewPlanVariables {
  input: UpdateGuideTemplateInterviewPlanInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideTemplateForEditInterviewPlan
// ====================================================

export interface GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates_updatedBy_user;
}

export interface GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates_createdBy_personalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
  imageUrl: string;
}

export interface GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates_createdBy_personalProfile;
}

export interface GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates_updatedBy;
  department: GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates_department | null;
  postTemplateSyncData: GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates_postTemplateSyncData | null;
  createdBy: GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates_createdBy;
}

export interface GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates[];
}

export interface GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan_items[];
}

export interface GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById {
  __typename: "GuideTemplate";
  id: string;
  interviewPlan: GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById_interviewPlan | null;
}

export interface GuideTemplateForEditInterviewPlan_currentOrganization {
  __typename: "Organization";
  id: string;
  name: string;
  defaultAvatarImageUrl: string;
  companyLogoUrl: string;
  guideTemplateById: GuideTemplateForEditInterviewPlan_currentOrganization_guideTemplateById;
}

export interface GuideTemplateForEditInterviewPlan {
  currentOrganization: GuideTemplateForEditInterviewPlan_currentOrganization | null;
}

export interface GuideTemplateForEditInterviewPlanVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertGuideTemplateInterviewPlan
// ====================================================

export interface UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items_postTemplates_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items_postTemplates_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items_postTemplates_updatedBy_user;
}

export interface UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items_postTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items_postTemplates_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items_postTemplates_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items_postTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items_postTemplates_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items_postTemplates_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items_postTemplates_createdBy_personalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
  imageUrl: string;
}

export interface UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items_postTemplates_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items_postTemplates_createdBy_personalProfile;
}

export interface UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items_postTemplates_updatedBy;
  department: UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items_postTemplates_department | null;
  postTemplateSyncData: UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items_postTemplates_postTemplateSyncData | null;
  createdBy: UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items_postTemplates_createdBy;
}

export interface UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items_postTemplates[];
}

export interface UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan_items[];
}

export interface UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  interviewPlan: UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate_interviewPlan | null;
}

export interface UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan {
  __typename: "UpsertGuideTemplateInterviewPlanResponse";
  success: boolean;
  code: string;
  message: string;
  guideTemplate: UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan_guideTemplate;
}

export interface UpsertGuideTemplateInterviewPlan {
  upsertGuideTemplateInterviewPlan: UpsertGuideTemplateInterviewPlan_upsertGuideTemplateInterviewPlan;
}

export interface UpsertGuideTemplateInterviewPlanVariables {
  input: UpsertGuideTemplateInterviewPlanInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StagesForJRGTInterviewPlanModal
// ====================================================

export interface StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates_updatedBy_user;
}

export interface StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates_createdBy_personalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
  imageUrl: string;
}

export interface StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates_createdBy_personalProfile;
}

export interface StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates_updatedBy;
  department: StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates_department | null;
  postTemplateSyncData: StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates_postTemplateSyncData | null;
  createdBy: StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates_createdBy;
}

export interface StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change = StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange | StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringFieldChange | StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange;

export interface StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change;
}

export interface StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplates: StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates[];
  changes: StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes[];
}

export interface StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  jobRoleGuideTemplateStages: StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages[];
}

export interface StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  name: string;
  defaultAvatarImageUrl: string;
  companyLogoUrl: string;
  jobRoleGuideTemplateById: StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById;
}

export interface StagesForJRGTInterviewPlanModal_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: StagesForJRGTInterviewPlanModal_currentUserPrisma_currentOrganization | null;
}

export interface StagesForJRGTInterviewPlanModal {
  currentUserPrisma: StagesForJRGTInterviewPlanModal_currentUserPrisma | null;
}

export interface StagesForJRGTInterviewPlanModalVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateJobRoleGuideTemplateInterviewPlan
// ====================================================

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates_updatedBy_user;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates_createdBy_personalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
  imageUrl: string;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates_createdBy_personalProfile;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates_updatedBy;
  department: UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates_department | null;
  postTemplateSyncData: UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates_postTemplateSyncData | null;
  createdBy: UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates_createdBy;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change = UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange | UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringFieldChange | UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange;

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplates: UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates[];
  changes: UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes[];
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes_change = UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes_change_BooleanFieldChange | UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes_change_StringFieldChange | UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes_change_StringArrayFieldChange;

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes {
  __typename: "GuidePropagationFieldChange";
  field: GuidePropagationChangesEnum;
  change: UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes_change;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedAddedData {
  __typename: "CollectionFieldChangedAddedData";
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedRemovedData {
  __typename: "CollectionFieldChangedRemovedData";
  parentId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedReorderedData {
  __typename: "CollectionFieldChangedReorderedData";
  parentId: string | null;
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export type UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges_data = UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedAddedData | UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedRemovedData | UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedReorderedData;

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges {
  __typename: "GuideCollectionPropagationFieldChange";
  type: GuideCollectionPropagationChangesTypeEnum;
  data: UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges_data;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  jobRoleGuideTemplateStages: UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_jobRoleGuideTemplateStages[];
  changes: UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes[];
  collectionChanges: UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges[];
}

export interface UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan {
  __typename: "UpdateJobRoleGuideTemplateInterviewPlanResponse";
  success: boolean;
  code: string;
  message: string;
  jobRoleGuideTemplate: UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate | null;
}

export interface UpdateJobRoleGuideTemplateInterviewPlan {
  updateJobRoleGuideTemplateInterviewPlan: UpdateJobRoleGuideTemplateInterviewPlan_updateJobRoleGuideTemplateInterviewPlan;
}

export interface UpdateJobRoleGuideTemplateInterviewPlanVariables {
  input: UpdateJobRoleGuideTemplateInterviewPlanInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobRoleGuideTemplateForEditInterviewPlan
// ====================================================

export interface JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates_updatedBy_user;
}

export interface JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates_createdBy_personalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
  imageUrl: string;
}

export interface JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates_createdBy_personalProfile;
}

export interface JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates_updatedBy;
  department: JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates_department | null;
  postTemplateSyncData: JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates_postTemplateSyncData | null;
  createdBy: JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates_createdBy;
}

export interface JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates[];
}

export interface JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items[];
}

export interface JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  interviewPlan: JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById_interviewPlan | null;
}

export interface JobRoleGuideTemplateForEditInterviewPlan_currentOrganization {
  __typename: "Organization";
  id: string;
  name: string;
  defaultAvatarImageUrl: string;
  companyLogoUrl: string;
  jobRoleGuideTemplateById: JobRoleGuideTemplateForEditInterviewPlan_currentOrganization_jobRoleGuideTemplateById;
}

export interface JobRoleGuideTemplateForEditInterviewPlan {
  currentOrganization: JobRoleGuideTemplateForEditInterviewPlan_currentOrganization | null;
}

export interface JobRoleGuideTemplateForEditInterviewPlanVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertJobRoleGuideTemplateInterviewPlan
// ====================================================

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items_postTemplates_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items_postTemplates_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items_postTemplates_updatedBy_user;
}

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items_postTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items_postTemplates_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items_postTemplates_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items_postTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items_postTemplates_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items_postTemplates_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items_postTemplates_createdBy_personalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
  imageUrl: string;
}

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items_postTemplates_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items_postTemplates_createdBy_personalProfile;
}

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items_postTemplates_updatedBy;
  department: UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items_postTemplates_department | null;
  postTemplateSyncData: UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items_postTemplates_postTemplateSyncData | null;
  createdBy: UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items_postTemplates_createdBy;
}

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items_postTemplates[];
}

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan_items[];
}

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes_change = UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes_change_BooleanFieldChange | UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes_change_StringFieldChange | UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes_change_StringArrayFieldChange;

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes {
  __typename: "GuidePropagationFieldChange";
  field: GuidePropagationChangesEnum;
  change: UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes_change;
}

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedAddedData {
  __typename: "CollectionFieldChangedAddedData";
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedRemovedData {
  __typename: "CollectionFieldChangedRemovedData";
  parentId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedReorderedData {
  __typename: "CollectionFieldChangedReorderedData";
  parentId: string | null;
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export type UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges_data = UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedAddedData | UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedRemovedData | UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedReorderedData;

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges {
  __typename: "GuideCollectionPropagationFieldChange";
  type: GuideCollectionPropagationChangesTypeEnum;
  data: UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges_data;
}

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  interviewPlan: UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_interviewPlan | null;
  changes: UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_changes[];
  collectionChanges: UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate_collectionChanges[];
}

export interface UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan {
  __typename: "UpsertJobRoleGuideTemplateInterviewPlanResponse";
  success: boolean;
  code: string;
  message: string;
  jobRoleGuideTemplate: UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan_jobRoleGuideTemplate;
}

export interface UpsertJobRoleGuideTemplateInterviewPlan {
  upsertJobRoleGuideTemplateInterviewPlan: UpsertJobRoleGuideTemplateInterviewPlan_upsertJobRoleGuideTemplateInterviewPlan;
}

export interface UpsertJobRoleGuideTemplateInterviewPlanVariables {
  input: UpsertJobRoleGuideTemplateInterviewPlanInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SyncGuideForGreenhouseExtensionParams
// ====================================================

export interface SyncGuideForGreenhouseExtensionParams_syncGuideWithGreenhouseApplication_guide {
  __typename: "Guide";
  id: string;
}

export interface SyncGuideForGreenhouseExtensionParams_syncGuideWithGreenhouseApplication {
  __typename: "GreenhouseSyncApplicationMutationResponse";
  code: string;
  success: boolean;
  guide: SyncGuideForGreenhouseExtensionParams_syncGuideWithGreenhouseApplication_guide | null;
}

export interface SyncGuideForGreenhouseExtensionParams {
  syncGuideWithGreenhouseApplication: SyncGuideForGreenhouseExtensionParams_syncGuideWithGreenhouseApplication;
}

export interface SyncGuideForGreenhouseExtensionParamsVariables {
  where: GuideApplicationCandidateWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchGuideForManagerTab
// ====================================================

export interface FetchGuideForManagerTab_guideFindUnique_jobRoleGuideTemplate_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
}

export interface FetchGuideForManagerTab_guideFindUnique_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  atsJob: FetchGuideForManagerTab_guideFindUnique_jobRoleGuideTemplate_atsJob | null;
  defaultGuideVersion: number;
}

export interface FetchGuideForManagerTab_guideFindUnique_candidate_organization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface FetchGuideForManagerTab_guideFindUnique_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  domains: string[];
}

export interface FetchGuideForManagerTab_guideFindUnique_candidate_organization {
  __typename: "Organization";
  id: string;
  guideDefaultBannerImageUrl: string;
  theme: FetchGuideForManagerTab_guideFindUnique_candidate_organization_theme | null;
  name: string;
  defaultAvatarImageUrl: string;
  customer: FetchGuideForManagerTab_guideFindUnique_candidate_organization_customer;
}

export interface FetchGuideForManagerTab_guideFindUnique_candidate {
  __typename: "Candidate";
  id: string;
  organization: FetchGuideForManagerTab_guideFindUnique_candidate_organization;
  name: string;
  imageUrl: string;
  firstName: string;
  lastName: string;
}

export interface FetchGuideForManagerTab_guideFindUnique_stages_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface FetchGuideForManagerTab_guideFindUnique_stages_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
}

export interface FetchGuideForManagerTab_guideFindUnique_stages {
  __typename: "Stage";
  id: string;
  configured: boolean;
  title: string;
  status: StageStatus;
  isAtsCurrent: boolean;
  hidden: boolean;
  hasAutomation: boolean;
  position: number;
  atsStage: FetchGuideForManagerTab_guideFindUnique_stages_atsStage | null;
  guide: FetchGuideForManagerTab_guideFindUnique_stages_guide;
  description: string | null;
  needsFeedback: boolean;
  stageTemplateInstallationId: string | null;
}

export interface FetchGuideForManagerTab_guideFindUnique_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  hasAutomation: boolean;
  configured: boolean;
  title: string;
  status: StageStatus;
  position: number;
  hidden: boolean;
  description: string | null;
  needsFeedback: boolean;
}

export interface FetchGuideForManagerTab_guideFindUnique_defaultContent {
  __typename: "GuideContent";
  data: GraphQL_JSON;
}

export interface FetchGuideForManagerTab_guideFindUnique_hiringTeam_interviewer {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface FetchGuideForManagerTab_guideFindUnique_hiringTeam {
  __typename: "HiringTeamMember";
  id: GraphQL_UUID;
  role: HiringTeamRoleEnum;
  isPointOfContact: boolean;
  hidden: boolean;
  interviewer: FetchGuideForManagerTab_guideFindUnique_hiringTeam_interviewer;
}

export interface FetchGuideForManagerTab_guideFindUnique_posts_senderUser {
  __typename: "GuidePostUser";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  isCandidate: boolean;
}

export interface FetchGuideForManagerTab_guideFindUnique_posts_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface FetchGuideForManagerTab_guideFindUnique_posts_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface FetchGuideForManagerTab_guideFindUnique_posts_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: FetchGuideForManagerTab_guideFindUnique_posts_replies_senderUser;
}

export interface FetchGuideForManagerTab_guideFindUnique_posts {
  __typename: "GuidePost";
  id: string;
  title: string;
  createdAt: GraphQL_DateTime;
  senderUser: FetchGuideForManagerTab_guideFindUnique_posts_senderUser;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: FetchGuideForManagerTab_guideFindUnique_posts_lastCandidateEmailEvent | null;
  replies: FetchGuideForManagerTab_guideFindUnique_posts_replies[];
  readAt: GraphQL_DateTime | null;
}

export interface FetchGuideForManagerTab_guideFindUnique_upcomingInterviewsV2_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface FetchGuideForManagerTab_guideFindUnique_upcomingInterviewsV2_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface FetchGuideForManagerTab_guideFindUnique_upcomingInterviewsV2_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface FetchGuideForManagerTab_guideFindUnique_upcomingInterviewsV2 {
  __typename: "Event";
  id: string;
  eventTemplateInstallationId: string | null;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: FetchGuideForManagerTab_guideFindUnique_upcomingInterviewsV2_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: FetchGuideForManagerTab_guideFindUnique_upcomingInterviewsV2_interviewers[];
  conferencePhone: string | null;
  location: FetchGuideForManagerTab_guideFindUnique_upcomingInterviewsV2_location | null;
}

export interface FetchGuideForManagerTab_guideFindUnique_currentStage {
  __typename: "Stage";
  id: string;
}

export interface FetchGuideForManagerTab_guideFindUnique {
  __typename: "Guide";
  id: string;
  startedAt: GraphQL_DateTime | null;
  candidateOpens: string[];
  jobRoleGuideTemplate: FetchGuideForManagerTab_guideFindUnique_jobRoleGuideTemplate | null;
  candidate: FetchGuideForManagerTab_guideFindUnique_candidate;
  guideVersion: number;
  stages: FetchGuideForManagerTab_guideFindUnique_stages[];
  status: GuideStatusEnum;
  sortedVisibleStages: FetchGuideForManagerTab_guideFindUnique_sortedVisibleStages[];
  roleName: string;
  bannerImageUrl: string | null;
  defaultContent: FetchGuideForManagerTab_guideFindUnique_defaultContent | null;
  invitedAt: GraphQL_DateTime | null;
  inviteOpenedAt: GraphQL_DateTime | null;
  hiringTeam: FetchGuideForManagerTab_guideFindUnique_hiringTeam[];
  publicUrl: string;
  posts: FetchGuideForManagerTab_guideFindUnique_posts[];
  upcomingInterviewsV2: FetchGuideForManagerTab_guideFindUnique_upcomingInterviewsV2[];
  jobRoleGuideTemplateId: string | null;
  statusV2: GuideStatusV2Enum;
  hiringDecisionAt: GraphQL_DateTime | null;
  currentStage: FetchGuideForManagerTab_guideFindUnique_currentStage | null;
  needsStageConfiguration: boolean;
}

export interface FetchGuideForManagerTab {
  guideFindUnique: FetchGuideForManagerTab_guideFindUnique;
}

export interface FetchGuideForManagerTabVariables {
  where: GuideWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchV2GuideForManagerTab
// ====================================================

export interface FetchV2GuideForManagerTab_guideById_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface FetchV2GuideForManagerTab_guideById_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
}

export interface FetchV2GuideForManagerTab_guideById_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: FetchV2GuideForManagerTab_guideById_interviewPlan_items[];
}

export interface FetchV2GuideForManagerTab_guideById {
  __typename: "GuideV2";
  id: string;
  jobRoleGuideTemplateId: string;
  currentInterviewPlanItemId: string | null;
  roleName: string;
  candidate: FetchV2GuideForManagerTab_guideById_candidate;
  status: GuideStatusV2Enum;
  interviewPlan: FetchV2GuideForManagerTab_guideById_interviewPlan | null;
}

export interface FetchV2GuideForManagerTab {
  guideById: FetchV2GuideForManagerTab_guideById | null;
}

export interface FetchV2GuideForManagerTabVariables {
  guideId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideManagerPastInterviews
// ====================================================

export interface GuideManagerPastInterviews_guideFindUnique_candidate {
  __typename: "Candidate";
  name: string;
}

export interface GuideManagerPastInterviews_guideFindUnique_pastInterviews_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface GuideManagerPastInterviews_guideFindUnique_pastInterviews_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface GuideManagerPastInterviews_guideFindUnique_pastInterviews_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface GuideManagerPastInterviews_guideFindUnique_pastInterviews {
  __typename: "Event";
  id: string;
  eventTemplateInstallationId: string | null;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: GuideManagerPastInterviews_guideFindUnique_pastInterviews_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: GuideManagerPastInterviews_guideFindUnique_pastInterviews_interviewers[];
  conferencePhone: string | null;
  location: GuideManagerPastInterviews_guideFindUnique_pastInterviews_location | null;
  collaborativeCodingUrl: string | null;
}

export interface GuideManagerPastInterviews_guideFindUnique {
  __typename: "Guide";
  id: string;
  roleName: string;
  candidate: GuideManagerPastInterviews_guideFindUnique_candidate;
  pastInterviews: GuideManagerPastInterviews_guideFindUnique_pastInterviews[];
}

export interface GuideManagerPastInterviews {
  guideFindUnique: GuideManagerPastInterviews_guideFindUnique;
}

export interface GuideManagerPastInterviewsVariables {
  where: GuideWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideManagerUpcomingInterviews
// ====================================================

export interface GuideManagerUpcomingInterviews_guideFindUnique_candidate {
  __typename: "Candidate";
  name: string;
}

export interface GuideManagerUpcomingInterviews_guideFindUnique_upcomingInterviewsV2_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface GuideManagerUpcomingInterviews_guideFindUnique_upcomingInterviewsV2_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface GuideManagerUpcomingInterviews_guideFindUnique_upcomingInterviewsV2_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface GuideManagerUpcomingInterviews_guideFindUnique_upcomingInterviewsV2 {
  __typename: "Event";
  id: string;
  eventTemplateInstallationId: string | null;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: GuideManagerUpcomingInterviews_guideFindUnique_upcomingInterviewsV2_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: GuideManagerUpcomingInterviews_guideFindUnique_upcomingInterviewsV2_interviewers[];
  conferencePhone: string | null;
  location: GuideManagerUpcomingInterviews_guideFindUnique_upcomingInterviewsV2_location | null;
  collaborativeCodingUrl: string | null;
}

export interface GuideManagerUpcomingInterviews_guideFindUnique {
  __typename: "Guide";
  id: string;
  roleName: string;
  candidate: GuideManagerUpcomingInterviews_guideFindUnique_candidate;
  upcomingInterviewsV2: GuideManagerUpcomingInterviews_guideFindUnique_upcomingInterviewsV2[];
}

export interface GuideManagerUpcomingInterviews {
  guideFindUnique: GuideManagerUpcomingInterviews_guideFindUnique;
}

export interface GuideManagerUpcomingInterviewsVariables {
  where: GuideWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideManagerInterviewsHeader
// ====================================================

export interface GuideManagerInterviewsHeader_guideFindUnique_candidate {
  __typename: "Candidate";
  name: string;
}

export interface GuideManagerInterviewsHeader_guideFindUnique {
  __typename: "Guide";
  id: string;
  roleName: string;
  candidate: GuideManagerInterviewsHeader_guideFindUnique_candidate;
}

export interface GuideManagerInterviewsHeader {
  guideFindUnique: GuideManagerInterviewsHeader_guideFindUnique;
}

export interface GuideManagerInterviewsHeaderVariables {
  where: GuideWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInboxMessages
// ====================================================

export interface GetInboxMessages_currentOrganization_inboxMessages_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  lastName: string;
}

export interface GetInboxMessages_currentOrganization_inboxMessages_recipientProfiles {
  __typename: "GuidePostUser";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  isCandidate: boolean;
}

export interface GetInboxMessages_currentOrganization_inboxMessages_inboxMessageMetadata_mostRecentActivityMessage {
  __typename: "GuidePost";
  id: string;
  updatedAt: GraphQL_DateTime;
}

export interface GetInboxMessages_currentOrganization_inboxMessages_inboxMessageMetadata {
  __typename: "InboxMessageMetadata";
  mostRecentActivityMessage: GetInboxMessages_currentOrganization_inboxMessages_inboxMessageMetadata_mostRecentActivityMessage;
  status: InboxMessageStatusEnum;
  href: string;
  availabilityStatus: InboxMessageAvailabilityStatusEnum | null;
  views: GraphQL_DateTime[];
}

export interface GetInboxMessages_currentOrganization_inboxMessages_guide {
  __typename: "Guide";
  id: string;
  atsUrl: string;
}

export interface GetInboxMessages_currentOrganization_inboxMessages {
  __typename: "GuidePost";
  id: string;
  title: string;
  data: GraphQL_JSON | null;
  readAt: GraphQL_DateTime | null;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  threadId: string | null;
  candidate: GetInboxMessages_currentOrganization_inboxMessages_candidate;
  recipientProfiles: GetInboxMessages_currentOrganization_inboxMessages_recipientProfiles[];
  inboxMessageMetadata: GetInboxMessages_currentOrganization_inboxMessages_inboxMessageMetadata | null;
  guide: GetInboxMessages_currentOrganization_inboxMessages_guide;
}

export interface GetInboxMessages_currentOrganization {
  __typename: "Organization";
  id: string;
  inboxMessages: GetInboxMessages_currentOrganization_inboxMessages[];
  inboxMessageCount: number;
}

export interface GetInboxMessages {
  currentOrganization: GetInboxMessages_currentOrganization | null;
}

export interface GetInboxMessagesVariables {
  limit?: number | null;
  offset?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MoveCandidateToStageWithoutMessage
// ====================================================

export interface MoveCandidateToStageWithoutMessage_moveCandidateToStage_stage_guide_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface MoveCandidateToStageWithoutMessage_moveCandidateToStage_stage_guide_currentStage {
  __typename: "Stage";
  id: string;
}

export interface MoveCandidateToStageWithoutMessage_moveCandidateToStage_stage_guide_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  hidden: boolean;
  title: string;
  description: string | null;
  needsFeedback: boolean;
  hasAutomation: boolean;
  configured: boolean;
  status: StageStatus;
  position: number;
}

export interface MoveCandidateToStageWithoutMessage_moveCandidateToStage_stage_guide_stages {
  __typename: "Stage";
  id: string;
  title: string;
  description: string | null;
  needsFeedback: boolean;
  hasAutomation: boolean;
  configured: boolean;
  status: StageStatus;
  position: number;
}

export interface MoveCandidateToStageWithoutMessage_moveCandidateToStage_stage_guide {
  __typename: "Guide";
  id: string;
  guideVersion: number;
  jobRoleGuideTemplateId: string | null;
  statusV2: GuideStatusV2Enum;
  hiringDecisionAt: GraphQL_DateTime | null;
  candidate: MoveCandidateToStageWithoutMessage_moveCandidateToStage_stage_guide_candidate;
  currentStage: MoveCandidateToStageWithoutMessage_moveCandidateToStage_stage_guide_currentStage | null;
  sortedVisibleStages: MoveCandidateToStageWithoutMessage_moveCandidateToStage_stage_guide_sortedVisibleStages[];
  stages: MoveCandidateToStageWithoutMessage_moveCandidateToStage_stage_guide_stages[];
}

export interface MoveCandidateToStageWithoutMessage_moveCandidateToStage_stage {
  __typename: "Stage";
  id: string;
  guide: MoveCandidateToStageWithoutMessage_moveCandidateToStage_stage_guide;
}

export interface MoveCandidateToStageWithoutMessage_moveCandidateToStage {
  __typename: "MoveCandidateToStageMutationResponse";
  message: string;
  success: boolean;
  code: string;
  stage: MoveCandidateToStageWithoutMessage_moveCandidateToStage_stage | null;
}

export interface MoveCandidateToStageWithoutMessage {
  moveCandidateToStage: MoveCandidateToStageWithoutMessage_moveCandidateToStage;
}

export interface MoveCandidateToStageWithoutMessageVariables {
  input: MoveCandidateToStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateLegacyGuideStatus
// ====================================================

export interface UpdateLegacyGuideStatus_updateLegacyGuideStatus_guide {
  __typename: "Guide";
  id: string;
  statusV2: GuideStatusV2Enum;
}

export interface UpdateLegacyGuideStatus_updateLegacyGuideStatus {
  __typename: "UpdateLegacyGuideStatusResponse";
  message: string;
  success: boolean;
  code: string;
  guide: UpdateLegacyGuideStatus_updateLegacyGuideStatus_guide;
}

export interface UpdateLegacyGuideStatus {
  updateLegacyGuideStatus: UpdateLegacyGuideStatus_updateLegacyGuideStatus;
}

export interface UpdateLegacyGuideStatusVariables {
  input: UpdateLegacyGuideStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TransitionMoveCandidateToStageWithoutMessage
// ====================================================

export interface TransitionMoveCandidateToStageWithoutMessage_moveCandidateToStage_stage_guide_currentStage {
  __typename: "Stage";
  id: string;
}

export interface TransitionMoveCandidateToStageWithoutMessage_moveCandidateToStage_stage_guide_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  hidden: boolean;
  status: StageStatus;
}

export interface TransitionMoveCandidateToStageWithoutMessage_moveCandidateToStage_stage_guide {
  __typename: "Guide";
  id: string;
  currentStage: TransitionMoveCandidateToStageWithoutMessage_moveCandidateToStage_stage_guide_currentStage | null;
  sortedVisibleStages: TransitionMoveCandidateToStageWithoutMessage_moveCandidateToStage_stage_guide_sortedVisibleStages[];
}

export interface TransitionMoveCandidateToStageWithoutMessage_moveCandidateToStage_stage {
  __typename: "Stage";
  id: string;
  guide: TransitionMoveCandidateToStageWithoutMessage_moveCandidateToStage_stage_guide;
}

export interface TransitionMoveCandidateToStageWithoutMessage_moveCandidateToStage {
  __typename: "MoveCandidateToStageMutationResponse";
  message: string;
  success: boolean;
  code: string;
  stage: TransitionMoveCandidateToStageWithoutMessage_moveCandidateToStage_stage | null;
}

export interface TransitionMoveCandidateToStageWithoutMessage {
  moveCandidateToStage: TransitionMoveCandidateToStageWithoutMessage_moveCandidateToStage;
}

export interface TransitionMoveCandidateToStageWithoutMessageVariables {
  input: MoveCandidateToStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideInterviewsForRichBlockInterviewsRenderer
// ====================================================

export interface GuideInterviewsForRichBlockInterviewsRenderer_interviewsById_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface GuideInterviewsForRichBlockInterviewsRenderer_interviewsById_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface GuideInterviewsForRichBlockInterviewsRenderer_interviewsById_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface GuideInterviewsForRichBlockInterviewsRenderer_interviewsById {
  __typename: "Event";
  id: string;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: GuideInterviewsForRichBlockInterviewsRenderer_interviewsById_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: GuideInterviewsForRichBlockInterviewsRenderer_interviewsById_interviewers[];
  conferencePhone: string | null;
  location: GuideInterviewsForRichBlockInterviewsRenderer_interviewsById_location | null;
  collaborativeCodingUrl: string | null;
}

export interface GuideInterviewsForRichBlockInterviewsRenderer {
  interviewsById: GuideInterviewsForRichBlockInterviewsRenderer_interviewsById[];
}

export interface GuideInterviewsForRichBlockInterviewsRendererVariables {
  guideId: string;
  interviewIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideUpcomingInterviewsForRichBlock
// ====================================================

export interface GuideUpcomingInterviewsForRichBlock_guideFindUnique_upcomingInterviewsV2_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface GuideUpcomingInterviewsForRichBlock_guideFindUnique_upcomingInterviewsV2_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface GuideUpcomingInterviewsForRichBlock_guideFindUnique_upcomingInterviewsV2_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface GuideUpcomingInterviewsForRichBlock_guideFindUnique_upcomingInterviewsV2_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
}

export interface GuideUpcomingInterviewsForRichBlock_guideFindUnique_upcomingInterviewsV2_icsFile {
  __typename: "EmailAttachedFile";
  id: GraphQL_UUID;
  filename: string;
  content: string;
}

export interface GuideUpcomingInterviewsForRichBlock_guideFindUnique_upcomingInterviewsV2 {
  __typename: "Event";
  id: string;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: GuideUpcomingInterviewsForRichBlock_guideFindUnique_upcomingInterviewsV2_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: GuideUpcomingInterviewsForRichBlock_guideFindUnique_upcomingInterviewsV2_interviewers[];
  conferencePhone: string | null;
  location: GuideUpcomingInterviewsForRichBlock_guideFindUnique_upcomingInterviewsV2_location | null;
  collaborativeCodingUrl: string | null;
  eventTemplate: GuideUpcomingInterviewsForRichBlock_guideFindUnique_upcomingInterviewsV2_eventTemplate | null;
  icsFile: GuideUpcomingInterviewsForRichBlock_guideFindUnique_upcomingInterviewsV2_icsFile;
}

export interface GuideUpcomingInterviewsForRichBlock_guideFindUnique {
  __typename: "Guide";
  id: string;
  upcomingInterviewsV2: GuideUpcomingInterviewsForRichBlock_guideFindUnique_upcomingInterviewsV2[];
}

export interface GuideUpcomingInterviewsForRichBlock {
  guideFindUnique: GuideUpcomingInterviewsForRichBlock_guideFindUnique;
}

export interface GuideUpcomingInterviewsForRichBlockVariables {
  where: GuideWhereUniqueInput;
  sendToEmail?: string | null;
  skipICSFiles: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideInterviewsFromRichBlockContext
// ====================================================

export interface GuideInterviewsFromRichBlockContext_interviewsById_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface GuideInterviewsFromRichBlockContext_interviewsById_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface GuideInterviewsFromRichBlockContext_interviewsById_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface GuideInterviewsFromRichBlockContext_interviewsById_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
}

export interface GuideInterviewsFromRichBlockContext_interviewsById_icsFile {
  __typename: "EmailAttachedFile";
  id: GraphQL_UUID;
  filename: string;
  content: string;
}

export interface GuideInterviewsFromRichBlockContext_interviewsById {
  __typename: "Event";
  id: string;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: GuideInterviewsFromRichBlockContext_interviewsById_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: GuideInterviewsFromRichBlockContext_interviewsById_interviewers[];
  conferencePhone: string | null;
  location: GuideInterviewsFromRichBlockContext_interviewsById_location | null;
  collaborativeCodingUrl: string | null;
  eventTemplate: GuideInterviewsFromRichBlockContext_interviewsById_eventTemplate | null;
  icsFile: GuideInterviewsFromRichBlockContext_interviewsById_icsFile;
}

export interface GuideInterviewsFromRichBlockContext {
  interviewsById: GuideInterviewsFromRichBlockContext_interviewsById[];
}

export interface GuideInterviewsFromRichBlockContextVariables {
  guideId: string;
  interviewIds: string[];
  sendToEmail?: string | null;
  skipICSFiles: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContextForRequestAvailabilityCalendar
// ====================================================

export interface ContextForRequestAvailabilityCalendar_guideFindUnique_candidate_organization {
  __typename: "Organization";
  id: string;
  defaultAvatarImageUrl: string;
  name: string;
  companyLogoUrl: string;
}

export interface ContextForRequestAvailabilityCalendar_guideFindUnique_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  organization: ContextForRequestAvailabilityCalendar_guideFindUnique_candidate_organization;
}

export interface ContextForRequestAvailabilityCalendar_guideFindUnique {
  __typename: "Guide";
  id: string;
  candidate: ContextForRequestAvailabilityCalendar_guideFindUnique_candidate;
}

export interface ContextForRequestAvailabilityCalendar {
  guideFindUnique: ContextForRequestAvailabilityCalendar_guideFindUnique;
}

export interface ContextForRequestAvailabilityCalendarVariables {
  where: GuideWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuidePostForRequestAvailabilityCalendar
// ====================================================

export interface GuidePostForRequestAvailabilityCalendar_guidePostById_parent {
  __typename: "GuidePost";
  id: string;
}

export interface GuidePostForRequestAvailabilityCalendar_guidePostById_availabilityRequests_calendarEvents_createdBy {
  __typename: "UserMembership";
  id: string;
}

export interface GuidePostForRequestAvailabilityCalendar_guidePostById_availabilityRequests_calendarEvents {
  __typename: "CalendarEvent";
  id: string;
  title: string;
  start: GraphQL_DateTime;
  end: GraphQL_DateTime;
  allDay: boolean;
  createdBy: GuidePostForRequestAvailabilityCalendar_guidePostById_availabilityRequests_calendarEvents_createdBy | null;
}

export interface GuidePostForRequestAvailabilityCalendar_guidePostById_availabilityRequests_candidateAvailabilities {
  __typename: "CandidateAvailability";
  id: string;
}

export interface GuidePostForRequestAvailabilityCalendar_guidePostById_availabilityRequests {
  __typename: "AvailabilityRequest";
  id: string;
  title: string;
  description: string | null;
  createMultipleEventsOnDrag: boolean;
  forceResponsesInIncrements: boolean;
  overlayCalendars: boolean;
  calendars: string[];
  allowSelectionOutsideSuggestions: boolean;
  calendarEvents: GuidePostForRequestAvailabilityCalendar_guidePostById_availabilityRequests_calendarEvents[];
  candidateAvailabilities: GuidePostForRequestAvailabilityCalendar_guidePostById_availabilityRequests_candidateAvailabilities[];
}

export interface GuidePostForRequestAvailabilityCalendar_guidePostById {
  __typename: "GuidePost";
  id: string;
  parent: GuidePostForRequestAvailabilityCalendar_guidePostById_parent | null;
  availabilityRequests: GuidePostForRequestAvailabilityCalendar_guidePostById_availabilityRequests[];
}

export interface GuidePostForRequestAvailabilityCalendar {
  guidePostById: GuidePostForRequestAvailabilityCalendar_guidePostById | null;
}

export interface GuidePostForRequestAvailabilityCalendarVariables {
  guidePostId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAvailabilityRequest
// ====================================================

export interface UpdateAvailabilityRequest_updateAvailabilityRequest_availabilityRequest_calendarEvents_createdBy {
  __typename: "UserMembership";
  id: string;
}

export interface UpdateAvailabilityRequest_updateAvailabilityRequest_availabilityRequest_calendarEvents {
  __typename: "CalendarEvent";
  id: string;
  title: string;
  start: GraphQL_DateTime;
  end: GraphQL_DateTime;
  allDay: boolean;
  createdBy: UpdateAvailabilityRequest_updateAvailabilityRequest_availabilityRequest_calendarEvents_createdBy | null;
}

export interface UpdateAvailabilityRequest_updateAvailabilityRequest_availabilityRequest_candidateAvailabilities {
  __typename: "CandidateAvailability";
  id: string;
}

export interface UpdateAvailabilityRequest_updateAvailabilityRequest_availabilityRequest {
  __typename: "AvailabilityRequest";
  id: string;
  title: string;
  description: string | null;
  createMultipleEventsOnDrag: boolean;
  forceResponsesInIncrements: boolean;
  overlayCalendars: boolean;
  calendars: string[];
  allowSelectionOutsideSuggestions: boolean;
  calendarEvents: UpdateAvailabilityRequest_updateAvailabilityRequest_availabilityRequest_calendarEvents[];
  candidateAvailabilities: UpdateAvailabilityRequest_updateAvailabilityRequest_availabilityRequest_candidateAvailabilities[];
}

export interface UpdateAvailabilityRequest_updateAvailabilityRequest {
  __typename: "UpdateAvailabilityRequestResponse";
  success: boolean;
  code: string;
  message: string;
  availabilityRequest: UpdateAvailabilityRequest_updateAvailabilityRequest_availabilityRequest;
}

export interface UpdateAvailabilityRequest {
  updateAvailabilityRequest: UpdateAvailabilityRequest_updateAvailabilityRequest;
}

export interface UpdateAvailabilityRequestVariables {
  input: UpdateAvailabilityRequestInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveCandidateAvailability
// ====================================================

export interface SaveCandidateAvailability_createGuidePost_guidePost_parent_replies {
  __typename: "GuidePost";
  id: string;
}

export interface SaveCandidateAvailability_createGuidePost_guidePost_parent {
  __typename: "GuidePost";
  id: string;
  replies: SaveCandidateAvailability_createGuidePost_guidePost_parent_replies[];
}

export interface SaveCandidateAvailability_createGuidePost_guidePost_guide_latestPost {
  __typename: "GuidePost";
  id: string;
}

export interface SaveCandidateAvailability_createGuidePost_guidePost_guide {
  __typename: "Guide";
  id: string;
  latestPost: SaveCandidateAvailability_createGuidePost_guidePost_guide_latestPost | null;
}

export interface SaveCandidateAvailability_createGuidePost_guidePost {
  __typename: "GuidePost";
  id: string;
  threadId: string | null;
  parent: SaveCandidateAvailability_createGuidePost_guidePost_parent | null;
  guide: SaveCandidateAvailability_createGuidePost_guidePost_guide;
}

export interface SaveCandidateAvailability_createGuidePost {
  __typename: "CreateGuidePostResponse";
  success: boolean;
  code: string;
  message: string;
  guidePost: SaveCandidateAvailability_createGuidePost_guidePost | null;
}

export interface SaveCandidateAvailability {
  createGuidePost: SaveCandidateAvailability_createGuidePost;
}

export interface SaveCandidateAvailabilityVariables {
  input: CreateGuidePostInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CanAccessCalendar
// ====================================================

export interface CanAccessCalendar_currentUserPrisma {
  __typename: "User";
  id: string;
  canAccessCalendar: boolean | null;
}

export interface CanAccessCalendar {
  currentUserPrisma: CanAccessCalendar_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOverlayCalendars
// ====================================================

export interface GetOverlayCalendars_getCalendarsV2 {
  __typename: "Calendar";
  id: string;
  name: string;
}

export interface GetOverlayCalendars {
  getCalendarsV2: GetOverlayCalendars_getCalendarsV2[];
}

export interface GetOverlayCalendarsVariables {
  where: GetCalendarsV2WhereInput;
  limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserCalendarEvents
// ====================================================

export interface GetUserCalendarEvents_getUserCalendarEvents {
  __typename: "UserCalendarEvent";
  id: string;
  title: string;
  startTime: string;
  endTime: string;
  calendarId: string;
}

export interface GetUserCalendarEvents {
  getUserCalendarEvents: GetUserCalendarEvents_getUserCalendarEvents[];
}

export interface GetUserCalendarEventsVariables {
  ids: string[];
  startTime: number;
  endTime: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuidePostForCandidateAvailabilityCalendar
// ====================================================

export interface GuidePostForCandidateAvailabilityCalendar_guidePostById_candidateAvailabilities_calendarEvents_createdBy {
  __typename: "UserMembership";
  id: string;
}

export interface GuidePostForCandidateAvailabilityCalendar_guidePostById_candidateAvailabilities_calendarEvents {
  __typename: "CalendarEvent";
  id: string;
  title: string;
  start: GraphQL_DateTime;
  end: GraphQL_DateTime;
  allDay: boolean;
  createdBy: GuidePostForCandidateAvailabilityCalendar_guidePostById_candidateAvailabilities_calendarEvents_createdBy | null;
}

export interface GuidePostForCandidateAvailabilityCalendar_guidePostById_candidateAvailabilities_availabilityRequest_calendarEvents_createdBy {
  __typename: "UserMembership";
  id: string;
}

export interface GuidePostForCandidateAvailabilityCalendar_guidePostById_candidateAvailabilities_availabilityRequest_calendarEvents {
  __typename: "CalendarEvent";
  id: string;
  title: string;
  start: GraphQL_DateTime;
  end: GraphQL_DateTime;
  allDay: boolean;
  createdBy: GuidePostForCandidateAvailabilityCalendar_guidePostById_candidateAvailabilities_availabilityRequest_calendarEvents_createdBy | null;
}

export interface GuidePostForCandidateAvailabilityCalendar_guidePostById_candidateAvailabilities_availabilityRequest_candidateAvailabilities {
  __typename: "CandidateAvailability";
  id: string;
  createdAt: GraphQL_DateTime;
}

export interface GuidePostForCandidateAvailabilityCalendar_guidePostById_candidateAvailabilities_availabilityRequest {
  __typename: "AvailabilityRequest";
  id: string;
  title: string;
  description: string | null;
  calendarEvents: GuidePostForCandidateAvailabilityCalendar_guidePostById_candidateAvailabilities_availabilityRequest_calendarEvents[];
  candidateAvailabilities: GuidePostForCandidateAvailabilityCalendar_guidePostById_candidateAvailabilities_availabilityRequest_candidateAvailabilities[];
}

export interface GuidePostForCandidateAvailabilityCalendar_guidePostById_candidateAvailabilities {
  __typename: "CandidateAvailability";
  id: string;
  title: string;
  overlayCalendars: boolean;
  calendarEvents: GuidePostForCandidateAvailabilityCalendar_guidePostById_candidateAvailabilities_calendarEvents[];
  availabilityRequest: GuidePostForCandidateAvailabilityCalendar_guidePostById_candidateAvailabilities_availabilityRequest | null;
}

export interface GuidePostForCandidateAvailabilityCalendar_guidePostById {
  __typename: "GuidePost";
  id: string;
  candidateAvailabilities: GuidePostForCandidateAvailabilityCalendar_guidePostById_candidateAvailabilities[];
}

export interface GuidePostForCandidateAvailabilityCalendar {
  guidePostById: GuidePostForCandidateAvailabilityCalendar_guidePostById | null;
}

export interface GuidePostForCandidateAvailabilityCalendarVariables {
  guidePostId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GenerateUploadcareSignatureForImageDialog
// ====================================================

export interface GenerateUploadcareSignatureForImageDialog_uploadcareSignature {
  __typename: "UploadcareConfig";
  signature: string;
  expire: string;
}

export interface GenerateUploadcareSignatureForImageDialog {
  uploadcareSignature: GenerateUploadcareSignatureForImageDialog_uploadcareSignature | null;
}

export interface GenerateUploadcareSignatureForImageDialogVariables {
  guideId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLinkPreviewForRichBlock
// ====================================================

export interface GetLinkPreviewForRichBlock_getLinkPreview {
  __typename: "LinkPreview";
  title: string;
  image: string | null;
  description: string | null;
}

export interface GetLinkPreviewForRichBlock {
  getLinkPreview: GetLinkPreviewForRichBlock_getLinkPreview;
}

export interface GetLinkPreviewForRichBlockVariables {
  url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConsumeMagicLink
// ====================================================

export interface ConsumeMagicLink_consumeMagicLink_user_availableOrganizations {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface ConsumeMagicLink_consumeMagicLink_user {
  __typename: "User";
  id: string;
  availableOrganizations: ConsumeMagicLink_consumeMagicLink_user_availableOrganizations[];
}

export interface ConsumeMagicLink_consumeMagicLink {
  __typename: "ConsumeMagicLinkResponse";
  success: boolean;
  code: string;
  user: ConsumeMagicLink_consumeMagicLink_user | null;
}

export interface ConsumeMagicLink {
  consumeMagicLink: ConsumeMagicLink_consumeMagicLink;
}

export interface ConsumeMagicLinkVariables {
  input: ConsumeMagicLinkInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserForAnalytics
// ====================================================

export interface UserForAnalytics_currentUserPrisma_currentUserMembership {
  __typename: "UserMembership";
  id: string;
  createdAt: GraphQL_DateTime;
  persona: PersonaEnum | null;
}

export interface UserForAnalytics_currentUserPrisma_currentOrganization_customer_plan {
  __typename: "CustomerPlan";
  id: string;
  name: string;
}

export interface UserForAnalytics_currentUserPrisma_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  trialDuration: number | null;
  trialStart: GraphQL_DateTime | null;
  name: string;
  plan: UserForAnalytics_currentUserPrisma_currentOrganization_customer_plan;
}

export interface UserForAnalytics_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  companyLogoUrl: string;
  id: string;
  name: string;
  createdAt: GraphQL_DateTime;
  zeusStatus: ZeusStatusEnum;
  planPricingModel: PlanPricingModelEnum | null;
  accountStatus: AccountStatusEnum | null;
  employeeCountEnum: CompanyEmployeeSizeEnum | null;
  talentTeamCountEnum: CompanyTalentTeamSizeEnum | null;
  contractStartDate: GraphQL_DateTime | null;
  customer: UserForAnalytics_currentUserPrisma_currentOrganization_customer;
}

export interface UserForAnalytics_currentUserPrisma {
  __typename: "User";
  id: string;
  intercomHash: string | null;
  primaryEmail: string | null;
  fullName: string;
  createdAt: GraphQL_DateTime;
  currentUserMembership: UserForAnalytics_currentUserPrisma_currentUserMembership | null;
  currentOrganization: UserForAnalytics_currentUserPrisma_currentOrganization | null;
}

export interface UserForAnalytics {
  currentUserPrisma: UserForAnalytics_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendUpgradeRequestEmail
// ====================================================

export interface SendUpgradeRequestEmail_sendUpgradeRequestEmail {
  __typename: "SendUpgradeRequestEmailResponse";
  success: boolean;
}

export interface SendUpgradeRequestEmail {
  sendUpgradeRequestEmail: SendUpgradeRequestEmail_sendUpgradeRequestEmail;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchAssignInterviewTemplateFiltersData
// ====================================================

export interface FetchAssignInterviewTemplateFiltersData_atsDepartments {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface FetchAssignInterviewTemplateFiltersData_currentOrganization_jobRoleGuideTemplates {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime;
}

export interface FetchAssignInterviewTemplateFiltersData_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplates: FetchAssignInterviewTemplateFiltersData_currentOrganization_jobRoleGuideTemplates[];
}

export interface FetchAssignInterviewTemplateFiltersData {
  atsDepartments: FetchAssignInterviewTemplateFiltersData_atsDepartments[];
  currentOrganization: FetchAssignInterviewTemplateFiltersData_currentOrganization | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchAssignInterviewTemplateData
// ====================================================

export interface FetchAssignInterviewTemplateData_currentOrganization_interviewTemplateById {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
}

export interface FetchAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_jobRole_atsJob_atsDepartments {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface FetchAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_jobRole_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
  requisitionId: string | null;
  atsDepartments: FetchAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_jobRole_atsJob_atsDepartments[];
}

export interface FetchAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_jobRole {
  __typename: "JobRole";
  id: string;
  atsJob: FetchAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_jobRole_atsJob | null;
}

export interface FetchAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_stageTemplateInstallations_eventTemplates_interviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
}

export interface FetchAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_stageTemplateInstallations_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
  interviewTemplate: FetchAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_stageTemplateInstallations_eventTemplates_interviewTemplate | null;
}

export interface FetchAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_stageTemplateInstallations {
  __typename: "StageTemplateInstallation";
  id: string;
  displayName: string;
  eventTemplates: FetchAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_stageTemplateInstallations_eventTemplates[];
}

export interface FetchAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime;
  jobRole: FetchAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_jobRole | null;
  stageTemplateInstallations: FetchAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_stageTemplateInstallations[];
}

export interface FetchAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplatesAggregate {
  __typename: "OrganizationJobRoleGuideTemplateAggregate";
  count: number | null;
}

export interface FetchAssignInterviewTemplateData_currentOrganization {
  __typename: "Organization";
  id: string;
  interviewTemplateById: FetchAssignInterviewTemplateData_currentOrganization_interviewTemplateById;
  jobRoleGuideTemplates: FetchAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates[];
  jobRoleGuideTemplatesAggregate: FetchAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplatesAggregate;
}

export interface FetchAssignInterviewTemplateData {
  currentOrganization: FetchAssignInterviewTemplateData_currentOrganization | null;
}

export interface FetchAssignInterviewTemplateDataVariables {
  where: OrganizationJobRoleGuideTemplateWhereInput;
  eventTemplatesWhere: EventTemplateWhereInput;
  offset?: number | null;
  limit?: number | null;
  interviewTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchMoreAssignInterviewTemplateData
// ====================================================

export interface FetchMoreAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_jobRole_atsJob_atsDepartments {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface FetchMoreAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_jobRole_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
  requisitionId: string | null;
  atsDepartments: FetchMoreAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_jobRole_atsJob_atsDepartments[];
}

export interface FetchMoreAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_jobRole {
  __typename: "JobRole";
  id: string;
  atsJob: FetchMoreAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_jobRole_atsJob | null;
}

export interface FetchMoreAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_stageTemplateInstallations_eventTemplates_interviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
}

export interface FetchMoreAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_stageTemplateInstallations_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
  interviewTemplate: FetchMoreAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_stageTemplateInstallations_eventTemplates_interviewTemplate | null;
}

export interface FetchMoreAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_stageTemplateInstallations {
  __typename: "StageTemplateInstallation";
  id: string;
  displayName: string;
  eventTemplates: FetchMoreAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_stageTemplateInstallations_eventTemplates[];
}

export interface FetchMoreAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime;
  jobRole: FetchMoreAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_jobRole | null;
  stageTemplateInstallations: FetchMoreAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates_stageTemplateInstallations[];
}

export interface FetchMoreAssignInterviewTemplateData_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplates: FetchMoreAssignInterviewTemplateData_currentOrganization_jobRoleGuideTemplates[];
}

export interface FetchMoreAssignInterviewTemplateData {
  currentOrganization: FetchMoreAssignInterviewTemplateData_currentOrganization | null;
}

export interface FetchMoreAssignInterviewTemplateDataVariables {
  where: OrganizationJobRoleGuideTemplateWhereInput;
  eventTemplatesWhere: EventTemplateWhereInput;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AssignInterviewTemplateToJobInterviews
// ====================================================

export interface AssignInterviewTemplateToJobInterviews_assignInterviewTemplateToJobInterviews_interviewTemplates_interviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
}

export interface AssignInterviewTemplateToJobInterviews_assignInterviewTemplateToJobInterviews_interviewTemplates {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
  interviewTemplate: AssignInterviewTemplateToJobInterviews_assignInterviewTemplateToJobInterviews_interviewTemplates_interviewTemplate | null;
}

export interface AssignInterviewTemplateToJobInterviews_assignInterviewTemplateToJobInterviews {
  __typename: "AssignInterviewTemplateToJobInterviewsMutationResponse";
  success: boolean;
  code: string;
  message: string;
  interviewTemplates: AssignInterviewTemplateToJobInterviews_assignInterviewTemplateToJobInterviews_interviewTemplates[];
}

export interface AssignInterviewTemplateToJobInterviews {
  assignInterviewTemplateToJobInterviews: AssignInterviewTemplateToJobInterviews_assignInterviewTemplateToJobInterviews;
}

export interface AssignInterviewTemplateToJobInterviewsVariables {
  input: AssignInterviewTemplateToJobInterviewsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GreenhouseJobData
// ====================================================

export interface GreenhouseJobData_jobRoles_atsJob_atsJobStages {
  __typename: "ATSJobRoleStage";
  rawJson: GraphQL_JSON;
  id: string;
  name: string;
}

export interface GreenhouseJobData_jobRoles_atsJob {
  __typename: "ATSJobRole";
  id: string;
  atsJobStages: GreenhouseJobData_jobRoles_atsJob_atsJobStages[];
}

export interface GreenhouseJobData_jobRoles {
  __typename: "JobRole";
  id: string;
  atsJob: GreenhouseJobData_jobRoles_atsJob | null;
}

export interface GreenhouseJobData {
  jobRoles: GreenhouseJobData_jobRoles[];
}

export interface GreenhouseJobDataVariables {
  where?: JobRoleWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobRoleGuideTemplatesForBulkEdit
// ====================================================

export interface JobRoleGuideTemplatesForBulkEdit_currentOrganization_jobRoleGuideTemplates_stageTemplateInstallations {
  __typename: "StageTemplateInstallation";
  id: string;
  activatedAt: GraphQL_DateTime | null;
  atsStageId: string;
}

export interface JobRoleGuideTemplatesForBulkEdit_currentOrganization_jobRoleGuideTemplates_jobRole_atsJob_atsJobStages {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
  originalATSId: string;
  jobId: string;
}

export interface JobRoleGuideTemplatesForBulkEdit_currentOrganization_jobRoleGuideTemplates_jobRole_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
  atsJobStages: JobRoleGuideTemplatesForBulkEdit_currentOrganization_jobRoleGuideTemplates_jobRole_atsJob_atsJobStages[];
}

export interface JobRoleGuideTemplatesForBulkEdit_currentOrganization_jobRoleGuideTemplates_jobRole {
  __typename: "JobRole";
  id: string;
  atsJob: JobRoleGuideTemplatesForBulkEdit_currentOrganization_jobRoleGuideTemplates_jobRole_atsJob | null;
}

export interface JobRoleGuideTemplatesForBulkEdit_currentOrganization_jobRoleGuideTemplates {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  stageTemplateInstallations: JobRoleGuideTemplatesForBulkEdit_currentOrganization_jobRoleGuideTemplates_stageTemplateInstallations[];
  jobRole: JobRoleGuideTemplatesForBulkEdit_currentOrganization_jobRoleGuideTemplates_jobRole | null;
  updatedAt: GraphQL_DateTime;
}

export interface JobRoleGuideTemplatesForBulkEdit_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplates: JobRoleGuideTemplatesForBulkEdit_currentOrganization_jobRoleGuideTemplates[];
}

export interface JobRoleGuideTemplatesForBulkEdit {
  currentOrganization: JobRoleGuideTemplatesForBulkEdit_currentOrganization | null;
}

export interface JobRoleGuideTemplatesForBulkEditVariables {
  where?: OrganizationJobRoleGuideTemplateWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetTimezoneOfCandidate
// ====================================================

export interface SetTimezoneOfCandidate_setTimezoneOfCandidate_candidate {
  __typename: "Candidate";
  id: string;
  timezone: string | null;
}

export interface SetTimezoneOfCandidate_setTimezoneOfCandidate {
  __typename: "SetTimezoneOfCandidateResponse";
  success: boolean;
  code: string;
  message: string;
  candidate: SetTimezoneOfCandidate_setTimezoneOfCandidate_candidate | null;
}

export interface SetTimezoneOfCandidate {
  setTimezoneOfCandidate: SetTimezoneOfCandidate_setTimezoneOfCandidate;
}

export interface SetTimezoneOfCandidateVariables {
  input: SetTimezoneOfCandidateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContentTemplatesPaginated
// ====================================================

export interface ContentTemplatesPaginated_currentUserPrisma_currentOrganization_contentTemplates_creator {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface ContentTemplatesPaginated_currentUserPrisma_currentOrganization_contentTemplates {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  archivedAt: GraphQL_DateTime | null;
  status: StageTemplateStatusEnum;
  creator: ContentTemplatesPaginated_currentUserPrisma_currentOrganization_contentTemplates_creator | null;
}

export interface ContentTemplatesPaginated_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  contentTemplates: ContentTemplatesPaginated_currentUserPrisma_currentOrganization_contentTemplates[];
}

export interface ContentTemplatesPaginated_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: ContentTemplatesPaginated_currentUserPrisma_currentOrganization | null;
}

export interface ContentTemplatesPaginated {
  currentUserPrisma: ContentTemplatesPaginated_currentUserPrisma | null;
}

export interface ContentTemplatesPaginatedVariables {
  search?: string | null;
  offset?: number | null;
  limit?: number | null;
  status?: ContentTemplateStatusEnum | null;
  orderBy?: OrganizationContentTemplatesOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllOrganizationContentTemplateIds
// ====================================================

export interface AllOrganizationContentTemplateIds_currentUserPrisma_currentOrganization_contentTemplates {
  __typename: "ContentTemplate";
  id: string;
}

export interface AllOrganizationContentTemplateIds_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  contentTemplates: AllOrganizationContentTemplateIds_currentUserPrisma_currentOrganization_contentTemplates[];
}

export interface AllOrganizationContentTemplateIds_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: AllOrganizationContentTemplateIds_currentUserPrisma_currentOrganization | null;
}

export interface AllOrganizationContentTemplateIds {
  currentUserPrisma: AllOrganizationContentTemplateIds_currentUserPrisma | null;
}

export interface AllOrganizationContentTemplateIdsVariables {
  search?: string | null;
  status?: ContentTemplateStatusEnum | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveContentTemplateFromTable
// ====================================================

export interface ArchiveContentTemplateFromTable_archiveContentTemplate_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  archivedAt: GraphQL_DateTime | null;
}

export interface ArchiveContentTemplateFromTable_archiveContentTemplate {
  __typename: "ArchiveContentTemplateResponse";
  success: boolean;
  code: string;
  message: string;
  contentTemplate: ArchiveContentTemplateFromTable_archiveContentTemplate_contentTemplate | null;
}

export interface ArchiveContentTemplateFromTable {
  archiveContentTemplate: ArchiveContentTemplateFromTable_archiveContentTemplate;
}

export interface ArchiveContentTemplateFromTableVariables {
  input: ArchiveContentTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnarchiveContentTemplateFromTable
// ====================================================

export interface UnarchiveContentTemplateFromTable_unarchiveContentTemplate_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  archivedAt: GraphQL_DateTime | null;
}

export interface UnarchiveContentTemplateFromTable_unarchiveContentTemplate {
  __typename: "UnarchiveContentTemplateResponse";
  success: boolean;
  code: string;
  message: string;
  contentTemplate: UnarchiveContentTemplateFromTable_unarchiveContentTemplate_contentTemplate | null;
}

export interface UnarchiveContentTemplateFromTable {
  unarchiveContentTemplate: UnarchiveContentTemplateFromTable_unarchiveContentTemplate;
}

export interface UnarchiveContentTemplateFromTableVariables {
  input: UnarchiveContentTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateContentTemplateInModal
// ====================================================

export interface CreateContentTemplateInModal_createContentTemplate_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
}

export interface CreateContentTemplateInModal_createContentTemplate {
  __typename: "CreateContentTemplateResponse";
  message: string;
  success: boolean;
  code: string;
  contentTemplate: CreateContentTemplateInModal_createContentTemplate_contentTemplate | null;
}

export interface CreateContentTemplateInModal {
  createContentTemplate: CreateContentTemplateInModal_createContentTemplate;
}

export interface CreateContentTemplateInModalVariables {
  input: CreateContentTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CreateGuideDetailsQuery
// ====================================================

export interface CreateGuideDetailsQuery_guideFindUnique_jobRoleGuideTemplate_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  position: number;
}

export interface CreateGuideDetailsQuery_guideFindUnique_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  jobRoleName: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleGuideTemplateStages: CreateGuideDetailsQuery_guideFindUnique_jobRoleGuideTemplate_jobRoleGuideTemplateStages[];
}

export interface CreateGuideDetailsQuery_guideFindUnique_candidate {
  __typename: "Candidate";
  firstName: string;
  lastName: string;
  imageUrl: string;
  emailAddresses: string[];
  id: string;
  name: string;
}

export interface CreateGuideDetailsQuery_guideFindUnique_stages_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface CreateGuideDetailsQuery_guideFindUnique_stages_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
}

export interface CreateGuideDetailsQuery_guideFindUnique_stages {
  __typename: "Stage";
  id: string;
  configured: boolean;
  title: string;
  status: StageStatus;
  isAtsCurrent: boolean;
  hidden: boolean;
  hasAutomation: boolean;
  position: number;
  atsStage: CreateGuideDetailsQuery_guideFindUnique_stages_atsStage | null;
  guide: CreateGuideDetailsQuery_guideFindUnique_stages_guide;
}

export interface CreateGuideDetailsQuery_guideFindUnique_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  hasAutomation: boolean;
  configured: boolean;
  title: string;
  status: StageStatus;
  position: number;
}

export interface CreateGuideDetailsQuery_guideFindUnique {
  __typename: "Guide";
  id: string;
  publicUrl: string;
  jobRoleGuideTemplate: CreateGuideDetailsQuery_guideFindUnique_jobRoleGuideTemplate | null;
  candidate: CreateGuideDetailsQuery_guideFindUnique_candidate;
  guideVersion: number;
  stages: CreateGuideDetailsQuery_guideFindUnique_stages[];
  status: GuideStatusEnum;
  sortedVisibleStages: CreateGuideDetailsQuery_guideFindUnique_sortedVisibleStages[];
}

export interface CreateGuideDetailsQuery {
  guideFindUnique: CreateGuideDetailsQuery_guideFindUnique;
}

export interface CreateGuideDetailsQueryVariables {
  where: GuideWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGuideStartGuideMutation
// ====================================================

export interface CreateGuideStartGuideMutation_startGuide_guide {
  __typename: "Guide";
  id: string;
  guideVersion: number;
  status: GuideStatusEnum;
}

export interface CreateGuideStartGuideMutation_startGuide {
  __typename: "StartGuideMutationResponse";
  success: boolean;
  message: string;
  guide: CreateGuideStartGuideMutation_startGuide_guide | null;
}

export interface CreateGuideStartGuideMutation {
  startGuide: CreateGuideStartGuideMutation_startGuide;
}

export interface CreateGuideStartGuideMutationVariables {
  input: StartGuideInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGuideMutation
// ====================================================

export interface CreateGuideMutation_startGuide_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
  publicUrl: string;
  guideVersion: number;
}

export interface CreateGuideMutation_startGuide {
  __typename: "StartGuideMutationResponse";
  success: boolean;
  message: string;
  guide: CreateGuideMutation_startGuide_guide | null;
}

export interface CreateGuideMutation {
  startGuide: CreateGuideMutation_startGuide;
}

export interface CreateGuideMutationVariables {
  input: StartGuideInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SendGuideDetailsQuery
// ====================================================

export interface SendGuideDetailsQuery_guideFindUnique_currentStage_stageTemplateInstallation_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
  isArchived: boolean;
}

export interface SendGuideDetailsQuery_guideFindUnique_currentStage_stageTemplateInstallation {
  __typename: "StageTemplateInstallation";
  id: string;
  emailActionCC: string[];
  emailActionBCC: string[];
  emailTemplate: SendGuideDetailsQuery_guideFindUnique_currentStage_stageTemplateInstallation_emailTemplate | null;
}

export interface SendGuideDetailsQuery_guideFindUnique_currentStage_jobRoleGuideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
  isArchived: boolean;
}

export interface SendGuideDetailsQuery_guideFindUnique_currentStage_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  emailRecipientsCC: string[];
  emailRecipientsBCC: string[];
  emailTemplate: SendGuideDetailsQuery_guideFindUnique_currentStage_jobRoleGuideTemplateStage_emailTemplate | null;
}

export interface SendGuideDetailsQuery_guideFindUnique_currentStage {
  __typename: "Stage";
  id: string;
  stageTemplateInstallation: SendGuideDetailsQuery_guideFindUnique_currentStage_stageTemplateInstallation | null;
  jobRoleGuideTemplateStage: SendGuideDetailsQuery_guideFindUnique_currentStage_jobRoleGuideTemplateStage | null;
  status: StageStatus;
}

export interface SendGuideDetailsQuery_guideFindUnique_mostRecentEmailActivity {
  __typename: "EmailActivity";
  id: string;
  sentTo: string;
}

export interface SendGuideDetailsQuery_guideFindUnique_candidate_organization_recipients {
  __typename: "Recipient";
  id: GraphQL_UUID;
  name: string;
  email: string;
}

export interface SendGuideDetailsQuery_guideFindUnique_candidate_organization {
  __typename: "Organization";
  id: string;
  customVariableOptions: string[];
  recipients: SendGuideDetailsQuery_guideFindUnique_candidate_organization_recipients[];
}

export interface SendGuideDetailsQuery_guideFindUnique_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  emailAddresses: string[];
  currentTimezone: string;
  organization: SendGuideDetailsQuery_guideFindUnique_candidate_organization;
}

export interface SendGuideDetailsQuery_guideFindUnique_sendGuideReplacementData_allInterviewers {
  __typename: "InterviewerReplacementData";
  title: string;
  firstName: string;
  lastName: string;
}

export interface SendGuideDetailsQuery_guideFindUnique_sendGuideReplacementData_sortedVisibleUpcomingEvents_interviewers {
  __typename: "InterviewerReplacementData";
  title: string;
  firstName: string;
  lastName: string;
}

export interface SendGuideDetailsQuery_guideFindUnique_sendGuideReplacementData_sortedVisibleUpcomingEvents {
  __typename: "EventReplacementData";
  startTime: string;
  endTime: string;
  title: string;
  hidden: boolean;
  interviewers: SendGuideDetailsQuery_guideFindUnique_sendGuideReplacementData_sortedVisibleUpcomingEvents_interviewers[];
}

export interface SendGuideDetailsQuery_guideFindUnique_sendGuideReplacementData {
  __typename: "SendGuideReplacementData";
  candidateFirstName: string;
  candidateFullName: string;
  companyName: string;
  jobRoleName: string;
  recruiterFirstName: string;
  recruiterFullName: string;
  recruiterEmailAddress: string;
  coordinatorFirstName: string;
  coordinatorFullName: string;
  coordinatorEmailAddress: string;
  guideUrl: string;
  office: string;
  greenhouseJobUrl: string;
  allInterviewers: SendGuideDetailsQuery_guideFindUnique_sendGuideReplacementData_allInterviewers[];
  senderName: string;
  senderFirstName: string;
  sortedVisibleUpcomingEvents: SendGuideDetailsQuery_guideFindUnique_sendGuideReplacementData_sortedVisibleUpcomingEvents[];
  stageCustomVariables: GraphQL_JSON;
  timezone: string;
}

export interface SendGuideDetailsQuery_guideFindUnique_icalAttachedFiles {
  __typename: "AttachedFile";
  id: GraphQL_UUID;
  filename: string;
}

export interface SendGuideDetailsQuery_guideFindUnique {
  __typename: "Guide";
  id: string;
  currentStage: SendGuideDetailsQuery_guideFindUnique_currentStage | null;
  mostRecentEmailActivity: SendGuideDetailsQuery_guideFindUnique_mostRecentEmailActivity[];
  candidate: SendGuideDetailsQuery_guideFindUnique_candidate;
  sendGuideReplacementData: SendGuideDetailsQuery_guideFindUnique_sendGuideReplacementData;
  status: GuideStatusEnum;
  guideVersion: number;
  publicUrl: string;
  icalAttachedFiles: SendGuideDetailsQuery_guideFindUnique_icalAttachedFiles[] | null;
}

export interface SendGuideDetailsQuery_currentUser {
  __typename: "User";
  id: string;
  primaryEmail: string | null;
  sendAsAddresses: string[];
}

export interface SendGuideDetailsQuery {
  guideFindUnique: SendGuideDetailsQuery_guideFindUnique;
  currentUser: SendGuideDetailsQuery_currentUser | null;
}

export interface SendGuideDetailsQueryVariables {
  where: GuideWhereUniqueInput;
  stageId?: GraphQL_UUID | null;
  sendTo?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchDepartmentsForDepartmentSelect
// ====================================================

export interface FetchDepartmentsForDepartmentSelect_atsDepartments {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface FetchDepartmentsForDepartmentSelect {
  atsDepartments: FetchDepartmentsForDepartmentSelect_atsDepartments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEvent
// ====================================================

export interface UpdateEvent_updateEvent_event {
  __typename: "Event";
  id: string;
}

export interface UpdateEvent_updateEvent {
  __typename: "UpdateEventResponse";
  success: boolean;
  code: string;
  message: string;
  event: UpdateEvent_updateEvent_event;
}

export interface UpdateEvent {
  updateEvent: UpdateEvent_updateEvent;
}

export interface UpdateEventVariables {
  input: UpdateEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetParticipantBio
// ====================================================

export interface SetParticipantBio_updateInterviewerBio_interviewer {
  __typename: "Interviewer";
  id: string;
  biography: string;
}

export interface SetParticipantBio_updateInterviewerBio {
  __typename: "UpdateInterviewerBioMutationResponse";
  success: boolean;
  interviewer: SetParticipantBio_updateInterviewerBio_interviewer | null;
}

export interface SetParticipantBio {
  updateInterviewerBio: SetParticipantBio_updateInterviewerBio;
}

export interface SetParticipantBioVariables {
  input: UpdateInterviewerBioInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetParticipantName
// ====================================================

export interface SetParticipantName_updateInterviewerName_interviewer {
  __typename: "Interviewer";
  id: string;
  imageUrl: string;
  name: string;
}

export interface SetParticipantName_updateInterviewerName {
  __typename: "UpdateInterviewerNameMutationResponse";
  success: boolean;
  interviewer: SetParticipantName_updateInterviewerName_interviewer | null;
}

export interface SetParticipantName {
  updateInterviewerName: SetParticipantName_updateInterviewerName;
}

export interface SetParticipantNameVariables {
  input: UpdateInterviewerNameInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetParticipantImageUrl
// ====================================================

export interface SetParticipantImageUrl_updateInterviewerImageUrl_interviewer {
  __typename: "Interviewer";
  id: string;
  imageUrl: string;
  name: string;
}

export interface SetParticipantImageUrl_updateInterviewerImageUrl {
  __typename: "UpdateInterviewerImageUrlMutationResponse";
  success: boolean;
  interviewer: SetParticipantImageUrl_updateInterviewerImageUrl_interviewer | null;
}

export interface SetParticipantImageUrl {
  updateInterviewerImageUrl: SetParticipantImageUrl_updateInterviewerImageUrl;
}

export interface SetParticipantImageUrlVariables {
  input: UpdateInterviewerImageUrlInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetParticipantLinks
// ====================================================

export interface SetParticipantLinks_updateInterviewerUrls_interviewer {
  __typename: "Interviewer";
  id: string;
  websiteUrls: string[];
}

export interface SetParticipantLinks_updateInterviewerUrls {
  __typename: "UpdateInterviewerUrlsMutationResponse";
  success: boolean;
  interviewer: SetParticipantLinks_updateInterviewerUrls_interviewer | null;
}

export interface SetParticipantLinks {
  updateInterviewerUrls: SetParticipantLinks_updateInterviewerUrls;
}

export interface SetParticipantLinksVariables {
  input: UpdateInterviewerUrlsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetParticipantPronouns
// ====================================================

export interface SetParticipantPronouns_updatePersonalProfilePronouns_personalProfile {
  __typename: "Interviewer";
  id: string;
  pronoun: string | null;
}

export interface SetParticipantPronouns_updatePersonalProfilePronouns {
  __typename: "UpdatePersonalProfilePronounsMutationResponse";
  success: boolean;
  message: string;
  personalProfile: SetParticipantPronouns_updatePersonalProfilePronouns_personalProfile | null;
}

export interface SetParticipantPronouns {
  updatePersonalProfilePronouns: SetParticipantPronouns_updatePersonalProfilePronouns;
}

export interface SetParticipantPronounsVariables {
  input: UpdatePersonalProfilePronounsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetParticipantTitle
// ====================================================

export interface SetParticipantTitle_updateInterviewerTitle_interviewer {
  __typename: "Interviewer";
  id: string;
  title: string;
}

export interface SetParticipantTitle_updateInterviewerTitle {
  __typename: "UpdateInterviewerTitleMutationResponse";
  success: boolean;
  interviewer: SetParticipantTitle_updateInterviewerTitle_interviewer | null;
}

export interface SetParticipantTitle {
  updateInterviewerTitle: SetParticipantTitle_updateInterviewerTitle;
}

export interface SetParticipantTitleVariables {
  input: UpdateInterviewerTitleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetParticipantVideoIntroduction
// ====================================================

export interface SetParticipantVideoIntroduction_updatePersonalProfileVideoIntroductionUrl_personalProfile {
  __typename: "Interviewer";
  id: string;
  videoIntroductionUrl: string | null;
}

export interface SetParticipantVideoIntroduction_updatePersonalProfileVideoIntroductionUrl {
  __typename: "UpdatePersonalProfileVideoIntroductionUrlMutationResponse";
  success: boolean;
  personalProfile: SetParticipantVideoIntroduction_updatePersonalProfileVideoIntroductionUrl_personalProfile | null;
}

export interface SetParticipantVideoIntroduction {
  updatePersonalProfileVideoIntroductionUrl: SetParticipantVideoIntroduction_updatePersonalProfileVideoIntroductionUrl;
}

export interface SetParticipantVideoIntroductionVariables {
  input: UpdatePersonalProfileVideoIntroductionUrlInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePersonalProfile
// ====================================================

export interface UpdatePersonalProfile_updatePersonalProfile_personalProfile {
  __typename: "Interviewer";
  id: string;
  email: string | null;
  name: string;
  title: string;
  displayTitle: string;
  biography: string;
  pronoun: string | null;
  websiteUrls: string[];
  imageUrl: string;
  videoIntroductionUrl: string | null;
}

export interface UpdatePersonalProfile_updatePersonalProfile {
  __typename: "UpdatePersonalProfileMutationResponse";
  success: boolean;
  message: string;
  personalProfile: UpdatePersonalProfile_updatePersonalProfile_personalProfile | null;
}

export interface UpdatePersonalProfile {
  updatePersonalProfile: UpdatePersonalProfile_updatePersonalProfile;
}

export interface UpdatePersonalProfileVariables {
  input: UpdatePersonalProfileInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProfile
// ====================================================

export interface GetProfile_personalProfileFindUnique {
  __typename: "Interviewer";
  id: string;
  email: string | null;
  name: string;
  title: string;
  displayTitle: string;
  biography: string;
  pronoun: string | null;
  websiteUrls: string[];
  imageUrl: string;
  videoIntroductionUrl: string | null;
}

export interface GetProfile {
  personalProfileFindUnique: GetProfile_personalProfileFindUnique;
}

export interface GetProfileVariables {
  profileId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EmailTemplatesForOrg
// ====================================================

export interface EmailTemplatesForOrg_currentUserPrisma_currentOrganization_emailTemplates {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
  isArchived: boolean;
}

export interface EmailTemplatesForOrg_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  emailTemplates: EmailTemplatesForOrg_currentUserPrisma_currentOrganization_emailTemplates[];
}

export interface EmailTemplatesForOrg_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: EmailTemplatesForOrg_currentUserPrisma_currentOrganization | null;
}

export interface EmailTemplatesForOrg {
  currentUserPrisma: EmailTemplatesForOrg_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EmailTemplatesPaginated
// ====================================================

export interface EmailTemplatesPaginated_currentUserPrisma_currentOrganization_emailTemplates_creator {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface EmailTemplatesPaginated_currentUserPrisma_currentOrganization_emailTemplates {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  archivedAt: GraphQL_DateTime | null;
  status: EmailTemplateStatusEnum;
  subject: string;
  body: string;
  creator: EmailTemplatesPaginated_currentUserPrisma_currentOrganization_emailTemplates_creator | null;
}

export interface EmailTemplatesPaginated_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  emailTemplates: EmailTemplatesPaginated_currentUserPrisma_currentOrganization_emailTemplates[];
}

export interface EmailTemplatesPaginated_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: EmailTemplatesPaginated_currentUserPrisma_currentOrganization | null;
}

export interface EmailTemplatesPaginated {
  currentUserPrisma: EmailTemplatesPaginated_currentUserPrisma | null;
}

export interface EmailTemplatesPaginatedVariables {
  search?: string | null;
  status?: EmailTemplateStatusEnum | null;
  offset?: number | null;
  limit?: number | null;
  orderBy?: EmailTemplateOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllOrganizationEmailTemplateIds
// ====================================================

export interface AllOrganizationEmailTemplateIds_currentUserPrisma_currentOrganization_emailTemplates {
  __typename: "EmailTemplate";
  id: string;
}

export interface AllOrganizationEmailTemplateIds_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  emailTemplates: AllOrganizationEmailTemplateIds_currentUserPrisma_currentOrganization_emailTemplates[];
}

export interface AllOrganizationEmailTemplateIds_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: AllOrganizationEmailTemplateIds_currentUserPrisma_currentOrganization | null;
}

export interface AllOrganizationEmailTemplateIds {
  currentUserPrisma: AllOrganizationEmailTemplateIds_currentUserPrisma | null;
}

export interface AllOrganizationEmailTemplateIdsVariables {
  search?: string | null;
  status?: EmailTemplateStatusEnum | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveEmailTemplateFromTable
// ====================================================

export interface ArchiveEmailTemplateFromTable_archiveEmailTemplate_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  archivedAt: GraphQL_DateTime | null;
}

export interface ArchiveEmailTemplateFromTable_archiveEmailTemplate {
  __typename: "ArchiveEmailTemplateMutationResponse";
  success: boolean;
  code: string;
  message: string;
  emailTemplate: ArchiveEmailTemplateFromTable_archiveEmailTemplate_emailTemplate | null;
}

export interface ArchiveEmailTemplateFromTable {
  archiveEmailTemplate: ArchiveEmailTemplateFromTable_archiveEmailTemplate;
}

export interface ArchiveEmailTemplateFromTableVariables {
  input: ArchiveEmailTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnarchiveEmailTemplateFromTable
// ====================================================

export interface UnarchiveEmailTemplateFromTable_unarchiveEmailTemplate_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  archivedAt: GraphQL_DateTime | null;
}

export interface UnarchiveEmailTemplateFromTable_unarchiveEmailTemplate {
  __typename: "UnarchiveEmailTemplateMutationResponse";
  success: boolean;
  code: string;
  message: string;
  emailTemplate: UnarchiveEmailTemplateFromTable_unarchiveEmailTemplate_emailTemplate | null;
}

export interface UnarchiveEmailTemplateFromTable {
  unarchiveEmailTemplate: UnarchiveEmailTemplateFromTable_unarchiveEmailTemplate;
}

export interface UnarchiveEmailTemplateFromTableVariables {
  input: UnarchiveEmailTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RestoreEventTemplateInstallationFromBanner
// ====================================================

export interface RestoreEventTemplateInstallationFromBanner_restoreEventTemplateInstallationDetails_eventTemplateInstallation_event {
  __typename: "Event";
  id: string;
  displayTitle: string;
  displayDescription: string;
}

export interface RestoreEventTemplateInstallationFromBanner_restoreEventTemplateInstallationDetails_eventTemplateInstallation {
  __typename: "EventTemplateInstallation";
  id: string;
  title: string;
  description: string;
  templateTitle: string;
  templateDescription: string;
  isOverridden: boolean;
  event: RestoreEventTemplateInstallationFromBanner_restoreEventTemplateInstallationDetails_eventTemplateInstallation_event | null;
}

export interface RestoreEventTemplateInstallationFromBanner_restoreEventTemplateInstallationDetails {
  __typename: "RestoreEventTemplateInstallationDetailsMutationResponse";
  success: boolean;
  message: string;
  eventTemplateInstallation: RestoreEventTemplateInstallationFromBanner_restoreEventTemplateInstallationDetails_eventTemplateInstallation | null;
}

export interface RestoreEventTemplateInstallationFromBanner {
  restoreEventTemplateInstallationDetails: RestoreEventTemplateInstallationFromBanner_restoreEventTemplateInstallationDetails | null;
}

export interface RestoreEventTemplateInstallationFromBannerVariables {
  input: RestoreEventTemplateInstallationDetailsMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShowEventFromBanner
// ====================================================

export interface ShowEventFromBanner_showEvent_event {
  __typename: "Event";
  id: string;
  hidden: boolean;
}

export interface ShowEventFromBanner_showEvent {
  __typename: "ShowEventMutationResponse";
  success: boolean;
  event: ShowEventFromBanner_showEvent_event | null;
}

export interface ShowEventFromBanner {
  showEvent: ShowEventFromBanner_showEvent;
}

export interface ShowEventFromBannerVariables {
  input: ShowEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEventTitle
// ====================================================

export interface UpdateEventTitle_updateEventTitle_event {
  __typename: "Event";
  id: string;
  rawTitle: string;
  replacementData: GraphQL_JSON;
  displayTitle: string;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
}

export interface UpdateEventTitle_updateEventTitle {
  __typename: "UpdateEventTitleMutationResponse";
  success: boolean;
  event: UpdateEventTitle_updateEventTitle_event | null;
}

export interface UpdateEventTitle {
  updateEventTitle: UpdateEventTitle_updateEventTitle | null;
}

export interface UpdateEventTitleVariables {
  input: UpdateEventTitleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEventConferenceUrl
// ====================================================

export interface UpdateEventConferenceUrl_updateEventConferenceUrl_event {
  __typename: "Event";
  id: string;
  conferenceUrl: string | null;
}

export interface UpdateEventConferenceUrl_updateEventConferenceUrl {
  __typename: "UpdateEventConferenceUrlMutationResponse";
  success: boolean;
  event: UpdateEventConferenceUrl_updateEventConferenceUrl_event | null;
}

export interface UpdateEventConferenceUrl {
  updateEventConferenceUrl: UpdateEventConferenceUrl_updateEventConferenceUrl | null;
}

export interface UpdateEventConferenceUrlVariables {
  input: UpdateEventConferenceUrlInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEventDescription
// ====================================================

export interface UpdateEventDescription_updateEventDescription_event {
  __typename: "Event";
  id: string;
  displayDescription: string;
  replacementData: GraphQL_JSON;
}

export interface UpdateEventDescription_updateEventDescription {
  __typename: "UpdateEventDescriptionMutationResponse";
  success: boolean;
  event: UpdateEventDescription_updateEventDescription_event | null;
}

export interface UpdateEventDescription {
  updateEventDescription: UpdateEventDescription_updateEventDescription | null;
}

export interface UpdateEventDescriptionVariables {
  input: UpdateEventDescriptionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEventLocation
// ====================================================

export interface UpdateEventLocation_updateEventLocation_event_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface UpdateEventLocation_updateEventLocation_event {
  __typename: "Event";
  id: string;
  location: UpdateEventLocation_updateEventLocation_event_location | null;
}

export interface UpdateEventLocation_updateEventLocation {
  __typename: "UpdateEventLocationMutationResponse";
  success: boolean;
  event: UpdateEventLocation_updateEventLocation_event | null;
}

export interface UpdateEventLocation {
  updateEventLocation: UpdateEventLocation_updateEventLocation | null;
}

export interface UpdateEventLocationVariables {
  input: UpdateEventLocationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEventConferencePhone
// ====================================================

export interface UpdateEventConferencePhone_updateEventConferencePhone_event {
  __typename: "Event";
  id: string;
  conferencePhone: string | null;
}

export interface UpdateEventConferencePhone_updateEventConferencePhone {
  __typename: "UpdateEventConferencePhoneMutationResponse";
  success: boolean;
  event: UpdateEventConferencePhone_updateEventConferencePhone_event | null;
}

export interface UpdateEventConferencePhone {
  updateEventConferencePhone: UpdateEventConferencePhone_updateEventConferencePhone | null;
}

export interface UpdateEventConferencePhoneVariables {
  input: UpdateEventConferencePhoneInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetProminentInstructions
// ====================================================

export interface SetProminentInstructions_updateEventProminentInstructions_event_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  label: string;
}

export interface SetProminentInstructions_updateEventProminentInstructions_event {
  __typename: "Event";
  id: string;
  conferenceUrl: string | null;
  prominentInstructions: SetProminentInstructions_updateEventProminentInstructions_event_prominentInstructions[];
}

export interface SetProminentInstructions_updateEventProminentInstructions {
  __typename: "UpdateEventProminentInstructionsMutationResponse";
  success: boolean;
  event: SetProminentInstructions_updateEventProminentInstructions_event | null;
}

export interface SetProminentInstructions {
  updateEventProminentInstructions: SetProminentInstructions_updateEventProminentInstructions | null;
}

export interface SetProminentInstructionsVariables {
  input: UpdateEventProminentInstructionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShowEventInterviewerFromBanner
// ====================================================

export interface ShowEventInterviewerFromBanner_showEventInterviewer_eventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
}

export interface ShowEventInterviewerFromBanner_showEventInterviewer {
  __typename: "ShowEventInterviewerMutationResponse";
  success: boolean;
  message: string;
  eventInterviewer: ShowEventInterviewerFromBanner_showEventInterviewer_eventInterviewer | null;
}

export interface ShowEventInterviewerFromBanner {
  showEventInterviewer: ShowEventInterviewerFromBanner_showEventInterviewer;
}

export interface ShowEventInterviewerFromBannerVariables {
  input: ShowEventInterviewerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEventTemplateInstallationTitleOverride
// ====================================================

export interface UpdateEventTemplateInstallationTitleOverride_updateEventTemplateInstallationTitle_eventTemplateInstallation_event {
  __typename: "Event";
  id: string;
  hidden: boolean;
  conferenceUrl: string | null;
}

export interface UpdateEventTemplateInstallationTitleOverride_updateEventTemplateInstallationTitle_eventTemplateInstallation {
  __typename: "EventTemplateInstallation";
  id: string;
  title: string;
  replacementData: GraphQL_JSON;
  hidden: boolean;
  event: UpdateEventTemplateInstallationTitleOverride_updateEventTemplateInstallationTitle_eventTemplateInstallation_event | null;
}

export interface UpdateEventTemplateInstallationTitleOverride_updateEventTemplateInstallationTitle {
  __typename: "UpdateEventTemplateInstallationTitleMutationResponse";
  success: boolean;
  eventTemplateInstallation: UpdateEventTemplateInstallationTitleOverride_updateEventTemplateInstallationTitle_eventTemplateInstallation | null;
}

export interface UpdateEventTemplateInstallationTitleOverride {
  updateEventTemplateInstallationTitle: UpdateEventTemplateInstallationTitleOverride_updateEventTemplateInstallationTitle | null;
}

export interface UpdateEventTemplateInstallationTitleOverrideVariables {
  input: UpdateEventTemplateInstallationTitleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEventTemplateInstallationDescriptionOverride
// ====================================================

export interface UpdateEventTemplateInstallationDescriptionOverride_updateEventTemplateInstallationDescription_eventTemplateInstallation {
  __typename: "EventTemplateInstallation";
  id: string;
  description: string;
}

export interface UpdateEventTemplateInstallationDescriptionOverride_updateEventTemplateInstallationDescription {
  __typename: "UpdateEventTemplateInstallationDescriptionMutationResponse";
  success: boolean;
  eventTemplateInstallation: UpdateEventTemplateInstallationDescriptionOverride_updateEventTemplateInstallationDescription_eventTemplateInstallation | null;
}

export interface UpdateEventTemplateInstallationDescriptionOverride {
  updateEventTemplateInstallationDescription: UpdateEventTemplateInstallationDescriptionOverride_updateEventTemplateInstallationDescription | null;
}

export interface UpdateEventTemplateInstallationDescriptionOverrideVariables {
  input: UpdateEventTemplateInstallationDescriptionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveEventTemplateInstallationScheduler
// ====================================================

export interface RemoveEventTemplateInstallationScheduler_removeEventTemplateInstallationScheduler_eventTemplateInstallation_schedulerConfig_availability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
  slug: string;
}

export interface RemoveEventTemplateInstallationScheduler_removeEventTemplateInstallationScheduler_eventTemplateInstallation_schedulerConfig_interviewers {
  __typename: "Interviewer";
  id: string;
  name: string;
}

export interface RemoveEventTemplateInstallationScheduler_removeEventTemplateInstallationScheduler_eventTemplateInstallation_schedulerConfig {
  __typename: "EventTemplateInstallationSchedulerConfig";
  availability: RemoveEventTemplateInstallationScheduler_removeEventTemplateInstallationScheduler_eventTemplateInstallation_schedulerConfig_availability;
  interviewers: RemoveEventTemplateInstallationScheduler_removeEventTemplateInstallationScheduler_eventTemplateInstallation_schedulerConfig_interviewers[];
}

export interface RemoveEventTemplateInstallationScheduler_removeEventTemplateInstallationScheduler_eventTemplateInstallation {
  __typename: "EventTemplateInstallation";
  id: string;
  schedulerConfig: RemoveEventTemplateInstallationScheduler_removeEventTemplateInstallationScheduler_eventTemplateInstallation_schedulerConfig | null;
}

export interface RemoveEventTemplateInstallationScheduler_removeEventTemplateInstallationScheduler {
  __typename: "RemoveEventTemplateInstallationSchedulerMutationResponse";
  success: boolean;
  message: string;
  eventTemplateInstallation: RemoveEventTemplateInstallationScheduler_removeEventTemplateInstallationScheduler_eventTemplateInstallation | null;
}

export interface RemoveEventTemplateInstallationScheduler {
  removeEventTemplateInstallationScheduler: RemoveEventTemplateInstallationScheduler_removeEventTemplateInstallationScheduler | null;
}

export interface RemoveEventTemplateInstallationSchedulerVariables {
  eventTemplateInstallationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEventTemplateInstallationScheduler
// ====================================================

export interface UpdateEventTemplateInstallationScheduler_updateEventTemplateInstallationScheduler_eventTemplateInstallation_schedulerConfig_availability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
  slug: string;
}

export interface UpdateEventTemplateInstallationScheduler_updateEventTemplateInstallationScheduler_eventTemplateInstallation_schedulerConfig_interviewers {
  __typename: "Interviewer";
  id: string;
  name: string;
}

export interface UpdateEventTemplateInstallationScheduler_updateEventTemplateInstallationScheduler_eventTemplateInstallation_schedulerConfig {
  __typename: "EventTemplateInstallationSchedulerConfig";
  availability: UpdateEventTemplateInstallationScheduler_updateEventTemplateInstallationScheduler_eventTemplateInstallation_schedulerConfig_availability;
  interviewers: UpdateEventTemplateInstallationScheduler_updateEventTemplateInstallationScheduler_eventTemplateInstallation_schedulerConfig_interviewers[];
}

export interface UpdateEventTemplateInstallationScheduler_updateEventTemplateInstallationScheduler_eventTemplateInstallation {
  __typename: "EventTemplateInstallation";
  id: string;
  schedulerConfig: UpdateEventTemplateInstallationScheduler_updateEventTemplateInstallationScheduler_eventTemplateInstallation_schedulerConfig | null;
}

export interface UpdateEventTemplateInstallationScheduler_updateEventTemplateInstallationScheduler {
  __typename: "UpdateEventTemplateInstallationSchedulerMutationResponse";
  success: boolean;
  eventTemplateInstallation: UpdateEventTemplateInstallationScheduler_updateEventTemplateInstallationScheduler_eventTemplateInstallation | null;
}

export interface UpdateEventTemplateInstallationScheduler {
  updateEventTemplateInstallationScheduler: UpdateEventTemplateInstallationScheduler_updateEventTemplateInstallationScheduler | null;
}

export interface UpdateEventTemplateInstallationSchedulerVariables {
  eventTemplateInstallationId: string;
  availabilityId: string;
  interviewerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideEventSchedulerConfigData
// ====================================================

export interface GuideEventSchedulerConfigData_eventTemplateInstallationFindUnique_schedulerConfig_availability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
  slug: string;
}

export interface GuideEventSchedulerConfigData_eventTemplateInstallationFindUnique_schedulerConfig_interviewers {
  __typename: "Interviewer";
  id: string;
  name: string;
}

export interface GuideEventSchedulerConfigData_eventTemplateInstallationFindUnique_schedulerConfig {
  __typename: "EventTemplateInstallationSchedulerConfig";
  availability: GuideEventSchedulerConfigData_eventTemplateInstallationFindUnique_schedulerConfig_availability;
  interviewers: GuideEventSchedulerConfigData_eventTemplateInstallationFindUnique_schedulerConfig_interviewers[];
}

export interface GuideEventSchedulerConfigData_eventTemplateInstallationFindUnique {
  __typename: "EventTemplateInstallation";
  id: string;
  schedulerConfig: GuideEventSchedulerConfigData_eventTemplateInstallationFindUnique_schedulerConfig | null;
}

export interface GuideEventSchedulerConfigData_currentUserPrisma_currentOrganization_schedulerAvailabilities {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface GuideEventSchedulerConfigData_currentUserPrisma_currentOrganization_interviewers {
  __typename: "Interviewer";
  id: string;
  name: string;
}

export interface GuideEventSchedulerConfigData_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  schedulerAvailabilities: GuideEventSchedulerConfigData_currentUserPrisma_currentOrganization_schedulerAvailabilities[];
  interviewers: GuideEventSchedulerConfigData_currentUserPrisma_currentOrganization_interviewers[];
}

export interface GuideEventSchedulerConfigData_currentUserPrisma {
  __typename: "User";
  id: string;
  hasConfiguredGoogleCalendar: boolean;
  currentOrganization: GuideEventSchedulerConfigData_currentUserPrisma_currentOrganization | null;
}

export interface GuideEventSchedulerConfigData {
  eventTemplateInstallationFindUnique: GuideEventSchedulerConfigData_eventTemplateInstallationFindUnique;
  currentUserPrisma: GuideEventSchedulerConfigData_currentUserPrisma | null;
}

export interface GuideEventSchedulerConfigDataVariables {
  eventTemplateInstallationId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShowEventFromMenu
// ====================================================

export interface ShowEventFromMenu_showEvent_event {
  __typename: "Event";
  id: string;
  hidden: boolean;
}

export interface ShowEventFromMenu_showEvent {
  __typename: "ShowEventMutationResponse";
  success: boolean;
  message: string;
  event: ShowEventFromMenu_showEvent_event | null;
}

export interface ShowEventFromMenu {
  showEvent: ShowEventFromMenu_showEvent;
}

export interface ShowEventFromMenuVariables {
  input: ShowEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: HideEvent
// ====================================================

export interface HideEvent_hideEvent_event {
  __typename: "Event";
  id: string;
  hidden: boolean;
}

export interface HideEvent_hideEvent {
  __typename: "HideEventMutationResponse";
  success: boolean;
  message: string;
  event: HideEvent_hideEvent_event | null;
}

export interface HideEvent {
  hideEvent: HideEvent_hideEvent;
}

export interface HideEventVariables {
  input: HideEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShowEventTemplateInstallation
// ====================================================

export interface ShowEventTemplateInstallation_showEventTemplateInstallation_eventTemplateInstallation {
  __typename: "EventTemplateInstallation";
  id: string;
  hidden: boolean;
}

export interface ShowEventTemplateInstallation_showEventTemplateInstallation {
  __typename: "ShowEventTemplateInstallationMutationResponse";
  success: boolean;
  message: string;
  eventTemplateInstallation: ShowEventTemplateInstallation_showEventTemplateInstallation_eventTemplateInstallation | null;
}

export interface ShowEventTemplateInstallation {
  showEventTemplateInstallation: ShowEventTemplateInstallation_showEventTemplateInstallation;
}

export interface ShowEventTemplateInstallationVariables {
  input: ShowEventTemplateInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: HideEventTemplateInstallation
// ====================================================

export interface HideEventTemplateInstallation_hideEventTemplateInstallation_eventTemplateInstallation {
  __typename: "EventTemplateInstallation";
  id: string;
  hidden: boolean;
}

export interface HideEventTemplateInstallation_hideEventTemplateInstallation {
  __typename: "HideEventTemplateInstallationMutationResponse";
  success: boolean;
  message: string;
  eventTemplateInstallation: HideEventTemplateInstallation_hideEventTemplateInstallation_eventTemplateInstallation | null;
}

export interface HideEventTemplateInstallation {
  hideEventTemplateInstallation: HideEventTemplateInstallation_hideEventTemplateInstallation;
}

export interface HideEventTemplateInstallationVariables {
  input: HideEventTemplateInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApplyInterviewTemplateToEventTemplateInstallation
// ====================================================

export interface ApplyInterviewTemplateToEventTemplateInstallation_applyInterviewTemplateToEventTemplateInstallation_eventTemplateInstallation {
  __typename: "EventTemplateInstallation";
  id: string;
  description: string;
  title: string;
  isOverridden: boolean;
}

export interface ApplyInterviewTemplateToEventTemplateInstallation_applyInterviewTemplateToEventTemplateInstallation {
  __typename: "ApplyInterviewTemplateToEventTemplateInstallationMutationResponse";
  success: boolean;
  message: string;
  eventTemplateInstallation: ApplyInterviewTemplateToEventTemplateInstallation_applyInterviewTemplateToEventTemplateInstallation_eventTemplateInstallation | null;
}

export interface ApplyInterviewTemplateToEventTemplateInstallation {
  applyInterviewTemplateToEventTemplateInstallation: ApplyInterviewTemplateToEventTemplateInstallation_applyInterviewTemplateToEventTemplateInstallation;
}

export interface ApplyInterviewTemplateToEventTemplateInstallationVariables {
  input: ApplyInterviewTemplateToEventTemplateInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApplyInterviewTemplateToEvent
// ====================================================

export interface ApplyInterviewTemplateToEvent_applyInterviewTemplateToEvent_event {
  __typename: "Event";
  id: string;
  displayTitle: string;
  displayDescription: string;
}

export interface ApplyInterviewTemplateToEvent_applyInterviewTemplateToEvent {
  __typename: "ApplyInterviewTemplateToEventMutationResponse";
  success: boolean;
  message: string;
  event: ApplyInterviewTemplateToEvent_applyInterviewTemplateToEvent_event | null;
}

export interface ApplyInterviewTemplateToEvent {
  applyInterviewTemplateToEvent: ApplyInterviewTemplateToEvent_applyInterviewTemplateToEvent;
}

export interface ApplyInterviewTemplateToEventVariables {
  input: ApplyInterviewTemplateToEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ActiveInterviewTemplatesForApplying
// ====================================================

export interface ActiveInterviewTemplatesForApplying_currentOrganization_interviewTemplates {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  title: GraphQL_JSON | null;
  description: GraphQL_JSON | null;
}

export interface ActiveInterviewTemplatesForApplying_currentOrganization {
  __typename: "Organization";
  interviewTemplates: ActiveInterviewTemplatesForApplying_currentOrganization_interviewTemplates[];
}

export interface ActiveInterviewTemplatesForApplying {
  currentOrganization: ActiveInterviewTemplatesForApplying_currentOrganization | null;
}

export interface ActiveInterviewTemplatesForApplyingVariables {
  where: OrganizationInterviewTemplateWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ExportCandidateFeedback
// ====================================================

export interface ExportCandidateFeedback_generateCandidateFeedbackExport {
  __typename: "GenerateCandidateFeedbackExport";
  success: boolean;
  url: string | null;
}

export interface ExportCandidateFeedback {
  generateCandidateFeedbackExport: ExportCandidateFeedback_generateCandidateFeedbackExport | null;
}

export interface ExportCandidateFeedbackVariables {
  input: GenerateCandidateFeedbackExportInputPrismaProxy;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendPremiumAddonRequestEmail
// ====================================================

export interface SendPremiumAddonRequestEmail_sendPremiumAddonRequestEmail {
  __typename: "SendPremiumAddonRequestEmailResponse";
  success: boolean;
}

export interface SendPremiumAddonRequestEmail {
  sendPremiumAddonRequestEmail: SendPremiumAddonRequestEmail_sendPremiumAddonRequestEmail;
}

export interface SendPremiumAddonRequestEmailVariables {
  input: SendPremiumAddonRequestEmailInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GreenhouseUsers
// ====================================================

export interface GreenhouseUsers_atsUsers {
  __typename: "ATSUser";
  id: string;
  name: string;
  primaryEmailAddress: string;
}

export interface GreenhouseUsers {
  atsUsers: GreenhouseUsers_atsUsers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideForSettings
// ====================================================

export interface GuideForSettings_guideFindUnique_candidate {
  __typename: "Candidate";
  id: string;
  email: string;
  emailAddresses: string[];
  primaryPhone: string | null;
  allPhones: string[];
}

export interface GuideForSettings_guideFindUnique_guideLexicalContext {
  __typename: "GuideLexicalContext";
  id: GraphQL_UUID;
}

export interface GuideForSettings_guideFindUnique {
  __typename: "Guide";
  id: string;
  candidate: GuideForSettings_guideFindUnique_candidate;
  guideLexicalContext: GuideForSettings_guideFindUnique_guideLexicalContext;
}

export interface GuideForSettings {
  guideFindUnique: GuideForSettings_guideFindUnique;
}

export interface GuideForSettingsVariables {
  where: GuideWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetPrimaryEmailOfCandidate
// ====================================================

export interface SetPrimaryEmailOfCandidate_setPrimaryEmailOfCandidate_candidate {
  __typename: "Candidate";
  id: string;
  email: string;
}

export interface SetPrimaryEmailOfCandidate_setPrimaryEmailOfCandidate {
  __typename: "SetPrimaryEmailOfCandidateResponse";
  success: boolean;
  code: string;
  message: string;
  candidate: SetPrimaryEmailOfCandidate_setPrimaryEmailOfCandidate_candidate | null;
}

export interface SetPrimaryEmailOfCandidate {
  setPrimaryEmailOfCandidate: SetPrimaryEmailOfCandidate_setPrimaryEmailOfCandidate;
}

export interface SetPrimaryEmailOfCandidateVariables {
  input: SetPrimaryEmailOfCandidateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetPrimaryPhoneOfCandidate
// ====================================================

export interface SetPrimaryPhoneOfCandidate_setPrimaryPhoneOfCandidate_candidate {
  __typename: "Candidate";
  id: string;
  primaryPhone: string | null;
}

export interface SetPrimaryPhoneOfCandidate_setPrimaryPhoneOfCandidate {
  __typename: "SetPrimaryPhoneOfCandidateResponse";
  success: boolean;
  code: string;
  message: string;
  candidate: SetPrimaryPhoneOfCandidate_setPrimaryPhoneOfCandidate_candidate | null;
}

export interface SetPrimaryPhoneOfCandidate {
  setPrimaryPhoneOfCandidate: SetPrimaryPhoneOfCandidate_setPrimaryPhoneOfCandidate;
}

export interface SetPrimaryPhoneOfCandidateVariables {
  input: SetPrimaryPhoneOfCandidateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StageByIdPrisma
// ====================================================

export interface StageByIdPrisma_stageFindUnique_modules {
  __typename: "StageResolvedModule";
  id: string;
  position: number;
  data: GraphQL_JSON;
  type: string;
}

export interface StageByIdPrisma_stageFindUnique {
  __typename: "Stage";
  id: string;
  /**
   * Returns the Content Modules for this stage contextualized
   *        to the stage's Guide. Today, the content modules come from a combination of 
   *        stamped modules (the interview scheduler) and the associated ContentTemplate's modules
   */
  modules: StageByIdPrisma_stageFindUnique_modules[];
}

export interface StageByIdPrisma {
  stageFindUnique: StageByIdPrisma_stageFindUnique;
}

export interface StageByIdPrismaVariables {
  stageId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StartGuide
// ====================================================

export interface StartGuide_startGuide_guide_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
}

export interface StartGuide_startGuide_guide_nextStage {
  __typename: "Stage";
  id: string;
}

export interface StartGuide_startGuide_guide_currentStage {
  __typename: "Stage";
  id: string;
}

export interface StartGuide_startGuide_guide_stages_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface StartGuide_startGuide_guide_stages_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
}

export interface StartGuide_startGuide_guide_stages {
  __typename: "Stage";
  id: string;
  status: StageStatus;
  configured: boolean;
  title: string;
  isAtsCurrent: boolean;
  hidden: boolean;
  hasAutomation: boolean;
  position: number;
  atsStage: StartGuide_startGuide_guide_stages_atsStage | null;
  guide: StartGuide_startGuide_guide_stages_guide;
}

export interface StartGuide_startGuide_guide_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface StartGuide_startGuide_guide_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  hasAutomation: boolean;
  configured: boolean;
  title: string;
  status: StageStatus;
  position: number;
}

export interface StartGuide_startGuide_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
  needsStageConfiguration: boolean;
  guideVersion: number;
  jobRoleGuideTemplate: StartGuide_startGuide_guide_jobRoleGuideTemplate | null;
  nextStage: StartGuide_startGuide_guide_nextStage | null;
  stageSyncStatus: StageSyncStatus;
  currentStage: StartGuide_startGuide_guide_currentStage | null;
  stages: StartGuide_startGuide_guide_stages[];
  publicUrl: string;
  candidate: StartGuide_startGuide_guide_candidate;
  sortedVisibleStages: StartGuide_startGuide_guide_sortedVisibleStages[];
}

export interface StartGuide_startGuide {
  __typename: "StartGuideMutationResponse";
  success: boolean;
  guide: StartGuide_startGuide_guide | null;
}

export interface StartGuide {
  startGuide: StartGuide_startGuide;
}

export interface StartGuideVariables {
  input: StartGuideInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContentTemplatesForSelector
// ====================================================

export interface ContentTemplatesForSelector_currentOrganization_contentTemplates_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface ContentTemplatesForSelector_currentOrganization_contentTemplates {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: ContentTemplatesForSelector_currentOrganization_contentTemplates_modulesInflated[];
}

export interface ContentTemplatesForSelector_currentOrganization {
  __typename: "Organization";
  id: string;
  contentTemplates: ContentTemplatesForSelector_currentOrganization_contentTemplates[];
}

export interface ContentTemplatesForSelector {
  currentOrganization: ContentTemplatesForSelector_currentOrganization | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EmailTemplatesForSelector
// ====================================================

export interface EmailTemplatesForSelector_currentOrganization_emailTemplates {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface EmailTemplatesForSelector_currentOrganization {
  __typename: "Organization";
  id: string;
  emailTemplates: EmailTemplatesForSelector_currentOrganization_emailTemplates[];
}

export interface EmailTemplatesForSelector {
  currentOrganization: EmailTemplatesForSelector_currentOrganization | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateJobRoleGuideTemplateForJobDetails
// ====================================================

export interface UpdateJobRoleGuideTemplateForJobDetails_updateJobRoleGuideTemplate_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  jobRoleNameOverride: string | null;
}

export interface UpdateJobRoleGuideTemplateForJobDetails_updateJobRoleGuideTemplate {
  __typename: "UpdateJobRoleGuideTemplateResponse";
  success: boolean;
  message: string;
  code: string;
  jobRoleGuideTemplate: UpdateJobRoleGuideTemplateForJobDetails_updateJobRoleGuideTemplate_jobRoleGuideTemplate | null;
}

export interface UpdateJobRoleGuideTemplateForJobDetails {
  updateJobRoleGuideTemplate: UpdateJobRoleGuideTemplateForJobDetails_updateJobRoleGuideTemplate;
}

export interface UpdateJobRoleGuideTemplateForJobDetailsVariables {
  input: UpdateJobRoleGuideTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetPointOfContact
// ====================================================

export interface SetPointOfContact_setPointOfContact_jobRoleGuideTemplate_pointOfContact {
  __typename: "Interviewer";
  id: string;
  name: string;
  imageUrl: string;
}

export interface SetPointOfContact_setPointOfContact_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  pointOfContact: SetPointOfContact_setPointOfContact_jobRoleGuideTemplate_pointOfContact | null;
}

export interface SetPointOfContact_setPointOfContact {
  __typename: "SetPointOfContactResponse";
  success: boolean;
  code: string;
  jobRoleGuideTemplate: SetPointOfContact_setPointOfContact_jobRoleGuideTemplate;
}

export interface SetPointOfContact {
  setPointOfContact: SetPointOfContact_setPointOfContact;
}

export interface SetPointOfContactVariables {
  input: SetPointOfContactInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobRoleGuideTemplateForJobDetails
// ====================================================

export interface GetJobRoleGuideTemplateForJobDetails_currentOrganization_pointOfContacts {
  __typename: "Interviewer";
  id: string;
  name: string;
  imageUrl: string;
}

export interface GetJobRoleGuideTemplateForJobDetails_currentOrganization_jobRoleGuideTemplateById_pointOfContact {
  __typename: "Interviewer";
  id: string;
  name: string;
  imageUrl: string;
}

export interface GetJobRoleGuideTemplateForJobDetails_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  pointOfContact: GetJobRoleGuideTemplateForJobDetails_currentOrganization_jobRoleGuideTemplateById_pointOfContact | null;
  id: string;
  jobRoleNameOverride: string | null;
}

export interface GetJobRoleGuideTemplateForJobDetails_currentOrganization {
  __typename: "Organization";
  id: string;
  pointOfContacts: GetJobRoleGuideTemplateForJobDetails_currentOrganization_pointOfContacts[];
  jobRoleGuideTemplateById: GetJobRoleGuideTemplateForJobDetails_currentOrganization_jobRoleGuideTemplateById;
}

export interface GetJobRoleGuideTemplateForJobDetails {
  currentOrganization: GetJobRoleGuideTemplateForJobDetails_currentOrganization | null;
}

export interface GetJobRoleGuideTemplateForJobDetailsVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveGuideTemplateStage
// ====================================================

export interface RemoveGuideTemplateStage_removeGuideTemplateStage_guideTemplateStage {
  __typename: "GuideTemplateStage";
  id: string;
}

export interface RemoveGuideTemplateStage_removeGuideTemplateStage {
  __typename: "RemoveGuideTemplateStageResponse";
  success: boolean;
  code: string;
  message: string;
  guideTemplateStage: RemoveGuideTemplateStage_removeGuideTemplateStage_guideTemplateStage | null;
}

export interface RemoveGuideTemplateStage {
  removeGuideTemplateStage: RemoveGuideTemplateStage_removeGuideTemplateStage;
}

export interface RemoveGuideTemplateStageVariables {
  input: RemoveGuideTemplateStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGuideTemplate
// ====================================================

export interface UpdateGuideTemplate_updateGuideTemplate_guideTemplate_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface UpdateGuideTemplate_updateGuideTemplate_guideTemplate_guideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateGuideTemplate_updateGuideTemplate_guideTemplate_guideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface UpdateGuideTemplate_updateGuideTemplate_guideTemplate_guideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: UpdateGuideTemplate_updateGuideTemplate_guideTemplate_guideTemplateStages_contentTemplate_modulesInflated[];
}

export interface UpdateGuideTemplate_updateGuideTemplate_guideTemplate_guideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface UpdateGuideTemplate_updateGuideTemplate_guideTemplate_guideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateGuideTemplate_updateGuideTemplate_guideTemplate_guideTemplateStages {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplate: UpdateGuideTemplate_updateGuideTemplate_guideTemplate_guideTemplateStages_postTemplate | null;
  contentTemplate: UpdateGuideTemplate_updateGuideTemplate_guideTemplate_guideTemplateStages_contentTemplate | null;
  emailTemplate: UpdateGuideTemplate_updateGuideTemplate_guideTemplate_guideTemplateStages_emailTemplate | null;
  postTemplates: UpdateGuideTemplate_updateGuideTemplate_guideTemplate_guideTemplateStages_postTemplates[];
}

export interface UpdateGuideTemplate_updateGuideTemplate_guideTemplate_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface UpdateGuideTemplate_updateGuideTemplate_guideTemplate_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: UpdateGuideTemplate_updateGuideTemplate_guideTemplate_interviewPlan_items_postTemplates[];
}

export interface UpdateGuideTemplate_updateGuideTemplate_guideTemplate_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: UpdateGuideTemplate_updateGuideTemplate_guideTemplate_interviewPlan_items[];
}

export interface UpdateGuideTemplate_updateGuideTemplate_guideTemplate_guideTemplateLexicalContext {
  __typename: "GuideTemplateLexicalContext";
  id: GraphQL_UUID;
  companyName: string;
}

export interface UpdateGuideTemplate_updateGuideTemplate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  usage: number;
  isGlobal: boolean;
  department: UpdateGuideTemplate_updateGuideTemplate_guideTemplate_department | null;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  inProgressGuidesCount: number;
  jobsWithInProgressGuidesCount: number;
  guideTemplateStages: UpdateGuideTemplate_updateGuideTemplate_guideTemplate_guideTemplateStages[];
  interviewPlan: UpdateGuideTemplate_updateGuideTemplate_guideTemplate_interviewPlan | null;
  guideTemplateLexicalContext: UpdateGuideTemplate_updateGuideTemplate_guideTemplate_guideTemplateLexicalContext;
  updatedAt: GraphQL_DateTime;
}

export interface UpdateGuideTemplate_updateGuideTemplate {
  __typename: "UpdateGuideTemplateResponse";
  success: boolean;
  code: string;
  guideTemplate: UpdateGuideTemplate_updateGuideTemplate_guideTemplate | null;
}

export interface UpdateGuideTemplate {
  updateGuideTemplate: UpdateGuideTemplate_updateGuideTemplate;
}

export interface UpdateGuideTemplateVariables {
  input: UpdateGuideTemplateInput;
  isZeus: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGuideTemplateStage
// ====================================================

export interface CreateGuideTemplateStage_createGuideTemplateStage_guideTemplateStage_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface CreateGuideTemplateStage_createGuideTemplateStage_guideTemplateStage_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface CreateGuideTemplateStage_createGuideTemplateStage_guideTemplateStage_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: CreateGuideTemplateStage_createGuideTemplateStage_guideTemplateStage_contentTemplate_modulesInflated[];
}

export interface CreateGuideTemplateStage_createGuideTemplateStage_guideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface CreateGuideTemplateStage_createGuideTemplateStage_guideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface CreateGuideTemplateStage_createGuideTemplateStage_guideTemplateStage {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplate: CreateGuideTemplateStage_createGuideTemplateStage_guideTemplateStage_postTemplate | null;
  contentTemplate: CreateGuideTemplateStage_createGuideTemplateStage_guideTemplateStage_contentTemplate | null;
  emailTemplate: CreateGuideTemplateStage_createGuideTemplateStage_guideTemplateStage_emailTemplate | null;
  postTemplates: CreateGuideTemplateStage_createGuideTemplateStage_guideTemplateStage_postTemplates[];
}

export interface CreateGuideTemplateStage_createGuideTemplateStage {
  __typename: "CreateGuideTemplateStageResponse";
  success: boolean;
  code: string;
  guideTemplateStage: CreateGuideTemplateStage_createGuideTemplateStage_guideTemplateStage;
}

export interface CreateGuideTemplateStage {
  createGuideTemplateStage: CreateGuideTemplateStage_createGuideTemplateStage;
}

export interface CreateGuideTemplateStageVariables {
  input: CreateGuideTemplateStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGuideTemplateStage
// ====================================================

export interface UpdateGuideTemplateStage_updateGuideTemplateStage_guideTemplateStage_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateGuideTemplateStage_updateGuideTemplateStage_guideTemplateStage_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface UpdateGuideTemplateStage_updateGuideTemplateStage_guideTemplateStage_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: UpdateGuideTemplateStage_updateGuideTemplateStage_guideTemplateStage_contentTemplate_modulesInflated[];
}

export interface UpdateGuideTemplateStage_updateGuideTemplateStage_guideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface UpdateGuideTemplateStage_updateGuideTemplateStage_guideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateGuideTemplateStage_updateGuideTemplateStage_guideTemplateStage {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplate: UpdateGuideTemplateStage_updateGuideTemplateStage_guideTemplateStage_postTemplate | null;
  contentTemplate: UpdateGuideTemplateStage_updateGuideTemplateStage_guideTemplateStage_contentTemplate | null;
  emailTemplate: UpdateGuideTemplateStage_updateGuideTemplateStage_guideTemplateStage_emailTemplate | null;
  postTemplates: UpdateGuideTemplateStage_updateGuideTemplateStage_guideTemplateStage_postTemplates[];
}

export interface UpdateGuideTemplateStage_updateGuideTemplateStage {
  __typename: "UpdateGuideTemplateStageResponse";
  success: boolean;
  code: string;
  guideTemplateStage: UpdateGuideTemplateStage_updateGuideTemplateStage_guideTemplateStage | null;
}

export interface UpdateGuideTemplateStage {
  updateGuideTemplateStage: UpdateGuideTemplateStage_updateGuideTemplateStage;
}

export interface UpdateGuideTemplateStageVariables {
  input: UpdateGuideTemplateStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveGuideTemplateStageContentTemplate
// ====================================================

export interface RemoveGuideTemplateStageContentTemplate_removeGuideTemplateStageContentTemplate_guideTemplateStage_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface RemoveGuideTemplateStageContentTemplate_removeGuideTemplateStageContentTemplate_guideTemplateStage_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface RemoveGuideTemplateStageContentTemplate_removeGuideTemplateStageContentTemplate_guideTemplateStage_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: RemoveGuideTemplateStageContentTemplate_removeGuideTemplateStageContentTemplate_guideTemplateStage_contentTemplate_modulesInflated[];
}

export interface RemoveGuideTemplateStageContentTemplate_removeGuideTemplateStageContentTemplate_guideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface RemoveGuideTemplateStageContentTemplate_removeGuideTemplateStageContentTemplate_guideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface RemoveGuideTemplateStageContentTemplate_removeGuideTemplateStageContentTemplate_guideTemplateStage {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplate: RemoveGuideTemplateStageContentTemplate_removeGuideTemplateStageContentTemplate_guideTemplateStage_postTemplate | null;
  contentTemplate: RemoveGuideTemplateStageContentTemplate_removeGuideTemplateStageContentTemplate_guideTemplateStage_contentTemplate | null;
  emailTemplate: RemoveGuideTemplateStageContentTemplate_removeGuideTemplateStageContentTemplate_guideTemplateStage_emailTemplate | null;
  postTemplates: RemoveGuideTemplateStageContentTemplate_removeGuideTemplateStageContentTemplate_guideTemplateStage_postTemplates[];
}

export interface RemoveGuideTemplateStageContentTemplate_removeGuideTemplateStageContentTemplate {
  __typename: "RemoveGuideTemplateStageContentTemplateResponse";
  success: boolean;
  code: string;
  guideTemplateStage: RemoveGuideTemplateStageContentTemplate_removeGuideTemplateStageContentTemplate_guideTemplateStage | null;
}

export interface RemoveGuideTemplateStageContentTemplate {
  removeGuideTemplateStageContentTemplate: RemoveGuideTemplateStageContentTemplate_removeGuideTemplateStageContentTemplate;
}

export interface RemoveGuideTemplateStageContentTemplateVariables {
  input: RemoveGuideTemplateStageContentTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveGuideTemplateStageEmailTemplate
// ====================================================

export interface RemoveGuideTemplateStageEmailTemplate_removeGuideTemplateStageEmailTemplate_guideTemplateStage_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface RemoveGuideTemplateStageEmailTemplate_removeGuideTemplateStageEmailTemplate_guideTemplateStage_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface RemoveGuideTemplateStageEmailTemplate_removeGuideTemplateStageEmailTemplate_guideTemplateStage_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: RemoveGuideTemplateStageEmailTemplate_removeGuideTemplateStageEmailTemplate_guideTemplateStage_contentTemplate_modulesInflated[];
}

export interface RemoveGuideTemplateStageEmailTemplate_removeGuideTemplateStageEmailTemplate_guideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface RemoveGuideTemplateStageEmailTemplate_removeGuideTemplateStageEmailTemplate_guideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface RemoveGuideTemplateStageEmailTemplate_removeGuideTemplateStageEmailTemplate_guideTemplateStage {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplate: RemoveGuideTemplateStageEmailTemplate_removeGuideTemplateStageEmailTemplate_guideTemplateStage_postTemplate | null;
  contentTemplate: RemoveGuideTemplateStageEmailTemplate_removeGuideTemplateStageEmailTemplate_guideTemplateStage_contentTemplate | null;
  emailTemplate: RemoveGuideTemplateStageEmailTemplate_removeGuideTemplateStageEmailTemplate_guideTemplateStage_emailTemplate | null;
  postTemplates: RemoveGuideTemplateStageEmailTemplate_removeGuideTemplateStageEmailTemplate_guideTemplateStage_postTemplates[];
}

export interface RemoveGuideTemplateStageEmailTemplate_removeGuideTemplateStageEmailTemplate {
  __typename: "RemoveGuideTemplateStageEmailTemplateResponse";
  success: boolean;
  code: string;
  guideTemplateStage: RemoveGuideTemplateStageEmailTemplate_removeGuideTemplateStageEmailTemplate_guideTemplateStage | null;
}

export interface RemoveGuideTemplateStageEmailTemplate {
  removeGuideTemplateStageEmailTemplate: RemoveGuideTemplateStageEmailTemplate_removeGuideTemplateStageEmailTemplate;
}

export interface RemoveGuideTemplateStageEmailTemplateVariables {
  input: RemoveGuideTemplateStageEmailTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveGuideTemplateStagePostTemplate
// ====================================================

export interface RemoveGuideTemplateStagePostTemplate_removeGuideTemplateStagePostTemplate_guideTemplateStage_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface RemoveGuideTemplateStagePostTemplate_removeGuideTemplateStagePostTemplate_guideTemplateStage_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface RemoveGuideTemplateStagePostTemplate_removeGuideTemplateStagePostTemplate_guideTemplateStage_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: RemoveGuideTemplateStagePostTemplate_removeGuideTemplateStagePostTemplate_guideTemplateStage_contentTemplate_modulesInflated[];
}

export interface RemoveGuideTemplateStagePostTemplate_removeGuideTemplateStagePostTemplate_guideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface RemoveGuideTemplateStagePostTemplate_removeGuideTemplateStagePostTemplate_guideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface RemoveGuideTemplateStagePostTemplate_removeGuideTemplateStagePostTemplate_guideTemplateStage {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplate: RemoveGuideTemplateStagePostTemplate_removeGuideTemplateStagePostTemplate_guideTemplateStage_postTemplate | null;
  contentTemplate: RemoveGuideTemplateStagePostTemplate_removeGuideTemplateStagePostTemplate_guideTemplateStage_contentTemplate | null;
  emailTemplate: RemoveGuideTemplateStagePostTemplate_removeGuideTemplateStagePostTemplate_guideTemplateStage_emailTemplate | null;
  postTemplates: RemoveGuideTemplateStagePostTemplate_removeGuideTemplateStagePostTemplate_guideTemplateStage_postTemplates[];
}

export interface RemoveGuideTemplateStagePostTemplate_removeGuideTemplateStagePostTemplate {
  __typename: "RemoveGuideTemplateStagePostTemplateResponse";
  success: boolean;
  code: string;
  guideTemplateStage: RemoveGuideTemplateStagePostTemplate_removeGuideTemplateStagePostTemplate_guideTemplateStage | null;
}

export interface RemoveGuideTemplateStagePostTemplate {
  removeGuideTemplateStagePostTemplate: RemoveGuideTemplateStagePostTemplate_removeGuideTemplateStagePostTemplate;
}

export interface RemoveGuideTemplateStagePostTemplateVariables {
  input: RemoveGuideTemplateStagePostTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentGuideTemplateForEditing
// ====================================================

export interface CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_guideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_guideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_guideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_guideTemplateStages_contentTemplate_modulesInflated[];
}

export interface CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_guideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_guideTemplateStages {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplate: CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_guideTemplateStages_postTemplate | null;
  contentTemplate: CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_guideTemplateStages_contentTemplate | null;
  emailTemplate: CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_guideTemplateStages_emailTemplate | null;
  postTemplates: CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates[];
}

export interface CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates[];
}

export interface CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_interviewPlan_items[];
}

export interface CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_guideTemplateLexicalContext {
  __typename: "GuideTemplateLexicalContext";
  id: GraphQL_UUID;
  companyName: string;
}

export interface CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById {
  __typename: "GuideTemplate";
  id: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  usage: number;
  isGlobal: boolean;
  department: CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_department | null;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  inProgressGuidesCount: number;
  jobsWithInProgressGuidesCount: number;
  guideTemplateStages: CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_guideTemplateStages[];
  interviewPlan: CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_interviewPlan | null;
  guideTemplateLexicalContext: CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById_guideTemplateLexicalContext;
  updatedAt: GraphQL_DateTime;
}

export interface CurrentGuideTemplateForEditing_currentOrganization {
  __typename: "Organization";
  id: string;
  guideTemplateById: CurrentGuideTemplateForEditing_currentOrganization_guideTemplateById;
}

export interface CurrentGuideTemplateForEditing {
  currentOrganization: CurrentGuideTemplateForEditing_currentOrganization | null;
}

export interface CurrentGuideTemplateForEditingVariables {
  guideTemplateId: string;
  isZeus: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReorderGuideTemplateStage
// ====================================================

export interface ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_guideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_guideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_guideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_guideTemplateStages_contentTemplate_modulesInflated[];
}

export interface ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_guideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_guideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_guideTemplateStages {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplate: ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_guideTemplateStages_postTemplate | null;
  contentTemplate: ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_guideTemplateStages_contentTemplate | null;
  emailTemplate: ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_guideTemplateStages_emailTemplate | null;
  postTemplates: ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_guideTemplateStages_postTemplates[];
}

export interface ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_interviewPlan_items_postTemplates[];
}

export interface ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_interviewPlan_items[];
}

export interface ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_guideTemplateLexicalContext {
  __typename: "GuideTemplateLexicalContext";
  id: GraphQL_UUID;
  companyName: string;
}

export interface ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  usage: number;
  isGlobal: boolean;
  department: ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_department | null;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  inProgressGuidesCount: number;
  jobsWithInProgressGuidesCount: number;
  guideTemplateStages: ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_guideTemplateStages[];
  interviewPlan: ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_interviewPlan | null;
  guideTemplateLexicalContext: ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate_guideTemplateLexicalContext;
  updatedAt: GraphQL_DateTime;
}

export interface ReorderGuideTemplateStage_reorderGuideTemplateStage {
  __typename: "ReorderGuideTemplateStageResponse";
  success: boolean;
  code: string;
  guideTemplate: ReorderGuideTemplateStage_reorderGuideTemplateStage_guideTemplate | null;
}

export interface ReorderGuideTemplateStage {
  reorderGuideTemplateStage: ReorderGuideTemplateStage_reorderGuideTemplateStage;
}

export interface ReorderGuideTemplateStageVariables {
  input: ReorderGuideTemplateStageInput;
  isZeus: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateJobRoleGuideTemplateStageContentTemplate
// ====================================================

export interface UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_contentTemplate_modulesInflated[];
}

export interface UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer;
}

export interface UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate | null;
  schedulerAvailability: UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability | null;
  configInterviewers: UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers[];
}

export interface UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes_change = UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes_change_BooleanFieldChange | UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes_change_StringFieldChange | UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes_change_StringArrayFieldChange;

export interface UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes_change;
}

export interface UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_contentTemplate | null;
  emailTemplate: UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_emailTemplate | null;
  postTemplate: UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_postTemplate | null;
  postTemplates: UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_postTemplates[];
  atssyncJobStage: UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_atssyncJobStage | null;
  schedulerConfig: UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig | null;
  changes: UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes[];
}

export interface UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate {
  __typename: "UpdateJobRoleGuideTemplateStageContentTemplateResponse";
  success: boolean;
  code: string;
  jobRoleGuideTemplateStage: UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage;
}

export interface UpdateJobRoleGuideTemplateStageContentTemplate {
  updateJobRoleGuideTemplateStageContentTemplate: UpdateJobRoleGuideTemplateStageContentTemplate_updateJobRoleGuideTemplateStageContentTemplate;
}

export interface UpdateJobRoleGuideTemplateStageContentTemplateVariables {
  input: UpdateJobRoleGuideTemplateStageContentTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateJobRoleGuideTemplateStagePostTemplate
// ====================================================

export interface UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_contentTemplate_modulesInflated[];
}

export interface UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer;
}

export interface UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate | null;
  schedulerAvailability: UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability | null;
  configInterviewers: UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers[];
}

export interface UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes_change = UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes_change_BooleanFieldChange | UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes_change_StringFieldChange | UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes_change_StringArrayFieldChange;

export interface UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes_change;
}

export interface UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_contentTemplate | null;
  emailTemplate: UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_emailTemplate | null;
  postTemplate: UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_postTemplate | null;
  postTemplates: UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_postTemplates[];
  atssyncJobStage: UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_atssyncJobStage | null;
  schedulerConfig: UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig | null;
  changes: UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes[];
}

export interface UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate {
  __typename: "UpdateJobRoleGuideTemplateStagePostTemplateResponse";
  success: boolean;
  code: string;
  jobRoleGuideTemplateStage: UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage;
}

export interface UpdateJobRoleGuideTemplateStagePostTemplate {
  updateJobRoleGuideTemplateStagePostTemplate: UpdateJobRoleGuideTemplateStagePostTemplate_updateJobRoleGuideTemplateStagePostTemplate;
}

export interface UpdateJobRoleGuideTemplateStagePostTemplateVariables {
  input: UpdateJobRoleGuideTemplateStagePostTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateJobRoleGuideTemplateStageEmailTemplate
// ====================================================

export interface UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_contentTemplate_modulesInflated[];
}

export interface UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer;
}

export interface UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate | null;
  schedulerAvailability: UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability | null;
  configInterviewers: UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers[];
}

export interface UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes_change = UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes_change_BooleanFieldChange | UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes_change_StringFieldChange | UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes_change_StringArrayFieldChange;

export interface UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes_change;
}

export interface UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_contentTemplate | null;
  emailTemplate: UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_emailTemplate | null;
  postTemplate: UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_postTemplate | null;
  postTemplates: UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_postTemplates[];
  atssyncJobStage: UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_atssyncJobStage | null;
  schedulerConfig: UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig | null;
  changes: UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes[];
}

export interface UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate {
  __typename: "UpdateJobRoleGuideTemplateStageEmailTemplateResponse";
  success: boolean;
  code: string;
  jobRoleGuideTemplateStage: UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage | null;
}

export interface UpdateJobRoleGuideTemplateStageEmailTemplate {
  updateJobRoleGuideTemplateStageEmailTemplate: UpdateJobRoleGuideTemplateStageEmailTemplate_updateJobRoleGuideTemplateStageEmailTemplate;
}

export interface UpdateJobRoleGuideTemplateStageEmailTemplateVariables {
  input: UpdateJobRoleGuideTemplateStageEmailTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveJobRoleGuideTemplateStageContentTemplate
// ====================================================

export interface RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_contentTemplate_modulesInflated[];
}

export interface RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer;
}

export interface RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate | null;
  schedulerAvailability: RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability | null;
  configInterviewers: RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers[];
}

export interface RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes_change = RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes_change_BooleanFieldChange | RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes_change_StringFieldChange | RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes_change_StringArrayFieldChange;

export interface RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes_change;
}

export interface RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_contentTemplate | null;
  emailTemplate: RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_emailTemplate | null;
  postTemplate: RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_postTemplate | null;
  postTemplates: RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_postTemplates[];
  atssyncJobStage: RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_atssyncJobStage | null;
  schedulerConfig: RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_schedulerConfig | null;
  changes: RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage_changes[];
}

export interface RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate {
  __typename: "RemoveJobRoleGuideTemplateStageContentTemplateResponse";
  success: boolean;
  code: string;
  jobRoleGuideTemplateStage: RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate_jobRoleGuideTemplateStage | null;
}

export interface RemoveJobRoleGuideTemplateStageContentTemplate {
  removeJobRoleGuideTemplateStageContentTemplate: RemoveJobRoleGuideTemplateStageContentTemplate_removeJobRoleGuideTemplateStageContentTemplate;
}

export interface RemoveJobRoleGuideTemplateStageContentTemplateVariables {
  input: RemoveJobRoleGuideTemplateStageContentTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveJobRoleGuideTemplateStagePostTemplate
// ====================================================

export interface RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_contentTemplate_modulesInflated[];
}

export interface RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer;
}

export interface RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate | null;
  schedulerAvailability: RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability | null;
  configInterviewers: RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers[];
}

export interface RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes_change = RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes_change_BooleanFieldChange | RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes_change_StringFieldChange | RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes_change_StringArrayFieldChange;

export interface RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes_change;
}

export interface RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_contentTemplate | null;
  emailTemplate: RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_emailTemplate | null;
  postTemplate: RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_postTemplate | null;
  postTemplates: RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_postTemplates[];
  atssyncJobStage: RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_atssyncJobStage | null;
  schedulerConfig: RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_schedulerConfig | null;
  changes: RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage_changes[];
}

export interface RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate {
  __typename: "RemoveJobRoleGuideTemplateStagePostTemplateResponse";
  success: boolean;
  code: string;
  jobRoleGuideTemplateStage: RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate_jobRoleGuideTemplateStage | null;
}

export interface RemoveJobRoleGuideTemplateStagePostTemplate {
  removeJobRoleGuideTemplateStagePostTemplate: RemoveJobRoleGuideTemplateStagePostTemplate_removeJobRoleGuideTemplateStagePostTemplate;
}

export interface RemoveJobRoleGuideTemplateStagePostTemplateVariables {
  input: RemoveJobRoleGuideTemplateStagePostTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveJobRoleGuideTemplateStageEmailTemplate
// ====================================================

export interface RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_contentTemplate_modulesInflated[];
}

export interface RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer;
}

export interface RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate | null;
  schedulerAvailability: RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability | null;
  configInterviewers: RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers[];
}

export interface RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes_change = RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes_change_BooleanFieldChange | RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes_change_StringFieldChange | RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes_change_StringArrayFieldChange;

export interface RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes_change;
}

export interface RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_contentTemplate | null;
  emailTemplate: RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_emailTemplate | null;
  postTemplate: RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_postTemplate | null;
  postTemplates: RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_postTemplates[];
  atssyncJobStage: RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_atssyncJobStage | null;
  schedulerConfig: RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_schedulerConfig | null;
  changes: RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage_changes[];
}

export interface RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate {
  __typename: "RemoveJobRoleGuideTemplateStageEmailTemplateResponse";
  success: boolean;
  code: string;
  jobRoleGuideTemplateStage: RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate_jobRoleGuideTemplateStage | null;
}

export interface RemoveJobRoleGuideTemplateStageEmailTemplate {
  removeJobRoleGuideTemplateStageEmailTemplate: RemoveJobRoleGuideTemplateStageEmailTemplate_removeJobRoleGuideTemplateStageEmailTemplate;
}

export interface RemoveJobRoleGuideTemplateStageEmailTemplateVariables {
  input: RemoveJobRoleGuideTemplateStageEmailTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateJobRoleGuideTemplateOptions
// ====================================================

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_changes_change = UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_changes_change_BooleanFieldChange | UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_changes_change_StringFieldChange | UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_changes_change_StringArrayFieldChange;

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_changes {
  __typename: "GuidePropagationFieldChange";
  field: GuidePropagationChangesEnum;
  change: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_changes_change;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedAddedData {
  __typename: "CollectionFieldChangedAddedData";
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedRemovedData {
  __typename: "CollectionFieldChangedRemovedData";
  parentId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedReorderedData {
  __typename: "CollectionFieldChangedReorderedData";
  parentId: string | null;
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export type UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_collectionChanges_data = UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedAddedData | UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedRemovedData | UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedReorderedData;

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_collectionChanges {
  __typename: "GuideCollectionPropagationFieldChange";
  type: GuideCollectionPropagationChangesTypeEnum;
  data: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_collectionChanges_data;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate | null;
  schedulerAvailability: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability | null;
  configInterviewers: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers[];
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change = UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange | UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringFieldChange | UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange;

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate | null;
  emailTemplate: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_emailTemplate | null;
  postTemplate: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplate | null;
  postTemplates: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates[];
  atssyncJobStage: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_atssyncJobStage | null;
  schedulerConfig: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig | null;
  changes: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes[];
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_interviewPlan_items_postTemplates[];
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_interviewPlan_items[];
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_atsJob_atsJobStages {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_atsJob {
  __typename: "ATSJobRole";
  id: string;
  atsJobStages: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_atsJob_atsJobStages[];
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseInterview {
  __typename: "ATSInterview";
  id: string;
  name: string;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  atssyncGreenhouseJobStage: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseJobStage | null;
  atssyncGreenhouseInterview: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseInterview | null;
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  jobDisplayName: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleNameOverride: string | null;
  inProgressGuidesCount: number;
  setupStatus: JobRoleGuideTemplateSetupStatus;
  defaultGuideVersion: number;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  changes: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_changes[];
  collectionChanges: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_collectionChanges[];
  jobRoleGuideTemplateStages: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_jobRoleGuideTemplateStages[];
  interviewPlan: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_interviewPlan | null;
  guideTemplate: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_guideTemplate | null;
  atsJob: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_atsJob | null;
  eventTemplates: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate_eventTemplates[];
}

export interface UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate {
  __typename: "UpdateJobRoleGuideTemplateResponse";
  success: boolean;
  code: string;
  jobRoleGuideTemplate: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate_jobRoleGuideTemplate | null;
}

export interface UpdateJobRoleGuideTemplateOptions {
  updateJobRoleGuideTemplate: UpdateJobRoleGuideTemplateOptions_updateJobRoleGuideTemplate;
}

export interface UpdateJobRoleGuideTemplateOptionsVariables {
  input: UpdateJobRoleGuideTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateJobRoleGuideTemplateStage
// ====================================================

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_contentTemplate_modulesInflated[];
}

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer;
}

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate | null;
  schedulerAvailability: CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability | null;
  configInterviewers: CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers[];
}

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes_change = CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes_change_BooleanFieldChange | CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes_change_StringFieldChange | CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes_change_StringArrayFieldChange;

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes_change;
}

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  position: number;
}

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  jobRoleGuideTemplateStages: CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages[];
}

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_contentTemplate | null;
  emailTemplate: CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_emailTemplate | null;
  postTemplate: CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_postTemplate | null;
  postTemplates: CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_postTemplates[];
  atssyncJobStage: CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_atssyncJobStage | null;
  schedulerConfig: CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig | null;
  changes: CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes[];
  jobRoleGuideTemplate: CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_jobRoleGuideTemplate;
}

export interface CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage {
  __typename: "CreateJobRoleGuideTemplateStageResponse";
  success: boolean;
  code: string;
  jobRoleGuideTemplateStage: CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage_jobRoleGuideTemplateStage;
}

export interface CreateJobRoleGuideTemplateStage {
  createJobRoleGuideTemplateStage: CreateJobRoleGuideTemplateStage_createJobRoleGuideTemplateStage;
}

export interface CreateJobRoleGuideTemplateStageVariables {
  input: CreateJobRoleGuideTemplateStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateJobRoleGuideTemplateStage
// ====================================================

export interface UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_contentTemplate_modulesInflated[];
}

export interface UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer;
}

export interface UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate | null;
  schedulerAvailability: UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability | null;
  configInterviewers: UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers[];
}

export interface UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes_change = UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes_change_BooleanFieldChange | UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes_change_StringFieldChange | UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes_change_StringArrayFieldChange;

export interface UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes_change;
}

export interface UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_contentTemplate | null;
  emailTemplate: UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_emailTemplate | null;
  postTemplate: UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_postTemplate | null;
  postTemplates: UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_postTemplates[];
  atssyncJobStage: UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_atssyncJobStage | null;
  schedulerConfig: UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_schedulerConfig | null;
  changes: UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage_changes[];
}

export interface UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage {
  __typename: "UpdateJobRoleGuideTemplateStageResponse";
  success: boolean;
  code: string;
  jobRoleGuideTemplateStage: UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage_jobRoleGuideTemplateStage | null;
}

export interface UpdateJobRoleGuideTemplateStage {
  updateJobRoleGuideTemplateStage: UpdateJobRoleGuideTemplateStage_updateJobRoleGuideTemplateStage;
}

export interface UpdateJobRoleGuideTemplateStageVariables {
  input: UpdateJobRoleGuideTemplateStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentJobRoleGuideTemplateForEditing
// ====================================================

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_changes_change = CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_changes_change_BooleanFieldChange | CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_changes_change_StringFieldChange | CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_changes_change_StringArrayFieldChange;

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_changes {
  __typename: "GuidePropagationFieldChange";
  field: GuidePropagationChangesEnum;
  change: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_changes_change;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedAddedData {
  __typename: "CollectionFieldChangedAddedData";
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedRemovedData {
  __typename: "CollectionFieldChangedRemovedData";
  parentId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedReorderedData {
  __typename: "CollectionFieldChangedReorderedData";
  parentId: string | null;
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export type CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data = CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedAddedData | CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedRemovedData | CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedReorderedData;

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_collectionChanges {
  __typename: "GuideCollectionPropagationFieldChange";
  type: GuideCollectionPropagationChangesTypeEnum;
  data: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate | null;
  schedulerAvailability: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability | null;
  configInterviewers: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers[];
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change = CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange | CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringFieldChange | CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange;

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate | null;
  emailTemplate: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_emailTemplate | null;
  postTemplate: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplate | null;
  postTemplates: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates[];
  atssyncJobStage: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_atssyncJobStage | null;
  schedulerConfig: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig | null;
  changes: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes[];
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates[];
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items[];
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_atsJob_atsJobStages {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_atsJob {
  __typename: "ATSJobRole";
  id: string;
  atsJobStages: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_atsJob_atsJobStages[];
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseInterview {
  __typename: "ATSInterview";
  id: string;
  name: string;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  atssyncGreenhouseJobStage: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseJobStage | null;
  atssyncGreenhouseInterview: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseInterview | null;
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  jobDisplayName: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleNameOverride: string | null;
  inProgressGuidesCount: number;
  setupStatus: JobRoleGuideTemplateSetupStatus;
  defaultGuideVersion: number;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  changes: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_changes[];
  collectionChanges: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_collectionChanges[];
  jobRoleGuideTemplateStages: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages[];
  interviewPlan: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_interviewPlan | null;
  guideTemplate: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_guideTemplate | null;
  atsJob: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_atsJob | null;
  eventTemplates: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_eventTemplates[];
}

export interface CurrentJobRoleGuideTemplateForEditing_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplateById: CurrentJobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById;
}

export interface CurrentJobRoleGuideTemplateForEditing {
  currentOrganization: CurrentJobRoleGuideTemplateForEditing_currentOrganization | null;
}

export interface CurrentJobRoleGuideTemplateForEditingVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReorderJobRoleGuideTemplateStage
// ====================================================

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_changes_change = ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_changes_change_BooleanFieldChange | ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_changes_change_StringFieldChange | ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_changes_change_StringArrayFieldChange;

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_changes {
  __typename: "GuidePropagationFieldChange";
  field: GuidePropagationChangesEnum;
  change: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_changes_change;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedAddedData {
  __typename: "CollectionFieldChangedAddedData";
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedRemovedData {
  __typename: "CollectionFieldChangedRemovedData";
  parentId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedReorderedData {
  __typename: "CollectionFieldChangedReorderedData";
  parentId: string | null;
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export type ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_collectionChanges_data = ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedAddedData | ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedRemovedData | ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedReorderedData;

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_collectionChanges {
  __typename: "GuideCollectionPropagationFieldChange";
  type: GuideCollectionPropagationChangesTypeEnum;
  data: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_collectionChanges_data;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate | null;
  schedulerAvailability: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability | null;
  configInterviewers: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers[];
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change = ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange | ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringFieldChange | ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange;

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate | null;
  emailTemplate: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_emailTemplate | null;
  postTemplate: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplate | null;
  postTemplates: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates[];
  atssyncJobStage: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_atssyncJobStage | null;
  schedulerConfig: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig | null;
  changes: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes[];
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_interviewPlan_items_postTemplates[];
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_interviewPlan_items[];
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_atsJob_atsJobStages {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_atsJob {
  __typename: "ATSJobRole";
  id: string;
  atsJobStages: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_atsJob_atsJobStages[];
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseInterview {
  __typename: "ATSInterview";
  id: string;
  name: string;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  atssyncGreenhouseJobStage: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseJobStage | null;
  atssyncGreenhouseInterview: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseInterview | null;
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  jobDisplayName: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleNameOverride: string | null;
  inProgressGuidesCount: number;
  setupStatus: JobRoleGuideTemplateSetupStatus;
  defaultGuideVersion: number;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  changes: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_changes[];
  collectionChanges: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_collectionChanges[];
  jobRoleGuideTemplateStages: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_jobRoleGuideTemplateStages[];
  interviewPlan: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_interviewPlan | null;
  guideTemplate: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_guideTemplate | null;
  atsJob: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_atsJob | null;
  eventTemplates: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate_eventTemplates[];
}

export interface ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage {
  __typename: "ReorderJobRoleGuideTemplateStageResponse";
  success: boolean;
  code: string;
  jobRoleGuideTemplate: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage_jobRoleGuideTemplate | null;
}

export interface ReorderJobRoleGuideTemplateStage {
  reorderJobRoleGuideTemplateStage: ReorderJobRoleGuideTemplateStage_reorderJobRoleGuideTemplateStage;
}

export interface ReorderJobRoleGuideTemplateStageVariables {
  input: ReorderJobRoleGuideTemplateStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveJobRoleGuideTemplateStage
// ====================================================

export interface RemoveJobRoleGuideTemplateStage_removeJobRoleGuideTemplateStage_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
}

export interface RemoveJobRoleGuideTemplateStage_removeJobRoleGuideTemplateStage {
  __typename: "RemoveJobRoleGuideTemplateStageResponse";
  success: boolean;
  code: string;
  message: string;
  jobRoleGuideTemplateStage: RemoveJobRoleGuideTemplateStage_removeJobRoleGuideTemplateStage_jobRoleGuideTemplateStage | null;
}

export interface RemoveJobRoleGuideTemplateStage {
  removeJobRoleGuideTemplateStage: RemoveJobRoleGuideTemplateStage_removeJobRoleGuideTemplateStage;
}

export interface RemoveJobRoleGuideTemplateStageVariables {
  input: RemoveJobRoleGuideTemplateStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: HideJobRoleGuideTemplateStage
// ====================================================

export interface HideJobRoleGuideTemplateStage_hideJobRoleGuideTemplateStage_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  hidden: boolean;
}

export interface HideJobRoleGuideTemplateStage_hideJobRoleGuideTemplateStage {
  __typename: "HideJobRoleGuideTemplateStageResponse";
  success: boolean;
  code: string;
  message: string;
  jobRoleGuideTemplateStage: HideJobRoleGuideTemplateStage_hideJobRoleGuideTemplateStage_jobRoleGuideTemplateStage | null;
}

export interface HideJobRoleGuideTemplateStage {
  hideJobRoleGuideTemplateStage: HideJobRoleGuideTemplateStage_hideJobRoleGuideTemplateStage;
}

export interface HideJobRoleGuideTemplateStageVariables {
  input: HideJobRoleGuideTemplateStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShowJobRoleGuideTemplateStage
// ====================================================

export interface ShowJobRoleGuideTemplateStage_showJobRoleGuideTemplateStage_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  hidden: boolean;
}

export interface ShowJobRoleGuideTemplateStage_showJobRoleGuideTemplateStage {
  __typename: "ShowJobRoleGuideTemplateStageResponse";
  success: boolean;
  code: string;
  message: string;
  jobRoleGuideTemplateStage: ShowJobRoleGuideTemplateStage_showJobRoleGuideTemplateStage_jobRoleGuideTemplateStage | null;
}

export interface ShowJobRoleGuideTemplateStage {
  showJobRoleGuideTemplateStage: ShowJobRoleGuideTemplateStage_showJobRoleGuideTemplateStage;
}

export interface ShowJobRoleGuideTemplateStageVariables {
  input: ShowJobRoleGuideTemplateStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateJobRoleGuideTemplateStageRecipients
// ====================================================

export interface UpdateJobRoleGuideTemplateStageRecipients_updateJobRoleGuideTemplateStageRecipients_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  emailActionCC: string[];
  emailActionBCC: string[];
}

export interface UpdateJobRoleGuideTemplateStageRecipients_updateJobRoleGuideTemplateStageRecipients {
  __typename: "UpdateJobRoleGuideTemplateStageRecipientsResponse";
  success: boolean;
  code: string;
  message: string;
  jobRoleGuideTemplateStage: UpdateJobRoleGuideTemplateStageRecipients_updateJobRoleGuideTemplateStageRecipients_jobRoleGuideTemplateStage | null;
}

export interface UpdateJobRoleGuideTemplateStageRecipients {
  updateJobRoleGuideTemplateStageRecipients: UpdateJobRoleGuideTemplateStageRecipients_updateJobRoleGuideTemplateStageRecipients;
}

export interface UpdateJobRoleGuideTemplateStageRecipientsVariables {
  input: UpdateJobRoleGuideTemplateStageRecipientsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateJobRoleGuideTemplateStageSchedulerSettings
// ====================================================

export interface UpdateJobRoleGuideTemplateStageSchedulerSettings_upsertJobRoleGuideTemplateStageInterviewSchedulerConfig_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface UpdateJobRoleGuideTemplateStageSchedulerSettings_upsertJobRoleGuideTemplateStageInterviewSchedulerConfig_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface UpdateJobRoleGuideTemplateStageSchedulerSettings_upsertJobRoleGuideTemplateStageInterviewSchedulerConfig_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface UpdateJobRoleGuideTemplateStageSchedulerSettings_upsertJobRoleGuideTemplateStageInterviewSchedulerConfig_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: UpdateJobRoleGuideTemplateStageSchedulerSettings_upsertJobRoleGuideTemplateStageInterviewSchedulerConfig_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer;
}

export interface UpdateJobRoleGuideTemplateStageSchedulerSettings_upsertJobRoleGuideTemplateStageInterviewSchedulerConfig_jobRoleGuideTemplateStage_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: UpdateJobRoleGuideTemplateStageSchedulerSettings_upsertJobRoleGuideTemplateStageInterviewSchedulerConfig_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate | null;
  schedulerAvailability: UpdateJobRoleGuideTemplateStageSchedulerSettings_upsertJobRoleGuideTemplateStageInterviewSchedulerConfig_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability | null;
  configInterviewers: UpdateJobRoleGuideTemplateStageSchedulerSettings_upsertJobRoleGuideTemplateStageInterviewSchedulerConfig_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers[];
}

export interface UpdateJobRoleGuideTemplateStageSchedulerSettings_upsertJobRoleGuideTemplateStageInterviewSchedulerConfig_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  schedulerConfig: UpdateJobRoleGuideTemplateStageSchedulerSettings_upsertJobRoleGuideTemplateStageInterviewSchedulerConfig_jobRoleGuideTemplateStage_schedulerConfig | null;
}

export interface UpdateJobRoleGuideTemplateStageSchedulerSettings_upsertJobRoleGuideTemplateStageInterviewSchedulerConfig {
  __typename: "UpsertJobRoleGuideTemplateStageInterviewSchedulerConfigResponse";
  success: boolean;
  code: string;
  message: string;
  jobRoleGuideTemplateStage: UpdateJobRoleGuideTemplateStageSchedulerSettings_upsertJobRoleGuideTemplateStageInterviewSchedulerConfig_jobRoleGuideTemplateStage | null;
}

export interface UpdateJobRoleGuideTemplateStageSchedulerSettings {
  upsertJobRoleGuideTemplateStageInterviewSchedulerConfig: UpdateJobRoleGuideTemplateStageSchedulerSettings_upsertJobRoleGuideTemplateStageInterviewSchedulerConfig;
}

export interface UpdateJobRoleGuideTemplateStageSchedulerSettingsVariables {
  input: UpsertJobRoleGuideTemplateStageInterviewSchedulerConfigInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGuideOverviewContent
// ====================================================

export interface UpdateGuideOverviewContent_updateGuideOverviewContent_guide_defaultContent {
  __typename: "GuideContent";
  data: GraphQL_JSON;
}

export interface UpdateGuideOverviewContent_updateGuideOverviewContent_guide {
  __typename: "Guide";
  id: string;
  bannerImageUrl: string | null;
  defaultContent: UpdateGuideOverviewContent_updateGuideOverviewContent_guide_defaultContent | null;
}

export interface UpdateGuideOverviewContent_updateGuideOverviewContent {
  __typename: "UpdateGuideOverviewContentResponse";
  code: string;
  success: boolean;
  message: string;
  guide: UpdateGuideOverviewContent_updateGuideOverviewContent_guide | null;
}

export interface UpdateGuideOverviewContent {
  updateGuideOverviewContent: UpdateGuideOverviewContent_updateGuideOverviewContent;
}

export interface UpdateGuideOverviewContentVariables {
  input: UpdateGuideOverviewContentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGuideBanner
// ====================================================

export interface UpdateGuideBanner_updateGuideBanner_guide_defaultContent {
  __typename: "GuideContent";
  data: GraphQL_JSON;
}

export interface UpdateGuideBanner_updateGuideBanner_guide {
  __typename: "Guide";
  id: string;
  bannerImageUrl: string | null;
  defaultContent: UpdateGuideBanner_updateGuideBanner_guide_defaultContent | null;
}

export interface UpdateGuideBanner_updateGuideBanner {
  __typename: "UpdateGuideBannerResponse";
  code: string;
  success: boolean;
  message: string;
  guide: UpdateGuideBanner_updateGuideBanner_guide | null;
}

export interface UpdateGuideBanner {
  updateGuideBanner: UpdateGuideBanner_updateGuideBanner;
}

export interface UpdateGuideBannerVariables {
  input: UpdateGuideBannerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGuideTemplateOverviewContent
// ====================================================

export interface UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_guideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_guideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_guideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_guideTemplateStages_contentTemplate_modulesInflated[];
}

export interface UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_guideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_guideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_guideTemplateStages {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplate: UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_guideTemplateStages_postTemplate | null;
  contentTemplate: UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_guideTemplateStages_contentTemplate | null;
  emailTemplate: UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_guideTemplateStages_emailTemplate | null;
  postTemplates: UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_guideTemplateStages_postTemplates[];
}

export interface UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_interviewPlan_items_postTemplates[];
}

export interface UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_interviewPlan_items[];
}

export interface UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_guideTemplateLexicalContext {
  __typename: "GuideTemplateLexicalContext";
  id: GraphQL_UUID;
  companyName: string;
}

export interface UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  isGlobal: boolean;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  usage: number;
  department: UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_department | null;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  inProgressGuidesCount: number;
  jobsWithInProgressGuidesCount: number;
  guideTemplateStages: UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_guideTemplateStages[];
  interviewPlan: UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_interviewPlan | null;
  guideTemplateLexicalContext: UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate_guideTemplateLexicalContext;
  updatedAt: GraphQL_DateTime;
}

export interface UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent {
  __typename: "UpdateGuideTemplateOverviewContentResponse";
  code: string;
  success: boolean;
  message: string;
  guideTemplate: UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent_guideTemplate | null;
}

export interface UpdateGuideTemplateOverviewContent {
  updateGuideTemplateOverviewContent: UpdateGuideTemplateOverviewContent_updateGuideTemplateOverviewContent;
}

export interface UpdateGuideTemplateOverviewContentVariables {
  input: UpdateGuideTemplateOverviewContentInput;
  isZeus: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGuideTemplateBanner
// ====================================================

export interface UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_guideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_guideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_guideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_guideTemplateStages_contentTemplate_modulesInflated[];
}

export interface UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_guideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_guideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_guideTemplateStages {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplate: UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_guideTemplateStages_postTemplate | null;
  contentTemplate: UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_guideTemplateStages_contentTemplate | null;
  emailTemplate: UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_guideTemplateStages_emailTemplate | null;
  postTemplates: UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_guideTemplateStages_postTemplates[];
}

export interface UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_interviewPlan_items_postTemplates[];
}

export interface UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_interviewPlan_items[];
}

export interface UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_guideTemplateLexicalContext {
  __typename: "GuideTemplateLexicalContext";
  id: GraphQL_UUID;
  companyName: string;
}

export interface UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  isGlobal: boolean;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  usage: number;
  department: UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_department | null;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  inProgressGuidesCount: number;
  jobsWithInProgressGuidesCount: number;
  guideTemplateStages: UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_guideTemplateStages[];
  interviewPlan: UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_interviewPlan | null;
  guideTemplateLexicalContext: UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate_guideTemplateLexicalContext;
  updatedAt: GraphQL_DateTime;
}

export interface UpdateGuideTemplateBanner_updateGuideTemplateBanner {
  __typename: "UpdateGuideTemplateBannerResponse";
  code: string;
  success: boolean;
  message: string;
  guideTemplate: UpdateGuideTemplateBanner_updateGuideTemplateBanner_guideTemplate | null;
}

export interface UpdateGuideTemplateBanner {
  updateGuideTemplateBanner: UpdateGuideTemplateBanner_updateGuideTemplateBanner;
}

export interface UpdateGuideTemplateBannerVariables {
  input: UpdateGuideTemplateBannerInput;
  isZeus: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateJobRoleGuideTemplateOverviewContent
// ====================================================

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_changes_change = UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_changes_change_BooleanFieldChange | UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_changes_change_StringFieldChange | UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_changes_change_StringArrayFieldChange;

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_changes {
  __typename: "GuidePropagationFieldChange";
  field: GuidePropagationChangesEnum;
  change: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_changes_change;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedAddedData {
  __typename: "CollectionFieldChangedAddedData";
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedRemovedData {
  __typename: "CollectionFieldChangedRemovedData";
  parentId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedReorderedData {
  __typename: "CollectionFieldChangedReorderedData";
  parentId: string | null;
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export type UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_collectionChanges_data = UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedAddedData | UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedRemovedData | UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedReorderedData;

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_collectionChanges {
  __typename: "GuideCollectionPropagationFieldChange";
  type: GuideCollectionPropagationChangesTypeEnum;
  data: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_collectionChanges_data;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate | null;
  schedulerAvailability: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability | null;
  configInterviewers: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers[];
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change = UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange | UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringFieldChange | UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange;

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate | null;
  emailTemplate: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_emailTemplate | null;
  postTemplate: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplate | null;
  postTemplates: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates[];
  atssyncJobStage: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_atssyncJobStage | null;
  schedulerConfig: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig | null;
  changes: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes[];
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_interviewPlan_items_postTemplates[];
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_interviewPlan_items[];
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_atsJob_atsJobStages {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_atsJob {
  __typename: "ATSJobRole";
  id: string;
  atsJobStages: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_atsJob_atsJobStages[];
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseInterview {
  __typename: "ATSInterview";
  id: string;
  name: string;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  atssyncGreenhouseJobStage: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseJobStage | null;
  atssyncGreenhouseInterview: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseInterview | null;
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  jobDisplayName: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleNameOverride: string | null;
  inProgressGuidesCount: number;
  setupStatus: JobRoleGuideTemplateSetupStatus;
  defaultGuideVersion: number;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  changes: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_changes[];
  collectionChanges: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_collectionChanges[];
  jobRoleGuideTemplateStages: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_jobRoleGuideTemplateStages[];
  interviewPlan: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_interviewPlan | null;
  guideTemplate: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_guideTemplate | null;
  atsJob: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_atsJob | null;
  eventTemplates: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate_eventTemplates[];
}

export interface UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent {
  __typename: "UpdateJobRoleGuideTemplateOverviewContentResponse";
  code: string;
  success: boolean;
  message: string;
  jobRoleGuideTemplate: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent_jobRoleGuideTemplate | null;
}

export interface UpdateJobRoleGuideTemplateOverviewContent {
  updateJobRoleGuideTemplateOverviewContent: UpdateJobRoleGuideTemplateOverviewContent_updateJobRoleGuideTemplateOverviewContent;
}

export interface UpdateJobRoleGuideTemplateOverviewContentVariables {
  input: UpdateJobRoleGuideTemplateOverviewContentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateJobRoleGuideTemplateBanner
// ====================================================

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_changes_change = UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_changes_change_BooleanFieldChange | UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_changes_change_StringFieldChange | UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_changes_change_StringArrayFieldChange;

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_changes {
  __typename: "GuidePropagationFieldChange";
  field: GuidePropagationChangesEnum;
  change: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_changes_change;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedAddedData {
  __typename: "CollectionFieldChangedAddedData";
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedRemovedData {
  __typename: "CollectionFieldChangedRemovedData";
  parentId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedReorderedData {
  __typename: "CollectionFieldChangedReorderedData";
  parentId: string | null;
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export type UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_collectionChanges_data = UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedAddedData | UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedRemovedData | UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedReorderedData;

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_collectionChanges {
  __typename: "GuideCollectionPropagationFieldChange";
  type: GuideCollectionPropagationChangesTypeEnum;
  data: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_collectionChanges_data;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate | null;
  schedulerAvailability: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability | null;
  configInterviewers: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers[];
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change = UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange | UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringFieldChange | UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange;

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate | null;
  emailTemplate: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_emailTemplate | null;
  postTemplate: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplate | null;
  postTemplates: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates[];
  atssyncJobStage: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_atssyncJobStage | null;
  schedulerConfig: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig | null;
  changes: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes[];
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_interviewPlan_items_postTemplates[];
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_interviewPlan_items[];
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_atsJob_atsJobStages {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_atsJob {
  __typename: "ATSJobRole";
  id: string;
  atsJobStages: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_atsJob_atsJobStages[];
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseInterview {
  __typename: "ATSInterview";
  id: string;
  name: string;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  atssyncGreenhouseJobStage: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseJobStage | null;
  atssyncGreenhouseInterview: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseInterview | null;
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  jobDisplayName: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleNameOverride: string | null;
  inProgressGuidesCount: number;
  setupStatus: JobRoleGuideTemplateSetupStatus;
  defaultGuideVersion: number;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  changes: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_changes[];
  collectionChanges: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_collectionChanges[];
  jobRoleGuideTemplateStages: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_jobRoleGuideTemplateStages[];
  interviewPlan: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_interviewPlan | null;
  guideTemplate: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_guideTemplate | null;
  atsJob: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_atsJob | null;
  eventTemplates: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate_eventTemplates[];
}

export interface UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner {
  __typename: "UpdateJobRoleGuideTemplateBannerResponse";
  code: string;
  success: boolean;
  message: string;
  jobRoleGuideTemplate: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner_jobRoleGuideTemplate | null;
}

export interface UpdateJobRoleGuideTemplateBanner {
  updateJobRoleGuideTemplateBanner: UpdateJobRoleGuideTemplateBanner_updateJobRoleGuideTemplateBanner;
}

export interface UpdateJobRoleGuideTemplateBannerVariables {
  input: UpdateJobRoleGuideTemplateBannerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobRoleGuideTemplateForGlobalRestoreChanges
// ====================================================

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_guideTemplate_guideTemplateStages {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  guideTemplateStages: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_guideTemplate_guideTemplateStages[];
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes_change = GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes_change_BooleanFieldChange | GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes_change_StringFieldChange | GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes_change_StringArrayFieldChange;

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes {
  __typename: "GuidePropagationFieldChange";
  field: GuidePropagationChangesEnum;
  change: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes_change;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedAddedData {
  __typename: "CollectionFieldChangedAddedData";
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedRemovedData {
  __typename: "CollectionFieldChangedRemovedData";
  parentId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedReorderedData {
  __typename: "CollectionFieldChangedReorderedData";
  parentId: string | null;
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export type GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data = GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedAddedData | GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedRemovedData | GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedReorderedData;

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges {
  __typename: "GuideCollectionPropagationFieldChange";
  type: GuideCollectionPropagationChangesTypeEnum;
  data: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate | null;
  schedulerAvailability: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability | null;
  configInterviewers: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers[];
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change = GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange | GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringFieldChange | GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange;

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate | null;
  emailTemplate: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_emailTemplate | null;
  postTemplate: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplate | null;
  postTemplates: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates[];
  atssyncJobStage: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_atssyncJobStage | null;
  schedulerConfig: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig | null;
  changes: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes[];
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates[];
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items[];
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_atsJob_atsJobStages {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_atsJob {
  __typename: "ATSJobRole";
  id: string;
  atsJobStages: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_atsJob_atsJobStages[];
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseInterview {
  __typename: "ATSInterview";
  id: string;
  name: string;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  atssyncGreenhouseJobStage: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseJobStage | null;
  atssyncGreenhouseInterview: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseInterview | null;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  guideTemplate: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_guideTemplate | null;
  name: string;
  jobDisplayName: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleNameOverride: string | null;
  inProgressGuidesCount: number;
  setupStatus: JobRoleGuideTemplateSetupStatus;
  defaultGuideVersion: number;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  changes: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes[];
  collectionChanges: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges[];
  jobRoleGuideTemplateStages: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages[];
  interviewPlan: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_interviewPlan | null;
  atsJob: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_atsJob | null;
  eventTemplates: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById_eventTemplates[];
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplateById: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization_jobRoleGuideTemplateById;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChanges {
  currentOrganization: GetJobRoleGuideTemplateForGlobalRestoreChanges_currentOrganization | null;
}

export interface GetJobRoleGuideTemplateForGlobalRestoreChangesVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RestoreGlobalJobRoleGuideTemplateChanges
// ====================================================

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes_change = RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes_change_BooleanFieldChange | RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes_change_StringFieldChange | RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes_change_StringArrayFieldChange;

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes {
  __typename: "GuidePropagationFieldChange";
  field: GuidePropagationChangesEnum;
  change: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes_change;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedAddedData {
  __typename: "CollectionFieldChangedAddedData";
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedRemovedData {
  __typename: "CollectionFieldChangedRemovedData";
  parentId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedReorderedData {
  __typename: "CollectionFieldChangedReorderedData";
  parentId: string | null;
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export type RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges_data = RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedAddedData | RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedRemovedData | RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedReorderedData;

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges {
  __typename: "GuideCollectionPropagationFieldChange";
  type: GuideCollectionPropagationChangesTypeEnum;
  data: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges_data;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate | null;
  schedulerAvailability: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability | null;
  configInterviewers: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers[];
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change = RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange | RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringFieldChange | RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange;

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate | null;
  emailTemplate: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_emailTemplate | null;
  postTemplate: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplate | null;
  postTemplates: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates[];
  atssyncJobStage: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_atssyncJobStage | null;
  schedulerConfig: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig | null;
  changes: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes[];
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_interviewPlan_items_postTemplates[];
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_interviewPlan_items[];
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_atsJob_atsJobStages {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_atsJob {
  __typename: "ATSJobRole";
  id: string;
  atsJobStages: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_atsJob_atsJobStages[];
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseInterview {
  __typename: "ATSInterview";
  id: string;
  name: string;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  atssyncGreenhouseJobStage: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseJobStage | null;
  atssyncGreenhouseInterview: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseInterview | null;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  jobDisplayName: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleNameOverride: string | null;
  inProgressGuidesCount: number;
  setupStatus: JobRoleGuideTemplateSetupStatus;
  defaultGuideVersion: number;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  changes: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes[];
  collectionChanges: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges[];
  jobRoleGuideTemplateStages: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages[];
  interviewPlan: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_interviewPlan | null;
  guideTemplate: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_guideTemplate | null;
  atsJob: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_atsJob | null;
  eventTemplates: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate_eventTemplates[];
}

export interface RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges {
  __typename: "RestoreGlobalJobRoleGuideTemplateChangesResponse";
  success: boolean;
  code: string;
  message: string;
  jobRoleGuideTemplate: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges_jobRoleGuideTemplate;
}

export interface RestoreGlobalJobRoleGuideTemplateChanges {
  restoreGlobalJobRoleGuideTemplateChanges: RestoreGlobalJobRoleGuideTemplateChanges_restoreGlobalJobRoleGuideTemplateChanges;
}

export interface RestoreGlobalJobRoleGuideTemplateChangesVariables {
  input: RestoreGlobalJobRoleGuideTemplateChangesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobRoleGuideTemplateForRestoreChanges
// ====================================================

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes_change = GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes_change_BooleanFieldChange | GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes_change_StringFieldChange | GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes_change_StringArrayFieldChange;

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes {
  __typename: "GuidePropagationFieldChange";
  field: GuidePropagationChangesEnum;
  change: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes_change;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedAddedData {
  __typename: "CollectionFieldChangedAddedData";
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedRemovedData {
  __typename: "CollectionFieldChangedRemovedData";
  parentId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedReorderedData {
  __typename: "CollectionFieldChangedReorderedData";
  parentId: string | null;
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export type GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data = GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedAddedData | GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedRemovedData | GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedReorderedData;

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges {
  __typename: "GuideCollectionPropagationFieldChange";
  type: GuideCollectionPropagationChangesTypeEnum;
  data: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate | null;
  schedulerAvailability: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability | null;
  configInterviewers: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers[];
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change = GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange | GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringFieldChange | GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange;

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate | null;
  emailTemplate: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_emailTemplate | null;
  postTemplate: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplate | null;
  postTemplates: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates[];
  atssyncJobStage: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_atssyncJobStage | null;
  schedulerConfig: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig | null;
  changes: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes[];
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates[];
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items[];
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_atsJob_atsJobStages {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_atsJob {
  __typename: "ATSJobRole";
  id: string;
  atsJobStages: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_atsJob_atsJobStages[];
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseInterview {
  __typename: "ATSInterview";
  id: string;
  name: string;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  atssyncGreenhouseJobStage: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseJobStage | null;
  atssyncGreenhouseInterview: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseInterview | null;
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  guideTemplate: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_guideTemplate | null;
  name: string;
  jobDisplayName: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleNameOverride: string | null;
  inProgressGuidesCount: number;
  setupStatus: JobRoleGuideTemplateSetupStatus;
  defaultGuideVersion: number;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  changes: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_changes[];
  collectionChanges: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_collectionChanges[];
  jobRoleGuideTemplateStages: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages[];
  interviewPlan: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_interviewPlan | null;
  atsJob: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_atsJob | null;
  eventTemplates: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById_eventTemplates[];
}

export interface GetJobRoleGuideTemplateForRestoreChanges_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplateById: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization_jobRoleGuideTemplateById;
}

export interface GetJobRoleGuideTemplateForRestoreChanges {
  currentOrganization: GetJobRoleGuideTemplateForRestoreChanges_currentOrganization | null;
}

export interface GetJobRoleGuideTemplateForRestoreChangesVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RestoreJobRoleGuideTemplateChanges
// ====================================================

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes_change = RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes_change_BooleanFieldChange | RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes_change_StringFieldChange | RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes_change_StringArrayFieldChange;

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes {
  __typename: "GuidePropagationFieldChange";
  field: GuidePropagationChangesEnum;
  change: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes_change;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedAddedData {
  __typename: "CollectionFieldChangedAddedData";
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedRemovedData {
  __typename: "CollectionFieldChangedRemovedData";
  parentId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedReorderedData {
  __typename: "CollectionFieldChangedReorderedData";
  parentId: string | null;
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export type RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges_data = RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedAddedData | RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedRemovedData | RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges_data_CollectionFieldChangedReorderedData;

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges {
  __typename: "GuideCollectionPropagationFieldChange";
  type: GuideCollectionPropagationChangesTypeEnum;
  data: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges_data;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate | null;
  schedulerAvailability: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability | null;
  configInterviewers: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers[];
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change = RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange | RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringFieldChange | RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange;

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes_change;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate | null;
  emailTemplate: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_emailTemplate | null;
  postTemplate: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplate | null;
  postTemplates: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_postTemplates[];
  atssyncJobStage: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_atssyncJobStage | null;
  schedulerConfig: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_schedulerConfig | null;
  changes: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages_changes[];
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_interviewPlan_items_postTemplates[];
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_interviewPlan_items[];
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_atsJob_atsJobStages {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_atsJob {
  __typename: "ATSJobRole";
  id: string;
  atsJobStages: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_atsJob_atsJobStages[];
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseInterview {
  __typename: "ATSInterview";
  id: string;
  name: string;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  atssyncGreenhouseJobStage: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseJobStage | null;
  atssyncGreenhouseInterview: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_eventTemplates_atssyncGreenhouseInterview | null;
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  jobDisplayName: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleNameOverride: string | null;
  inProgressGuidesCount: number;
  setupStatus: JobRoleGuideTemplateSetupStatus;
  defaultGuideVersion: number;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  changes: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_changes[];
  collectionChanges: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_collectionChanges[];
  jobRoleGuideTemplateStages: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_jobRoleGuideTemplateStages[];
  interviewPlan: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_interviewPlan | null;
  guideTemplate: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_guideTemplate | null;
  atsJob: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_atsJob | null;
  eventTemplates: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate_eventTemplates[];
}

export interface RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges {
  __typename: "RestoreJobRoleGuideTemplateChangesResponse";
  success: boolean;
  code: string;
  message: string;
  jobRoleGuideTemplate: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges_jobRoleGuideTemplate;
}

export interface RestoreJobRoleGuideTemplateChanges {
  restoreJobRoleGuideTemplateChanges: RestoreJobRoleGuideTemplateChanges_restoreJobRoleGuideTemplateChanges;
}

export interface RestoreJobRoleGuideTemplateChangesVariables {
  input: RestoreJobRoleGuideTemplateChangesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobRoleGuideTemplateStageForRestoreChanges
// ====================================================

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_jobRoleGuideTemplate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
}

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  guideTemplate: GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_jobRoleGuideTemplate_guideTemplate | null;
}

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_contentTemplate_modulesInflated[];
}

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_schedulerConfig_configInterviewers_interviewer;
}

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_schedulerConfig_eventTemplate | null;
  schedulerAvailability: GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_schedulerConfig_schedulerAvailability | null;
  configInterviewers: GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_schedulerConfig_configInterviewers[];
}

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_changes_change = GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_changes_change_BooleanFieldChange | GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_changes_change_StringFieldChange | GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_changes_change_StringArrayFieldChange;

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_changes_change;
}

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  jobRoleGuideTemplate: GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_jobRoleGuideTemplate;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_contentTemplate | null;
  emailTemplate: GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_emailTemplate | null;
  postTemplate: GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_postTemplate | null;
  postTemplates: GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_postTemplates[];
  atssyncJobStage: GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_atssyncJobStage | null;
  schedulerConfig: GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_schedulerConfig | null;
  changes: GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById_changes[];
}

export interface GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplateStageById: GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization_jobRoleGuideTemplateStageById;
}

export interface GetJobRoleGuideTemplateStageForRestoreChanges {
  currentOrganization: GetJobRoleGuideTemplateStageForRestoreChanges_currentOrganization | null;
}

export interface GetJobRoleGuideTemplateStageForRestoreChangesVariables {
  jobRoleGuideTemplateStageId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RestoreJobRoleGuideTemplateStageChanges
// ====================================================

export interface RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_contentTemplate_modulesInflated[];
}

export interface RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers_interviewer;
}

export interface RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_schedulerConfig_eventTemplate | null;
  schedulerAvailability: RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_schedulerConfig_schedulerAvailability | null;
  configInterviewers: RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_schedulerConfig_configInterviewers[];
}

export interface RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_changes_change = RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_changes_change_BooleanFieldChange | RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_changes_change_StringFieldChange | RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_changes_change_StringArrayFieldChange;

export interface RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_changes_change;
}

export interface RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_contentTemplate | null;
  emailTemplate: RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_emailTemplate | null;
  postTemplate: RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_postTemplate | null;
  postTemplates: RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_postTemplates[];
  atssyncJobStage: RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_atssyncJobStage | null;
  schedulerConfig: RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_schedulerConfig | null;
  changes: RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage_changes[];
}

export interface RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges {
  __typename: "RestoreJobRoleGuideTemplateStageChangesResponse";
  success: boolean;
  code: string;
  message: string;
  jobRoleGuideTemplateStage: RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges_jobRoleGuideTemplateStage;
}

export interface RestoreJobRoleGuideTemplateStageChanges {
  restoreJobRoleGuideTemplateStageChanges: RestoreJobRoleGuideTemplateStageChanges_restoreJobRoleGuideTemplateStageChanges;
}

export interface RestoreJobRoleGuideTemplateStageChangesVariables {
  input: RestoreJobRoleGuideTemplateStageChangesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewersForGuideEditor
// ====================================================

export interface InterviewersForGuideEditor_currentOrganization_schedulerAvailabilities {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface InterviewersForGuideEditor_currentOrganization_interviewers {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface InterviewersForGuideEditor_currentOrganization {
  __typename: "Organization";
  id: string;
  schedulerAvailabilities: InterviewersForGuideEditor_currentOrganization_schedulerAvailabilities[];
  interviewers: InterviewersForGuideEditor_currentOrganization_interviewers[];
}

export interface InterviewersForGuideEditor {
  currentOrganization: InterviewersForGuideEditor_currentOrganization | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCandidateTimezone
// ====================================================

export interface UpdateCandidateTimezone_updateCandidateTimezone_candidate {
  __typename: "Candidate";
  id: string;
  currentTimezone: string;
}

export interface UpdateCandidateTimezone_updateCandidateTimezone {
  __typename: "UpdateCandidateTimezoneMutationResponse";
  success: boolean;
  candidate: UpdateCandidateTimezone_updateCandidateTimezone_candidate | null;
}

export interface UpdateCandidateTimezone {
  updateCandidateTimezone: UpdateCandidateTimezone_updateCandidateTimezone;
}

export interface UpdateCandidateTimezoneVariables {
  input: UpdateCandidateTimezoneInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CanSendEmail
// ====================================================

export interface CanSendEmail_currentUserPrisma {
  __typename: "User";
  id: string;
  canSendEmail: boolean | null;
}

export interface CanSendEmail {
  currentUserPrisma: CanSendEmail_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendGuideConfirmationEmailMutation
// ====================================================

export interface SendGuideConfirmationEmailMutation_sendCandidateConfirmation_emailActivity_stage_emailActivities_sender {
  __typename: "User";
  id: string;
  imageUrl: string;
  fullName: string;
}

export interface SendGuideConfirmationEmailMutation_sendCandidateConfirmation_emailActivity_stage_emailActivities {
  __typename: "EmailActivity";
  id: string;
  createdAt: GraphQL_DateTime;
  sentTo: string;
  sentFrom: string;
  subject: string;
  body: string;
  sender: SendGuideConfirmationEmailMutation_sendCandidateConfirmation_emailActivity_stage_emailActivities_sender | null;
}

export interface SendGuideConfirmationEmailMutation_sendCandidateConfirmation_emailActivity_stage {
  __typename: "Stage";
  id: string;
  emailActivities: SendGuideConfirmationEmailMutation_sendCandidateConfirmation_emailActivity_stage_emailActivities[];
}

export interface SendGuideConfirmationEmailMutation_sendCandidateConfirmation_emailActivity_guide_emailActivities_sender {
  __typename: "User";
  id: string;
  imageUrl: string;
  fullName: string;
}

export interface SendGuideConfirmationEmailMutation_sendCandidateConfirmation_emailActivity_guide_emailActivities {
  __typename: "EmailActivity";
  id: string;
  createdAt: GraphQL_DateTime;
  sentTo: string;
  sentFrom: string;
  subject: string;
  body: string;
  sender: SendGuideConfirmationEmailMutation_sendCandidateConfirmation_emailActivity_guide_emailActivities_sender | null;
}

export interface SendGuideConfirmationEmailMutation_sendCandidateConfirmation_emailActivity_guide {
  __typename: "Guide";
  id: string;
  emailActivities: SendGuideConfirmationEmailMutation_sendCandidateConfirmation_emailActivity_guide_emailActivities[];
}

export interface SendGuideConfirmationEmailMutation_sendCandidateConfirmation_emailActivity {
  __typename: "EmailActivity";
  id: string;
  stage: SendGuideConfirmationEmailMutation_sendCandidateConfirmation_emailActivity_stage | null;
  guide: SendGuideConfirmationEmailMutation_sendCandidateConfirmation_emailActivity_guide | null;
}

export interface SendGuideConfirmationEmailMutation_sendCandidateConfirmation {
  __typename: "SendCandidateConfirmationMutationResponse";
  success: boolean;
  message: string;
  code: string;
  emailActivity: SendGuideConfirmationEmailMutation_sendCandidateConfirmation_emailActivity | null;
}

export interface SendGuideConfirmationEmailMutation {
  sendCandidateConfirmation: SendGuideConfirmationEmailMutation_sendCandidateConfirmation;
}

export interface SendGuideConfirmationEmailMutationVariables {
  input: SendCandidateConfirmationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendGuideTestEmailMutation
// ====================================================

export interface SendGuideTestEmailMutation_sendTestConfirmation {
  __typename: "SendTestConfirmationMutationResponse";
  success: boolean;
  message: string;
  code: string;
}

export interface SendGuideTestEmailMutation {
  sendTestConfirmation: SendGuideTestEmailMutation_sendTestConfirmation;
}

export interface SendGuideTestEmailMutationVariables {
  input: SendTestConfirmationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetStageCustomVaribles
// ====================================================

export interface SetStageCustomVaribles_updateStageCustomVariables_stage_guide_candidate_organization {
  __typename: "Organization";
  id: string;
  customVariableOptions: string[];
}

export interface SetStageCustomVaribles_updateStageCustomVariables_stage_guide_candidate {
  __typename: "Candidate";
  id: string;
  organization: SetStageCustomVaribles_updateStageCustomVariables_stage_guide_candidate_organization;
}

export interface SetStageCustomVaribles_updateStageCustomVariables_stage_guide {
  __typename: "Guide";
  id: string;
  candidate: SetStageCustomVaribles_updateStageCustomVariables_stage_guide_candidate;
}

export interface SetStageCustomVaribles_updateStageCustomVariables_stage {
  __typename: "Stage";
  id: string;
  customVariables: GraphQL_JSON;
  guide: SetStageCustomVaribles_updateStageCustomVariables_stage_guide;
}

export interface SetStageCustomVaribles_updateStageCustomVariables {
  __typename: "UpdateStageCustomVariablesMutationResponse";
  success: boolean;
  message: string;
  code: string;
  stage: SetStageCustomVaribles_updateStageCustomVariables_stage | null;
}

export interface SetStageCustomVaribles {
  updateStageCustomVariables: SetStageCustomVaribles_updateStageCustomVariables;
}

export interface SetStageCustomVariblesVariables {
  input: UpdateStageCustomVariablesInput;
  skipGuideHacks: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetStageCustomHiringTeam
// ====================================================

export interface SetStageCustomHiringTeam_updateStageCustomHiringTeam_stage_customHiringTeam {
  __typename: "Interviewer";
  id: string;
  name: string;
  email: string | null;
}

export interface SetStageCustomHiringTeam_updateStageCustomHiringTeam_stage_guide_candidate_organization_interviewers {
  __typename: "Interviewer";
  id: string;
  name: string;
  email: string | null;
  displayImageUrl: string;
}

export interface SetStageCustomHiringTeam_updateStageCustomHiringTeam_stage_guide_candidate_organization {
  __typename: "Organization";
  id: string;
  interviewers: SetStageCustomHiringTeam_updateStageCustomHiringTeam_stage_guide_candidate_organization_interviewers[];
}

export interface SetStageCustomHiringTeam_updateStageCustomHiringTeam_stage_guide_candidate {
  __typename: "Candidate";
  id: string;
  organization: SetStageCustomHiringTeam_updateStageCustomHiringTeam_stage_guide_candidate_organization;
}

export interface SetStageCustomHiringTeam_updateStageCustomHiringTeam_stage_guide {
  __typename: "Guide";
  id: string;
  candidate: SetStageCustomHiringTeam_updateStageCustomHiringTeam_stage_guide_candidate;
}

export interface SetStageCustomHiringTeam_updateStageCustomHiringTeam_stage {
  __typename: "Stage";
  id: string;
  customHiringTeam: SetStageCustomHiringTeam_updateStageCustomHiringTeam_stage_customHiringTeam[];
  guide: SetStageCustomHiringTeam_updateStageCustomHiringTeam_stage_guide;
}

export interface SetStageCustomHiringTeam_updateStageCustomHiringTeam {
  __typename: "UpdateStageCustomHiringTeamMutationResponse";
  success: boolean;
  message: string;
  code: string;
  stage: SetStageCustomHiringTeam_updateStageCustomHiringTeam_stage | null;
}

export interface SetStageCustomHiringTeam {
  updateStageCustomHiringTeam: SetStageCustomHiringTeam_updateStageCustomHiringTeam;
}

export interface SetStageCustomHiringTeamVariables {
  input: UpdateStageCustomHiringTeamInput;
  skipGuideHacks: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideModuleContextForPreview
// ====================================================

export interface GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_interviews_prominentInstructions {
  __typename: "ModuleResolutionProminentInstructionContext";
  id: GraphQL_UUID;
  label: string;
  url: string | null;
}

export interface GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_interviews_interviewers {
  __typename: "ModuleResolutionInterviewerContext";
  id: GraphQL_UUID;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  pronoun: string | null;
  videoIntroductionUrl: string | null;
}

export interface GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_interviews {
  __typename: "ModuleResolutionInterviewContext";
  id: GraphQL_UUID;
  atsJobStageId: string | null;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  title: string;
  description: string;
  variableReplacements: GraphQL_JSON;
  eventTemplateId: string | null;
  prominentInstructions: GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_interviews_prominentInstructions[];
  interviewers: GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_interviews_interviewers[];
}

export interface GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_stages_schedulerConfig_interviewers {
  __typename: "ModuleResolutionSchedulerConfigInterviewerContext";
  id: GraphQL_UUID;
  firstName: string;
  lastName: string;
  imageUrl: string;
}

export interface GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_stages_schedulerConfig {
  __typename: "ModuleResolutionSchedulerConfigContext";
  availabilitySlug: string | null;
  eventTemplateId: string | null;
  interviewers: GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_stages_schedulerConfig_interviewers[];
  eventTemplateInstallationId: string | null;
}

export interface GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_stages_customData_hiringTeam {
  __typename: "ModuleResolutionInterviewerContext";
  id: GraphQL_UUID;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  pronoun: string | null;
  videoIntroductionUrl: string | null;
}

export interface GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_stages_customData {
  __typename: "StageCustomData";
  hiringTeam: GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_stages_customData_hiringTeam[];
  customVariables: GraphQL_JSON | null;
}

export interface GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_stages {
  __typename: "ModuleResolutionStageContext";
  id: string;
  atsJobStageId: string;
  needsFeedback: boolean;
  variableReplacements: GraphQL_JSON;
  schedulerConfig: GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_stages_schedulerConfig | null;
  customData: GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_stages_customData;
}

export interface GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_candidate {
  __typename: "ModuleResolutionCandidateContext";
  id: GraphQL_UUID;
  email: string | null;
  firstName: string;
  fullName: string;
  imageUrl: string;
}

export interface GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_organization_values {
  __typename: "ModuleResolutionValueContext";
  id: GraphQL_UUID;
  title: string;
  description: string;
}

export interface GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_organization {
  __typename: "ModuleResolutionOrganizationContext";
  id: GraphQL_UUID;
  name: string;
  addToCalendarEnabled: boolean;
  missionStatement: string;
  values: GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_organization_values[];
}

export interface GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_job {
  __typename: "ModuleResolutionJobContext";
  id: GraphQL_UUID;
  name: string;
  greenhouseJobUrl: string;
}

export interface GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext {
  __typename: "ModuleResolutionGuideContext";
  id: GraphQL_UUID;
  publicUrl: string;
  recruiterEmailAddress: string;
  recruiterFirstName: string;
  interviews: GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_interviews[];
  stages: GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_stages[];
  candidate: GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_candidate;
  organization: GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_organization;
  job: GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext_job;
}

export interface GuideModuleContextForPreview_guideFindUnique {
  __typename: "Guide";
  id: string;
  interviewProcessEnabled: boolean;
  moduleResolutionContext: GuideModuleContextForPreview_guideFindUnique_moduleResolutionContext;
}

export interface GuideModuleContextForPreview {
  guideFindUnique: GuideModuleContextForPreview_guideFindUnique;
}

export interface GuideModuleContextForPreviewVariables {
  where: GuideWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationForModuleResolution
// ====================================================

export interface OrganizationForModuleResolution_currentUserPrisma_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface OrganizationForModuleResolution_currentUserPrisma_currentOrganization_values {
  __typename: "Value";
  id: GraphQL_UUID;
  title: string;
  description: string;
}

export interface OrganizationForModuleResolution_currentUserPrisma_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  addToCalendarEnabled: boolean;
}

export interface OrganizationForModuleResolution_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  name: string;
  missionStatement: string;
  theme: OrganizationForModuleResolution_currentUserPrisma_currentOrganization_theme | null;
  values: OrganizationForModuleResolution_currentUserPrisma_currentOrganization_values[];
  customer: OrganizationForModuleResolution_currentUserPrisma_currentOrganization_customer;
}

export interface OrganizationForModuleResolution_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: OrganizationForModuleResolution_currentUserPrisma_currentOrganization | null;
}

export interface OrganizationForModuleResolution {
  currentUserPrisma: OrganizationForModuleResolution_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuidesForHomepage
// ====================================================

export interface GuidesForHomepage_currentUser_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
}

export interface GuidesForHomepage_currentUser_currentOrganization_guidesV2_upcomingInterviews {
  __typename: "Event";
  id: string;
  displayTitle: string;
  replacementData: GraphQL_JSON;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
}

export interface GuidesForHomepage_currentUser_currentOrganization_guidesV2_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
}

export interface GuidesForHomepage_currentUser_currentOrganization_guidesV2_currentStage {
  __typename: "Stage";
  id: string;
  title: string;
  position: number;
}

export interface GuidesForHomepage_currentUser_currentOrganization_guidesV2 {
  __typename: "Guide";
  id: string;
  atsUrl: string;
  publicUrl: string;
  roleName: string;
  stageCount: number;
  currentStagePosition: number | null;
  upcomingInterviews: GuidesForHomepage_currentUser_currentOrganization_guidesV2_upcomingInterviews[];
  candidate: GuidesForHomepage_currentUser_currentOrganization_guidesV2_candidate;
  currentStage: GuidesForHomepage_currentUser_currentOrganization_guidesV2_currentStage | null;
  jobRoleGuideTemplateId: string | null;
  lastEmailActivityAt: GraphQL_DateTime | null;
  candidateOpens: string[];
}

export interface GuidesForHomepage_currentUser_currentOrganization {
  __typename: "Organization";
  id: string;
  defaultAvatarImageUrl: string;
  theme: GuidesForHomepage_currentUser_currentOrganization_theme | null;
  name: string;
  guidesV2: GuidesForHomepage_currentUser_currentOrganization_guidesV2[];
  guidesCount: number;
}

export interface GuidesForHomepage_currentUser {
  __typename: "User";
  id: string;
  currentOrganization: GuidesForHomepage_currentUser_currentOrganization | null;
}

export interface GuidesForHomepage {
  currentUser: GuidesForHomepage_currentUser | null;
}

export interface GuidesForHomepageVariables {
  search?: string | null;
  limit: number;
  offset: number;
  orderBy?: OrganizationGuidesOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuidesList
// ====================================================

export interface GuidesList_currentUser_currentOrganization_guides_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  lastName: string;
}

export interface GuidesList_currentUser_currentOrganization_guides_currentStage_nextInterview {
  __typename: "Event";
  id: string;
  startTime: GraphQL_DateTime;
}

export interface GuidesList_currentUser_currentOrganization_guides_currentStage {
  __typename: "Stage";
  id: string;
  nextInterview: GuidesList_currentUser_currentOrganization_guides_currentStage_nextInterview | null;
  lastEmailActivityAt: GraphQL_DateTime | null;
}

export interface GuidesList_currentUser_currentOrganization_guides {
  __typename: "Guide";
  id: string;
  isDemo: boolean;
  atsUrl: string;
  publicUrl: string;
  candidate: GuidesList_currentUser_currentOrganization_guides_candidate;
  roleName: string;
  currentStage: GuidesList_currentUser_currentOrganization_guides_currentStage | null;
  lastEmailActivityAt: GraphQL_DateTime | null;
  candidateOpens: string[];
}

export interface GuidesList_currentUser_currentOrganization {
  __typename: "Organization";
  id: string;
  guides: GuidesList_currentUser_currentOrganization_guides[];
}

export interface GuidesList_currentUser {
  __typename: "User";
  id: string;
  currentOrganization: GuidesList_currentUser_currentOrganization | null;
}

export interface GuidesList {
  currentUser: GuidesList_currentUser | null;
}

export interface GuidesListVariables {
  search?: string | null;
  limit: number;
  offset: number;
  orderBy?: OrganizationGuidesOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveGuideTemplate
// ====================================================

export interface ArchiveGuideTemplate_archiveGuideTemplate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  archivedAt: GraphQL_DateTime | null;
}

export interface ArchiveGuideTemplate_archiveGuideTemplate {
  __typename: "ArchiveGuideTemplateResponse";
  success: boolean;
  message: string;
  guideTemplate: ArchiveGuideTemplate_archiveGuideTemplate_guideTemplate;
}

export interface ArchiveGuideTemplate {
  archiveGuideTemplate: ArchiveGuideTemplate_archiveGuideTemplate;
}

export interface ArchiveGuideTemplateVariables {
  input: ArchiveGuideTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGuideTemplate
// ====================================================

export interface CreateGuideTemplate_createGuideTemplate_guideTemplate_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface CreateGuideTemplate_createGuideTemplate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  isGlobal: boolean;
  department: CreateGuideTemplate_createGuideTemplate_guideTemplate_department | null;
}

export interface CreateGuideTemplate_createGuideTemplate {
  __typename: "CreateGuideTemplateResponse";
  success: boolean;
  message: string;
  guideTemplate: CreateGuideTemplate_createGuideTemplate_guideTemplate;
}

export interface CreateGuideTemplate {
  createGuideTemplate: CreateGuideTemplate_createGuideTemplate;
}

export interface CreateGuideTemplateVariables {
  input: CreateGuideTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DuplicateGuideTemplate
// ====================================================

export interface DuplicateGuideTemplate_duplicateGuideTemplate_guideTemplate_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface DuplicateGuideTemplate_duplicateGuideTemplate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  isGlobal: boolean;
  department: DuplicateGuideTemplate_duplicateGuideTemplate_guideTemplate_department | null;
}

export interface DuplicateGuideTemplate_duplicateGuideTemplate {
  __typename: "DuplicateGuideTemplateResponse";
  success: boolean;
  message: string;
  guideTemplate: DuplicateGuideTemplate_duplicateGuideTemplate_guideTemplate;
}

export interface DuplicateGuideTemplate {
  duplicateGuideTemplate: DuplicateGuideTemplate_duplicateGuideTemplate;
}

export interface DuplicateGuideTemplateVariables {
  input: DuplicateGuideTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ForcePropagateGuideTemplateToGuides
// ====================================================

export interface ForcePropagateGuideTemplateToGuides_forcePropagateGuideTemplateToGuides {
  __typename: "ForcePropagateGuideTemplateToGuidesResponse";
  success: boolean;
  code: string;
  message: string;
}

export interface ForcePropagateGuideTemplateToGuides {
  forcePropagateGuideTemplateToGuides: ForcePropagateGuideTemplateToGuides_forcePropagateGuideTemplateToGuides;
}

export interface ForcePropagateGuideTemplateToGuidesVariables {
  input: ForcePropagateGuideTemplateToGuidesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnarchiveGuideTemplateDetailsPage
// ====================================================

export interface UnarchiveGuideTemplateDetailsPage_unarchiveGuideTemplate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  archivedAt: GraphQL_DateTime | null;
}

export interface UnarchiveGuideTemplateDetailsPage_unarchiveGuideTemplate {
  __typename: "UnarchiveGuideTemplateResponse";
  success: boolean;
  message: string;
  guideTemplate: UnarchiveGuideTemplateDetailsPage_unarchiveGuideTemplate_guideTemplate;
}

export interface UnarchiveGuideTemplateDetailsPage {
  unarchiveGuideTemplate: UnarchiveGuideTemplateDetailsPage_unarchiveGuideTemplate;
}

export interface UnarchiveGuideTemplateDetailsPageVariables {
  input: UnarchiveGuideTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGuideTemplateForEditGuideHeader
// ====================================================

export interface GetGuideTemplateForEditGuideHeader_currentOrganization_guideTemplateById_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface GetGuideTemplateForEditGuideHeader_currentOrganization_guideTemplateById_createdBy_personalProfile {
  __typename: "Interviewer";
  firstName: string;
  lastName: string;
  imageUrl: string;
}

export interface GetGuideTemplateForEditGuideHeader_currentOrganization_guideTemplateById_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: GetGuideTemplateForEditGuideHeader_currentOrganization_guideTemplateById_createdBy_personalProfile;
}

export interface GetGuideTemplateForEditGuideHeader_currentOrganization_guideTemplateById {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  usage: number;
  archivedAt: GraphQL_DateTime | null;
  inProgressGuidesCount: number;
  department: GetGuideTemplateForEditGuideHeader_currentOrganization_guideTemplateById_department | null;
  createdBy: GetGuideTemplateForEditGuideHeader_currentOrganization_guideTemplateById_createdBy | null;
  isGlobal: boolean;
}

export interface GetGuideTemplateForEditGuideHeader_currentOrganization {
  __typename: "Organization";
  id: string;
  guideTemplateById: GetGuideTemplateForEditGuideHeader_currentOrganization_guideTemplateById;
}

export interface GetGuideTemplateForEditGuideHeader {
  currentOrganization: GetGuideTemplateForEditGuideHeader_currentOrganization | null;
}

export interface GetGuideTemplateForEditGuideHeaderVariables {
  guideTemplateId: string;
  isZeus: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditGuideTemplate
// ====================================================

export interface EditGuideTemplate_updateGuideTemplate_guideTemplate_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface EditGuideTemplate_updateGuideTemplate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  isGlobal: boolean;
  department: EditGuideTemplate_updateGuideTemplate_guideTemplate_department | null;
}

export interface EditGuideTemplate_updateGuideTemplate {
  __typename: "UpdateGuideTemplateResponse";
  success: boolean;
  message: string;
  guideTemplate: EditGuideTemplate_updateGuideTemplate_guideTemplate | null;
}

export interface EditGuideTemplate {
  updateGuideTemplate: EditGuideTemplate_updateGuideTemplate;
}

export interface EditGuideTemplateVariables {
  input: UpdateGuideTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnarchiveGuideTemplate
// ====================================================

export interface UnarchiveGuideTemplate_unarchiveGuideTemplate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  archivedAt: GraphQL_DateTime | null;
}

export interface UnarchiveGuideTemplate_unarchiveGuideTemplate {
  __typename: "UnarchiveGuideTemplateResponse";
  success: boolean;
  message: string;
  guideTemplate: UnarchiveGuideTemplate_unarchiveGuideTemplate_guideTemplate;
}

export interface UnarchiveGuideTemplate {
  unarchiveGuideTemplate: UnarchiveGuideTemplate_unarchiveGuideTemplate;
}

export interface UnarchiveGuideTemplateVariables {
  input: UnarchiveGuideTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobRoleGuideTemplatesUsingGuideTemplate
// ====================================================

export interface JobRoleGuideTemplatesUsingGuideTemplate_currentOrganization_jobRoleGuideTemplates_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
}

export interface JobRoleGuideTemplatesUsingGuideTemplate_currentOrganization_jobRoleGuideTemplates {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  inProgressGuidesCount: number;
  guideTemplate: JobRoleGuideTemplatesUsingGuideTemplate_currentOrganization_jobRoleGuideTemplates_guideTemplate | null;
}

export interface JobRoleGuideTemplatesUsingGuideTemplate_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplates: JobRoleGuideTemplatesUsingGuideTemplate_currentOrganization_jobRoleGuideTemplates[];
}

export interface JobRoleGuideTemplatesUsingGuideTemplate {
  currentOrganization: JobRoleGuideTemplatesUsingGuideTemplate_currentOrganization | null;
}

export interface JobRoleGuideTemplatesUsingGuideTemplateVariables {
  where: OrganizationJobRoleGuideTemplateWhereInput;
  offset?: number | null;
  limit?: number | null;
  orderBy?: OrganizationJobRoleGuideTemplateOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DisableGuide
// ====================================================

export interface DisableGuide_disableGuide_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
}

export interface DisableGuide_disableGuide {
  __typename: "DisableGuideMutationResponse";
  success: boolean;
  guide: DisableGuide_disableGuide_guide | null;
}

export interface DisableGuide {
  disableGuide: DisableGuide_disableGuide;
}

export interface DisableGuideVariables {
  input: DisableGuideInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EnableGuide
// ====================================================

export interface EnableGuide_enableGuide_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
}

export interface EnableGuide_enableGuide {
  __typename: "EnableGuideMutationResponse";
  success: boolean;
  guide: EnableGuide_enableGuide_guide | null;
}

export interface EnableGuide {
  enableGuide: EnableGuide_enableGuide;
}

export interface EnableGuideVariables {
  input: EnableGuideInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AutoConfigureStagesForGuide
// ====================================================

export interface AutoConfigureStagesForGuide_activateStageTemplateInstallation {
  __typename: "ActivateStageTemplateInstallationMutationResponse";
  success: boolean;
}

export interface AutoConfigureStagesForGuide {
  activateStageTemplateInstallation: AutoConfigureStagesForGuide_activateStageTemplateInstallation;
}

export interface AutoConfigureStagesForGuideVariables {
  input: ActivateStageTemplateInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentUserForFlags
// ====================================================

export interface CurrentUserForFlags_currentUserPrisma_currentUserMembership {
  __typename: "UserMembership";
  id: string;
  roles: string[];
}

export interface CurrentUserForFlags_currentUserPrisma_currentOrganization_customer_plan {
  __typename: "CustomerPlan";
  id: string;
  name: string;
}

export interface CurrentUserForFlags_currentUserPrisma_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  name: string;
  grandfatheredAllFeaturesPlanEnabled: boolean;
  plan: CurrentUserForFlags_currentUserPrisma_currentOrganization_customer_plan;
}

export interface CurrentUserForFlags_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  name: string;
  zeusStatus: ZeusStatusEnum;
  customer: CurrentUserForFlags_currentUserPrisma_currentOrganization_customer;
}

export interface CurrentUserForFlags_currentUserPrisma {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  primaryEmail: string | null;
  currentUserMembership: CurrentUserForFlags_currentUserPrisma_currentUserMembership | null;
  currentOrganization: CurrentUserForFlags_currentUserPrisma_currentOrganization | null;
}

export interface CurrentUserForFlags {
  currentUserPrisma: CurrentUserForFlags_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideDetailsForIdentity
// ====================================================

export interface GuideDetailsForIdentity_guideFindUniqueByShortId_candidate_organization_customer_plan {
  __typename: "CustomerPlan";
  id: string;
  name: string;
}

export interface GuideDetailsForIdentity_guideFindUniqueByShortId_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  name: string;
  grandfatheredAllFeaturesPlanEnabled: boolean;
  plan: GuideDetailsForIdentity_guideFindUniqueByShortId_candidate_organization_customer_plan;
}

export interface GuideDetailsForIdentity_guideFindUniqueByShortId_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
  zeusStatus: ZeusStatusEnum;
  customer: GuideDetailsForIdentity_guideFindUniqueByShortId_candidate_organization_customer;
}

export interface GuideDetailsForIdentity_guideFindUniqueByShortId_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  organization: GuideDetailsForIdentity_guideFindUniqueByShortId_candidate_organization;
}

export interface GuideDetailsForIdentity_guideFindUniqueByShortId {
  __typename: "Guide";
  id: string;
  candidate: GuideDetailsForIdentity_guideFindUniqueByShortId_candidate;
}

export interface GuideDetailsForIdentity {
  guideFindUniqueByShortId: GuideDetailsForIdentity_guideFindUniqueByShortId | null;
}

export interface GuideDetailsForIdentityVariables {
  customerSlug: string;
  guideShortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GenerateUploadcareSignature
// ====================================================

export interface GenerateUploadcareSignature_uploadcareSignature {
  __typename: "UploadcareConfig";
  signature: string;
  expire: string;
}

export interface GenerateUploadcareSignature {
  uploadcareSignature: GenerateUploadcareSignature_uploadcareSignature | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InterviewTableHideInterview
// ====================================================

export interface InterviewTableHideInterview_hideEventTemplate_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  hidden: boolean;
}

export interface InterviewTableHideInterview_hideEventTemplate {
  __typename: "HideEventTemplateMutationResponse";
  success: boolean;
  message: string;
  eventTemplate: InterviewTableHideInterview_hideEventTemplate_eventTemplate | null;
}

export interface InterviewTableHideInterview {
  hideEventTemplate: InterviewTableHideInterview_hideEventTemplate;
}

export interface InterviewTableHideInterviewVariables {
  interviewId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InterviewTableShowInterview
// ====================================================

export interface InterviewTableShowInterview_showEventTemplate_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  hidden: boolean;
}

export interface InterviewTableShowInterview_showEventTemplate {
  __typename: "ShowEventTemplateMutationResponse";
  success: boolean;
  message: string;
  eventTemplate: InterviewTableShowInterview_showEventTemplate_eventTemplate | null;
}

export interface InterviewTableShowInterview {
  showEventTemplate: InterviewTableShowInterview_showEventTemplate;
}

export interface InterviewTableShowInterviewVariables {
  interviewId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateInterviewTemplate
// ====================================================

export interface CreateInterviewTemplate_createInterviewTemplate_interviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
}

export interface CreateInterviewTemplate_createInterviewTemplate {
  __typename: "CreateInterviewTemplateMutationResponse";
  message: string;
  success: boolean;
  interviewTemplate: CreateInterviewTemplate_createInterviewTemplate_interviewTemplate | null;
}

export interface CreateInterviewTemplate {
  createInterviewTemplate: CreateInterviewTemplate_createInterviewTemplate;
}

export interface CreateInterviewTemplateVariables {
  input: CreateInterviewTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchDuplicateInterviewTemplate
// ====================================================

export interface FetchDuplicateInterviewTemplate_currentOrganization_interviewTemplateById_department {
  __typename: "ATSDepartment";
  id: string;
}

export interface FetchDuplicateInterviewTemplate_currentOrganization_interviewTemplateById {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  description: GraphQL_JSON | null;
  title: GraphQL_JSON | null;
  department: FetchDuplicateInterviewTemplate_currentOrganization_interviewTemplateById_department | null;
}

export interface FetchDuplicateInterviewTemplate_currentOrganization {
  __typename: "Organization";
  id: string;
  interviewTemplateById: FetchDuplicateInterviewTemplate_currentOrganization_interviewTemplateById;
}

export interface FetchDuplicateInterviewTemplate {
  currentOrganization: FetchDuplicateInterviewTemplate_currentOrganization | null;
}

export interface FetchDuplicateInterviewTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckForTemplateNameDuplicateOnCreate
// ====================================================

export interface CheckForTemplateNameDuplicateOnCreate_currentOrganization_interviewTemplateByName {
  __typename: "InterviewTemplate";
  id: string;
}

export interface CheckForTemplateNameDuplicateOnCreate_currentOrganization {
  __typename: "Organization";
  id: string;
  interviewTemplateByName: CheckForTemplateNameDuplicateOnCreate_currentOrganization_interviewTemplateByName | null;
}

export interface CheckForTemplateNameDuplicateOnCreate {
  currentOrganization: CheckForTemplateNameDuplicateOnCreate_currentOrganization | null;
}

export interface CheckForTemplateNameDuplicateOnCreateVariables {
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditInterviewTemplate
// ====================================================

export interface EditInterviewTemplate_editInterviewTemplate_interviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  description: GraphQL_JSON | null;
  title: GraphQL_JSON | null;
  status: InterviewTemplateStatusEnum | null;
}

export interface EditInterviewTemplate_editInterviewTemplate {
  __typename: "EditInterviewTemplateMutationResponse";
  message: string;
  success: boolean;
  interviewTemplate: EditInterviewTemplate_editInterviewTemplate_interviewTemplate | null;
}

export interface EditInterviewTemplate {
  editInterviewTemplate: EditInterviewTemplate_editInterviewTemplate;
}

export interface EditInterviewTemplateVariables {
  input: EditInterviewTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchEditInterviewTemplate
// ====================================================

export interface FetchEditInterviewTemplate_currentOrganization_interviewTemplateById {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  description: GraphQL_JSON | null;
  title: GraphQL_JSON | null;
  status: InterviewTemplateStatusEnum | null;
}

export interface FetchEditInterviewTemplate_currentOrganization {
  __typename: "Organization";
  id: string;
  interviewTemplateById: FetchEditInterviewTemplate_currentOrganization_interviewTemplateById;
}

export interface FetchEditInterviewTemplate {
  currentOrganization: FetchEditInterviewTemplate_currentOrganization | null;
}

export interface FetchEditInterviewTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchUpdateInterviewTemplate
// ====================================================

export interface FetchUpdateInterviewTemplate_currentOrganization_interviewTemplateById_department {
  __typename: "ATSDepartment";
  id: string;
}

export interface FetchUpdateInterviewTemplate_currentOrganization_interviewTemplateById {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  department: FetchUpdateInterviewTemplate_currentOrganization_interviewTemplateById_department | null;
}

export interface FetchUpdateInterviewTemplate_currentOrganization {
  __typename: "Organization";
  id: string;
  interviewTemplateById: FetchUpdateInterviewTemplate_currentOrganization_interviewTemplateById;
}

export interface FetchUpdateInterviewTemplate_atsDepartments {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface FetchUpdateInterviewTemplate {
  currentOrganization: FetchUpdateInterviewTemplate_currentOrganization | null;
  atsDepartments: FetchUpdateInterviewTemplate_atsDepartments[];
}

export interface FetchUpdateInterviewTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateInterviewTemplate
// ====================================================

export interface UpdateInterviewTemplate_updateInterviewTemplate_interviewTemplate_department {
  __typename: "ATSDepartment";
  id: string;
}

export interface UpdateInterviewTemplate_updateInterviewTemplate_interviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  department: UpdateInterviewTemplate_updateInterviewTemplate_interviewTemplate_department | null;
}

export interface UpdateInterviewTemplate_updateInterviewTemplate {
  __typename: "UpdateInterviewTemplateMutationResponse";
  message: string;
  success: boolean;
  interviewTemplate: UpdateInterviewTemplate_updateInterviewTemplate_interviewTemplate | null;
}

export interface UpdateInterviewTemplate {
  updateInterviewTemplate: UpdateInterviewTemplate_updateInterviewTemplate;
}

export interface UpdateInterviewTemplateVariables {
  input: UpdateInterviewTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckForTemplateNameDuplicateOnEdit
// ====================================================

export interface CheckForTemplateNameDuplicateOnEdit_currentOrganization_interviewTemplateByName {
  __typename: "InterviewTemplate";
  id: string;
}

export interface CheckForTemplateNameDuplicateOnEdit_currentOrganization {
  __typename: "Organization";
  id: string;
  interviewTemplateByName: CheckForTemplateNameDuplicateOnEdit_currentOrganization_interviewTemplateByName | null;
}

export interface CheckForTemplateNameDuplicateOnEdit {
  currentOrganization: CheckForTemplateNameDuplicateOnEdit_currentOrganization | null;
}

export interface CheckForTemplateNameDuplicateOnEditVariables {
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewTemplateInstallations
// ====================================================

export interface InterviewTemplateInstallations_currentOrganization_interviewTemplateById_installationsSimpleView {
  __typename: "InterviewTemplateInstallationSimpleView";
  id: string;
  name: string;
  jobName: string | null;
  stageName: string | null;
}

export interface InterviewTemplateInstallations_currentOrganization_interviewTemplateById {
  __typename: "InterviewTemplate";
  id: string;
  installationsSimpleView: InterviewTemplateInstallations_currentOrganization_interviewTemplateById_installationsSimpleView[];
}

export interface InterviewTemplateInstallations_currentOrganization {
  __typename: "Organization";
  id: string;
  interviewTemplateById: InterviewTemplateInstallations_currentOrganization_interviewTemplateById;
}

export interface InterviewTemplateInstallations {
  currentOrganization: InterviewTemplateInstallations_currentOrganization | null;
}

export interface InterviewTemplateInstallationsVariables {
  id: string;
  limit?: number | null;
  search?: string | null;
  orderBy?: InterviewTemplateInstallationSimpleViewOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchInterviewTemplateName
// ====================================================

export interface FetchInterviewTemplateName_currentOrganization_interviewTemplateById {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
}

export interface FetchInterviewTemplateName_currentOrganization {
  __typename: "Organization";
  id: string;
  interviewTemplateById: FetchInterviewTemplateName_currentOrganization_interviewTemplateById;
}

export interface FetchInterviewTemplateName {
  currentOrganization: FetchInterviewTemplateName_currentOrganization | null;
}

export interface FetchInterviewTemplateNameVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveInterviewTemplateFromTable
// ====================================================

export interface ArchiveInterviewTemplateFromTable_archiveInterviewTemplate_interviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  status: InterviewTemplateStatusEnum | null;
}

export interface ArchiveInterviewTemplateFromTable_archiveInterviewTemplate {
  __typename: "ArchiveInterviewTemplateMutationResponse";
  success: boolean;
  code: string;
  message: string;
  interviewTemplate: ArchiveInterviewTemplateFromTable_archiveInterviewTemplate_interviewTemplate | null;
}

export interface ArchiveInterviewTemplateFromTable {
  archiveInterviewTemplate: ArchiveInterviewTemplateFromTable_archiveInterviewTemplate;
}

export interface ArchiveInterviewTemplateFromTableVariables {
  input: ArchiveInterviewTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnarchiveInterviewTemplateFromTable
// ====================================================

export interface UnarchiveInterviewTemplateFromTable_unarchiveInterviewTemplate_interviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  status: InterviewTemplateStatusEnum | null;
}

export interface UnarchiveInterviewTemplateFromTable_unarchiveInterviewTemplate {
  __typename: "UnarchiveInterviewTemplateMutationResponse";
  success: boolean;
  code: string;
  message: string;
  interviewTemplate: UnarchiveInterviewTemplateFromTable_unarchiveInterviewTemplate_interviewTemplate | null;
}

export interface UnarchiveInterviewTemplateFromTable {
  unarchiveInterviewTemplate: UnarchiveInterviewTemplateFromTable_unarchiveInterviewTemplate;
}

export interface UnarchiveInterviewTemplateFromTableVariables {
  input: UnarchiveInterviewTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BulkInviteModalOrganizationRoleData
// ====================================================

export interface BulkInviteModalOrganizationRoleData_roles {
  __typename: "Role";
  id: string;
  name: string;
}

export interface BulkInviteModalOrganizationRoleData_currentUserPrisma_currentOrganization_defaultRole {
  __typename: "Role";
  id: string;
}

export interface BulkInviteModalOrganizationRoleData_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  defaultRole: BulkInviteModalOrganizationRoleData_currentUserPrisma_currentOrganization_defaultRole;
}

export interface BulkInviteModalOrganizationRoleData_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: BulkInviteModalOrganizationRoleData_currentUserPrisma_currentOrganization | null;
}

export interface BulkInviteModalOrganizationRoleData {
  roles: BulkInviteModalOrganizationRoleData_roles[];
  currentUserPrisma: BulkInviteModalOrganizationRoleData_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkInviteModalSendUserInvitations
// ====================================================

export interface BulkInviteModalSendUserInvitations_sendInvitationsByInterviewerId_invitations {
  __typename: "UserInvitation";
  id: string;
}

export interface BulkInviteModalSendUserInvitations_sendInvitationsByInterviewerId {
  __typename: "SendUserInvitationsMutationResponse";
  message: string;
  success: boolean;
  invitations: BulkInviteModalSendUserInvitations_sendInvitationsByInterviewerId_invitations[] | null;
}

export interface BulkInviteModalSendUserInvitations {
  sendInvitationsByInterviewerId: BulkInviteModalSendUserInvitations_sendInvitationsByInterviewerId;
}

export interface BulkInviteModalSendUserInvitationsVariables {
  input: SendUserInvitationsByInterviewerIdInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InviteUserModalOrganizationRoleData
// ====================================================

export interface InviteUserModalOrganizationRoleData_roles {
  __typename: "Role";
  id: string;
  name: string;
}

export interface InviteUserModalOrganizationRoleData_currentUserPrisma_currentOrganization_defaultRole {
  __typename: "Role";
  id: string;
}

export interface InviteUserModalOrganizationRoleData_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  defaultRole: InviteUserModalOrganizationRoleData_currentUserPrisma_currentOrganization_defaultRole;
}

export interface InviteUserModalOrganizationRoleData_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: InviteUserModalOrganizationRoleData_currentUserPrisma_currentOrganization | null;
}

export interface InviteUserModalOrganizationRoleData {
  roles: InviteUserModalOrganizationRoleData_roles[];
  currentUserPrisma: InviteUserModalOrganizationRoleData_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InviteUserModalSendUserInvitations
// ====================================================

export interface InviteUserModalSendUserInvitations_sendInvitations_invitations {
  __typename: "UserInvitation";
  id: string;
}

export interface InviteUserModalSendUserInvitations_sendInvitations {
  __typename: "SendUserInvitationsMutationResponse";
  message: string;
  success: boolean;
  invitations: InviteUserModalSendUserInvitations_sendInvitations_invitations[] | null;
}

export interface InviteUserModalSendUserInvitations {
  sendInvitations: InviteUserModalSendUserInvitations_sendInvitations;
}

export interface InviteUserModalSendUserInvitationsVariables {
  input: SendUserInvitationsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DetachGuideTemplate
// ====================================================

export interface DetachGuideTemplate_detachGuideTemplate_jobRoleGuideTemplate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
}

export interface DetachGuideTemplate_detachGuideTemplate_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  guideTemplate: DetachGuideTemplate_detachGuideTemplate_jobRoleGuideTemplate_guideTemplate | null;
}

export interface DetachGuideTemplate_detachGuideTemplate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  usage: number;
}

export interface DetachGuideTemplate_detachGuideTemplate {
  __typename: "DetachGuideTemplateResponse";
  success: boolean;
  message: string;
  jobRoleGuideTemplate: DetachGuideTemplate_detachGuideTemplate_jobRoleGuideTemplate;
  guideTemplate: DetachGuideTemplate_detachGuideTemplate_guideTemplate;
}

export interface DetachGuideTemplate {
  detachGuideTemplate: DetachGuideTemplate_detachGuideTemplate;
}

export interface DetachGuideTemplateVariables {
  input: DetachGuideTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobRoleGuideTemplateForEditGuideHeader
// ====================================================

export interface GetJobRoleGuideTemplateForEditGuideHeader_currentOrganization_jobRoleGuideTemplateById_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
}

export interface GetJobRoleGuideTemplateForEditGuideHeader_currentOrganization_jobRoleGuideTemplateById_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
  requisitionId: string | null;
}

export interface GetJobRoleGuideTemplateForEditGuideHeader_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  defaultGuideVersion: number;
  guideTemplate: GetJobRoleGuideTemplateForEditGuideHeader_currentOrganization_jobRoleGuideTemplateById_guideTemplate | null;
  atsJob: GetJobRoleGuideTemplateForEditGuideHeader_currentOrganization_jobRoleGuideTemplateById_atsJob | null;
}

export interface GetJobRoleGuideTemplateForEditGuideHeader_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplateById: GetJobRoleGuideTemplateForEditGuideHeader_currentOrganization_jobRoleGuideTemplateById;
}

export interface GetJobRoleGuideTemplateForEditGuideHeader {
  currentOrganization: GetJobRoleGuideTemplateForEditGuideHeader_currentOrganization | null;
}

export interface GetJobRoleGuideTemplateForEditGuideHeaderVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ForcePropagateJRGTToGuides
// ====================================================

export interface ForcePropagateJRGTToGuides_forcePropagateJobRoleGuideTemplateToGuides {
  __typename: "ForcePropagateJobRoleGuideTemplateToGuidesResponse";
  success: boolean;
  code: string;
  message: string;
}

export interface ForcePropagateJRGTToGuides {
  forcePropagateJobRoleGuideTemplateToGuides: ForcePropagateJRGTToGuides_forcePropagateJobRoleGuideTemplateToGuides;
}

export interface ForcePropagateJRGTToGuidesVariables {
  input: ForcePropagateJobRoleGuideTemplateToGuidesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuidesForJobRoleGuideTemplatePaginated
// ====================================================

export interface GuidesForJobRoleGuideTemplatePaginated_currentUser_currentOrganization_jobRoleGuideTemplateById_atsJob {
  __typename: "ATSJobRole";
  id: string;
  candidatesUrl: string;
}

export interface GuidesForJobRoleGuideTemplatePaginated_currentUser_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  atsJob: GuidesForJobRoleGuideTemplatePaginated_currentUser_currentOrganization_jobRoleGuideTemplateById_atsJob | null;
}

export interface GuidesForJobRoleGuideTemplatePaginated_currentUser_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
}

export interface GuidesForJobRoleGuideTemplatePaginated_currentUser_currentOrganization_guidesV2_upcomingInterviews {
  __typename: "Event";
  id: string;
  displayTitle: string;
  replacementData: GraphQL_JSON;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
}

export interface GuidesForJobRoleGuideTemplatePaginated_currentUser_currentOrganization_guidesV2_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
}

export interface GuidesForJobRoleGuideTemplatePaginated_currentUser_currentOrganization_guidesV2_currentStage {
  __typename: "Stage";
  id: string;
  title: string;
  position: number;
}

export interface GuidesForJobRoleGuideTemplatePaginated_currentUser_currentOrganization_guidesV2 {
  __typename: "Guide";
  id: string;
  atsUrl: string;
  publicUrl: string;
  roleName: string;
  stageCount: number;
  currentStagePosition: number | null;
  upcomingInterviews: GuidesForJobRoleGuideTemplatePaginated_currentUser_currentOrganization_guidesV2_upcomingInterviews[];
  candidate: GuidesForJobRoleGuideTemplatePaginated_currentUser_currentOrganization_guidesV2_candidate;
  currentStage: GuidesForJobRoleGuideTemplatePaginated_currentUser_currentOrganization_guidesV2_currentStage | null;
  jobRoleGuideTemplateId: string | null;
  lastEmailActivityAt: GraphQL_DateTime | null;
  candidateOpens: string[];
}

export interface GuidesForJobRoleGuideTemplatePaginated_currentUser_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplateById: GuidesForJobRoleGuideTemplatePaginated_currentUser_currentOrganization_jobRoleGuideTemplateById;
  defaultAvatarImageUrl: string;
  theme: GuidesForJobRoleGuideTemplatePaginated_currentUser_currentOrganization_theme | null;
  name: string;
  guidesV2: GuidesForJobRoleGuideTemplatePaginated_currentUser_currentOrganization_guidesV2[];
}

export interface GuidesForJobRoleGuideTemplatePaginated_currentUser {
  __typename: "User";
  id: string;
  currentOrganization: GuidesForJobRoleGuideTemplatePaginated_currentUser_currentOrganization | null;
}

export interface GuidesForJobRoleGuideTemplatePaginated {
  currentUser: GuidesForJobRoleGuideTemplatePaginated_currentUser | null;
}

export interface GuidesForJobRoleGuideTemplatePaginatedVariables {
  search?: string | null;
  limit: number;
  offset: number;
  orderBy?: OrganizationGuidesOrderByInput[] | null;
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllGuidesForJobRoleGuideTemplate
// ====================================================

export interface AllGuidesForJobRoleGuideTemplate_currentUser_currentOrganization_guidesV2 {
  __typename: "Guide";
  id: string;
}

export interface AllGuidesForJobRoleGuideTemplate_currentUser_currentOrganization {
  __typename: "Organization";
  id: string;
  guidesV2: AllGuidesForJobRoleGuideTemplate_currentUser_currentOrganization_guidesV2[];
}

export interface AllGuidesForJobRoleGuideTemplate_currentUser {
  __typename: "User";
  id: string;
  currentOrganization: AllGuidesForJobRoleGuideTemplate_currentUser_currentOrganization | null;
}

export interface AllGuidesForJobRoleGuideTemplate {
  currentUser: AllGuidesForJobRoleGuideTemplate_currentUser | null;
}

export interface AllGuidesForJobRoleGuideTemplateVariables {
  search?: string | null;
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SyncJobRoleGuideTemplatePrismaProxy
// ====================================================

export interface SyncJobRoleGuideTemplatePrismaProxy_syncGuideTemplatePrismaProxy {
  __typename: "SyncGuideTemplateResponsePrismaProxy";
  taskId: string | null;
}

export interface SyncJobRoleGuideTemplatePrismaProxy {
  syncGuideTemplatePrismaProxy: SyncJobRoleGuideTemplatePrismaProxy_syncGuideTemplatePrismaProxy;
}

export interface SyncJobRoleGuideTemplatePrismaProxyVariables {
  input: SyncGuideTemplateInputPrismaProxy;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobRoleGuideTemplateForHeader
// ====================================================

export interface GetJobRoleGuideTemplateForHeader_currentOrganization_jobRoleGuideTemplateById_atsJob_atsDepartments {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface GetJobRoleGuideTemplateForHeader_currentOrganization_jobRoleGuideTemplateById_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
  requisitionId: string | null;
  atsUrl: string;
  atsDepartments: GetJobRoleGuideTemplateForHeader_currentOrganization_jobRoleGuideTemplateById_atsJob_atsDepartments[];
}

export interface GetJobRoleGuideTemplateForHeader_currentOrganization_jobRoleGuideTemplateById_guideTemplate_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface GetJobRoleGuideTemplateForHeader_currentOrganization_jobRoleGuideTemplateById_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  isGlobal: boolean;
  department: GetJobRoleGuideTemplateForHeader_currentOrganization_jobRoleGuideTemplateById_guideTemplate_department | null;
}

export interface GetJobRoleGuideTemplateForHeader_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  setupStatus: JobRoleGuideTemplateSetupStatus;
  defaultGuideVersion: number;
  configuredStageCount: number;
  unconfiguredStageCount: number;
  atsJob: GetJobRoleGuideTemplateForHeader_currentOrganization_jobRoleGuideTemplateById_atsJob | null;
  guideTemplate: GetJobRoleGuideTemplateForHeader_currentOrganization_jobRoleGuideTemplateById_guideTemplate | null;
}

export interface GetJobRoleGuideTemplateForHeader_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplateById: GetJobRoleGuideTemplateForHeader_currentOrganization_jobRoleGuideTemplateById;
}

export interface GetJobRoleGuideTemplateForHeader {
  currentOrganization: GetJobRoleGuideTemplateForHeader_currentOrganization | null;
}

export interface GetJobRoleGuideTemplateForHeaderVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ForcePropagateJRGTToGuidesForJobPage
// ====================================================

export interface ForcePropagateJRGTToGuidesForJobPage_forcePropagateJobRoleGuideTemplateToGuides {
  __typename: "ForcePropagateJobRoleGuideTemplateToGuidesResponse";
  success: boolean;
  code: string;
  message: string;
}

export interface ForcePropagateJRGTToGuidesForJobPage {
  forcePropagateJobRoleGuideTemplateToGuides: ForcePropagateJRGTToGuidesForJobPage_forcePropagateJobRoleGuideTemplateToGuides;
}

export interface ForcePropagateJRGTToGuidesForJobPageVariables {
  input: ForcePropagateJobRoleGuideTemplateToGuidesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobRoleGuideTemplateForSettingsHeader
// ====================================================

export interface GetJobRoleGuideTemplateForSettingsHeader_currentOrganization_jobRoleGuideTemplateById_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
  requisitionId: string | null;
}

export interface GetJobRoleGuideTemplateForSettingsHeader_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  atsJob: GetJobRoleGuideTemplateForSettingsHeader_currentOrganization_jobRoleGuideTemplateById_atsJob | null;
}

export interface GetJobRoleGuideTemplateForSettingsHeader_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplateById: GetJobRoleGuideTemplateForSettingsHeader_currentOrganization_jobRoleGuideTemplateById;
}

export interface GetJobRoleGuideTemplateForSettingsHeader {
  currentOrganization: GetJobRoleGuideTemplateForSettingsHeader_currentOrganization | null;
}

export interface GetJobRoleGuideTemplateForSettingsHeaderVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobRoleGuideTemplateForEditing
// ====================================================

export interface JobRoleGuideTemplateForEditing_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface JobRoleGuideTemplateForEditing_currentOrganization_contentTemplates {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  archivedAt: GraphQL_DateTime | null;
}

export interface JobRoleGuideTemplateForEditing_currentOrganization_emailTemplates {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  archivedAt: GraphQL_DateTime | null;
}

export interface JobRoleGuideTemplateForEditing_currentOrganization_customer_atsIntegration {
  __typename: "AtsIntegration";
  id: string;
  atsType: string | null;
  displayName: string | null;
}

export interface JobRoleGuideTemplateForEditing_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  atsIntegration: JobRoleGuideTemplateForEditing_currentOrganization_customer_atsIntegration | null;
}

export interface JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  archivedAt: GraphQL_DateTime | null;
}

export interface JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_contentTemplate_modules {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  data: GraphQL_JSON;
  position: number;
}

export interface JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  archivedAt: GraphQL_DateTime | null;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered. Also includes defaults and additional
   *     resolved data needed by our Content Template editor. We should instead fetch the data
   *     needed for the editor directly from those frontend components, and switch to simply using 
   *     "modulesInflated" instead
   */
  modules: JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_contentTemplate_modules[];
}

export interface JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_eventTemplates_interviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  title: GraphQL_JSON | null;
  description: GraphQL_JSON | null;
}

export interface JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  position: number;
  description: string;
  hidden: boolean;
  atsEventTemplateId: string;
  atsEventTemplateName: string;
  interviewTemplate: JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_eventTemplates_interviewTemplate | null;
}

export interface JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations {
  __typename: "StageTemplateInstallation";
  id: string;
  atsStageName: string;
  atsStageId: string;
  displayName: string;
  description: string | null;
  activatedAt: GraphQL_DateTime | null;
  hidden: boolean;
  position: number;
  requireManualApproval: boolean;
  emailTemplate: JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_emailTemplate | null;
  emailActionCC: string[];
  emailActionBCC: string[];
  contentTemplate: JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_contentTemplate;
  eventTemplates: JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_eventTemplates[];
}

export interface JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_atsJob_atsJobStages {
  __typename: "ATSJobRoleStage";
  id: string;
  originalATSId: string;
  name: string;
  position: number | null;
  jobId: string;
  rawJson: GraphQL_JSON;
}

export interface JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
  atsJobStages: JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_atsJob_atsJobStages[];
}

export interface JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRole {
  __typename: "JobRole";
  id: string;
  name: string;
  atsJobId: string;
}

export interface JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  jobDisplayName: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleNameOverride: string | null;
  stageTemplateInstallations: JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations[];
  atsJob: JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_atsJob | null;
  jobRole: JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById_jobRole | null;
}

export interface JobRoleGuideTemplateForEditing_currentOrganization {
  __typename: "Organization";
  id: string;
  theme: JobRoleGuideTemplateForEditing_currentOrganization_theme | null;
  name: string;
  companyLogoUrl: string;
  contentTemplates: JobRoleGuideTemplateForEditing_currentOrganization_contentTemplates[];
  emailTemplates: JobRoleGuideTemplateForEditing_currentOrganization_emailTemplates[];
  customer: JobRoleGuideTemplateForEditing_currentOrganization_customer;
  jobRoleGuideTemplateById: JobRoleGuideTemplateForEditing_currentOrganization_jobRoleGuideTemplateById;
}

export interface JobRoleGuideTemplateForEditing {
  currentOrganization: JobRoleGuideTemplateForEditing_currentOrganization | null;
}

export interface JobRoleGuideTemplateForEditingVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CandidatesForSetupModal
// ====================================================

export interface CandidatesForSetupModal_currentOrganization_jobRoleGuideTemplateById_atsJob {
  __typename: "ATSJobRole";
  id: string;
  candidatesUrl: string;
}

export interface CandidatesForSetupModal_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  atsJob: CandidatesForSetupModal_currentOrganization_jobRoleGuideTemplateById_atsJob | null;
}

export interface CandidatesForSetupModal_currentOrganization_guidesV2_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  emailAddresses: string[];
}

export interface CandidatesForSetupModal_currentOrganization_guidesV2 {
  __typename: "Guide";
  id: string;
  atsUrl: string;
  publicUrl: string;
  status: GuideStatusEnum;
  candidate: CandidatesForSetupModal_currentOrganization_guidesV2_candidate;
}

export interface CandidatesForSetupModal_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplateById: CandidatesForSetupModal_currentOrganization_jobRoleGuideTemplateById;
  guidesV2: CandidatesForSetupModal_currentOrganization_guidesV2[];
}

export interface CandidatesForSetupModal {
  currentOrganization: CandidatesForSetupModal_currentOrganization | null;
}

export interface CandidatesForSetupModalVariables {
  limit: number;
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UnconfiguredJobRoleGuideTemplates
// ====================================================

export interface UnconfiguredJobRoleGuideTemplates_currentOrganization_unconfiguredJobRoleGuideTemplates {
  __typename: "JobRoleGuideTemplate";
  id: string;
  jobRoleName: string;
  unconfiguredStageCount: number;
  setupStatus: JobRoleGuideTemplateSetupStatus;
}

export interface UnconfiguredJobRoleGuideTemplates_currentOrganization {
  __typename: "Organization";
  id: string;
  unconfiguredJobRoleGuideTemplates: UnconfiguredJobRoleGuideTemplates_currentOrganization_unconfiguredJobRoleGuideTemplates[];
}

export interface UnconfiguredJobRoleGuideTemplates {
  currentOrganization: UnconfiguredJobRoleGuideTemplates_currentOrganization | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GenerateUploadcareSignatureForAdmin
// ====================================================

export interface GenerateUploadcareSignatureForAdmin_uploadcareSignature {
  __typename: "UploadcareConfig";
  signature: string;
  expire: string;
}

export interface GenerateUploadcareSignatureForAdmin {
  uploadcareSignature: GenerateUploadcareSignatureForAdmin_uploadcareSignature | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateInterviewersPrismaProxy
// ====================================================

export interface UpdateInterviewersPrismaProxy_updateInterviewersPrismaProxy {
  __typename: "UpdateInterviewersResponsePrismaProxy";
  success: boolean;
  numUpdated: number | null;
  numSkipped: number | null;
  numErrored: number | null;
}

export interface UpdateInterviewersPrismaProxy {
  updateInterviewersPrismaProxy: UpdateInterviewersPrismaProxy_updateInterviewersPrismaProxy;
}

export interface UpdateInterviewersPrismaProxyVariables {
  csvUrl: string;
  dryRun: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkUpdateEventTemplatesForInterviewKit
// ====================================================

export interface BulkUpdateEventTemplatesForInterviewKit_bulkUpdateEventTemplates_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateId: string;
  atsEventTemplateName: string;
  hidden: boolean;
  title: string;
  description: string;
}

export interface BulkUpdateEventTemplatesForInterviewKit_bulkUpdateEventTemplates {
  __typename: "BulkUpdateEventTemplatesResponse";
  success: boolean;
  message: string;
  eventTemplates: (BulkUpdateEventTemplatesForInterviewKit_bulkUpdateEventTemplates_eventTemplates | null)[] | null;
}

export interface BulkUpdateEventTemplatesForInterviewKit {
  bulkUpdateEventTemplates: BulkUpdateEventTemplatesForInterviewKit_bulkUpdateEventTemplates;
}

export interface BulkUpdateEventTemplatesForInterviewKitVariables {
  input: BulkUpdateEventTemplatesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkUpdateStageTemplateInstallations
// ====================================================

export interface BulkUpdateStageTemplateInstallations_bulkUpdateStageTemplateInstallations_stageTemplateInstallations_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
}

export interface BulkUpdateStageTemplateInstallations_bulkUpdateStageTemplateInstallations_stageTemplateInstallations {
  __typename: "StageTemplateInstallation";
  id: string;
  activatedAt: GraphQL_DateTime | null;
  displayName: string;
  hidden: boolean;
  contentTemplate: BulkUpdateStageTemplateInstallations_bulkUpdateStageTemplateInstallations_stageTemplateInstallations_contentTemplate;
}

export interface BulkUpdateStageTemplateInstallations_bulkUpdateStageTemplateInstallations {
  __typename: "BulkUpdateStageTemplateInstallationsResponse";
  success: boolean;
  message: string;
  stageTemplateInstallations: BulkUpdateStageTemplateInstallations_bulkUpdateStageTemplateInstallations_stageTemplateInstallations[] | null;
}

export interface BulkUpdateStageTemplateInstallations {
  bulkUpdateStageTemplateInstallations: BulkUpdateStageTemplateInstallations_bulkUpdateStageTemplateInstallations;
}

export interface BulkUpdateStageTemplateInstallationsVariables {
  input: BulkUpdateStageTemplateInstallationsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetContentTemplateForBulkStageEdit
// ====================================================

export interface GetContentTemplateForBulkStageEdit_currentOrganization_contentTemplate_modules {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
}

export interface GetContentTemplateForBulkStageEdit_currentOrganization_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered. Also includes defaults and additional
   *     resolved data needed by our Content Template editor. We should instead fetch the data
   *     needed for the editor directly from those frontend components, and switch to simply using 
   *     "modulesInflated" instead
   */
  modules: GetContentTemplateForBulkStageEdit_currentOrganization_contentTemplate_modules[];
}

export interface GetContentTemplateForBulkStageEdit_currentOrganization {
  __typename: "Organization";
  id: string;
  contentTemplate: GetContentTemplateForBulkStageEdit_currentOrganization_contentTemplate;
}

export interface GetContentTemplateForBulkStageEdit {
  currentOrganization: GetContentTemplateForBulkStageEdit_currentOrganization | null;
}

export interface GetContentTemplateForBulkStageEditVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEmailTemplateContent
// ====================================================

export interface GetEmailTemplateContent_currentOrganization_emailTemplateById {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface GetEmailTemplateContent_currentOrganization {
  __typename: "Organization";
  id: string;
  emailTemplateById: GetEmailTemplateContent_currentOrganization_emailTemplateById;
}

export interface GetEmailTemplateContent {
  currentOrganization: GetEmailTemplateContent_currentOrganization | null;
}

export interface GetEmailTemplateContentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GreenhouseCandidatePrisma
// ====================================================

export interface GreenhouseCandidatePrisma_greenhouseCandidate {
  __typename: "GreenhouseCandidateRaw";
  rawJson: string;
  activityFeedRawJson: string;
}

export interface GreenhouseCandidatePrisma {
  greenhouseCandidate: GreenhouseCandidatePrisma_greenhouseCandidate | null;
}

export interface GreenhouseCandidatePrismaVariables {
  where: GreenhouseCandidateWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReorderContentTemplateModuleInstallations
// ====================================================

export interface ReorderContentTemplateModuleInstallations_reorderContentTemplateModuleInstallations_contentTemplate_modules {
  __typename: "StageResolvedModule";
  id: string;
  position: number;
}

export interface ReorderContentTemplateModuleInstallations_reorderContentTemplateModuleInstallations_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered. Also includes defaults and additional
   *     resolved data needed by our Content Template editor. We should instead fetch the data
   *     needed for the editor directly from those frontend components, and switch to simply using 
   *     "modulesInflated" instead
   */
  modules: ReorderContentTemplateModuleInstallations_reorderContentTemplateModuleInstallations_contentTemplate_modules[];
}

export interface ReorderContentTemplateModuleInstallations_reorderContentTemplateModuleInstallations {
  __typename: "ReorderContentTemplateModuleInstallationsResponse";
  contentTemplate: ReorderContentTemplateModuleInstallations_reorderContentTemplateModuleInstallations_contentTemplate | null;
}

export interface ReorderContentTemplateModuleInstallations {
  reorderContentTemplateModuleInstallations: ReorderContentTemplateModuleInstallations_reorderContentTemplateModuleInstallations;
}

export interface ReorderContentTemplateModuleInstallationsVariables {
  input: ReorderContentTemplateModuleInstallationsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddContentTemplateModuleToContentTemplate
// ====================================================

export interface AddContentTemplateModuleToContentTemplate_createContentTemplateModuleInstallation_contentTemplate_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
}

export interface AddContentTemplateModuleToContentTemplate_createContentTemplateModuleInstallation_contentTemplate_organization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface AddContentTemplateModuleToContentTemplate_createContentTemplateModuleInstallation_contentTemplate_modules {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface AddContentTemplateModuleToContentTemplate_createContentTemplateModuleInstallation_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  emailTemplate: AddContentTemplateModuleToContentTemplate_createContentTemplateModuleInstallation_contentTemplate_emailTemplate | null;
  organization: AddContentTemplateModuleToContentTemplate_createContentTemplateModuleInstallation_contentTemplate_organization;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered. Also includes defaults and additional
   *     resolved data needed by our Content Template editor. We should instead fetch the data
   *     needed for the editor directly from those frontend components, and switch to simply using 
   *     "modulesInflated" instead
   */
  modules: AddContentTemplateModuleToContentTemplate_createContentTemplateModuleInstallation_contentTemplate_modules[];
  createdBy: string;
  updatedAt: GraphQL_DateTime;
}

export interface AddContentTemplateModuleToContentTemplate_createContentTemplateModuleInstallation {
  __typename: "CreateContentTemplateModuleInstallationResponse";
  contentTemplate: AddContentTemplateModuleToContentTemplate_createContentTemplateModuleInstallation_contentTemplate | null;
}

export interface AddContentTemplateModuleToContentTemplate {
  createContentTemplateModuleInstallation: AddContentTemplateModuleToContentTemplate_createContentTemplateModuleInstallation;
}

export interface AddContentTemplateModuleToContentTemplateVariables {
  input: CreateContentTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveContentTemplateModuleFromContentTemplate
// ====================================================

export interface RemoveContentTemplateModuleFromContentTemplate_removeContentTemplateModuleInstallation_contentTemplate_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
}

export interface RemoveContentTemplateModuleFromContentTemplate_removeContentTemplateModuleInstallation_contentTemplate_organization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface RemoveContentTemplateModuleFromContentTemplate_removeContentTemplateModuleInstallation_contentTemplate_modules {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface RemoveContentTemplateModuleFromContentTemplate_removeContentTemplateModuleInstallation_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  emailTemplate: RemoveContentTemplateModuleFromContentTemplate_removeContentTemplateModuleInstallation_contentTemplate_emailTemplate | null;
  organization: RemoveContentTemplateModuleFromContentTemplate_removeContentTemplateModuleInstallation_contentTemplate_organization;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered. Also includes defaults and additional
   *     resolved data needed by our Content Template editor. We should instead fetch the data
   *     needed for the editor directly from those frontend components, and switch to simply using 
   *     "modulesInflated" instead
   */
  modules: RemoveContentTemplateModuleFromContentTemplate_removeContentTemplateModuleInstallation_contentTemplate_modules[];
  createdBy: string;
  updatedAt: GraphQL_DateTime;
}

export interface RemoveContentTemplateModuleFromContentTemplate_removeContentTemplateModuleInstallation {
  __typename: "RemoveContentTemplateModuleInstallationResponse";
  contentTemplate: RemoveContentTemplateModuleFromContentTemplate_removeContentTemplateModuleInstallation_contentTemplate | null;
}

export interface RemoveContentTemplateModuleFromContentTemplate {
  removeContentTemplateModuleInstallation: RemoveContentTemplateModuleFromContentTemplate_removeContentTemplateModuleInstallation;
}

export interface RemoveContentTemplateModuleFromContentTemplateVariables {
  input: RemoveContentTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateContentTemplateName
// ====================================================

export interface UpdateContentTemplateName_updateContentTemplateName_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
}

export interface UpdateContentTemplateName_updateContentTemplateName {
  __typename: "UpdateContentTemplateNameResponse";
  contentTemplate: UpdateContentTemplateName_updateContentTemplateName_contentTemplate | null;
}

export interface UpdateContentTemplateName {
  updateContentTemplateName: UpdateContentTemplateName_updateContentTemplateName;
}

export interface UpdateContentTemplateNameVariables {
  input: UpdateContentTemplateNameInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetContentTemplatesForPicker
// ====================================================

export interface GetContentTemplatesForPicker_currentOrganization_contentTemplates {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  archivedAt: GraphQL_DateTime | null;
}

export interface GetContentTemplatesForPicker_currentOrganization {
  __typename: "Organization";
  id: string;
  contentTemplates: GetContentTemplatesForPicker_currentOrganization_contentTemplates[];
}

export interface GetContentTemplatesForPicker {
  currentOrganization: GetContentTemplatesForPicker_currentOrganization | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GenerateUploadcareSignatureForDropzone
// ====================================================

export interface GenerateUploadcareSignatureForDropzone_uploadcareSignature {
  __typename: "UploadcareConfig";
  signature: string;
  expire: string;
}

export interface GenerateUploadcareSignatureForDropzone {
  uploadcareSignature: GenerateUploadcareSignatureForDropzone_uploadcareSignature | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEmailTemplatesForPicker
// ====================================================

export interface GetEmailTemplatesForPicker_currentOrganization_emailTemplates {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  archivedAt: GraphQL_DateTime | null;
}

export interface GetEmailTemplatesForPicker_currentOrganization {
  __typename: "Organization";
  id: string;
  emailTemplates: GetEmailTemplatesForPicker_currentOrganization_emailTemplates[];
}

export interface GetEmailTemplatesForPicker {
  currentOrganization: GetEmailTemplatesForPicker_currentOrganization | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentUserForEnsureCalendarPermissions
// ====================================================

export interface CurrentUserForEnsureCalendarPermissions_currentUserPrisma {
  __typename: "User";
  id: string;
  nylasAccessTokenExists: boolean;
  hasCalendarScope: boolean;
}

export interface CurrentUserForEnsureCalendarPermissions {
  currentUserPrisma: CurrentUserForEnsureCalendarPermissions_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConnectGoogleCalendarIntegrationForUserPrismaProxy
// ====================================================

export interface ConnectGoogleCalendarIntegrationForUserPrismaProxy_storeGoogleCalendarAuthPrismaProxy {
  __typename: "StoreGoogleCalendarAuthResponsePrismaProxy";
  success: boolean;
}

export interface ConnectGoogleCalendarIntegrationForUserPrismaProxy {
  storeGoogleCalendarAuthPrismaProxy: ConnectGoogleCalendarIntegrationForUserPrismaProxy_storeGoogleCalendarAuthPrismaProxy;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FeedbackForOrganizationPrismaProxy
// ====================================================

export interface FeedbackForOrganizationPrismaProxy_feedbackForOrganizationPrismaProxy_organization {
  __typename: "Organization";
  id: string;
  enableNPS: boolean;
}

export interface FeedbackForOrganizationPrismaProxy_feedbackForOrganizationPrismaProxy {
  __typename: "FeedbackForOrganizationResponsePrismaProxy";
  stats: GraphQL_JSON | null;
  success: boolean;
  organization: FeedbackForOrganizationPrismaProxy_feedbackForOrganizationPrismaProxy_organization | null;
}

export interface FeedbackForOrganizationPrismaProxy {
  feedbackForOrganizationPrismaProxy: FeedbackForOrganizationPrismaProxy_feedbackForOrganizationPrismaProxy;
}

export interface FeedbackForOrganizationPrismaProxyVariables {
  input: FeedbackForOrganizationInputPrismaProxy;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDataForFeedbackFilters
// ====================================================

export interface GetDataForFeedbackFilters_atsDepartments {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface GetDataForFeedbackFilters_currentOrganization_jobRoleGuideTemplates_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
}

export interface GetDataForFeedbackFilters_currentOrganization_jobRoleGuideTemplates_stageTemplateInstallations {
  __typename: "StageTemplateInstallation";
  id: string;
  atsStageName: string;
}

export interface GetDataForFeedbackFilters_currentOrganization_jobRoleGuideTemplates_jobRole {
  __typename: "JobRole";
  id: string;
  name: string;
}

export interface GetDataForFeedbackFilters_currentOrganization_jobRoleGuideTemplates {
  __typename: "JobRoleGuideTemplate";
  id: string;
  jobRoleGuideTemplateStages: GetDataForFeedbackFilters_currentOrganization_jobRoleGuideTemplates_jobRoleGuideTemplateStages[];
  stageTemplateInstallations: GetDataForFeedbackFilters_currentOrganization_jobRoleGuideTemplates_stageTemplateInstallations[];
  jobRole: GetDataForFeedbackFilters_currentOrganization_jobRoleGuideTemplates_jobRole | null;
}

export interface GetDataForFeedbackFilters_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplates: GetDataForFeedbackFilters_currentOrganization_jobRoleGuideTemplates[];
}

export interface GetDataForFeedbackFilters {
  atsDepartments: GetDataForFeedbackFilters_atsDepartments[];
  currentOrganization: GetDataForFeedbackFilters_currentOrganization | null;
}

export interface GetDataForFeedbackFiltersVariables {
  zeusOnly: boolean;
  classicOrTransition: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GeneratePageEditTokenPrismaProxy
// ====================================================

export interface GeneratePageEditTokenPrismaProxy_pageEditTokenForCurrentUserPrismaProxy {
  __typename: "PageEditTokenResponsePrismaProxy";
  success: boolean;
}

export interface GeneratePageEditTokenPrismaProxy {
  pageEditTokenForCurrentUserPrismaProxy: GeneratePageEditTokenPrismaProxy_pageEditTokenForCurrentUserPrismaProxy | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchedulingIntegrationData
// ====================================================

export interface GetSchedulingIntegrationData_currentUserPrisma_currentOrganization_schedulerAvailabilities {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface GetSchedulingIntegrationData_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  name: string;
  companyLogoUrl: string;
  schedulerAvailabilities: GetSchedulingIntegrationData_currentUserPrisma_currentOrganization_schedulerAvailabilities[];
}

export interface GetSchedulingIntegrationData_currentUserPrisma_currentUserMembership_schedulerAvailabilities {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
  eventDuration: number;
  eventTitle: string;
  eventLocation: string;
  slug: string;
  editToken: string;
}

export interface GetSchedulingIntegrationData_currentUserPrisma_currentUserMembership {
  __typename: "UserMembership";
  id: string;
  hasLimitedAccess: boolean;
  schedulerAvailabilities: GetSchedulingIntegrationData_currentUserPrisma_currentUserMembership_schedulerAvailabilities[];
}

export interface GetSchedulingIntegrationData_currentUserPrisma {
  __typename: "User";
  id: string;
  nylasAccessTokenExists: boolean;
  currentOrganization: GetSchedulingIntegrationData_currentUserPrisma_currentOrganization | null;
  currentUserMembership: GetSchedulingIntegrationData_currentUserPrisma_currentUserMembership | null;
}

export interface GetSchedulingIntegrationData {
  currentUserPrisma: GetSchedulingIntegrationData_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DisconnectGoogleCalendar
// ====================================================

export interface DisconnectGoogleCalendar_disconnectCurrentUserCalendar_user {
  __typename: "User";
  id: string;
  hasConfiguredGoogleCalendar: boolean;
  nylasAccessTokenExists: boolean;
}

export interface DisconnectGoogleCalendar_disconnectCurrentUserCalendar {
  __typename: "DisconnectCurrentUserCalendarMutationResponse";
  success: boolean;
  message: string;
  user: DisconnectGoogleCalendar_disconnectCurrentUserCalendar_user | null;
}

export interface DisconnectGoogleCalendar {
  disconnectCurrentUserCalendar: DisconnectGoogleCalendar_disconnectCurrentUserCalendar;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: HideInterview
// ====================================================

export interface HideInterview_hideEventTemplate_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  hidden: boolean;
}

export interface HideInterview_hideEventTemplate {
  __typename: "HideEventTemplateMutationResponse";
  success: boolean;
  message: string;
  eventTemplate: HideInterview_hideEventTemplate_eventTemplate | null;
}

export interface HideInterview {
  hideEventTemplate: HideInterview_hideEventTemplate;
}

export interface HideInterviewVariables {
  interviewId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShowInterview
// ====================================================

export interface ShowInterview_showEventTemplate_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  hidden: boolean;
}

export interface ShowInterview_showEventTemplate {
  __typename: "ShowEventTemplateMutationResponse";
  success: boolean;
  message: string;
  eventTemplate: ShowInterview_showEventTemplate_eventTemplate | null;
}

export interface ShowInterview {
  showEventTemplate: ShowInterview_showEventTemplate;
}

export interface ShowInterviewVariables {
  interviewId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInterviewTemplates
// ====================================================

export interface GetInterviewTemplates_currentUserPrisma_currentOrganization_interviewTemplates {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  title: GraphQL_JSON | null;
  description: GraphQL_JSON | null;
}

export interface GetInterviewTemplates_currentUserPrisma_currentOrganization_customer_atsIntegration {
  __typename: "AtsIntegration";
  id: string;
  displayName: string | null;
}

export interface GetInterviewTemplates_currentUserPrisma_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  atsIntegration: GetInterviewTemplates_currentUserPrisma_currentOrganization_customer_atsIntegration | null;
}

export interface GetInterviewTemplates_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  interviewTemplates: GetInterviewTemplates_currentUserPrisma_currentOrganization_interviewTemplates[];
  customer: GetInterviewTemplates_currentUserPrisma_currentOrganization_customer;
}

export interface GetInterviewTemplates_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: GetInterviewTemplates_currentUserPrisma_currentOrganization | null;
}

export interface GetInterviewTemplates {
  currentUserPrisma: GetInterviewTemplates_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEventTemplateForInterviewKitPrisma
// ====================================================

export interface UpdateEventTemplateForInterviewKitPrisma_updateEventTemplate_eventTemplate_interviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
}

export interface UpdateEventTemplateForInterviewKitPrisma_updateEventTemplate_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  hidden: boolean;
  description: string;
  title: string;
  interviewTemplate: UpdateEventTemplateForInterviewKitPrisma_updateEventTemplate_eventTemplate_interviewTemplate | null;
}

export interface UpdateEventTemplateForInterviewKitPrisma_updateEventTemplate {
  __typename: "UpdateEventTemplateMutationResponse";
  eventTemplate: UpdateEventTemplateForInterviewKitPrisma_updateEventTemplate_eventTemplate | null;
}

export interface UpdateEventTemplateForInterviewKitPrisma {
  updateEventTemplate: UpdateEventTemplateForInterviewKitPrisma_updateEventTemplate;
}

export interface UpdateEventTemplateForInterviewKitPrismaVariables {
  input: UpdateEventTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFeedbackData
// ====================================================

export interface GetFeedbackData_guideFindUnique_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface GetFeedbackData_guideFindUnique_candidate {
  __typename: "Candidate";
  id: string;
  organization: GetFeedbackData_guideFindUnique_candidate_organization;
}

export interface GetFeedbackData_guideFindUnique {
  __typename: "Guide";
  id: string;
  candidate: GetFeedbackData_guideFindUnique_candidate;
}

export interface GetFeedbackData {
  guideFindUnique: GetFeedbackData_guideFindUnique;
}

export interface GetFeedbackDataVariables {
  where: GuideWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitFeedbackForStagePrisma
// ====================================================

export interface SubmitFeedbackForStagePrisma_submitCandidateFeedbackForStage_candidateFeedback {
  __typename: "CandidateFeedback";
  id: string;
  data: GraphQL_JSON;
}

export interface SubmitFeedbackForStagePrisma_submitCandidateFeedbackForStage {
  __typename: "SubmitCandidateFeedbackForStageMutationResponse";
  success: boolean;
  candidateFeedback: SubmitFeedbackForStagePrisma_submitCandidateFeedbackForStage_candidateFeedback | null;
}

export interface SubmitFeedbackForStagePrisma {
  submitCandidateFeedbackForStage: SubmitFeedbackForStagePrisma_submitCandidateFeedbackForStage;
}

export interface SubmitFeedbackForStagePrismaVariables {
  input: SubmitCandidateFeedbackForStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideFeedbackDataForGuide
// ====================================================

export interface GuideFeedbackDataForGuide_guideFindUnique_currentStage {
  __typename: "Stage";
  id: string;
}

export interface GuideFeedbackDataForGuide_guideFindUnique_feedbackSubmissionData_feedbackSubmission {
  __typename: "CandidateFeedback";
  id: string;
}

export interface GuideFeedbackDataForGuide_guideFindUnique_feedbackSubmissionData {
  __typename: "GuideFeedbackSubmissionData";
  needsFeedback: boolean;
  enableNPS: boolean;
  lastInterviewId: GraphQL_UUID | null;
  feedbackSubmission: GuideFeedbackDataForGuide_guideFindUnique_feedbackSubmissionData_feedbackSubmission | null;
}

export interface GuideFeedbackDataForGuide_guideFindUnique {
  __typename: "Guide";
  id: string;
  currentStage: GuideFeedbackDataForGuide_guideFindUnique_currentStage | null;
  feedbackSubmissionData: GuideFeedbackDataForGuide_guideFindUnique_feedbackSubmissionData | null;
}

export interface GuideFeedbackDataForGuide {
  guideFindUnique: GuideFeedbackDataForGuide_guideFindUnique;
}

export interface GuideFeedbackDataForGuideVariables {
  where: GuideWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitFeedbackForLastInterview
// ====================================================

export interface SubmitFeedbackForLastInterview_submitCandidateFeedbackForLastInterview_candidateFeedback {
  __typename: "CandidateFeedback";
  id: string;
  data: GraphQL_JSON;
}

export interface SubmitFeedbackForLastInterview_submitCandidateFeedbackForLastInterview {
  __typename: "SubmitCandidateFeedbackForLastInterviewMutationResponse";
  success: boolean;
  candidateFeedback: SubmitFeedbackForLastInterview_submitCandidateFeedbackForLastInterview_candidateFeedback | null;
}

export interface SubmitFeedbackForLastInterview {
  submitCandidateFeedbackForLastInterview: SubmitFeedbackForLastInterview_submitCandidateFeedbackForLastInterview;
}

export interface SubmitFeedbackForLastInterviewVariables {
  input: SubmitCandidateFeedbackForLastInterviewInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAvailabilitySchedulerData
// ====================================================

export interface GetAvailabilitySchedulerData_schedulerAvailabilityEventFindFirst_pitchPage {
  __typename: "PitchPage";
  id: string;
  isSample: boolean;
}

export interface GetAvailabilitySchedulerData_schedulerAvailabilityEventFindFirst_availabilityEventParticipants_participant_interviewer {
  __typename: "Interviewer";
  id: string;
  displayTitle: string;
  displayImageUrl: string;
  displayFirstName: string;
}

export interface GetAvailabilitySchedulerData_schedulerAvailabilityEventFindFirst_availabilityEventParticipants_participant {
  __typename: "SchedulerAvailabilityEventParticipant";
  id: string;
  name: string;
  interviewer: GetAvailabilitySchedulerData_schedulerAvailabilityEventFindFirst_availabilityEventParticipants_participant_interviewer | null;
}

export interface GetAvailabilitySchedulerData_schedulerAvailabilityEventFindFirst_availabilityEventParticipants {
  __typename: "SchedulerAvailabilityEventToParticipant";
  id: string;
  participant: GetAvailabilitySchedulerData_schedulerAvailabilityEventFindFirst_availabilityEventParticipants_participant;
}

export interface GetAvailabilitySchedulerData_schedulerAvailabilityEventFindFirst {
  __typename: "SchedulerAvailabilityEvent";
  id: string;
  externalEditHash: string;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  location: string;
  pitchPage: GetAvailabilitySchedulerData_schedulerAvailabilityEventFindFirst_pitchPage | null;
  availabilityEventParticipants: GetAvailabilitySchedulerData_schedulerAvailabilityEventFindFirst_availabilityEventParticipants[];
}

export interface GetAvailabilitySchedulerData {
  schedulerAvailabilityEventFindFirst: GetAvailabilitySchedulerData_schedulerAvailabilityEventFindFirst | null;
}

export interface GetAvailabilitySchedulerDataVariables {
  editHash?: GraphQL_UUID | null;
  pitchPageId?: GraphQL_UUID | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SchedulerEventsPrisma
// ====================================================

export interface SchedulerEventsPrisma_legacyStageForEventsFindUnique_events {
  __typename: "Event";
  id: string;
  nylasEditHash: string | null;
  eventTemplateId: string | null;
  startTime: GraphQL_DateTime;
}

export interface SchedulerEventsPrisma_legacyStageForEventsFindUnique {
  __typename: "Stage";
  id: string;
  events: SchedulerEventsPrisma_legacyStageForEventsFindUnique_events[];
}

export interface SchedulerEventsPrisma {
  legacyStageForEventsFindUnique: SchedulerEventsPrisma_legacyStageForEventsFindUnique;
}

export interface SchedulerEventsPrismaVariables {
  stageId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAvailabilitySchedulerDataForPitchPage
// ====================================================

export interface GetAvailabilitySchedulerDataForPitchPage_schedulerAvailabilityEventFindFirst_pitchPage {
  __typename: "PitchPage";
  id: string;
  isSample: boolean;
}

export interface GetAvailabilitySchedulerDataForPitchPage_schedulerAvailabilityEventFindFirst_availabilityEventParticipants_participant_interviewer {
  __typename: "Interviewer";
  id: string;
  displayTitle: string;
  displayImageUrl: string;
  displayFirstName: string;
}

export interface GetAvailabilitySchedulerDataForPitchPage_schedulerAvailabilityEventFindFirst_availabilityEventParticipants_participant {
  __typename: "SchedulerAvailabilityEventParticipant";
  id: string;
  name: string;
  interviewer: GetAvailabilitySchedulerDataForPitchPage_schedulerAvailabilityEventFindFirst_availabilityEventParticipants_participant_interviewer | null;
}

export interface GetAvailabilitySchedulerDataForPitchPage_schedulerAvailabilityEventFindFirst_availabilityEventParticipants {
  __typename: "SchedulerAvailabilityEventToParticipant";
  id: string;
  participant: GetAvailabilitySchedulerDataForPitchPage_schedulerAvailabilityEventFindFirst_availabilityEventParticipants_participant;
}

export interface GetAvailabilitySchedulerDataForPitchPage_schedulerAvailabilityEventFindFirst {
  __typename: "SchedulerAvailabilityEvent";
  id: string;
  externalEditHash: string;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  location: string;
  pitchPage: GetAvailabilitySchedulerDataForPitchPage_schedulerAvailabilityEventFindFirst_pitchPage | null;
  availabilityEventParticipants: GetAvailabilitySchedulerDataForPitchPage_schedulerAvailabilityEventFindFirst_availabilityEventParticipants[];
}

export interface GetAvailabilitySchedulerDataForPitchPage {
  schedulerAvailabilityEventFindFirst: GetAvailabilitySchedulerDataForPitchPage_schedulerAvailabilityEventFindFirst | null;
}

export interface GetAvailabilitySchedulerDataForPitchPageVariables {
  editHash?: GraphQL_UUID | null;
  pitchPageId?: GraphQL_UUID | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLinkPreview
// ====================================================

export interface GetLinkPreview_getLinkPreview {
  __typename: "LinkPreview";
  title: string;
  description: string | null;
  favicon: string | null;
  image: string | null;
  url: string | null;
}

export interface GetLinkPreview {
  getLinkPreview: GetLinkPreview_getLinkPreview;
}

export interface GetLinkPreviewVariables {
  url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentUserForStream
// ====================================================

export interface CurrentUserForStream_currentUserPrisma {
  __typename: "User";
  id: string;
  name: string;
  streamToken: string | null;
  photo: string;
}

export interface CurrentUserForStream {
  currentUserPrisma: CurrentUserForStream_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CandidateForStream
// ====================================================

export interface CandidateForStream_guideFindUnique_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface CandidateForStream_guideFindUnique_candidate {
  __typename: "Candidate";
  id: string;
  streamToken: string | null;
  name: string;
  organization: CandidateForStream_guideFindUnique_candidate_organization;
}

export interface CandidateForStream_guideFindUnique {
  __typename: "Guide";
  id: string;
  candidate: CandidateForStream_guideFindUnique_candidate;
}

export interface CandidateForStream {
  guideFindUnique: CandidateForStream_guideFindUnique;
}

export interface CandidateForStreamVariables {
  where: GuideWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUpGuideForStream
// ====================================================

export interface SetUpGuideForStream_setUpStreamForGuide_guide {
  __typename: "Guide";
  id: string;
}

export interface SetUpGuideForStream_setUpStreamForGuide {
  __typename: "SetUpStreamForGuideMutationResponse";
  success: boolean;
  guide: SetUpGuideForStream_setUpStreamForGuide_guide | null;
}

export interface SetUpGuideForStream {
  setUpStreamForGuide: SetUpGuideForStream_setUpStreamForGuide;
}

export interface SetUpGuideForStreamVariables {
  input: SetUpStreamForGuideInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OnboardingData
// ====================================================

export interface OnboardingData_currentUserPrisma_currentUserMembership {
  __typename: "UserMembership";
  id: string;
  hasLimitedAccess: boolean;
}

export interface OnboardingData_currentUserPrisma_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface OnboardingData_currentUserPrisma_currentOrganization_values {
  __typename: "Value";
  id: GraphQL_UUID;
  title: string;
  description: string;
}

export interface OnboardingData_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  companyLogoUrl: string;
  name: string;
  theme: OnboardingData_currentUserPrisma_currentOrganization_theme | null;
  values: OnboardingData_currentUserPrisma_currentOrganization_values[];
}

export interface OnboardingData_currentUserPrisma {
  __typename: "User";
  id: string;
  pitchPageOnboardingComplete: boolean;
  currentUserMembership: OnboardingData_currentUserPrisma_currentUserMembership | null;
  currentOrganization: OnboardingData_currentUserPrisma_currentOrganization | null;
}

export interface OnboardingData {
  currentUserPrisma: OnboardingData_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GlobalOrganizationContentForCompanySidePanel
// ====================================================

export interface GlobalOrganizationContentForCompanySidePanel_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface GlobalOrganizationContentForCompanySidePanel_currentOrganization {
  __typename: "Organization";
  id: string;
  name: string;
  missionStatement: string;
  theme: GlobalOrganizationContentForCompanySidePanel_currentOrganization_theme | null;
  showTrim: boolean;
  companyLogoUrl: string;
  defaultAvatarImageUrl: string;
}

export interface GlobalOrganizationContentForCompanySidePanel {
  currentOrganization: GlobalOrganizationContentForCompanySidePanel_currentOrganization | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrgForPitchPages
// ====================================================

export interface UpdateOrgForPitchPages_updateOrganizationBranding_organization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface UpdateOrgForPitchPages_updateOrganizationBranding_organization {
  __typename: "Organization";
  id: string;
  missionStatement: string;
  companyLogoUrl: string;
  theme: UpdateOrgForPitchPages_updateOrganizationBranding_organization_theme | null;
  name: string;
}

export interface UpdateOrgForPitchPages_updateOrganizationBranding {
  __typename: "UpdateOrganizationBrandingMutationResponse";
  organization: UpdateOrgForPitchPages_updateOrganizationBranding_organization | null;
}

export interface UpdateOrgForPitchPages {
  updateOrganizationBranding: UpdateOrgForPitchPages_updateOrganizationBranding;
}

export interface UpdateOrgForPitchPagesVariables {
  input: UpdateOrganizationBrandingInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentUserForCompanySidePanel
// ====================================================

export interface CurrentUserForCompanySidePanel_currentUserPrisma {
  __typename: "User";
  id: string;
  firstName: string;
  name: string;
  streamToken: string | null;
  photo: string;
}

export interface CurrentUserForCompanySidePanel {
  currentUserPrisma: CurrentUserForCompanySidePanel_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TrackPitchPageView
// ====================================================

export interface TrackPitchPageView_trackPitchPageView {
  __typename: "TrackPitchPageViewMutationResponse";
  success: boolean;
}

export interface TrackPitchPageView {
  trackPitchPageView: TrackPitchPageView_trackPitchPageView;
}

export interface TrackPitchPageViewVariables {
  pitchPageId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TrackPitchPageClickEvent
// ====================================================

export interface TrackPitchPageClickEvent_trackPitchPageClickEvent {
  __typename: "TrackPitchPageViewMutationResponse";
  success: boolean;
}

export interface TrackPitchPageClickEvent {
  trackPitchPageClickEvent: TrackPitchPageClickEvent_trackPitchPageClickEvent;
}

export interface TrackPitchPageClickEventVariables {
  pitchPageId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchPitchPages
// ====================================================

export interface FetchPitchPages_currentUserPrisma_currentUserMembership {
  __typename: "UserMembership";
  id: string;
}

export interface FetchPitchPages_currentUserPrisma {
  __typename: "User";
  id: string;
  currentUserMembership: FetchPitchPages_currentUserPrisma_currentUserMembership | null;
}

export interface FetchPitchPages_pitchPageTemplateFindUnique_createdBy {
  __typename: "UserMembership";
  id: string;
}

export interface FetchPitchPages_pitchPageTemplateFindUnique_pitchPages {
  __typename: "PitchPage";
  id: string;
  recipientFirstName: string;
  recipientLastName: string | null;
  numViews: number;
  numClicks: number;
  views: GraphQL_DateTime[];
  clicks: GraphQL_DateTime[];
  generatedManually: boolean;
  url: string;
}

export interface FetchPitchPages_pitchPageTemplateFindUnique {
  __typename: "PitchPageTemplate";
  id: string;
  createdBy: FetchPitchPages_pitchPageTemplateFindUnique_createdBy;
  pitchPages: FetchPitchPages_pitchPageTemplateFindUnique_pitchPages[];
}

export interface FetchPitchPages {
  currentUserPrisma: FetchPitchPages_currentUserPrisma | null;
  pitchPageTemplateFindUnique: FetchPitchPages_pitchPageTemplateFindUnique;
}

export interface FetchPitchPagesVariables {
  pitchPageTemplateId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePitchPageInstallationModuleInterviewProcess
// ====================================================

export interface UpdatePitchPageInstallationModuleInterviewProcess_updatePitchPageTemplateModuleInstallationData_pitchPageTemplateModuleInstallation {
  __typename: "PitchPageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
}

export interface UpdatePitchPageInstallationModuleInterviewProcess_updatePitchPageTemplateModuleInstallationData {
  __typename: "UpdatePitchPageTemplateModuleInstallationDataResponse";
  message: string;
  success: boolean;
  pitchPageTemplateModuleInstallation: UpdatePitchPageInstallationModuleInterviewProcess_updatePitchPageTemplateModuleInstallationData_pitchPageTemplateModuleInstallation | null;
}

export interface UpdatePitchPageInstallationModuleInterviewProcess {
  updatePitchPageTemplateModuleInstallationData: UpdatePitchPageInstallationModuleInterviewProcess_updatePitchPageTemplateModuleInstallationData;
}

export interface UpdatePitchPageInstallationModuleInterviewProcessVariables {
  input: UpdatePitchPageTemplateModuleInstallationDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePitchPageInterviewScheduler
// ====================================================

export interface UpdatePitchPageInterviewScheduler_updateInterviewScheduler_pitchPageTemplateModuleInstallation_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface UpdatePitchPageInterviewScheduler_updateInterviewScheduler_pitchPageTemplateModuleInstallation {
  __typename: "PitchPageTemplateModuleInstallation";
  id: string;
  schedulerAvailability: UpdatePitchPageInterviewScheduler_updateInterviewScheduler_pitchPageTemplateModuleInstallation_schedulerAvailability;
  data: GraphQL_JSON;
}

export interface UpdatePitchPageInterviewScheduler_updateInterviewScheduler {
  __typename: "UpdateInterviewSchedulerResponse";
  message: string;
  success: boolean;
  pitchPageTemplateModuleInstallation: UpdatePitchPageInterviewScheduler_updateInterviewScheduler_pitchPageTemplateModuleInstallation | null;
}

export interface UpdatePitchPageInterviewScheduler {
  updateInterviewScheduler: UpdatePitchPageInterviewScheduler_updateInterviewScheduler;
}

export interface UpdatePitchPageInterviewSchedulerVariables {
  input: UpdateInterviewSchedulerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePitchPageInstallationModuleLinks
// ====================================================

export interface UpdatePitchPageInstallationModuleLinks_updatePitchPageTemplateModuleInstallationData_pitchPageTemplateModuleInstallation {
  __typename: "PitchPageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
}

export interface UpdatePitchPageInstallationModuleLinks_updatePitchPageTemplateModuleInstallationData {
  __typename: "UpdatePitchPageTemplateModuleInstallationDataResponse";
  message: string;
  success: boolean;
  pitchPageTemplateModuleInstallation: UpdatePitchPageInstallationModuleLinks_updatePitchPageTemplateModuleInstallationData_pitchPageTemplateModuleInstallation | null;
}

export interface UpdatePitchPageInstallationModuleLinks {
  updatePitchPageTemplateModuleInstallationData: UpdatePitchPageInstallationModuleLinks_updatePitchPageTemplateModuleInstallationData;
}

export interface UpdatePitchPageInstallationModuleLinksVariables {
  input: UpdatePitchPageTemplateModuleInstallationDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePitchPageInstallationModuleLogistics
// ====================================================

export interface UpdatePitchPageInstallationModuleLogistics_updatePitchPageTemplateModuleInstallationData_pitchPageTemplateModuleInstallation {
  __typename: "PitchPageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
}

export interface UpdatePitchPageInstallationModuleLogistics_updatePitchPageTemplateModuleInstallationData {
  __typename: "UpdatePitchPageTemplateModuleInstallationDataResponse";
  message: string;
  success: boolean;
  pitchPageTemplateModuleInstallation: UpdatePitchPageInstallationModuleLogistics_updatePitchPageTemplateModuleInstallationData_pitchPageTemplateModuleInstallation | null;
}

export interface UpdatePitchPageInstallationModuleLogistics {
  updatePitchPageTemplateModuleInstallationData: UpdatePitchPageInstallationModuleLogistics_updatePitchPageTemplateModuleInstallationData;
}

export interface UpdatePitchPageInstallationModuleLogisticsVariables {
  input: UpdatePitchPageTemplateModuleInstallationDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePitchPageInstallationModulePhoto
// ====================================================

export interface UpdatePitchPageInstallationModulePhoto_updatePitchPageTemplateModuleInstallationData_pitchPageTemplateModuleInstallation {
  __typename: "PitchPageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
}

export interface UpdatePitchPageInstallationModulePhoto_updatePitchPageTemplateModuleInstallationData {
  __typename: "UpdatePitchPageTemplateModuleInstallationDataResponse";
  message: string;
  success: boolean;
  pitchPageTemplateModuleInstallation: UpdatePitchPageInstallationModulePhoto_updatePitchPageTemplateModuleInstallationData_pitchPageTemplateModuleInstallation | null;
}

export interface UpdatePitchPageInstallationModulePhoto {
  updatePitchPageTemplateModuleInstallationData: UpdatePitchPageInstallationModulePhoto_updatePitchPageTemplateModuleInstallationData;
}

export interface UpdatePitchPageInstallationModulePhotoVariables {
  input: UpdatePitchPageTemplateModuleInstallationDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePitchPageInstallationModuleRichText
// ====================================================

export interface UpdatePitchPageInstallationModuleRichText_updatePitchPageTemplateModuleInstallationData_pitchPageTemplateModuleInstallation {
  __typename: "PitchPageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
}

export interface UpdatePitchPageInstallationModuleRichText_updatePitchPageTemplateModuleInstallationData {
  __typename: "UpdatePitchPageTemplateModuleInstallationDataResponse";
  message: string;
  success: boolean;
  pitchPageTemplateModuleInstallation: UpdatePitchPageInstallationModuleRichText_updatePitchPageTemplateModuleInstallationData_pitchPageTemplateModuleInstallation | null;
}

export interface UpdatePitchPageInstallationModuleRichText {
  updatePitchPageTemplateModuleInstallationData: UpdatePitchPageInstallationModuleRichText_updatePitchPageTemplateModuleInstallationData;
}

export interface UpdatePitchPageInstallationModuleRichTextVariables {
  input: UpdatePitchPageTemplateModuleInstallationDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePitchPageInstallationModuleVideo
// ====================================================

export interface UpdatePitchPageInstallationModuleVideo_updatePitchPageTemplateModuleInstallationData_pitchPageTemplateModuleInstallation {
  __typename: "PitchPageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
}

export interface UpdatePitchPageInstallationModuleVideo_updatePitchPageTemplateModuleInstallationData {
  __typename: "UpdatePitchPageTemplateModuleInstallationDataResponse";
  message: string;
  success: boolean;
  pitchPageTemplateModuleInstallation: UpdatePitchPageInstallationModuleVideo_updatePitchPageTemplateModuleInstallationData_pitchPageTemplateModuleInstallation | null;
}

export interface UpdatePitchPageInstallationModuleVideo {
  updatePitchPageTemplateModuleInstallationData: UpdatePitchPageInstallationModuleVideo_updatePitchPageTemplateModuleInstallationData;
}

export interface UpdatePitchPageInstallationModuleVideoVariables {
  input: UpdatePitchPageTemplateModuleInstallationDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePitchPageInstallationModuleData
// ====================================================

export interface UpdatePitchPageInstallationModuleData_updatePitchPageTemplateModuleInstallationData_pitchPageTemplateModuleInstallation {
  __typename: "PitchPageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
}

export interface UpdatePitchPageInstallationModuleData_updatePitchPageTemplateModuleInstallationData {
  __typename: "UpdatePitchPageTemplateModuleInstallationDataResponse";
  message: string;
  success: boolean;
  pitchPageTemplateModuleInstallation: UpdatePitchPageInstallationModuleData_updatePitchPageTemplateModuleInstallationData_pitchPageTemplateModuleInstallation | null;
}

export interface UpdatePitchPageInstallationModuleData {
  updatePitchPageTemplateModuleInstallationData: UpdatePitchPageInstallationModuleData_updatePitchPageTemplateModuleInstallationData;
}

export interface UpdatePitchPageInstallationModuleDataVariables {
  input: UpdatePitchPageTemplateModuleInstallationDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePitchPageTemplateModuleMissionStatement
// ====================================================

export interface UpdatePitchPageTemplateModuleMissionStatement_updateOrganizationMissionStatement_organization {
  __typename: "Organization";
  id: string;
  missionStatement: string;
}

export interface UpdatePitchPageTemplateModuleMissionStatement_updateOrganizationMissionStatement {
  __typename: "UpdateOrganizationMissionStatementMutationResponse";
  success: boolean;
  code: string;
  message: string;
  organization: UpdatePitchPageTemplateModuleMissionStatement_updateOrganizationMissionStatement_organization | null;
}

export interface UpdatePitchPageTemplateModuleMissionStatement {
  updateOrganizationMissionStatement: UpdatePitchPageTemplateModuleMissionStatement_updateOrganizationMissionStatement;
}

export interface UpdatePitchPageTemplateModuleMissionStatementVariables {
  input: UpdateOrganizationMissionStatementInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationMission
// ====================================================

export interface OrganizationMission_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  missionStatement: string;
}

export interface OrganizationMission_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: OrganizationMission_currentUserPrisma_currentOrganization | null;
}

export interface OrganizationMission {
  currentUserPrisma: OrganizationMission_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PitchPageSchedulerConfigData
// ====================================================

export interface PitchPageSchedulerConfigData_currentUserPrisma_currentOrganization_schedulerAvailabilities {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface PitchPageSchedulerConfigData_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  schedulerAvailabilities: PitchPageSchedulerConfigData_currentUserPrisma_currentOrganization_schedulerAvailabilities[];
}

export interface PitchPageSchedulerConfigData_currentUserPrisma {
  __typename: "User";
  id: string;
  hasConfiguredGoogleCalendar: boolean;
  currentOrganization: PitchPageSchedulerConfigData_currentUserPrisma_currentOrganization | null;
}

export interface PitchPageSchedulerConfigData {
  currentUserPrisma: PitchPageSchedulerConfigData_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePitchPageInstallationModuleValues
// ====================================================

export interface UpdatePitchPageInstallationModuleValues_updatePitchPageTemplateModuleInstallationData_pitchPageTemplateModuleInstallation {
  __typename: "PitchPageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
}

export interface UpdatePitchPageInstallationModuleValues_updatePitchPageTemplateModuleInstallationData {
  __typename: "UpdatePitchPageTemplateModuleInstallationDataResponse";
  message: string;
  success: boolean;
  pitchPageTemplateModuleInstallation: UpdatePitchPageInstallationModuleValues_updatePitchPageTemplateModuleInstallationData_pitchPageTemplateModuleInstallation | null;
}

export interface UpdatePitchPageInstallationModuleValues {
  updatePitchPageTemplateModuleInstallationData: UpdatePitchPageInstallationModuleValues_updatePitchPageTemplateModuleInstallationData;
}

export interface UpdatePitchPageInstallationModuleValuesVariables {
  input: UpdatePitchPageTemplateModuleInstallationDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationValues
// ====================================================

export interface OrganizationValues_currentUserPrisma_currentOrganization_values {
  __typename: "Value";
  id: GraphQL_UUID;
  title: string;
  description: string;
}

export interface OrganizationValues_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  values: OrganizationValues_currentUserPrisma_currentOrganization_values[];
}

export interface OrganizationValues_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: OrganizationValues_currentUserPrisma_currentOrganization | null;
}

export interface OrganizationValues {
  currentUserPrisma: OrganizationValues_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetOrganizationValues
// ====================================================

export interface SetOrganizationValues_setOrganizationValues_organization_values {
  __typename: "Value";
  id: GraphQL_UUID;
  title: string;
  description: string;
}

export interface SetOrganizationValues_setOrganizationValues_organization {
  __typename: "Organization";
  id: string;
  values: SetOrganizationValues_setOrganizationValues_organization_values[];
}

export interface SetOrganizationValues_setOrganizationValues {
  __typename: "SetOrganizationValuesMutationResponse";
  success: boolean;
  organization: SetOrganizationValues_setOrganizationValues_organization | null;
}

export interface SetOrganizationValues {
  setOrganizationValues: SetOrganizationValues_setOrganizationValues;
}

export interface SetOrganizationValuesVariables {
  input: SetOrganizationValuesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPitchPageTemplateDetails
// ====================================================

export interface GetPitchPageTemplateDetails_pitchPageTemplateFindUnique {
  __typename: "PitchPageTemplate";
  id: string;
  name: string;
}

export interface GetPitchPageTemplateDetails {
  pitchPageTemplateFindUnique: GetPitchPageTemplateDetails_pitchPageTemplateFindUnique;
}

export interface GetPitchPageTemplateDetailsVariables {
  pitchPageTemplateId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePitchPageTemplateName
// ====================================================

export interface UpdatePitchPageTemplateName_updatePitchPageTemplate_pitchPageTemplate {
  __typename: "PitchPageTemplate";
  id: string;
  name: string;
}

export interface UpdatePitchPageTemplateName_updatePitchPageTemplate {
  __typename: "UpdatePitchPageTemplateMutationResponse";
  success: boolean;
  pitchPageTemplate: UpdatePitchPageTemplateName_updatePitchPageTemplate_pitchPageTemplate | null;
}

export interface UpdatePitchPageTemplateName {
  updatePitchPageTemplate: UpdatePitchPageTemplateName_updatePitchPageTemplate;
}

export interface UpdatePitchPageTemplateNameVariables {
  input: UpdatePitchPageTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePitchPage
// ====================================================

export interface DeletePitchPage_deletePitchPage {
  __typename: "DeletePitchPageMutationResponse";
  success: boolean;
}

export interface DeletePitchPage {
  deletePitchPage: DeletePitchPage_deletePitchPage;
}

export interface DeletePitchPageVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GeneratePitchPageShareLink
// ====================================================

export interface GeneratePitchPageShareLink_createPitchPage_pitchPage {
  __typename: "PitchPage";
  id: string;
  recipientFirstName: string;
  url: string;
}

export interface GeneratePitchPageShareLink_createPitchPage {
  __typename: "CreatePitchPageMutationResponse";
  success: boolean;
  pitchPage: GeneratePitchPageShareLink_createPitchPage_pitchPage | null;
}

export interface GeneratePitchPageShareLink {
  createPitchPage: GeneratePitchPageShareLink_createPitchPage;
}

export interface GeneratePitchPageShareLinkVariables {
  firstName: string;
  lastName?: string | null;
  pitchPageTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchedulingIntegrationDataForPitchPage
// ====================================================

export interface GetSchedulingIntegrationDataForPitchPage_currentUserPrisma_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
}

export interface GetSchedulingIntegrationDataForPitchPage_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  name: string;
  companyLogoUrl: string;
  theme: GetSchedulingIntegrationDataForPitchPage_currentUserPrisma_currentOrganization_theme | null;
}

export interface GetSchedulingIntegrationDataForPitchPage_currentUserPrisma_currentUserMembership_schedulerAvailabilities {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
  eventDuration: number;
  eventTitle: string;
  eventLocation: string;
  slug: string;
  editToken: string;
}

export interface GetSchedulingIntegrationDataForPitchPage_currentUserPrisma_currentUserMembership {
  __typename: "UserMembership";
  id: string;
  schedulerAvailabilities: GetSchedulingIntegrationDataForPitchPage_currentUserPrisma_currentUserMembership_schedulerAvailabilities[];
}

export interface GetSchedulingIntegrationDataForPitchPage_currentUserPrisma {
  __typename: "User";
  id: string;
  nylasAccessTokenExists: boolean;
  currentOrganization: GetSchedulingIntegrationDataForPitchPage_currentUserPrisma_currentOrganization | null;
  currentUserMembership: GetSchedulingIntegrationDataForPitchPage_currentUserPrisma_currentUserMembership | null;
}

export interface GetSchedulingIntegrationDataForPitchPage {
  currentUserPrisma: GetSchedulingIntegrationDataForPitchPage_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReorderPitchPageTemplateModulesPrismaProxy
// ====================================================

export interface ReorderPitchPageTemplateModulesPrismaProxy_reorderPitchPageTemplateModuleInstallationsPrismaProxy_pitchPageTemplate_modules {
  __typename: "PitchPageTemplateResolvedModule";
  id: string;
  position: number;
}

export interface ReorderPitchPageTemplateModulesPrismaProxy_reorderPitchPageTemplateModuleInstallationsPrismaProxy_pitchPageTemplate {
  __typename: "PitchPageTemplate";
  id: string;
  modules: ReorderPitchPageTemplateModulesPrismaProxy_reorderPitchPageTemplateModuleInstallationsPrismaProxy_pitchPageTemplate_modules[];
}

export interface ReorderPitchPageTemplateModulesPrismaProxy_reorderPitchPageTemplateModuleInstallationsPrismaProxy {
  __typename: "ReorderPitchPageTemplateModuleInstallationResponsePrismaProxy";
  pitchPageTemplate: ReorderPitchPageTemplateModulesPrismaProxy_reorderPitchPageTemplateModuleInstallationsPrismaProxy_pitchPageTemplate;
}

export interface ReorderPitchPageTemplateModulesPrismaProxy {
  reorderPitchPageTemplateModuleInstallationsPrismaProxy: ReorderPitchPageTemplateModulesPrismaProxy_reorderPitchPageTemplateModuleInstallationsPrismaProxy | null;
}

export interface ReorderPitchPageTemplateModulesPrismaProxyVariables {
  moduleIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemovePitchPageTemplateModule
// ====================================================

export interface RemovePitchPageTemplateModule_deletePitchPageTemplateModule {
  __typename: "DeletePitchPageTemplateModuleInstallationResponse";
  message: string;
  success: boolean;
}

export interface RemovePitchPageTemplateModule {
  deletePitchPageTemplateModule: RemovePitchPageTemplateModule_deletePitchPageTemplateModule;
}

export interface RemovePitchPageTemplateModuleVariables {
  input: DeletePitchPageTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddPitchPageTemplateModule
// ====================================================

export interface AddPitchPageTemplateModule_createPitchPageTemplateModule_pitchPageTemplateModule_stageModule {
  __typename: "StageModule";
  id: string;
}

export interface AddPitchPageTemplateModule_createPitchPageTemplateModule_pitchPageTemplateModule {
  __typename: "PitchPageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
  position: number;
  stageModule: AddPitchPageTemplateModule_createPitchPageTemplateModule_pitchPageTemplateModule_stageModule;
  type: string;
}

export interface AddPitchPageTemplateModule_createPitchPageTemplateModule {
  __typename: "CreatePitchPageTemplateModuleInstallationResponse";
  message: string;
  success: boolean;
  pitchPageTemplateModule: AddPitchPageTemplateModule_createPitchPageTemplateModule_pitchPageTemplateModule | null;
}

export interface AddPitchPageTemplateModule {
  createPitchPageTemplateModule: AddPitchPageTemplateModule_createPitchPageTemplateModule;
}

export interface AddPitchPageTemplateModuleVariables {
  input: CreatePitchPageTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePitchPageTemplate
// ====================================================

export interface CreatePitchPageTemplate_createPitchPageTemplate_pitchPageTemplate {
  __typename: "PitchPageTemplate";
  id: string;
  name: string;
}

export interface CreatePitchPageTemplate_createPitchPageTemplate {
  __typename: "CreatePitchPageTemplateMutationResponse";
  success: boolean;
  pitchPageTemplate: CreatePitchPageTemplate_createPitchPageTemplate_pitchPageTemplate | null;
}

export interface CreatePitchPageTemplate {
  createPitchPageTemplate: CreatePitchPageTemplate_createPitchPageTemplate;
}

export interface CreatePitchPageTemplateVariables {
  name: string;
  fromPitchPageTemplateId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateStageTemplateInstallationEmailActionMetadata
// ====================================================

export interface UpdateStageTemplateInstallationEmailActionMetadata_updateStageTemplateInstallationEmailActionMetadata_stageTemplateInstallation {
  __typename: "StageTemplateInstallation";
  id: string;
  emailActionCC: string[];
  emailActionBCC: string[];
}

export interface UpdateStageTemplateInstallationEmailActionMetadata_updateStageTemplateInstallationEmailActionMetadata {
  __typename: "UpdateStageTemplateInstallationEmailActionMetadataResponse";
  message: string;
  code: string;
  success: boolean;
  stageTemplateInstallation: UpdateStageTemplateInstallationEmailActionMetadata_updateStageTemplateInstallationEmailActionMetadata_stageTemplateInstallation | null;
}

export interface UpdateStageTemplateInstallationEmailActionMetadata {
  updateStageTemplateInstallationEmailActionMetadata: UpdateStageTemplateInstallationEmailActionMetadata_updateStageTemplateInstallationEmailActionMetadata;
}

export interface UpdateStageTemplateInstallationEmailActionMetadataVariables {
  input: UpdateStageTemplateInstallationEmailActionMetadataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateStageTemplateInstallationEmailTemplateId
// ====================================================

export interface UpdateStageTemplateInstallationEmailTemplateId_updateStageTemplateInstallationEmailTemplateId_stageTemplateInstallation_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  archivedAt: GraphQL_DateTime | null;
}

export interface UpdateStageTemplateInstallationEmailTemplateId_updateStageTemplateInstallationEmailTemplateId_stageTemplateInstallation {
  __typename: "StageTemplateInstallation";
  id: string;
  emailTemplate: UpdateStageTemplateInstallationEmailTemplateId_updateStageTemplateInstallationEmailTemplateId_stageTemplateInstallation_emailTemplate | null;
}

export interface UpdateStageTemplateInstallationEmailTemplateId_updateStageTemplateInstallationEmailTemplateId {
  __typename: "UpdateStageTemplateInstallationEmailTemplateIdResponse";
  success: boolean;
  code: string;
  message: string;
  stageTemplateInstallation: UpdateStageTemplateInstallationEmailTemplateId_updateStageTemplateInstallationEmailTemplateId_stageTemplateInstallation | null;
}

export interface UpdateStageTemplateInstallationEmailTemplateId {
  updateStageTemplateInstallationEmailTemplateId: UpdateStageTemplateInstallationEmailTemplateId_updateStageTemplateInstallationEmailTemplateId;
}

export interface UpdateStageTemplateInstallationEmailTemplateIdVariables {
  input: UpdateStageTemplateInstallationEmailTemplateIdInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobRoleGuideTemplateForActivation
// ====================================================

export interface JobRoleGuideTemplateForActivation_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface JobRoleGuideTemplateForActivation_currentOrganization_contentTemplates {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  archivedAt: GraphQL_DateTime | null;
}

export interface JobRoleGuideTemplateForActivation_currentOrganization_emailTemplates {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  archivedAt: GraphQL_DateTime | null;
}

export interface JobRoleGuideTemplateForActivation_currentOrganization_customer_atsIntegration {
  __typename: "AtsIntegration";
  id: string;
  atsType: string | null;
  displayName: string | null;
}

export interface JobRoleGuideTemplateForActivation_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  atsIntegration: JobRoleGuideTemplateForActivation_currentOrganization_customer_atsIntegration | null;
}

export interface JobRoleGuideTemplateForActivation_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  archivedAt: GraphQL_DateTime | null;
}

export interface JobRoleGuideTemplateForActivation_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_contentTemplate_modules {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  data: GraphQL_JSON;
  position: number;
}

export interface JobRoleGuideTemplateForActivation_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  archivedAt: GraphQL_DateTime | null;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered. Also includes defaults and additional
   *     resolved data needed by our Content Template editor. We should instead fetch the data
   *     needed for the editor directly from those frontend components, and switch to simply using 
   *     "modulesInflated" instead
   */
  modules: JobRoleGuideTemplateForActivation_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_contentTemplate_modules[];
}

export interface JobRoleGuideTemplateForActivation_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_eventTemplates_interviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  title: GraphQL_JSON | null;
  description: GraphQL_JSON | null;
}

export interface JobRoleGuideTemplateForActivation_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  position: number;
  description: string;
  hidden: boolean;
  atsEventTemplateId: string;
  atsEventTemplateName: string;
  interviewTemplate: JobRoleGuideTemplateForActivation_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_eventTemplates_interviewTemplate | null;
}

export interface JobRoleGuideTemplateForActivation_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations {
  __typename: "StageTemplateInstallation";
  id: string;
  atsStageName: string;
  atsStageId: string;
  displayName: string;
  description: string | null;
  activatedAt: GraphQL_DateTime | null;
  hidden: boolean;
  position: number;
  requireManualApproval: boolean;
  emailTemplate: JobRoleGuideTemplateForActivation_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_emailTemplate | null;
  emailActionCC: string[];
  emailActionBCC: string[];
  contentTemplate: JobRoleGuideTemplateForActivation_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_contentTemplate;
  eventTemplates: JobRoleGuideTemplateForActivation_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_eventTemplates[];
}

export interface JobRoleGuideTemplateForActivation_currentOrganization_jobRoleGuideTemplateById_jobRole {
  __typename: "JobRole";
  id: string;
  name: string;
  atsJobId: string;
}

export interface JobRoleGuideTemplateForActivation_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleNameOverride: string | null;
  stageTemplateInstallations: JobRoleGuideTemplateForActivation_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations[];
  jobRole: JobRoleGuideTemplateForActivation_currentOrganization_jobRoleGuideTemplateById_jobRole | null;
}

export interface JobRoleGuideTemplateForActivation_currentOrganization {
  __typename: "Organization";
  id: string;
  theme: JobRoleGuideTemplateForActivation_currentOrganization_theme | null;
  name: string;
  companyLogoUrl: string;
  contentTemplates: JobRoleGuideTemplateForActivation_currentOrganization_contentTemplates[];
  emailTemplates: JobRoleGuideTemplateForActivation_currentOrganization_emailTemplates[];
  customer: JobRoleGuideTemplateForActivation_currentOrganization_customer;
  jobRoleGuideTemplateById: JobRoleGuideTemplateForActivation_currentOrganization_jobRoleGuideTemplateById;
}

export interface JobRoleGuideTemplateForActivation {
  currentOrganization: JobRoleGuideTemplateForActivation_currentOrganization | null;
}

export interface JobRoleGuideTemplateForActivationVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddGreenhouseStageTriggerPrismaProxy
// ====================================================

export interface AddGreenhouseStageTriggerPrismaProxy_addGreenhouseStageTriggerPrismaProxy_stageTemplateInstallation {
  __typename: "StageTemplateInstallation";
  id: string;
}

export interface AddGreenhouseStageTriggerPrismaProxy_addGreenhouseStageTriggerPrismaProxy {
  __typename: "InsertGreenhouseStageTriggerResponsePrismaProxy";
  success: boolean;
  stageTemplateInstallation: AddGreenhouseStageTriggerPrismaProxy_addGreenhouseStageTriggerPrismaProxy_stageTemplateInstallation | null;
}

export interface AddGreenhouseStageTriggerPrismaProxy {
  addGreenhouseStageTriggerPrismaProxy: AddGreenhouseStageTriggerPrismaProxy_addGreenhouseStageTriggerPrismaProxy;
}

export interface AddGreenhouseStageTriggerPrismaProxyVariables {
  input: InsertGreenhouseStageTriggerInputPrismaProxy;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfigureStageTemplateInstallationPrismaProxy
// ====================================================

export interface ConfigureStageTemplateInstallationPrismaProxy_configureStageTemplateInstallationPrismaProxy_stageTemplateInstallation {
  __typename: "StageTemplateInstallation";
  id: string;
}

export interface ConfigureStageTemplateInstallationPrismaProxy_configureStageTemplateInstallationPrismaProxy {
  __typename: "ConfigureStageTemplateInstallationResponsePrismaProxy";
  success: boolean;
  message: string;
  code: string;
  stageTemplateInstallation: ConfigureStageTemplateInstallationPrismaProxy_configureStageTemplateInstallationPrismaProxy_stageTemplateInstallation | null;
}

export interface ConfigureStageTemplateInstallationPrismaProxy {
  configureStageTemplateInstallationPrismaProxy: ConfigureStageTemplateInstallationPrismaProxy_configureStageTemplateInstallationPrismaProxy;
}

export interface ConfigureStageTemplateInstallationPrismaProxyVariables {
  input: ConfigureStageTemplateInstallationInputPrismaProxy;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobStagesForJobRoleGuideTemplateActivation
// ====================================================

export interface JobStagesForJobRoleGuideTemplateActivation_currentOrganization_jobRoleGuideTemplateById_atsJob_atsJobStages {
  __typename: "ATSJobRoleStage";
  id: string;
  originalATSId: string;
  name: string;
  position: number | null;
  jobId: string;
  rawJson: GraphQL_JSON;
}

export interface JobStagesForJobRoleGuideTemplateActivation_currentOrganization_jobRoleGuideTemplateById_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
  atsJobStages: JobStagesForJobRoleGuideTemplateActivation_currentOrganization_jobRoleGuideTemplateById_atsJob_atsJobStages[];
}

export interface JobStagesForJobRoleGuideTemplateActivation_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  jobRoleId: string | null;
  atsJob: JobStagesForJobRoleGuideTemplateActivation_currentOrganization_jobRoleGuideTemplateById_atsJob | null;
}

export interface JobStagesForJobRoleGuideTemplateActivation_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplateById: JobStagesForJobRoleGuideTemplateActivation_currentOrganization_jobRoleGuideTemplateById;
}

export interface JobStagesForJobRoleGuideTemplateActivation {
  currentOrganization: JobStagesForJobRoleGuideTemplateActivation_currentOrganization | null;
}

export interface JobStagesForJobRoleGuideTemplateActivationVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateStageTemplateInstallationDisplayName
// ====================================================

export interface UpdateStageTemplateInstallationDisplayName_updateStageTemplateInstallationDisplayName_stageTemplateInstallation {
  __typename: "StageTemplateInstallation";
  id: string;
  displayName: string;
}

export interface UpdateStageTemplateInstallationDisplayName_updateStageTemplateInstallationDisplayName {
  __typename: "UpdateStageTemplateInstallationDisplayNameResponse";
  message: string;
  code: string;
  success: boolean;
  stageTemplateInstallation: UpdateStageTemplateInstallationDisplayName_updateStageTemplateInstallationDisplayName_stageTemplateInstallation | null;
}

export interface UpdateStageTemplateInstallationDisplayName {
  updateStageTemplateInstallationDisplayName: UpdateStageTemplateInstallationDisplayName_updateStageTemplateInstallationDisplayName;
}

export interface UpdateStageTemplateInstallationDisplayNameVariables {
  input: UpdateStageTemplateInstallationDisplayNameInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateStageTemplateInstallationDescription
// ====================================================

export interface UpdateStageTemplateInstallationDescription_updateStageTemplateInstallationDescription_stageTemplateInstallation {
  __typename: "StageTemplateInstallation";
  id: string;
  description: string | null;
}

export interface UpdateStageTemplateInstallationDescription_updateStageTemplateInstallationDescription {
  __typename: "UpdateStageTemplateInstallationEmailTemplateMutationResponse";
  message: string;
  code: string;
  success: boolean;
  stageTemplateInstallation: UpdateStageTemplateInstallationDescription_updateStageTemplateInstallationDescription_stageTemplateInstallation | null;
}

export interface UpdateStageTemplateInstallationDescription {
  updateStageTemplateInstallationDescription: UpdateStageTemplateInstallationDescription_updateStageTemplateInstallationDescription;
}

export interface UpdateStageTemplateInstallationDescriptionVariables {
  input: UpdateStageTemplateInstallationDescriptionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateStageTemplateInstallationContentTemplateId
// ====================================================

export interface UpdateStageTemplateInstallationContentTemplateId_updateStageTemplateInstallationContentTemplateId_stageTemplateInstallation_contentTemplate_modules {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  data: GraphQL_JSON;
  position: number;
}

export interface UpdateStageTemplateInstallationContentTemplateId_updateStageTemplateInstallationContentTemplateId_stageTemplateInstallation_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  archivedAt: GraphQL_DateTime | null;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered. Also includes defaults and additional
   *     resolved data needed by our Content Template editor. We should instead fetch the data
   *     needed for the editor directly from those frontend components, and switch to simply using 
   *     "modulesInflated" instead
   */
  modules: UpdateStageTemplateInstallationContentTemplateId_updateStageTemplateInstallationContentTemplateId_stageTemplateInstallation_contentTemplate_modules[];
}

export interface UpdateStageTemplateInstallationContentTemplateId_updateStageTemplateInstallationContentTemplateId_stageTemplateInstallation {
  __typename: "StageTemplateInstallation";
  id: string;
  contentTemplate: UpdateStageTemplateInstallationContentTemplateId_updateStageTemplateInstallationContentTemplateId_stageTemplateInstallation_contentTemplate;
}

export interface UpdateStageTemplateInstallationContentTemplateId_updateStageTemplateInstallationContentTemplateId {
  __typename: "UpdateStageTemplateInstallationContentTemplateIdResponse";
  success: boolean;
  code: string;
  message: string;
  stageTemplateInstallation: UpdateStageTemplateInstallationContentTemplateId_updateStageTemplateInstallationContentTemplateId_stageTemplateInstallation | null;
}

export interface UpdateStageTemplateInstallationContentTemplateId {
  updateStageTemplateInstallationContentTemplateId: UpdateStageTemplateInstallationContentTemplateId_updateStageTemplateInstallationContentTemplateId;
}

export interface UpdateStageTemplateInstallationContentTemplateIdVariables {
  input: UpdateStageTemplateInstallationContentTemplateIdInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeactivateStageTemplateInstallation
// ====================================================

export interface DeactivateStageTemplateInstallation_deactivateStageTemplateInstallation_stageTemplateInstallation {
  __typename: "StageTemplateInstallation";
  id: string;
  activatedAt: GraphQL_DateTime | null;
}

export interface DeactivateStageTemplateInstallation_deactivateStageTemplateInstallation {
  __typename: "DeactivateStageTemplateInstallationResponse";
  message: string;
  code: string;
  success: boolean;
  stageTemplateInstallation: DeactivateStageTemplateInstallation_deactivateStageTemplateInstallation_stageTemplateInstallation | null;
}

export interface DeactivateStageTemplateInstallation {
  deactivateStageTemplateInstallation: DeactivateStageTemplateInstallation_deactivateStageTemplateInstallation;
}

export interface DeactivateStageTemplateInstallationVariables {
  input: DeactivateStageTemplateInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveStageTemplateInstallationEmailTemplate
// ====================================================

export interface RemoveStageTemplateInstallationEmailTemplate_removeStageTemplateInstallationEmailTemplate_stageTemplateInstallation_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
}

export interface RemoveStageTemplateInstallationEmailTemplate_removeStageTemplateInstallationEmailTemplate_stageTemplateInstallation {
  __typename: "StageTemplateInstallation";
  id: string;
  emailTemplate: RemoveStageTemplateInstallationEmailTemplate_removeStageTemplateInstallationEmailTemplate_stageTemplateInstallation_emailTemplate | null;
}

export interface RemoveStageTemplateInstallationEmailTemplate_removeStageTemplateInstallationEmailTemplate {
  __typename: "RemoveStageTemplateInstallationEmailTemplateResponse";
  success: boolean;
  code: string;
  message: string;
  stageTemplateInstallation: RemoveStageTemplateInstallationEmailTemplate_removeStageTemplateInstallationEmailTemplate_stageTemplateInstallation | null;
}

export interface RemoveStageTemplateInstallationEmailTemplate {
  removeStageTemplateInstallationEmailTemplate: RemoveStageTemplateInstallationEmailTemplate_removeStageTemplateInstallationEmailTemplate;
}

export interface RemoveStageTemplateInstallationEmailTemplateVariables {
  input: RemoveStageTemplateInstallationEmailTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchedulerConfigForStageTemplateInstallation
// ====================================================

export interface GetSchedulerConfigForStageTemplateInstallation_currentOrganization_stageTemplateInstallationById_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface GetSchedulerConfigForStageTemplateInstallation_currentOrganization_stageTemplateInstallationById_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface GetSchedulerConfigForStageTemplateInstallation_currentOrganization_stageTemplateInstallationById_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
}

export interface GetSchedulerConfigForStageTemplateInstallation_currentOrganization_stageTemplateInstallationById_schedulerConfig_configInterviewers {
  __typename: "StageTemplateInstallationSchedulerConfigInterviewer";
  id: number;
  interviewer: GetSchedulerConfigForStageTemplateInstallation_currentOrganization_stageTemplateInstallationById_schedulerConfig_configInterviewers_interviewer;
}

export interface GetSchedulerConfigForStageTemplateInstallation_currentOrganization_stageTemplateInstallationById_schedulerConfig {
  __typename: "StageTemplateInstallationSchedulerConfig";
  id: string;
  eventTemplate: GetSchedulerConfigForStageTemplateInstallation_currentOrganization_stageTemplateInstallationById_schedulerConfig_eventTemplate | null;
  schedulerAvailability: GetSchedulerConfigForStageTemplateInstallation_currentOrganization_stageTemplateInstallationById_schedulerConfig_schedulerAvailability | null;
  configInterviewers: GetSchedulerConfigForStageTemplateInstallation_currentOrganization_stageTemplateInstallationById_schedulerConfig_configInterviewers[];
}

export interface GetSchedulerConfigForStageTemplateInstallation_currentOrganization_stageTemplateInstallationById {
  __typename: "StageTemplateInstallation";
  id: string;
  schedulerConfig: GetSchedulerConfigForStageTemplateInstallation_currentOrganization_stageTemplateInstallationById_schedulerConfig | null;
}

export interface GetSchedulerConfigForStageTemplateInstallation_currentOrganization {
  __typename: "Organization";
  id: string;
  stageTemplateInstallationById: GetSchedulerConfigForStageTemplateInstallation_currentOrganization_stageTemplateInstallationById | null;
}

export interface GetSchedulerConfigForStageTemplateInstallation {
  currentOrganization: GetSchedulerConfigForStageTemplateInstallation_currentOrganization | null;
}

export interface GetSchedulerConfigForStageTemplateInstallationVariables {
  stageTemplateInstallationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationAvailabilitiesForStageTemplateInstallation
// ====================================================

export interface GetOrganizationAvailabilitiesForStageTemplateInstallation_currentUserPrisma_currentOrganization_schedulerAvailabilities {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface GetOrganizationAvailabilitiesForStageTemplateInstallation_currentUserPrisma_currentOrganization_interviewers {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
}

export interface GetOrganizationAvailabilitiesForStageTemplateInstallation_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  schedulerAvailabilities: GetOrganizationAvailabilitiesForStageTemplateInstallation_currentUserPrisma_currentOrganization_schedulerAvailabilities[];
  interviewers: GetOrganizationAvailabilitiesForStageTemplateInstallation_currentUserPrisma_currentOrganization_interviewers[];
}

export interface GetOrganizationAvailabilitiesForStageTemplateInstallation_currentUserPrisma {
  __typename: "User";
  id: string;
  nylasAccessTokenExists: boolean;
  currentOrganization: GetOrganizationAvailabilitiesForStageTemplateInstallation_currentUserPrisma_currentOrganization | null;
}

export interface GetOrganizationAvailabilitiesForStageTemplateInstallation {
  currentUserPrisma: GetOrganizationAvailabilitiesForStageTemplateInstallation_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertInterviewSchedulerConfig
// ====================================================

export interface UpsertInterviewSchedulerConfig_upsertInterviewSchedulerConfig_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
}

export interface UpsertInterviewSchedulerConfig_upsertInterviewSchedulerConfig_schedulerConfig_configInterviewers {
  __typename: "StageTemplateInstallationSchedulerConfigInterviewer";
  id: number;
  interviewer: UpsertInterviewSchedulerConfig_upsertInterviewSchedulerConfig_schedulerConfig_configInterviewers_interviewer;
}

export interface UpsertInterviewSchedulerConfig_upsertInterviewSchedulerConfig_schedulerConfig {
  __typename: "StageTemplateInstallationSchedulerConfig";
  id: string;
  configInterviewers: UpsertInterviewSchedulerConfig_upsertInterviewSchedulerConfig_schedulerConfig_configInterviewers[];
}

export interface UpsertInterviewSchedulerConfig_upsertInterviewSchedulerConfig {
  __typename: "UpsertInterviewSchedulerConfigResponse";
  message: string;
  code: string;
  success: boolean;
  schedulerConfig: UpsertInterviewSchedulerConfig_upsertInterviewSchedulerConfig_schedulerConfig | null;
}

export interface UpsertInterviewSchedulerConfig {
  upsertInterviewSchedulerConfig: UpsertInterviewSchedulerConfig_upsertInterviewSchedulerConfig;
}

export interface UpsertInterviewSchedulerConfigVariables {
  input: UpsertInterviewSchedulerConfigInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SyncATSJobsForJourneysDjangoAction
// ====================================================

export interface SyncATSJobsForJourneysDjangoAction_syncATSJobsPrismaProxy {
  __typename: "SyncATSJobsResponsePrismaProxy";
  taskId: string;
}

export interface SyncATSJobsForJourneysDjangoAction {
  syncATSJobsPrismaProxy: SyncATSJobsForJourneysDjangoAction_syncATSJobsPrismaProxy;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailabilityDeleteStats
// ====================================================

export interface AvailabilityDeleteStats_schedulerAvailabilityFindUnique_stageTemplateInstallationSchedulerConfigs_stageTemplateInstallation_jobRoleGuideTemplate_organization {
  __typename: "Organization";
  id: string;
}

export interface AvailabilityDeleteStats_schedulerAvailabilityFindUnique_stageTemplateInstallationSchedulerConfigs_stageTemplateInstallation_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  organization: AvailabilityDeleteStats_schedulerAvailabilityFindUnique_stageTemplateInstallationSchedulerConfigs_stageTemplateInstallation_jobRoleGuideTemplate_organization;
}

export interface AvailabilityDeleteStats_schedulerAvailabilityFindUnique_stageTemplateInstallationSchedulerConfigs_stageTemplateInstallation {
  __typename: "StageTemplateInstallation";
  id: string;
  jobRoleGuideTemplate: AvailabilityDeleteStats_schedulerAvailabilityFindUnique_stageTemplateInstallationSchedulerConfigs_stageTemplateInstallation_jobRoleGuideTemplate;
}

export interface AvailabilityDeleteStats_schedulerAvailabilityFindUnique_stageTemplateInstallationSchedulerConfigs {
  __typename: "StageTemplateInstallationSchedulerConfig";
  id: string;
  stageTemplateInstallation: AvailabilityDeleteStats_schedulerAvailabilityFindUnique_stageTemplateInstallationSchedulerConfigs_stageTemplateInstallation;
}

export interface AvailabilityDeleteStats_schedulerAvailabilityFindUnique {
  __typename: "SchedulerAvailability";
  id: string;
  stageTemplateInstallationSchedulerConfigs: AvailabilityDeleteStats_schedulerAvailabilityFindUnique_stageTemplateInstallationSchedulerConfigs[];
}

export interface AvailabilityDeleteStats {
  schedulerAvailabilityFindUnique: AvailabilityDeleteStats_schedulerAvailabilityFindUnique;
}

export interface AvailabilityDeleteStatsVariables {
  where: SchedulerAvailabilityWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentUserForAvailabilityPage
// ====================================================

export interface CurrentUserForAvailabilityPage_currentUserPrisma_currentUserMembership {
  __typename: "UserMembership";
  id: string;
  hasLimitedAccess: boolean;
}

export interface CurrentUserForAvailabilityPage_currentUserPrisma {
  __typename: "User";
  id: string;
  currentUserMembership: CurrentUserForAvailabilityPage_currentUserPrisma_currentUserMembership | null;
}

export interface CurrentUserForAvailabilityPage {
  currentUserPrisma: CurrentUserForAvailabilityPage_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateAvailabilityForCurrentUserPrismaProxy
// ====================================================

export interface GenerateAvailabilityForCurrentUserPrismaProxy_generateAvailabilityForCurrentUserPrismaProxy_availability {
  __typename: "SchedulerAvailability";
  id: string;
  slug: string;
  eventTitle: string;
  eventDuration: number;
  eventLocation: string;
  editToken: string;
  bookingMinCancellationNotice: number;
}

export interface GenerateAvailabilityForCurrentUserPrismaProxy_generateAvailabilityForCurrentUserPrismaProxy {
  __typename: "GenerateAvailabilityForCurrentUserResponsePrismaProxy";
  success: boolean;
  availability: GenerateAvailabilityForCurrentUserPrismaProxy_generateAvailabilityForCurrentUserPrismaProxy_availability | null;
}

export interface GenerateAvailabilityForCurrentUserPrismaProxy {
  generateAvailabilityForCurrentUserPrismaProxy: GenerateAvailabilityForCurrentUserPrismaProxy_generateAvailabilityForCurrentUserPrismaProxy | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteAvailabilityPrismaProxy
// ====================================================

export interface DeleteAvailabilityPrismaProxy_deleteAvailabilityPrismaProxy {
  __typename: "DeleteAvailabilityResponsePrismaProxy";
  success: boolean;
  availabilityId: GraphQL_UUID | null;
}

export interface DeleteAvailabilityPrismaProxy {
  deleteAvailabilityPrismaProxy: DeleteAvailabilityPrismaProxy_deleteAvailabilityPrismaProxy;
}

export interface DeleteAvailabilityPrismaProxyVariables {
  input: DeleteAvailabilityInputPrismaProxy;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSchedulerAvailabilityPageTemplateModuleInstallationInterviewScheduler
// ====================================================

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationInterviewScheduler_updateSchedulerAvailabilityPageTemplateModuleInstallationData_schedulerAvailabilityPageTemplateModuleInstallation {
  __typename: "SchedulerAvailabilityPageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationInterviewScheduler_updateSchedulerAvailabilityPageTemplateModuleInstallationData {
  __typename: "UpdateSchedulerAvailabilityPageTemplateModuleInstallationDataResponse";
  message: string;
  success: boolean;
  schedulerAvailabilityPageTemplateModuleInstallation: UpdateSchedulerAvailabilityPageTemplateModuleInstallationInterviewScheduler_updateSchedulerAvailabilityPageTemplateModuleInstallationData_schedulerAvailabilityPageTemplateModuleInstallation | null;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationInterviewScheduler {
  updateSchedulerAvailabilityPageTemplateModuleInstallationData: UpdateSchedulerAvailabilityPageTemplateModuleInstallationInterviewScheduler_updateSchedulerAvailabilityPageTemplateModuleInstallationData;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationInterviewSchedulerVariables {
  input: UpdateSchedulerAvailabilityPageTemplateModuleInstallationDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSchedulerAvailabilityPageTemplateModuleInstallationLinks
// ====================================================

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationLinks_updateSchedulerAvailabilityPageTemplateModuleInstallationData_schedulerAvailabilityPageTemplateModuleInstallation {
  __typename: "SchedulerAvailabilityPageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationLinks_updateSchedulerAvailabilityPageTemplateModuleInstallationData {
  __typename: "UpdateSchedulerAvailabilityPageTemplateModuleInstallationDataResponse";
  message: string;
  success: boolean;
  schedulerAvailabilityPageTemplateModuleInstallation: UpdateSchedulerAvailabilityPageTemplateModuleInstallationLinks_updateSchedulerAvailabilityPageTemplateModuleInstallationData_schedulerAvailabilityPageTemplateModuleInstallation | null;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationLinks {
  updateSchedulerAvailabilityPageTemplateModuleInstallationData: UpdateSchedulerAvailabilityPageTemplateModuleInstallationLinks_updateSchedulerAvailabilityPageTemplateModuleInstallationData;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationLinksVariables {
  input: UpdateSchedulerAvailabilityPageTemplateModuleInstallationDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSchedulerAvailabilityPageTemplateModuleInstallationLogistics
// ====================================================

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationLogistics_updateSchedulerAvailabilityPageTemplateModuleInstallationData_schedulerAvailabilityPageTemplateModuleInstallation {
  __typename: "SchedulerAvailabilityPageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationLogistics_updateSchedulerAvailabilityPageTemplateModuleInstallationData {
  __typename: "UpdateSchedulerAvailabilityPageTemplateModuleInstallationDataResponse";
  message: string;
  success: boolean;
  schedulerAvailabilityPageTemplateModuleInstallation: UpdateSchedulerAvailabilityPageTemplateModuleInstallationLogistics_updateSchedulerAvailabilityPageTemplateModuleInstallationData_schedulerAvailabilityPageTemplateModuleInstallation | null;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationLogistics {
  updateSchedulerAvailabilityPageTemplateModuleInstallationData: UpdateSchedulerAvailabilityPageTemplateModuleInstallationLogistics_updateSchedulerAvailabilityPageTemplateModuleInstallationData;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationLogisticsVariables {
  input: UpdateSchedulerAvailabilityPageTemplateModuleInstallationDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSchedulerAvailabilityPageTemplateModuleInstallationMission
// ====================================================

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationMission_updateSchedulerAvailabilityPageTemplateModuleInstallationData_schedulerAvailabilityPageTemplateModuleInstallation {
  __typename: "SchedulerAvailabilityPageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationMission_updateSchedulerAvailabilityPageTemplateModuleInstallationData {
  __typename: "UpdateSchedulerAvailabilityPageTemplateModuleInstallationDataResponse";
  message: string;
  success: boolean;
  schedulerAvailabilityPageTemplateModuleInstallation: UpdateSchedulerAvailabilityPageTemplateModuleInstallationMission_updateSchedulerAvailabilityPageTemplateModuleInstallationData_schedulerAvailabilityPageTemplateModuleInstallation | null;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationMission {
  updateSchedulerAvailabilityPageTemplateModuleInstallationData: UpdateSchedulerAvailabilityPageTemplateModuleInstallationMission_updateSchedulerAvailabilityPageTemplateModuleInstallationData;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationMissionVariables {
  input: UpdateSchedulerAvailabilityPageTemplateModuleInstallationDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSchedulerAvailabilityPageTemplateModuleInstallationPhoto
// ====================================================

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationPhoto_updateSchedulerAvailabilityPageTemplateModuleInstallationData_schedulerAvailabilityPageTemplateModuleInstallation {
  __typename: "SchedulerAvailabilityPageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationPhoto_updateSchedulerAvailabilityPageTemplateModuleInstallationData {
  __typename: "UpdateSchedulerAvailabilityPageTemplateModuleInstallationDataResponse";
  message: string;
  success: boolean;
  schedulerAvailabilityPageTemplateModuleInstallation: UpdateSchedulerAvailabilityPageTemplateModuleInstallationPhoto_updateSchedulerAvailabilityPageTemplateModuleInstallationData_schedulerAvailabilityPageTemplateModuleInstallation | null;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationPhoto {
  updateSchedulerAvailabilityPageTemplateModuleInstallationData: UpdateSchedulerAvailabilityPageTemplateModuleInstallationPhoto_updateSchedulerAvailabilityPageTemplateModuleInstallationData;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationPhotoVariables {
  input: UpdateSchedulerAvailabilityPageTemplateModuleInstallationDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSchedulerAvailabilityPageTemplateModuleInstallationRichText
// ====================================================

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationRichText_updateSchedulerAvailabilityPageTemplateModuleInstallationData_schedulerAvailabilityPageTemplateModuleInstallation {
  __typename: "SchedulerAvailabilityPageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationRichText_updateSchedulerAvailabilityPageTemplateModuleInstallationData {
  __typename: "UpdateSchedulerAvailabilityPageTemplateModuleInstallationDataResponse";
  message: string;
  success: boolean;
  schedulerAvailabilityPageTemplateModuleInstallation: UpdateSchedulerAvailabilityPageTemplateModuleInstallationRichText_updateSchedulerAvailabilityPageTemplateModuleInstallationData_schedulerAvailabilityPageTemplateModuleInstallation | null;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationRichText {
  updateSchedulerAvailabilityPageTemplateModuleInstallationData: UpdateSchedulerAvailabilityPageTemplateModuleInstallationRichText_updateSchedulerAvailabilityPageTemplateModuleInstallationData;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationRichTextVariables {
  input: UpdateSchedulerAvailabilityPageTemplateModuleInstallationDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSchedulerAvailabilityPageTemplateModuleInstallationValues
// ====================================================

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationValues_updateSchedulerAvailabilityPageTemplateModuleInstallationData_schedulerAvailabilityPageTemplateModuleInstallation {
  __typename: "SchedulerAvailabilityPageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationValues_updateSchedulerAvailabilityPageTemplateModuleInstallationData {
  __typename: "UpdateSchedulerAvailabilityPageTemplateModuleInstallationDataResponse";
  message: string;
  success: boolean;
  schedulerAvailabilityPageTemplateModuleInstallation: UpdateSchedulerAvailabilityPageTemplateModuleInstallationValues_updateSchedulerAvailabilityPageTemplateModuleInstallationData_schedulerAvailabilityPageTemplateModuleInstallation | null;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationValues {
  updateSchedulerAvailabilityPageTemplateModuleInstallationData: UpdateSchedulerAvailabilityPageTemplateModuleInstallationValues_updateSchedulerAvailabilityPageTemplateModuleInstallationData;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationValuesVariables {
  input: UpdateSchedulerAvailabilityPageTemplateModuleInstallationDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSchedulerAvailabilityPageTemplateModuleInstallationVideo
// ====================================================

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationVideo_updateSchedulerAvailabilityPageTemplateModuleInstallationData_schedulerAvailabilityPageTemplateModuleInstallation {
  __typename: "SchedulerAvailabilityPageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationVideo_updateSchedulerAvailabilityPageTemplateModuleInstallationData {
  __typename: "UpdateSchedulerAvailabilityPageTemplateModuleInstallationDataResponse";
  message: string;
  success: boolean;
  schedulerAvailabilityPageTemplateModuleInstallation: UpdateSchedulerAvailabilityPageTemplateModuleInstallationVideo_updateSchedulerAvailabilityPageTemplateModuleInstallationData_schedulerAvailabilityPageTemplateModuleInstallation | null;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationVideo {
  updateSchedulerAvailabilityPageTemplateModuleInstallationData: UpdateSchedulerAvailabilityPageTemplateModuleInstallationVideo_updateSchedulerAvailabilityPageTemplateModuleInstallationData;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationVideoVariables {
  input: UpdateSchedulerAvailabilityPageTemplateModuleInstallationDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReorderAvailabilityPageTemplateModulesPrismaProxy
// ====================================================

export interface ReorderAvailabilityPageTemplateModulesPrismaProxy_reorderAvailabilityPageTemplateModuleInstallationsPrismaProxy_availabilityPageTemplate_resolvedModules {
  __typename: "SchedulerAvailabilityPageTemplateResolvedModule";
  id: string;
  position: number;
}

export interface ReorderAvailabilityPageTemplateModulesPrismaProxy_reorderAvailabilityPageTemplateModuleInstallationsPrismaProxy_availabilityPageTemplate {
  __typename: "SchedulerAvailabilityPageTemplate";
  id: string;
  resolvedModules: ReorderAvailabilityPageTemplateModulesPrismaProxy_reorderAvailabilityPageTemplateModuleInstallationsPrismaProxy_availabilityPageTemplate_resolvedModules[];
}

export interface ReorderAvailabilityPageTemplateModulesPrismaProxy_reorderAvailabilityPageTemplateModuleInstallationsPrismaProxy {
  __typename: "ReorderAvailabilityPageTemplateModuleInstallationResponsePrismaProxy";
  availabilityPageTemplate: ReorderAvailabilityPageTemplateModulesPrismaProxy_reorderAvailabilityPageTemplateModuleInstallationsPrismaProxy_availabilityPageTemplate;
}

export interface ReorderAvailabilityPageTemplateModulesPrismaProxy {
  reorderAvailabilityPageTemplateModuleInstallationsPrismaProxy: ReorderAvailabilityPageTemplateModulesPrismaProxy_reorderAvailabilityPageTemplateModuleInstallationsPrismaProxy | null;
}

export interface ReorderAvailabilityPageTemplateModulesPrismaProxyVariables {
  moduleIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveAvailabilityPageTemplateModule
// ====================================================

export interface RemoveAvailabilityPageTemplateModule_removeAvailabilityPageTemplateModule {
  __typename: "RemoveAvailabilityPageTemplateModuleMutationResponse";
  success: boolean;
  message: string;
}

export interface RemoveAvailabilityPageTemplateModule {
  removeAvailabilityPageTemplateModule: RemoveAvailabilityPageTemplateModule_removeAvailabilityPageTemplateModule | null;
}

export interface RemoveAvailabilityPageTemplateModuleVariables {
  input: RemoveAvailabilityPageTemplateModuleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddAvailabilityPageTemplateModule
// ====================================================

export interface AddAvailabilityPageTemplateModule_createAvailabilityPageTemplateModule_availabilityPageTemplate {
  __typename: "SchedulerAvailabilityPageTemplate";
  id: string;
}

export interface AddAvailabilityPageTemplateModule_createAvailabilityPageTemplateModule {
  __typename: "CreateAvailabilityPageTemplateModuleMutationResponse";
  success: boolean;
  message: string;
  availabilityPageTemplate: AddAvailabilityPageTemplateModule_createAvailabilityPageTemplateModule_availabilityPageTemplate | null;
}

export interface AddAvailabilityPageTemplateModule {
  createAvailabilityPageTemplateModule: AddAvailabilityPageTemplateModule_createAvailabilityPageTemplateModule | null;
}

export interface AddAvailabilityPageTemplateModuleVariables {
  input: CreateAvailabilityPageTemplateModuleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SyncNylasPagesPrismaProxy
// ====================================================

export interface SyncNylasPagesPrismaProxy_syncNylasPagesPrismaProxy {
  __typename: "SyncNylasPagesResponsePrismaProxy";
  success: boolean;
}

export interface SyncNylasPagesPrismaProxy {
  syncNylasPagesPrismaProxy: SyncNylasPagesPrismaProxy_syncNylasPagesPrismaProxy | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAvailabilityPrismaProxy
// ====================================================

export interface UpdateAvailabilityPrismaProxy_updateAvailabilityPrismaProxy_availability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface UpdateAvailabilityPrismaProxy_updateAvailabilityPrismaProxy {
  __typename: "UpdateAvailabilityResponsePrismaProxy";
  success: string;
  availability: UpdateAvailabilityPrismaProxy_updateAvailabilityPrismaProxy_availability | null;
}

export interface UpdateAvailabilityPrismaProxy {
  updateAvailabilityPrismaProxy: UpdateAvailabilityPrismaProxy_updateAvailabilityPrismaProxy;
}

export interface UpdateAvailabilityPrismaProxyVariables {
  input: UpdateAvailabilityInputPrismaProxy;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationLocations
// ====================================================

export interface GetOrganizationLocations_currentUserPrisma_currentOrganization_locations {
  __typename: "Location";
  id: string;
  name: string;
  arrivalInstructions: string;
  coordinates: string[];
  address: string;
  isArchived: boolean;
}

export interface GetOrganizationLocations_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  locations: GetOrganizationLocations_currentUserPrisma_currentOrganization_locations[];
}

export interface GetOrganizationLocations_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: GetOrganizationLocations_currentUserPrisma_currentOrganization | null;
}

export interface GetOrganizationLocations {
  currentUserPrisma: GetOrganizationLocations_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCompanySettingsTheme
// ====================================================

export interface UpdateCompanySettingsTheme_updateOrganizationTheme_organization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface UpdateCompanySettingsTheme_updateOrganizationTheme_organization {
  __typename: "Organization";
  id: string;
  theme: UpdateCompanySettingsTheme_updateOrganizationTheme_organization_theme | null;
}

export interface UpdateCompanySettingsTheme_updateOrganizationTheme {
  __typename: "UpdateOrganizationThemeMutationResponse";
  success: boolean;
  code: string;
  message: string;
  organization: UpdateCompanySettingsTheme_updateOrganizationTheme_organization | null;
}

export interface UpdateCompanySettingsTheme {
  updateOrganizationTheme: UpdateCompanySettingsTheme_updateOrganizationTheme;
}

export interface UpdateCompanySettingsThemeVariables {
  input: UpdateOrganizationThemeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCompanySettingsShowTrim
// ====================================================

export interface UpdateCompanySettingsShowTrim_updateOrganizationShowTrim_organization {
  __typename: "Organization";
  id: string;
  showTrim: boolean;
}

export interface UpdateCompanySettingsShowTrim_updateOrganizationShowTrim {
  __typename: "UpdateOrganizationShowTrimMutationResponse";
  success: boolean;
  code: string;
  message: string;
  organization: UpdateCompanySettingsShowTrim_updateOrganizationShowTrim_organization | null;
}

export interface UpdateCompanySettingsShowTrim {
  updateOrganizationShowTrim: UpdateCompanySettingsShowTrim_updateOrganizationShowTrim;
}

export interface UpdateCompanySettingsShowTrimVariables {
  input: UpdateOrganizationShowTrimInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCustomLogistic
// ====================================================

export interface CreateCustomLogistic_createCustomLogistic {
  __typename: "CreateCustomLogisticMutationResponse";
  success: boolean;
  code: string;
  message: string;
}

export interface CreateCustomLogistic {
  createCustomLogistic: CreateCustomLogistic_createCustomLogistic;
}

export interface CreateCustomLogisticVariables {
  input: CreateCustomLogisticMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCustomLogistic
// ====================================================

export interface UpdateCustomLogistic_updateCustomLogistic_logistic {
  __typename: "Logistic";
  id: string;
  emoji: string;
  title: string;
  description: string;
}

export interface UpdateCustomLogistic_updateCustomLogistic {
  __typename: "UpdateCustomLogisticMutationResponse";
  success: boolean;
  code: string;
  message: string;
  logistic: UpdateCustomLogistic_updateCustomLogistic_logistic | null;
}

export interface UpdateCustomLogistic {
  updateCustomLogistic: UpdateCustomLogistic_updateCustomLogistic;
}

export interface UpdateCustomLogisticVariables {
  input: UpdateCustomLogisticMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCustomLogistic
// ====================================================

export interface DeleteCustomLogistic_deleteCustomLogistic {
  __typename: "DeleteCustomLogisticMutationResponse";
  success: boolean;
  code: string;
  message: string;
}

export interface DeleteCustomLogistic {
  deleteCustomLogistic: DeleteCustomLogistic_deleteCustomLogistic;
}

export interface DeleteCustomLogisticVariables {
  input: DeleteCustomLogisticMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCompanySettingsLogo
// ====================================================

export interface UpdateCompanySettingsLogo_updateOrganizationLogo_organization {
  __typename: "Organization";
  id: string;
  companyLogoUrl: string;
}

export interface UpdateCompanySettingsLogo_updateOrganizationLogo {
  __typename: "UpdateOrganizationLogoMutationResponse";
  success: boolean;
  code: string;
  message: string;
  organization: UpdateCompanySettingsLogo_updateOrganizationLogo_organization | null;
}

export interface UpdateCompanySettingsLogo {
  updateOrganizationLogo: UpdateCompanySettingsLogo_updateOrganizationLogo;
}

export interface UpdateCompanySettingsLogoVariables {
  input: UpdateOrganizationLogoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCompanySettingsDefaultInterviewerImage
// ====================================================

export interface UpdateCompanySettingsDefaultInterviewerImage_updateOrganizationInterviewerImage_organization {
  __typename: "Organization";
  id: string;
  defaultAvatarImageUrl: string;
}

export interface UpdateCompanySettingsDefaultInterviewerImage_updateOrganizationInterviewerImage {
  __typename: "UpdateOrganizationInterviewerImageMutationResponse";
  success: boolean;
  code: string;
  message: string;
  organization: UpdateCompanySettingsDefaultInterviewerImage_updateOrganizationInterviewerImage_organization | null;
}

export interface UpdateCompanySettingsDefaultInterviewerImage {
  updateOrganizationInterviewerImage: UpdateCompanySettingsDefaultInterviewerImage_updateOrganizationInterviewerImage;
}

export interface UpdateCompanySettingsDefaultInterviewerImageVariables {
  input: UpdateOrganizationInterviewerImageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindLocationToEdit
// ====================================================

export interface FindLocationToEdit_currentOrganization_locationById {
  __typename: "Location";
  id: string;
  name: string;
  arrivalInstructions: string;
  address: string;
  coordinates: string[];
  isArchived: boolean;
}

export interface FindLocationToEdit_currentOrganization {
  __typename: "Organization";
  id: string;
  locationById: FindLocationToEdit_currentOrganization_locationById | null;
}

export interface FindLocationToEdit {
  currentOrganization: FindLocationToEdit_currentOrganization | null;
}

export interface FindLocationToEditVariables {
  input: LocationByIdInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateLocation
// ====================================================

export interface CreateLocation_createLocation {
  __typename: "CreateLocationMutationResponse";
  success: boolean;
  code: string;
  message: string;
}

export interface CreateLocation {
  createLocation: CreateLocation_createLocation;
}

export interface CreateLocationVariables {
  input: CreateLocationMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateLocation
// ====================================================

export interface UpdateLocation_updateLocation_location {
  __typename: "Location";
  id: string;
  name: string;
  arrivalInstructions: string;
  address: string;
  coordinates: string[];
  isArchived: boolean;
}

export interface UpdateLocation_updateLocation {
  __typename: "UpdateLocationMutationResponse";
  success: boolean;
  code: string;
  message: string;
  location: UpdateLocation_updateLocation_location | null;
}

export interface UpdateLocation {
  updateLocation: UpdateLocation_updateLocation;
}

export interface UpdateLocationVariables {
  input: UpdateLocationMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveLocation
// ====================================================

export interface ArchiveLocation_archiveLocation {
  __typename: "ArchiveLocationMutationResponse";
  success: boolean;
  code: string;
  message: string;
}

export interface ArchiveLocation {
  archiveLocation: ArchiveLocation_archiveLocation;
}

export interface ArchiveLocationVariables {
  input: ArchiveLocationMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnarchiveLocation
// ====================================================

export interface UnarchiveLocation_unarchiveLocation {
  __typename: "UnarchiveLocationMutationResponse";
  success: boolean;
  code: string;
  message: string;
}

export interface UnarchiveLocation {
  unarchiveLocation: UnarchiveLocation_unarchiveLocation;
}

export interface UnarchiveLocationVariables {
  input: UnarchiveLocationMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCompanySettingsMissionStatement
// ====================================================

export interface UpdateCompanySettingsMissionStatement_updateOrganizationMissionStatement_organization {
  __typename: "Organization";
  id: string;
  missionStatement: string;
}

export interface UpdateCompanySettingsMissionStatement_updateOrganizationMissionStatement {
  __typename: "UpdateOrganizationMissionStatementMutationResponse";
  success: boolean;
  code: string;
  message: string;
  organization: UpdateCompanySettingsMissionStatement_updateOrganizationMissionStatement_organization | null;
}

export interface UpdateCompanySettingsMissionStatement {
  updateOrganizationMissionStatement: UpdateCompanySettingsMissionStatement_updateOrganizationMissionStatement;
}

export interface UpdateCompanySettingsMissionStatementVariables {
  input: UpdateOrganizationMissionStatementInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateValue
// ====================================================

export interface CreateValue_createValue {
  __typename: "CreateValueMutationResponse";
  success: boolean;
  code: string;
  message: string;
}

export interface CreateValue {
  createValue: CreateValue_createValue;
}

export interface CreateValueVariables {
  input: CreateValueMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateValue
// ====================================================

export interface UpdateValue_updateValue_value {
  __typename: "Value";
  id: GraphQL_UUID;
  title: string;
  description: string;
}

export interface UpdateValue_updateValue {
  __typename: "UpdateValueMutationResponse";
  success: boolean;
  code: string;
  message: string;
  value: UpdateValue_updateValue_value | null;
}

export interface UpdateValue {
  updateValue: UpdateValue_updateValue;
}

export interface UpdateValueVariables {
  input: UpdateValueMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteValue
// ====================================================

export interface DeleteValue_deleteValue {
  __typename: "DeleteValueMutationResponse";
  success: boolean;
  code: string;
  message: string;
}

export interface DeleteValue {
  deleteValue: DeleteValue_deleteValue;
}

export interface DeleteValueVariables {
  input: DeleteValueMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCurrentUserForSettingsPrisma
// ====================================================

export interface GetCurrentUserForSettingsPrisma_currentOrganization_customer_atsIntegration {
  __typename: "AtsIntegration";
  id: string;
}

export interface GetCurrentUserForSettingsPrisma_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  atsIntegration: GetCurrentUserForSettingsPrisma_currentOrganization_customer_atsIntegration | null;
}

export interface GetCurrentUserForSettingsPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  customer: GetCurrentUserForSettingsPrisma_currentOrganization_customer;
  prospectOnboardingComplete: boolean;
}

export interface GetCurrentUserForSettingsPrisma_currentUserPrisma_currentUserMembership {
  __typename: "UserMembership";
  id: string;
  hasLimitedAccess: boolean;
}

export interface GetCurrentUserForSettingsPrisma_currentUserPrisma {
  __typename: "User";
  id: string;
  currentUserMembership: GetCurrentUserForSettingsPrisma_currentUserPrisma_currentUserMembership | null;
}

export interface GetCurrentUserForSettingsPrisma {
  currentOrganization: GetCurrentUserForSettingsPrisma_currentOrganization | null;
  currentUserPrisma: GetCurrentUserForSettingsPrisma_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DisconnectSlackIntegrationForCustomerPrisma
// ====================================================

export interface DisconnectSlackIntegrationForCustomerPrisma_disconnectSlack_customer_slackIntegration {
  __typename: "SlackIntegration";
  id: string;
  type: IntegrationEnum;
  connected: boolean;
  auth0SlackUserId: string | null;
  autopopulateInterviewer: boolean | null;
  needsReauthentication: boolean | null;
  notificationsChannelId: string | null;
  receiveNotifications: boolean | null;
}

export interface DisconnectSlackIntegrationForCustomerPrisma_disconnectSlack_customer {
  __typename: "Customer";
  id: string;
  slackIntegration: DisconnectSlackIntegrationForCustomerPrisma_disconnectSlack_customer_slackIntegration;
}

export interface DisconnectSlackIntegrationForCustomerPrisma_disconnectSlack {
  __typename: "DisconnectSlackMutationResponse";
  success: boolean;
  message: string;
  code: string;
  customer: DisconnectSlackIntegrationForCustomerPrisma_disconnectSlack_customer | null;
}

export interface DisconnectSlackIntegrationForCustomerPrisma {
  disconnectSlack: DisconnectSlackIntegrationForCustomerPrisma_disconnectSlack;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SyncSlackIntegrationForCustomerPrisma
// ====================================================

export interface SyncSlackIntegrationForCustomerPrisma_syncSlack_customer_slackIntegration {
  __typename: "SlackIntegration";
  id: string;
  type: IntegrationEnum;
  connected: boolean;
  auth0SlackUserId: string | null;
  autopopulateInterviewer: boolean | null;
  notificationsChannelId: string | null;
  receiveNotifications: boolean | null;
  needsReauthentication: boolean | null;
}

export interface SyncSlackIntegrationForCustomerPrisma_syncSlack_customer {
  __typename: "Customer";
  id: string;
  slackIntegration: SyncSlackIntegrationForCustomerPrisma_syncSlack_customer_slackIntegration;
}

export interface SyncSlackIntegrationForCustomerPrisma_syncSlack {
  __typename: "SyncSlackMutationResponse";
  success: boolean;
  message: string;
  customer: SyncSlackIntegrationForCustomerPrisma_syncSlack_customer | null;
}

export interface SyncSlackIntegrationForCustomerPrisma {
  syncSlack: SyncSlackIntegrationForCustomerPrisma_syncSlack;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SlackIntegrationForCustomerPrisma
// ====================================================

export interface SlackIntegrationForCustomerPrisma_currentUserV2_organization_customer_integration {
  __typename: "SlackIntegration";
  id: string;
  type: IntegrationEnum;
  connected: boolean;
  auth0SlackUserId: string | null;
  notificationsChannelId: string | null;
  autopopulateInterviewer: boolean | null;
  receiveNotifications: boolean | null;
  needsReauthentication: boolean | null;
}

export interface SlackIntegrationForCustomerPrisma_currentUserV2_organization_customer {
  __typename: "Customer";
  id: string;
  integration: SlackIntegrationForCustomerPrisma_currentUserV2_organization_customer_integration;
}

export interface SlackIntegrationForCustomerPrisma_currentUserV2_organization {
  __typename: "Organization";
  id: string;
  customer: SlackIntegrationForCustomerPrisma_currentUserV2_organization_customer;
}

export interface SlackIntegrationForCustomerPrisma_currentUserV2 {
  __typename: "User";
  id: string;
  organization: SlackIntegrationForCustomerPrisma_currentUserV2_organization | null;
}

export interface SlackIntegrationForCustomerPrisma {
  currentUserV2: SlackIntegrationForCustomerPrisma_currentUserV2 | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSlackIntegrationForCustomerPrisma
// ====================================================

export interface UpdateSlackIntegrationForCustomerPrisma_updateSlack_customer_slackIntegration {
  __typename: "SlackIntegration";
  id: string;
  type: IntegrationEnum;
  connected: boolean;
  auth0SlackUserId: string | null;
  autopopulateInterviewer: boolean | null;
  needsReauthentication: boolean | null;
  notificationsChannelId: string | null;
  receiveNotifications: boolean | null;
}

export interface UpdateSlackIntegrationForCustomerPrisma_updateSlack_customer {
  __typename: "Customer";
  id: string;
  slackIntegration: UpdateSlackIntegrationForCustomerPrisma_updateSlack_customer_slackIntegration;
}

export interface UpdateSlackIntegrationForCustomerPrisma_updateSlack {
  __typename: "UpdateSlackMutationResponse";
  success: boolean;
  message: string;
  code: string;
  customer: UpdateSlackIntegrationForCustomerPrisma_updateSlack_customer | null;
}

export interface UpdateSlackIntegrationForCustomerPrisma {
  updateSlack: UpdateSlackIntegrationForCustomerPrisma_updateSlack;
}

export interface UpdateSlackIntegrationForCustomerPrismaVariables {
  input: UpdateSlackInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRichTextInstallationForStageTemplatePrisma
// ====================================================

export interface UpdateRichTextInstallationForStageTemplatePrisma_updateRichTextStageTemplateModuleInstallation_stageTemplateModuleInstallation {
  __typename: "StageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
  type: string;
  position: number;
}

export interface UpdateRichTextInstallationForStageTemplatePrisma_updateRichTextStageTemplateModuleInstallation {
  __typename: "UpdateRichTextStageTemplateModuleInstallationMutationResponse";
  success: boolean;
  stageTemplateModuleInstallation: UpdateRichTextInstallationForStageTemplatePrisma_updateRichTextStageTemplateModuleInstallation_stageTemplateModuleInstallation | null;
}

export interface UpdateRichTextInstallationForStageTemplatePrisma {
  updateRichTextStageTemplateModuleInstallation: UpdateRichTextInstallationForStageTemplatePrisma_updateRichTextStageTemplateModuleInstallation;
}

export interface UpdateRichTextInstallationForStageTemplatePrismaVariables {
  input: UpdateRichTextStageTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAssessmentInstallationForStageTemplatePrisma
// ====================================================

export interface UpdateAssessmentInstallationForStageTemplatePrisma_updateAssessmentStageTemplateModuleInstallation_stageTemplateModuleInstallation {
  __typename: "StageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
  type: string;
  position: number;
}

export interface UpdateAssessmentInstallationForStageTemplatePrisma_updateAssessmentStageTemplateModuleInstallation {
  __typename: "UpdateAssessmentStageTemplateModuleInstallationMutationResponse";
  success: boolean;
  stageTemplateModuleInstallation: UpdateAssessmentInstallationForStageTemplatePrisma_updateAssessmentStageTemplateModuleInstallation_stageTemplateModuleInstallation | null;
}

export interface UpdateAssessmentInstallationForStageTemplatePrisma {
  updateAssessmentStageTemplateModuleInstallation: UpdateAssessmentInstallationForStageTemplatePrisma_updateAssessmentStageTemplateModuleInstallation;
}

export interface UpdateAssessmentInstallationForStageTemplatePrismaVariables {
  input: UpdateAssessmentStageTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOfferInstallationForStageTemplatePrisma
// ====================================================

export interface UpdateOfferInstallationForStageTemplatePrisma_updateOfferStageTemplateModuleInstallation_stageTemplateModuleInstallation {
  __typename: "StageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
  type: string;
  position: number;
}

export interface UpdateOfferInstallationForStageTemplatePrisma_updateOfferStageTemplateModuleInstallation {
  __typename: "UpdateOfferStageTemplateModuleInstallationMutationResponse";
  success: boolean;
  stageTemplateModuleInstallation: UpdateOfferInstallationForStageTemplatePrisma_updateOfferStageTemplateModuleInstallation_stageTemplateModuleInstallation | null;
}

export interface UpdateOfferInstallationForStageTemplatePrisma {
  updateOfferStageTemplateModuleInstallation: UpdateOfferInstallationForStageTemplatePrisma_updateOfferStageTemplateModuleInstallation;
}

export interface UpdateOfferInstallationForStageTemplatePrismaVariables {
  input: UpdateOfferStageTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTeamPhotoInstallationForStageTemplatePrisma
// ====================================================

export interface UpdateTeamPhotoInstallationForStageTemplatePrisma_updateTeamPhotoStageTemplateModuleInstallation_stageTemplateModuleInstallation {
  __typename: "StageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
  type: string;
  position: number;
}

export interface UpdateTeamPhotoInstallationForStageTemplatePrisma_updateTeamPhotoStageTemplateModuleInstallation {
  __typename: "UpdateTeamPhotoStageTemplateModuleInstallationMutationResponse";
  code: string;
  message: string;
  success: boolean;
  stageTemplateModuleInstallation: UpdateTeamPhotoInstallationForStageTemplatePrisma_updateTeamPhotoStageTemplateModuleInstallation_stageTemplateModuleInstallation | null;
}

export interface UpdateTeamPhotoInstallationForStageTemplatePrisma {
  updateTeamPhotoStageTemplateModuleInstallation: UpdateTeamPhotoInstallationForStageTemplatePrisma_updateTeamPhotoStageTemplateModuleInstallation;
}

export interface UpdateTeamPhotoInstallationForStageTemplatePrismaVariables {
  input: UpdateTeamPhotoStageTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateInterviewTeamInstallationForStageTemplatePrisma
// ====================================================

export interface UpdateInterviewTeamInstallationForStageTemplatePrisma_updateInterviewTeamStageTemplateModuleInstallation_stageTemplateModuleInstallation {
  __typename: "StageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
  type: string;
  position: number;
}

export interface UpdateInterviewTeamInstallationForStageTemplatePrisma_updateInterviewTeamStageTemplateModuleInstallation {
  __typename: "UpdateInterviewTeamStageTemplateModuleInstallationMutationResponse";
  success: boolean;
  stageTemplateModuleInstallation: UpdateInterviewTeamInstallationForStageTemplatePrisma_updateInterviewTeamStageTemplateModuleInstallation_stageTemplateModuleInstallation | null;
}

export interface UpdateInterviewTeamInstallationForStageTemplatePrisma {
  updateInterviewTeamStageTemplateModuleInstallation: UpdateInterviewTeamInstallationForStageTemplatePrisma_updateInterviewTeamStageTemplateModuleInstallation;
}

export interface UpdateInterviewTeamInstallationForStageTemplatePrismaVariables {
  input: UpdateInterviewTeamStageTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateLinksInstallationForStageTemplatePrisma
// ====================================================

export interface UpdateLinksInstallationForStageTemplatePrisma_updateLinksStageTemplateModuleInstallation_stageTemplateModuleInstallation {
  __typename: "StageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
  type: string;
  position: number;
}

export interface UpdateLinksInstallationForStageTemplatePrisma_updateLinksStageTemplateModuleInstallation {
  __typename: "UpdateLinksStageTemplateModuleInstallationMutationResponse";
  success: boolean;
  stageTemplateModuleInstallation: UpdateLinksInstallationForStageTemplatePrisma_updateLinksStageTemplateModuleInstallation_stageTemplateModuleInstallation | null;
}

export interface UpdateLinksInstallationForStageTemplatePrisma {
  updateLinksStageTemplateModuleInstallation: UpdateLinksInstallationForStageTemplatePrisma_updateLinksStageTemplateModuleInstallation;
}

export interface UpdateLinksInstallationForStageTemplatePrismaVariables {
  input: UpdateLinksStageTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateLocationInstallationForStageTemplatePrisma
// ====================================================

export interface UpdateLocationInstallationForStageTemplatePrisma_updateLocationStageTemplateModuleInstallation_stageTemplateModuleInstallation {
  __typename: "StageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
  type: string;
  position: number;
}

export interface UpdateLocationInstallationForStageTemplatePrisma_updateLocationStageTemplateModuleInstallation {
  __typename: "UpdateLocationStageTemplateModuleInstallationMutationResponse";
  success: boolean;
  stageTemplateModuleInstallation: UpdateLocationInstallationForStageTemplatePrisma_updateLocationStageTemplateModuleInstallation_stageTemplateModuleInstallation | null;
}

export interface UpdateLocationInstallationForStageTemplatePrisma {
  updateLocationStageTemplateModuleInstallation: UpdateLocationInstallationForStageTemplatePrisma_updateLocationStageTemplateModuleInstallation;
}

export interface UpdateLocationInstallationForStageTemplatePrismaVariables {
  input: UpdateLocationStageTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateLogisticsInstallationForStageTemplatePrisma
// ====================================================

export interface UpdateLogisticsInstallationForStageTemplatePrisma_updateLogisticsStageTemplateModuleInstallation_stageTemplateModuleInstallation {
  __typename: "StageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
  type: string;
  position: number;
}

export interface UpdateLogisticsInstallationForStageTemplatePrisma_updateLogisticsStageTemplateModuleInstallation {
  __typename: "UpdateLogisticsStageTemplateModuleInstallationMutationResponse";
  success: boolean;
  stageTemplateModuleInstallation: UpdateLogisticsInstallationForStageTemplatePrisma_updateLogisticsStageTemplateModuleInstallation_stageTemplateModuleInstallation | null;
}

export interface UpdateLogisticsInstallationForStageTemplatePrisma {
  updateLogisticsStageTemplateModuleInstallation: UpdateLogisticsInstallationForStageTemplatePrisma_updateLogisticsStageTemplateModuleInstallation;
}

export interface UpdateLogisticsInstallationForStageTemplatePrismaVariables {
  input: UpdateLogisticsStageTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMediaInstallationForStageTemplatePrisma
// ====================================================

export interface UpdateMediaInstallationForStageTemplatePrisma_updateMediaStageTemplateModuleInstallation_stageTemplateModuleInstallation {
  __typename: "StageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
  type: string;
  position: number;
}

export interface UpdateMediaInstallationForStageTemplatePrisma_updateMediaStageTemplateModuleInstallation {
  __typename: "UpdateMediaStageTemplateModuleInstallationMutationResponse";
  success: boolean;
  stageTemplateModuleInstallation: UpdateMediaInstallationForStageTemplatePrisma_updateMediaStageTemplateModuleInstallation_stageTemplateModuleInstallation | null;
}

export interface UpdateMediaInstallationForStageTemplatePrisma {
  updateMediaStageTemplateModuleInstallation: UpdateMediaInstallationForStageTemplatePrisma_updateMediaStageTemplateModuleInstallation;
}

export interface UpdateMediaInstallationForStageTemplatePrismaVariables {
  input: UpdateMediaStageTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateScheduleInstallationForStageTemplatePrisma
// ====================================================

export interface UpdateScheduleInstallationForStageTemplatePrisma_updateScheduleStageTemplateModuleInstallation_stageTemplateModuleInstallation {
  __typename: "StageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
  type: string;
  position: number;
}

export interface UpdateScheduleInstallationForStageTemplatePrisma_updateScheduleStageTemplateModuleInstallation {
  __typename: "UpdateScheduleStageTemplateModuleInstallationMutationResponse";
  success: boolean;
  stageTemplateModuleInstallation: UpdateScheduleInstallationForStageTemplatePrisma_updateScheduleStageTemplateModuleInstallation_stageTemplateModuleInstallation | null;
}

export interface UpdateScheduleInstallationForStageTemplatePrisma {
  updateScheduleStageTemplateModuleInstallation: UpdateScheduleInstallationForStageTemplatePrisma_updateScheduleStageTemplateModuleInstallation;
}

export interface UpdateScheduleInstallationForStageTemplatePrismaVariables {
  input: UpdateScheduleStageTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTimeInstallationForStageTemplatePrisma
// ====================================================

export interface UpdateTimeInstallationForStageTemplatePrisma_updateTimeStageTemplateModuleInstallation_stageTemplateModuleInstallation {
  __typename: "StageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
  type: string;
  position: number;
}

export interface UpdateTimeInstallationForStageTemplatePrisma_updateTimeStageTemplateModuleInstallation {
  __typename: "UpdateTimeStageTemplateModuleInstallationMutationResponse";
  success: boolean;
  stageTemplateModuleInstallation: UpdateTimeInstallationForStageTemplatePrisma_updateTimeStageTemplateModuleInstallation_stageTemplateModuleInstallation | null;
}

export interface UpdateTimeInstallationForStageTemplatePrisma {
  updateTimeStageTemplateModuleInstallation: UpdateTimeInstallationForStageTemplatePrisma_updateTimeStageTemplateModuleInstallation;
}

export interface UpdateTimeInstallationForStageTemplatePrismaVariables {
  input: UpdateTimeStageTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateValuesInstallationForStageTemplatePrisma
// ====================================================

export interface UpdateValuesInstallationForStageTemplatePrisma_updateValuesStageTemplateModuleInstallation_stageTemplateModuleInstallation {
  __typename: "StageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
  type: string;
  position: number;
}

export interface UpdateValuesInstallationForStageTemplatePrisma_updateValuesStageTemplateModuleInstallation {
  __typename: "UpdateValuesStageTemplateModuleInstallationMutationResponse";
  success: boolean;
  stageTemplateModuleInstallation: UpdateValuesInstallationForStageTemplatePrisma_updateValuesStageTemplateModuleInstallation_stageTemplateModuleInstallation | null;
}

export interface UpdateValuesInstallationForStageTemplatePrisma {
  updateValuesStageTemplateModuleInstallation: UpdateValuesInstallationForStageTemplatePrisma_updateValuesStageTemplateModuleInstallation;
}

export interface UpdateValuesInstallationForStageTemplatePrismaVariables {
  input: UpdateValuesStageTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMissionInstallationForStageTemplatePrisma
// ====================================================

export interface UpdateMissionInstallationForStageTemplatePrisma_updateMissionStageTemplateModuleInstallation_stageTemplateModuleInstallation {
  __typename: "StageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
  type: string;
  position: number;
}

export interface UpdateMissionInstallationForStageTemplatePrisma_updateMissionStageTemplateModuleInstallation {
  __typename: "UpdateMissionStageTemplateModuleInstallationMutationResponse";
  success: boolean;
  stageTemplateModuleInstallation: UpdateMissionInstallationForStageTemplatePrisma_updateMissionStageTemplateModuleInstallation_stageTemplateModuleInstallation | null;
}

export interface UpdateMissionInstallationForStageTemplatePrisma {
  updateMissionStageTemplateModuleInstallation: UpdateMissionInstallationForStageTemplatePrisma_updateMissionStageTemplateModuleInstallation;
}

export interface UpdateMissionInstallationForStageTemplatePrismaVariables {
  input: UpdateMissionStageTemplateModuleInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentUserForEmailTemplate
// ====================================================

export interface CurrentUserForEmailTemplate_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  customVariableOptions: string[];
}

export interface CurrentUserForEmailTemplate_currentUserPrisma {
  __typename: "User";
  id: string;
  name: string;
  streamToken: string | null;
  photo: string;
  currentOrganization: CurrentUserForEmailTemplate_currentUserPrisma_currentOrganization | null;
}

export interface CurrentUserForEmailTemplate {
  currentUserPrisma: CurrentUserForEmailTemplate_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEmailTemplateForEmailTemplateModal
// ====================================================

export interface GetEmailTemplateForEmailTemplateModal_currentOrganization_emailTemplateById {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface GetEmailTemplateForEmailTemplateModal_currentOrganization {
  __typename: "Organization";
  id: string;
  emailTemplateById: GetEmailTemplateForEmailTemplateModal_currentOrganization_emailTemplateById;
}

export interface GetEmailTemplateForEmailTemplateModal {
  currentOrganization: GetEmailTemplateForEmailTemplateModal_currentOrganization | null;
}

export interface GetEmailTemplateForEmailTemplateModalVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEmailTemplate
// ====================================================

export interface UpdateEmailTemplate_updateEmailTemplate_emailTemplate_creator {
  __typename: "User";
  id: string;
}

export interface UpdateEmailTemplate_updateEmailTemplate_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
  updatedAt: GraphQL_DateTime;
  creator: UpdateEmailTemplate_updateEmailTemplate_emailTemplate_creator | null;
}

export interface UpdateEmailTemplate_updateEmailTemplate {
  __typename: "UpdateEmailTemplateResponse";
  emailTemplate: UpdateEmailTemplate_updateEmailTemplate_emailTemplate | null;
}

export interface UpdateEmailTemplate {
  updateEmailTemplate: UpdateEmailTemplate_updateEmailTemplate;
}

export interface UpdateEmailTemplateVariables {
  input: UpdateEmailTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateEmailTemplate
// ====================================================

export interface CreateEmailTemplate_createEmailTemplate_emailTemplate_creator {
  __typename: "User";
  id: string;
}

export interface CreateEmailTemplate_createEmailTemplate_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  updatedAt: GraphQL_DateTime;
  creator: CreateEmailTemplate_createEmailTemplate_emailTemplate_creator | null;
}

export interface CreateEmailTemplate_createEmailTemplate {
  __typename: "CreateEmailTemplateResponse";
  emailTemplate: CreateEmailTemplate_createEmailTemplate_emailTemplate | null;
}

export interface CreateEmailTemplate {
  createEmailTemplate: CreateEmailTemplate_createEmailTemplate;
}

export interface CreateEmailTemplateVariables {
  input: CreateEmailTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchDepartments
// ====================================================

export interface FetchDepartments_atsDepartments {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface FetchDepartments {
  atsDepartments: FetchDepartments_atsDepartments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewersForMessageSender
// ====================================================

export interface InterviewersForMessageSender_currentOrganization_senders_userMembership {
  __typename: "UserMembership";
  id: string;
}

export interface InterviewersForMessageSender_currentOrganization_senders {
  __typename: "Interviewer";
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string | null;
  imageUrl: string;
  userMembership: InterviewersForMessageSender_currentOrganization_senders_userMembership | null;
}

export interface InterviewersForMessageSender_currentOrganization {
  __typename: "Organization";
  id: string;
  senders: InterviewersForMessageSender_currentOrganization_senders[];
}

export interface InterviewersForMessageSender {
  currentOrganization: InterviewersForMessageSender_currentOrganization | null;
}

export interface InterviewersForMessageSenderVariables {
  searchTerm: string;
  limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PostMessageFetchGuide
// ====================================================

export interface PostMessageFetchGuide_guideLookup_currentStage {
  __typename: "Stage";
  id: string;
}

export interface PostMessageFetchGuide_guideLookup_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface PostMessageFetchGuide_guideLookup_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface PostMessageFetchGuide_guideLookup_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: PostMessageFetchGuide_guideLookup_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: PostMessageFetchGuide_guideLookup_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface PostMessageFetchGuide_guideLookup_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  postTemplateSyncData: PostMessageFetchGuide_guideLookup_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData | null;
}

export interface PostMessageFetchGuide_guideLookup_sortedVisibleStages_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  postTemplates: PostMessageFetchGuide_guideLookup_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates[];
}

export interface PostMessageFetchGuide_guideLookup_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  title: string;
  position: number;
  jobRoleGuideTemplateStage: PostMessageFetchGuide_guideLookup_sortedVisibleStages_jobRoleGuideTemplateStage | null;
}

export interface PostMessageFetchGuide_guideLookup_guideLexicalContext_recruiterName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface PostMessageFetchGuide_guideLookup_guideLexicalContext_candidateName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface PostMessageFetchGuide_guideLookup_guideLexicalContext_coordinatorName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface PostMessageFetchGuide_guideLookup_guideLexicalContext {
  __typename: "GuideLexicalContext";
  id: GraphQL_UUID;
  href: string;
  recruiterName: PostMessageFetchGuide_guideLookup_guideLexicalContext_recruiterName;
  candidateName: PostMessageFetchGuide_guideLookup_guideLexicalContext_candidateName;
  candidatePhone: string | null;
  coordinatorName: PostMessageFetchGuide_guideLookup_guideLexicalContext_coordinatorName | null;
  jobTitle: string;
  jobListingUrl: string | null;
  companyName: string;
}

export interface PostMessageFetchGuide_guideLookup_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
  companyLogoUrl: string;
  defaultAvatarImageUrl: string;
  enableICSFiles: boolean;
}

export interface PostMessageFetchGuide_guideLookup_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  email: string;
  imageUrl: string;
  emailAddresses: string[];
  timezone: string | null;
  organization: PostMessageFetchGuide_guideLookup_candidate_organization;
}

export interface PostMessageFetchGuide_guideLookup {
  __typename: "Guide";
  id: string;
  statusV2: GuideStatusV2Enum;
  currentStage: PostMessageFetchGuide_guideLookup_currentStage | null;
  sortedVisibleStages: PostMessageFetchGuide_guideLookup_sortedVisibleStages[];
  guideLexicalContext: PostMessageFetchGuide_guideLookup_guideLexicalContext;
  candidate: PostMessageFetchGuide_guideLookup_candidate;
}

export interface PostMessageFetchGuide {
  guideLookup: PostMessageFetchGuide_guideLookup | null;
}

export interface PostMessageFetchGuideVariables {
  where: GuideLookupWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PostMessageThread
// ====================================================

export interface PostMessageThread_guidePostById_recipientProfiles {
  __typename: "GuidePostUser";
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  isCandidate: boolean;
}

export interface PostMessageThread_guidePostById {
  __typename: "GuidePost";
  id: string;
  title: string;
  data: GraphQL_JSON | null;
  recipientProfiles: PostMessageThread_guidePostById_recipientProfiles[];
}

export interface PostMessageThread {
  guidePostById: PostMessageThread_guidePostById | null;
}

export interface PostMessageThreadVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddPostTemplateToJobRoleGuideTemplateStage
// ====================================================

export interface AddPostTemplateToJobRoleGuideTemplateStage_addPostTemplateToJobRoleGuideTemplateStage_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
}

export interface AddPostTemplateToJobRoleGuideTemplateStage_addPostTemplateToJobRoleGuideTemplateStage {
  __typename: "AddPostTemplateToJobRoleGuideTemplateStageResponse";
  message: string;
  success: boolean;
  code: string;
  jobRoleGuideTemplateStage: AddPostTemplateToJobRoleGuideTemplateStage_addPostTemplateToJobRoleGuideTemplateStage_jobRoleGuideTemplateStage;
}

export interface AddPostTemplateToJobRoleGuideTemplateStage {
  addPostTemplateToJobRoleGuideTemplateStage: AddPostTemplateToJobRoleGuideTemplateStage_addPostTemplateToJobRoleGuideTemplateStage;
}

export interface AddPostTemplateToJobRoleGuideTemplateStageVariables {
  input: AddPostTemplateToJobRoleGuideTemplateStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendTestMessageEmail
// ====================================================

export interface SendTestMessageEmail_sendGuidePostTestEmail {
  __typename: "SendGuidePostTestEmailResponse";
  success: boolean;
  code: string;
  message: string;
}

export interface SendTestMessageEmail {
  sendGuidePostTestEmail: SendTestMessageEmail_sendGuidePostTestEmail;
}

export interface SendTestMessageEmailVariables {
  input: SendGuidePostTestEmailInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PostMessage
// ====================================================

export interface PostMessage_createGuidePost_guidePost_parent_replies {
  __typename: "GuidePost";
  id: string;
}

export interface PostMessage_createGuidePost_guidePost_parent {
  __typename: "GuidePost";
  id: string;
  replies: PostMessage_createGuidePost_guidePost_parent_replies[];
}

export interface PostMessage_createGuidePost_guidePost_guide_latestPost {
  __typename: "GuidePost";
  id: string;
}

export interface PostMessage_createGuidePost_guidePost_guide_candidate {
  __typename: "Candidate";
  id: string;
  timezone: string | null;
}

export interface PostMessage_createGuidePost_guidePost_guide {
  __typename: "Guide";
  id: string;
  latestPost: PostMessage_createGuidePost_guidePost_guide_latestPost | null;
  candidate: PostMessage_createGuidePost_guidePost_guide_candidate;
}

export interface PostMessage_createGuidePost_guidePost {
  __typename: "GuidePost";
  id: string;
  title: string;
  data: GraphQL_JSON | null;
  parent: PostMessage_createGuidePost_guidePost_parent | null;
  guide: PostMessage_createGuidePost_guidePost_guide;
}

export interface PostMessage_createGuidePost {
  __typename: "CreateGuidePostResponse";
  success: boolean;
  code: string;
  message: string;
  guidePost: PostMessage_createGuidePost_guidePost | null;
}

export interface PostMessage {
  createGuidePost: PostMessage_createGuidePost;
}

export interface PostMessageVariables {
  input: CreateGuidePostInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePostTemplateSubject
// ====================================================

export interface UpdatePostTemplateSubject_upsertPostTemplate_postTemplate {
  __typename: "PostTemplate";
  id: string;
  title: string | null;
}

export interface UpdatePostTemplateSubject_upsertPostTemplate {
  __typename: "UpsertPostTemplateResponse";
  message: string;
  success: boolean;
  postTemplate: UpdatePostTemplateSubject_upsertPostTemplate_postTemplate;
}

export interface UpdatePostTemplateSubject {
  upsertPostTemplate: UpdatePostTemplateSubject_upsertPostTemplate;
}

export interface UpdatePostTemplateSubjectVariables {
  input: UpsertPostTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PostTemplatesForSelect
// ====================================================

export interface PostTemplatesForSelect_currentOrganization_paginatedPostTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
}

export interface PostTemplatesForSelect_currentOrganization_paginatedPostTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  postTemplateSyncData: PostTemplatesForSelect_currentOrganization_paginatedPostTemplates_postTemplateSyncData | null;
}

export interface PostTemplatesForSelect_currentOrganization {
  __typename: "Organization";
  id: string;
  paginatedPostTemplates: PostTemplatesForSelect_currentOrganization_paginatedPostTemplates[];
  paginatedPostTemplatesCount: number | null;
}

export interface PostTemplatesForSelect {
  currentOrganization: PostTemplatesForSelect_currentOrganization | null;
}

export interface PostTemplatesForSelectVariables {
  where?: OrganizationPostTemplatesWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MetabaseDashboard
// ====================================================

export interface MetabaseDashboard_currentUserPrisma_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  metabaseDashboardUrl: string | null;
}

export interface MetabaseDashboard_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  customer: MetabaseDashboard_currentUserPrisma_currentOrganization_customer;
}

export interface MetabaseDashboard_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: MetabaseDashboard_currentUserPrisma_currentOrganization | null;
}

export interface MetabaseDashboard {
  currentUserPrisma: MetabaseDashboard_currentUserPrisma | null;
}

export interface MetabaseDashboardVariables {
  input: MetabaseDashboardInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProfileForOnboarding
// ====================================================

export interface GetProfileForOnboarding_currentUserPrisma_currentUserMembership_personalProfile {
  __typename: "Interviewer";
  id: string;
  email: string | null;
  name: string;
  title: string;
  displayTitle: string;
  biography: string;
  pronoun: string | null;
  websiteUrls: string[];
  imageUrl: string;
  videoIntroductionUrl: string | null;
}

export interface GetProfileForOnboarding_currentUserPrisma_currentUserMembership {
  __typename: "UserMembership";
  id: string;
  personalProfile: GetProfileForOnboarding_currentUserPrisma_currentUserMembership_personalProfile;
}

export interface GetProfileForOnboarding_currentUserPrisma {
  __typename: "User";
  id: string;
  currentUserMembership: GetProfileForOnboarding_currentUserPrisma_currentUserMembership | null;
}

export interface GetProfileForOnboarding {
  currentUserPrisma: GetProfileForOnboarding_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailableOrganizations
// ====================================================

export interface AvailableOrganizations_currentUserPrisma_availableOrganizations {
  __typename: "Organization";
  id: string;
  name: string;
  defaultAvatarImageUrl: string;
}

export interface AvailableOrganizations_currentUserPrisma {
  __typename: "User";
  id: string;
  availableOrganizations: AvailableOrganizations_currentUserPrisma_availableOrganizations[];
}

export interface AvailableOrganizations_teammates_personalProfile_userMembership {
  __typename: "UserMembership";
  id: string;
}

export interface AvailableOrganizations_teammates_personalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
  displayImageUrl: string;
  userMembership: AvailableOrganizations_teammates_personalProfile_userMembership | null;
}

export interface AvailableOrganizations_teammates {
  __typename: "Teammate";
  id: string;
  personalProfile: AvailableOrganizations_teammates_personalProfile | null;
}

export interface AvailableOrganizations {
  currentUserPrisma: AvailableOrganizations_currentUserPrisma | null;
  teammates: AvailableOrganizations_teammates[];
}

export interface AvailableOrganizationsVariables {
  teammatesSearch?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestProfileUpdateExtensionMenu
// ====================================================

export interface RequestProfileUpdateExtensionMenu_requestProfileUpdate_personalProfile_userMembership {
  __typename: "UserMembership";
  id: string;
}

export interface RequestProfileUpdateExtensionMenu_requestProfileUpdate_personalProfile {
  __typename: "Interviewer";
  id: string;
  lastRequestedUserUpdateAt: GraphQL_DateTime | null;
  lastUserUpdatedAt: GraphQL_DateTime | null;
  userMembership: RequestProfileUpdateExtensionMenu_requestProfileUpdate_personalProfile_userMembership | null;
}

export interface RequestProfileUpdateExtensionMenu_requestProfileUpdate {
  __typename: "RequestProfileUpdateMutationResponse";
  success: boolean;
  message: string;
  code: string;
  personalProfile: RequestProfileUpdateExtensionMenu_requestProfileUpdate_personalProfile | null;
}

export interface RequestProfileUpdateExtensionMenu {
  requestProfileUpdate: RequestProfileUpdateExtensionMenu_requestProfileUpdate;
}

export interface RequestProfileUpdateExtensionMenuVariables {
  input: RequestProfileUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShowEventInterviewer
// ====================================================

export interface ShowEventInterviewer_showEventInterviewer_eventInterviewer_personalProfile_userMembership {
  __typename: "UserMembership";
  id: string;
}

export interface ShowEventInterviewer_showEventInterviewer_eventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  lastRequestedUserUpdateAt: GraphQL_DateTime | null;
  lastUserUpdatedAt: GraphQL_DateTime | null;
  userMembership: ShowEventInterviewer_showEventInterviewer_eventInterviewer_personalProfile_userMembership | null;
}

export interface ShowEventInterviewer_showEventInterviewer_eventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
  personalProfile: ShowEventInterviewer_showEventInterviewer_eventInterviewer_personalProfile;
}

export interface ShowEventInterviewer_showEventInterviewer {
  __typename: "ShowEventInterviewerMutationResponse";
  success: boolean;
  message: string;
  eventInterviewer: ShowEventInterviewer_showEventInterviewer_eventInterviewer | null;
}

export interface ShowEventInterviewer {
  showEventInterviewer: ShowEventInterviewer_showEventInterviewer;
}

export interface ShowEventInterviewerVariables {
  input: ShowEventInterviewerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: HideEventInterviewer
// ====================================================

export interface HideEventInterviewer_hideEventInterviewer_eventInterviewer_personalProfile_userMembership {
  __typename: "UserMembership";
  id: string;
}

export interface HideEventInterviewer_hideEventInterviewer_eventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  lastRequestedUserUpdateAt: GraphQL_DateTime | null;
  lastUserUpdatedAt: GraphQL_DateTime | null;
  userMembership: HideEventInterviewer_hideEventInterviewer_eventInterviewer_personalProfile_userMembership | null;
}

export interface HideEventInterviewer_hideEventInterviewer_eventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
  personalProfile: HideEventInterviewer_hideEventInterviewer_eventInterviewer_personalProfile;
}

export interface HideEventInterviewer_hideEventInterviewer {
  __typename: "HideEventInterviewerMutationResponse";
  success: boolean;
  message: string;
  eventInterviewer: HideEventInterviewer_hideEventInterviewer_eventInterviewer | null;
}

export interface HideEventInterviewer {
  hideEventInterviewer: HideEventInterviewer_hideEventInterviewer;
}

export interface HideEventInterviewerVariables {
  input: HideEventInterviewerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PostTemplateForManaging
// ====================================================

export interface PostTemplateForManaging_currentOrganization_postTemplateById_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface PostTemplateForManaging_currentOrganization_postTemplateById_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: PostTemplateForManaging_currentOrganization_postTemplateById_updatedBy_user;
}

export interface PostTemplateForManaging_currentOrganization_postTemplateById_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface PostTemplateForManaging_currentOrganization_postTemplateById {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: PostTemplateForManaging_currentOrganization_postTemplateById_updatedBy;
  department: PostTemplateForManaging_currentOrganization_postTemplateById_department | null;
}

export interface PostTemplateForManaging_currentOrganization {
  __typename: "Organization";
  id: string;
  postTemplateById: PostTemplateForManaging_currentOrganization_postTemplateById;
}

export interface PostTemplateForManaging {
  currentOrganization: PostTemplateForManaging_currentOrganization | null;
}

export interface PostTemplateForManagingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertPostTemplate
// ====================================================

export interface UpsertPostTemplate_upsertPostTemplate_postTemplate_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface UpsertPostTemplate_upsertPostTemplate_postTemplate_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: UpsertPostTemplate_upsertPostTemplate_postTemplate_updatedBy_user;
}

export interface UpsertPostTemplate_upsertPostTemplate_postTemplate_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface UpsertPostTemplate_upsertPostTemplate_postTemplate_organization {
  __typename: "Organization";
  id: string;
}

export interface UpsertPostTemplate_upsertPostTemplate_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: UpsertPostTemplate_upsertPostTemplate_postTemplate_updatedBy;
  department: UpsertPostTemplate_upsertPostTemplate_postTemplate_department | null;
  organization: UpsertPostTemplate_upsertPostTemplate_postTemplate_organization;
}

export interface UpsertPostTemplate_upsertPostTemplate {
  __typename: "UpsertPostTemplateResponse";
  message: string;
  success: boolean;
  postTemplate: UpsertPostTemplate_upsertPostTemplate_postTemplate;
}

export interface UpsertPostTemplate {
  upsertPostTemplate: UpsertPostTemplate_upsertPostTemplate;
}

export interface UpsertPostTemplateVariables {
  input: UpsertPostTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchivePostTemplate
// ====================================================

export interface ArchivePostTemplate_archivePostTemplate_postTemplate_organization {
  __typename: "Organization";
  id: string;
}

export interface ArchivePostTemplate_archivePostTemplate_postTemplate {
  __typename: "PostTemplate";
  id: string;
  archivedAt: GraphQL_DateTime | null;
  status: PostTemplateStatusEnum | null;
  organization: ArchivePostTemplate_archivePostTemplate_postTemplate_organization;
}

export interface ArchivePostTemplate_archivePostTemplate {
  __typename: "ArchivePostTemplateResponse";
  success: boolean;
  message: string;
  postTemplate: ArchivePostTemplate_archivePostTemplate_postTemplate;
}

export interface ArchivePostTemplate {
  archivePostTemplate: ArchivePostTemplate_archivePostTemplate;
}

export interface ArchivePostTemplateVariables {
  input: ArchivePostTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnarchivePostTemplate
// ====================================================

export interface UnarchivePostTemplate_unarchivePostTemplate_postTemplate_organization {
  __typename: "Organization";
  id: string;
}

export interface UnarchivePostTemplate_unarchivePostTemplate_postTemplate {
  __typename: "PostTemplate";
  id: string;
  archivedAt: GraphQL_DateTime | null;
  status: PostTemplateStatusEnum | null;
  organization: UnarchivePostTemplate_unarchivePostTemplate_postTemplate_organization;
}

export interface UnarchivePostTemplate_unarchivePostTemplate {
  __typename: "UnarchivePostTemplateResponse";
  success: boolean;
  message: string;
  postTemplate: UnarchivePostTemplate_unarchivePostTemplate_postTemplate;
}

export interface UnarchivePostTemplate {
  unarchivePostTemplate: UnarchivePostTemplate_unarchivePostTemplate;
}

export interface UnarchivePostTemplateVariables {
  input: UnarchivePostTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContextForPostTemplateSelector
// ====================================================

export interface ContextForPostTemplateSelector_guideFindUnique_guideLexicalContext_recruiterName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface ContextForPostTemplateSelector_guideFindUnique_guideLexicalContext_candidateName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface ContextForPostTemplateSelector_guideFindUnique_guideLexicalContext_coordinatorName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface ContextForPostTemplateSelector_guideFindUnique_guideLexicalContext {
  __typename: "GuideLexicalContext";
  id: GraphQL_UUID;
  href: string;
  recruiterName: ContextForPostTemplateSelector_guideFindUnique_guideLexicalContext_recruiterName;
  candidateName: ContextForPostTemplateSelector_guideFindUnique_guideLexicalContext_candidateName;
  candidatePhone: string | null;
  coordinatorName: ContextForPostTemplateSelector_guideFindUnique_guideLexicalContext_coordinatorName | null;
  jobTitle: string;
  jobListingUrl: string | null;
  companyName: string;
}

export interface ContextForPostTemplateSelector_guideFindUnique {
  __typename: "Guide";
  id: string;
  guideLexicalContext: ContextForPostTemplateSelector_guideFindUnique_guideLexicalContext;
}

export interface ContextForPostTemplateSelector_currentOrganization_senders_userMembership {
  __typename: "UserMembership";
  id: string;
}

export interface ContextForPostTemplateSelector_currentOrganization_senders {
  __typename: "Interviewer";
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  userMembership: ContextForPostTemplateSelector_currentOrganization_senders_userMembership | null;
}

export interface ContextForPostTemplateSelector_currentOrganization {
  __typename: "Organization";
  id: string;
  name: string;
  defaultAvatarImageUrl: string;
  companyLogoUrl: string;
  senders: ContextForPostTemplateSelector_currentOrganization_senders[];
}

export interface ContextForPostTemplateSelector {
  guideFindUnique: ContextForPostTemplateSelector_guideFindUnique;
  currentOrganization: ContextForPostTemplateSelector_currentOrganization | null;
}

export interface ContextForPostTemplateSelectorVariables {
  where: GuideWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaginatedPostTemplatesForSelector
// ====================================================

export interface PaginatedPostTemplatesForSelector_currentOrganization_paginatedPostTemplates_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface PaginatedPostTemplatesForSelector_currentOrganization_paginatedPostTemplates_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: PaginatedPostTemplatesForSelector_currentOrganization_paginatedPostTemplates_updatedBy_user;
}

export interface PaginatedPostTemplatesForSelector_currentOrganization_paginatedPostTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface PaginatedPostTemplatesForSelector_currentOrganization_paginatedPostTemplates_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface PaginatedPostTemplatesForSelector_currentOrganization_paginatedPostTemplates_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface PaginatedPostTemplatesForSelector_currentOrganization_paginatedPostTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: PaginatedPostTemplatesForSelector_currentOrganization_paginatedPostTemplates_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: PaginatedPostTemplatesForSelector_currentOrganization_paginatedPostTemplates_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface PaginatedPostTemplatesForSelector_currentOrganization_paginatedPostTemplates_createdBy_personalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
  imageUrl: string;
}

export interface PaginatedPostTemplatesForSelector_currentOrganization_paginatedPostTemplates_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: PaginatedPostTemplatesForSelector_currentOrganization_paginatedPostTemplates_createdBy_personalProfile;
}

export interface PaginatedPostTemplatesForSelector_currentOrganization_paginatedPostTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: PaginatedPostTemplatesForSelector_currentOrganization_paginatedPostTemplates_updatedBy;
  department: PaginatedPostTemplatesForSelector_currentOrganization_paginatedPostTemplates_department | null;
  postTemplateSyncData: PaginatedPostTemplatesForSelector_currentOrganization_paginatedPostTemplates_postTemplateSyncData | null;
  createdBy: PaginatedPostTemplatesForSelector_currentOrganization_paginatedPostTemplates_createdBy;
}

export interface PaginatedPostTemplatesForSelector_currentOrganization {
  __typename: "Organization";
  id: string;
  paginatedPostTemplates: PaginatedPostTemplatesForSelector_currentOrganization_paginatedPostTemplates[];
  paginatedPostTemplatesCount: number | null;
}

export interface PaginatedPostTemplatesForSelector {
  currentOrganization: PaginatedPostTemplatesForSelector_currentOrganization | null;
}

export interface PaginatedPostTemplatesForSelectorVariables {
  where?: OrganizationPostTemplatesWhereInput | null;
  offset?: number | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PostTemplateTypesForSelector
// ====================================================

export interface PostTemplateTypesForSelector_currentOrganization {
  __typename: "Organization";
  id: string;
  postTemplateTypes: string[];
}

export interface PostTemplateTypesForSelector {
  currentOrganization: PostTemplateTypesForSelector_currentOrganization | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewersForRecipientManager
// ====================================================

export interface InterviewersForRecipientManager_currentOrganization_interviewers {
  __typename: "Interviewer";
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
}

export interface InterviewersForRecipientManager_currentOrganization {
  __typename: "Organization";
  id: string;
  interviewers: InterviewersForRecipientManager_currentOrganization_interviewers[];
}

export interface InterviewersForRecipientManager {
  currentOrganization: InterviewersForRecipientManager_currentOrganization | null;
}

export interface InterviewersForRecipientManagerVariables {
  searchTerm: string;
  limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetGuidePostRecipients
// ====================================================

export interface SetGuidePostRecipients_setGuidePostRecipients_guidePost_recipientProfiles {
  __typename: "GuidePostUser";
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  isCandidate: boolean;
}

export interface SetGuidePostRecipients_setGuidePostRecipients_guidePost {
  __typename: "GuidePost";
  id: string;
  recipientProfiles: SetGuidePostRecipients_setGuidePostRecipients_guidePost_recipientProfiles[];
}

export interface SetGuidePostRecipients_setGuidePostRecipients {
  __typename: "SetGuidePostRecipientsResponse";
  success: boolean;
  code: string;
  guidePost: SetGuidePostRecipients_setGuidePostRecipients_guidePost | null;
}

export interface SetGuidePostRecipients {
  setGuidePostRecipients: SetGuidePostRecipients_setGuidePostRecipients;
}

export interface SetGuidePostRecipientsVariables {
  input: SetGuidePostRecipientsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TeammatesForTagField
// ====================================================

export interface TeammatesForTagField_teammates_personalProfile {
  __typename: "Interviewer";
  id: string;
  email: string | null;
  name: string;
  imageUrl: string;
}

export interface TeammatesForTagField_teammates {
  __typename: "Teammate";
  id: string;
  personalProfile: TeammatesForTagField_teammates_personalProfile | null;
}

export interface TeammatesForTagField {
  teammates: TeammatesForTagField_teammates[];
}

export interface TeammatesForTagFieldVariables {
  search?: string | null;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProfileToRemove
// ====================================================

export interface GetProfileToRemove_personalProfileFindUnique_userMembership {
  __typename: "UserMembership";
  id: string;
}

export interface GetProfileToRemove_personalProfileFindUnique {
  __typename: "Interviewer";
  id: string;
  email: string | null;
  name: string;
  userMembership: GetProfileToRemove_personalProfileFindUnique_userMembership | null;
}

export interface GetProfileToRemove {
  personalProfileFindUnique: GetProfileToRemove_personalProfileFindUnique;
}

export interface GetProfileToRemoveVariables {
  profileId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveUserMembership
// ====================================================

export interface RemoveUserMembership_removeUserMembership_userMembership_membershipRoles {
  __typename: "Role";
  id: string;
  name: string;
}

export interface RemoveUserMembership_removeUserMembership_userMembership {
  __typename: "UserMembership";
  id: string;
  membershipRoles: RemoveUserMembership_removeUserMembership_userMembership_membershipRoles[];
  active: boolean;
  createdAt: GraphQL_DateTime;
  canChangeRole: boolean;
}

export interface RemoveUserMembership_removeUserMembership {
  __typename: "RemoveUserMembershipResponse";
  message: string;
  success: boolean;
  userMembership: RemoveUserMembership_removeUserMembership_userMembership | null;
}

export interface RemoveUserMembership {
  removeUserMembership: RemoveUserMembership_removeUserMembership;
}

export interface RemoveUserMembershipVariables {
  input: RemoveUserMembershipInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProfileToRestore
// ====================================================

export interface GetProfileToRestore_personalProfileFindUnique_userMembership {
  __typename: "UserMembership";
  id: string;
}

export interface GetProfileToRestore_personalProfileFindUnique {
  __typename: "Interviewer";
  id: string;
  email: string | null;
  name: string;
  userMembership: GetProfileToRestore_personalProfileFindUnique_userMembership | null;
}

export interface GetProfileToRestore {
  personalProfileFindUnique: GetProfileToRestore_personalProfileFindUnique;
}

export interface GetProfileToRestoreVariables {
  profileId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RestoreUserMembership
// ====================================================

export interface RestoreUserMembership_restoreUserMembership_userMembership_membershipRoles {
  __typename: "Role";
  id: string;
  name: string;
}

export interface RestoreUserMembership_restoreUserMembership_userMembership {
  __typename: "UserMembership";
  id: string;
  membershipRoles: RestoreUserMembership_restoreUserMembership_userMembership_membershipRoles[];
  active: boolean;
  createdAt: GraphQL_DateTime;
  canChangeRole: boolean;
}

export interface RestoreUserMembership_restoreUserMembership {
  __typename: "RestoreUserMembershipResponse";
  message: string;
  success: boolean;
  userMembership: RestoreUserMembership_restoreUserMembership_userMembership | null;
}

export interface RestoreUserMembership {
  restoreUserMembership: RestoreUserMembership_restoreUserMembership;
}

export interface RestoreUserMembershipVariables {
  input: RestoreUserMembershipInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserForSatismeter
// ====================================================

export interface UserForSatismeter_currentUserPrisma {
  __typename: "User";
  id: string;
  primaryEmail: string | null;
  fullName: string;
  createdAt: GraphQL_DateTime;
  auth0UserId: string | null;
}

export interface UserForSatismeter {
  currentUserPrisma: UserForSatismeter_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrganizationDefaultGuideContentTemplate
// ====================================================

export interface UpdateOrganizationDefaultGuideContentTemplate_updateOrganizationDefaultGuideContentTemplate {
  __typename: "UpdateOrganizationDefaultGuideContentTemplateResponse";
  success: boolean;
  message: string;
}

export interface UpdateOrganizationDefaultGuideContentTemplate {
  updateOrganizationDefaultGuideContentTemplate: UpdateOrganizationDefaultGuideContentTemplate_updateOrganizationDefaultGuideContentTemplate;
}

export interface UpdateOrganizationDefaultGuideContentTemplateVariables {
  input: UpdateOrganizationDefaultGuideContentTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GreenhouseAPIData
// ====================================================

export interface GreenhouseAPIData_atsUsers {
  __typename: "ATSUser";
  id: string;
  name: string;
}

export interface GreenhouseAPIData_currentUserPrisma_currentOrganization_customer_greenhouseIntegration {
  __typename: "GreenhouseIntegration";
  id: string;
  connected: boolean;
  webhooksSecretKey: string;
  apiKeySuffix: string | null;
  maildropAddress: string;
  subdomain: string;
  boardToken: string | null;
  onBehalfOfUserId: string | null;
}

export interface GreenhouseAPIData_currentUserPrisma_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  greenhouseIntegration: GreenhouseAPIData_currentUserPrisma_currentOrganization_customer_greenhouseIntegration;
}

export interface GreenhouseAPIData_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  customer: GreenhouseAPIData_currentUserPrisma_currentOrganization_customer;
}

export interface GreenhouseAPIData_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: GreenhouseAPIData_currentUserPrisma_currentOrganization | null;
}

export interface GreenhouseAPIData {
  atsUsers: GreenhouseAPIData_atsUsers[];
  currentUserPrisma: GreenhouseAPIData_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGreenhouseAPI
// ====================================================

export interface UpdateGreenhouseAPI_updateCustomerGreenhouse_customer_greenhouseIntegration {
  __typename: "GreenhouseIntegration";
  id: string;
  connected: boolean;
  apiKeySuffix: string | null;
  maildropAddress: string;
  subdomain: string;
  requiredWebhooksConfigured: boolean;
  onBehalfOfUserId: string | null;
}

export interface UpdateGreenhouseAPI_updateCustomerGreenhouse_customer {
  __typename: "Customer";
  id: string;
  greenhouseIntegration: UpdateGreenhouseAPI_updateCustomerGreenhouse_customer_greenhouseIntegration;
}

export interface UpdateGreenhouseAPI_updateCustomerGreenhouse {
  __typename: "UpdateCustomerGreenhouseMutationResponse";
  success: boolean;
  message: string;
  code: string;
  customer: UpdateGreenhouseAPI_updateCustomerGreenhouse_customer | null;
}

export interface UpdateGreenhouseAPI {
  updateCustomerGreenhouse: UpdateGreenhouseAPI_updateCustomerGreenhouse;
}

export interface UpdateGreenhouseAPIVariables {
  input: UpdateCustomerGreenhouseInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DisconnectGreenhouseIntegrationForCustomer
// ====================================================

export interface DisconnectGreenhouseIntegrationForCustomer_disconnectGreenhouse_customer_greenhouseIntegration {
  __typename: "GreenhouseIntegration";
  id: string;
  type: IntegrationEnum;
  connected: boolean;
  apiKeySuffix: string | null;
  maildropAddress: string;
  subdomain: string;
  requiredWebhooksConfigured: boolean;
  onBehalfOfUserId: string | null;
}

export interface DisconnectGreenhouseIntegrationForCustomer_disconnectGreenhouse_customer {
  __typename: "Customer";
  id: string;
  isGreenhouseIntegrated: boolean;
  greenhouseIntegration: DisconnectGreenhouseIntegrationForCustomer_disconnectGreenhouse_customer_greenhouseIntegration;
}

export interface DisconnectGreenhouseIntegrationForCustomer_disconnectGreenhouse {
  __typename: "DisconnectGreenhouseMutationResponse";
  success: boolean;
  message: string;
  code: string;
  customer: DisconnectGreenhouseIntegrationForCustomer_disconnectGreenhouse_customer | null;
}

export interface DisconnectGreenhouseIntegrationForCustomer {
  disconnectGreenhouse: DisconnectGreenhouseIntegrationForCustomer_disconnectGreenhouse;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GreenhouseCardData
// ====================================================

export interface GreenhouseCardData_currentUserPrisma_currentOrganization_customer_greenhouseIntegration {
  __typename: "GreenhouseIntegration";
  id: string;
  type: IntegrationEnum;
  connected: boolean;
  apiKeySuffix: string | null;
  maildropAddress: string;
  subdomain: string;
  requiredWebhooksConfigured: boolean;
  onBehalfOfUserId: string | null;
}

export interface GreenhouseCardData_currentUserPrisma_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  isGreenhouseIntegrated: boolean;
  greenhouseIntegration: GreenhouseCardData_currentUserPrisma_currentOrganization_customer_greenhouseIntegration;
}

export interface GreenhouseCardData_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  customer: GreenhouseCardData_currentUserPrisma_currentOrganization_customer;
}

export interface GreenhouseCardData_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: GreenhouseCardData_currentUserPrisma_currentOrganization | null;
}

export interface GreenhouseCardData {
  currentUserPrisma: GreenhouseCardData_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GreenhouseWebhookData
// ====================================================

export interface GreenhouseWebhookData_currentUserPrisma_currentOrganization_customer_greenhouseIntegration_webhooks {
  __typename: "GreenhouseWebhook";
  id: string;
  eventName: string;
  lastReceivedAt: GraphQL_DateTime | null;
}

export interface GreenhouseWebhookData_currentUserPrisma_currentOrganization_customer_greenhouseIntegration {
  __typename: "GreenhouseIntegration";
  id: string;
  connected: boolean;
  webhooksSecretKey: string;
  apiKeySuffix: string | null;
  maildropAddress: string;
  subdomain: string;
  webhooks: GreenhouseWebhookData_currentUserPrisma_currentOrganization_customer_greenhouseIntegration_webhooks[];
}

export interface GreenhouseWebhookData_currentUserPrisma_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  greenhouseIntegration: GreenhouseWebhookData_currentUserPrisma_currentOrganization_customer_greenhouseIntegration;
}

export interface GreenhouseWebhookData_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  customer: GreenhouseWebhookData_currentUserPrisma_currentOrganization_customer;
}

export interface GreenhouseWebhookData_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: GreenhouseWebhookData_currentUserPrisma_currentOrganization | null;
}

export interface GreenhouseWebhookData {
  currentUserPrisma: GreenhouseWebhookData_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateMergeLinkTokenForCurrentUser
// ====================================================

export interface GenerateMergeLinkTokenForCurrentUser_generateMergeLinkTokenForCurrentUser {
  __typename: "GenerateMergeLinkTokenForCurrentUser";
  accountId: string | null;
  linkToken: string | null;
}

export interface GenerateMergeLinkTokenForCurrentUser {
  generateMergeLinkTokenForCurrentUser: GenerateMergeLinkTokenForCurrentUser_generateMergeLinkTokenForCurrentUser | null;
}

export interface GenerateMergeLinkTokenForCurrentUserVariables {
  input: GenerateMergeLinkTokenInputPrismaProxy;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StoreMergeAccountToken
// ====================================================

export interface StoreMergeAccountToken_storeMergeAccountToken {
  __typename: "StoreMergeAccountToken";
  success: boolean;
  message: string;
}

export interface StoreMergeAccountToken {
  storeMergeAccountToken: StoreMergeAccountToken_storeMergeAccountToken | null;
}

export interface StoreMergeAccountTokenVariables {
  input: StoreMergeAccountTokenInputPrismaProxy;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteMergeAccount
// ====================================================

export interface DeleteMergeAccount_deleteMergeAccount {
  __typename: "DeleteMergeAccount";
  success: boolean;
  message: string;
}

export interface DeleteMergeAccount {
  deleteMergeAccount: DeleteMergeAccount_deleteMergeAccount | null;
}

export interface DeleteMergeAccountVariables {
  input: DeleteMergeAccountInputPrismaProxy;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SetupWizardHiringPlanQuery
// ====================================================

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  stageAutomationEnabled: boolean;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes_change = SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes_change_BooleanFieldChange | SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes_change_StringFieldChange | SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes_change_StringArrayFieldChange;

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes {
  __typename: "GuidePropagationFieldChange";
  field: GuidePropagationChangesEnum;
  change: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes_change;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedAddedData {
  __typename: "CollectionFieldChangedAddedData";
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedRemovedData {
  __typename: "CollectionFieldChangedRemovedData";
  parentId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedReorderedData {
  __typename: "CollectionFieldChangedReorderedData";
  parentId: string | null;
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export type SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data = SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedAddedData | SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedRemovedData | SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedReorderedData;

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges {
  __typename: "GuideCollectionPropagationFieldChange";
  type: GuideCollectionPropagationChangesTypeEnum;
  data: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate | null;
  schedulerAvailability: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability | null;
  configInterviewers: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers[];
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change = SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange | SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringFieldChange | SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange;

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate | null;
  emailTemplate: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_emailTemplate | null;
  postTemplate: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplate | null;
  postTemplates: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates[];
  atssyncJobStage: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_atssyncJobStage | null;
  schedulerConfig: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig | null;
  changes: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes[];
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates[];
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items[];
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_atsJob_atsJobStages {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_atsJob {
  __typename: "ATSJobRole";
  id: string;
  atsJobStages: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_atsJob_atsJobStages[];
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseInterview {
  __typename: "ATSInterview";
  id: string;
  name: string;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  atssyncGreenhouseJobStage: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseJobStage | null;
  atssyncGreenhouseInterview: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseInterview | null;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  jobDisplayName: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleNameOverride: string | null;
  inProgressGuidesCount: number;
  setupStatus: JobRoleGuideTemplateSetupStatus;
  defaultGuideVersion: number;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  changes: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes[];
  collectionChanges: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges[];
  jobRoleGuideTemplateStages: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages[];
  interviewPlan: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_interviewPlan | null;
  guideTemplate: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_guideTemplate | null;
  atsJob: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_atsJob | null;
  eventTemplates: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_eventTemplates[];
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  defaultAvatarImageUrl: string;
  theme: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_theme | null;
  customer: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_customer;
  companyLogoUrl: string;
  name: string;
  jobRoleGuideTemplateById: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById;
}

export interface SetupWizardHiringPlanQuery_currentUserPrisma {
  __typename: "User";
  id: string;
  nylasAccessTokenExists: boolean;
  currentOrganization: SetupWizardHiringPlanQuery_currentUserPrisma_currentOrganization | null;
}

export interface SetupWizardHiringPlanQuery {
  currentUserPrisma: SetupWizardHiringPlanQuery_currentUserPrisma | null;
}

export interface SetupWizardHiringPlanQueryVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobInterviewsForJobSetup
// ====================================================

export interface JobInterviewsForJobSetup_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

export interface JobInterviewsForJobSetup_currentOrganization_jobRoleGuideTemplateById_eventTemplates_interviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  title: GraphQL_JSON | null;
  description: GraphQL_JSON | null;
}

export interface JobInterviewsForJobSetup_currentOrganization_jobRoleGuideTemplateById_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  position: number;
  description: string;
  hidden: boolean;
  atsEventTemplateId: string;
  atsEventTemplateName: string;
  atssyncGreenhouseJobStage: JobInterviewsForJobSetup_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseJobStage | null;
  interviewTemplate: JobInterviewsForJobSetup_currentOrganization_jobRoleGuideTemplateById_eventTemplates_interviewTemplate | null;
}

export interface JobInterviewsForJobSetup_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  eventTemplates: JobInterviewsForJobSetup_currentOrganization_jobRoleGuideTemplateById_eventTemplates[];
}

export interface JobInterviewsForJobSetup_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplateById: JobInterviewsForJobSetup_currentOrganization_jobRoleGuideTemplateById;
}

export interface JobInterviewsForJobSetup {
  currentOrganization: JobInterviewsForJobSetup_currentOrganization | null;
}

export interface JobInterviewsForJobSetupVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobRoleGuideTemplateForSettingsSetup
// ====================================================

export interface GetJobRoleGuideTemplateForSettingsSetup_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  jobRoleNameOverride: string | null;
  name: string;
  linkToJobDescription: string | null;
}

export interface GetJobRoleGuideTemplateForSettingsSetup_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplateById: GetJobRoleGuideTemplateForSettingsSetup_currentOrganization_jobRoleGuideTemplateById;
}

export interface GetJobRoleGuideTemplateForSettingsSetup {
  currentOrganization: GetJobRoleGuideTemplateForSettingsSetup_currentOrganization | null;
}

export interface GetJobRoleGuideTemplateForSettingsSetupVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetupJobRoleJobDetails
// ====================================================

export interface SetupJobRoleJobDetails_updateJobRoleGuideTemplate {
  __typename: "UpdateJobRoleGuideTemplateResponse";
  success: boolean;
  message: string;
  code: string;
}

export interface SetupJobRoleJobDetails {
  updateJobRoleGuideTemplate: SetupJobRoleJobDetails_updateJobRoleGuideTemplate;
}

export interface SetupJobRoleJobDetailsVariables {
  input: UpdateJobRoleGuideTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobRoleGuideTemplatesToCopyFilterData
// ====================================================

export interface JobRoleGuideTemplatesToCopyFilterData_atsOffices {
  __typename: "ATSOffice";
  id: string;
  name: string;
}

export interface JobRoleGuideTemplatesToCopyFilterData_atsDepartments {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface JobRoleGuideTemplatesToCopyFilterData_atsUsers {
  __typename: "ATSUser";
  id: string;
  name: string;
}

export interface JobRoleGuideTemplatesToCopyFilterData {
  atsOffices: JobRoleGuideTemplatesToCopyFilterData_atsOffices[];
  atsDepartments: JobRoleGuideTemplatesToCopyFilterData_atsDepartments[];
  atsUsers: JobRoleGuideTemplatesToCopyFilterData_atsUsers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllJobRoleGuideTemplateIdsToCopy
// ====================================================

export interface AllJobRoleGuideTemplateIdsToCopy_currentOrganization_jobRoleGuideTemplates_jobRole {
  __typename: "JobRole";
  id: string;
}

export interface AllJobRoleGuideTemplateIdsToCopy_currentOrganization_jobRoleGuideTemplates {
  __typename: "JobRoleGuideTemplate";
  id: string;
  jobRole: AllJobRoleGuideTemplateIdsToCopy_currentOrganization_jobRoleGuideTemplates_jobRole | null;
}

export interface AllJobRoleGuideTemplateIdsToCopy_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplates: AllJobRoleGuideTemplateIdsToCopy_currentOrganization_jobRoleGuideTemplates[];
}

export interface AllJobRoleGuideTemplateIdsToCopy {
  currentOrganization: AllJobRoleGuideTemplateIdsToCopy_currentOrganization | null;
}

export interface AllJobRoleGuideTemplateIdsToCopyVariables {
  search?: string | null;
  atsUserIds?: string[] | null;
  atsOfficeIds?: string[] | null;
  atsDepartmentIds?: string[] | null;
  status?: JourneyStatusEnum | null;
  excludeIds?: GraphQL_UUID[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOpenJobsToCopy
// ====================================================

export interface GetOpenJobsToCopy_currentUserPrisma_currentOrganization_customer_atsIntegration {
  __typename: "AtsIntegration";
  id: string;
  atsType: string | null;
}

export interface GetOpenJobsToCopy_currentUserPrisma_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  atsIntegration: GetOpenJobsToCopy_currentUserPrisma_currentOrganization_customer_atsIntegration | null;
}

export interface GetOpenJobsToCopy_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  customer: GetOpenJobsToCopy_currentUserPrisma_currentOrganization_customer;
}

export interface GetOpenJobsToCopy_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: GetOpenJobsToCopy_currentUserPrisma_currentOrganization | null;
}

export interface GetOpenJobsToCopy_openJobs_jobRole_atsJob_atsDepartments {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface GetOpenJobsToCopy_openJobs_jobRole_atsJob_atsOffices {
  __typename: "ATSOffice";
  id: string;
  name: string;
}

export interface GetOpenJobsToCopy_openJobs_jobRole_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
  requisitionId: string | null;
  atsDepartments: GetOpenJobsToCopy_openJobs_jobRole_atsJob_atsDepartments[];
  atsOffices: GetOpenJobsToCopy_openJobs_jobRole_atsJob_atsOffices[];
}

export interface GetOpenJobsToCopy_openJobs_jobRole {
  __typename: "JobRole";
  id: string;
  isDemo: boolean;
  atsJob: GetOpenJobsToCopy_openJobs_jobRole_atsJob | null;
}

export interface GetOpenJobsToCopy_openJobs_configuredStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  position: number;
}

export interface GetOpenJobsToCopy_openJobs {
  __typename: "OpenJob";
  id: string;
  name: string;
  jobRole: GetOpenJobsToCopy_openJobs_jobRole | null;
  configuredStages: GetOpenJobsToCopy_openJobs_configuredStages[];
  updatedAt: GraphQL_DateTime;
  sentGuideCount: number;
  configuredStagesCount: number | null;
  unconfiguredStagesCount: number | null;
  setupStatus: JobRoleGuideTemplateSetupStatus;
}

export interface GetOpenJobsToCopy {
  currentUserPrisma: GetOpenJobsToCopy_currentUserPrisma | null;
  openJobs: GetOpenJobsToCopy_openJobs[];
}

export interface GetOpenJobsToCopyVariables {
  search?: string | null;
  atsUserIds?: string[] | null;
  atsOfficeIds?: string[] | null;
  atsDepartmentIds?: string[] | null;
  offset?: number | null;
  limit?: number | null;
  status?: JourneyStatusEnum | null;
  orderBy?: OpenJobOrderByInput[] | null;
  excludeIds?: GraphQL_UUID[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CopySettingsToJob
// ====================================================

export interface CopySettingsToJob_copySettingsToJob {
  __typename: "CopySettingsToJobResponse";
  success: boolean;
}

export interface CopySettingsToJob {
  copySettingsToJob: CopySettingsToJob_copySettingsToJob;
}

export interface CopySettingsToJobVariables {
  input: CopySettingsToJobInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOneAvailableOpenJob
// ====================================================

export interface GetOneAvailableOpenJob_openJobs {
  __typename: "OpenJob";
  id: string;
}

export interface GetOneAvailableOpenJob {
  openJobs: GetOneAvailableOpenJob_openJobs[];
}

export interface GetOneAvailableOpenJobVariables {
  excludeIds?: GraphQL_UUID[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobRoleGuideTemplateStagesForReview
// ====================================================

export interface JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
}

export interface JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  originalATSId: string;
}

export interface JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer;
}

export interface JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate | null;
  schedulerAvailability: JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability | null;
  configInterviewers: JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers[];
}

export interface JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  position: number;
  description: string;
  contentTemplate: JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate | null;
  atssyncJobStage: JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_atssyncJobStage | null;
  schedulerConfig: JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig | null;
}

export interface JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  jobRoleName: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  defaultGuideVersion: number;
  guideTemplate: JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_guideTemplate | null;
  jobRoleGuideTemplateStages: JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages[];
  jobDisplayName: string;
}

export interface JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  stageAutomationEnabled: boolean;
}

export interface JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  name: string;
  companyLogoUrl: string;
  jobRoleGuideTemplateById: JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById;
  defaultAvatarImageUrl: string;
  theme: JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_theme | null;
  customer: JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization_customer;
  showTrim: boolean;
}

export interface JobRoleGuideTemplateStagesForReview_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: JobRoleGuideTemplateStagesForReview_currentUserPrisma_currentOrganization | null;
}

export interface JobRoleGuideTemplateStagesForReview {
  currentUserPrisma: JobRoleGuideTemplateStagesForReview_currentUserPrisma | null;
}

export interface JobRoleGuideTemplateStagesForReviewVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FinishJobSetup
// ====================================================

export interface FinishJobSetup_updateJobRoleGuideTemplate_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  setupStatus: JobRoleGuideTemplateSetupStatus;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
}

export interface FinishJobSetup_updateJobRoleGuideTemplate {
  __typename: "UpdateJobRoleGuideTemplateResponse";
  success: boolean;
  message: string;
  code: string;
  jobRoleGuideTemplate: FinishJobSetup_updateJobRoleGuideTemplate_jobRoleGuideTemplate | null;
}

export interface FinishJobSetup {
  updateJobRoleGuideTemplate: FinishJobSetup_updateJobRoleGuideTemplate;
}

export interface FinishJobSetupVariables {
  input: UpdateJobRoleGuideTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePitchPageTemplate
// ====================================================

export interface DeletePitchPageTemplate_deletePitchPageTemplate {
  __typename: "DeletePitchPageTemplateMutationResponse";
  success: boolean;
}

export interface DeletePitchPageTemplate {
  deletePitchPageTemplate: DeletePitchPageTemplate_deletePitchPageTemplate;
}

export interface DeletePitchPageTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchPitchPageTemplates
// ====================================================

export interface FetchPitchPageTemplates_currentUserPrisma_currentUserMembership {
  __typename: "UserMembership";
  id: string;
}

export interface FetchPitchPageTemplates_currentUserPrisma_currentOrganization_pitchPageTemplates_createdBy_user {
  __typename: "User";
  id: string;
  fullName: string;
  imageUrl: string;
}

export interface FetchPitchPageTemplates_currentUserPrisma_currentOrganization_pitchPageTemplates_createdBy {
  __typename: "UserMembership";
  id: string;
  user: FetchPitchPageTemplates_currentUserPrisma_currentOrganization_pitchPageTemplates_createdBy_user;
}

export interface FetchPitchPageTemplates_currentUserPrisma_currentOrganization_pitchPageTemplates {
  __typename: "PitchPageTemplate";
  id: string;
  name: string;
  numCreated: number;
  numBooked: number;
  createdBy: FetchPitchPageTemplates_currentUserPrisma_currentOrganization_pitchPageTemplates_createdBy;
}

export interface FetchPitchPageTemplates_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  pitchPageTemplates: FetchPitchPageTemplates_currentUserPrisma_currentOrganization_pitchPageTemplates[];
}

export interface FetchPitchPageTemplates_currentUserPrisma {
  __typename: "User";
  id: string;
  currentUserMembership: FetchPitchPageTemplates_currentUserPrisma_currentUserMembership | null;
  currentOrganization: FetchPitchPageTemplates_currentUserPrisma_currentOrganization | null;
}

export interface FetchPitchPageTemplates {
  currentUserPrisma: FetchPitchPageTemplates_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShowStageFromBanner
// ====================================================

export interface ShowStageFromBanner_showStage_stage {
  __typename: "Stage";
  id: string;
  hidden: boolean;
  status: StageStatus;
}

export interface ShowStageFromBanner_showStage {
  __typename: "ShowStageMutationResponse";
  success: boolean;
  message: string;
  stage: ShowStageFromBanner_showStage_stage | null;
}

export interface ShowStageFromBanner {
  showStage: ShowStageFromBanner_showStage;
}

export interface ShowStageFromBannerVariables {
  input: ShowStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShowStage
// ====================================================

export interface ShowStage_showStage_stage {
  __typename: "Stage";
  id: string;
  hidden: boolean;
  status: StageStatus;
}

export interface ShowStage_showStage {
  __typename: "ShowStageMutationResponse";
  success: boolean;
  message: string;
  stage: ShowStage_showStage_stage | null;
}

export interface ShowStage {
  showStage: ShowStage_showStage;
}

export interface ShowStageVariables {
  input: ShowStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: HideStage
// ====================================================

export interface HideStage_hideStage_stage {
  __typename: "Stage";
  id: string;
  hidden: boolean;
  status: StageStatus;
}

export interface HideStage_hideStage {
  __typename: "HideStageMutationResponse";
  success: boolean;
  message: string;
  stage: HideStage_hideStage_stage | null;
}

export interface HideStage {
  hideStage: HideStage_hideStage;
}

export interface HideStageVariables {
  input: HideStageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AutoConfigureStage
// ====================================================

export interface AutoConfigureStage_activateStageTemplateInstallation {
  __typename: "ActivateStageTemplateInstallationMutationResponse";
  success: boolean;
}

export interface AutoConfigureStage {
  activateStageTemplateInstallation: AutoConfigureStage_activateStageTemplateInstallation;
}

export interface AutoConfigureStageVariables {
  input: ActivateStageTemplateInstallationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideTemplatesForSelect
// ====================================================

export interface GuideTemplatesForSelect_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface GuideTemplatesForSelect_currentOrganization_guideTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface GuideTemplatesForSelect_currentOrganization_guideTemplates_guideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface GuideTemplatesForSelect_currentOrganization_guideTemplates_guideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: GuideTemplatesForSelect_currentOrganization_guideTemplates_guideTemplateStages_contentTemplate_modulesInflated[];
}

export interface GuideTemplatesForSelect_currentOrganization_guideTemplates_guideTemplateStages {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  contentTemplate: GuideTemplatesForSelect_currentOrganization_guideTemplates_guideTemplateStages_contentTemplate | null;
}

export interface GuideTemplatesForSelect_currentOrganization_guideTemplates {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime;
  chatEnabled: boolean;
  isGlobal: boolean;
  department: GuideTemplatesForSelect_currentOrganization_guideTemplates_department | null;
  interviewProcessEnabled: boolean;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  guideTemplateStages: GuideTemplatesForSelect_currentOrganization_guideTemplates_guideTemplateStages[];
}

export interface GuideTemplatesForSelect_currentOrganization {
  __typename: "Organization";
  id: string;
  companyLogoUrl: string;
  name: string;
  theme: GuideTemplatesForSelect_currentOrganization_theme | null;
  showTrim: boolean;
  guideTemplates: GuideTemplatesForSelect_currentOrganization_guideTemplates[];
}

export interface GuideTemplatesForSelect {
  currentOrganization: GuideTemplatesForSelect_currentOrganization | null;
}

export interface GuideTemplatesForSelectVariables {
  orderBy?: OrganizationGuideTemplatesOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAtsStagesForMapping
// ====================================================

export interface GetAtsStagesForMapping_currentOrganization_stages {
  __typename: "AtsStageWithJobRoleGuideTemplateIdsResponse";
  id: string;
  name: string;
  avgPosition: number | null;
  jobRoleGuideTemplateIds: string[];
  guideTemplateStageIds: string[];
}

export interface GetAtsStagesForMapping_currentOrganization {
  __typename: "Organization";
  id: string;
  stages: GetAtsStagesForMapping_currentOrganization_stages[];
}

export interface GetAtsStagesForMapping {
  currentOrganization: GetAtsStagesForMapping_currentOrganization | null;
}

export interface GetAtsStagesForMappingVariables {
  where: AtsStageWithJobRoleGuideTemplateIdsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPlanForMapping
// ====================================================

export interface GetPlanForMapping_currentOrganization_guideTemplate_guideTemplateStages {
  __typename: "GuideTemplateStage";
  id: string;
  position: number;
  name: string;
}

export interface GetPlanForMapping_currentOrganization_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  guideTemplateStages: GetPlanForMapping_currentOrganization_guideTemplate_guideTemplateStages[];
}

export interface GetPlanForMapping_currentOrganization {
  __typename: "Organization";
  id: string;
  guideTemplate: GetPlanForMapping_currentOrganization_guideTemplate;
}

export interface GetPlanForMapping {
  currentOrganization: GetPlanForMapping_currentOrganization | null;
}

export interface GetPlanForMappingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UseGuideTemplate
// ====================================================

export interface UseGuideTemplate_mapGuideTemplate_jobRoleGuideTemplates_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface UseGuideTemplate_mapGuideTemplate_jobRoleGuideTemplates_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: UseGuideTemplate_mapGuideTemplate_jobRoleGuideTemplates_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface UseGuideTemplate_mapGuideTemplate_jobRoleGuideTemplates_jobRoleGuideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface UseGuideTemplate_mapGuideTemplate_jobRoleGuideTemplates_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  contentTemplate: UseGuideTemplate_mapGuideTemplate_jobRoleGuideTemplates_jobRoleGuideTemplateStages_contentTemplate | null;
  emailTemplate: UseGuideTemplate_mapGuideTemplate_jobRoleGuideTemplates_jobRoleGuideTemplateStages_emailTemplate | null;
}

export interface UseGuideTemplate_mapGuideTemplate_jobRoleGuideTemplates_guideTemplate_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface UseGuideTemplate_mapGuideTemplate_jobRoleGuideTemplates_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  isGlobal: boolean;
  department: UseGuideTemplate_mapGuideTemplate_jobRoleGuideTemplates_guideTemplate_department | null;
}

export interface UseGuideTemplate_mapGuideTemplate_jobRoleGuideTemplates {
  __typename: "JobRoleGuideTemplate";
  id: string;
  jobRoleGuideTemplateStages: UseGuideTemplate_mapGuideTemplate_jobRoleGuideTemplates_jobRoleGuideTemplateStages[];
  guideTemplate: UseGuideTemplate_mapGuideTemplate_jobRoleGuideTemplates_guideTemplate | null;
}

export interface UseGuideTemplate_mapGuideTemplate_atsStageWithJobRoleGuideTemplateIds {
  __typename: "AtsStageWithJobRoleGuideTemplateIdsResponse";
  id: string;
  name: string;
  jobRoleGuideTemplateIds: string[];
  guideTemplateStageIds: string[];
}

export interface UseGuideTemplate_mapGuideTemplate {
  __typename: "MapGuideTemplateResponse";
  message: string;
  code: string;
  success: boolean;
  jobRoleGuideTemplates: UseGuideTemplate_mapGuideTemplate_jobRoleGuideTemplates[];
  atsStageWithJobRoleGuideTemplateIds: UseGuideTemplate_mapGuideTemplate_atsStageWithJobRoleGuideTemplateIds[];
}

export interface UseGuideTemplate {
  mapGuideTemplate: UseGuideTemplate_mapGuideTemplate;
}

export interface UseGuideTemplateVariables {
  input: MapGuideTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ZeusGuideTemplatesForSelect
// ====================================================

export interface ZeusGuideTemplatesForSelect_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface ZeusGuideTemplatesForSelect_currentOrganization_guideTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface ZeusGuideTemplatesForSelect_currentOrganization_guideTemplates_guideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface ZeusGuideTemplatesForSelect_currentOrganization_guideTemplates_guideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: ZeusGuideTemplatesForSelect_currentOrganization_guideTemplates_guideTemplateStages_contentTemplate_modulesInflated[];
}

export interface ZeusGuideTemplatesForSelect_currentOrganization_guideTemplates_guideTemplateStages {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  contentTemplate: ZeusGuideTemplatesForSelect_currentOrganization_guideTemplates_guideTemplateStages_contentTemplate | null;
}

export interface ZeusGuideTemplatesForSelect_currentOrganization_guideTemplates {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  isGlobal: boolean;
  department: ZeusGuideTemplatesForSelect_currentOrganization_guideTemplates_department | null;
  updatedAt: GraphQL_DateTime;
  guideTemplateStages: ZeusGuideTemplatesForSelect_currentOrganization_guideTemplates_guideTemplateStages[];
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
}

export interface ZeusGuideTemplatesForSelect_currentOrganization {
  __typename: "Organization";
  id: string;
  defaultAvatarImageUrl: string;
  companyLogoUrl: string;
  name: string;
  theme: ZeusGuideTemplatesForSelect_currentOrganization_theme | null;
  showTrim: boolean;
  guideTemplates: ZeusGuideTemplatesForSelect_currentOrganization_guideTemplates[];
}

export interface ZeusGuideTemplatesForSelect {
  currentOrganization: ZeusGuideTemplatesForSelect_currentOrganization | null;
}

export interface ZeusGuideTemplatesForSelectVariables {
  orderBy?: OrganizationGuideTemplatesOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkRequestProfileUpdate
// ====================================================

export interface BulkRequestProfileUpdate_bulkRequestProfileUpdate_personalProfiles {
  __typename: "Interviewer";
  id: string;
  lastRequestedUserUpdateAt: GraphQL_DateTime | null;
  lastUserUpdatedAt: GraphQL_DateTime | null;
}

export interface BulkRequestProfileUpdate_bulkRequestProfileUpdate {
  __typename: "BulkRequestProfileUpdateMutationResponse";
  message: string;
  success: boolean;
  code: string;
  personalProfiles: BulkRequestProfileUpdate_bulkRequestProfileUpdate_personalProfiles[] | null;
}

export interface BulkRequestProfileUpdate {
  bulkRequestProfileUpdate: BulkRequestProfileUpdate_bulkRequestProfileUpdate;
}

export interface BulkRequestProfileUpdateVariables {
  input: BulkRequestProfileUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRoleForInvitation
// ====================================================

export interface UpdateRoleForInvitation_updateInvitationRole_userInvitation_role {
  __typename: "Role";
  id: string;
  name: string;
}

export interface UpdateRoleForInvitation_updateInvitationRole_userInvitation {
  __typename: "UserInvitation";
  id: string;
  canChangeRole: boolean;
  role: UpdateRoleForInvitation_updateInvitationRole_userInvitation_role;
}

export interface UpdateRoleForInvitation_updateInvitationRole {
  __typename: "UpdateInvitationRoleMutationResponse";
  success: boolean;
  message: string;
  userInvitation: UpdateRoleForInvitation_updateInvitationRole_userInvitation | null;
}

export interface UpdateRoleForInvitation {
  updateInvitationRole: UpdateRoleForInvitation_updateInvitationRole;
}

export interface UpdateRoleForInvitationVariables {
  input: UpdateInvitationRoleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRoleForUserMembership
// ====================================================

export interface UpdateRoleForUserMembership_updateUserMembershipRole_userMembership_membershipRoles {
  __typename: "Role";
  id: string;
  name: string;
}

export interface UpdateRoleForUserMembership_updateUserMembershipRole_userMembership {
  __typename: "UserMembership";
  id: string;
  canChangeRole: boolean;
  membershipRoles: UpdateRoleForUserMembership_updateUserMembershipRole_userMembership_membershipRoles[];
}

export interface UpdateRoleForUserMembership_updateUserMembershipRole {
  __typename: "UpdateUserMembershipRoleMutationResponse";
  success: boolean;
  message: string;
  userMembership: UpdateRoleForUserMembership_updateUserMembershipRole_userMembership | null;
}

export interface UpdateRoleForUserMembership {
  updateUserMembershipRole: UpdateRoleForUserMembership_updateUserMembershipRole;
}

export interface UpdateRoleForUserMembershipVariables {
  input: UpdateUserMembershipRoleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RevokeInvitation
// ====================================================

export interface RevokeInvitation_revokeInvitations {
  __typename: "RevokeUserInvitationsMutationResponse";
  success: boolean;
  message: string;
  deletedInvitationIds: GraphQL_UUID[] | null;
}

export interface RevokeInvitation {
  revokeInvitations: RevokeInvitation_revokeInvitations;
}

export interface RevokeInvitationVariables {
  input: RevokeUserInvitationsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestProfileUpdateUsersTable
// ====================================================

export interface RequestProfileUpdateUsersTable_requestProfileUpdate_personalProfile {
  __typename: "Interviewer";
  id: string;
  lastRequestedUserUpdateAt: GraphQL_DateTime | null;
  lastUserUpdatedAt: GraphQL_DateTime | null;
}

export interface RequestProfileUpdateUsersTable_requestProfileUpdate {
  __typename: "RequestProfileUpdateMutationResponse";
  success: boolean;
  message: string;
  code: string;
  personalProfile: RequestProfileUpdateUsersTable_requestProfileUpdate_personalProfile | null;
}

export interface RequestProfileUpdateUsersTable {
  requestProfileUpdate: RequestProfileUpdateUsersTable_requestProfileUpdate;
}

export interface RequestProfileUpdateUsersTableVariables {
  input: RequestProfileUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResendUserInvitation
// ====================================================

export interface ResendUserInvitation_sendInvitations_invitations {
  __typename: "UserInvitation";
  id: string;
}

export interface ResendUserInvitation_sendInvitations {
  __typename: "SendUserInvitationsMutationResponse";
  message: string;
  success: boolean;
  invitations: ResendUserInvitation_sendInvitations_invitations[] | null;
}

export interface ResendUserInvitation {
  sendInvitations: ResendUserInvitation_sendInvitations;
}

export interface ResendUserInvitationVariables {
  input: SendUserInvitationsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuidePostRendererContext
// ====================================================

export interface GuidePostRendererContext_guideFindUnique_guideLexicalContext_recruiterName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuidePostRendererContext_guideFindUnique_guideLexicalContext_candidateName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuidePostRendererContext_guideFindUnique_guideLexicalContext_coordinatorName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuidePostRendererContext_guideFindUnique_guideLexicalContext {
  __typename: "GuideLexicalContext";
  id: GraphQL_UUID;
  href: string;
  recruiterName: GuidePostRendererContext_guideFindUnique_guideLexicalContext_recruiterName;
  candidateName: GuidePostRendererContext_guideFindUnique_guideLexicalContext_candidateName;
  candidatePhone: string | null;
  coordinatorName: GuidePostRendererContext_guideFindUnique_guideLexicalContext_coordinatorName | null;
  jobTitle: string;
  jobListingUrl: string | null;
  companyName: string;
}

export interface GuidePostRendererContext_guideFindUnique {
  __typename: "Guide";
  id: string;
  guideLexicalContext: GuidePostRendererContext_guideFindUnique_guideLexicalContext;
}

export interface GuidePostRendererContext {
  guideFindUnique: GuidePostRendererContext_guideFindUnique;
}

export interface GuidePostRendererContextVariables {
  where: GuideWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GeneratedGuidePostHtml
// ====================================================

export interface GeneratedGuidePostHtml_generatedGuidePostHtml {
  __typename: "GuidePostHtml";
  renderedHTML: string;
}

export interface GeneratedGuidePostHtml {
  generatedGuidePostHtml: GeneratedGuidePostHtml_generatedGuidePostHtml | null;
}

export interface GeneratedGuidePostHtmlVariables {
  input: GeneratedGuidePostHtmlInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendRawViaSendWithUs
// ====================================================

export interface SendRawViaSendWithUs_sendViaSendWithUs {
  __typename: "SendViaSendWithUsResponse";
  code: string;
  success: boolean;
  message: string;
}

export interface SendRawViaSendWithUs {
  sendViaSendWithUs: SendRawViaSendWithUs_sendViaSendWithUs;
}

export interface SendRawViaSendWithUsVariables {
  input: SendViaSendWithUsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TrackGuideOpen
// ====================================================

export interface TrackGuideOpen_trackGuideOpen {
  __typename: "Guide";
  id: string;
}

export interface TrackGuideOpen {
  trackGuideOpen: TrackGuideOpen_trackGuideOpen;
}

export interface TrackGuideOpenVariables {
  guideId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideForShortIdPrisma
// ====================================================

export interface GuideForShortIdPrisma_guideFindUniqueByShortId_currentStage_modules {
  __typename: "StageResolvedModule";
  id: string;
  position: number;
  data: GraphQL_JSON;
  type: string;
}

export interface GuideForShortIdPrisma_guideFindUniqueByShortId_currentStage {
  __typename: "Stage";
  id: string;
  position: number;
  title: string;
  /**
   * Returns the Content Modules for this stage contextualized
   *        to the stage's Guide. Today, the content modules come from a combination of 
   *        stamped modules (the interview scheduler) and the associated ContentTemplate's modules
   */
  modules: GuideForShortIdPrisma_guideFindUniqueByShortId_currentStage_modules[];
}

export interface GuideForShortIdPrisma_guideFindUniqueByShortId_candidate_organization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface GuideForShortIdPrisma_guideFindUniqueByShortId_candidate_organization_customer_plan {
  __typename: "CustomerPlan";
  id: string;
  name: string;
}

export interface GuideForShortIdPrisma_guideFindUniqueByShortId_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  trialDuration: number | null;
  trialStart: GraphQL_DateTime | null;
  name: string;
  plan: GuideForShortIdPrisma_guideFindUniqueByShortId_candidate_organization_customer_plan;
}

export interface GuideForShortIdPrisma_guideFindUniqueByShortId_candidate_organization {
  __typename: "Organization";
  id: string;
  companyLogoUrl: string;
  theme: GuideForShortIdPrisma_guideFindUniqueByShortId_candidate_organization_theme | null;
  showTrim: boolean;
  hideLogoAndTrackerInGuides: boolean;
  disableChatGlobally: boolean;
  name: string;
  createdAt: GraphQL_DateTime;
  zeusStatus: ZeusStatusEnum;
  planPricingModel: PlanPricingModelEnum | null;
  accountStatus: AccountStatusEnum | null;
  employeeCountEnum: CompanyEmployeeSizeEnum | null;
  talentTeamCountEnum: CompanyTalentTeamSizeEnum | null;
  contractStartDate: GraphQL_DateTime | null;
  customer: GuideForShortIdPrisma_guideFindUniqueByShortId_candidate_organization_customer;
}

export interface GuideForShortIdPrisma_guideFindUniqueByShortId_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  organization: GuideForShortIdPrisma_guideFindUniqueByShortId_candidate_organization;
}

export interface GuideForShortIdPrisma_guideFindUniqueByShortId_sortedVisibleStages_feedbackSubmissionData_feedbackSubmission {
  __typename: "CandidateFeedback";
  id: string;
}

export interface GuideForShortIdPrisma_guideFindUniqueByShortId_sortedVisibleStages_feedbackSubmissionData_surveySubmission {
  __typename: "CandidateSurvey";
  id: string;
}

export interface GuideForShortIdPrisma_guideFindUniqueByShortId_sortedVisibleStages_feedbackSubmissionData {
  __typename: "StageFeedbackSubmissionData";
  needsFeedback: boolean;
  feedbackSubmission: GuideForShortIdPrisma_guideFindUniqueByShortId_sortedVisibleStages_feedbackSubmissionData_feedbackSubmission | null;
  surveySubmission: GuideForShortIdPrisma_guideFindUniqueByShortId_sortedVisibleStages_feedbackSubmissionData_surveySubmission | null;
}

export interface GuideForShortIdPrisma_guideFindUniqueByShortId_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  position: number;
  title: string;
  hidden: boolean;
  description: string | null;
  feedbackSubmissionData: GuideForShortIdPrisma_guideFindUniqueByShortId_sortedVisibleStages_feedbackSubmissionData | null;
}

export interface GuideForShortIdPrisma_guideFindUniqueByShortId_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  defaultGuideVersion: number;
}

export interface GuideForShortIdPrisma_guideFindUniqueByShortId {
  __typename: "Guide";
  id: string;
  atsUrl: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleGuideTemplateId: string | null;
  isDemo: boolean;
  currentStage: GuideForShortIdPrisma_guideFindUniqueByShortId_currentStage | null;
  candidate: GuideForShortIdPrisma_guideFindUniqueByShortId_candidate;
  sortedVisibleStages: GuideForShortIdPrisma_guideFindUniqueByShortId_sortedVisibleStages[];
  guideVersion: number;
  jobRoleGuideTemplate: GuideForShortIdPrisma_guideFindUniqueByShortId_jobRoleGuideTemplate | null;
}

export interface GuideForShortIdPrisma {
  guideFindUniqueByShortId: GuideForShortIdPrisma_guideFindUniqueByShortId | null;
}

export interface GuideForShortIdPrismaVariables {
  customerSlug: string;
  guideShortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProfileForAccountPage
// ====================================================

export interface GetProfileForAccountPage_currentUserPrisma_currentUserMembership_personalProfile {
  __typename: "Interviewer";
  id: string;
  email: string | null;
  name: string;
  title: string;
  displayTitle: string;
  biography: string;
  pronoun: string | null;
  websiteUrls: string[];
  imageUrl: string;
  videoIntroductionUrl: string | null;
}

export interface GetProfileForAccountPage_currentUserPrisma_currentUserMembership {
  __typename: "UserMembership";
  id: string;
  personalProfile: GetProfileForAccountPage_currentUserPrisma_currentUserMembership_personalProfile;
}

export interface GetProfileForAccountPage_currentUserPrisma {
  __typename: "User";
  id: string;
  currentUserMembership: GetProfileForAccountPage_currentUserPrisma_currentUserMembership | null;
}

export interface GetProfileForAccountPage {
  currentUserPrisma: GetProfileForAccountPage_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MyAccountSettingsQuery
// ====================================================

export interface MyAccountSettingsQuery_currentUserPrisma_connectedAccounts {
  __typename: "ConnectedAccount";
  id: string;
  email: string;
  type: ConnectedAccountTypeEnum;
}

export interface MyAccountSettingsQuery_currentUserPrisma {
  __typename: "User";
  id: string;
  primaryEmail: string | null;
  connectedAccounts: MyAccountSettingsQuery_currentUserPrisma_connectedAccounts[];
}

export interface MyAccountSettingsQuery_teammates_personalProfile {
  __typename: "Interviewer";
  name: string;
  email: string | null;
}

export interface MyAccountSettingsQuery_teammates {
  __typename: "Teammate";
  id: string;
  personalProfile: MyAccountSettingsQuery_teammates_personalProfile | null;
}

export interface MyAccountSettingsQuery {
  currentUserPrisma: MyAccountSettingsQuery_currentUserPrisma | null;
  teammates: MyAccountSettingsQuery_teammates[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchBookingData
// ====================================================

export interface FetchBookingData_schedulerAvailabilityFindUnique_userMembership_organization_theme {
  __typename: "Theme";
  primaryColor: string;
}

export interface FetchBookingData_schedulerAvailabilityFindUnique_userMembership_organization {
  __typename: "Organization";
  id: string;
  theme: FetchBookingData_schedulerAvailabilityFindUnique_userMembership_organization_theme | null;
  name: string;
  companyLogoUrl: string;
}

export interface FetchBookingData_schedulerAvailabilityFindUnique_userMembership_user {
  __typename: "User";
  id: string;
  fullName: string;
  imageUrl: string;
}

export interface FetchBookingData_schedulerAvailabilityFindUnique_userMembership {
  __typename: "UserMembership";
  id: string;
  organization: FetchBookingData_schedulerAvailabilityFindUnique_userMembership_organization;
  user: FetchBookingData_schedulerAvailabilityFindUnique_userMembership_user;
}

export interface FetchBookingData_schedulerAvailabilityFindUnique {
  __typename: "SchedulerAvailability";
  id: string;
  userMembership: FetchBookingData_schedulerAvailabilityFindUnique_userMembership;
}

export interface FetchBookingData {
  schedulerAvailabilityFindUnique: FetchBookingData_schedulerAvailabilityFindUnique;
}

export interface FetchBookingDataVariables {
  where: SchedulerAvailabilityWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MergeApplicationRaw
// ====================================================

export interface MergeApplicationRaw_mergeApplicationRaw {
  __typename: "MergeApplicationRaw";
  rawJson: GraphQL_JSON;
}

export interface MergeApplicationRaw {
  mergeApplicationRaw: MergeApplicationRaw_mergeApplicationRaw | null;
}

export interface MergeApplicationRawVariables {
  input: MergeApplicationRawInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContentEditorDevQuery
// ====================================================

export interface ContentEditorDevQuery_guideFindUnique_currentStage {
  __typename: "Stage";
  id: string;
}

export interface ContentEditorDevQuery_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplate_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface ContentEditorDevQuery_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplate_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface ContentEditorDevQuery_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplate_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: ContentEditorDevQuery_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplate_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: ContentEditorDevQuery_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplate_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface ContentEditorDevQuery_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  postTemplateSyncData: ContentEditorDevQuery_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplate_postTemplateSyncData | null;
}

export interface ContentEditorDevQuery_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  postTemplate: ContentEditorDevQuery_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplate | null;
}

export interface ContentEditorDevQuery_guideFindUnique_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  title: string;
  position: number;
  jobRoleGuideTemplateStage: ContentEditorDevQuery_guideFindUnique_sortedVisibleStages_jobRoleGuideTemplateStage | null;
}

export interface ContentEditorDevQuery_guideFindUnique_guideLexicalContext_recruiterName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface ContentEditorDevQuery_guideFindUnique_guideLexicalContext_candidateName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface ContentEditorDevQuery_guideFindUnique_guideLexicalContext_coordinatorName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface ContentEditorDevQuery_guideFindUnique_guideLexicalContext {
  __typename: "GuideLexicalContext";
  id: GraphQL_UUID;
  href: string;
  recruiterName: ContentEditorDevQuery_guideFindUnique_guideLexicalContext_recruiterName;
  candidateName: ContentEditorDevQuery_guideFindUnique_guideLexicalContext_candidateName;
  candidatePhone: string | null;
  coordinatorName: ContentEditorDevQuery_guideFindUnique_guideLexicalContext_coordinatorName | null;
  jobTitle: string;
  jobListingUrl: string | null;
  companyName: string;
}

export interface ContentEditorDevQuery_guideFindUnique_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
  companyLogoUrl: string;
  defaultAvatarImageUrl: string;
}

export interface ContentEditorDevQuery_guideFindUnique_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  email: string;
  imageUrl: string;
  emailAddresses: string[];
  organization: ContentEditorDevQuery_guideFindUnique_candidate_organization;
}

export interface ContentEditorDevQuery_guideFindUnique {
  __typename: "Guide";
  id: string;
  currentStage: ContentEditorDevQuery_guideFindUnique_currentStage | null;
  sortedVisibleStages: ContentEditorDevQuery_guideFindUnique_sortedVisibleStages[];
  guideLexicalContext: ContentEditorDevQuery_guideFindUnique_guideLexicalContext;
  candidate: ContentEditorDevQuery_guideFindUnique_candidate;
}

export interface ContentEditorDevQuery {
  guideFindUnique: ContentEditorDevQuery_guideFindUnique;
}

export interface ContentEditorDevQueryVariables {
  guideId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchStageOverviewForExtension
// ====================================================

export interface FetchStageOverviewForExtension_stageFindUnique_guide_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  defaultGuideVersion: number;
}

export interface FetchStageOverviewForExtension_stageFindUnique_guide_stages {
  __typename: "Stage";
  id: string;
  hidden: boolean;
  status: StageStatus;
  configured: boolean;
}

export interface FetchStageOverviewForExtension_stageFindUnique_guide_candidate_organization_customer_atsIntegration {
  __typename: "AtsIntegration";
  id: string;
  atsType: string | null;
  displayName: string | null;
}

export interface FetchStageOverviewForExtension_stageFindUnique_guide_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  atsIntegration: FetchStageOverviewForExtension_stageFindUnique_guide_candidate_organization_customer_atsIntegration | null;
}

export interface FetchStageOverviewForExtension_stageFindUnique_guide_candidate_organization_interviewers {
  __typename: "Interviewer";
  id: string;
  name: string;
  email: string | null;
  displayImageUrl: string;
}

export interface FetchStageOverviewForExtension_stageFindUnique_guide_candidate_organization {
  __typename: "Organization";
  id: string;
  customer: FetchStageOverviewForExtension_stageFindUnique_guide_candidate_organization_customer;
  customVariableOptions: string[];
  interviewers: FetchStageOverviewForExtension_stageFindUnique_guide_candidate_organization_interviewers[];
}

export interface FetchStageOverviewForExtension_stageFindUnique_guide_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
  organization: FetchStageOverviewForExtension_stageFindUnique_guide_candidate_organization;
  currentTimezone: string;
}

export interface FetchStageOverviewForExtension_stageFindUnique_guide_nextStage {
  __typename: "Stage";
  id: string;
}

export interface FetchStageOverviewForExtension_stageFindUnique_guide_currentStage {
  __typename: "Stage";
  id: string;
}

export interface FetchStageOverviewForExtension_stageFindUnique_guide {
  __typename: "Guide";
  id: string;
  mergesyncApplicationId: string | null;
  jobRoleGuideTemplate: FetchStageOverviewForExtension_stageFindUnique_guide_jobRoleGuideTemplate | null;
  guideVersion: number;
  status: GuideStatusEnum;
  stages: FetchStageOverviewForExtension_stageFindUnique_guide_stages[];
  candidate: FetchStageOverviewForExtension_stageFindUnique_guide_candidate;
  publicUrl: string;
  stageSyncStatus: StageSyncStatus;
  nextStage: FetchStageOverviewForExtension_stageFindUnique_guide_nextStage | null;
  currentStage: FetchStageOverviewForExtension_stageFindUnique_guide_currentStage | null;
}

export interface FetchStageOverviewForExtension_stageFindUnique_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface FetchStageOverviewForExtension_stageFindUnique_customHiringTeam {
  __typename: "Interviewer";
  id: string;
  name: string;
  email: string | null;
}

export interface FetchStageOverviewForExtension_stageFindUnique_interviews_event_interviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  title: string;
  imageUrl: string;
}

export interface FetchStageOverviewForExtension_stageFindUnique_interviews_event_interviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
  personalProfile: FetchStageOverviewForExtension_stageFindUnique_interviews_event_interviewParticipants_EventInterviewer_personalProfile;
}

export interface FetchStageOverviewForExtension_stageFindUnique_interviews_event_interviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export type FetchStageOverviewForExtension_stageFindUnique_interviews_event_interviewParticipants = FetchStageOverviewForExtension_stageFindUnique_interviews_event_interviewParticipants_EventInterviewer | FetchStageOverviewForExtension_stageFindUnique_interviews_event_interviewParticipants_Candidate;

export interface FetchStageOverviewForExtension_stageFindUnique_interviews_event {
  __typename: "Event";
  id: string;
  hidden: boolean;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  displayTitle: string;
  interviewParticipants: FetchStageOverviewForExtension_stageFindUnique_interviews_event_interviewParticipants[];
  rawTitle: string;
  replacementData: GraphQL_JSON;
  conferenceUrl: string | null;
}

export interface FetchStageOverviewForExtension_stageFindUnique_interviews_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
}

export interface FetchStageOverviewForExtension_stageFindUnique_interviews_schedulerConfig_availability {
  __typename: "SchedulerAvailability";
  id: string;
  slug: string;
}

export interface FetchStageOverviewForExtension_stageFindUnique_interviews_schedulerConfig {
  __typename: "EventTemplateInstallationSchedulerConfig";
  availability: FetchStageOverviewForExtension_stageFindUnique_interviews_schedulerConfig_availability;
}

export interface FetchStageOverviewForExtension_stageFindUnique_interviews_defaultInterviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface FetchStageOverviewForExtension_stageFindUnique_interviews_defaultInterviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  imageUrl: string;
}

export interface FetchStageOverviewForExtension_stageFindUnique_interviews_defaultInterviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  personalProfile: FetchStageOverviewForExtension_stageFindUnique_interviews_defaultInterviewParticipants_EventInterviewer_personalProfile;
}

export type FetchStageOverviewForExtension_stageFindUnique_interviews_defaultInterviewParticipants = FetchStageOverviewForExtension_stageFindUnique_interviews_defaultInterviewParticipants_Candidate | FetchStageOverviewForExtension_stageFindUnique_interviews_defaultInterviewParticipants_EventInterviewer;

export interface FetchStageOverviewForExtension_stageFindUnique_interviews {
  __typename: "EventTemplateInstallation";
  id: string;
  event: FetchStageOverviewForExtension_stageFindUnique_interviews_event | null;
  title: string;
  description: string;
  hidden: boolean;
  replacementData: GraphQL_JSON;
  eventTemplate: FetchStageOverviewForExtension_stageFindUnique_interviews_eventTemplate;
  schedulerConfig: FetchStageOverviewForExtension_stageFindUnique_interviews_schedulerConfig | null;
  defaultInterviewParticipants: FetchStageOverviewForExtension_stageFindUnique_interviews_defaultInterviewParticipants[];
}

export interface FetchStageOverviewForExtension_stageFindUnique_emailActivities_sender {
  __typename: "User";
  id: string;
  imageUrl: string;
  fullName: string;
}

export interface FetchStageOverviewForExtension_stageFindUnique_emailActivities {
  __typename: "EmailActivity";
  id: string;
  createdAt: GraphQL_DateTime;
  sentTo: string;
  sentFrom: string;
  subject: string;
  body: string;
  sender: FetchStageOverviewForExtension_stageFindUnique_emailActivities_sender | null;
}

export interface FetchStageOverviewForExtension_stageFindUnique {
  __typename: "Stage";
  id: string;
  configured: boolean;
  guide: FetchStageOverviewForExtension_stageFindUnique_guide;
  hidden: boolean;
  status: StageStatus;
  stageTemplateInstallationId: string | null;
  position: number;
  title: string;
  isAtsCurrent: boolean;
  atsStage: FetchStageOverviewForExtension_stageFindUnique_atsStage | null;
  customVariables: GraphQL_JSON;
  customHiringTeam: FetchStageOverviewForExtension_stageFindUnique_customHiringTeam[];
  interviews: FetchStageOverviewForExtension_stageFindUnique_interviews[];
  emailActivities: FetchStageOverviewForExtension_stageFindUnique_emailActivities[];
}

export interface FetchStageOverviewForExtension {
  stageFindUnique: FetchStageOverviewForExtension_stageFindUnique;
}

export interface FetchStageOverviewForExtensionVariables {
  stageId: GraphQL_UUID;
  skipGuideHacks: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SyncCandidateApplicationInterviewsOnMerge
// ====================================================

export interface SyncCandidateApplicationInterviewsOnMerge_syncCandidateApplicationInterviewsOnMerge {
  __typename: "SyncCandidateApplicationInterviewsOnMerge";
  success: boolean;
  message: string;
}

export interface SyncCandidateApplicationInterviewsOnMerge {
  syncCandidateApplicationInterviewsOnMerge: SyncCandidateApplicationInterviewsOnMerge_syncCandidateApplicationInterviewsOnMerge | null;
}

export interface SyncCandidateApplicationInterviewsOnMergeVariables {
  input: SyncCandidateApplicationInterviewsInputPrismaProxy;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchStageSendDataForExtension
// ====================================================

export interface FetchStageSendDataForExtension_guideFindUnique_currentStage_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
  guideVersion: number;
  publicUrl: string;
}

export interface FetchStageSendDataForExtension_guideFindUnique_currentStage_stageTemplateInstallation_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
  isArchived: boolean;
}

export interface FetchStageSendDataForExtension_guideFindUnique_currentStage_stageTemplateInstallation {
  __typename: "StageTemplateInstallation";
  id: string;
  emailActionCC: string[];
  emailActionBCC: string[];
  emailTemplate: FetchStageSendDataForExtension_guideFindUnique_currentStage_stageTemplateInstallation_emailTemplate | null;
}

export interface FetchStageSendDataForExtension_guideFindUnique_currentStage_jobRoleGuideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
  isArchived: boolean;
}

export interface FetchStageSendDataForExtension_guideFindUnique_currentStage_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  emailRecipientsCC: string[];
  emailRecipientsBCC: string[];
  emailTemplate: FetchStageSendDataForExtension_guideFindUnique_currentStage_jobRoleGuideTemplateStage_emailTemplate | null;
}

export interface FetchStageSendDataForExtension_guideFindUnique_currentStage {
  __typename: "Stage";
  id: string;
  guide: FetchStageSendDataForExtension_guideFindUnique_currentStage_guide;
  status: StageStatus;
  stageTemplateInstallation: FetchStageSendDataForExtension_guideFindUnique_currentStage_stageTemplateInstallation | null;
  jobRoleGuideTemplateStage: FetchStageSendDataForExtension_guideFindUnique_currentStage_jobRoleGuideTemplateStage | null;
}

export interface FetchStageSendDataForExtension_guideFindUnique_mostRecentEmailActivity {
  __typename: "EmailActivity";
  id: string;
  sentTo: string;
}

export interface FetchStageSendDataForExtension_guideFindUnique_candidate_organization_recipients {
  __typename: "Recipient";
  id: GraphQL_UUID;
  name: string;
  email: string;
}

export interface FetchStageSendDataForExtension_guideFindUnique_candidate_organization {
  __typename: "Organization";
  id: string;
  customVariableOptions: string[];
  recipients: FetchStageSendDataForExtension_guideFindUnique_candidate_organization_recipients[];
}

export interface FetchStageSendDataForExtension_guideFindUnique_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  emailAddresses: string[];
  currentTimezone: string;
  organization: FetchStageSendDataForExtension_guideFindUnique_candidate_organization;
}

export interface FetchStageSendDataForExtension_guideFindUnique_sendGuideReplacementData_allInterviewers {
  __typename: "InterviewerReplacementData";
  title: string;
  firstName: string;
  lastName: string;
}

export interface FetchStageSendDataForExtension_guideFindUnique_sendGuideReplacementData_sortedVisibleUpcomingEvents_interviewers {
  __typename: "InterviewerReplacementData";
  title: string;
  firstName: string;
  lastName: string;
}

export interface FetchStageSendDataForExtension_guideFindUnique_sendGuideReplacementData_sortedVisibleUpcomingEvents {
  __typename: "EventReplacementData";
  startTime: string;
  endTime: string;
  title: string;
  hidden: boolean;
  interviewers: FetchStageSendDataForExtension_guideFindUnique_sendGuideReplacementData_sortedVisibleUpcomingEvents_interviewers[];
}

export interface FetchStageSendDataForExtension_guideFindUnique_sendGuideReplacementData {
  __typename: "SendGuideReplacementData";
  candidateFirstName: string;
  candidateFullName: string;
  companyName: string;
  jobRoleName: string;
  recruiterFirstName: string;
  recruiterFullName: string;
  recruiterEmailAddress: string;
  coordinatorFirstName: string;
  coordinatorFullName: string;
  coordinatorEmailAddress: string;
  guideUrl: string;
  office: string;
  greenhouseJobUrl: string;
  allInterviewers: FetchStageSendDataForExtension_guideFindUnique_sendGuideReplacementData_allInterviewers[];
  senderName: string;
  senderFirstName: string;
  sortedVisibleUpcomingEvents: FetchStageSendDataForExtension_guideFindUnique_sendGuideReplacementData_sortedVisibleUpcomingEvents[];
  stageCustomVariables: GraphQL_JSON;
  timezone: string;
}

export interface FetchStageSendDataForExtension_guideFindUnique_icalAttachedFiles {
  __typename: "AttachedFile";
  id: GraphQL_UUID;
  filename: string;
}

export interface FetchStageSendDataForExtension_guideFindUnique {
  __typename: "Guide";
  id: string;
  currentStage: FetchStageSendDataForExtension_guideFindUnique_currentStage | null;
  mostRecentEmailActivity: FetchStageSendDataForExtension_guideFindUnique_mostRecentEmailActivity[];
  candidate: FetchStageSendDataForExtension_guideFindUnique_candidate;
  sendGuideReplacementData: FetchStageSendDataForExtension_guideFindUnique_sendGuideReplacementData;
  status: GuideStatusEnum;
  guideVersion: number;
  publicUrl: string;
  icalAttachedFiles: FetchStageSendDataForExtension_guideFindUnique_icalAttachedFiles[] | null;
}

export interface FetchStageSendDataForExtension_currentUserPrisma {
  __typename: "User";
  id: string;
  primaryEmail: string | null;
  sendAsAddresses: string[];
}

export interface FetchStageSendDataForExtension {
  guideFindUnique: FetchStageSendDataForExtension_guideFindUnique;
  currentUserPrisma: FetchStageSendDataForExtension_currentUserPrisma | null;
}

export interface FetchStageSendDataForExtensionVariables {
  where: GuideWhereUniqueInput;
  stageId: GraphQL_UUID;
  sendTo?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendStageEmailForExtension
// ====================================================

export interface SendStageEmailForExtension_sendCandidateConfirmation_emailActivity_stage_emailActivities_sender {
  __typename: "User";
  id: string;
  imageUrl: string;
  fullName: string;
}

export interface SendStageEmailForExtension_sendCandidateConfirmation_emailActivity_stage_emailActivities {
  __typename: "EmailActivity";
  id: string;
  createdAt: GraphQL_DateTime;
  sentTo: string;
  sentFrom: string;
  subject: string;
  body: string;
  sender: SendStageEmailForExtension_sendCandidateConfirmation_emailActivity_stage_emailActivities_sender | null;
}

export interface SendStageEmailForExtension_sendCandidateConfirmation_emailActivity_stage {
  __typename: "Stage";
  id: string;
  emailActivities: SendStageEmailForExtension_sendCandidateConfirmation_emailActivity_stage_emailActivities[];
}

export interface SendStageEmailForExtension_sendCandidateConfirmation_emailActivity_guide_emailActivities_sender {
  __typename: "User";
  id: string;
  imageUrl: string;
  fullName: string;
}

export interface SendStageEmailForExtension_sendCandidateConfirmation_emailActivity_guide_emailActivities {
  __typename: "EmailActivity";
  id: string;
  createdAt: GraphQL_DateTime;
  sentTo: string;
  sentFrom: string;
  subject: string;
  body: string;
  sender: SendStageEmailForExtension_sendCandidateConfirmation_emailActivity_guide_emailActivities_sender | null;
}

export interface SendStageEmailForExtension_sendCandidateConfirmation_emailActivity_guide {
  __typename: "Guide";
  id: string;
  emailActivities: SendStageEmailForExtension_sendCandidateConfirmation_emailActivity_guide_emailActivities[];
}

export interface SendStageEmailForExtension_sendCandidateConfirmation_emailActivity {
  __typename: "EmailActivity";
  id: string;
  stage: SendStageEmailForExtension_sendCandidateConfirmation_emailActivity_stage | null;
  guide: SendStageEmailForExtension_sendCandidateConfirmation_emailActivity_guide | null;
}

export interface SendStageEmailForExtension_sendCandidateConfirmation {
  __typename: "SendCandidateConfirmationMutationResponse";
  success: boolean;
  message: string;
  code: string;
  emailActivity: SendStageEmailForExtension_sendCandidateConfirmation_emailActivity | null;
}

export interface SendStageEmailForExtension {
  sendCandidateConfirmation: SendStageEmailForExtension_sendCandidateConfirmation;
}

export interface SendStageEmailForExtensionVariables {
  input: SendCandidateConfirmationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendStageTestEmailFromSendForExtension
// ====================================================

export interface SendStageTestEmailFromSendForExtension_sendTestConfirmation {
  __typename: "SendTestConfirmationMutationResponse";
  success: boolean;
  message: string;
  code: string;
}

export interface SendStageTestEmailFromSendForExtension {
  sendTestConfirmation: SendStageTestEmailFromSendForExtension_sendTestConfirmation;
}

export interface SendStageTestEmailFromSendForExtensionVariables {
  input: SendTestConfirmationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchGuideForATSCandidateApplication
// ====================================================

export interface FetchGuideForATSCandidateApplication_guideFindUnique_jobRoleGuideTemplate_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  defaultGuideVersion: number;
  atsJob: FetchGuideForATSCandidateApplication_guideFindUnique_jobRoleGuideTemplate_atsJob | null;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_candidate_organization_customer_atsIntegration {
  __typename: "AtsIntegration";
  id: string;
  displayName: string | null;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  domains: string[];
  atsIntegration: FetchGuideForATSCandidateApplication_guideFindUnique_candidate_organization_customer_atsIntegration | null;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_candidate_organization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
  customer: FetchGuideForATSCandidateApplication_guideFindUnique_candidate_organization_customer;
  defaultAvatarImageUrl: string;
  theme: FetchGuideForATSCandidateApplication_guideFindUnique_candidate_organization_theme | null;
  guideDefaultBannerImageUrl: string;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
  organization: FetchGuideForATSCandidateApplication_guideFindUnique_candidate_organization;
  firstName: string;
  lastName: string;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_stages_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_stages_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_stages {
  __typename: "Stage";
  id: string;
  configured: boolean;
  title: string;
  status: StageStatus;
  isAtsCurrent: boolean;
  hidden: boolean;
  hasAutomation: boolean;
  position: number;
  atsStage: FetchGuideForATSCandidateApplication_guideFindUnique_stages_atsStage | null;
  guide: FetchGuideForATSCandidateApplication_guideFindUnique_stages_guide;
  stageTemplateInstallationId: string | null;
  description: string | null;
  needsFeedback: boolean;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  hasAutomation: boolean;
  configured: boolean;
  title: string;
  status: StageStatus;
  position: number;
  hidden: boolean;
  description: string | null;
  needsFeedback: boolean;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_defaultContent {
  __typename: "GuideContent";
  data: GraphQL_JSON;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_nextStage {
  __typename: "Stage";
  id: string;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_currentStage {
  __typename: "Stage";
  id: string;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_emailActivities_sender {
  __typename: "User";
  id: string;
  imageUrl: string;
  fullName: string;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_emailActivities {
  __typename: "EmailActivity";
  id: string;
  createdAt: GraphQL_DateTime;
  sentTo: string;
  sentFrom: string;
  subject: string;
  body: string;
  sender: FetchGuideForATSCandidateApplication_guideFindUnique_emailActivities_sender | null;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_hiringTeam_interviewer {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_hiringTeam {
  __typename: "HiringTeamMember";
  id: GraphQL_UUID;
  role: HiringTeamRoleEnum;
  isPointOfContact: boolean;
  hidden: boolean;
  interviewer: FetchGuideForATSCandidateApplication_guideFindUnique_hiringTeam_interviewer;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_posts_senderUser {
  __typename: "GuidePostUser";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  isCandidate: boolean;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_posts_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_posts_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_posts_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: FetchGuideForATSCandidateApplication_guideFindUnique_posts_replies_senderUser;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_posts {
  __typename: "GuidePost";
  id: string;
  title: string;
  createdAt: GraphQL_DateTime;
  senderUser: FetchGuideForATSCandidateApplication_guideFindUnique_posts_senderUser;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: FetchGuideForATSCandidateApplication_guideFindUnique_posts_lastCandidateEmailEvent | null;
  replies: FetchGuideForATSCandidateApplication_guideFindUnique_posts_replies[];
  readAt: GraphQL_DateTime | null;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_upcomingInterviewsV2_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_upcomingInterviewsV2_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_upcomingInterviewsV2_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique_upcomingInterviewsV2 {
  __typename: "Event";
  id: string;
  eventTemplateInstallationId: string | null;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: FetchGuideForATSCandidateApplication_guideFindUnique_upcomingInterviewsV2_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: FetchGuideForATSCandidateApplication_guideFindUnique_upcomingInterviewsV2_interviewers[];
  conferencePhone: string | null;
  location: FetchGuideForATSCandidateApplication_guideFindUnique_upcomingInterviewsV2_location | null;
}

export interface FetchGuideForATSCandidateApplication_guideFindUnique {
  __typename: "Guide";
  id: string;
  guideVersion: number;
  startedAt: GraphQL_DateTime | null;
  candidateOpens: string[];
  jobRoleGuideTemplate: FetchGuideForATSCandidateApplication_guideFindUnique_jobRoleGuideTemplate | null;
  candidate: FetchGuideForATSCandidateApplication_guideFindUnique_candidate;
  stages: FetchGuideForATSCandidateApplication_guideFindUnique_stages[];
  status: GuideStatusEnum;
  sortedVisibleStages: FetchGuideForATSCandidateApplication_guideFindUnique_sortedVisibleStages[];
  roleName: string;
  bannerImageUrl: string | null;
  defaultContent: FetchGuideForATSCandidateApplication_guideFindUnique_defaultContent | null;
  needsStageConfiguration: boolean;
  nextStage: FetchGuideForATSCandidateApplication_guideFindUnique_nextStage | null;
  stageSyncStatus: StageSyncStatus;
  currentStage: FetchGuideForATSCandidateApplication_guideFindUnique_currentStage | null;
  publicUrl: string;
  emailActivities: FetchGuideForATSCandidateApplication_guideFindUnique_emailActivities[];
  invitedAt: GraphQL_DateTime | null;
  inviteOpenedAt: GraphQL_DateTime | null;
  hiringTeam: FetchGuideForATSCandidateApplication_guideFindUnique_hiringTeam[];
  posts: FetchGuideForATSCandidateApplication_guideFindUnique_posts[];
  upcomingInterviewsV2: FetchGuideForATSCandidateApplication_guideFindUnique_upcomingInterviewsV2[];
  jobRoleGuideTemplateId: string | null;
  statusV2: GuideStatusV2Enum;
  hiringDecisionAt: GraphQL_DateTime | null;
}

export interface FetchGuideForATSCandidateApplication {
  guideFindUnique: FetchGuideForATSCandidateApplication_guideFindUnique;
}

export interface FetchGuideForATSCandidateApplicationVariables {
  where: GuideWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchAdvanceDataForExtension
// ====================================================

export interface FetchAdvanceDataForExtension_guideFindUnique_nextStage {
  __typename: "Stage";
  id: string;
  position: number;
}

export interface FetchAdvanceDataForExtension_guideFindUnique_stages_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
  guideVersion: number;
  publicUrl: string;
}

export interface FetchAdvanceDataForExtension_guideFindUnique_stages_stageTemplateInstallation_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
  isArchived: boolean;
}

export interface FetchAdvanceDataForExtension_guideFindUnique_stages_stageTemplateInstallation {
  __typename: "StageTemplateInstallation";
  id: string;
  emailActionCC: string[];
  emailActionBCC: string[];
  emailTemplate: FetchAdvanceDataForExtension_guideFindUnique_stages_stageTemplateInstallation_emailTemplate | null;
}

export interface FetchAdvanceDataForExtension_guideFindUnique_stages_jobRoleGuideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
  isArchived: boolean;
}

export interface FetchAdvanceDataForExtension_guideFindUnique_stages_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  emailRecipientsCC: string[];
  emailRecipientsBCC: string[];
  emailTemplate: FetchAdvanceDataForExtension_guideFindUnique_stages_jobRoleGuideTemplateStage_emailTemplate | null;
}

export interface FetchAdvanceDataForExtension_guideFindUnique_stages {
  __typename: "Stage";
  id: string;
  isAtsCurrent: boolean;
  status: StageStatus;
  position: number;
  guide: FetchAdvanceDataForExtension_guideFindUnique_stages_guide;
  stageTemplateInstallation: FetchAdvanceDataForExtension_guideFindUnique_stages_stageTemplateInstallation | null;
  jobRoleGuideTemplateStage: FetchAdvanceDataForExtension_guideFindUnique_stages_jobRoleGuideTemplateStage | null;
  title: string;
}

export interface FetchAdvanceDataForExtension_guideFindUnique_mostRecentEmailActivity {
  __typename: "EmailActivity";
  id: string;
  sentTo: string;
}

export interface FetchAdvanceDataForExtension_guideFindUnique_candidate_organization_recipients {
  __typename: "Recipient";
  id: GraphQL_UUID;
  name: string;
  email: string;
}

export interface FetchAdvanceDataForExtension_guideFindUnique_candidate_organization {
  __typename: "Organization";
  id: string;
  customVariableOptions: string[];
  recipients: FetchAdvanceDataForExtension_guideFindUnique_candidate_organization_recipients[];
}

export interface FetchAdvanceDataForExtension_guideFindUnique_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  emailAddresses: string[];
  currentTimezone: string;
  organization: FetchAdvanceDataForExtension_guideFindUnique_candidate_organization;
  imageUrl: string;
}

export interface FetchAdvanceDataForExtension_guideFindUnique_sendGuideReplacementData_allInterviewers {
  __typename: "InterviewerReplacementData";
  title: string;
  firstName: string;
  lastName: string;
}

export interface FetchAdvanceDataForExtension_guideFindUnique_sendGuideReplacementData_sortedVisibleUpcomingEvents_interviewers {
  __typename: "InterviewerReplacementData";
  title: string;
  firstName: string;
  lastName: string;
}

export interface FetchAdvanceDataForExtension_guideFindUnique_sendGuideReplacementData_sortedVisibleUpcomingEvents {
  __typename: "EventReplacementData";
  startTime: string;
  endTime: string;
  title: string;
  hidden: boolean;
  interviewers: FetchAdvanceDataForExtension_guideFindUnique_sendGuideReplacementData_sortedVisibleUpcomingEvents_interviewers[];
}

export interface FetchAdvanceDataForExtension_guideFindUnique_sendGuideReplacementData {
  __typename: "SendGuideReplacementData";
  candidateFirstName: string;
  candidateFullName: string;
  companyName: string;
  jobRoleName: string;
  recruiterFirstName: string;
  recruiterFullName: string;
  recruiterEmailAddress: string;
  coordinatorFirstName: string;
  coordinatorFullName: string;
  coordinatorEmailAddress: string;
  guideUrl: string;
  office: string;
  greenhouseJobUrl: string;
  allInterviewers: FetchAdvanceDataForExtension_guideFindUnique_sendGuideReplacementData_allInterviewers[];
  senderName: string;
  senderFirstName: string;
  sortedVisibleUpcomingEvents: FetchAdvanceDataForExtension_guideFindUnique_sendGuideReplacementData_sortedVisibleUpcomingEvents[];
  stageCustomVariables: GraphQL_JSON;
  timezone: string;
}

export interface FetchAdvanceDataForExtension_guideFindUnique_icalAttachedFiles {
  __typename: "AttachedFile";
  id: GraphQL_UUID;
  filename: string;
}

export interface FetchAdvanceDataForExtension_guideFindUnique_currentStage {
  __typename: "Stage";
  id: string;
  title: string;
  status: StageStatus;
}

export interface FetchAdvanceDataForExtension_guideFindUnique {
  __typename: "Guide";
  id: string;
  nextStage: FetchAdvanceDataForExtension_guideFindUnique_nextStage | null;
  stages: FetchAdvanceDataForExtension_guideFindUnique_stages[];
  mostRecentEmailActivity: FetchAdvanceDataForExtension_guideFindUnique_mostRecentEmailActivity[];
  candidate: FetchAdvanceDataForExtension_guideFindUnique_candidate;
  sendGuideReplacementData: FetchAdvanceDataForExtension_guideFindUnique_sendGuideReplacementData;
  status: GuideStatusEnum;
  guideVersion: number;
  publicUrl: string;
  icalAttachedFiles: FetchAdvanceDataForExtension_guideFindUnique_icalAttachedFiles[] | null;
  currentStage: FetchAdvanceDataForExtension_guideFindUnique_currentStage | null;
}

export interface FetchAdvanceDataForExtension_currentUserPrisma {
  __typename: "User";
  id: string;
  primaryEmail: string | null;
  sendAsAddresses: string[];
}

export interface FetchAdvanceDataForExtension {
  guideFindUnique: FetchAdvanceDataForExtension_guideFindUnique;
  currentUserPrisma: FetchAdvanceDataForExtension_currentUserPrisma | null;
}

export interface FetchAdvanceDataForExtensionVariables {
  where: GuideWhereUniqueInput;
  stageId?: GraphQL_UUID | null;
  sendTo?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MoveCandidateToStageForExtension
// ====================================================

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_jobRoleGuideTemplate_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  defaultGuideVersion: number;
  atsJob: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_jobRoleGuideTemplate_atsJob | null;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_stages_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_stages_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_stages {
  __typename: "Stage";
  id: string;
  hidden: boolean;
  status: StageStatus;
  configured: boolean;
  title: string;
  isAtsCurrent: boolean;
  hasAutomation: boolean;
  position: number;
  atsStage: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_stages_atsStage | null;
  guide: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_stages_guide;
  stageTemplateInstallationId: string | null;
  description: string | null;
  needsFeedback: boolean;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_candidate_organization_customer_atsIntegration {
  __typename: "AtsIntegration";
  id: string;
  atsType: string | null;
  displayName: string | null;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  atsIntegration: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_candidate_organization_customer_atsIntegration | null;
  domains: string[];
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_candidate_organization_interviewers {
  __typename: "Interviewer";
  id: string;
  name: string;
  email: string | null;
  displayImageUrl: string;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_candidate_organization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_candidate_organization {
  __typename: "Organization";
  id: string;
  customer: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_candidate_organization_customer;
  customVariableOptions: string[];
  interviewers: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_candidate_organization_interviewers[];
  name: string;
  defaultAvatarImageUrl: string;
  theme: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_candidate_organization_theme | null;
  guideDefaultBannerImageUrl: string;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
  organization: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_candidate_organization;
  currentTimezone: string;
  firstName: string;
  lastName: string;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_nextStage {
  __typename: "Stage";
  id: string;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_currentStage {
  __typename: "Stage";
  id: string;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  hasAutomation: boolean;
  configured: boolean;
  title: string;
  status: StageStatus;
  position: number;
  hidden: boolean;
  description: string | null;
  needsFeedback: boolean;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_defaultContent {
  __typename: "GuideContent";
  data: GraphQL_JSON;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_emailActivities_sender {
  __typename: "User";
  id: string;
  imageUrl: string;
  fullName: string;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_emailActivities {
  __typename: "EmailActivity";
  id: string;
  createdAt: GraphQL_DateTime;
  sentTo: string;
  sentFrom: string;
  subject: string;
  body: string;
  sender: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_emailActivities_sender | null;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_hiringTeam_interviewer {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_hiringTeam {
  __typename: "HiringTeamMember";
  id: GraphQL_UUID;
  role: HiringTeamRoleEnum;
  isPointOfContact: boolean;
  hidden: boolean;
  interviewer: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_hiringTeam_interviewer;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_posts_senderUser {
  __typename: "GuidePostUser";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  isCandidate: boolean;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_posts_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_posts_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_posts_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_posts_replies_senderUser;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_posts {
  __typename: "GuidePost";
  id: string;
  title: string;
  createdAt: GraphQL_DateTime;
  senderUser: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_posts_senderUser;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_posts_lastCandidateEmailEvent | null;
  replies: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_posts_replies[];
  readAt: GraphQL_DateTime | null;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_upcomingInterviewsV2_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_upcomingInterviewsV2_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_upcomingInterviewsV2_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_upcomingInterviewsV2 {
  __typename: "Event";
  id: string;
  eventTemplateInstallationId: string | null;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_upcomingInterviewsV2_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_upcomingInterviewsV2_interviewers[];
  conferencePhone: string | null;
  location: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_upcomingInterviewsV2_location | null;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide {
  __typename: "Guide";
  id: string;
  mergesyncApplicationId: string | null;
  jobRoleGuideTemplate: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_jobRoleGuideTemplate | null;
  guideVersion: number;
  status: GuideStatusEnum;
  stages: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_stages[];
  candidate: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_candidate;
  publicUrl: string;
  stageSyncStatus: StageSyncStatus;
  nextStage: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_nextStage | null;
  currentStage: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_currentStage | null;
  startedAt: GraphQL_DateTime | null;
  candidateOpens: string[];
  sortedVisibleStages: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_sortedVisibleStages[];
  roleName: string;
  bannerImageUrl: string | null;
  defaultContent: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_defaultContent | null;
  needsStageConfiguration: boolean;
  emailActivities: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_emailActivities[];
  invitedAt: GraphQL_DateTime | null;
  inviteOpenedAt: GraphQL_DateTime | null;
  hiringTeam: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_hiringTeam[];
  posts: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_posts[];
  upcomingInterviewsV2: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide_upcomingInterviewsV2[];
  jobRoleGuideTemplateId: string | null;
  statusV2: GuideStatusV2Enum;
  hiringDecisionAt: GraphQL_DateTime | null;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_customHiringTeam {
  __typename: "Interviewer";
  id: string;
  name: string;
  email: string | null;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_event_interviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  title: string;
  imageUrl: string;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_event_interviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
  personalProfile: MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_event_interviewParticipants_EventInterviewer_personalProfile;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_event_interviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export type MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_event_interviewParticipants = MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_event_interviewParticipants_EventInterviewer | MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_event_interviewParticipants_Candidate;

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_event {
  __typename: "Event";
  id: string;
  hidden: boolean;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  displayTitle: string;
  interviewParticipants: MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_event_interviewParticipants[];
  rawTitle: string;
  replacementData: GraphQL_JSON;
  conferenceUrl: string | null;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_schedulerConfig_availability {
  __typename: "SchedulerAvailability";
  id: string;
  slug: string;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_schedulerConfig {
  __typename: "EventTemplateInstallationSchedulerConfig";
  availability: MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_schedulerConfig_availability;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_defaultInterviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_defaultInterviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  imageUrl: string;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_defaultInterviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  personalProfile: MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_defaultInterviewParticipants_EventInterviewer_personalProfile;
}

export type MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_defaultInterviewParticipants = MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_defaultInterviewParticipants_Candidate | MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_defaultInterviewParticipants_EventInterviewer;

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews {
  __typename: "EventTemplateInstallation";
  id: string;
  event: MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_event | null;
  title: string;
  description: string;
  hidden: boolean;
  replacementData: GraphQL_JSON;
  eventTemplate: MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_eventTemplate;
  schedulerConfig: MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_schedulerConfig | null;
  defaultInterviewParticipants: MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews_defaultInterviewParticipants[];
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_emailActivities_sender {
  __typename: "User";
  id: string;
  imageUrl: string;
  fullName: string;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage_emailActivities {
  __typename: "EmailActivity";
  id: string;
  createdAt: GraphQL_DateTime;
  sentTo: string;
  sentFrom: string;
  subject: string;
  body: string;
  sender: MoveCandidateToStageForExtension_moveCandidateToStage_stage_emailActivities_sender | null;
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage_stage {
  __typename: "Stage";
  id: string;
  configured: boolean;
  guide: MoveCandidateToStageForExtension_moveCandidateToStage_stage_guide;
  hidden: boolean;
  status: StageStatus;
  stageTemplateInstallationId: string | null;
  position: number;
  title: string;
  isAtsCurrent: boolean;
  atsStage: MoveCandidateToStageForExtension_moveCandidateToStage_stage_atsStage | null;
  customVariables: GraphQL_JSON;
  customHiringTeam: MoveCandidateToStageForExtension_moveCandidateToStage_stage_customHiringTeam[];
  interviews: MoveCandidateToStageForExtension_moveCandidateToStage_stage_interviews[];
  emailActivities: MoveCandidateToStageForExtension_moveCandidateToStage_stage_emailActivities[];
}

export interface MoveCandidateToStageForExtension_moveCandidateToStage {
  __typename: "MoveCandidateToStageMutationResponse";
  success: boolean;
  message: string;
  code: string;
  stage: MoveCandidateToStageForExtension_moveCandidateToStage_stage | null;
}

export interface MoveCandidateToStageForExtension {
  moveCandidateToStage: MoveCandidateToStageForExtension_moveCandidateToStage;
}

export interface MoveCandidateToStageForExtensionVariables {
  input: MoveCandidateToStageInput;
  skipGuideHacks: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MoveCandidateToStageAndSendConfirmationForExtension
// ====================================================

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  defaultGuideVersion: number;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide_stages {
  __typename: "Stage";
  id: string;
  hidden: boolean;
  status: StageStatus;
  configured: boolean;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide_candidate_organization_customer_atsIntegration {
  __typename: "AtsIntegration";
  id: string;
  atsType: string | null;
  displayName: string | null;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  atsIntegration: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide_candidate_organization_customer_atsIntegration | null;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide_candidate_organization_interviewers {
  __typename: "Interviewer";
  id: string;
  name: string;
  email: string | null;
  displayImageUrl: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide_candidate_organization {
  __typename: "Organization";
  id: string;
  customer: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide_candidate_organization_customer;
  customVariableOptions: string[];
  interviewers: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide_candidate_organization_interviewers[];
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
  organization: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide_candidate_organization;
  currentTimezone: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide_nextStage {
  __typename: "Stage";
  id: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide_currentStage {
  __typename: "Stage";
  id: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide {
  __typename: "Guide";
  id: string;
  mergesyncApplicationId: string | null;
  jobRoleGuideTemplate: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide_jobRoleGuideTemplate | null;
  guideVersion: number;
  status: GuideStatusEnum;
  stages: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide_stages[];
  candidate: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide_candidate;
  publicUrl: string;
  stageSyncStatus: StageSyncStatus;
  nextStage: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide_nextStage | null;
  currentStage: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide_currentStage | null;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_customHiringTeam {
  __typename: "Interviewer";
  id: string;
  name: string;
  email: string | null;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_event_interviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  title: string;
  imageUrl: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_event_interviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
  personalProfile: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_event_interviewParticipants_EventInterviewer_personalProfile;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_event_interviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export type MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_event_interviewParticipants = MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_event_interviewParticipants_EventInterviewer | MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_event_interviewParticipants_Candidate;

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_event {
  __typename: "Event";
  id: string;
  hidden: boolean;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  displayTitle: string;
  interviewParticipants: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_event_interviewParticipants[];
  rawTitle: string;
  replacementData: GraphQL_JSON;
  conferenceUrl: string | null;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_schedulerConfig_availability {
  __typename: "SchedulerAvailability";
  id: string;
  slug: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_schedulerConfig {
  __typename: "EventTemplateInstallationSchedulerConfig";
  availability: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_schedulerConfig_availability;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_defaultInterviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_defaultInterviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  imageUrl: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_defaultInterviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  personalProfile: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_defaultInterviewParticipants_EventInterviewer_personalProfile;
}

export type MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_defaultInterviewParticipants = MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_defaultInterviewParticipants_Candidate | MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_defaultInterviewParticipants_EventInterviewer;

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews {
  __typename: "EventTemplateInstallation";
  id: string;
  event: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_event | null;
  title: string;
  description: string;
  hidden: boolean;
  replacementData: GraphQL_JSON;
  eventTemplate: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_eventTemplate;
  schedulerConfig: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_schedulerConfig | null;
  defaultInterviewParticipants: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews_defaultInterviewParticipants[];
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_emailActivities_sender {
  __typename: "User";
  id: string;
  imageUrl: string;
  fullName: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_emailActivities {
  __typename: "EmailActivity";
  id: string;
  createdAt: GraphQL_DateTime;
  sentTo: string;
  sentFrom: string;
  subject: string;
  body: string;
  sender: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_emailActivities_sender | null;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage {
  __typename: "Stage";
  id: string;
  configured: boolean;
  guide: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_guide;
  hidden: boolean;
  status: StageStatus;
  stageTemplateInstallationId: string | null;
  position: number;
  title: string;
  isAtsCurrent: boolean;
  atsStage: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_atsStage | null;
  customVariables: GraphQL_JSON;
  customHiringTeam: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_customHiringTeam[];
  interviews: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_interviews[];
  emailActivities: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage_emailActivities[];
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_jobRoleGuideTemplate_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  defaultGuideVersion: number;
  atsJob: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_jobRoleGuideTemplate_atsJob | null;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_candidate_organization_customer_atsIntegration {
  __typename: "AtsIntegration";
  id: string;
  displayName: string | null;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  domains: string[];
  atsIntegration: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_candidate_organization_customer_atsIntegration | null;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_candidate_organization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
  customer: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_candidate_organization_customer;
  defaultAvatarImageUrl: string;
  theme: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_candidate_organization_theme | null;
  guideDefaultBannerImageUrl: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
  organization: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_candidate_organization;
  firstName: string;
  lastName: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_stages_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_stages_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_stages {
  __typename: "Stage";
  id: string;
  configured: boolean;
  title: string;
  status: StageStatus;
  isAtsCurrent: boolean;
  hidden: boolean;
  hasAutomation: boolean;
  position: number;
  atsStage: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_stages_atsStage | null;
  guide: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_stages_guide;
  stageTemplateInstallationId: string | null;
  description: string | null;
  needsFeedback: boolean;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  hasAutomation: boolean;
  configured: boolean;
  title: string;
  status: StageStatus;
  position: number;
  hidden: boolean;
  description: string | null;
  needsFeedback: boolean;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_defaultContent {
  __typename: "GuideContent";
  data: GraphQL_JSON;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_nextStage {
  __typename: "Stage";
  id: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_currentStage {
  __typename: "Stage";
  id: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_emailActivities_sender {
  __typename: "User";
  id: string;
  imageUrl: string;
  fullName: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_emailActivities {
  __typename: "EmailActivity";
  id: string;
  createdAt: GraphQL_DateTime;
  sentTo: string;
  sentFrom: string;
  subject: string;
  body: string;
  sender: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_emailActivities_sender | null;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_hiringTeam_interviewer {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_hiringTeam {
  __typename: "HiringTeamMember";
  id: GraphQL_UUID;
  role: HiringTeamRoleEnum;
  isPointOfContact: boolean;
  hidden: boolean;
  interviewer: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_hiringTeam_interviewer;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_posts_senderUser {
  __typename: "GuidePostUser";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  isCandidate: boolean;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_posts_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_posts_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_posts_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_posts_replies_senderUser;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_posts {
  __typename: "GuidePost";
  id: string;
  title: string;
  createdAt: GraphQL_DateTime;
  senderUser: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_posts_senderUser;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_posts_lastCandidateEmailEvent | null;
  replies: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_posts_replies[];
  readAt: GraphQL_DateTime | null;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_upcomingInterviewsV2_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_upcomingInterviewsV2_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_upcomingInterviewsV2_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_upcomingInterviewsV2 {
  __typename: "Event";
  id: string;
  eventTemplateInstallationId: string | null;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_upcomingInterviewsV2_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_upcomingInterviewsV2_interviewers[];
  conferencePhone: string | null;
  location: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_upcomingInterviewsV2_location | null;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide {
  __typename: "Guide";
  id: string;
  guideVersion: number;
  startedAt: GraphQL_DateTime | null;
  candidateOpens: string[];
  jobRoleGuideTemplate: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_jobRoleGuideTemplate | null;
  candidate: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_candidate;
  stages: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_stages[];
  status: GuideStatusEnum;
  sortedVisibleStages: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_sortedVisibleStages[];
  roleName: string;
  bannerImageUrl: string | null;
  defaultContent: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_defaultContent | null;
  needsStageConfiguration: boolean;
  nextStage: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_nextStage | null;
  stageSyncStatus: StageSyncStatus;
  currentStage: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_currentStage | null;
  publicUrl: string;
  emailActivities: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_emailActivities[];
  invitedAt: GraphQL_DateTime | null;
  inviteOpenedAt: GraphQL_DateTime | null;
  hiringTeam: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_hiringTeam[];
  posts: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_posts[];
  upcomingInterviewsV2: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide_upcomingInterviewsV2[];
  jobRoleGuideTemplateId: string | null;
  statusV2: GuideStatusV2Enum;
  hiringDecisionAt: GraphQL_DateTime | null;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity {
  __typename: "EmailActivity";
  id: string;
  messageId: string;
  stage: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_stage | null;
  guide: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity_guide | null;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation {
  __typename: "MoveCandidateToStageAndSendConfirmationMutationResponse";
  success: boolean;
  message: string;
  code: string;
  emailActivity: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation_emailActivity | null;
}

export interface MoveCandidateToStageAndSendConfirmationForExtension {
  moveCandidateToStageAndSendConfirmation: MoveCandidateToStageAndSendConfirmationForExtension_moveCandidateToStageAndSendConfirmation;
}

export interface MoveCandidateToStageAndSendConfirmationForExtensionVariables {
  input: MoveCandidateToStageWithConfirmationInput;
  skipGuideHacks: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendTestEmailFromAdvanceForExtension
// ====================================================

export interface SendTestEmailFromAdvanceForExtension_sendTestConfirmation {
  __typename: "SendTestConfirmationMutationResponse";
  success: boolean;
  message: string;
}

export interface SendTestEmailFromAdvanceForExtension {
  sendTestConfirmation: SendTestEmailFromAdvanceForExtension_sendTestConfirmation;
}

export interface SendTestEmailFromAdvanceForExtensionVariables {
  input: SendTestConfirmationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchSendDataForExtension
// ====================================================

export interface FetchSendDataForExtension_guideFindUnique_currentStage_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
  guideVersion: number;
  publicUrl: string;
}

export interface FetchSendDataForExtension_guideFindUnique_currentStage_stageTemplateInstallation_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
  isArchived: boolean;
}

export interface FetchSendDataForExtension_guideFindUnique_currentStage_stageTemplateInstallation {
  __typename: "StageTemplateInstallation";
  id: string;
  emailActionCC: string[];
  emailActionBCC: string[];
  emailTemplate: FetchSendDataForExtension_guideFindUnique_currentStage_stageTemplateInstallation_emailTemplate | null;
}

export interface FetchSendDataForExtension_guideFindUnique_currentStage_jobRoleGuideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
  isArchived: boolean;
}

export interface FetchSendDataForExtension_guideFindUnique_currentStage_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  emailRecipientsCC: string[];
  emailRecipientsBCC: string[];
  emailTemplate: FetchSendDataForExtension_guideFindUnique_currentStage_jobRoleGuideTemplateStage_emailTemplate | null;
}

export interface FetchSendDataForExtension_guideFindUnique_currentStage {
  __typename: "Stage";
  id: string;
  guide: FetchSendDataForExtension_guideFindUnique_currentStage_guide;
  status: StageStatus;
  stageTemplateInstallation: FetchSendDataForExtension_guideFindUnique_currentStage_stageTemplateInstallation | null;
  jobRoleGuideTemplateStage: FetchSendDataForExtension_guideFindUnique_currentStage_jobRoleGuideTemplateStage | null;
}

export interface FetchSendDataForExtension_guideFindUnique_mostRecentEmailActivity {
  __typename: "EmailActivity";
  id: string;
  sentTo: string;
}

export interface FetchSendDataForExtension_guideFindUnique_candidate_organization_recipients {
  __typename: "Recipient";
  id: GraphQL_UUID;
  name: string;
  email: string;
}

export interface FetchSendDataForExtension_guideFindUnique_candidate_organization {
  __typename: "Organization";
  id: string;
  customVariableOptions: string[];
  recipients: FetchSendDataForExtension_guideFindUnique_candidate_organization_recipients[];
}

export interface FetchSendDataForExtension_guideFindUnique_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  emailAddresses: string[];
  currentTimezone: string;
  organization: FetchSendDataForExtension_guideFindUnique_candidate_organization;
}

export interface FetchSendDataForExtension_guideFindUnique_sendGuideReplacementData_allInterviewers {
  __typename: "InterviewerReplacementData";
  title: string;
  firstName: string;
  lastName: string;
}

export interface FetchSendDataForExtension_guideFindUnique_sendGuideReplacementData_sortedVisibleUpcomingEvents_interviewers {
  __typename: "InterviewerReplacementData";
  title: string;
  firstName: string;
  lastName: string;
}

export interface FetchSendDataForExtension_guideFindUnique_sendGuideReplacementData_sortedVisibleUpcomingEvents {
  __typename: "EventReplacementData";
  startTime: string;
  endTime: string;
  title: string;
  hidden: boolean;
  interviewers: FetchSendDataForExtension_guideFindUnique_sendGuideReplacementData_sortedVisibleUpcomingEvents_interviewers[];
}

export interface FetchSendDataForExtension_guideFindUnique_sendGuideReplacementData {
  __typename: "SendGuideReplacementData";
  candidateFirstName: string;
  candidateFullName: string;
  companyName: string;
  jobRoleName: string;
  recruiterFirstName: string;
  recruiterFullName: string;
  recruiterEmailAddress: string;
  coordinatorFirstName: string;
  coordinatorFullName: string;
  coordinatorEmailAddress: string;
  guideUrl: string;
  office: string;
  greenhouseJobUrl: string;
  allInterviewers: FetchSendDataForExtension_guideFindUnique_sendGuideReplacementData_allInterviewers[];
  senderName: string;
  senderFirstName: string;
  sortedVisibleUpcomingEvents: FetchSendDataForExtension_guideFindUnique_sendGuideReplacementData_sortedVisibleUpcomingEvents[];
  stageCustomVariables: GraphQL_JSON;
  timezone: string;
}

export interface FetchSendDataForExtension_guideFindUnique_icalAttachedFiles {
  __typename: "AttachedFile";
  id: GraphQL_UUID;
  filename: string;
}

export interface FetchSendDataForExtension_guideFindUnique {
  __typename: "Guide";
  id: string;
  currentStage: FetchSendDataForExtension_guideFindUnique_currentStage | null;
  mostRecentEmailActivity: FetchSendDataForExtension_guideFindUnique_mostRecentEmailActivity[];
  candidate: FetchSendDataForExtension_guideFindUnique_candidate;
  sendGuideReplacementData: FetchSendDataForExtension_guideFindUnique_sendGuideReplacementData;
  status: GuideStatusEnum;
  guideVersion: number;
  publicUrl: string;
  icalAttachedFiles: FetchSendDataForExtension_guideFindUnique_icalAttachedFiles[] | null;
}

export interface FetchSendDataForExtension_currentUserPrisma {
  __typename: "User";
  id: string;
  primaryEmail: string | null;
  sendAsAddresses: string[];
}

export interface FetchSendDataForExtension {
  guideFindUnique: FetchSendDataForExtension_guideFindUnique;
  currentUserPrisma: FetchSendDataForExtension_currentUserPrisma | null;
}

export interface FetchSendDataForExtensionVariables {
  where: GuideWhereUniqueInput;
  stageId?: GraphQL_UUID | null;
  sendTo?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrgForExtension
// ====================================================

export interface OrgForExtension_currentUserPrisma_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface OrgForExtension_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  name: string;
  defaultAvatarImageUrl: string;
  theme: OrgForExtension_currentUserPrisma_currentOrganization_theme | null;
}

export interface OrgForExtension_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: OrgForExtension_currentUserPrisma_currentOrganization | null;
}

export interface OrgForExtension {
  currentUserPrisma: OrgForExtension_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchGuideForGreenhouseExtension
// ====================================================

export interface FetchGuideForGreenhouseExtension_guideFindUniqueByGreenhouse_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  name: string;
}

export interface FetchGuideForGreenhouseExtension_guideFindUniqueByGreenhouse_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  defaultGuideVersion: number;
}

export interface FetchGuideForGreenhouseExtension_guideFindUniqueByGreenhouse {
  __typename: "Guide";
  id: string;
  guideVersion: number;
  status: GuideStatusEnum;
  candidate: FetchGuideForGreenhouseExtension_guideFindUniqueByGreenhouse_candidate;
  jobRoleGuideTemplate: FetchGuideForGreenhouseExtension_guideFindUniqueByGreenhouse_jobRoleGuideTemplate | null;
}

export interface FetchGuideForGreenhouseExtension {
  guideFindUniqueByGreenhouse: FetchGuideForGreenhouseExtension_guideFindUniqueByGreenhouse | null;
}

export interface FetchGuideForGreenhouseExtensionVariables {
  where: GuideApplicationCandidateWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchGuideV2ForGreenhouseExtension
// ====================================================

export interface FetchGuideV2ForGreenhouseExtension_guideByAtssyncGreenhouseApplicationId {
  __typename: "GuideV2";
  id: string;
}

export interface FetchGuideV2ForGreenhouseExtension {
  guideByAtssyncGreenhouseApplicationId: FetchGuideV2ForGreenhouseExtension_guideByAtssyncGreenhouseApplicationId | null;
}

export interface FetchGuideV2ForGreenhouseExtensionVariables {
  atsApplicationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FixZeusGuideMutation
// ====================================================

export interface FixZeusGuideMutation_fixZeusGuide_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
  publicUrl: string;
  guideVersion: number;
}

export interface FixZeusGuideMutation_fixZeusGuide {
  __typename: "FixZeusGuideResponse";
  success: boolean;
  message: string;
  guide: FixZeusGuideMutation_fixZeusGuide_guide;
}

export interface FixZeusGuideMutation {
  fixZeusGuide: FixZeusGuideMutation_fixZeusGuide;
}

export interface FixZeusGuideMutationVariables {
  input: FixZeusGuideInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchGuideForGreenhouseCandidate
// ====================================================

export interface FetchGuideForGreenhouseCandidate_guideFindUniqueByGreenhouse {
  __typename: "Guide";
  id: string;
}

export interface FetchGuideForGreenhouseCandidate {
  guideFindUniqueByGreenhouse: FetchGuideForGreenhouseCandidate_guideFindUniqueByGreenhouse | null;
}

export interface FetchGuideForGreenhouseCandidateVariables {
  where: GuideApplicationCandidateWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SyncGuideForGreenhouseCandidate
// ====================================================

export interface SyncGuideForGreenhouseCandidate_syncGuideWithGreenhouseApplication_guide {
  __typename: "Guide";
  id: string;
}

export interface SyncGuideForGreenhouseCandidate_syncGuideWithGreenhouseApplication {
  __typename: "GreenhouseSyncApplicationMutationResponse";
  code: string;
  success: boolean;
  guide: SyncGuideForGreenhouseCandidate_syncGuideWithGreenhouseApplication_guide | null;
}

export interface SyncGuideForGreenhouseCandidate {
  syncGuideWithGreenhouseApplication: SyncGuideForGreenhouseCandidate_syncGuideWithGreenhouseApplication;
}

export interface SyncGuideForGreenhouseCandidateVariables {
  where: GuideApplicationCandidateWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuidesForJobRoleGuideTemplateCandidatesPaginated
// ====================================================

export interface GuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization_jobRoleGuideTemplateById_atsJob {
  __typename: "ATSJobRole";
  id: string;
  candidatesUrl: string;
}

export interface GuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  setupStatus: JobRoleGuideTemplateSetupStatus;
  atsJob: GuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization_jobRoleGuideTemplateById_atsJob | null;
}

export interface GuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization_guidesV2_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  emailAddresses: string[];
}

export interface GuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization_guidesV2_upcomingInterviews {
  __typename: "Event";
  id: string;
  displayTitle: string;
  replacementData: GraphQL_JSON;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
}

export interface GuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization_guidesV2_currentStage {
  __typename: "Stage";
  id: string;
  title: string;
  position: number;
}

export interface GuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization_guidesV2_currentAtsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface GuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization_guidesV2 {
  __typename: "Guide";
  id: string;
  atsUrl: string;
  publicUrl: string;
  stageCount: number;
  currentStagePosition: number | null;
  status: GuideStatusEnum;
  candidate: GuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization_guidesV2_candidate;
  upcomingInterviews: GuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization_guidesV2_upcomingInterviews[];
  currentStage: GuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization_guidesV2_currentStage | null;
  currentAtsStage: GuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization_guidesV2_currentAtsStage | null;
}

export interface GuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplateById: GuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization_jobRoleGuideTemplateById;
  guidesV2: GuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization_guidesV2[];
}

export interface GuidesForJobRoleGuideTemplateCandidatesPaginated {
  currentOrganization: GuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization | null;
}

export interface GuidesForJobRoleGuideTemplateCandidatesPaginatedVariables {
  search?: string | null;
  limit: number;
  offset: number;
  orderBy?: OrganizationGuidesOrderByInput[] | null;
  jobRoleGuideTemplateId: string;
  statusList?: GuideStatusEnum[] | null;
  atsApplicationStatus?: OrganizationGuidesAtsApplicationStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllGuidesForJobRoleGuideTemplateCandidatesPaginated
// ====================================================

export interface AllGuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization_guidesV2 {
  __typename: "Guide";
  id: string;
}

export interface AllGuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization {
  __typename: "Organization";
  id: string;
  guidesV2: AllGuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization_guidesV2[];
}

export interface AllGuidesForJobRoleGuideTemplateCandidatesPaginated {
  currentOrganization: AllGuidesForJobRoleGuideTemplateCandidatesPaginated_currentOrganization | null;
}

export interface AllGuidesForJobRoleGuideTemplateCandidatesPaginatedVariables {
  search?: string | null;
  jobRoleGuideTemplateId: string;
  statusList?: GuideStatusEnum[] | null;
  atsApplicationStatus?: OrganizationGuidesAtsApplicationStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobInterviewsForJobRoleGuideTemplate
// ====================================================

export interface JobInterviewsForJobRoleGuideTemplate_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

export interface JobInterviewsForJobRoleGuideTemplate_currentOrganization_jobRoleGuideTemplateById_eventTemplates_interviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  title: GraphQL_JSON | null;
  description: GraphQL_JSON | null;
}

export interface JobInterviewsForJobRoleGuideTemplate_currentOrganization_jobRoleGuideTemplateById_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  position: number;
  description: string;
  hidden: boolean;
  atsEventTemplateId: string;
  atsEventTemplateName: string;
  atssyncGreenhouseJobStage: JobInterviewsForJobRoleGuideTemplate_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseJobStage | null;
  interviewTemplate: JobInterviewsForJobRoleGuideTemplate_currentOrganization_jobRoleGuideTemplateById_eventTemplates_interviewTemplate | null;
}

export interface JobInterviewsForJobRoleGuideTemplate_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_eventTemplates_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

export interface JobInterviewsForJobRoleGuideTemplate_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_eventTemplates_interviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  title: GraphQL_JSON | null;
  description: GraphQL_JSON | null;
}

export interface JobInterviewsForJobRoleGuideTemplate_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  position: number;
  description: string;
  hidden: boolean;
  atsEventTemplateId: string;
  atsEventTemplateName: string;
  atssyncGreenhouseJobStage: JobInterviewsForJobRoleGuideTemplate_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_eventTemplates_atssyncGreenhouseJobStage | null;
  interviewTemplate: JobInterviewsForJobRoleGuideTemplate_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_eventTemplates_interviewTemplate | null;
}

export interface JobInterviewsForJobRoleGuideTemplate_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations {
  __typename: "StageTemplateInstallation";
  id: string;
  atsStageName: string;
  activatedAt: GraphQL_DateTime | null;
  hidden: boolean;
  position: number;
  eventTemplates: JobInterviewsForJobRoleGuideTemplate_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations_eventTemplates[];
}

export interface JobInterviewsForJobRoleGuideTemplate_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  eventTemplates: JobInterviewsForJobRoleGuideTemplate_currentOrganization_jobRoleGuideTemplateById_eventTemplates[];
  stageTemplateInstallations: JobInterviewsForJobRoleGuideTemplate_currentOrganization_jobRoleGuideTemplateById_stageTemplateInstallations[];
}

export interface JobInterviewsForJobRoleGuideTemplate_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplateById: JobInterviewsForJobRoleGuideTemplate_currentOrganization_jobRoleGuideTemplateById;
}

export interface JobInterviewsForJobRoleGuideTemplate {
  currentOrganization: JobInterviewsForJobRoleGuideTemplate_currentOrganization | null;
}

export interface JobInterviewsForJobRoleGuideTemplateVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobRoleGuideTemplateForOptions
// ====================================================

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_changes_change = GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_changes_change_BooleanFieldChange | GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_changes_change_StringFieldChange | GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_changes_change_StringArrayFieldChange;

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_changes {
  __typename: "GuidePropagationFieldChange";
  field: GuidePropagationChangesEnum;
  change: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_changes_change;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedAddedData {
  __typename: "CollectionFieldChangedAddedData";
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedRemovedData {
  __typename: "CollectionFieldChangedRemovedData";
  parentId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedReorderedData {
  __typename: "CollectionFieldChangedReorderedData";
  parentId: string | null;
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export type GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data = GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedAddedData | GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedRemovedData | GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedReorderedData;

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_collectionChanges {
  __typename: "GuideCollectionPropagationFieldChange";
  type: GuideCollectionPropagationChangesTypeEnum;
  data: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate | null;
  schedulerAvailability: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability | null;
  configInterviewers: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers[];
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change = GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange | GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringFieldChange | GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange;

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate | null;
  emailTemplate: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_emailTemplate | null;
  postTemplate: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplate | null;
  postTemplates: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates[];
  atssyncJobStage: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_atssyncJobStage | null;
  schedulerConfig: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig | null;
  changes: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes[];
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates[];
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items[];
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_atsJob_atsJobStages {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_atsJob {
  __typename: "ATSJobRole";
  id: string;
  atsJobStages: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_atsJob_atsJobStages[];
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseInterview {
  __typename: "ATSInterview";
  id: string;
  name: string;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  atssyncGreenhouseJobStage: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseJobStage | null;
  atssyncGreenhouseInterview: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseInterview | null;
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  jobDisplayName: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleNameOverride: string | null;
  inProgressGuidesCount: number;
  setupStatus: JobRoleGuideTemplateSetupStatus;
  defaultGuideVersion: number;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  changes: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_changes[];
  collectionChanges: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_collectionChanges[];
  jobRoleGuideTemplateStages: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages[];
  interviewPlan: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_interviewPlan | null;
  guideTemplate: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_guideTemplate | null;
  atsJob: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_atsJob | null;
  eventTemplates: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById_eventTemplates[];
}

export interface GetJobRoleGuideTemplateForOptions_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplateById: GetJobRoleGuideTemplateForOptions_currentOrganization_jobRoleGuideTemplateById;
}

export interface GetJobRoleGuideTemplateForOptions {
  currentOrganization: GetJobRoleGuideTemplateForOptions_currentOrganization | null;
}

export interface GetJobRoleGuideTemplateForOptionsVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobRoleGuideTemplateSetupStatus
// ====================================================

export interface GetJobRoleGuideTemplateSetupStatus_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  setupStatus: JobRoleGuideTemplateSetupStatus;
  name: string;
  jobDisplayName: string;
  defaultGuideVersion: number;
}

export interface GetJobRoleGuideTemplateSetupStatus_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplateById: GetJobRoleGuideTemplateSetupStatus_currentOrganization_jobRoleGuideTemplateById;
}

export interface GetJobRoleGuideTemplateSetupStatus {
  currentOrganization: GetJobRoleGuideTemplateSetupStatus_currentOrganization | null;
}

export interface GetJobRoleGuideTemplateSetupStatusVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobRoleGuideTemplateStages
// ====================================================

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  stageAutomationEnabled: boolean;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes_change = JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes_change_BooleanFieldChange | JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes_change_StringFieldChange | JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes_change_StringArrayFieldChange;

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes {
  __typename: "GuidePropagationFieldChange";
  field: GuidePropagationChangesEnum;
  change: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes_change;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedAddedData {
  __typename: "CollectionFieldChangedAddedData";
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedRemovedData {
  __typename: "CollectionFieldChangedRemovedData";
  parentId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedReorderedData {
  __typename: "CollectionFieldChangedReorderedData";
  parentId: string | null;
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export type JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data = JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedAddedData | JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedRemovedData | JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data_CollectionFieldChangedReorderedData;

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges {
  __typename: "GuideCollectionPropagationFieldChange";
  type: GuideCollectionPropagationChangesTypeEnum;
  data: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges_data;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate | null;
  schedulerAvailability: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability | null;
  configInterviewers: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers[];
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change = JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange | JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringFieldChange | JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange;

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes_change;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate | null;
  emailTemplate: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_emailTemplate | null;
  postTemplate: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplate | null;
  postTemplates: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_postTemplates[];
  atssyncJobStage: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_atssyncJobStage | null;
  schedulerConfig: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig | null;
  changes: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_changes[];
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items_postTemplates[];
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_interviewPlan_items[];
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_guideTemplate_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_guideTemplate_guideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_guideTemplate_guideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_guideTemplate_guideTemplateStages_contentTemplate_modulesInflated[];
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_guideTemplate_guideTemplateStages {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  contentTemplate: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_guideTemplate_guideTemplateStages_contentTemplate | null;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  chatEnabled: boolean;
  isGlobal: boolean;
  department: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_guideTemplate_department | null;
  interviewProcessEnabled: boolean;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  guideTemplateStages: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_guideTemplate_guideTemplateStages[];
  updatedAt: GraphQL_DateTime;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_atsJob_atsJobStages {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_atsJob {
  __typename: "ATSJobRole";
  id: string;
  atsJobStages: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_atsJob_atsJobStages[];
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseInterview {
  __typename: "ATSInterview";
  id: string;
  name: string;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  atssyncGreenhouseJobStage: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseJobStage | null;
  atssyncGreenhouseInterview: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_eventTemplates_atssyncGreenhouseInterview | null;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideLexicalContext {
  __typename: "JobRoleGuideLexicalContext";
  id: GraphQL_UUID;
  jobTitle: string;
  jobListingUrl: string | null;
  companyName: string;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  jobDisplayName: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleNameOverride: string | null;
  inProgressGuidesCount: number;
  setupStatus: JobRoleGuideTemplateSetupStatus;
  defaultGuideVersion: number;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  changes: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_changes[];
  collectionChanges: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_collectionChanges[];
  jobRoleGuideTemplateStages: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages[];
  interviewPlan: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_interviewPlan | null;
  guideTemplate: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_guideTemplate | null;
  atsJob: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_atsJob | null;
  eventTemplates: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_eventTemplates[];
  jobRoleGuideLexicalContext: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideLexicalContext;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  defaultAvatarImageUrl: string;
  theme: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_theme | null;
  customer: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_customer;
  companyLogoUrl: string;
  name: string;
  jobRoleGuideTemplateById: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization_jobRoleGuideTemplateById;
}

export interface JobRoleGuideTemplateStages_currentUserPrisma {
  __typename: "User";
  id: string;
  nylasAccessTokenExists: boolean;
  currentOrganization: JobRoleGuideTemplateStages_currentUserPrisma_currentOrganization | null;
}

export interface JobRoleGuideTemplateStages {
  currentUserPrisma: JobRoleGuideTemplateStages_currentUserPrisma | null;
}

export interface JobRoleGuideTemplateStagesVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobRoleGuideTemplatesFilterData
// ====================================================

export interface JobRoleGuideTemplatesFilterData_atsOffices {
  __typename: "ATSOffice";
  id: string;
  name: string;
}

export interface JobRoleGuideTemplatesFilterData_atsDepartments {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface JobRoleGuideTemplatesFilterData_atsUsers {
  __typename: "ATSUser";
  id: string;
  name: string;
}

export interface JobRoleGuideTemplatesFilterData {
  atsOffices: JobRoleGuideTemplatesFilterData_atsOffices[];
  atsDepartments: JobRoleGuideTemplatesFilterData_atsDepartments[];
  atsUsers: JobRoleGuideTemplatesFilterData_atsUsers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllJobRoleGuideTemplateIds
// ====================================================

export interface AllJobRoleGuideTemplateIds_currentOrganization_jobRoleGuideTemplates_jobRole {
  __typename: "JobRole";
  id: string;
}

export interface AllJobRoleGuideTemplateIds_currentOrganization_jobRoleGuideTemplates {
  __typename: "JobRoleGuideTemplate";
  id: string;
  jobRole: AllJobRoleGuideTemplateIds_currentOrganization_jobRoleGuideTemplates_jobRole | null;
}

export interface AllJobRoleGuideTemplateIds_currentOrganization {
  __typename: "Organization";
  id: string;
  jobRoleGuideTemplates: AllJobRoleGuideTemplateIds_currentOrganization_jobRoleGuideTemplates[];
}

export interface AllJobRoleGuideTemplateIds {
  currentOrganization: AllJobRoleGuideTemplateIds_currentOrganization | null;
}

export interface AllJobRoleGuideTemplateIdsVariables {
  search?: string | null;
  atsUserIds?: string[] | null;
  atsOfficeIds?: string[] | null;
  atsDepartmentIds?: string[] | null;
  status?: JourneyStatusEnum | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOpenJobs
// ====================================================

export interface GetOpenJobs_currentUserPrisma_currentOrganization_customer_atsIntegration {
  __typename: "AtsIntegration";
  id: string;
  atsType: string | null;
}

export interface GetOpenJobs_currentUserPrisma_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  atsIntegration: GetOpenJobs_currentUserPrisma_currentOrganization_customer_atsIntegration | null;
}

export interface GetOpenJobs_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  customer: GetOpenJobs_currentUserPrisma_currentOrganization_customer;
}

export interface GetOpenJobs_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: GetOpenJobs_currentUserPrisma_currentOrganization | null;
}

export interface GetOpenJobs_openJobs_jobRole_atsJob_atsDepartments {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface GetOpenJobs_openJobs_jobRole_atsJob_atsOffices {
  __typename: "ATSOffice";
  id: string;
  name: string;
}

export interface GetOpenJobs_openJobs_jobRole_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
  requisitionId: string | null;
  atsDepartments: GetOpenJobs_openJobs_jobRole_atsJob_atsDepartments[];
  atsOffices: GetOpenJobs_openJobs_jobRole_atsJob_atsOffices[];
}

export interface GetOpenJobs_openJobs_jobRole {
  __typename: "JobRole";
  id: string;
  isDemo: boolean;
  atsJob: GetOpenJobs_openJobs_jobRole_atsJob | null;
}

export interface GetOpenJobs_openJobs_configuredStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  position: number;
}

export interface GetOpenJobs_openJobs {
  __typename: "OpenJob";
  id: string;
  name: string;
  jobRole: GetOpenJobs_openJobs_jobRole | null;
  updatedAt: GraphQL_DateTime;
  sentGuideCount: number;
  configuredStageCount: number;
  totalStageCount: number;
  defaultGuideVersion: number;
  unconfiguredStagesCount: number | null;
  configuredStagesCount: number | null;
  configuredStages: GetOpenJobs_openJobs_configuredStages[];
  setupStatus: JobRoleGuideTemplateSetupStatus;
}

export interface GetOpenJobs_openJobsAggregate {
  __typename: "OpenJobAggregate";
  count: number | null;
}

export interface GetOpenJobs {
  currentUserPrisma: GetOpenJobs_currentUserPrisma | null;
  openJobs: GetOpenJobs_openJobs[];
  openJobsAggregate: GetOpenJobs_openJobsAggregate;
}

export interface GetOpenJobsVariables {
  search?: string | null;
  atsUserIds?: string[] | null;
  atsOfficeIds?: string[] | null;
  atsDepartmentIds?: string[] | null;
  offset?: number | null;
  limit?: number | null;
  status?: JourneyStatusEnum | null;
  orderBy?: OpenJobOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ForceIncrementalSyncOnMergeForJobs
// ====================================================

export interface ForceIncrementalSyncOnMergeForJobs_forceIncrementalSyncOnMerge {
  __typename: "ForceIncrementalSyncOnMerge";
  success: boolean;
}

export interface ForceIncrementalSyncOnMergeForJobs {
  forceIncrementalSyncOnMerge: ForceIncrementalSyncOnMergeForJobs_forceIncrementalSyncOnMerge | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckLoggedInLogin
// ====================================================

export interface CheckLoggedInLogin_currentUserPrisma {
  __typename: "User";
  id: string;
}

export interface CheckLoggedInLogin {
  currentUserPrisma: CheckLoggedInLogin_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchGuideForMergeApplication
// ====================================================

export interface FetchGuideForMergeApplication_guideFindUniqueByMergeApplicationRemoteId {
  __typename: "Guide";
  id: string;
}

export interface FetchGuideForMergeApplication {
  guideFindUniqueByMergeApplicationRemoteId: FetchGuideForMergeApplication_guideFindUniqueByMergeApplicationRemoteId | null;
}

export interface FetchGuideForMergeApplicationVariables {
  where: GuideMergeCandidateApplicationWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ForceIncrementalSyncOnMerge
// ====================================================

export interface ForceIncrementalSyncOnMerge_forceIncrementalSyncOnMerge {
  __typename: "ForceIncrementalSyncOnMerge";
  success: boolean;
}

export interface ForceIncrementalSyncOnMerge {
  forceIncrementalSyncOnMerge: ForceIncrementalSyncOnMerge_forceIncrementalSyncOnMerge | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetProfileImageUrl
// ====================================================

export interface SetProfileImageUrl_updatePersonalProfileImageUrl_personalProfile {
  __typename: "Interviewer";
  id: string;
  email: string | null;
  name: string;
  title: string;
  displayTitle: string;
  biography: string;
  pronoun: string | null;
  websiteUrls: string[];
  imageUrl: string;
  videoIntroductionUrl: string | null;
}

export interface SetProfileImageUrl_updatePersonalProfileImageUrl {
  __typename: "UpdatePersonalProfileImageUrlMutationResponse";
  success: boolean;
  personalProfile: SetProfileImageUrl_updatePersonalProfileImageUrl_personalProfile | null;
  code: string;
  message: string;
}

export interface SetProfileImageUrl {
  updatePersonalProfileImageUrl: SetProfileImageUrl_updatePersonalProfileImageUrl;
}

export interface SetProfileImageUrlVariables {
  input: UpdatePersonalProfileImageUrlInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetOnboardingComplete
// ====================================================

export interface SetOnboardingComplete_setCurrentUserOnboardingComplete_userMembership {
  __typename: "UserMembership";
  id: string;
  onboardingComplete: boolean;
}

export interface SetOnboardingComplete_setCurrentUserOnboardingComplete {
  __typename: "SetCurrentUserOnboardingCompleteMutationResponse";
  success: boolean;
  userMembership: SetOnboardingComplete_setCurrentUserOnboardingComplete_userMembership | null;
}

export interface SetOnboardingComplete {
  setCurrentUserOnboardingComplete: SetOnboardingComplete_setCurrentUserOnboardingComplete;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OnboardingPitchPageTemplate
// ====================================================

export interface OnboardingPitchPageTemplate_onboardingPitchPageTemplate_pitchPageTemplate {
  __typename: "PitchPageTemplate";
  id: string;
  name: string;
}

export interface OnboardingPitchPageTemplate_onboardingPitchPageTemplate {
  __typename: "OnboardingPitchPageTemplateMutationResponse";
  success: boolean;
  pitchPageTemplate: OnboardingPitchPageTemplate_onboardingPitchPageTemplate_pitchPageTemplate | null;
}

export interface OnboardingPitchPageTemplate {
  onboardingPitchPageTemplate: OnboardingPitchPageTemplate_onboardingPitchPageTemplate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompletePitchPageOnboarding
// ====================================================

export interface CompletePitchPageOnboarding_completePitchPageOnboarding_user {
  __typename: "User";
  id: string;
  pitchPageOnboardingComplete: boolean;
}

export interface CompletePitchPageOnboarding_completePitchPageOnboarding {
  __typename: "CompletePitchPageOnboardingResponse";
  success: boolean;
  user: CompletePitchPageOnboarding_completePitchPageOnboarding_user | null;
}

export interface CompletePitchPageOnboarding {
  completePitchPageOnboarding: CompletePitchPageOnboarding_completePitchPageOnboarding;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPitchPageTemplateModulesForOnboarding
// ====================================================

export interface GetPitchPageTemplateModulesForOnboarding_currentUserPrisma_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface GetPitchPageTemplateModulesForOnboarding_currentUserPrisma_currentOrganization_customLogisticGroups_options {
  __typename: "Logistic";
  id: string;
  emoji: string;
  title: string;
  description: string;
  type: string;
  logisticGroupId: GraphQL_UUID;
}

export interface GetPitchPageTemplateModulesForOnboarding_currentUserPrisma_currentOrganization_customLogisticGroups {
  __typename: "LogisticGroup";
  id: string;
  name: string;
  type: string;
  options: GetPitchPageTemplateModulesForOnboarding_currentUserPrisma_currentOrganization_customLogisticGroups_options[];
}

export interface GetPitchPageTemplateModulesForOnboarding_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  name: string;
  companyLogoUrl: string;
  theme: GetPitchPageTemplateModulesForOnboarding_currentUserPrisma_currentOrganization_theme | null;
  customLogisticGroups: GetPitchPageTemplateModulesForOnboarding_currentUserPrisma_currentOrganization_customLogisticGroups[];
}

export interface GetPitchPageTemplateModulesForOnboarding_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: GetPitchPageTemplateModulesForOnboarding_currentUserPrisma_currentOrganization | null;
}

export interface GetPitchPageTemplateModulesForOnboarding_pitchPageTemplateFindUnique_modules_stageModule {
  __typename: "StageModule";
  id: string;
  name: string;
  type: string;
}

export interface GetPitchPageTemplateModulesForOnboarding_pitchPageTemplateFindUnique_modules {
  __typename: "PitchPageTemplateResolvedModule";
  id: string;
  position: number;
  type: string;
  data: GraphQL_JSON;
  stageModule: GetPitchPageTemplateModulesForOnboarding_pitchPageTemplateFindUnique_modules_stageModule;
}

export interface GetPitchPageTemplateModulesForOnboarding_pitchPageTemplateFindUnique {
  __typename: "PitchPageTemplate";
  id: string;
  name: string;
  modules: GetPitchPageTemplateModulesForOnboarding_pitchPageTemplateFindUnique_modules[];
}

export interface GetPitchPageTemplateModulesForOnboarding_stageModules {
  __typename: "StageModule";
  id: string;
  type: string;
}

export interface GetPitchPageTemplateModulesForOnboarding_availableLogisticGroups_options {
  __typename: "Logistic";
  id: string;
  emoji: string;
  title: string;
  description: string;
  type: string;
  logisticGroupId: GraphQL_UUID;
}

export interface GetPitchPageTemplateModulesForOnboarding_availableLogisticGroups {
  __typename: "LogisticGroup";
  id: string;
  name: string;
  type: string;
  options: GetPitchPageTemplateModulesForOnboarding_availableLogisticGroups_options[];
}

export interface GetPitchPageTemplateModulesForOnboarding {
  currentUserPrisma: GetPitchPageTemplateModulesForOnboarding_currentUserPrisma | null;
  pitchPageTemplateFindUnique: GetPitchPageTemplateModulesForOnboarding_pitchPageTemplateFindUnique;
  stageModules: GetPitchPageTemplateModulesForOnboarding_stageModules[];
  availableLogisticGroups: GetPitchPageTemplateModulesForOnboarding_availableLogisticGroups[];
}

export interface GetPitchPageTemplateModulesForOnboardingVariables {
  pitchPageTemplateId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchPitchPage
// ====================================================

export interface FetchPitchPage_pitchPageFindUnique_schedulerAvailabilityEvent_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  slug: string;
}

export interface FetchPitchPage_pitchPageFindUnique_schedulerAvailabilityEvent {
  __typename: "SchedulerAvailabilityEvent";
  id: string;
  schedulerAvailability: FetchPitchPage_pitchPageFindUnique_schedulerAvailabilityEvent_schedulerAvailability | null;
  externalEditHash: string;
}

export interface FetchPitchPage_pitchPageFindUnique_activeGuide {
  __typename: "Guide";
  id: string;
  publicUrl: string;
}

export interface FetchPitchPage_pitchPageFindUnique_pitchPageTemplate_createdBy_organization_values {
  __typename: "Value";
  id: GraphQL_UUID;
  title: string;
  description: string;
}

export interface FetchPitchPage_pitchPageFindUnique_pitchPageTemplate_createdBy_organization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface FetchPitchPage_pitchPageFindUnique_pitchPageTemplate_createdBy_organization {
  __typename: "Organization";
  id: string;
  values: FetchPitchPage_pitchPageFindUnique_pitchPageTemplate_createdBy_organization_values[];
  missionStatement: string;
  theme: FetchPitchPage_pitchPageFindUnique_pitchPageTemplate_createdBy_organization_theme | null;
  companyLogoUrl: string;
  name: string;
}

export interface FetchPitchPage_pitchPageFindUnique_pitchPageTemplate_createdBy {
  __typename: "UserMembership";
  id: string;
  organization: FetchPitchPage_pitchPageFindUnique_pitchPageTemplate_createdBy_organization;
}

export interface FetchPitchPage_pitchPageFindUnique_pitchPageTemplate_modules {
  __typename: "PitchPageTemplateResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
  displayType: string;
}

export interface FetchPitchPage_pitchPageFindUnique_pitchPageTemplate {
  __typename: "PitchPageTemplate";
  id: string;
  createdBy: FetchPitchPage_pitchPageFindUnique_pitchPageTemplate_createdBy;
  modules: FetchPitchPage_pitchPageFindUnique_pitchPageTemplate_modules[];
}

export interface FetchPitchPage_pitchPageFindUnique {
  __typename: "PitchPage";
  id: string;
  isSample: boolean;
  recipientFirstName: string;
  recipientLastName: string | null;
  schedulerAvailabilityEvent: FetchPitchPage_pitchPageFindUnique_schedulerAvailabilityEvent | null;
  activeGuide: FetchPitchPage_pitchPageFindUnique_activeGuide | null;
  pitchPageTemplate: FetchPitchPage_pitchPageFindUnique_pitchPageTemplate;
}

export interface FetchPitchPage {
  pitchPageFindUnique: FetchPitchPage_pitchPageFindUnique | null;
}

export interface FetchPitchPageVariables {
  pitchPageId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPitchPageTemplateModulesForDesign
// ====================================================

export interface GetPitchPageTemplateModulesForDesign_currentUserPrisma_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface GetPitchPageTemplateModulesForDesign_currentUserPrisma_currentOrganization_customLogisticGroups_options {
  __typename: "Logistic";
  id: string;
  emoji: string;
  title: string;
  description: string;
  type: string;
  logisticGroupId: GraphQL_UUID;
}

export interface GetPitchPageTemplateModulesForDesign_currentUserPrisma_currentOrganization_customLogisticGroups {
  __typename: "LogisticGroup";
  id: string;
  name: string;
  type: string;
  options: GetPitchPageTemplateModulesForDesign_currentUserPrisma_currentOrganization_customLogisticGroups_options[];
}

export interface GetPitchPageTemplateModulesForDesign_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  name: string;
  companyLogoUrl: string;
  theme: GetPitchPageTemplateModulesForDesign_currentUserPrisma_currentOrganization_theme | null;
  customLogisticGroups: GetPitchPageTemplateModulesForDesign_currentUserPrisma_currentOrganization_customLogisticGroups[];
}

export interface GetPitchPageTemplateModulesForDesign_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: GetPitchPageTemplateModulesForDesign_currentUserPrisma_currentOrganization | null;
}

export interface GetPitchPageTemplateModulesForDesign_pitchPageTemplateFindUnique_modules_stageModule {
  __typename: "StageModule";
  id: string;
  name: string;
  type: string;
}

export interface GetPitchPageTemplateModulesForDesign_pitchPageTemplateFindUnique_modules {
  __typename: "PitchPageTemplateResolvedModule";
  id: string;
  position: number;
  type: string;
  data: GraphQL_JSON;
  stageModule: GetPitchPageTemplateModulesForDesign_pitchPageTemplateFindUnique_modules_stageModule;
}

export interface GetPitchPageTemplateModulesForDesign_pitchPageTemplateFindUnique {
  __typename: "PitchPageTemplate";
  id: string;
  name: string;
  modules: GetPitchPageTemplateModulesForDesign_pitchPageTemplateFindUnique_modules[];
}

export interface GetPitchPageTemplateModulesForDesign_stageModules {
  __typename: "StageModule";
  id: string;
  type: string;
}

export interface GetPitchPageTemplateModulesForDesign_availableLogisticGroups_options {
  __typename: "Logistic";
  id: string;
  emoji: string;
  title: string;
  description: string;
  type: string;
  logisticGroupId: GraphQL_UUID;
}

export interface GetPitchPageTemplateModulesForDesign_availableLogisticGroups {
  __typename: "LogisticGroup";
  id: string;
  name: string;
  type: string;
  options: GetPitchPageTemplateModulesForDesign_availableLogisticGroups_options[];
}

export interface GetPitchPageTemplateModulesForDesign {
  currentUserPrisma: GetPitchPageTemplateModulesForDesign_currentUserPrisma | null;
  pitchPageTemplateFindUnique: GetPitchPageTemplateModulesForDesign_pitchPageTemplateFindUnique;
  stageModules: GetPitchPageTemplateModulesForDesign_stageModules[];
  availableLogisticGroups: GetPitchPageTemplateModulesForDesign_availableLogisticGroups[];
}

export interface GetPitchPageTemplateModulesForDesignVariables {
  pitchPageTemplateId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideTemplatePreview
// ====================================================

export interface GuideTemplatePreview_currentOrganization_guideTemplateById_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface GuideTemplatePreview_currentOrganization_guideTemplateById_guideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface GuideTemplatePreview_currentOrganization_guideTemplateById_guideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: GuideTemplatePreview_currentOrganization_guideTemplateById_guideTemplateStages_contentTemplate_modulesInflated[];
}

export interface GuideTemplatePreview_currentOrganization_guideTemplateById_guideTemplateStages {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  contentTemplate: GuideTemplatePreview_currentOrganization_guideTemplateById_guideTemplateStages_contentTemplate | null;
}

export interface GuideTemplatePreview_currentOrganization_guideTemplateById {
  __typename: "GuideTemplate";
  id: string;
  chatEnabled: boolean;
  isGlobal: boolean;
  department: GuideTemplatePreview_currentOrganization_guideTemplateById_department | null;
  interviewProcessEnabled: boolean;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  guideTemplateStages: GuideTemplatePreview_currentOrganization_guideTemplateById_guideTemplateStages[];
  updatedAt: GraphQL_DateTime;
}

export interface GuideTemplatePreview_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface GuideTemplatePreview_currentOrganization {
  __typename: "Organization";
  guideTemplateById: GuideTemplatePreview_currentOrganization_guideTemplateById;
  id: string;
  companyLogoUrl: string;
  name: string;
  theme: GuideTemplatePreview_currentOrganization_theme | null;
  showTrim: boolean;
}

export interface GuideTemplatePreview {
  currentOrganization: GuideTemplatePreview_currentOrganization | null;
}

export interface GuideTemplatePreviewVariables {
  guideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuidePreviewGuideById
// ====================================================

export interface GuidePreviewGuideById_guideFindUnique_candidate_organization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface GuidePreviewGuideById_guideFindUnique_candidate_organization {
  __typename: "Organization";
  id: string;
  companyLogoUrl: string;
  name: string;
  theme: GuidePreviewGuideById_guideFindUnique_candidate_organization_theme | null;
  showTrim: boolean;
}

export interface GuidePreviewGuideById_guideFindUnique_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  organization: GuidePreviewGuideById_guideFindUnique_candidate_organization;
}

export interface GuidePreviewGuideById_guideFindUnique_currentStage {
  __typename: "Stage";
  id: string;
}

export interface GuidePreviewGuideById_guideFindUnique_sortedVisibleStages_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface GuidePreviewGuideById_guideFindUnique_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  title: string;
  description: string | null;
  position: number;
  atsJobStageId: string | null;
  /**
   * Returns the Content Modules for this stage inflated 
   *         with data from the database that is NOT contextual to the 
   *         stage's Guide. Today, the content modules come from a combination of
   *         stamped modules (the interview scheduler) and the associated ContentTemplate's
   *         modules.
   */
  modulesInflated: GuidePreviewGuideById_guideFindUnique_sortedVisibleStages_modulesInflated[];
}

export interface GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_interviews_prominentInstructions {
  __typename: "ModuleResolutionProminentInstructionContext";
  id: GraphQL_UUID;
  label: string;
  url: string | null;
}

export interface GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_interviews_interviewers {
  __typename: "ModuleResolutionInterviewerContext";
  id: GraphQL_UUID;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  pronoun: string | null;
  videoIntroductionUrl: string | null;
}

export interface GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_interviews {
  __typename: "ModuleResolutionInterviewContext";
  id: GraphQL_UUID;
  atsJobStageId: string | null;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  title: string;
  description: string;
  variableReplacements: GraphQL_JSON;
  eventTemplateId: string | null;
  prominentInstructions: GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_interviews_prominentInstructions[];
  interviewers: GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_interviews_interviewers[];
}

export interface GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_stages_schedulerConfig_interviewers {
  __typename: "ModuleResolutionSchedulerConfigInterviewerContext";
  id: GraphQL_UUID;
  firstName: string;
  lastName: string;
  imageUrl: string;
}

export interface GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_stages_schedulerConfig {
  __typename: "ModuleResolutionSchedulerConfigContext";
  availabilitySlug: string | null;
  eventTemplateId: string | null;
  interviewers: GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_stages_schedulerConfig_interviewers[];
  eventTemplateInstallationId: string | null;
}

export interface GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_stages_customData_hiringTeam {
  __typename: "ModuleResolutionInterviewerContext";
  id: GraphQL_UUID;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  pronoun: string | null;
  videoIntroductionUrl: string | null;
}

export interface GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_stages_customData {
  __typename: "StageCustomData";
  hiringTeam: GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_stages_customData_hiringTeam[];
  customVariables: GraphQL_JSON | null;
}

export interface GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_stages {
  __typename: "ModuleResolutionStageContext";
  id: string;
  atsJobStageId: string;
  needsFeedback: boolean;
  variableReplacements: GraphQL_JSON;
  schedulerConfig: GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_stages_schedulerConfig | null;
  customData: GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_stages_customData;
}

export interface GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_candidate {
  __typename: "ModuleResolutionCandidateContext";
  id: GraphQL_UUID;
  email: string | null;
  firstName: string;
  fullName: string;
  imageUrl: string;
}

export interface GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_organization_values {
  __typename: "ModuleResolutionValueContext";
  id: GraphQL_UUID;
  title: string;
  description: string;
}

export interface GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_organization {
  __typename: "ModuleResolutionOrganizationContext";
  id: GraphQL_UUID;
  name: string;
  addToCalendarEnabled: boolean;
  missionStatement: string;
  values: GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_organization_values[];
}

export interface GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_job {
  __typename: "ModuleResolutionJobContext";
  id: GraphQL_UUID;
  name: string;
  greenhouseJobUrl: string;
}

export interface GuidePreviewGuideById_guideFindUnique_moduleResolutionContext {
  __typename: "ModuleResolutionGuideContext";
  id: GraphQL_UUID;
  publicUrl: string;
  recruiterEmailAddress: string;
  recruiterFirstName: string;
  interviews: GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_interviews[];
  stages: GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_stages[];
  candidate: GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_candidate;
  organization: GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_organization;
  job: GuidePreviewGuideById_guideFindUnique_moduleResolutionContext_job;
}

export interface GuidePreviewGuideById_guideFindUnique_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  defaultGuideVersion: number;
}

export interface GuidePreviewGuideById_guideFindUnique {
  __typename: "Guide";
  id: string;
  candidate: GuidePreviewGuideById_guideFindUnique_candidate;
  currentStage: GuidePreviewGuideById_guideFindUnique_currentStage | null;
  sortedVisibleStages: GuidePreviewGuideById_guideFindUnique_sortedVisibleStages[];
  interviewProcessEnabled: boolean;
  moduleResolutionContext: GuidePreviewGuideById_guideFindUnique_moduleResolutionContext;
  atsUrl: string;
  guideVersion: number;
  jobRoleGuideTemplate: GuidePreviewGuideById_guideFindUnique_jobRoleGuideTemplate | null;
}

export interface GuidePreviewGuideById {
  guideFindUnique: GuidePreviewGuideById_guideFindUnique;
}

export interface GuidePreviewGuideByIdVariables {
  where: GuideWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobGuideSettingsPreview
// ====================================================

export interface JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  originalATSId: string;
}

export interface JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer;
}

export interface JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate | null;
  schedulerAvailability: JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability | null;
  configInterviewers: JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers[];
}

export interface JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  atssyncJobStage: JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_atssyncJobStage | null;
  schedulerConfig: JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_schedulerConfig | null;
  contentTemplate: JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages_contentTemplate | null;
}

export interface JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById {
  __typename: "JobRoleGuideTemplate";
  id: string;
  jobDisplayName: string;
  interviewProcessEnabled: boolean;
  jobRoleGuideTemplateStages: JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById_jobRoleGuideTemplateStages[];
}

export interface JobGuideSettingsPreview_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface JobGuideSettingsPreview_currentOrganization {
  __typename: "Organization";
  jobRoleGuideTemplateById: JobGuideSettingsPreview_currentOrganization_jobRoleGuideTemplateById;
  id: string;
  companyLogoUrl: string;
  name: string;
  theme: JobGuideSettingsPreview_currentOrganization_theme | null;
  showTrim: boolean;
}

export interface JobGuideSettingsPreview {
  currentOrganization: JobGuideSettingsPreview_currentOrganization | null;
}

export interface JobGuideSettingsPreviewVariables {
  jobRoleGuideTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSchedulerPageModules
// ====================================================

export interface GetSchedulerPageModules_schedulerAvailabilityFindUnique_userMembership_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
}

export interface GetSchedulerPageModules_schedulerAvailabilityFindUnique_userMembership_organization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface GetSchedulerPageModules_schedulerAvailabilityFindUnique_userMembership_organization {
  __typename: "Organization";
  id: string;
  theme: GetSchedulerPageModules_schedulerAvailabilityFindUnique_userMembership_organization_theme | null;
  companyLogoUrl: string;
  name: string;
}

export interface GetSchedulerPageModules_schedulerAvailabilityFindUnique_userMembership {
  __typename: "UserMembership";
  id: string;
  personalProfile: GetSchedulerPageModules_schedulerAvailabilityFindUnique_userMembership_personalProfile;
  organization: GetSchedulerPageModules_schedulerAvailabilityFindUnique_userMembership_organization;
}

export interface GetSchedulerPageModules_schedulerAvailabilityFindUnique_availabilityPageTemplate_resolvedModules {
  __typename: "SchedulerAvailabilityPageTemplateResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface GetSchedulerPageModules_schedulerAvailabilityFindUnique_availabilityPageTemplate {
  __typename: "SchedulerAvailabilityPageTemplate";
  id: string;
  resolvedModules: GetSchedulerPageModules_schedulerAvailabilityFindUnique_availabilityPageTemplate_resolvedModules[];
}

export interface GetSchedulerPageModules_schedulerAvailabilityFindUnique {
  __typename: "SchedulerAvailability";
  id: string;
  eventTitle: string;
  userMembership: GetSchedulerPageModules_schedulerAvailabilityFindUnique_userMembership;
  availabilityPageTemplate: GetSchedulerPageModules_schedulerAvailabilityFindUnique_availabilityPageTemplate | null;
}

export interface GetSchedulerPageModules {
  schedulerAvailabilityFindUnique: GetSchedulerPageModules_schedulerAvailabilityFindUnique;
}

export interface GetSchedulerPageModulesVariables {
  where: SchedulerAvailabilityWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailabilityPageTemplatesForCurrentUser
// ====================================================

export interface AvailabilityPageTemplatesForCurrentUser_currentUser_currentUserMembership_availabilityPageTemplates_resolvedModules {
  __typename: "SchedulerAvailabilityPageTemplateResolvedModule";
  id: string;
  position: number;
  type: string;
  displayType: string;
  data: GraphQL_JSON;
}

export interface AvailabilityPageTemplatesForCurrentUser_currentUser_currentUserMembership_availabilityPageTemplates {
  __typename: "SchedulerAvailabilityPageTemplate";
  id: string;
  resolvedModules: AvailabilityPageTemplatesForCurrentUser_currentUser_currentUserMembership_availabilityPageTemplates_resolvedModules[];
}

export interface AvailabilityPageTemplatesForCurrentUser_currentUser_currentUserMembership {
  __typename: "UserMembership";
  id: string;
  availabilityPageTemplates: AvailabilityPageTemplatesForCurrentUser_currentUser_currentUserMembership_availabilityPageTemplates[];
}

export interface AvailabilityPageTemplatesForCurrentUser_currentUser {
  __typename: "User";
  id: string;
  currentUserMembership: AvailabilityPageTemplatesForCurrentUser_currentUser_currentUserMembership | null;
}

export interface AvailabilityPageTemplatesForCurrentUser_currentOrganization_values {
  __typename: "Value";
  id: GraphQL_UUID;
  description: string;
  title: string;
}

export interface AvailabilityPageTemplatesForCurrentUser_currentOrganization_customLogisticGroups_options {
  __typename: "Logistic";
  id: string;
  emoji: string;
  title: string;
  description: string;
  type: string;
  logisticGroupId: GraphQL_UUID;
}

export interface AvailabilityPageTemplatesForCurrentUser_currentOrganization_customLogisticGroups {
  __typename: "LogisticGroup";
  id: string;
  name: string;
  type: string;
  options: AvailabilityPageTemplatesForCurrentUser_currentOrganization_customLogisticGroups_options[];
}

export interface AvailabilityPageTemplatesForCurrentUser_currentOrganization {
  __typename: "Organization";
  id: string;
  companyLogoUrl: string;
  missionStatement: string;
  values: AvailabilityPageTemplatesForCurrentUser_currentOrganization_values[];
  customLogisticGroups: AvailabilityPageTemplatesForCurrentUser_currentOrganization_customLogisticGroups[];
}

export interface AvailabilityPageTemplatesForCurrentUser_stageModules {
  __typename: "StageModule";
  id: string;
  type: string;
}

export interface AvailabilityPageTemplatesForCurrentUser_availableLogisticGroups_options {
  __typename: "Logistic";
  id: string;
  emoji: string;
  title: string;
  description: string;
  type: string;
  logisticGroupId: GraphQL_UUID;
}

export interface AvailabilityPageTemplatesForCurrentUser_availableLogisticGroups {
  __typename: "LogisticGroup";
  id: string;
  name: string;
  type: string;
  options: AvailabilityPageTemplatesForCurrentUser_availableLogisticGroups_options[];
}

export interface AvailabilityPageTemplatesForCurrentUser {
  currentUser: AvailabilityPageTemplatesForCurrentUser_currentUser | null;
  currentOrganization: AvailabilityPageTemplatesForCurrentUser_currentOrganization | null;
  stageModules: AvailabilityPageTemplatesForCurrentUser_stageModules[];
  availableLogisticGroups: AvailabilityPageTemplatesForCurrentUser_availableLogisticGroups[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCompanySettingsLogoBrandKit
// ====================================================

export interface UpdateCompanySettingsLogoBrandKit_updateOrganizationLogo_organization {
  __typename: "Organization";
  id: string;
  companyLogoUrl: string;
}

export interface UpdateCompanySettingsLogoBrandKit_updateOrganizationLogo {
  __typename: "UpdateOrganizationLogoMutationResponse";
  success: boolean;
  code: string;
  message: string;
  organization: UpdateCompanySettingsLogoBrandKit_updateOrganizationLogo_organization | null;
}

export interface UpdateCompanySettingsLogoBrandKit {
  updateOrganizationLogo: UpdateCompanySettingsLogoBrandKit_updateOrganizationLogo;
}

export interface UpdateCompanySettingsLogoBrandKitVariables {
  input: UpdateOrganizationLogoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCompanySettingsDefaultInterviewerImageBrandKit
// ====================================================

export interface UpdateCompanySettingsDefaultInterviewerImageBrandKit_updateOrganizationInterviewerImage_organization {
  __typename: "Organization";
  id: string;
  defaultAvatarImageUrl: string;
}

export interface UpdateCompanySettingsDefaultInterviewerImageBrandKit_updateOrganizationInterviewerImage {
  __typename: "UpdateOrganizationInterviewerImageMutationResponse";
  success: boolean;
  code: string;
  message: string;
  organization: UpdateCompanySettingsDefaultInterviewerImageBrandKit_updateOrganizationInterviewerImage_organization | null;
}

export interface UpdateCompanySettingsDefaultInterviewerImageBrandKit {
  updateOrganizationInterviewerImage: UpdateCompanySettingsDefaultInterviewerImageBrandKit_updateOrganizationInterviewerImage;
}

export interface UpdateCompanySettingsDefaultInterviewerImageBrandKitVariables {
  input: UpdateOrganizationInterviewerImageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GlobalOrganizationContentForCompanySettings
// ====================================================

export interface GlobalOrganizationContentForCompanySettings_currentOrganization_locations {
  __typename: "Location";
  id: string;
  name: string;
  arrivalInstructions: string;
  address: string;
  coordinates: string[];
  isArchived: boolean;
}

export interface GlobalOrganizationContentForCompanySettings_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface GlobalOrganizationContentForCompanySettings_currentOrganization_defaultGuideContent {
  __typename: "GuideContent";
  id: string;
  data: GraphQL_JSON;
}

export interface GlobalOrganizationContentForCompanySettings_currentOrganization_values {
  __typename: "Value";
  id: GraphQL_UUID;
  title: string;
  description: string;
}

export interface GlobalOrganizationContentForCompanySettings_currentOrganization_customLogisticGroups_options {
  __typename: "Logistic";
  id: string;
  emoji: string;
  title: string;
  description: string;
}

export interface GlobalOrganizationContentForCompanySettings_currentOrganization_customLogisticGroups {
  __typename: "LogisticGroup";
  id: string;
  options: GlobalOrganizationContentForCompanySettings_currentOrganization_customLogisticGroups_options[];
}

export interface GlobalOrganizationContentForCompanySettings_currentOrganization {
  __typename: "Organization";
  id: string;
  name: string;
  locations: GlobalOrganizationContentForCompanySettings_currentOrganization_locations[];
  missionStatement: string;
  theme: GlobalOrganizationContentForCompanySettings_currentOrganization_theme | null;
  showTrim: boolean;
  companyLogoUrl: string;
  defaultAvatarImageUrl: string;
  defaultGuideContent: GlobalOrganizationContentForCompanySettings_currentOrganization_defaultGuideContent | null;
  values: GlobalOrganizationContentForCompanySettings_currentOrganization_values[];
  customLogisticGroups: GlobalOrganizationContentForCompanySettings_currentOrganization_customLogisticGroups[];
}

export interface GlobalOrganizationContentForCompanySettings {
  currentOrganization: GlobalOrganizationContentForCompanySettings_currentOrganization | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrgConfigurationDataPrisma
// ====================================================

export interface OrgConfigurationDataPrisma_roles {
  __typename: "Role";
  id: string;
  name: string;
}

export interface OrgConfigurationDataPrisma_currentUserPrisma_currentOrganization_domainJoinRole {
  __typename: "Role";
  id: string;
  name: string;
}

export interface OrgConfigurationDataPrisma_currentUserPrisma_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  domains: string[];
}

export interface OrgConfigurationDataPrisma_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  disableChatGlobally: boolean;
  enableNPS: boolean;
  enableICSFiles: boolean;
  domainJoinEnabled: boolean;
  domainJoinRole: OrgConfigurationDataPrisma_currentUserPrisma_currentOrganization_domainJoinRole | null;
  customer: OrgConfigurationDataPrisma_currentUserPrisma_currentOrganization_customer;
}

export interface OrgConfigurationDataPrisma_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: OrgConfigurationDataPrisma_currentUserPrisma_currentOrganization | null;
}

export interface OrgConfigurationDataPrisma {
  roles: OrgConfigurationDataPrisma_roles[];
  currentUserPrisma: OrgConfigurationDataPrisma_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDisableChatGloballyPrisma
// ====================================================

export interface UpdateDisableChatGloballyPrisma_updateDisableChatGlobally_organization_domainJoinRole {
  __typename: "Role";
  id: string;
  name: string;
}

export interface UpdateDisableChatGloballyPrisma_updateDisableChatGlobally_organization {
  __typename: "Organization";
  id: string;
  disableChatGlobally: boolean;
  enableNPS: boolean;
  enableICSFiles: boolean;
  domainJoinEnabled: boolean;
  domainJoinRole: UpdateDisableChatGloballyPrisma_updateDisableChatGlobally_organization_domainJoinRole | null;
}

export interface UpdateDisableChatGloballyPrisma_updateDisableChatGlobally {
  __typename: "UpdateDisableChatGloballyMutationResponse";
  success: boolean;
  code: string;
  message: string;
  organization: UpdateDisableChatGloballyPrisma_updateDisableChatGlobally_organization | null;
}

export interface UpdateDisableChatGloballyPrisma {
  updateDisableChatGlobally: UpdateDisableChatGloballyPrisma_updateDisableChatGlobally;
}

export interface UpdateDisableChatGloballyPrismaVariables {
  disableChatGlobally: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEnableNPSPrisma
// ====================================================

export interface UpdateEnableNPSPrisma_updateEnableNPS_organization_domainJoinRole {
  __typename: "Role";
  id: string;
  name: string;
}

export interface UpdateEnableNPSPrisma_updateEnableNPS_organization {
  __typename: "Organization";
  id: string;
  disableChatGlobally: boolean;
  enableNPS: boolean;
  enableICSFiles: boolean;
  domainJoinEnabled: boolean;
  domainJoinRole: UpdateEnableNPSPrisma_updateEnableNPS_organization_domainJoinRole | null;
}

export interface UpdateEnableNPSPrisma_updateEnableNPS {
  __typename: "UpdateEnableNPSMutationResponse";
  success: boolean;
  code: string;
  message: string;
  organization: UpdateEnableNPSPrisma_updateEnableNPS_organization | null;
}

export interface UpdateEnableNPSPrisma {
  updateEnableNPS: UpdateEnableNPSPrisma_updateEnableNPS;
}

export interface UpdateEnableNPSPrismaVariables {
  enableNPS: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDomainJoinEnabledPrisma
// ====================================================

export interface UpdateDomainJoinEnabledPrisma_updateDomainJoinEnabled_organization_domainJoinRole {
  __typename: "Role";
  id: string;
  name: string;
}

export interface UpdateDomainJoinEnabledPrisma_updateDomainJoinEnabled_organization {
  __typename: "Organization";
  id: string;
  disableChatGlobally: boolean;
  enableNPS: boolean;
  enableICSFiles: boolean;
  domainJoinEnabled: boolean;
  domainJoinRole: UpdateDomainJoinEnabledPrisma_updateDomainJoinEnabled_organization_domainJoinRole | null;
}

export interface UpdateDomainJoinEnabledPrisma_updateDomainJoinEnabled {
  __typename: "UpdateDomainJoinEnabledMutationResponse";
  success: boolean;
  code: string;
  message: string;
  organization: UpdateDomainJoinEnabledPrisma_updateDomainJoinEnabled_organization | null;
}

export interface UpdateDomainJoinEnabledPrisma {
  updateDomainJoinEnabled: UpdateDomainJoinEnabledPrisma_updateDomainJoinEnabled;
}

export interface UpdateDomainJoinEnabledPrismaVariables {
  domainJoinEnabled: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEnableICSFiles
// ====================================================

export interface UpdateEnableICSFiles_updateEnableICSFiles_organization_domainJoinRole {
  __typename: "Role";
  id: string;
  name: string;
}

export interface UpdateEnableICSFiles_updateEnableICSFiles_organization {
  __typename: "Organization";
  id: string;
  disableChatGlobally: boolean;
  enableNPS: boolean;
  enableICSFiles: boolean;
  domainJoinEnabled: boolean;
  domainJoinRole: UpdateEnableICSFiles_updateEnableICSFiles_organization_domainJoinRole | null;
}

export interface UpdateEnableICSFiles_updateEnableICSFiles {
  __typename: "UpdateEnableICSFilesResponse";
  success: boolean;
  code: string;
  message: string;
  organization: UpdateEnableICSFiles_updateEnableICSFiles_organization | null;
}

export interface UpdateEnableICSFiles {
  updateEnableICSFiles: UpdateEnableICSFiles_updateEnableICSFiles;
}

export interface UpdateEnableICSFilesVariables {
  enableICSFiles: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDomainJoinRolePrisma
// ====================================================

export interface UpdateDomainJoinRolePrisma_updateDomainJoinRole_organization_domainJoinRole {
  __typename: "Role";
  id: string;
  name: string;
}

export interface UpdateDomainJoinRolePrisma_updateDomainJoinRole_organization {
  __typename: "Organization";
  id: string;
  disableChatGlobally: boolean;
  enableNPS: boolean;
  enableICSFiles: boolean;
  domainJoinEnabled: boolean;
  domainJoinRole: UpdateDomainJoinRolePrisma_updateDomainJoinRole_organization_domainJoinRole | null;
}

export interface UpdateDomainJoinRolePrisma_updateDomainJoinRole {
  __typename: "UpdateDomainJoinRoleMutationResponse";
  success: boolean;
  code: string;
  message: string;
  organization: UpdateDomainJoinRolePrisma_updateDomainJoinRole_organization | null;
}

export interface UpdateDomainJoinRolePrisma {
  updateDomainJoinRole: UpdateDomainJoinRolePrisma_updateDomainJoinRole;
}

export interface UpdateDomainJoinRolePrismaVariables {
  domainJoinRoleId: GraphQL_UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CustomerIntegrationsData
// ====================================================

export interface CustomerIntegrationsData_currentUserPrisma_currentUserMembership {
  __typename: "UserMembership";
  id: string;
  hasLimitedAccess: boolean;
}

export interface CustomerIntegrationsData_currentUserPrisma_currentOrganization_customer_greenhouseIntegration {
  __typename: "GreenhouseIntegration";
  id: string;
  type: IntegrationEnum;
  connected: boolean;
  apiKeySuffix: string | null;
  maildropAddress: string;
  subdomain: string;
  requiredWebhooksConfigured: boolean;
  onBehalfOfUserId: string | null;
}

export interface CustomerIntegrationsData_currentUserPrisma_currentOrganization_customer_slackIntegration {
  __typename: "SlackIntegration";
  id: string;
  type: IntegrationEnum;
  connected: boolean;
  auth0SlackUserId: string | null;
  autopopulateInterviewer: boolean | null;
  needsReauthentication: boolean | null;
  notificationsChannelId: string | null;
  receiveNotifications: boolean | null;
}

export interface CustomerIntegrationsData_currentUserPrisma_currentOrganization_customer_leverIntegration {
  __typename: "MergeIntegration";
  id: string;
  mergeAccountId: string | null;
  type: IntegrationEnum;
  connected: boolean;
}

export interface CustomerIntegrationsData_currentUserPrisma_currentOrganization_customer_greenhouseMergeIntegration {
  __typename: "MergeIntegration";
  id: string;
  mergeAccountId: string | null;
  type: IntegrationEnum;
  connected: boolean;
}

export interface CustomerIntegrationsData_currentUserPrisma_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  greenhouseIntegration: CustomerIntegrationsData_currentUserPrisma_currentOrganization_customer_greenhouseIntegration;
  slackIntegration: CustomerIntegrationsData_currentUserPrisma_currentOrganization_customer_slackIntegration;
  leverIntegration: CustomerIntegrationsData_currentUserPrisma_currentOrganization_customer_leverIntegration;
  greenhouseMergeIntegration: CustomerIntegrationsData_currentUserPrisma_currentOrganization_customer_greenhouseMergeIntegration;
}

export interface CustomerIntegrationsData_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  customer: CustomerIntegrationsData_currentUserPrisma_currentOrganization_customer;
}

export interface CustomerIntegrationsData_currentUserPrisma {
  __typename: "User";
  id: string;
  currentUserMembership: CustomerIntegrationsData_currentUserPrisma_currentUserMembership | null;
  currentOrganization: CustomerIntegrationsData_currentUserPrisma_currentOrganization | null;
}

export interface CustomerIntegrationsData {
  currentUserPrisma: CustomerIntegrationsData_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewTemplatesFilterData
// ====================================================

export interface InterviewTemplatesFilterData_atsDepartments {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface InterviewTemplatesFilterData {
  atsDepartments: InterviewTemplatesFilterData_atsDepartments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InterviewTemplatesPaginated
// ====================================================

export interface InterviewTemplatesPaginated_currentUserPrisma_currentOrganization_interviewTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface InterviewTemplatesPaginated_currentUserPrisma_currentOrganization_interviewTemplates_lastEditedBy {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface InterviewTemplatesPaginated_currentUserPrisma_currentOrganization_interviewTemplates_createdBy {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface InterviewTemplatesPaginated_currentUserPrisma_currentOrganization_interviewTemplates {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  department: InterviewTemplatesPaginated_currentUserPrisma_currentOrganization_interviewTemplates_department | null;
  usage: number;
  lastEditedBy: InterviewTemplatesPaginated_currentUserPrisma_currentOrganization_interviewTemplates_lastEditedBy | null;
  lastEditedAt: GraphQL_DateTime | null;
  status: InterviewTemplateStatusEnum | null;
  createdAt: GraphQL_DateTime;
  createdBy: InterviewTemplatesPaginated_currentUserPrisma_currentOrganization_interviewTemplates_createdBy;
}

export interface InterviewTemplatesPaginated_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  interviewTemplates: InterviewTemplatesPaginated_currentUserPrisma_currentOrganization_interviewTemplates[];
}

export interface InterviewTemplatesPaginated_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: InterviewTemplatesPaginated_currentUserPrisma_currentOrganization | null;
}

export interface InterviewTemplatesPaginated {
  currentUserPrisma: InterviewTemplatesPaginated_currentUserPrisma | null;
}

export interface InterviewTemplatesPaginatedVariables {
  search?: string | null;
  offset?: number | null;
  limit?: number | null;
  status?: InterviewTemplateStatusEnum | null;
  department?: GraphQL_UUID[] | null;
  orderBy?: OrganizationInterviewTemplateOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllOrganizationInterviewTemplateIds
// ====================================================

export interface AllOrganizationInterviewTemplateIds_currentUserPrisma_currentOrganization_interviewTemplates {
  __typename: "InterviewTemplate";
  id: string;
}

export interface AllOrganizationInterviewTemplateIds_currentUserPrisma_currentOrganization {
  __typename: "Organization";
  id: string;
  interviewTemplates: AllOrganizationInterviewTemplateIds_currentUserPrisma_currentOrganization_interviewTemplates[];
}

export interface AllOrganizationInterviewTemplateIds_currentUserPrisma {
  __typename: "User";
  id: string;
  currentOrganization: AllOrganizationInterviewTemplateIds_currentUserPrisma_currentOrganization | null;
}

export interface AllOrganizationInterviewTemplateIds {
  currentUserPrisma: AllOrganizationInterviewTemplateIds_currentUserPrisma | null;
}

export interface AllOrganizationInterviewTemplateIdsVariables {
  search?: string | null;
  status?: InterviewTemplateStatusEnum | null;
  department?: GraphQL_UUID[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Teammates
// ====================================================

export interface Teammates_teammates_personalProfile_userMembership_membershipRoles {
  __typename: "Role";
  id: string;
  name: string;
}

export interface Teammates_teammates_personalProfile_userMembership {
  __typename: "UserMembership";
  id: string;
  membershipRoles: Teammates_teammates_personalProfile_userMembership_membershipRoles[];
  active: boolean;
  createdAt: GraphQL_DateTime;
  canChangeRole: boolean;
}

export interface Teammates_teammates_personalProfile {
  __typename: "Interviewer";
  id: string;
  email: string | null;
  name: string;
  imageUrl: string;
  lastUserUpdatedAt: GraphQL_DateTime | null;
  userMembership: Teammates_teammates_personalProfile_userMembership | null;
  lastRequestedUserUpdateAt: GraphQL_DateTime | null;
}

export interface Teammates_teammates_invitation_role {
  __typename: "Role";
  id: string;
  name: string;
}

export interface Teammates_teammates_invitation_inviter {
  __typename: "User";
  id: string;
  fullName: string;
  email: string;
}

export interface Teammates_teammates_invitation {
  __typename: "UserInvitation";
  id: string;
  email: string;
  createdAt: GraphQL_DateTime;
  role: Teammates_teammates_invitation_role;
  inviter: Teammates_teammates_invitation_inviter;
  canChangeRole: boolean;
}

export interface Teammates_teammates {
  __typename: "Teammate";
  id: string;
  personalProfile: Teammates_teammates_personalProfile | null;
  invitation: Teammates_teammates_invitation | null;
}

export interface Teammates_teammatesAggregate {
  __typename: "TeammateAggregate";
  count: number | null;
}

export interface Teammates_roles {
  __typename: "Role";
  id: string;
  name: string;
}

export interface Teammates {
  teammates: Teammates_teammates[];
  teammatesAggregate: Teammates_teammatesAggregate;
  roles: Teammates_roles[];
}

export interface TeammatesVariables {
  search?: string | null;
  teammateType: TeammateTypeFilterEnum;
  offset?: number | null;
  limit?: number | null;
  orderBy?: TeammateOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllTeammateIds
// ====================================================

export interface AllTeammateIds_teammates {
  __typename: "Teammate";
  id: string;
}

export interface AllTeammateIds {
  teammates: AllTeammateIds_teammates[];
}

export interface AllTeammateIdsVariables {
  search?: string | null;
  teammateType: TeammateTypeFilterEnum;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckLoggedInSignup
// ====================================================

export interface CheckLoggedInSignup_currentUserPrisma {
  __typename: "User";
  id: string;
}

export interface CheckLoggedInSignup {
  currentUserPrisma: CheckLoggedInSignup_currentUserPrisma | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetContentTemplateForEditing
// ====================================================

export interface GetContentTemplateForEditing_currentOrganization_contentTemplate_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
}

export interface GetContentTemplateForEditing_currentOrganization_contentTemplate_modules {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface GetContentTemplateForEditing_currentOrganization_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  emailTemplate: GetContentTemplateForEditing_currentOrganization_contentTemplate_emailTemplate | null;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered. Also includes defaults and additional
   *     resolved data needed by our Content Template editor. We should instead fetch the data
   *     needed for the editor directly from those frontend components, and switch to simply using 
   *     "modulesInflated" instead
   */
  modules: GetContentTemplateForEditing_currentOrganization_contentTemplate_modules[];
  createdBy: string;
  updatedAt: GraphQL_DateTime;
}

export interface GetContentTemplateForEditing_currentOrganization {
  __typename: "Organization";
  id: string;
  customVariableOptions: string[];
  contentTemplate: GetContentTemplateForEditing_currentOrganization_contentTemplate;
}

export interface GetContentTemplateForEditing {
  currentOrganization: GetContentTemplateForEditing_currentOrganization | null;
}

export interface GetContentTemplateForEditingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideTemplateOptionsEditor
// ====================================================

export interface GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_guideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_guideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_guideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_guideTemplateStages_contentTemplate_modulesInflated[];
}

export interface GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_guideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_guideTemplateStages {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplate: GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_guideTemplateStages_postTemplate | null;
  contentTemplate: GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_guideTemplateStages_contentTemplate | null;
  emailTemplate: GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_guideTemplateStages_emailTemplate | null;
  postTemplates: GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates[];
}

export interface GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates[];
}

export interface GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_interviewPlan_items[];
}

export interface GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_guideTemplateLexicalContext {
  __typename: "GuideTemplateLexicalContext";
  id: GraphQL_UUID;
  companyName: string;
}

export interface GuideTemplateOptionsEditor_currentOrganization_guideTemplateById {
  __typename: "GuideTemplate";
  id: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  usage: number;
  isGlobal: boolean;
  department: GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_department | null;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  inProgressGuidesCount: number;
  jobsWithInProgressGuidesCount: number;
  guideTemplateStages: GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_guideTemplateStages[];
  interviewPlan: GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_interviewPlan | null;
  guideTemplateLexicalContext: GuideTemplateOptionsEditor_currentOrganization_guideTemplateById_guideTemplateLexicalContext;
  updatedAt: GraphQL_DateTime;
}

export interface GuideTemplateOptionsEditor_currentOrganization {
  __typename: "Organization";
  id: string;
  guideTemplateById: GuideTemplateOptionsEditor_currentOrganization_guideTemplateById;
}

export interface GuideTemplateOptionsEditor {
  currentOrganization: GuideTemplateOptionsEditor_currentOrganization | null;
}

export interface GuideTemplateOptionsEditorVariables {
  guideTemplateId: string;
  isZeus: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideTemplateEditor
// ====================================================

export interface GuideTemplateEditor_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface GuideTemplateEditor_currentOrganization_customer {
  __typename: "Customer";
  id: string;
  stageAutomationEnabled: boolean;
}

export interface GuideTemplateEditor_currentOrganization_guideTemplateById_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface GuideTemplateEditor_currentOrganization_guideTemplateById_guideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface GuideTemplateEditor_currentOrganization_guideTemplateById_guideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface GuideTemplateEditor_currentOrganization_guideTemplateById_guideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: GuideTemplateEditor_currentOrganization_guideTemplateById_guideTemplateStages_contentTemplate_modulesInflated[];
}

export interface GuideTemplateEditor_currentOrganization_guideTemplateById_guideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface GuideTemplateEditor_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface GuideTemplateEditor_currentOrganization_guideTemplateById_guideTemplateStages {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplate: GuideTemplateEditor_currentOrganization_guideTemplateById_guideTemplateStages_postTemplate | null;
  contentTemplate: GuideTemplateEditor_currentOrganization_guideTemplateById_guideTemplateStages_contentTemplate | null;
  emailTemplate: GuideTemplateEditor_currentOrganization_guideTemplateById_guideTemplateStages_emailTemplate | null;
  postTemplates: GuideTemplateEditor_currentOrganization_guideTemplateById_guideTemplateStages_postTemplates[];
}

export interface GuideTemplateEditor_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface GuideTemplateEditor_currentOrganization_guideTemplateById_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: GuideTemplateEditor_currentOrganization_guideTemplateById_interviewPlan_items_postTemplates[];
}

export interface GuideTemplateEditor_currentOrganization_guideTemplateById_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: GuideTemplateEditor_currentOrganization_guideTemplateById_interviewPlan_items[];
}

export interface GuideTemplateEditor_currentOrganization_guideTemplateById_guideTemplateLexicalContext {
  __typename: "GuideTemplateLexicalContext";
  id: GraphQL_UUID;
  companyName: string;
}

export interface GuideTemplateEditor_currentOrganization_guideTemplateById {
  __typename: "GuideTemplate";
  id: string;
  isGlobal: boolean;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  usage: number;
  department: GuideTemplateEditor_currentOrganization_guideTemplateById_department | null;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  inProgressGuidesCount: number;
  jobsWithInProgressGuidesCount: number;
  guideTemplateStages: GuideTemplateEditor_currentOrganization_guideTemplateById_guideTemplateStages[];
  interviewPlan: GuideTemplateEditor_currentOrganization_guideTemplateById_interviewPlan | null;
  guideTemplateLexicalContext: GuideTemplateEditor_currentOrganization_guideTemplateById_guideTemplateLexicalContext;
  updatedAt: GraphQL_DateTime;
}

export interface GuideTemplateEditor_currentOrganization {
  __typename: "Organization";
  id: string;
  defaultAvatarImageUrl: string;
  theme: GuideTemplateEditor_currentOrganization_theme | null;
  customer: GuideTemplateEditor_currentOrganization_customer;
  companyLogoUrl: string;
  name: string;
  guideTemplateById: GuideTemplateEditor_currentOrganization_guideTemplateById;
}

export interface GuideTemplateEditor {
  currentOrganization: GuideTemplateEditor_currentOrganization | null;
}

export interface GuideTemplateEditorVariables {
  guideTemplateId: string;
  isZeus: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideTemplatesFilterData
// ====================================================

export interface GuideTemplatesFilterData_atsDepartments {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface GuideTemplatesFilterData {
  atsDepartments: GuideTemplatesFilterData_atsDepartments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuideTemplates
// ====================================================

export interface GuideTemplates_currentOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
}

export interface GuideTemplates_currentOrganization_guideTemplatesView_createdBy_user {
  __typename: "User";
  id: string;
  fullName: string;
  imageUrl: string;
}

export interface GuideTemplates_currentOrganization_guideTemplatesView_createdBy {
  __typename: "UserMembership";
  id: string;
  user: GuideTemplates_currentOrganization_guideTemplatesView_createdBy_user;
}

export interface GuideTemplates_currentOrganization_guideTemplatesView_updatedBy_user {
  __typename: "User";
  id: string;
  fullName: string;
  imageUrl: string;
}

export interface GuideTemplates_currentOrganization_guideTemplatesView_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: GuideTemplates_currentOrganization_guideTemplatesView_updatedBy_user;
}

export interface GuideTemplates_currentOrganization_guideTemplatesView_organization {
  __typename: "Organization";
  id: string;
}

export interface GuideTemplates_currentOrganization_guideTemplatesView_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface GuideTemplates_currentOrganization_guideTemplatesView {
  __typename: "GuideTemplateView";
  id: string;
  name: string;
  archivedAt: GraphQL_DateTime | null;
  createdBy: GuideTemplates_currentOrganization_guideTemplatesView_createdBy | null;
  updatedBy: GuideTemplates_currentOrganization_guideTemplatesView_updatedBy | null;
  organization: GuideTemplates_currentOrganization_guideTemplatesView_organization | null;
  updatedAt: GraphQL_DateTime;
  usage: number;
  department: GuideTemplates_currentOrganization_guideTemplatesView_department | null;
  isGlobal: boolean;
}

export interface GuideTemplates_currentOrganization {
  __typename: "Organization";
  id: string;
  name: string;
  companyLogoUrl: string;
  theme: GuideTemplates_currentOrganization_theme | null;
  guideTemplatesView: GuideTemplates_currentOrganization_guideTemplatesView[];
}

export interface GuideTemplates {
  currentOrganization: GuideTemplates_currentOrganization | null;
}

export interface GuideTemplatesVariables {
  search?: string | null;
  offset?: number | null;
  limit?: number | null;
  status?: GuideTemplateStatusEnum | null;
  department?: GraphQL_UUID[] | null;
  orderBy?: OrganizationGuideTemplatesOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllOrganizationGuideTemplateIds
// ====================================================

export interface AllOrganizationGuideTemplateIds_currentOrganization_guideTemplatesView {
  __typename: "GuideTemplateView";
  id: string;
}

export interface AllOrganizationGuideTemplateIds_currentOrganization {
  __typename: "Organization";
  id: string;
  guideTemplatesView: AllOrganizationGuideTemplateIds_currentOrganization_guideTemplatesView[];
}

export interface AllOrganizationGuideTemplateIds {
  currentOrganization: AllOrganizationGuideTemplateIds_currentOrganization | null;
}

export interface AllOrganizationGuideTemplateIdsVariables {
  search?: string | null;
  status?: GuideTemplateStatusEnum | null;
  department?: GraphQL_UUID[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PostTemplatesFilterData
// ====================================================

export interface PostTemplatesFilterData_atsDepartments {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface PostTemplatesFilterData {
  atsDepartments: PostTemplatesFilterData_atsDepartments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PostTemplatesPaginated
// ====================================================

export interface PostTemplatesPaginated_currentOrganization_postTemplates_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface PostTemplatesPaginated_currentOrganization_postTemplates_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: PostTemplatesPaginated_currentOrganization_postTemplates_updatedBy_user;
}

export interface PostTemplatesPaginated_currentOrganization_postTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface PostTemplatesPaginated_currentOrganization_postTemplates_createdBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface PostTemplatesPaginated_currentOrganization_postTemplates_createdBy {
  __typename: "UserMembership";
  id: string;
  user: PostTemplatesPaginated_currentOrganization_postTemplates_createdBy_user;
}

export interface PostTemplatesPaginated_currentOrganization_postTemplates_organization {
  __typename: "Organization";
  id: string;
}

export interface PostTemplatesPaginated_currentOrganization_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  archivedAt: GraphQL_DateTime | null;
  status: PostTemplateStatusEnum | null;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedBy: PostTemplatesPaginated_currentOrganization_postTemplates_updatedBy;
  department: PostTemplatesPaginated_currentOrganization_postTemplates_department | null;
  createdBy: PostTemplatesPaginated_currentOrganization_postTemplates_createdBy;
  organization: PostTemplatesPaginated_currentOrganization_postTemplates_organization;
}

export interface PostTemplatesPaginated_currentOrganization {
  __typename: "Organization";
  id: string;
  name: string;
  defaultAvatarImageUrl: string;
  companyLogoUrl: string;
  postTemplates: PostTemplatesPaginated_currentOrganization_postTemplates[];
}

export interface PostTemplatesPaginated {
  currentOrganization: PostTemplatesPaginated_currentOrganization | null;
}

export interface PostTemplatesPaginatedVariables {
  search?: string | null;
  status?: PostTemplateStatusEnum | null;
  departmentIds?: GraphQL_UUID[] | null;
  offset?: number | null;
  limit?: number | null;
  orderBy?: OrganizationPostTemplatesOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PostTemplateForEdit
// ====================================================

export interface PostTemplateForEdit_currentOrganization_postTemplateById_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface PostTemplateForEdit_currentOrganization_postTemplateById_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: PostTemplateForEdit_currentOrganization_postTemplateById_updatedBy_user;
}

export interface PostTemplateForEdit_currentOrganization_postTemplateById_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface PostTemplateForEdit_currentOrganization_postTemplateById_createdBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface PostTemplateForEdit_currentOrganization_postTemplateById_createdBy {
  __typename: "UserMembership";
  id: string;
  user: PostTemplateForEdit_currentOrganization_postTemplateById_createdBy_user;
}

export interface PostTemplateForEdit_currentOrganization_postTemplateById_organization {
  __typename: "Organization";
  id: string;
}

export interface PostTemplateForEdit_currentOrganization_postTemplateById {
  __typename: "PostTemplate";
  id: string;
  name: string;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  archivedAt: GraphQL_DateTime | null;
  status: PostTemplateStatusEnum | null;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedBy: PostTemplateForEdit_currentOrganization_postTemplateById_updatedBy;
  department: PostTemplateForEdit_currentOrganization_postTemplateById_department | null;
  createdBy: PostTemplateForEdit_currentOrganization_postTemplateById_createdBy;
  organization: PostTemplateForEdit_currentOrganization_postTemplateById_organization;
}

export interface PostTemplateForEdit_currentOrganization {
  __typename: "Organization";
  id: string;
  postTemplateById: PostTemplateForEdit_currentOrganization_postTemplateById;
}

export interface PostTemplateForEdit {
  currentOrganization: PostTemplateForEdit_currentOrganization | null;
}

export interface PostTemplateForEditVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllOrganizationPostTemplateIds
// ====================================================

export interface AllOrganizationPostTemplateIds_currentOrganization_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface AllOrganizationPostTemplateIds_currentOrganization {
  __typename: "Organization";
  id: string;
  postTemplates: AllOrganizationPostTemplateIds_currentOrganization_postTemplates[];
}

export interface AllOrganizationPostTemplateIds {
  currentOrganization: AllOrganizationPostTemplateIds_currentOrganization | null;
}

export interface AllOrganizationPostTemplateIdsVariables {
  search?: string | null;
  status?: PostTemplateStatusEnum | null;
  departmentIds?: GraphQL_UUID[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CustomerForIntegrationsPage
// ====================================================

export interface CustomerForIntegrationsPage_atssyncAccounts {
  __typename: "AtssyncAccount";
  id: string;
  type: AtssyncAccountType;
  apiKeySuffix: string;
}

export interface CustomerForIntegrationsPage {
  __typename: "Customer";
  id: string;
  atssyncAccounts: CustomerForIntegrationsPage_atssyncAccounts[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideAboutFragment
// ====================================================

export interface GuideAboutFragment_defaultContent {
  __typename: "GuideContent";
  id: string;
  data: GraphQL_JSON;
}

export interface GuideAboutFragment_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface GuideAboutFragment_candidate {
  __typename: "Candidate";
  id: string;
  organization: GuideAboutFragment_candidate_organization;
}

export interface GuideAboutFragment_guideLexicalContext_recruiterName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuideAboutFragment_guideLexicalContext_candidateName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuideAboutFragment_guideLexicalContext_coordinatorName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuideAboutFragment_guideLexicalContext {
  __typename: "GuideLexicalContext";
  id: GraphQL_UUID;
  href: string;
  recruiterName: GuideAboutFragment_guideLexicalContext_recruiterName;
  candidateName: GuideAboutFragment_guideLexicalContext_candidateName;
  candidatePhone: string | null;
  coordinatorName: GuideAboutFragment_guideLexicalContext_coordinatorName | null;
  jobTitle: string;
  jobListingUrl: string | null;
  companyName: string;
}

export interface GuideAboutFragment {
  __typename: "Guide";
  defaultContent: GuideAboutFragment_defaultContent | null;
  candidate: GuideAboutFragment_candidate;
  id: string;
  guideLexicalContext: GuideAboutFragment_guideLexicalContext;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuidePostToEditGuidePostFragment
// ====================================================

export interface GuidePostToEditGuidePostFragment_guide_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
  companyLogoUrl: string;
}

export interface GuidePostToEditGuidePostFragment_guide_candidate {
  __typename: "Candidate";
  id: string;
  organization: GuidePostToEditGuidePostFragment_guide_candidate_organization;
  name: string;
  imageUrl: string;
}

export interface GuidePostToEditGuidePostFragment_guide_stages_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface GuidePostToEditGuidePostFragment_guide_stages_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
}

export interface GuidePostToEditGuidePostFragment_guide_stages {
  __typename: "Stage";
  id: string;
  configured: boolean;
  title: string;
  status: StageStatus;
  isAtsCurrent: boolean;
  hidden: boolean;
  hasAutomation: boolean;
  position: number;
  atsStage: GuidePostToEditGuidePostFragment_guide_stages_atsStage | null;
  guide: GuidePostToEditGuidePostFragment_guide_stages_guide;
}

export interface GuidePostToEditGuidePostFragment_guide_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  hasAutomation: boolean;
  configured: boolean;
  title: string;
  status: StageStatus;
  position: number;
}

export interface GuidePostToEditGuidePostFragment_guide_guideLexicalContext_recruiterName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuidePostToEditGuidePostFragment_guide_guideLexicalContext_candidateName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuidePostToEditGuidePostFragment_guide_guideLexicalContext_coordinatorName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuidePostToEditGuidePostFragment_guide_guideLexicalContext {
  __typename: "GuideLexicalContext";
  id: GraphQL_UUID;
  href: string;
  recruiterName: GuidePostToEditGuidePostFragment_guide_guideLexicalContext_recruiterName;
  candidateName: GuidePostToEditGuidePostFragment_guide_guideLexicalContext_candidateName;
  candidatePhone: string | null;
  coordinatorName: GuidePostToEditGuidePostFragment_guide_guideLexicalContext_coordinatorName | null;
  jobTitle: string;
  jobListingUrl: string | null;
  companyName: string;
}

export interface GuidePostToEditGuidePostFragment_guide {
  __typename: "Guide";
  id: string;
  candidate: GuidePostToEditGuidePostFragment_guide_candidate;
  guideVersion: number;
  stages: GuidePostToEditGuidePostFragment_guide_stages[];
  status: GuideStatusEnum;
  sortedVisibleStages: GuidePostToEditGuidePostFragment_guide_sortedVisibleStages[];
  guideLexicalContext: GuidePostToEditGuidePostFragment_guide_guideLexicalContext;
}

export interface GuidePostToEditGuidePostFragment_senderUser {
  __typename: "GuidePostUser";
  id: string;
  firstName: string;
  lastName: string;
}

export interface GuidePostToEditGuidePostFragment {
  __typename: "GuidePost";
  id: string;
  data: GraphQL_JSON | null;
  threadId: string | null;
  guide: GuidePostToEditGuidePostFragment_guide;
  senderUser: GuidePostToEditGuidePostFragment_senderUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: HeaderActionsFragment
// ====================================================

export interface HeaderActionsFragment_jobRoleGuideTemplate_pointOfContact {
  __typename: "Interviewer";
  id: string;
  email: string | null;
}

export interface HeaderActionsFragment_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  pointOfContact: HeaderActionsFragment_jobRoleGuideTemplate_pointOfContact | null;
}

export interface HeaderActionsFragment {
  __typename: "Guide";
  id: string;
  atsUrl: string;
  guideVersion: number;
  jobRoleGuideTemplateId: string | null;
  atsApplicationId: string | null;
  jobRoleGuideTemplate: HeaderActionsFragment_jobRoleGuideTemplate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NavUpdatesGuideFragment
// ====================================================

export interface NavUpdatesGuideFragment {
  __typename: "Guide";
  postCount: number;
  unreadPostCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NavInterviewsGuideFragment
// ====================================================

export interface NavInterviewsGuideFragment_upcomingInterviewsV2 {
  __typename: "Event";
  id: string;
}

export interface NavInterviewsGuideFragment {
  __typename: "Guide";
  upcomingInterviewsV2: NavInterviewsGuideFragment_upcomingInterviewsV2[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrgLogoFragment
// ====================================================

export interface OrgLogoFragment {
  __typename: "Organization";
  id: string;
  name: string;
  companyLogoUrl: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrgLogoGuideFragment
// ====================================================

export interface OrgLogoGuideFragment_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
  companyLogoUrl: string;
}

export interface OrgLogoGuideFragment_candidate {
  __typename: "Candidate";
  organization: OrgLogoGuideFragment_candidate_organization;
}

export interface OrgLogoGuideFragment {
  __typename: "Guide";
  candidate: OrgLogoGuideFragment_candidate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideUpdatesCandidateFragment
// ====================================================

export interface GuideUpdatesCandidateFragment {
  __typename: "Candidate";
  id: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ReplyFragment
// ====================================================

export interface ReplyFragment_senderUser {
  __typename: "GuidePostUser";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  email: string;
  isCandidate: boolean;
}

export interface ReplyFragment_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface ReplyFragment_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface ReplyFragment_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: ReplyFragment_replies_senderUser;
}

export interface ReplyFragment {
  __typename: "GuidePost";
  id: string;
  title: string;
  data: GraphQL_JSON | null;
  readAt: GraphQL_DateTime | null;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  threadId: string | null;
  senderUser: ReplyFragment_senderUser;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: ReplyFragment_lastCandidateEmailEvent | null;
  replies: ReplyFragment_replies[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UpdateFragment
// ====================================================

export interface UpdateFragment_senderUser {
  __typename: "GuidePostUser";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  email: string;
  isCandidate: boolean;
}

export interface UpdateFragment_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface UpdateFragment_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
  firstName: string;
  lastName: string;
  imageUrl: string;
  email: string;
}

export interface UpdateFragment_replies_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface UpdateFragment_replies_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface UpdateFragment_replies_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: UpdateFragment_replies_replies_senderUser;
}

export interface UpdateFragment_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: UpdateFragment_replies_senderUser;
  title: string;
  data: GraphQL_JSON | null;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  threadId: string | null;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: UpdateFragment_replies_lastCandidateEmailEvent | null;
  replies: UpdateFragment_replies_replies[];
}

export interface UpdateFragment_recipientProfiles {
  __typename: "GuidePostUser";
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  isCandidate: boolean;
}

export interface UpdateFragment_stage {
  __typename: "Stage";
  id: string;
  title: string;
}

export interface UpdateFragment_previousStage {
  __typename: "Stage";
  id: string;
  title: string;
}

export interface UpdateFragment_guide_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
}

export interface UpdateFragment_guide {
  __typename: "Guide";
  id: string;
  candidate: UpdateFragment_guide_candidate;
}

export interface UpdateFragment {
  __typename: "GuidePost";
  id: string;
  title: string;
  data: GraphQL_JSON | null;
  readAt: GraphQL_DateTime | null;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  threadId: string | null;
  senderUser: UpdateFragment_senderUser;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: UpdateFragment_lastCandidateEmailEvent | null;
  replies: UpdateFragment_replies[];
  recipientProfiles: UpdateFragment_recipientProfiles[];
  stage: UpdateFragment_stage | null;
  previousStage: UpdateFragment_previousStage | null;
  guide: UpdateFragment_guide;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideMetadataForUpdatesFragment
// ====================================================

export interface GuideMetadataForUpdatesFragment_jobRoleGuideTemplate_pointOfContact {
  __typename: "Interviewer";
  id: string;
  email: string | null;
}

export interface GuideMetadataForUpdatesFragment_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  pointOfContact: GuideMetadataForUpdatesFragment_jobRoleGuideTemplate_pointOfContact | null;
}

export interface GuideMetadataForUpdatesFragment_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
}

export interface GuideMetadataForUpdatesFragment {
  __typename: "Guide";
  id: string;
  jobRoleGuideTemplate: GuideMetadataForUpdatesFragment_jobRoleGuideTemplate | null;
  candidate: GuideMetadataForUpdatesFragment_candidate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ViewedIndicatorGuidePostFragment
// ====================================================

export interface ViewedIndicatorGuidePostFragment_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface ViewedIndicatorGuidePostFragment_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface ViewedIndicatorGuidePostFragment_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: ViewedIndicatorGuidePostFragment_replies_senderUser;
}

export interface ViewedIndicatorGuidePostFragment_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface ViewedIndicatorGuidePostFragment {
  __typename: "GuidePost";
  id: string;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: ViewedIndicatorGuidePostFragment_lastCandidateEmailEvent | null;
  replies: ViewedIndicatorGuidePostFragment_replies[];
  readAt: GraphQL_DateTime | null;
  senderUser: ViewedIndicatorGuidePostFragment_senderUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideBaseVariableValuesGuideFragment
// ====================================================

export interface GuideBaseVariableValuesGuideFragment_guideLexicalContext_recruiterName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuideBaseVariableValuesGuideFragment_guideLexicalContext_candidateName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuideBaseVariableValuesGuideFragment_guideLexicalContext_coordinatorName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuideBaseVariableValuesGuideFragment_guideLexicalContext {
  __typename: "GuideLexicalContext";
  id: GraphQL_UUID;
  href: string;
  recruiterName: GuideBaseVariableValuesGuideFragment_guideLexicalContext_recruiterName;
  candidateName: GuideBaseVariableValuesGuideFragment_guideLexicalContext_candidateName;
  candidatePhone: string | null;
  coordinatorName: GuideBaseVariableValuesGuideFragment_guideLexicalContext_coordinatorName | null;
  jobTitle: string;
  jobListingUrl: string | null;
  companyName: string;
}

export interface GuideBaseVariableValuesGuideFragment {
  __typename: "Guide";
  id: string;
  guideLexicalContext: GuideBaseVariableValuesGuideFragment_guideLexicalContext;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UseUnreadMessagesGuidePostReplyFragment
// ====================================================

export interface UseUnreadMessagesGuidePostReplyFragment_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface UseUnreadMessagesGuidePostReplyFragment {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: UseUnreadMessagesGuidePostReplyFragment_senderUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UseUnreadMessagesGuidePostMessageFragment
// ====================================================

export interface UseUnreadMessagesGuidePostMessageFragment_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface UseUnreadMessagesGuidePostMessageFragment_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: UseUnreadMessagesGuidePostMessageFragment_replies_senderUser;
}

export interface UseUnreadMessagesGuidePostMessageFragment_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface UseUnreadMessagesGuidePostMessageFragment {
  __typename: "GuidePost";
  id: string;
  replies: UseUnreadMessagesGuidePostMessageFragment_replies[];
  readAt: GraphQL_DateTime | null;
  senderUser: UseUnreadMessagesGuidePostMessageFragment_senderUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideV2HeaderFragment
// ====================================================

export interface GuideV2HeaderFragment_candidate_organization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface GuideV2HeaderFragment_candidate_organization {
  __typename: "Organization";
  guideDefaultBannerImageUrl: string;
  theme: GuideV2HeaderFragment_candidate_organization_theme | null;
  id: string;
  name: string;
  defaultAvatarImageUrl: string;
}

export interface GuideV2HeaderFragment_candidate {
  __typename: "Candidate";
  id: string;
  organization: GuideV2HeaderFragment_candidate_organization;
  firstName: string;
  lastName: string;
}

export interface GuideV2HeaderFragment_hiringTeam_interviewer {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface GuideV2HeaderFragment_hiringTeam {
  __typename: "HiringTeamMember";
  id: GraphQL_UUID;
  role: HiringTeamRoleEnum;
  isPointOfContact: boolean;
  hidden: boolean;
  interviewer: GuideV2HeaderFragment_hiringTeam_interviewer;
}

export interface GuideV2HeaderFragment {
  __typename: "Guide";
  roleName: string;
  bannerImageUrl: string | null;
  candidate: GuideV2HeaderFragment_candidate;
  id: string;
  candidateOpens: string[];
  hiringTeam: GuideV2HeaderFragment_hiringTeam[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LatestUpdateFragment
// ====================================================

export interface LatestUpdateFragment_latestPost_senderUser {
  __typename: "GuidePostUser";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  email: string;
  isCandidate: boolean;
}

export interface LatestUpdateFragment_latestPost_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface LatestUpdateFragment_latestPost_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
  firstName: string;
  lastName: string;
  imageUrl: string;
  email: string;
}

export interface LatestUpdateFragment_latestPost_replies_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface LatestUpdateFragment_latestPost_replies_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface LatestUpdateFragment_latestPost_replies_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: LatestUpdateFragment_latestPost_replies_replies_senderUser;
}

export interface LatestUpdateFragment_latestPost_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: LatestUpdateFragment_latestPost_replies_senderUser;
  title: string;
  data: GraphQL_JSON | null;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  threadId: string | null;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: LatestUpdateFragment_latestPost_replies_lastCandidateEmailEvent | null;
  replies: LatestUpdateFragment_latestPost_replies_replies[];
}

export interface LatestUpdateFragment_latestPost_recipientProfiles {
  __typename: "GuidePostUser";
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  isCandidate: boolean;
}

export interface LatestUpdateFragment_latestPost_stage {
  __typename: "Stage";
  id: string;
  title: string;
}

export interface LatestUpdateFragment_latestPost_previousStage {
  __typename: "Stage";
  id: string;
  title: string;
}

export interface LatestUpdateFragment_latestPost_guide_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
}

export interface LatestUpdateFragment_latestPost_guide {
  __typename: "Guide";
  id: string;
  candidate: LatestUpdateFragment_latestPost_guide_candidate;
}

export interface LatestUpdateFragment_latestPost {
  __typename: "GuidePost";
  id: string;
  title: string;
  data: GraphQL_JSON | null;
  readAt: GraphQL_DateTime | null;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  threadId: string | null;
  senderUser: LatestUpdateFragment_latestPost_senderUser;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: LatestUpdateFragment_latestPost_lastCandidateEmailEvent | null;
  replies: LatestUpdateFragment_latestPost_replies[];
  recipientProfiles: LatestUpdateFragment_latestPost_recipientProfiles[];
  stage: LatestUpdateFragment_latestPost_stage | null;
  previousStage: LatestUpdateFragment_latestPost_previousStage | null;
  guide: LatestUpdateFragment_latestPost_guide;
}

export interface LatestUpdateFragment {
  __typename: "Guide";
  latestPost: LatestUpdateFragment_latestPost | null;
  postCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CandidateGuideV2
// ====================================================

export interface CandidateGuideV2_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface CandidateGuideV2_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
}

export interface CandidateGuideV2_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: CandidateGuideV2_interviewPlan_items[];
}

export interface CandidateGuideV2 {
  __typename: "GuideV2";
  id: string;
  currentInterviewPlanItemId: string | null;
  jobRoleGuideTemplateId: string;
  status: GuideStatusV2Enum;
  hiringDecisionAt: GraphQL_DateTime | null;
  candidate: CandidateGuideV2_candidate;
  interviewPlan: CandidateGuideV2_interviewPlan | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UpdateLinkFragment
// ====================================================

export interface UpdateLinkFragment_stage {
  __typename: "Stage";
  id: string;
  title: string;
}

export interface UpdateLinkFragment_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface UpdateLinkFragment_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface UpdateLinkFragment_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: UpdateLinkFragment_replies_senderUser;
}

export interface UpdateLinkFragment_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
  imageUrl: string;
  firstName: string;
  lastName: string;
}

export interface UpdateLinkFragment {
  __typename: "GuidePost";
  id: string;
  title: string;
  createdAt: GraphQL_DateTime;
  stage: UpdateLinkFragment_stage | null;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: UpdateLinkFragment_lastCandidateEmailEvent | null;
  replies: UpdateLinkFragment_replies[];
  readAt: GraphQL_DateTime | null;
  senderUser: UpdateLinkFragment_senderUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideInterviewsLayoutFragmentGuide
// ====================================================

export interface GuideInterviewsLayoutFragmentGuide_candidate {
  __typename: "Candidate";
  name: string;
}

export interface GuideInterviewsLayoutFragmentGuide {
  __typename: "Guide";
  id: string;
  roleName: string;
  candidate: GuideInterviewsLayoutFragmentGuide_candidate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RequestProfileUpdateExtensionDetailsProfile
// ====================================================

export interface RequestProfileUpdateExtensionDetailsProfile_userMembership {
  __typename: "UserMembership";
  id: string;
}

export interface RequestProfileUpdateExtensionDetailsProfile_invitation {
  __typename: "UserInvitation";
  id: string;
}

export interface RequestProfileUpdateExtensionDetailsProfile {
  __typename: "Interviewer";
  id: string;
  lastRequestedUserUpdateAt: GraphQL_DateTime | null;
  lastUserUpdatedAt: GraphQL_DateTime | null;
  email: string | null;
  userMembership: RequestProfileUpdateExtensionDetailsProfile_userMembership | null;
  invitation: RequestProfileUpdateExtensionDetailsProfile_invitation | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideManagerUpdateGuideFragment
// ====================================================

export interface GuideManagerUpdateGuideFragment_posts_senderUser {
  __typename: "GuidePostUser";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  isCandidate: boolean;
}

export interface GuideManagerUpdateGuideFragment_posts_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface GuideManagerUpdateGuideFragment_posts_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface GuideManagerUpdateGuideFragment_posts_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: GuideManagerUpdateGuideFragment_posts_replies_senderUser;
}

export interface GuideManagerUpdateGuideFragment_posts {
  __typename: "GuidePost";
  id: string;
  title: string;
  createdAt: GraphQL_DateTime;
  senderUser: GuideManagerUpdateGuideFragment_posts_senderUser;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: GuideManagerUpdateGuideFragment_posts_lastCandidateEmailEvent | null;
  replies: GuideManagerUpdateGuideFragment_posts_replies[];
  readAt: GraphQL_DateTime | null;
}

export interface GuideManagerUpdateGuideFragment {
  __typename: "Guide";
  publicUrl: string;
  posts: GuideManagerUpdateGuideFragment_posts[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideManagerInterviewsGuideFragment
// ====================================================

export interface GuideManagerInterviewsGuideFragment_upcomingInterviewsV2_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface GuideManagerInterviewsGuideFragment_upcomingInterviewsV2_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface GuideManagerInterviewsGuideFragment_upcomingInterviewsV2_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface GuideManagerInterviewsGuideFragment_upcomingInterviewsV2 {
  __typename: "Event";
  id: string;
  eventTemplateInstallationId: string | null;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: GuideManagerInterviewsGuideFragment_upcomingInterviewsV2_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: GuideManagerInterviewsGuideFragment_upcomingInterviewsV2_interviewers[];
  conferencePhone: string | null;
  location: GuideManagerInterviewsGuideFragment_upcomingInterviewsV2_location | null;
}

export interface GuideManagerInterviewsGuideFragment {
  __typename: "Guide";
  id: string;
  upcomingInterviewsV2: GuideManagerInterviewsGuideFragment_upcomingInterviewsV2[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TopBarGuideFragment
// ====================================================

export interface TopBarGuideFragment_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  defaultGuideVersion: number;
}

export interface TopBarGuideFragment_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  domains: string[];
}

export interface TopBarGuideFragment_candidate_organization {
  __typename: "Organization";
  id: string;
  customer: TopBarGuideFragment_candidate_organization_customer;
}

export interface TopBarGuideFragment_candidate {
  __typename: "Candidate";
  id: string;
  organization: TopBarGuideFragment_candidate_organization;
}

export interface TopBarGuideFragment_stages {
  __typename: "Stage";
  id: string;
  status: StageStatus;
  stageTemplateInstallationId: string | null;
}

export interface TopBarGuideFragment {
  __typename: "Guide";
  id: string;
  jobRoleGuideTemplate: TopBarGuideFragment_jobRoleGuideTemplate | null;
  startedAt: GraphQL_DateTime | null;
  candidateOpens: string[];
  status: GuideStatusEnum;
  needsStageConfiguration: boolean;
  publicUrl: string;
  guideVersion: number;
  candidate: TopBarGuideFragment_candidate;
  stages: TopBarGuideFragment_stages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageForGuideInterviewPlanModal
// ====================================================

export interface StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage_postTemplates_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage_postTemplates_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage_postTemplates_updatedBy_user;
}

export interface StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage_postTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage_postTemplates_createdBy_personalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
  imageUrl: string;
}

export interface StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage_postTemplates_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage_postTemplates_createdBy_personalProfile;
}

export interface StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage_postTemplates_updatedBy;
  department: StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage_postTemplates_department | null;
  postTemplateSyncData: StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData | null;
  createdBy: StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage_postTemplates_createdBy;
}

export interface StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  postTemplates: StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage_postTemplates[];
}

export interface StageForGuideInterviewPlanModal {
  __typename: "Stage";
  id: string;
  title: string;
  description: string | null;
  configured: boolean;
  status: StageStatus;
  position: number;
  hidden: boolean;
  jobRoleGuideTemplateStage: StageForGuideInterviewPlanModal_jobRoleGuideTemplateStage | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideTemplateStageForInterviewPlanEditor
// ====================================================

export interface GuideTemplateStageForInterviewPlanEditor_postTemplates_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface GuideTemplateStageForInterviewPlanEditor_postTemplates_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: GuideTemplateStageForInterviewPlanEditor_postTemplates_updatedBy_user;
}

export interface GuideTemplateStageForInterviewPlanEditor_postTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface GuideTemplateStageForInterviewPlanEditor_postTemplates_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface GuideTemplateStageForInterviewPlanEditor_postTemplates_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface GuideTemplateStageForInterviewPlanEditor_postTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: GuideTemplateStageForInterviewPlanEditor_postTemplates_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: GuideTemplateStageForInterviewPlanEditor_postTemplates_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface GuideTemplateStageForInterviewPlanEditor_postTemplates_createdBy_personalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
  imageUrl: string;
}

export interface GuideTemplateStageForInterviewPlanEditor_postTemplates_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: GuideTemplateStageForInterviewPlanEditor_postTemplates_createdBy_personalProfile;
}

export interface GuideTemplateStageForInterviewPlanEditor_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: GuideTemplateStageForInterviewPlanEditor_postTemplates_updatedBy;
  department: GuideTemplateStageForInterviewPlanEditor_postTemplates_department | null;
  postTemplateSyncData: GuideTemplateStageForInterviewPlanEditor_postTemplates_postTemplateSyncData | null;
  createdBy: GuideTemplateStageForInterviewPlanEditor_postTemplates_createdBy;
}

export interface GuideTemplateStageForInterviewPlanEditor {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplates: GuideTemplateStageForInterviewPlanEditor_postTemplates[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JRGTSForInterviewPlanEditor
// ====================================================

export interface JRGTSForInterviewPlanEditor_postTemplates_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface JRGTSForInterviewPlanEditor_postTemplates_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: JRGTSForInterviewPlanEditor_postTemplates_updatedBy_user;
}

export interface JRGTSForInterviewPlanEditor_postTemplates_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface JRGTSForInterviewPlanEditor_postTemplates_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface JRGTSForInterviewPlanEditor_postTemplates_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface JRGTSForInterviewPlanEditor_postTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: JRGTSForInterviewPlanEditor_postTemplates_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: JRGTSForInterviewPlanEditor_postTemplates_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface JRGTSForInterviewPlanEditor_postTemplates_createdBy_personalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
  imageUrl: string;
}

export interface JRGTSForInterviewPlanEditor_postTemplates_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: JRGTSForInterviewPlanEditor_postTemplates_createdBy_personalProfile;
}

export interface JRGTSForInterviewPlanEditor_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: JRGTSForInterviewPlanEditor_postTemplates_updatedBy;
  department: JRGTSForInterviewPlanEditor_postTemplates_department | null;
  postTemplateSyncData: JRGTSForInterviewPlanEditor_postTemplates_postTemplateSyncData | null;
  createdBy: JRGTSForInterviewPlanEditor_postTemplates_createdBy;
}

export interface JRGTSForInterviewPlanEditor_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface JRGTSForInterviewPlanEditor_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface JRGTSForInterviewPlanEditor_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type JRGTSForInterviewPlanEditor_changes_change = JRGTSForInterviewPlanEditor_changes_change_BooleanFieldChange | JRGTSForInterviewPlanEditor_changes_change_StringFieldChange | JRGTSForInterviewPlanEditor_changes_change_StringArrayFieldChange;

export interface JRGTSForInterviewPlanEditor_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: JRGTSForInterviewPlanEditor_changes_change;
}

export interface JRGTSForInterviewPlanEditor {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplates: JRGTSForInterviewPlanEditor_postTemplates[];
  changes: JRGTSForInterviewPlanEditor_changes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideManagerGuide
// ====================================================

export interface GuideManagerGuide_jobRoleGuideTemplate_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
}

export interface GuideManagerGuide_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  atsJob: GuideManagerGuide_jobRoleGuideTemplate_atsJob | null;
  defaultGuideVersion: number;
}

export interface GuideManagerGuide_candidate_organization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface GuideManagerGuide_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  domains: string[];
}

export interface GuideManagerGuide_candidate_organization {
  __typename: "Organization";
  id: string;
  guideDefaultBannerImageUrl: string;
  theme: GuideManagerGuide_candidate_organization_theme | null;
  name: string;
  defaultAvatarImageUrl: string;
  customer: GuideManagerGuide_candidate_organization_customer;
}

export interface GuideManagerGuide_candidate {
  __typename: "Candidate";
  id: string;
  organization: GuideManagerGuide_candidate_organization;
  name: string;
  imageUrl: string;
  firstName: string;
  lastName: string;
}

export interface GuideManagerGuide_stages_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface GuideManagerGuide_stages_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
}

export interface GuideManagerGuide_stages {
  __typename: "Stage";
  id: string;
  configured: boolean;
  title: string;
  status: StageStatus;
  isAtsCurrent: boolean;
  hidden: boolean;
  hasAutomation: boolean;
  position: number;
  atsStage: GuideManagerGuide_stages_atsStage | null;
  guide: GuideManagerGuide_stages_guide;
  description: string | null;
  needsFeedback: boolean;
  stageTemplateInstallationId: string | null;
}

export interface GuideManagerGuide_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  hasAutomation: boolean;
  configured: boolean;
  title: string;
  status: StageStatus;
  position: number;
  hidden: boolean;
  description: string | null;
  needsFeedback: boolean;
}

export interface GuideManagerGuide_defaultContent {
  __typename: "GuideContent";
  data: GraphQL_JSON;
}

export interface GuideManagerGuide_hiringTeam_interviewer {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface GuideManagerGuide_hiringTeam {
  __typename: "HiringTeamMember";
  id: GraphQL_UUID;
  role: HiringTeamRoleEnum;
  isPointOfContact: boolean;
  hidden: boolean;
  interviewer: GuideManagerGuide_hiringTeam_interviewer;
}

export interface GuideManagerGuide_posts_senderUser {
  __typename: "GuidePostUser";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  isCandidate: boolean;
}

export interface GuideManagerGuide_posts_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface GuideManagerGuide_posts_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface GuideManagerGuide_posts_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: GuideManagerGuide_posts_replies_senderUser;
}

export interface GuideManagerGuide_posts {
  __typename: "GuidePost";
  id: string;
  title: string;
  createdAt: GraphQL_DateTime;
  senderUser: GuideManagerGuide_posts_senderUser;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: GuideManagerGuide_posts_lastCandidateEmailEvent | null;
  replies: GuideManagerGuide_posts_replies[];
  readAt: GraphQL_DateTime | null;
}

export interface GuideManagerGuide_upcomingInterviewsV2_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface GuideManagerGuide_upcomingInterviewsV2_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface GuideManagerGuide_upcomingInterviewsV2_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface GuideManagerGuide_upcomingInterviewsV2 {
  __typename: "Event";
  id: string;
  eventTemplateInstallationId: string | null;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: GuideManagerGuide_upcomingInterviewsV2_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: GuideManagerGuide_upcomingInterviewsV2_interviewers[];
  conferencePhone: string | null;
  location: GuideManagerGuide_upcomingInterviewsV2_location | null;
}

export interface GuideManagerGuide_currentStage {
  __typename: "Stage";
  id: string;
}

export interface GuideManagerGuide {
  __typename: "Guide";
  id: string;
  startedAt: GraphQL_DateTime | null;
  candidateOpens: string[];
  jobRoleGuideTemplate: GuideManagerGuide_jobRoleGuideTemplate | null;
  candidate: GuideManagerGuide_candidate;
  guideVersion: number;
  stages: GuideManagerGuide_stages[];
  status: GuideStatusEnum;
  sortedVisibleStages: GuideManagerGuide_sortedVisibleStages[];
  roleName: string;
  bannerImageUrl: string | null;
  defaultContent: GuideManagerGuide_defaultContent | null;
  invitedAt: GraphQL_DateTime | null;
  inviteOpenedAt: GraphQL_DateTime | null;
  hiringTeam: GuideManagerGuide_hiringTeam[];
  publicUrl: string;
  posts: GuideManagerGuide_posts[];
  upcomingInterviewsV2: GuideManagerGuide_upcomingInterviewsV2[];
  jobRoleGuideTemplateId: string | null;
  statusV2: GuideStatusV2Enum;
  hiringDecisionAt: GraphQL_DateTime | null;
  currentStage: GuideManagerGuide_currentStage | null;
  needsStageConfiguration: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideManagerGuideV2
// ====================================================

export interface GuideManagerGuideV2_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface GuideManagerGuideV2_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
}

export interface GuideManagerGuideV2_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: GuideManagerGuideV2_interviewPlan_items[];
}

export interface GuideManagerGuideV2 {
  __typename: "GuideV2";
  id: string;
  jobRoleGuideTemplateId: string;
  currentInterviewPlanItemId: string | null;
  roleName: string;
  candidate: GuideManagerGuideV2_candidate;
  status: GuideStatusV2Enum;
  interviewPlan: GuideManagerGuideV2_interviewPlan | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MoveToStageStageFragment
// ====================================================

export interface MoveToStageStageFragment {
  __typename: "Stage";
  id: string;
  hasAutomation: boolean;
  configured: boolean;
  title: string;
  status: StageStatus;
  position: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MoveToStageGuideFragment
// ====================================================

export interface MoveToStageGuideFragment_candidate {
  __typename: "Candidate";
  id: string;
}

export interface MoveToStageGuideFragment_stages {
  __typename: "Stage";
  id: string;
  hasAutomation: boolean;
  configured: boolean;
  title: string;
  status: StageStatus;
  position: number;
}

export interface MoveToStageGuideFragment_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  hasAutomation: boolean;
  configured: boolean;
  title: string;
  status: StageStatus;
  position: number;
}

export interface MoveToStageGuideFragment {
  __typename: "Guide";
  id: string;
  guideVersion: number;
  candidate: MoveToStageGuideFragment_candidate;
  stages: MoveToStageGuideFragment_stages[];
  sortedVisibleStages: MoveToStageGuideFragment_sortedVisibleStages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideForPostFragment
// ====================================================

export interface GuideForPostFragment_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
  companyLogoUrl: string;
}

export interface GuideForPostFragment_candidate {
  __typename: "Candidate";
  id: string;
  organization: GuideForPostFragment_candidate_organization;
  name: string;
  imageUrl: string;
}

export interface GuideForPostFragment_stages_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface GuideForPostFragment_stages_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
}

export interface GuideForPostFragment_stages {
  __typename: "Stage";
  id: string;
  configured: boolean;
  title: string;
  status: StageStatus;
  isAtsCurrent: boolean;
  hidden: boolean;
  hasAutomation: boolean;
  position: number;
  atsStage: GuideForPostFragment_stages_atsStage | null;
  guide: GuideForPostFragment_stages_guide;
}

export interface GuideForPostFragment_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  hasAutomation: boolean;
  configured: boolean;
  title: string;
  status: StageStatus;
  position: number;
}

export interface GuideForPostFragment_guideLexicalContext_recruiterName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuideForPostFragment_guideLexicalContext_candidateName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuideForPostFragment_guideLexicalContext_coordinatorName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuideForPostFragment_guideLexicalContext {
  __typename: "GuideLexicalContext";
  id: GraphQL_UUID;
  href: string;
  recruiterName: GuideForPostFragment_guideLexicalContext_recruiterName;
  candidateName: GuideForPostFragment_guideLexicalContext_candidateName;
  candidatePhone: string | null;
  coordinatorName: GuideForPostFragment_guideLexicalContext_coordinatorName | null;
  jobTitle: string;
  jobListingUrl: string | null;
  companyName: string;
}

export interface GuideForPostFragment {
  __typename: "Guide";
  id: string;
  candidate: GuideForPostFragment_candidate;
  guideVersion: number;
  stages: GuideForPostFragment_stages[];
  status: GuideStatusEnum;
  sortedVisibleStages: GuideForPostFragment_sortedVisibleStages[];
  guideLexicalContext: GuideForPostFragment_guideLexicalContext;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserForPostFragment
// ====================================================

export interface UserForPostFragment {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: HeaderAvatarsGuideFragment
// ====================================================

export interface HeaderAvatarsGuideFragment_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
  defaultAvatarImageUrl: string;
}

export interface HeaderAvatarsGuideFragment_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  lastName: string;
  organization: HeaderAvatarsGuideFragment_candidate_organization;
}

export interface HeaderAvatarsGuideFragment {
  __typename: "Guide";
  id: string;
  candidate: HeaderAvatarsGuideFragment_candidate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: HeaderHiringTeamGuideFragment
// ====================================================

export interface HeaderHiringTeamGuideFragment_hiringTeam_interviewer {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface HeaderHiringTeamGuideFragment_hiringTeam {
  __typename: "HiringTeamMember";
  id: GraphQL_UUID;
  role: HiringTeamRoleEnum;
  isPointOfContact: boolean;
  hidden: boolean;
  interviewer: HeaderHiringTeamGuideFragment_hiringTeam_interviewer;
}

export interface HeaderHiringTeamGuideFragment_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
  defaultAvatarImageUrl: string;
}

export interface HeaderHiringTeamGuideFragment_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  lastName: string;
  organization: HeaderHiringTeamGuideFragment_candidate_organization;
}

export interface HeaderHiringTeamGuideFragment {
  __typename: "Guide";
  id: string;
  candidateOpens: string[];
  hiringTeam: HeaderHiringTeamGuideFragment_hiringTeam[];
  candidate: HeaderHiringTeamGuideFragment_candidate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: HiringPlanStageFragment
// ====================================================

export interface HiringPlanStageFragment {
  __typename: "Stage";
  id: string;
  title: string;
  description: string | null;
  needsFeedback: boolean;
  hasAutomation: boolean;
  configured: boolean;
  status: StageStatus;
  position: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: HiringPlanGuideFragment
// ====================================================

export interface HiringPlanGuideFragment_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface HiringPlanGuideFragment_currentStage {
  __typename: "Stage";
  id: string;
}

export interface HiringPlanGuideFragment_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  hidden: boolean;
  title: string;
  description: string | null;
  needsFeedback: boolean;
  hasAutomation: boolean;
  configured: boolean;
  status: StageStatus;
  position: number;
}

export interface HiringPlanGuideFragment_stages {
  __typename: "Stage";
  id: string;
  title: string;
  description: string | null;
  needsFeedback: boolean;
  hasAutomation: boolean;
  configured: boolean;
  status: StageStatus;
  position: number;
}

export interface HiringPlanGuideFragment {
  __typename: "Guide";
  id: string;
  guideVersion: number;
  jobRoleGuideTemplateId: string | null;
  statusV2: GuideStatusV2Enum;
  hiringDecisionAt: GraphQL_DateTime | null;
  candidate: HiringPlanGuideFragment_candidate;
  currentStage: HiringPlanGuideFragment_currentStage | null;
  sortedVisibleStages: HiringPlanGuideFragment_sortedVisibleStages[];
  stages: HiringPlanGuideFragment_stages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EditableInterviewItemCardInterviewFragment
// ====================================================

export interface EditableInterviewItemCardInterviewFragment_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface EditableInterviewItemCardInterviewFragment_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface EditableInterviewItemCardInterviewFragment_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
}

export interface EditableInterviewItemCardInterviewFragment_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface EditableInterviewItemCardInterviewFragment {
  __typename: "Event";
  id: string;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: EditableInterviewItemCardInterviewFragment_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: EditableInterviewItemCardInterviewFragment_interviewers[];
  eventTemplate: EditableInterviewItemCardInterviewFragment_eventTemplate | null;
  conferencePhone: string | null;
  location: EditableInterviewItemCardInterviewFragment_location | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EditableInterviewListInterviewFragment
// ====================================================

export interface EditableInterviewListInterviewFragment_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface EditableInterviewListInterviewFragment_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface EditableInterviewListInterviewFragment_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
}

export interface EditableInterviewListInterviewFragment_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface EditableInterviewListInterviewFragment {
  __typename: "Event";
  id: string;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: EditableInterviewListInterviewFragment_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: EditableInterviewListInterviewFragment_interviewers[];
  eventTemplate: EditableInterviewListInterviewFragment_eventTemplate | null;
  conferencePhone: string | null;
  location: EditableInterviewListInterviewFragment_location | null;
  collaborativeCodingUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewDetailsAttendeesInterviewerFragment
// ====================================================

export interface InterviewDetailsAttendeesInterviewerFragment {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewDetailsInterviewerFragment
// ====================================================

export interface InterviewDetailsInterviewerFragment {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewDetailsInterviewFragment
// ====================================================

export interface InterviewDetailsInterviewFragment_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface InterviewDetailsInterviewFragment_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface InterviewDetailsInterviewFragment {
  __typename: "Event";
  id: string;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  interviewers: InterviewDetailsInterviewFragment_interviewers[];
  conferenceUrl: string | null;
  conferencePhone: string | null;
  collaborativeCodingUrl: string | null;
  location: InterviewDetailsInterviewFragment_location | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewDetailsLocationInterviewFragment
// ====================================================

export interface InterviewDetailsLocationInterviewFragment_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface InterviewDetailsLocationInterviewFragment {
  __typename: "Event";
  id: string;
  conferenceUrl: string | null;
  conferencePhone: string | null;
  collaborativeCodingUrl: string | null;
  location: InterviewDetailsLocationInterviewFragment_location | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewerCardInterviewerFragment
// ====================================================

export interface InterviewerCardInterviewerFragment {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewerDetailsDialogInterviewerFragment
// ====================================================

export interface InterviewerDetailsDialogInterviewerFragment {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewItemCardInterviewFragment
// ====================================================

export interface InterviewItemCardInterviewFragment_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface InterviewItemCardInterviewFragment_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface InterviewItemCardInterviewFragment_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface InterviewItemCardInterviewFragment {
  __typename: "Event";
  id: string;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: InterviewItemCardInterviewFragment_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: InterviewItemCardInterviewFragment_interviewers[];
  conferencePhone: string | null;
  location: InterviewItemCardInterviewFragment_location | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewItemCardInterviewerFragment
// ====================================================

export interface InterviewItemCardInterviewerFragment {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewItemCardLocationInterviewFragment
// ====================================================

export interface InterviewItemCardLocationInterviewFragment_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface InterviewItemCardLocationInterviewFragment {
  __typename: "Event";
  id: string;
  conferenceUrl: string | null;
  conferencePhone: string | null;
  location: InterviewItemCardLocationInterviewFragment_location | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewListInterviewFragment
// ====================================================

export interface InterviewListInterviewFragment_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface InterviewListInterviewFragment_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface InterviewListInterviewFragment_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface InterviewListInterviewFragment {
  __typename: "Event";
  id: string;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: InterviewListInterviewFragment_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: InterviewListInterviewFragment_interviewers[];
  conferencePhone: string | null;
  location: InterviewListInterviewFragment_location | null;
  collaborativeCodingUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewPlanItemFragment
// ====================================================

export interface InterviewPlanItemFragment {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewPlanFragment
// ====================================================

export interface InterviewPlanFragment_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
}

export interface InterviewPlanFragment {
  __typename: "InterviewPlan";
  id: string;
  items: InterviewPlanFragment_items[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AttachedFilesForRichBlocks
// ====================================================

export interface AttachedFilesForRichBlocks {
  __typename: "EmailAttachedFile";
  id: GraphQL_UUID;
  filename: string;
  content: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewsRichBlockInterviewFragment
// ====================================================

export interface InterviewsRichBlockInterviewFragment_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface InterviewsRichBlockInterviewFragment_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface InterviewsRichBlockInterviewFragment_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface InterviewsRichBlockInterviewFragment_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
}

export interface InterviewsRichBlockInterviewFragment_icsFile {
  __typename: "EmailAttachedFile";
  id: GraphQL_UUID;
  filename: string;
  content: string;
}

export interface InterviewsRichBlockInterviewFragment {
  __typename: "Event";
  id: string;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: InterviewsRichBlockInterviewFragment_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: InterviewsRichBlockInterviewFragment_interviewers[];
  conferencePhone: string | null;
  location: InterviewsRichBlockInterviewFragment_location | null;
  collaborativeCodingUrl: string | null;
  eventTemplate: InterviewsRichBlockInterviewFragment_eventTemplate | null;
  icsFile: InterviewsRichBlockInterviewFragment_icsFile;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AvailabilityRequestCalendarEventFragment
// ====================================================

export interface AvailabilityRequestCalendarEventFragment_createdBy {
  __typename: "UserMembership";
  id: string;
}

export interface AvailabilityRequestCalendarEventFragment {
  __typename: "CalendarEvent";
  id: string;
  title: string;
  start: GraphQL_DateTime;
  end: GraphQL_DateTime;
  allDay: boolean;
  createdBy: AvailabilityRequestCalendarEventFragment_createdBy | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AvailabilityRequestFragment
// ====================================================

export interface AvailabilityRequestFragment_calendarEvents_createdBy {
  __typename: "UserMembership";
  id: string;
}

export interface AvailabilityRequestFragment_calendarEvents {
  __typename: "CalendarEvent";
  id: string;
  title: string;
  start: GraphQL_DateTime;
  end: GraphQL_DateTime;
  allDay: boolean;
  createdBy: AvailabilityRequestFragment_calendarEvents_createdBy | null;
}

export interface AvailabilityRequestFragment_candidateAvailabilities {
  __typename: "CandidateAvailability";
  id: string;
}

export interface AvailabilityRequestFragment {
  __typename: "AvailabilityRequest";
  id: string;
  title: string;
  description: string | null;
  createMultipleEventsOnDrag: boolean;
  forceResponsesInIncrements: boolean;
  overlayCalendars: boolean;
  calendars: string[];
  allowSelectionOutsideSuggestions: boolean;
  calendarEvents: AvailabilityRequestFragment_calendarEvents[];
  candidateAvailabilities: AvailabilityRequestFragment_candidateAvailabilities[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CandidateAvailabilityCalendarEventFragment
// ====================================================

export interface CandidateAvailabilityCalendarEventFragment_createdBy {
  __typename: "UserMembership";
  id: string;
}

export interface CandidateAvailabilityCalendarEventFragment {
  __typename: "CalendarEvent";
  id: string;
  title: string;
  start: GraphQL_DateTime;
  end: GraphQL_DateTime;
  allDay: boolean;
  createdBy: CandidateAvailabilityCalendarEventFragment_createdBy | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CandidateAvailabilityFragment
// ====================================================

export interface CandidateAvailabilityFragment_calendarEvents_createdBy {
  __typename: "UserMembership";
  id: string;
}

export interface CandidateAvailabilityFragment_calendarEvents {
  __typename: "CalendarEvent";
  id: string;
  title: string;
  start: GraphQL_DateTime;
  end: GraphQL_DateTime;
  allDay: boolean;
  createdBy: CandidateAvailabilityFragment_calendarEvents_createdBy | null;
}

export interface CandidateAvailabilityFragment_availabilityRequest_calendarEvents_createdBy {
  __typename: "UserMembership";
  id: string;
}

export interface CandidateAvailabilityFragment_availabilityRequest_calendarEvents {
  __typename: "CalendarEvent";
  id: string;
  title: string;
  start: GraphQL_DateTime;
  end: GraphQL_DateTime;
  allDay: boolean;
  createdBy: CandidateAvailabilityFragment_availabilityRequest_calendarEvents_createdBy | null;
}

export interface CandidateAvailabilityFragment_availabilityRequest_candidateAvailabilities {
  __typename: "CandidateAvailability";
  id: string;
  createdAt: GraphQL_DateTime;
}

export interface CandidateAvailabilityFragment_availabilityRequest {
  __typename: "AvailabilityRequest";
  id: string;
  title: string;
  description: string | null;
  calendarEvents: CandidateAvailabilityFragment_availabilityRequest_calendarEvents[];
  candidateAvailabilities: CandidateAvailabilityFragment_availabilityRequest_candidateAvailabilities[];
}

export interface CandidateAvailabilityFragment {
  __typename: "CandidateAvailability";
  id: string;
  title: string;
  overlayCalendars: boolean;
  calendarEvents: CandidateAvailabilityFragment_calendarEvents[];
  availabilityRequest: CandidateAvailabilityFragment_availabilityRequest | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdvanceHeaderStage
// ====================================================

export interface AdvanceHeaderStage {
  __typename: "Stage";
  id: string;
  title: string;
  status: StageStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdvanceHeaderGuide
// ====================================================

export interface AdvanceHeaderGuide_currentStage {
  __typename: "Stage";
  id: string;
  title: string;
  status: StageStatus;
}

export interface AdvanceHeaderGuide_candidate {
  __typename: "Candidate";
  id: string;
  imageUrl: string;
  name: string;
}

export interface AdvanceHeaderGuide_stages {
  __typename: "Stage";
  id: string;
  title: string;
  status: StageStatus;
}

export interface AdvanceHeaderGuide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
  currentStage: AdvanceHeaderGuide_currentStage | null;
  candidate: AdvanceHeaderGuide_candidate;
  stages: AdvanceHeaderGuide_stages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdvanceTopBarStage
// ====================================================

export interface AdvanceTopBarStage_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
  guideVersion: number;
  publicUrl: string;
}

export interface AdvanceTopBarStage {
  __typename: "Stage";
  id: string;
  guide: AdvanceTopBarStage_guide;
  status: StageStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AssignInterviewTemplateJobRoleGuideTemplate
// ====================================================

export interface AssignInterviewTemplateJobRoleGuideTemplate_jobRole_atsJob_atsDepartments {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface AssignInterviewTemplateJobRoleGuideTemplate_jobRole_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
  requisitionId: string | null;
  atsDepartments: AssignInterviewTemplateJobRoleGuideTemplate_jobRole_atsJob_atsDepartments[];
}

export interface AssignInterviewTemplateJobRoleGuideTemplate_jobRole {
  __typename: "JobRole";
  id: string;
  atsJob: AssignInterviewTemplateJobRoleGuideTemplate_jobRole_atsJob | null;
}

export interface AssignInterviewTemplateJobRoleGuideTemplate_stageTemplateInstallations_eventTemplates_interviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
}

export interface AssignInterviewTemplateJobRoleGuideTemplate_stageTemplateInstallations_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
  interviewTemplate: AssignInterviewTemplateJobRoleGuideTemplate_stageTemplateInstallations_eventTemplates_interviewTemplate | null;
}

export interface AssignInterviewTemplateJobRoleGuideTemplate_stageTemplateInstallations {
  __typename: "StageTemplateInstallation";
  id: string;
  displayName: string;
  eventTemplates: AssignInterviewTemplateJobRoleGuideTemplate_stageTemplateInstallations_eventTemplates[];
}

export interface AssignInterviewTemplateJobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  updatedAt: GraphQL_DateTime;
  jobRole: AssignInterviewTemplateJobRoleGuideTemplate_jobRole | null;
  stageTemplateInstallations: AssignInterviewTemplateJobRoleGuideTemplate_stageTemplateInstallations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideForCandidatesTableRowFragment
// ====================================================

export interface GuideForCandidatesTableRowFragment_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  emailAddresses: string[];
}

export interface GuideForCandidatesTableRowFragment_upcomingInterviews {
  __typename: "Event";
  id: string;
  displayTitle: string;
  replacementData: GraphQL_JSON;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
}

export interface GuideForCandidatesTableRowFragment_currentStage {
  __typename: "Stage";
  id: string;
  title: string;
  position: number;
}

export interface GuideForCandidatesTableRowFragment_currentAtsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface GuideForCandidatesTableRowFragment {
  __typename: "Guide";
  id: string;
  atsUrl: string;
  publicUrl: string;
  stageCount: number;
  currentStagePosition: number | null;
  status: GuideStatusEnum;
  candidate: GuideForCandidatesTableRowFragment_candidate;
  upcomingInterviews: GuideForCandidatesTableRowFragment_upcomingInterviews[];
  currentStage: GuideForCandidatesTableRowFragment_currentStage | null;
  currentAtsStage: GuideForCandidatesTableRowFragment_currentAtsStage | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationLogoFragment
// ====================================================

export interface OrganizationLogoFragment {
  __typename: "Organization";
  companyLogoUrl: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ParticipantBioInterviewer
// ====================================================

export interface ParticipantBioInterviewer {
  __typename: "Interviewer";
  id: string;
  biography: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ParticipantHeaderInterviewer
// ====================================================

export interface ParticipantHeaderInterviewer {
  __typename: "Interviewer";
  id: string;
  imageUrl: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ParticipantLinksInterviewer
// ====================================================

export interface ParticipantLinksInterviewer {
  __typename: "Interviewer";
  id: string;
  websiteUrls: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ParticipantPronounsInterviewer
// ====================================================

export interface ParticipantPronounsInterviewer {
  __typename: "Interviewer";
  id: string;
  pronoun: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ParticipantTitleInterviewer
// ====================================================

export interface ParticipantTitleInterviewer {
  __typename: "Interviewer";
  id: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ParticipantVideoIntroductionInterviewer
// ====================================================

export interface ParticipantVideoIntroductionInterviewer {
  __typename: "Interviewer";
  id: string;
  videoIntroductionUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PersonalProfile
// ====================================================

export interface PersonalProfile {
  __typename: "Interviewer";
  id: string;
  email: string | null;
  name: string;
  title: string;
  displayTitle: string;
  biography: string;
  pronoun: string | null;
  websiteUrls: string[];
  imageUrl: string;
  videoIntroductionUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EmailActivityRowFragment
// ====================================================

export interface EmailActivityRowFragment_sender {
  __typename: "User";
  id: string;
  imageUrl: string;
  fullName: string;
}

export interface EmailActivityRowFragment {
  __typename: "EmailActivity";
  id: string;
  createdAt: GraphQL_DateTime;
  sentTo: string;
  sentFrom: string;
  subject: string;
  body: string;
  sender: EmailActivityRowFragment_sender | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventDetailsOverriddenEventTemplateInstallationFragment
// ====================================================

export interface EventDetailsOverriddenEventTemplateInstallationFragment_event {
  __typename: "Event";
  id: string;
  displayTitle: string;
  displayDescription: string;
}

export interface EventDetailsOverriddenEventTemplateInstallationFragment {
  __typename: "EventTemplateInstallation";
  id: string;
  title: string;
  description: string;
  templateTitle: string;
  templateDescription: string;
  isOverridden: boolean;
  event: EventDetailsOverriddenEventTemplateInstallationFragment_event | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventHiddenBannerEventFragment
// ====================================================

export interface EventHiddenBannerEventFragment {
  __typename: "Event";
  id: string;
  hidden: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventCategoryTileEventTemplateInstallation
// ====================================================

export interface EventCategoryTileEventTemplateInstallation_event {
  __typename: "Event";
  id: string;
  hidden: boolean;
  conferenceUrl: string | null;
}

export interface EventCategoryTileEventTemplateInstallation {
  __typename: "EventTemplateInstallation";
  id: string;
  hidden: boolean;
  event: EventCategoryTileEventTemplateInstallation_event | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventHeaderEvent
// ====================================================

export interface EventHeaderEvent {
  __typename: "Event";
  id: string;
  rawTitle: string;
  replacementData: GraphQL_JSON;
  displayTitle: string;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventHeaderEventTemplateInstallation
// ====================================================

export interface EventHeaderEventTemplateInstallation_event {
  __typename: "Event";
  id: string;
  rawTitle: string;
  replacementData: GraphQL_JSON;
  displayTitle: string;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  hidden: boolean;
  conferenceUrl: string | null;
}

export interface EventHeaderEventTemplateInstallation {
  __typename: "EventTemplateInstallation";
  event: EventHeaderEventTemplateInstallation_event | null;
  id: string;
  hidden: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventConferenceUrlEvent
// ====================================================

export interface EventConferenceUrlEvent {
  __typename: "Event";
  id: string;
  conferenceUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventDescriptionEvent
// ====================================================

export interface EventDescriptionEvent {
  __typename: "Event";
  id: string;
  displayDescription: string;
  replacementData: GraphQL_JSON;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventInfoEventTemplateInstallation
// ====================================================

export interface EventInfoEventTemplateInstallation_schedulerConfig_availability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
  slug: string;
}

export interface EventInfoEventTemplateInstallation_schedulerConfig_interviewers {
  __typename: "Interviewer";
  id: string;
  name: string;
}

export interface EventInfoEventTemplateInstallation_schedulerConfig {
  __typename: "EventTemplateInstallationSchedulerConfig";
  availability: EventInfoEventTemplateInstallation_schedulerConfig_availability;
  interviewers: EventInfoEventTemplateInstallation_schedulerConfig_interviewers[];
}

export interface EventInfoEventTemplateInstallation_event_location {
  __typename: "Location";
  id: string;
}

export interface EventInfoEventTemplateInstallation_event_stage_guide_candidate_organization_locations {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface EventInfoEventTemplateInstallation_event_stage_guide_candidate_organization {
  __typename: "Organization";
  id: string;
  locations: EventInfoEventTemplateInstallation_event_stage_guide_candidate_organization_locations[];
}

export interface EventInfoEventTemplateInstallation_event_stage_guide_candidate {
  __typename: "Candidate";
  id: string;
  organization: EventInfoEventTemplateInstallation_event_stage_guide_candidate_organization;
}

export interface EventInfoEventTemplateInstallation_event_stage_guide {
  __typename: "Guide";
  id: string;
  candidate: EventInfoEventTemplateInstallation_event_stage_guide_candidate;
}

export interface EventInfoEventTemplateInstallation_event_stage {
  __typename: "Stage";
  id: string;
  guide: EventInfoEventTemplateInstallation_event_stage_guide;
}

export interface EventInfoEventTemplateInstallation_event_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  label: string;
}

export interface EventInfoEventTemplateInstallation_event {
  __typename: "Event";
  id: string;
  displayDescription: string;
  replacementData: GraphQL_JSON;
  location: EventInfoEventTemplateInstallation_event_location | null;
  stage: EventInfoEventTemplateInstallation_event_stage;
  conferenceUrl: string | null;
  conferencePhone: string | null;
  prominentInstructions: EventInfoEventTemplateInstallation_event_prominentInstructions[];
}

export interface EventInfoEventTemplateInstallation_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
}

export interface EventInfoEventTemplateInstallation {
  __typename: "EventTemplateInstallation";
  id: string;
  schedulerConfig: EventInfoEventTemplateInstallation_schedulerConfig | null;
  event: EventInfoEventTemplateInstallation_event | null;
  eventTemplate: EventInfoEventTemplateInstallation_eventTemplate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventLocationEvent
// ====================================================

export interface EventLocationEvent_location {
  __typename: "Location";
  id: string;
}

export interface EventLocationEvent_stage_guide_candidate_organization_locations {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface EventLocationEvent_stage_guide_candidate_organization {
  __typename: "Organization";
  id: string;
  locations: EventLocationEvent_stage_guide_candidate_organization_locations[];
}

export interface EventLocationEvent_stage_guide_candidate {
  __typename: "Candidate";
  id: string;
  organization: EventLocationEvent_stage_guide_candidate_organization;
}

export interface EventLocationEvent_stage_guide {
  __typename: "Guide";
  id: string;
  candidate: EventLocationEvent_stage_guide_candidate;
}

export interface EventLocationEvent_stage {
  __typename: "Stage";
  id: string;
  guide: EventLocationEvent_stage_guide;
}

export interface EventLocationEvent {
  __typename: "Event";
  id: string;
  location: EventLocationEvent_location | null;
  stage: EventLocationEvent_stage;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventConferencePhoneEvent
// ====================================================

export interface EventConferencePhoneEvent {
  __typename: "Event";
  id: string;
  conferencePhone: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventProminentInstructionsEvent
// ====================================================

export interface EventProminentInstructionsEvent_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  label: string;
}

export interface EventProminentInstructionsEvent {
  __typename: "Event";
  id: string;
  prominentInstructions: EventProminentInstructionsEvent_prominentInstructions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventInterviewerHiddenBannerEventInterviewerFragment
// ====================================================

export interface EventInterviewerHiddenBannerEventInterviewerFragment {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventParticipantItemInterviewParticipantFragment
// ====================================================

export interface EventParticipantItemInterviewParticipantFragment_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface EventParticipantItemInterviewParticipantFragment_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  displayImageUrl: string;
}

export interface EventParticipantItemInterviewParticipantFragment_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
  personalProfile: EventParticipantItemInterviewParticipantFragment_EventInterviewer_personalProfile;
}

export type EventParticipantItemInterviewParticipantFragment = EventParticipantItemInterviewParticipantFragment_Candidate | EventParticipantItemInterviewParticipantFragment_EventInterviewer;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventParticipantListEventTemplateInstallation
// ====================================================

export interface EventParticipantListEventTemplateInstallation_defaultInterviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface EventParticipantListEventTemplateInstallation_defaultInterviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  displayImageUrl: string;
}

export interface EventParticipantListEventTemplateInstallation_defaultInterviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
  personalProfile: EventParticipantListEventTemplateInstallation_defaultInterviewParticipants_EventInterviewer_personalProfile;
}

export type EventParticipantListEventTemplateInstallation_defaultInterviewParticipants = EventParticipantListEventTemplateInstallation_defaultInterviewParticipants_Candidate | EventParticipantListEventTemplateInstallation_defaultInterviewParticipants_EventInterviewer;

export interface EventParticipantListEventTemplateInstallation_event_interviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface EventParticipantListEventTemplateInstallation_event_interviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  displayImageUrl: string;
}

export interface EventParticipantListEventTemplateInstallation_event_interviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
  personalProfile: EventParticipantListEventTemplateInstallation_event_interviewParticipants_EventInterviewer_personalProfile;
}

export type EventParticipantListEventTemplateInstallation_event_interviewParticipants = EventParticipantListEventTemplateInstallation_event_interviewParticipants_Candidate | EventParticipantListEventTemplateInstallation_event_interviewParticipants_EventInterviewer;

export interface EventParticipantListEventTemplateInstallation_event {
  __typename: "Event";
  id: string;
  interviewParticipants: EventParticipantListEventTemplateInstallation_event_interviewParticipants[];
}

export interface EventParticipantListEventTemplateInstallation {
  __typename: "EventTemplateInstallation";
  id: string;
  defaultInterviewParticipants: EventParticipantListEventTemplateInstallation_defaultInterviewParticipants[];
  event: EventParticipantListEventTemplateInstallation_event | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventTemplateInstallationHeaderFragmentInstallation
// ====================================================

export interface EventTemplateInstallationHeaderFragmentInstallation_event {
  __typename: "Event";
  id: string;
  hidden: boolean;
  conferenceUrl: string | null;
}

export interface EventTemplateInstallationHeaderFragmentInstallation {
  __typename: "EventTemplateInstallation";
  id: string;
  title: string;
  replacementData: GraphQL_JSON;
  hidden: boolean;
  event: EventTemplateInstallationHeaderFragmentInstallation_event | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventTemplateInstallationDescriptionInstallation
// ====================================================

export interface EventTemplateInstallationDescriptionInstallation {
  __typename: "EventTemplateInstallation";
  id: string;
  description: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventTemplateInstallationInfoInstallation
// ====================================================

export interface EventTemplateInstallationInfoInstallation_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
}

export interface EventTemplateInstallationInfoInstallation_schedulerConfig_availability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
  slug: string;
}

export interface EventTemplateInstallationInfoInstallation_schedulerConfig_interviewers {
  __typename: "Interviewer";
  id: string;
  name: string;
}

export interface EventTemplateInstallationInfoInstallation_schedulerConfig {
  __typename: "EventTemplateInstallationSchedulerConfig";
  availability: EventTemplateInstallationInfoInstallation_schedulerConfig_availability;
  interviewers: EventTemplateInstallationInfoInstallation_schedulerConfig_interviewers[];
}

export interface EventTemplateInstallationInfoInstallation {
  __typename: "EventTemplateInstallation";
  eventTemplate: EventTemplateInstallationInfoInstallation_eventTemplate;
  isSchedulerManagementAllowed: boolean;
  id: string;
  description: string;
  schedulerConfig: EventTemplateInstallationInfoInstallation_schedulerConfig | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventTemplateInstallationSchedulerFragmentInstallation
// ====================================================

export interface EventTemplateInstallationSchedulerFragmentInstallation_schedulerConfig_availability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
  slug: string;
}

export interface EventTemplateInstallationSchedulerFragmentInstallation_schedulerConfig_interviewers {
  __typename: "Interviewer";
  id: string;
  name: string;
}

export interface EventTemplateInstallationSchedulerFragmentInstallation_schedulerConfig {
  __typename: "EventTemplateInstallationSchedulerConfig";
  availability: EventTemplateInstallationSchedulerFragmentInstallation_schedulerConfig_availability;
  interviewers: EventTemplateInstallationSchedulerFragmentInstallation_schedulerConfig_interviewers[];
}

export interface EventTemplateInstallationSchedulerFragmentInstallation {
  __typename: "EventTemplateInstallation";
  id: string;
  schedulerConfig: EventTemplateInstallationSchedulerFragmentInstallation_schedulerConfig | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventTopBarEventTemplateInstallationFragment
// ====================================================

export interface EventTopBarEventTemplateInstallationFragment_event {
  __typename: "Event";
  id: string;
  hidden: boolean;
}

export interface EventTopBarEventTemplateInstallationFragment {
  __typename: "EventTemplateInstallation";
  id: string;
  hidden: boolean;
  event: EventTopBarEventTemplateInstallationFragment_event | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventActionsMenuEvent
// ====================================================

export interface EventActionsMenuEvent {
  __typename: "Event";
  id: string;
  hidden: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventTemplateInstallationActionsMenuEventTemplateInstallation
// ====================================================

export interface EventTemplateInstallationActionsMenuEventTemplateInstallation {
  __typename: "EventTemplateInstallation";
  id: string;
  hidden: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventTopBarMenuEventTemplateInstallationFragment
// ====================================================

export interface EventTopBarMenuEventTemplateInstallationFragment_event {
  __typename: "Event";
  id: string;
  hidden: boolean;
}

export interface EventTopBarMenuEventTemplateInstallationFragment {
  __typename: "EventTemplateInstallation";
  id: string;
  hidden: boolean;
  event: EventTopBarMenuEventTemplateInstallationFragment_event | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideHeaderFragment
// ====================================================

export interface GuideHeaderFragment_candidate {
  __typename: "Candidate";
  id: string;
  imageUrl: string;
  name: string;
}

export interface GuideHeaderFragment_defaultContent {
  __typename: "GuideContent";
  data: GraphQL_JSON;
}

export interface GuideHeaderFragment {
  __typename: "Guide";
  candidate: GuideHeaderFragment_candidate;
  roleName: string;
  status: GuideStatusEnum;
  bannerImageUrl: string | null;
  defaultContent: GuideHeaderFragment_defaultContent | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EditGuideHeaderFragment
// ====================================================

export interface EditGuideHeaderFragment_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  defaultGuideVersion: number;
}

export interface EditGuideHeaderFragment {
  __typename: "Guide";
  id: string;
  atsUrl: string;
  guideVersion: number;
  jobRoleGuideTemplate: EditGuideHeaderFragment_jobRoleGuideTemplate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageByIdStage
// ====================================================

export interface StageByIdStage_modules {
  __typename: "StageResolvedModule";
  id: string;
  position: number;
  data: GraphQL_JSON;
  type: string;
}

export interface StageByIdStage {
  __typename: "Stage";
  id: string;
  /**
   * Returns the Content Modules for this stage contextualized
   *        to the stage's Guide. Today, the content modules come from a combination of 
   *        stamped modules (the interview scheduler) and the associated ContentTemplate's modules
   */
  modules: StageByIdStage_modules[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideActionsFragment
// ====================================================

export interface GuideActionsFragment_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
}

export interface GuideActionsFragment_nextStage {
  __typename: "Stage";
  id: string;
}

export interface GuideActionsFragment_currentStage {
  __typename: "Stage";
  id: string;
}

export interface GuideActionsFragment_stages {
  __typename: "Stage";
  id: string;
  status: StageStatus;
}

export interface GuideActionsFragment {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
  needsStageConfiguration: boolean;
  guideVersion: number;
  jobRoleGuideTemplate: GuideActionsFragment_jobRoleGuideTemplate | null;
  nextStage: GuideActionsFragment_nextStage | null;
  stageSyncStatus: StageSyncStatus;
  currentStage: GuideActionsFragment_currentStage | null;
  stages: GuideActionsFragment_stages[];
  publicUrl: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ZeusGuideActionsFragment
// ====================================================

export interface ZeusGuideActionsFragment_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
}

export interface ZeusGuideActionsFragment_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
}

export interface ZeusGuideActionsFragment {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
  invitedAt: GraphQL_DateTime | null;
  inviteOpenedAt: GraphQL_DateTime | null;
  candidateOpens: string[];
  candidate: ZeusGuideActionsFragment_candidate;
  jobRoleGuideTemplate: ZeusGuideActionsFragment_jobRoleGuideTemplate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideActivityFeedGuide
// ====================================================

export interface GuideActivityFeedGuide_emailActivities_sender {
  __typename: "User";
  id: string;
  imageUrl: string;
  fullName: string;
}

export interface GuideActivityFeedGuide_emailActivities {
  __typename: "EmailActivity";
  id: string;
  createdAt: GraphQL_DateTime;
  sentTo: string;
  sentFrom: string;
  subject: string;
  body: string;
  sender: GuideActivityFeedGuide_emailActivities_sender | null;
}

export interface GuideActivityFeedGuide {
  __typename: "Guide";
  id: string;
  emailActivities: GuideActivityFeedGuide_emailActivities[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideBannerGuide
// ====================================================

export interface GuideBannerGuide_nextStage {
  __typename: "Stage";
  id: string;
}

export interface GuideBannerGuide_candidate_organization_theme {
  __typename: "Theme";
  primaryColor: string;
}

export interface GuideBannerGuide_candidate_organization_customer_atsIntegration {
  __typename: "AtsIntegration";
  id: string;
  displayName: string | null;
}

export interface GuideBannerGuide_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  atsIntegration: GuideBannerGuide_candidate_organization_customer_atsIntegration | null;
}

export interface GuideBannerGuide_candidate_organization {
  __typename: "Organization";
  id: string;
  defaultAvatarImageUrl: string;
  name: string;
  theme: GuideBannerGuide_candidate_organization_theme | null;
  customer: GuideBannerGuide_candidate_organization_customer;
}

export interface GuideBannerGuide_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  organization: GuideBannerGuide_candidate_organization;
}

export interface GuideBannerGuide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
  stageSyncStatus: StageSyncStatus;
  nextStage: GuideBannerGuide_nextStage | null;
  candidate: GuideBannerGuide_candidate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobRoleGuideTemplateForJobDetailsFragmentJobRoleGuideTemplate
// ====================================================

export interface JobRoleGuideTemplateForJobDetailsFragmentJobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  jobRoleNameOverride: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AtsStageForEditorFragment
// ====================================================

export interface AtsStageForEditorFragment {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ContentTemplateForEditorFragment
// ====================================================

export interface ContentTemplateForEditorFragment_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface ContentTemplateForEditorFragment {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: ContentTemplateForEditorFragment_modulesInflated[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EmailTemplateForEditorFragment
// ====================================================

export interface EmailTemplateForEditorFragment {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideEditorEventTemplateFragment
// ====================================================

export interface GuideEditorEventTemplateFragment_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
}

export interface GuideEditorEventTemplateFragment_atssyncGreenhouseInterview {
  __typename: "ATSInterview";
  id: string;
  name: string;
}

export interface GuideEditorEventTemplateFragment {
  __typename: "EventTemplate";
  id: string;
  title: string;
  atssyncGreenhouseJobStage: GuideEditorEventTemplateFragment_atssyncGreenhouseJobStage | null;
  atssyncGreenhouseInterview: GuideEditorEventTemplateFragment_atssyncGreenhouseInterview | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Change
// ====================================================

export interface Change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface Change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface Change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type Change = Change_BooleanFieldChange | Change_StringFieldChange | Change_StringArrayFieldChange;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CollectionFieldChangeData
// ====================================================

export interface CollectionFieldChangeData_CollectionFieldChangedAddedData {
  __typename: "CollectionFieldChangedAddedData";
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface CollectionFieldChangeData_CollectionFieldChangedRemovedData {
  __typename: "CollectionFieldChangedRemovedData";
  parentId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface CollectionFieldChangeData_CollectionFieldChangedReorderedData {
  __typename: "CollectionFieldChangedReorderedData";
  parentId: string | null;
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export type CollectionFieldChangeData = CollectionFieldChangeData_CollectionFieldChangedAddedData | CollectionFieldChangeData_CollectionFieldChangedRemovedData | CollectionFieldChangeData_CollectionFieldChangedReorderedData;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuidePropagationFieldChange
// ====================================================

export interface GuidePropagationFieldChange_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface GuidePropagationFieldChange_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface GuidePropagationFieldChange_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type GuidePropagationFieldChange_change = GuidePropagationFieldChange_change_BooleanFieldChange | GuidePropagationFieldChange_change_StringFieldChange | GuidePropagationFieldChange_change_StringArrayFieldChange;

export interface GuidePropagationFieldChange {
  __typename: "GuidePropagationFieldChange";
  field: GuidePropagationChangesEnum;
  change: GuidePropagationFieldChange_change;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideCollectionPropagationFieldChange
// ====================================================

export interface GuideCollectionPropagationFieldChange_data_CollectionFieldChangedAddedData {
  __typename: "CollectionFieldChangedAddedData";
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface GuideCollectionPropagationFieldChange_data_CollectionFieldChangedRemovedData {
  __typename: "CollectionFieldChangedRemovedData";
  parentId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface GuideCollectionPropagationFieldChange_data_CollectionFieldChangedReorderedData {
  __typename: "CollectionFieldChangedReorderedData";
  parentId: string | null;
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export type GuideCollectionPropagationFieldChange_data = GuideCollectionPropagationFieldChange_data_CollectionFieldChangedAddedData | GuideCollectionPropagationFieldChange_data_CollectionFieldChangedRemovedData | GuideCollectionPropagationFieldChange_data_CollectionFieldChangedReorderedData;

export interface GuideCollectionPropagationFieldChange {
  __typename: "GuideCollectionPropagationFieldChange";
  type: GuideCollectionPropagationChangesTypeEnum;
  data: GuideCollectionPropagationFieldChange_data;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StagePropagationFieldChange
// ====================================================

export interface StagePropagationFieldChange_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface StagePropagationFieldChange_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface StagePropagationFieldChange_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type StagePropagationFieldChange_change = StagePropagationFieldChange_change_BooleanFieldChange | StagePropagationFieldChange_change_StringFieldChange | StagePropagationFieldChange_change_StringArrayFieldChange;

export interface StagePropagationFieldChange {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: StagePropagationFieldChange_change;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideBrandKitEditorFragment
// ====================================================

export interface GuideBrandKitEditorFragment_defaultContent {
  __typename: "GuideContent";
  data: GraphQL_JSON;
}

export interface GuideBrandKitEditorFragment {
  __typename: "Guide";
  id: string;
  bannerImageUrl: string | null;
  defaultContent: GuideBrandKitEditorFragment_defaultContent | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideTemplateStageForGuideEditorFragment
// ====================================================

export interface GuideTemplateStageForGuideEditorFragment_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface GuideTemplateStageForGuideEditorFragment_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface GuideTemplateStageForGuideEditorFragment_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: GuideTemplateStageForGuideEditorFragment_contentTemplate_modulesInflated[];
}

export interface GuideTemplateStageForGuideEditorFragment_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface GuideTemplateStageForGuideEditorFragment_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface GuideTemplateStageForGuideEditorFragment {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplate: GuideTemplateStageForGuideEditorFragment_postTemplate | null;
  contentTemplate: GuideTemplateStageForGuideEditorFragment_contentTemplate | null;
  emailTemplate: GuideTemplateStageForGuideEditorFragment_emailTemplate | null;
  postTemplates: GuideTemplateStageForGuideEditorFragment_postTemplates[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideTemplateForGuideEditorFragment
// ====================================================

export interface GuideTemplateForGuideEditorFragment_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface GuideTemplateForGuideEditorFragment_guideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface GuideTemplateForGuideEditorFragment_guideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface GuideTemplateForGuideEditorFragment_guideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: GuideTemplateForGuideEditorFragment_guideTemplateStages_contentTemplate_modulesInflated[];
}

export interface GuideTemplateForGuideEditorFragment_guideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface GuideTemplateForGuideEditorFragment_guideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface GuideTemplateForGuideEditorFragment_guideTemplateStages {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  postTemplate: GuideTemplateForGuideEditorFragment_guideTemplateStages_postTemplate | null;
  contentTemplate: GuideTemplateForGuideEditorFragment_guideTemplateStages_contentTemplate | null;
  emailTemplate: GuideTemplateForGuideEditorFragment_guideTemplateStages_emailTemplate | null;
  postTemplates: GuideTemplateForGuideEditorFragment_guideTemplateStages_postTemplates[];
}

export interface GuideTemplateForGuideEditorFragment_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface GuideTemplateForGuideEditorFragment_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: GuideTemplateForGuideEditorFragment_interviewPlan_items_postTemplates[];
}

export interface GuideTemplateForGuideEditorFragment_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: GuideTemplateForGuideEditorFragment_interviewPlan_items[];
}

export interface GuideTemplateForGuideEditorFragment_guideTemplateLexicalContext {
  __typename: "GuideTemplateLexicalContext";
  id: GraphQL_UUID;
  companyName: string;
}

export interface GuideTemplateForGuideEditorFragment {
  __typename: "GuideTemplate";
  id: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  usage: number;
  isGlobal: boolean;
  department: GuideTemplateForGuideEditorFragment_department | null;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  inProgressGuidesCount: number;
  jobsWithInProgressGuidesCount: number;
  guideTemplateStages: GuideTemplateForGuideEditorFragment_guideTemplateStages[];
  interviewPlan: GuideTemplateForGuideEditorFragment_interviewPlan | null;
  guideTemplateLexicalContext: GuideTemplateForGuideEditorFragment_guideTemplateLexicalContext;
  updatedAt: GraphQL_DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewerForEditorFragment
// ====================================================

export interface InterviewerForEditorFragment {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideEditorJobRoleGuideTemplateStageSchedulerConfigFragment
// ====================================================

export interface GuideEditorJobRoleGuideTemplateStageSchedulerConfigFragment_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface GuideEditorJobRoleGuideTemplateStageSchedulerConfigFragment_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface GuideEditorJobRoleGuideTemplateStageSchedulerConfigFragment_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface GuideEditorJobRoleGuideTemplateStageSchedulerConfigFragment_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: GuideEditorJobRoleGuideTemplateStageSchedulerConfigFragment_configInterviewers_interviewer;
}

export interface GuideEditorJobRoleGuideTemplateStageSchedulerConfigFragment {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: GuideEditorJobRoleGuideTemplateStageSchedulerConfigFragment_eventTemplate | null;
  schedulerAvailability: GuideEditorJobRoleGuideTemplateStageSchedulerConfigFragment_schedulerAvailability | null;
  configInterviewers: GuideEditorJobRoleGuideTemplateStageSchedulerConfigFragment_configInterviewers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobRoleGuideTemplateStageForGuideEditorFragment
// ====================================================

export interface JobRoleGuideTemplateStageForGuideEditorFragment_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface JobRoleGuideTemplateStageForGuideEditorFragment_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: JobRoleGuideTemplateStageForGuideEditorFragment_contentTemplate_modulesInflated[];
}

export interface JobRoleGuideTemplateStageForGuideEditorFragment_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface JobRoleGuideTemplateStageForGuideEditorFragment_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface JobRoleGuideTemplateStageForGuideEditorFragment_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface JobRoleGuideTemplateStageForGuideEditorFragment_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface JobRoleGuideTemplateStageForGuideEditorFragment_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface JobRoleGuideTemplateStageForGuideEditorFragment_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface JobRoleGuideTemplateStageForGuideEditorFragment_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface JobRoleGuideTemplateStageForGuideEditorFragment_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: JobRoleGuideTemplateStageForGuideEditorFragment_schedulerConfig_configInterviewers_interviewer;
}

export interface JobRoleGuideTemplateStageForGuideEditorFragment_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: JobRoleGuideTemplateStageForGuideEditorFragment_schedulerConfig_eventTemplate | null;
  schedulerAvailability: JobRoleGuideTemplateStageForGuideEditorFragment_schedulerConfig_schedulerAvailability | null;
  configInterviewers: JobRoleGuideTemplateStageForGuideEditorFragment_schedulerConfig_configInterviewers[];
}

export interface JobRoleGuideTemplateStageForGuideEditorFragment_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface JobRoleGuideTemplateStageForGuideEditorFragment_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface JobRoleGuideTemplateStageForGuideEditorFragment_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type JobRoleGuideTemplateStageForGuideEditorFragment_changes_change = JobRoleGuideTemplateStageForGuideEditorFragment_changes_change_BooleanFieldChange | JobRoleGuideTemplateStageForGuideEditorFragment_changes_change_StringFieldChange | JobRoleGuideTemplateStageForGuideEditorFragment_changes_change_StringArrayFieldChange;

export interface JobRoleGuideTemplateStageForGuideEditorFragment_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: JobRoleGuideTemplateStageForGuideEditorFragment_changes_change;
}

export interface JobRoleGuideTemplateStageForGuideEditorFragment {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: JobRoleGuideTemplateStageForGuideEditorFragment_contentTemplate | null;
  emailTemplate: JobRoleGuideTemplateStageForGuideEditorFragment_emailTemplate | null;
  postTemplate: JobRoleGuideTemplateStageForGuideEditorFragment_postTemplate | null;
  postTemplates: JobRoleGuideTemplateStageForGuideEditorFragment_postTemplates[];
  atssyncJobStage: JobRoleGuideTemplateStageForGuideEditorFragment_atssyncJobStage | null;
  schedulerConfig: JobRoleGuideTemplateStageForGuideEditorFragment_schedulerConfig | null;
  changes: JobRoleGuideTemplateStageForGuideEditorFragment_changes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobRoleGuideTemplateForGuideEditorFragment
// ====================================================

export interface JobRoleGuideTemplateForGuideEditorFragment_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type JobRoleGuideTemplateForGuideEditorFragment_changes_change = JobRoleGuideTemplateForGuideEditorFragment_changes_change_BooleanFieldChange | JobRoleGuideTemplateForGuideEditorFragment_changes_change_StringFieldChange | JobRoleGuideTemplateForGuideEditorFragment_changes_change_StringArrayFieldChange;

export interface JobRoleGuideTemplateForGuideEditorFragment_changes {
  __typename: "GuidePropagationFieldChange";
  field: GuidePropagationChangesEnum;
  change: JobRoleGuideTemplateForGuideEditorFragment_changes_change;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_collectionChanges_data_CollectionFieldChangedAddedData {
  __typename: "CollectionFieldChangedAddedData";
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_collectionChanges_data_CollectionFieldChangedRemovedData {
  __typename: "CollectionFieldChangedRemovedData";
  parentId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_collectionChanges_data_CollectionFieldChangedReorderedData {
  __typename: "CollectionFieldChangedReorderedData";
  parentId: string | null;
  childId: string | null;
  status: CollectionFieldChangeStatusEnum | null;
}

export type JobRoleGuideTemplateForGuideEditorFragment_collectionChanges_data = JobRoleGuideTemplateForGuideEditorFragment_collectionChanges_data_CollectionFieldChangedAddedData | JobRoleGuideTemplateForGuideEditorFragment_collectionChanges_data_CollectionFieldChangedRemovedData | JobRoleGuideTemplateForGuideEditorFragment_collectionChanges_data_CollectionFieldChangedReorderedData;

export interface JobRoleGuideTemplateForGuideEditorFragment_collectionChanges {
  __typename: "GuideCollectionPropagationFieldChange";
  type: GuideCollectionPropagationChangesTypeEnum;
  data: JobRoleGuideTemplateForGuideEditorFragment_collectionChanges_data;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_postTemplate {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  originalATSId: string;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate | null;
  schedulerAvailability: JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability | null;
  configInterviewers: JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers[];
}

export interface JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange {
  __typename: "BooleanFieldChange";
  restoreValueBoolean: boolean | null;
  currentValueBoolean: boolean | null;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_changes_change_StringFieldChange {
  __typename: "StringFieldChange";
  restoreValueString: string | null;
  currentValueString: string | null;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange {
  __typename: "StringArrayFieldChange";
  restoreValueStringArray: string[] | null;
  currentValueStringArray: string[] | null;
}

export type JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_changes_change = JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_changes_change_BooleanFieldChange | JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_changes_change_StringFieldChange | JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_changes_change_StringArrayFieldChange;

export interface JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_changes {
  __typename: "StagePropagationFieldChange";
  field: StagePropagationChangesEnum;
  change: JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_changes_change;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  emailActionCC: string[];
  emailActionBCC: string[];
  hidden: boolean;
  contentTemplate: JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_contentTemplate | null;
  emailTemplate: JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_emailTemplate | null;
  postTemplate: JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_postTemplate | null;
  postTemplates: JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_postTemplates[];
  atssyncJobStage: JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_atssyncJobStage | null;
  schedulerConfig: JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_schedulerConfig | null;
  changes: JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages_changes[];
}

export interface JobRoleGuideTemplateForGuideEditorFragment_interviewPlan_items_postTemplates {
  __typename: "PostTemplate";
  id: string;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_interviewPlan_items {
  __typename: "InterviewPlanItem";
  id: string;
  title: string;
  description: string | null;
  position: number;
  postTemplates: JobRoleGuideTemplateForGuideEditorFragment_interviewPlan_items_postTemplates[];
}

export interface JobRoleGuideTemplateForGuideEditorFragment_interviewPlan {
  __typename: "InterviewPlan";
  id: string;
  items: JobRoleGuideTemplateForGuideEditorFragment_interviewPlan_items[];
}

export interface JobRoleGuideTemplateForGuideEditorFragment_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_atsJob_atsJobStages {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_atsJob {
  __typename: "ATSJobRole";
  id: string;
  atsJobStages: JobRoleGuideTemplateForGuideEditorFragment_atsJob_atsJobStages[];
}

export interface JobRoleGuideTemplateForGuideEditorFragment_eventTemplates_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_eventTemplates_atssyncGreenhouseInterview {
  __typename: "ATSInterview";
  id: string;
  name: string;
}

export interface JobRoleGuideTemplateForGuideEditorFragment_eventTemplates {
  __typename: "EventTemplate";
  id: string;
  title: string;
  atssyncGreenhouseJobStage: JobRoleGuideTemplateForGuideEditorFragment_eventTemplates_atssyncGreenhouseJobStage | null;
  atssyncGreenhouseInterview: JobRoleGuideTemplateForGuideEditorFragment_eventTemplates_atssyncGreenhouseInterview | null;
}

export interface JobRoleGuideTemplateForGuideEditorFragment {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  jobDisplayName: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleNameOverride: string | null;
  inProgressGuidesCount: number;
  setupStatus: JobRoleGuideTemplateSetupStatus;
  defaultGuideVersion: number;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  changes: JobRoleGuideTemplateForGuideEditorFragment_changes[];
  collectionChanges: JobRoleGuideTemplateForGuideEditorFragment_collectionChanges[];
  jobRoleGuideTemplateStages: JobRoleGuideTemplateForGuideEditorFragment_jobRoleGuideTemplateStages[];
  interviewPlan: JobRoleGuideTemplateForGuideEditorFragment_interviewPlan | null;
  guideTemplate: JobRoleGuideTemplateForGuideEditorFragment_guideTemplate | null;
  atsJob: JobRoleGuideTemplateForGuideEditorFragment_atsJob | null;
  eventTemplates: JobRoleGuideTemplateForGuideEditorFragment_eventTemplates[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobRoleGuideTemplateVariableValuesFragment
// ====================================================

export interface JobRoleGuideTemplateVariableValuesFragment_jobRoleGuideLexicalContext {
  __typename: "JobRoleGuideLexicalContext";
  id: GraphQL_UUID;
  jobTitle: string;
  jobListingUrl: string | null;
  companyName: string;
}

export interface JobRoleGuideTemplateVariableValuesFragment {
  __typename: "JobRoleGuideTemplate";
  jobRoleGuideLexicalContext: JobRoleGuideTemplateVariableValuesFragment_jobRoleGuideLexicalContext;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationForGuideEditorFragment
// ====================================================

export interface OrganizationForGuideEditorFragment_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface OrganizationForGuideEditorFragment_customer {
  __typename: "Customer";
  id: string;
  stageAutomationEnabled: boolean;
}

export interface OrganizationForGuideEditorFragment {
  __typename: "Organization";
  id: string;
  defaultAvatarImageUrl: string;
  theme: OrganizationForGuideEditorFragment_theme | null;
  customer: OrganizationForGuideEditorFragment_customer;
  companyLogoUrl: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostTemplateForEditorFragment
// ====================================================

export interface PostTemplateForEditorFragment {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideEditorSchedulerConfigAvailabilityFragment
// ====================================================

export interface GuideEditorSchedulerConfigAvailabilityFragment {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideEditorSchedulerConfigEventTemplateFragment
// ====================================================

export interface GuideEditorSchedulerConfigEventTemplateFragment {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideEmailFormUser
// ====================================================

export interface GuideEmailFormUser {
  __typename: "User";
  id: string;
  primaryEmail: string | null;
  sendAsAddresses: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideEmailFormStageFragment
// ====================================================

export interface GuideEmailFormStageFragment_stageTemplateInstallation_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
  isArchived: boolean;
}

export interface GuideEmailFormStageFragment_stageTemplateInstallation {
  __typename: "StageTemplateInstallation";
  id: string;
  emailActionCC: string[];
  emailActionBCC: string[];
  emailTemplate: GuideEmailFormStageFragment_stageTemplateInstallation_emailTemplate | null;
}

export interface GuideEmailFormStageFragment_jobRoleGuideTemplateStage_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
  isArchived: boolean;
}

export interface GuideEmailFormStageFragment_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  emailRecipientsCC: string[];
  emailRecipientsBCC: string[];
  emailTemplate: GuideEmailFormStageFragment_jobRoleGuideTemplateStage_emailTemplate | null;
}

export interface GuideEmailFormStageFragment {
  __typename: "Stage";
  id: string;
  stageTemplateInstallation: GuideEmailFormStageFragment_stageTemplateInstallation | null;
  jobRoleGuideTemplateStage: GuideEmailFormStageFragment_jobRoleGuideTemplateStage | null;
  status: StageStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideIcalFilesGuide
// ====================================================

export interface GuideIcalFilesGuide_icalAttachedFiles {
  __typename: "AttachedFile";
  id: GraphQL_UUID;
  filename: string;
}

export interface GuideIcalFilesGuide {
  __typename: "Guide";
  icalAttachedFiles: GuideIcalFilesGuide_icalAttachedFiles[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideEmailFormGuideFragment
// ====================================================

export interface GuideEmailFormGuideFragment_mostRecentEmailActivity {
  __typename: "EmailActivity";
  id: string;
  sentTo: string;
}

export interface GuideEmailFormGuideFragment_candidate_organization_recipients {
  __typename: "Recipient";
  id: GraphQL_UUID;
  name: string;
  email: string;
}

export interface GuideEmailFormGuideFragment_candidate_organization {
  __typename: "Organization";
  id: string;
  customVariableOptions: string[];
  recipients: GuideEmailFormGuideFragment_candidate_organization_recipients[];
}

export interface GuideEmailFormGuideFragment_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  emailAddresses: string[];
  currentTimezone: string;
  organization: GuideEmailFormGuideFragment_candidate_organization;
}

export interface GuideEmailFormGuideFragment_sendGuideReplacementData_allInterviewers {
  __typename: "InterviewerReplacementData";
  title: string;
  firstName: string;
  lastName: string;
}

export interface GuideEmailFormGuideFragment_sendGuideReplacementData_sortedVisibleUpcomingEvents_interviewers {
  __typename: "InterviewerReplacementData";
  title: string;
  firstName: string;
  lastName: string;
}

export interface GuideEmailFormGuideFragment_sendGuideReplacementData_sortedVisibleUpcomingEvents {
  __typename: "EventReplacementData";
  startTime: string;
  endTime: string;
  title: string;
  hidden: boolean;
  interviewers: GuideEmailFormGuideFragment_sendGuideReplacementData_sortedVisibleUpcomingEvents_interviewers[];
}

export interface GuideEmailFormGuideFragment_sendGuideReplacementData {
  __typename: "SendGuideReplacementData";
  candidateFirstName: string;
  candidateFullName: string;
  companyName: string;
  jobRoleName: string;
  recruiterFirstName: string;
  recruiterFullName: string;
  recruiterEmailAddress: string;
  coordinatorFirstName: string;
  coordinatorFullName: string;
  coordinatorEmailAddress: string;
  guideUrl: string;
  office: string;
  greenhouseJobUrl: string;
  allInterviewers: GuideEmailFormGuideFragment_sendGuideReplacementData_allInterviewers[];
  senderName: string;
  senderFirstName: string;
  sortedVisibleUpcomingEvents: GuideEmailFormGuideFragment_sendGuideReplacementData_sortedVisibleUpcomingEvents[];
  stageCustomVariables: GraphQL_JSON;
  timezone: string;
}

export interface GuideEmailFormGuideFragment_icalAttachedFiles {
  __typename: "AttachedFile";
  id: GraphQL_UUID;
  filename: string;
}

export interface GuideEmailFormGuideFragment {
  __typename: "Guide";
  id: string;
  mostRecentEmailActivity: GuideEmailFormGuideFragment_mostRecentEmailActivity[];
  candidate: GuideEmailFormGuideFragment_candidate;
  sendGuideReplacementData: GuideEmailFormGuideFragment_sendGuideReplacementData;
  status: GuideStatusEnum;
  guideVersion: number;
  publicUrl: string;
  icalAttachedFiles: GuideEmailFormGuideFragment_icalAttachedFiles[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideInfoUrlGuide
// ====================================================

export interface GuideInfoUrlGuide {
  __typename: "Guide";
  publicUrl: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideInfoVisibility
// ====================================================

export interface GuideInfoVisibility_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface GuideInfoVisibility_candidate {
  __typename: "Candidate";
  id: string;
  organization: GuideInfoVisibility_candidate_organization;
}

export interface GuideInfoVisibility {
  __typename: "Guide";
  status: GuideStatusEnum;
  candidate: GuideInfoVisibility_candidate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideInfoVariablesStage
// ====================================================

export interface GuideInfoVariablesStage_guide_candidate_organization {
  __typename: "Organization";
  id: string;
  customVariableOptions: string[];
}

export interface GuideInfoVariablesStage_guide_candidate {
  __typename: "Candidate";
  id: string;
  organization: GuideInfoVariablesStage_guide_candidate_organization;
}

export interface GuideInfoVariablesStage_guide {
  __typename: "Guide";
  id: string;
  candidate: GuideInfoVariablesStage_guide_candidate;
}

export interface GuideInfoVariablesStage {
  __typename: "Stage";
  id: string;
  customVariables: GraphQL_JSON;
  guide: GuideInfoVariablesStage_guide;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideInfoHiringTeamStage
// ====================================================

export interface GuideInfoHiringTeamStage_customHiringTeam {
  __typename: "Interviewer";
  id: string;
  name: string;
  email: string | null;
}

export interface GuideInfoHiringTeamStage_guide_candidate_organization_interviewers {
  __typename: "Interviewer";
  id: string;
  name: string;
  email: string | null;
  displayImageUrl: string;
}

export interface GuideInfoHiringTeamStage_guide_candidate_organization {
  __typename: "Organization";
  id: string;
  interviewers: GuideInfoHiringTeamStage_guide_candidate_organization_interviewers[];
}

export interface GuideInfoHiringTeamStage_guide_candidate {
  __typename: "Candidate";
  id: string;
  organization: GuideInfoHiringTeamStage_guide_candidate_organization;
}

export interface GuideInfoHiringTeamStage_guide {
  __typename: "Guide";
  id: string;
  candidate: GuideInfoHiringTeamStage_guide_candidate;
}

export interface GuideInfoHiringTeamStage {
  __typename: "Stage";
  id: string;
  customHiringTeam: GuideInfoHiringTeamStage_customHiringTeam[];
  guide: GuideInfoHiringTeamStage_guide;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewerForPreviewInterviewer
// ====================================================

export interface InterviewerForPreviewInterviewer {
  __typename: "ModuleResolutionInterviewerContext";
  id: GraphQL_UUID;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  pronoun: string | null;
  videoIntroductionUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuidePreviewModuleResolutionContextGuide
// ====================================================

export interface GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_interviews_prominentInstructions {
  __typename: "ModuleResolutionProminentInstructionContext";
  id: GraphQL_UUID;
  label: string;
  url: string | null;
}

export interface GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_interviews_interviewers {
  __typename: "ModuleResolutionInterviewerContext";
  id: GraphQL_UUID;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  pronoun: string | null;
  videoIntroductionUrl: string | null;
}

export interface GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_interviews {
  __typename: "ModuleResolutionInterviewContext";
  id: GraphQL_UUID;
  atsJobStageId: string | null;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  title: string;
  description: string;
  variableReplacements: GraphQL_JSON;
  eventTemplateId: string | null;
  prominentInstructions: GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_interviews_prominentInstructions[];
  interviewers: GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_interviews_interviewers[];
}

export interface GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_stages_schedulerConfig_interviewers {
  __typename: "ModuleResolutionSchedulerConfigInterviewerContext";
  id: GraphQL_UUID;
  firstName: string;
  lastName: string;
  imageUrl: string;
}

export interface GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_stages_schedulerConfig {
  __typename: "ModuleResolutionSchedulerConfigContext";
  availabilitySlug: string | null;
  eventTemplateId: string | null;
  interviewers: GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_stages_schedulerConfig_interviewers[];
  eventTemplateInstallationId: string | null;
}

export interface GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_stages_customData_hiringTeam {
  __typename: "ModuleResolutionInterviewerContext";
  id: GraphQL_UUID;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  pronoun: string | null;
  videoIntroductionUrl: string | null;
}

export interface GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_stages_customData {
  __typename: "StageCustomData";
  hiringTeam: GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_stages_customData_hiringTeam[];
  customVariables: GraphQL_JSON | null;
}

export interface GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_stages {
  __typename: "ModuleResolutionStageContext";
  id: string;
  atsJobStageId: string;
  needsFeedback: boolean;
  variableReplacements: GraphQL_JSON;
  schedulerConfig: GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_stages_schedulerConfig | null;
  customData: GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_stages_customData;
}

export interface GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_candidate {
  __typename: "ModuleResolutionCandidateContext";
  id: GraphQL_UUID;
  email: string | null;
  firstName: string;
  fullName: string;
  imageUrl: string;
}

export interface GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_organization_values {
  __typename: "ModuleResolutionValueContext";
  id: GraphQL_UUID;
  title: string;
  description: string;
}

export interface GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_organization {
  __typename: "ModuleResolutionOrganizationContext";
  id: GraphQL_UUID;
  name: string;
  addToCalendarEnabled: boolean;
  missionStatement: string;
  values: GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_organization_values[];
}

export interface GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_job {
  __typename: "ModuleResolutionJobContext";
  id: GraphQL_UUID;
  name: string;
  greenhouseJobUrl: string;
}

export interface GuidePreviewModuleResolutionContextGuide_moduleResolutionContext {
  __typename: "ModuleResolutionGuideContext";
  id: GraphQL_UUID;
  publicUrl: string;
  recruiterEmailAddress: string;
  recruiterFirstName: string;
  interviews: GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_interviews[];
  stages: GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_stages[];
  candidate: GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_candidate;
  organization: GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_organization;
  job: GuidePreviewModuleResolutionContextGuide_moduleResolutionContext_job;
}

export interface GuidePreviewModuleResolutionContextGuide {
  __typename: "Guide";
  id: string;
  interviewProcessEnabled: boolean;
  moduleResolutionContext: GuidePreviewModuleResolutionContextGuide_moduleResolutionContext;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobRoleGuideTemplateForPreviewFragment
// ====================================================

export interface JobRoleGuideTemplateForPreviewFragment_jobRoleGuideTemplateStages_atssyncJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  originalATSId: string;
}

export interface JobRoleGuideTemplateForPreviewFragment_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

export interface JobRoleGuideTemplateForPreviewFragment_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

export interface JobRoleGuideTemplateForPreviewFragment_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
  email: string | null;
  imageUrl: string;
  atsUserId: string | null;
}

export interface JobRoleGuideTemplateForPreviewFragment_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers {
  __typename: "JobRoleGuideTemplateStageConfigInterviewer";
  id: string | null;
  interviewer: JobRoleGuideTemplateForPreviewFragment_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers_interviewer;
}

export interface JobRoleGuideTemplateForPreviewFragment_jobRoleGuideTemplateStages_schedulerConfig {
  __typename: "JobRoleGuideTemplateStageSchedulerConfig";
  id: string | null;
  eventTemplate: JobRoleGuideTemplateForPreviewFragment_jobRoleGuideTemplateStages_schedulerConfig_eventTemplate | null;
  schedulerAvailability: JobRoleGuideTemplateForPreviewFragment_jobRoleGuideTemplateStages_schedulerConfig_schedulerAvailability | null;
  configInterviewers: JobRoleGuideTemplateForPreviewFragment_jobRoleGuideTemplateStages_schedulerConfig_configInterviewers[];
}

export interface JobRoleGuideTemplateForPreviewFragment_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface JobRoleGuideTemplateForPreviewFragment_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: JobRoleGuideTemplateForPreviewFragment_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface JobRoleGuideTemplateForPreviewFragment_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  atssyncJobStage: JobRoleGuideTemplateForPreviewFragment_jobRoleGuideTemplateStages_atssyncJobStage | null;
  schedulerConfig: JobRoleGuideTemplateForPreviewFragment_jobRoleGuideTemplateStages_schedulerConfig | null;
  contentTemplate: JobRoleGuideTemplateForPreviewFragment_jobRoleGuideTemplateStages_contentTemplate | null;
}

export interface JobRoleGuideTemplateForPreviewFragment {
  __typename: "JobRoleGuideTemplate";
  id: string;
  jobDisplayName: string;
  interviewProcessEnabled: boolean;
  jobRoleGuideTemplateStages: JobRoleGuideTemplateForPreviewFragment_jobRoleGuideTemplateStages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideTemplateForPreviewFragment
// ====================================================

export interface GuideTemplateForPreviewFragment_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface GuideTemplateForPreviewFragment_guideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface GuideTemplateForPreviewFragment_guideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: GuideTemplateForPreviewFragment_guideTemplateStages_contentTemplate_modulesInflated[];
}

export interface GuideTemplateForPreviewFragment_guideTemplateStages {
  __typename: "GuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  contentTemplate: GuideTemplateForPreviewFragment_guideTemplateStages_contentTemplate | null;
}

export interface GuideTemplateForPreviewFragment {
  __typename: "GuideTemplate";
  id: string;
  chatEnabled: boolean;
  isGlobal: boolean;
  department: GuideTemplateForPreviewFragment_department | null;
  interviewProcessEnabled: boolean;
  guideBanner: string | null;
  guideContent: GraphQL_JSON | null;
  guideTemplateStages: GuideTemplateForPreviewFragment_guideTemplateStages[];
  updatedAt: GraphQL_DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PreviewGuideOrganizationFragment
// ====================================================

export interface PreviewGuideOrganizationFragment_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface PreviewGuideOrganizationFragment {
  __typename: "Organization";
  id: string;
  companyLogoUrl: string;
  name: string;
  theme: PreviewGuideOrganizationFragment_theme | null;
  showTrim: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuidePreviewButtonGuide
// ====================================================

export interface GuidePreviewButtonGuide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
  guideVersion: number;
  publicUrl: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuidePreviewButtonStage
// ====================================================

export interface GuidePreviewButtonStage {
  __typename: "Stage";
  id: string;
  status: StageStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UpcomingInterviewsHovercardFragment
// ====================================================

export interface UpcomingInterviewsHovercardFragment {
  __typename: "Event";
  id: string;
  displayTitle: string;
  replacementData: GraphQL_JSON;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideSnapshotFragment
// ====================================================

export interface GuideSnapshotFragment_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
}

export interface GuideSnapshotFragment_candidate {
  __typename: "Candidate";
  id: string;
  organization: GuideSnapshotFragment_candidate_organization;
}

export interface GuideSnapshotFragment {
  __typename: "Guide";
  status: GuideStatusEnum;
  candidate: GuideSnapshotFragment_candidate;
  publicUrl: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideTableRowGuide
// ====================================================

export interface GuideTableRowGuide_upcomingInterviews {
  __typename: "Event";
  id: string;
  displayTitle: string;
  replacementData: GraphQL_JSON;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
}

export interface GuideTableRowGuide_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
}

export interface GuideTableRowGuide_currentStage {
  __typename: "Stage";
  id: string;
  title: string;
  position: number;
}

export interface GuideTableRowGuide {
  __typename: "Guide";
  id: string;
  atsUrl: string;
  publicUrl: string;
  roleName: string;
  stageCount: number;
  currentStagePosition: number | null;
  upcomingInterviews: GuideTableRowGuide_upcomingInterviews[];
  candidate: GuideTableRowGuide_candidate;
  currentStage: GuideTableRowGuide_currentStage | null;
  jobRoleGuideTemplateId: string | null;
  lastEmailActivityAt: GraphQL_DateTime | null;
  candidateOpens: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideTableRowOrganizationFragment
// ====================================================

export interface GuideTableRowOrganizationFragment_theme {
  __typename: "Theme";
  primaryColor: string;
}

export interface GuideTableRowOrganizationFragment {
  __typename: "Organization";
  id: string;
  defaultAvatarImageUrl: string;
  theme: GuideTableRowOrganizationFragment_theme | null;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DuplicateGuideTemplateFragment
// ====================================================

export interface DuplicateGuideTemplateFragment_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface DuplicateGuideTemplateFragment {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  isGlobal: boolean;
  department: DuplicateGuideTemplateFragment_department | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EditGuideTemplateHeaderGuideTemplateFragment
// ====================================================

export interface EditGuideTemplateHeaderGuideTemplateFragment_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface EditGuideTemplateHeaderGuideTemplateFragment_createdBy_personalProfile {
  __typename: "Interviewer";
  firstName: string;
  lastName: string;
  imageUrl: string;
}

export interface EditGuideTemplateHeaderGuideTemplateFragment_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: EditGuideTemplateHeaderGuideTemplateFragment_createdBy_personalProfile;
}

export interface EditGuideTemplateHeaderGuideTemplateFragment {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  usage: number;
  archivedAt: GraphQL_DateTime | null;
  inProgressGuidesCount: number;
  department: EditGuideTemplateHeaderGuideTemplateFragment_department | null;
  createdBy: EditGuideTemplateHeaderGuideTemplateFragment_createdBy | null;
  isGlobal: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EditGuideTemplateHeaderGuideTemplateViewFragment
// ====================================================

export interface EditGuideTemplateHeaderGuideTemplateViewFragment {
  __typename: "GuideTemplateView";
  id: string;
  name: string;
  archivedAt: GraphQL_DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EditGuideTemplateFragment
// ====================================================

export interface EditGuideTemplateFragment_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface EditGuideTemplateFragment {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  isGlobal: boolean;
  department: EditGuideTemplateFragment_department | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideTemplatesTableGuideTemplateFragment
// ====================================================

export interface GuideTemplatesTableGuideTemplateFragment_createdBy_user {
  __typename: "User";
  id: string;
  fullName: string;
  imageUrl: string;
}

export interface GuideTemplatesTableGuideTemplateFragment_createdBy {
  __typename: "UserMembership";
  id: string;
  user: GuideTemplatesTableGuideTemplateFragment_createdBy_user;
}

export interface GuideTemplatesTableGuideTemplateFragment_updatedBy_user {
  __typename: "User";
  id: string;
  fullName: string;
  imageUrl: string;
}

export interface GuideTemplatesTableGuideTemplateFragment_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: GuideTemplatesTableGuideTemplateFragment_updatedBy_user;
}

export interface GuideTemplatesTableGuideTemplateFragment_organization {
  __typename: "Organization";
  id: string;
}

export interface GuideTemplatesTableGuideTemplateFragment_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface GuideTemplatesTableGuideTemplateFragment {
  __typename: "GuideTemplateView";
  id: string;
  name: string;
  archivedAt: GraphQL_DateTime | null;
  createdBy: GuideTemplatesTableGuideTemplateFragment_createdBy | null;
  updatedBy: GuideTemplatesTableGuideTemplateFragment_updatedBy | null;
  organization: GuideTemplatesTableGuideTemplateFragment_organization | null;
  updatedAt: GraphQL_DateTime;
  usage: number;
  department: GuideTemplatesTableGuideTemplateFragment_department | null;
  isGlobal: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideTopBarGuide
// ====================================================

export interface GuideTopBarGuide_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  defaultGuideVersion: number;
}

export interface GuideTopBarGuide_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  domains: string[];
}

export interface GuideTopBarGuide_candidate_organization {
  __typename: "Organization";
  id: string;
  customer: GuideTopBarGuide_candidate_organization_customer;
}

export interface GuideTopBarGuide_candidate {
  __typename: "Candidate";
  id: string;
  organization: GuideTopBarGuide_candidate_organization;
}

export interface GuideTopBarGuide_stages {
  __typename: "Stage";
  id: string;
  status: StageStatus;
  stageTemplateInstallationId: string | null;
}

export interface GuideTopBarGuide {
  __typename: "Guide";
  id: string;
  jobRoleGuideTemplate: GuideTopBarGuide_jobRoleGuideTemplate | null;
  status: GuideStatusEnum;
  startedAt: GraphQL_DateTime | null;
  needsStageConfiguration: boolean;
  publicUrl: string;
  guideVersion: number;
  candidate: GuideTopBarGuide_candidate;
  stages: GuideTopBarGuide_stages[];
  candidateOpens: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideTopBarMenuGuide
// ====================================================

export interface GuideTopBarMenuGuide_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  domains: string[];
}

export interface GuideTopBarMenuGuide_candidate_organization {
  __typename: "Organization";
  id: string;
  customer: GuideTopBarMenuGuide_candidate_organization_customer;
}

export interface GuideTopBarMenuGuide_candidate {
  __typename: "Candidate";
  id: string;
  organization: GuideTopBarMenuGuide_candidate_organization;
}

export interface GuideTopBarMenuGuide_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  defaultGuideVersion: number;
}

export interface GuideTopBarMenuGuide_stages {
  __typename: "Stage";
  id: string;
  status: StageStatus;
  stageTemplateInstallationId: string | null;
}

export interface GuideTopBarMenuGuide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
  startedAt: GraphQL_DateTime | null;
  needsStageConfiguration: boolean;
  publicUrl: string;
  guideVersion: number;
  candidate: GuideTopBarMenuGuide_candidate;
  jobRoleGuideTemplate: GuideTopBarMenuGuide_jobRoleGuideTemplate | null;
  stages: GuideTopBarMenuGuide_stages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideMetricsGuideFragment
// ====================================================

export interface GuideMetricsGuideFragment {
  __typename: "Guide";
  candidateOpens: string[];
  startedAt: GraphQL_DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrgForFlagsFragment
// ====================================================

export interface OrgForFlagsFragment_customer_plan {
  __typename: "CustomerPlan";
  id: string;
  name: string;
}

export interface OrgForFlagsFragment_customer {
  __typename: "Customer";
  id: string;
  name: string;
  grandfatheredAllFeaturesPlanEnabled: boolean;
  plan: OrgForFlagsFragment_customer_plan;
}

export interface OrgForFlagsFragment {
  __typename: "Organization";
  id: string;
  name: string;
  zeusStatus: ZeusStatusEnum;
  customer: OrgForFlagsFragment_customer;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CreateInterviewTemplateModalInterviewTemplate
// ====================================================

export interface CreateInterviewTemplateModalInterviewTemplate_department {
  __typename: "ATSDepartment";
  id: string;
}

export interface CreateInterviewTemplateModalInterviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  description: GraphQL_JSON | null;
  title: GraphQL_JSON | null;
  department: CreateInterviewTemplateModalInterviewTemplate_department | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EditInterviewTemplateModalInterviewTemplate
// ====================================================

export interface EditInterviewTemplateModalInterviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  description: GraphQL_JSON | null;
  title: GraphQL_JSON | null;
  status: InterviewTemplateStatusEnum | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UpdateInterviewTemplateModalInterviewTemplate
// ====================================================

export interface UpdateInterviewTemplateModalInterviewTemplate_department {
  __typename: "ATSDepartment";
  id: string;
}

export interface UpdateInterviewTemplateModalInterviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  department: UpdateInterviewTemplateModalInterviewTemplate_department | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterviewTemplateTableInterviewTemplate
// ====================================================

export interface InterviewTemplateTableInterviewTemplate_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface InterviewTemplateTableInterviewTemplate_lastEditedBy {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface InterviewTemplateTableInterviewTemplate_createdBy {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface InterviewTemplateTableInterviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  department: InterviewTemplateTableInterviewTemplate_department | null;
  usage: number;
  lastEditedBy: InterviewTemplateTableInterviewTemplate_lastEditedBy | null;
  lastEditedAt: GraphQL_DateTime | null;
  status: InterviewTemplateStatusEnum | null;
  createdAt: GraphQL_DateTime;
  createdBy: InterviewTemplateTableInterviewTemplate_createdBy;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EditJobRoleGuideTemplateLayoutJob
// ====================================================

export interface EditJobRoleGuideTemplateLayoutJob_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
}

export interface EditJobRoleGuideTemplateLayoutJob_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
  requisitionId: string | null;
}

export interface EditJobRoleGuideTemplateLayoutJob {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  defaultGuideVersion: number;
  guideTemplate: EditJobRoleGuideTemplateLayoutJob_guideTemplate | null;
  atsJob: EditJobRoleGuideTemplateLayoutJob_atsJob | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobPageJobRoleGuideTemplate
// ====================================================

export interface JobPageJobRoleGuideTemplate_atsJob_atsDepartments {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface JobPageJobRoleGuideTemplate_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
  atsUrl: string;
  requisitionId: string | null;
  atsDepartments: JobPageJobRoleGuideTemplate_atsJob_atsDepartments[];
}

export interface JobPageJobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  atsJob: JobPageJobRoleGuideTemplate_atsJob | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobPageSettingsLayoutJob
// ====================================================

export interface JobPageSettingsLayoutJob_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
  requisitionId: string | null;
}

export interface JobPageSettingsLayoutJob {
  __typename: "JobRoleGuideTemplate";
  id: string;
  name: string;
  atsJob: JobPageSettingsLayoutJob_atsJob | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideRowItemGuide
// ====================================================

export interface GuideRowItemGuide_candidate {
  __typename: "Candidate";
  id: string;
  firstName: string;
  lastName: string;
}

export interface GuideRowItemGuide_currentStage_nextInterview {
  __typename: "Event";
  id: string;
  startTime: GraphQL_DateTime;
}

export interface GuideRowItemGuide_currentStage {
  __typename: "Stage";
  id: string;
  nextInterview: GuideRowItemGuide_currentStage_nextInterview | null;
  lastEmailActivityAt: GraphQL_DateTime | null;
}

export interface GuideRowItemGuide {
  __typename: "Guide";
  id: string;
  isDemo: boolean;
  atsUrl: string;
  publicUrl: string;
  candidate: GuideRowItemGuide_candidate;
  roleName: string;
  currentStage: GuideRowItemGuide_currentStage | null;
  lastEmailActivityAt: GraphQL_DateTime | null;
  candidateOpens: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SchedulerAvailabilityDataAvailabilityEvent
// ====================================================

export interface SchedulerAvailabilityDataAvailabilityEvent_pitchPage {
  __typename: "PitchPage";
  id: string;
  isSample: boolean;
}

export interface SchedulerAvailabilityDataAvailabilityEvent_availabilityEventParticipants_participant_interviewer {
  __typename: "Interviewer";
  id: string;
  displayTitle: string;
  displayImageUrl: string;
  displayFirstName: string;
}

export interface SchedulerAvailabilityDataAvailabilityEvent_availabilityEventParticipants_participant {
  __typename: "SchedulerAvailabilityEventParticipant";
  id: string;
  name: string;
  interviewer: SchedulerAvailabilityDataAvailabilityEvent_availabilityEventParticipants_participant_interviewer | null;
}

export interface SchedulerAvailabilityDataAvailabilityEvent_availabilityEventParticipants {
  __typename: "SchedulerAvailabilityEventToParticipant";
  id: string;
  participant: SchedulerAvailabilityDataAvailabilityEvent_availabilityEventParticipants_participant;
}

export interface SchedulerAvailabilityDataAvailabilityEvent {
  __typename: "SchedulerAvailabilityEvent";
  id: string;
  externalEditHash: string;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  location: string;
  pitchPage: SchedulerAvailabilityDataAvailabilityEvent_pitchPage | null;
  availabilityEventParticipants: SchedulerAvailabilityDataAvailabilityEvent_availabilityEventParticipants[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SchedulerConfigAvailability
// ====================================================

export interface SchedulerConfigAvailability {
  __typename: "SchedulerAvailability";
  id: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SchedulerConfigEventTemplate
// ====================================================

export interface SchedulerConfigEventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SchedulerConfigInterviewer
// ====================================================

export interface SchedulerConfigInterviewer {
  __typename: "Interviewer";
  id: string;
  displayFirstName: string;
  displayLastName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationTheme
// ====================================================

export interface OrganizationTheme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationCustomLogistic
// ====================================================

export interface OrganizationCustomLogistic {
  __typename: "Logistic";
  id: string;
  emoji: string;
  title: string;
  description: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationCustomLogisticGroup
// ====================================================

export interface OrganizationCustomLogisticGroup_options {
  __typename: "Logistic";
  id: string;
  emoji: string;
  title: string;
  description: string;
}

export interface OrganizationCustomLogisticGroup {
  __typename: "LogisticGroup";
  id: string;
  options: OrganizationCustomLogisticGroup_options[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationLocation
// ====================================================

export interface OrganizationLocation {
  __typename: "Location";
  id: string;
  name: string;
  arrivalInstructions: string;
  address: string;
  coordinates: string[];
  isArchived: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationValue
// ====================================================

export interface OrganizationValue {
  __typename: "Value";
  id: GraphQL_UUID;
  title: string;
  description: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UpdateStageTemplateModuleInstallationDataInstallation
// ====================================================

export interface UpdateStageTemplateModuleInstallationDataInstallation {
  __typename: "StageTemplateModuleInstallation";
  id: string;
  data: GraphQL_JSON;
  type: string;
  position: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GuideForMessageFragment
// ====================================================

export interface GuideForMessageFragment_currentStage {
  __typename: "Stage";
  id: string;
}

export interface GuideForMessageFragment_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface GuideForMessageFragment_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface GuideForMessageFragment_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: GuideForMessageFragment_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: GuideForMessageFragment_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface GuideForMessageFragment_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  postTemplateSyncData: GuideForMessageFragment_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates_postTemplateSyncData | null;
}

export interface GuideForMessageFragment_sortedVisibleStages_jobRoleGuideTemplateStage {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  postTemplates: GuideForMessageFragment_sortedVisibleStages_jobRoleGuideTemplateStage_postTemplates[];
}

export interface GuideForMessageFragment_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  title: string;
  position: number;
  jobRoleGuideTemplateStage: GuideForMessageFragment_sortedVisibleStages_jobRoleGuideTemplateStage | null;
}

export interface GuideForMessageFragment_guideLexicalContext_recruiterName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuideForMessageFragment_guideLexicalContext_candidateName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuideForMessageFragment_guideLexicalContext_coordinatorName {
  __typename: "LexicalPersonNameContext";
  firstName: string;
  lastName: string | null;
  fullName: string;
}

export interface GuideForMessageFragment_guideLexicalContext {
  __typename: "GuideLexicalContext";
  id: GraphQL_UUID;
  href: string;
  recruiterName: GuideForMessageFragment_guideLexicalContext_recruiterName;
  candidateName: GuideForMessageFragment_guideLexicalContext_candidateName;
  candidatePhone: string | null;
  coordinatorName: GuideForMessageFragment_guideLexicalContext_coordinatorName | null;
  jobTitle: string;
  jobListingUrl: string | null;
  companyName: string;
}

export interface GuideForMessageFragment_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
  companyLogoUrl: string;
  defaultAvatarImageUrl: string;
  enableICSFiles: boolean;
}

export interface GuideForMessageFragment_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  email: string;
  imageUrl: string;
  emailAddresses: string[];
  timezone: string | null;
  organization: GuideForMessageFragment_candidate_organization;
}

export interface GuideForMessageFragment {
  __typename: "Guide";
  id: string;
  statusV2: GuideStatusV2Enum;
  currentStage: GuideForMessageFragment_currentStage | null;
  sortedVisibleStages: GuideForMessageFragment_sortedVisibleStages[];
  guideLexicalContext: GuideForMessageFragment_guideLexicalContext;
  candidate: GuideForMessageFragment_candidate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageMessageSelectorMessageTemplateFragment
// ====================================================

export interface StageMessageSelectorMessageTemplateFragment_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface StageMessageSelectorMessageTemplateFragment_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface StageMessageSelectorMessageTemplateFragment_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: StageMessageSelectorMessageTemplateFragment_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: StageMessageSelectorMessageTemplateFragment_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface StageMessageSelectorMessageTemplateFragment {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  postTemplateSyncData: StageMessageSelectorMessageTemplateFragment_postTemplateSyncData | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ThreadForDetailFragment
// ====================================================

export interface ThreadForDetailFragment {
  __typename: "GuidePost";
  id: string;
  title: string;
  data: GraphQL_JSON | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventInterviewerTopBarEventInterviewerFragment
// ====================================================

export interface EventInterviewerTopBarEventInterviewerFragment_personalProfile_userMembership {
  __typename: "UserMembership";
  id: string;
}

export interface EventInterviewerTopBarEventInterviewerFragment_personalProfile {
  __typename: "Interviewer";
  id: string;
  lastRequestedUserUpdateAt: GraphQL_DateTime | null;
  lastUserUpdatedAt: GraphQL_DateTime | null;
  userMembership: EventInterviewerTopBarEventInterviewerFragment_personalProfile_userMembership | null;
}

export interface EventInterviewerTopBarEventInterviewerFragment {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
  personalProfile: EventInterviewerTopBarEventInterviewerFragment_personalProfile;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RequestProfileUpdateExtensionMenuProfile
// ====================================================

export interface RequestProfileUpdateExtensionMenuProfile_userMembership {
  __typename: "UserMembership";
  id: string;
}

export interface RequestProfileUpdateExtensionMenuProfile {
  __typename: "Interviewer";
  id: string;
  lastRequestedUserUpdateAt: GraphQL_DateTime | null;
  lastUserUpdatedAt: GraphQL_DateTime | null;
  userMembership: RequestProfileUpdateExtensionMenuProfile_userMembership | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventInterviewerTopBarMenuEventInterviewerFragment
// ====================================================

export interface EventInterviewerTopBarMenuEventInterviewerFragment_personalProfile_userMembership {
  __typename: "UserMembership";
  id: string;
}

export interface EventInterviewerTopBarMenuEventInterviewerFragment_personalProfile {
  __typename: "Interviewer";
  id: string;
  lastRequestedUserUpdateAt: GraphQL_DateTime | null;
  lastUserUpdatedAt: GraphQL_DateTime | null;
  userMembership: EventInterviewerTopBarMenuEventInterviewerFragment_personalProfile_userMembership | null;
}

export interface EventInterviewerTopBarMenuEventInterviewerFragment {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
  personalProfile: EventInterviewerTopBarMenuEventInterviewerFragment_personalProfile;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostTemplateForManagingFragment
// ====================================================

export interface PostTemplateForManagingFragment_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface PostTemplateForManagingFragment_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: PostTemplateForManagingFragment_updatedBy_user;
}

export interface PostTemplateForManagingFragment_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface PostTemplateForManagingFragment {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: PostTemplateForManagingFragment_updatedBy;
  department: PostTemplateForManagingFragment_department | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostTemplatesTableUserMembershipFragment
// ====================================================

export interface PostTemplatesTableUserMembershipFragment_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface PostTemplatesTableUserMembershipFragment {
  __typename: "UserMembership";
  id: string;
  user: PostTemplatesTableUserMembershipFragment_user;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostTemplatesTableRowFragment
// ====================================================

export interface PostTemplatesTableRowFragment_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface PostTemplatesTableRowFragment_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: PostTemplatesTableRowFragment_updatedBy_user;
}

export interface PostTemplatesTableRowFragment_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface PostTemplatesTableRowFragment_createdBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface PostTemplatesTableRowFragment_createdBy {
  __typename: "UserMembership";
  id: string;
  user: PostTemplatesTableRowFragment_createdBy_user;
}

export interface PostTemplatesTableRowFragment_organization {
  __typename: "Organization";
  id: string;
}

export interface PostTemplatesTableRowFragment {
  __typename: "PostTemplate";
  id: string;
  name: string;
  createdAt: GraphQL_DateTime;
  updatedAt: GraphQL_DateTime;
  archivedAt: GraphQL_DateTime | null;
  status: PostTemplateStatusEnum | null;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedBy: PostTemplatesTableRowFragment_updatedBy;
  department: PostTemplatesTableRowFragment_department | null;
  createdBy: PostTemplatesTableRowFragment_createdBy;
  organization: PostTemplatesTableRowFragment_organization;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ArchivedPostTemplateResponseFragment
// ====================================================

export interface ArchivedPostTemplateResponseFragment_organization {
  __typename: "Organization";
  id: string;
}

export interface ArchivedPostTemplateResponseFragment {
  __typename: "PostTemplate";
  id: string;
  archivedAt: GraphQL_DateTime | null;
  status: PostTemplateStatusEnum | null;
  organization: ArchivedPostTemplateResponseFragment_organization;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostTemplateForCreatePostFragment
// ====================================================

export interface PostTemplateForCreatePostFragment {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostTemplateForSelector
// ====================================================

export interface PostTemplateForSelector_updatedBy_user {
  __typename: "User";
  id: string;
  name: string;
  imageUrl: string;
}

export interface PostTemplateForSelector_updatedBy {
  __typename: "UserMembership";
  id: string;
  user: PostTemplateForSelector_updatedBy_user;
}

export interface PostTemplateForSelector_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface PostTemplateForSelector_postTemplateSyncData_greenhouseEmailTemplate {
  __typename: "AtssyncGreenhouseEmailTemplate";
  id: string;
  originalATSId: string | null;
}

export interface PostTemplateForSelector_postTemplateSyncData_ownerPersonalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
}

export interface PostTemplateForSelector_postTemplateSyncData {
  __typename: "PostTemplateSyncData";
  id: string;
  atsUrl: string;
  greenhouseEmailTemplate: PostTemplateForSelector_postTemplateSyncData_greenhouseEmailTemplate;
  ownerPersonalProfile: PostTemplateForSelector_postTemplateSyncData_ownerPersonalProfile | null;
}

export interface PostTemplateForSelector_createdBy_personalProfile {
  __typename: "Interviewer";
  id: string;
  displayName: string;
  imageUrl: string;
}

export interface PostTemplateForSelector_createdBy {
  __typename: "UserMembership";
  id: string;
  personalProfile: PostTemplateForSelector_createdBy_personalProfile;
}

export interface PostTemplateForSelector {
  __typename: "PostTemplate";
  id: string;
  name: string;
  title: string | null;
  data: GraphQL_JSON | null;
  updatedAt: GraphQL_DateTime;
  updatedBy: PostTemplateForSelector_updatedBy;
  department: PostTemplateForSelector_department | null;
  postTemplateSyncData: PostTemplateForSelector_postTemplateSyncData | null;
  createdBy: PostTemplateForSelector_createdBy;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ContentTemplatePreviewOrganization
// ====================================================

export interface ContentTemplatePreviewOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
}

export interface ContentTemplatePreviewOrganization {
  __typename: "Organization";
  id: string;
  theme: ContentTemplatePreviewOrganization_theme | null;
  companyLogoUrl: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationThemedPreviewContainerOrganization
// ====================================================

export interface OrganizationThemedPreviewContainerOrganization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface OrganizationThemedPreviewContainerOrganization {
  __typename: "Organization";
  theme: OrganizationThemedPreviewContainerOrganization_theme | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RecipientsManagerCandidateFragment
// ====================================================

export interface RecipientsManagerCandidateFragment {
  __typename: "Candidate";
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SendTopBarStage
// ====================================================

export interface SendTopBarStage_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
  guideVersion: number;
  publicUrl: string;
}

export interface SendTopBarStage {
  __typename: "Stage";
  id: string;
  guide: SendTopBarStage_guide;
  status: StageStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DefaultInterviewsForJobSetup
// ====================================================

export interface DefaultInterviewsForJobSetup_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

export interface DefaultInterviewsForJobSetup_interviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  title: GraphQL_JSON | null;
  description: GraphQL_JSON | null;
}

export interface DefaultInterviewsForJobSetup {
  __typename: "EventTemplate";
  id: string;
  title: string;
  position: number;
  description: string;
  hidden: boolean;
  atsEventTemplateId: string;
  atsEventTemplateName: string;
  atssyncGreenhouseJobStage: DefaultInterviewsForJobSetup_atssyncGreenhouseJobStage | null;
  interviewTemplate: DefaultInterviewsForJobSetup_interviewTemplate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageActionsStageFragment
// ====================================================

export interface StageActionsStageFragment_guide_nextStage {
  __typename: "Stage";
  id: string;
}

export interface StageActionsStageFragment_guide_currentStage {
  __typename: "Stage";
  id: string;
}

export interface StageActionsStageFragment_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
  stageSyncStatus: StageSyncStatus;
  nextStage: StageActionsStageFragment_guide_nextStage | null;
  currentStage: StageActionsStageFragment_guide_currentStage | null;
  guideVersion: number;
  publicUrl: string;
}

export interface StageActionsStageFragment {
  __typename: "Stage";
  id: string;
  status: StageStatus;
  guide: StageActionsStageFragment_guide;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageActivityFeedStage
// ====================================================

export interface StageActivityFeedStage_emailActivities_sender {
  __typename: "User";
  id: string;
  imageUrl: string;
  fullName: string;
}

export interface StageActivityFeedStage_emailActivities {
  __typename: "EmailActivity";
  id: string;
  createdAt: GraphQL_DateTime;
  sentTo: string;
  sentFrom: string;
  subject: string;
  body: string;
  sender: StageActivityFeedStage_emailActivities_sender | null;
}

export interface StageActivityFeedStage {
  __typename: "Stage";
  id: string;
  emailActivities: StageActivityFeedStage_emailActivities[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageBannerStage
// ====================================================

export interface StageBannerStage_guide_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
}

export interface StageBannerStage_guide {
  __typename: "Guide";
  id: string;
  jobRoleGuideTemplate: StageBannerStage_guide_jobRoleGuideTemplate | null;
}

export interface StageBannerStage {
  __typename: "Stage";
  id: string;
  configured: boolean;
  hidden: boolean;
  guide: StageBannerStage_guide;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageConfigurationStageFragment
// ====================================================

export interface StageConfigurationStageFragment_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface StageConfigurationStageFragment_guide_candidate_organization_interviewers {
  __typename: "Interviewer";
  id: string;
  name: string;
  email: string | null;
  displayImageUrl: string;
}

export interface StageConfigurationStageFragment_guide_candidate_organization {
  __typename: "Organization";
  id: string;
  customVariableOptions: string[];
  interviewers: StageConfigurationStageFragment_guide_candidate_organization_interviewers[];
}

export interface StageConfigurationStageFragment_guide_candidate {
  __typename: "Candidate";
  id: string;
  organization: StageConfigurationStageFragment_guide_candidate_organization;
}

export interface StageConfigurationStageFragment_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
  publicUrl: string;
  candidate: StageConfigurationStageFragment_guide_candidate;
}

export interface StageConfigurationStageFragment_customHiringTeam {
  __typename: "Interviewer";
  id: string;
  name: string;
  email: string | null;
}

export interface StageConfigurationStageFragment {
  __typename: "Stage";
  id: string;
  hidden: boolean;
  status: StageStatus;
  configured: boolean;
  atsStage: StageConfigurationStageFragment_atsStage | null;
  guide: StageConfigurationStageFragment_guide;
  customVariables: GraphQL_JSON;
  customHiringTeam: StageConfigurationStageFragment_customHiringTeam[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageContentStage
// ====================================================

export interface StageContentStage_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface StageContentStage_guide_stages {
  __typename: "Stage";
  id: string;
  hidden: boolean;
  status: StageStatus;
  configured: boolean;
}

export interface StageContentStage_guide_candidate_organization_customer_atsIntegration {
  __typename: "AtsIntegration";
  id: string;
  atsType: string | null;
  displayName: string | null;
}

export interface StageContentStage_guide_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  atsIntegration: StageContentStage_guide_candidate_organization_customer_atsIntegration | null;
}

export interface StageContentStage_guide_candidate_organization_interviewers {
  __typename: "Interviewer";
  id: string;
  name: string;
  email: string | null;
  displayImageUrl: string;
}

export interface StageContentStage_guide_candidate_organization {
  __typename: "Organization";
  id: string;
  customer: StageContentStage_guide_candidate_organization_customer;
  customVariableOptions: string[];
  interviewers: StageContentStage_guide_candidate_organization_interviewers[];
}

export interface StageContentStage_guide_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
  organization: StageContentStage_guide_candidate_organization;
  currentTimezone: string;
}

export interface StageContentStage_guide_nextStage {
  __typename: "Stage";
  id: string;
}

export interface StageContentStage_guide_currentStage {
  __typename: "Stage";
  id: string;
}

export interface StageContentStage_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
  stages: StageContentStage_guide_stages[];
  candidate: StageContentStage_guide_candidate;
  publicUrl: string;
  stageSyncStatus: StageSyncStatus;
  nextStage: StageContentStage_guide_nextStage | null;
  currentStage: StageContentStage_guide_currentStage | null;
  guideVersion: number;
}

export interface StageContentStage_customHiringTeam {
  __typename: "Interviewer";
  id: string;
  name: string;
  email: string | null;
}

export interface StageContentStage_interviews_event_interviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  title: string;
  imageUrl: string;
}

export interface StageContentStage_interviews_event_interviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
  personalProfile: StageContentStage_interviews_event_interviewParticipants_EventInterviewer_personalProfile;
}

export interface StageContentStage_interviews_event_interviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export type StageContentStage_interviews_event_interviewParticipants = StageContentStage_interviews_event_interviewParticipants_EventInterviewer | StageContentStage_interviews_event_interviewParticipants_Candidate;

export interface StageContentStage_interviews_event {
  __typename: "Event";
  id: string;
  hidden: boolean;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  displayTitle: string;
  interviewParticipants: StageContentStage_interviews_event_interviewParticipants[];
  rawTitle: string;
  replacementData: GraphQL_JSON;
  conferenceUrl: string | null;
}

export interface StageContentStage_interviews_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
}

export interface StageContentStage_interviews_schedulerConfig_availability {
  __typename: "SchedulerAvailability";
  id: string;
  slug: string;
}

export interface StageContentStage_interviews_schedulerConfig {
  __typename: "EventTemplateInstallationSchedulerConfig";
  availability: StageContentStage_interviews_schedulerConfig_availability;
}

export interface StageContentStage_interviews_defaultInterviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface StageContentStage_interviews_defaultInterviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  imageUrl: string;
}

export interface StageContentStage_interviews_defaultInterviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  personalProfile: StageContentStage_interviews_defaultInterviewParticipants_EventInterviewer_personalProfile;
}

export type StageContentStage_interviews_defaultInterviewParticipants = StageContentStage_interviews_defaultInterviewParticipants_Candidate | StageContentStage_interviews_defaultInterviewParticipants_EventInterviewer;

export interface StageContentStage_interviews {
  __typename: "EventTemplateInstallation";
  id: string;
  event: StageContentStage_interviews_event | null;
  title: string;
  description: string;
  hidden: boolean;
  replacementData: GraphQL_JSON;
  eventTemplate: StageContentStage_interviews_eventTemplate;
  schedulerConfig: StageContentStage_interviews_schedulerConfig | null;
  defaultInterviewParticipants: StageContentStage_interviews_defaultInterviewParticipants[];
}

export interface StageContentStage {
  __typename: "Stage";
  id: string;
  configured: boolean;
  position: number;
  hidden: boolean;
  title: string;
  isAtsCurrent: boolean;
  status: StageStatus;
  atsStage: StageContentStage_atsStage | null;
  guide: StageContentStage_guide;
  customVariables: GraphQL_JSON;
  customHiringTeam: StageContentStage_customHiringTeam[];
  interviews: StageContentStage_interviews[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageContentGuide
// ====================================================

export interface StageContentGuide {
  __typename: "Guide";
  id: string;
  guideVersion: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageHeaderFragment
// ====================================================

export interface StageHeaderFragment_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface StageHeaderFragment_guide_stages {
  __typename: "Stage";
  id: string;
  hidden: boolean;
  status: StageStatus;
  configured: boolean;
}

export interface StageHeaderFragment_guide_candidate_organization_customer_atsIntegration {
  __typename: "AtsIntegration";
  id: string;
  atsType: string | null;
  displayName: string | null;
}

export interface StageHeaderFragment_guide_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  atsIntegration: StageHeaderFragment_guide_candidate_organization_customer_atsIntegration | null;
}

export interface StageHeaderFragment_guide_candidate_organization {
  __typename: "Organization";
  id: string;
  customer: StageHeaderFragment_guide_candidate_organization_customer;
}

export interface StageHeaderFragment_guide_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
  organization: StageHeaderFragment_guide_candidate_organization;
}

export interface StageHeaderFragment_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
  stages: StageHeaderFragment_guide_stages[];
  candidate: StageHeaderFragment_guide_candidate;
}

export interface StageHeaderFragment {
  __typename: "Stage";
  id: string;
  configured: boolean;
  position: number;
  hidden: boolean;
  title: string;
  isAtsCurrent: boolean;
  status: StageStatus;
  atsStage: StageHeaderFragment_atsStage | null;
  guide: StageHeaderFragment_guide;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageListGuideFragment
// ====================================================

export interface StageListGuideFragment_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface StageListGuideFragment_stages_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface StageListGuideFragment_stages_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
}

export interface StageListGuideFragment_stages {
  __typename: "Stage";
  id: string;
  configured: boolean;
  title: string;
  status: StageStatus;
  isAtsCurrent: boolean;
  hidden: boolean;
  hasAutomation: boolean;
  position: number;
  atsStage: StageListGuideFragment_stages_atsStage | null;
  guide: StageListGuideFragment_stages_guide;
}

export interface StageListGuideFragment_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  hasAutomation: boolean;
  configured: boolean;
  title: string;
  status: StageStatus;
  position: number;
}

export interface StageListGuideFragment {
  __typename: "Guide";
  id: string;
  guideVersion: number;
  candidate: StageListGuideFragment_candidate;
  stages: StageListGuideFragment_stages[];
  status: GuideStatusEnum;
  sortedVisibleStages: StageListGuideFragment_sortedVisibleStages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageListHeaderGuideFragment
// ====================================================

export interface StageListHeaderGuideFragment_candidate {
  __typename: "Candidate";
  id: string;
}

export interface StageListHeaderGuideFragment_stages {
  __typename: "Stage";
  id: string;
  hasAutomation: boolean;
  configured: boolean;
  title: string;
  status: StageStatus;
  position: number;
}

export interface StageListHeaderGuideFragment_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  hasAutomation: boolean;
  configured: boolean;
  title: string;
  status: StageStatus;
  position: number;
}

export interface StageListHeaderGuideFragment {
  __typename: "Guide";
  id: string;
  guideVersion: number;
  status: GuideStatusEnum;
  candidate: StageListHeaderGuideFragment_candidate;
  stages: StageListHeaderGuideFragment_stages[];
  sortedVisibleStages: StageListHeaderGuideFragment_sortedVisibleStages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageListRowCandidateFragment
// ====================================================

export interface StageListRowCandidateFragment {
  __typename: "Candidate";
  name: string;
  imageUrl: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageListRowStageFragment
// ====================================================

export interface StageListRowStageFragment_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface StageListRowStageFragment_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
}

export interface StageListRowStageFragment {
  __typename: "Stage";
  id: string;
  title: string;
  status: StageStatus;
  isAtsCurrent: boolean;
  hidden: boolean;
  hasAutomation: boolean;
  position: number;
  atsStage: StageListRowStageFragment_atsStage | null;
  guide: StageListRowStageFragment_guide;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventListInterview
// ====================================================

export interface EventListInterview_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
}

export interface EventListInterview_schedulerConfig_availability {
  __typename: "SchedulerAvailability";
  id: string;
  slug: string;
}

export interface EventListInterview_schedulerConfig {
  __typename: "EventTemplateInstallationSchedulerConfig";
  availability: EventListInterview_schedulerConfig_availability;
}

export interface EventListInterview_defaultInterviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface EventListInterview_defaultInterviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  imageUrl: string;
}

export interface EventListInterview_defaultInterviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  personalProfile: EventListInterview_defaultInterviewParticipants_EventInterviewer_personalProfile;
}

export type EventListInterview_defaultInterviewParticipants = EventListInterview_defaultInterviewParticipants_Candidate | EventListInterview_defaultInterviewParticipants_EventInterviewer;

export interface EventListInterview_event_interviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface EventListInterview_event_interviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  imageUrl: string;
}

export interface EventListInterview_event_interviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  personalProfile: EventListInterview_event_interviewParticipants_EventInterviewer_personalProfile;
}

export type EventListInterview_event_interviewParticipants = EventListInterview_event_interviewParticipants_Candidate | EventListInterview_event_interviewParticipants_EventInterviewer;

export interface EventListInterview_event {
  __typename: "Event";
  id: string;
  rawTitle: string;
  replacementData: GraphQL_JSON;
  hidden: boolean;
  displayTitle: string;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewParticipants: EventListInterview_event_interviewParticipants[];
}

export interface EventListInterview {
  __typename: "EventTemplateInstallation";
  id: string;
  title: string;
  description: string;
  hidden: boolean;
  replacementData: GraphQL_JSON;
  eventTemplate: EventListInterview_eventTemplate;
  schedulerConfig: EventListInterview_schedulerConfig | null;
  defaultInterviewParticipants: EventListInterview_defaultInterviewParticipants[];
  event: EventListInterview_event | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventListRowInterviewParticipantFragment
// ====================================================

export interface EventListRowInterviewParticipantFragment_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface EventListRowInterviewParticipantFragment_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  imageUrl: string;
}

export interface EventListRowInterviewParticipantFragment_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  personalProfile: EventListRowInterviewParticipantFragment_EventInterviewer_personalProfile;
}

export type EventListRowInterviewParticipantFragment = EventListRowInterviewParticipantFragment_Candidate | EventListRowInterviewParticipantFragment_EventInterviewer;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventTemplateInstallationListRowFragment
// ====================================================

export interface EventTemplateInstallationListRowFragment_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
}

export interface EventTemplateInstallationListRowFragment_schedulerConfig_availability {
  __typename: "SchedulerAvailability";
  id: string;
  slug: string;
}

export interface EventTemplateInstallationListRowFragment_schedulerConfig {
  __typename: "EventTemplateInstallationSchedulerConfig";
  availability: EventTemplateInstallationListRowFragment_schedulerConfig_availability;
}

export interface EventTemplateInstallationListRowFragment_defaultInterviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface EventTemplateInstallationListRowFragment_defaultInterviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  imageUrl: string;
}

export interface EventTemplateInstallationListRowFragment_defaultInterviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  personalProfile: EventTemplateInstallationListRowFragment_defaultInterviewParticipants_EventInterviewer_personalProfile;
}

export type EventTemplateInstallationListRowFragment_defaultInterviewParticipants = EventTemplateInstallationListRowFragment_defaultInterviewParticipants_Candidate | EventTemplateInstallationListRowFragment_defaultInterviewParticipants_EventInterviewer;

export interface EventTemplateInstallationListRowFragment_event_interviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface EventTemplateInstallationListRowFragment_event_interviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  imageUrl: string;
}

export interface EventTemplateInstallationListRowFragment_event_interviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  personalProfile: EventTemplateInstallationListRowFragment_event_interviewParticipants_EventInterviewer_personalProfile;
}

export type EventTemplateInstallationListRowFragment_event_interviewParticipants = EventTemplateInstallationListRowFragment_event_interviewParticipants_Candidate | EventTemplateInstallationListRowFragment_event_interviewParticipants_EventInterviewer;

export interface EventTemplateInstallationListRowFragment_event {
  __typename: "Event";
  id: string;
  rawTitle: string;
  replacementData: GraphQL_JSON;
  hidden: boolean;
  displayTitle: string;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewParticipants: EventTemplateInstallationListRowFragment_event_interviewParticipants[];
}

export interface EventTemplateInstallationListRowFragment {
  __typename: "EventTemplateInstallation";
  id: string;
  title: string;
  description: string;
  hidden: boolean;
  replacementData: GraphQL_JSON;
  eventTemplate: EventTemplateInstallationListRowFragment_eventTemplate;
  schedulerConfig: EventTemplateInstallationListRowFragment_schedulerConfig | null;
  defaultInterviewParticipants: EventTemplateInstallationListRowFragment_defaultInterviewParticipants[];
  event: EventTemplateInstallationListRowFragment_event | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventListRowSubtitleEventTemplateInstallation
// ====================================================

export interface EventListRowSubtitleEventTemplateInstallation_schedulerConfig_availability {
  __typename: "SchedulerAvailability";
  id: string;
  slug: string;
}

export interface EventListRowSubtitleEventTemplateInstallation_schedulerConfig {
  __typename: "EventTemplateInstallationSchedulerConfig";
  availability: EventListRowSubtitleEventTemplateInstallation_schedulerConfig_availability;
}

export interface EventListRowSubtitleEventTemplateInstallation_event {
  __typename: "Event";
  id: string;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
}

export interface EventListRowSubtitleEventTemplateInstallation {
  __typename: "EventTemplateInstallation";
  id: string;
  schedulerConfig: EventListRowSubtitleEventTemplateInstallation_schedulerConfig | null;
  event: EventListRowSubtitleEventTemplateInstallation_event | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageScheduleStageFragment
// ====================================================

export interface StageScheduleStageFragment_interviews_event_interviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  title: string;
  imageUrl: string;
}

export interface StageScheduleStageFragment_interviews_event_interviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
  personalProfile: StageScheduleStageFragment_interviews_event_interviewParticipants_EventInterviewer_personalProfile;
}

export interface StageScheduleStageFragment_interviews_event_interviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export type StageScheduleStageFragment_interviews_event_interviewParticipants = StageScheduleStageFragment_interviews_event_interviewParticipants_EventInterviewer | StageScheduleStageFragment_interviews_event_interviewParticipants_Candidate;

export interface StageScheduleStageFragment_interviews_event {
  __typename: "Event";
  id: string;
  hidden: boolean;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  displayTitle: string;
  interviewParticipants: StageScheduleStageFragment_interviews_event_interviewParticipants[];
  rawTitle: string;
  replacementData: GraphQL_JSON;
  conferenceUrl: string | null;
}

export interface StageScheduleStageFragment_interviews_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
}

export interface StageScheduleStageFragment_interviews_schedulerConfig_availability {
  __typename: "SchedulerAvailability";
  id: string;
  slug: string;
}

export interface StageScheduleStageFragment_interviews_schedulerConfig {
  __typename: "EventTemplateInstallationSchedulerConfig";
  availability: StageScheduleStageFragment_interviews_schedulerConfig_availability;
}

export interface StageScheduleStageFragment_interviews_defaultInterviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface StageScheduleStageFragment_interviews_defaultInterviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  imageUrl: string;
}

export interface StageScheduleStageFragment_interviews_defaultInterviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  personalProfile: StageScheduleStageFragment_interviews_defaultInterviewParticipants_EventInterviewer_personalProfile;
}

export type StageScheduleStageFragment_interviews_defaultInterviewParticipants = StageScheduleStageFragment_interviews_defaultInterviewParticipants_Candidate | StageScheduleStageFragment_interviews_defaultInterviewParticipants_EventInterviewer;

export interface StageScheduleStageFragment_interviews {
  __typename: "EventTemplateInstallation";
  id: string;
  event: StageScheduleStageFragment_interviews_event | null;
  title: string;
  description: string;
  hidden: boolean;
  replacementData: GraphQL_JSON;
  eventTemplate: StageScheduleStageFragment_interviews_eventTemplate;
  schedulerConfig: StageScheduleStageFragment_interviews_schedulerConfig | null;
  defaultInterviewParticipants: StageScheduleStageFragment_interviews_defaultInterviewParticipants[];
}

export interface StageScheduleStageFragment_guide_candidate {
  __typename: "Candidate";
  id: string;
  currentTimezone: string;
}

export interface StageScheduleStageFragment_guide {
  __typename: "Guide";
  id: string;
  candidate: StageScheduleStageFragment_guide_candidate;
}

export interface StageScheduleStageFragment {
  __typename: "Stage";
  id: string;
  interviews: StageScheduleStageFragment_interviews[];
  guide: StageScheduleStageFragment_guide;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageScheduleHeaderStage
// ====================================================

export interface StageScheduleHeaderStage_interviews_event_interviewParticipants_Candidate {
  __typename: "Candidate";
}

export interface StageScheduleHeaderStage_interviews_event_interviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  title: string;
}

export interface StageScheduleHeaderStage_interviews_event_interviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
  personalProfile: StageScheduleHeaderStage_interviews_event_interviewParticipants_EventInterviewer_personalProfile;
}

export type StageScheduleHeaderStage_interviews_event_interviewParticipants = StageScheduleHeaderStage_interviews_event_interviewParticipants_Candidate | StageScheduleHeaderStage_interviews_event_interviewParticipants_EventInterviewer;

export interface StageScheduleHeaderStage_interviews_event {
  __typename: "Event";
  id: string;
  hidden: boolean;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  displayTitle: string;
  interviewParticipants: StageScheduleHeaderStage_interviews_event_interviewParticipants[];
}

export interface StageScheduleHeaderStage_interviews {
  __typename: "EventTemplateInstallation";
  id: string;
  event: StageScheduleHeaderStage_interviews_event | null;
}

export interface StageScheduleHeaderStage_guide_candidate {
  __typename: "Candidate";
  id: string;
  currentTimezone: string;
}

export interface StageScheduleHeaderStage_guide {
  __typename: "Guide";
  id: string;
  candidate: StageScheduleHeaderStage_guide_candidate;
}

export interface StageScheduleHeaderStage {
  __typename: "Stage";
  id: string;
  interviews: StageScheduleHeaderStage_interviews[];
  guide: StageScheduleHeaderStage_guide;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageScheduleHeaderMenuStage
// ====================================================

export interface StageScheduleHeaderMenuStage_interviews_event_interviewParticipants_Candidate {
  __typename: "Candidate";
}

export interface StageScheduleHeaderMenuStage_interviews_event_interviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  title: string;
}

export interface StageScheduleHeaderMenuStage_interviews_event_interviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
  personalProfile: StageScheduleHeaderMenuStage_interviews_event_interviewParticipants_EventInterviewer_personalProfile;
}

export type StageScheduleHeaderMenuStage_interviews_event_interviewParticipants = StageScheduleHeaderMenuStage_interviews_event_interviewParticipants_Candidate | StageScheduleHeaderMenuStage_interviews_event_interviewParticipants_EventInterviewer;

export interface StageScheduleHeaderMenuStage_interviews_event {
  __typename: "Event";
  id: string;
  hidden: boolean;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  displayTitle: string;
  interviewParticipants: StageScheduleHeaderMenuStage_interviews_event_interviewParticipants[];
}

export interface StageScheduleHeaderMenuStage_interviews {
  __typename: "EventTemplateInstallation";
  id: string;
  event: StageScheduleHeaderMenuStage_interviews_event | null;
}

export interface StageScheduleHeaderMenuStage_guide_candidate {
  __typename: "Candidate";
  id: string;
  currentTimezone: string;
}

export interface StageScheduleHeaderMenuStage_guide {
  __typename: "Guide";
  id: string;
  candidate: StageScheduleHeaderMenuStage_guide_candidate;
}

export interface StageScheduleHeaderMenuStage {
  __typename: "Stage";
  id: string;
  interviews: StageScheduleHeaderMenuStage_interviews[];
  guide: StageScheduleHeaderMenuStage_guide;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageTopBarStageFragment
// ====================================================

export interface StageTopBarStageFragment_guide_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  defaultGuideVersion: number;
}

export interface StageTopBarStageFragment_guide {
  __typename: "Guide";
  id: string;
  guideVersion: number;
  jobRoleGuideTemplate: StageTopBarStageFragment_guide_jobRoleGuideTemplate | null;
}

export interface StageTopBarStageFragment {
  __typename: "Stage";
  id: string;
  guide: StageTopBarStageFragment_guide;
  hidden: boolean;
  configured: boolean;
  status: StageStatus;
  stageTemplateInstallationId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageTopBarMenuStageFragment
// ====================================================

export interface StageTopBarMenuStageFragment_guide {
  __typename: "Guide";
  id: string;
}

export interface StageTopBarMenuStageFragment {
  __typename: "Stage";
  id: string;
  hidden: boolean;
  configured: boolean;
  status: StageStatus;
  stageTemplateInstallationId: string | null;
  guide: StageTopBarMenuStageFragment_guide;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobRoleGuideTemplateJobRoleGuideTemplate
// ====================================================

export interface JobRoleGuideTemplateJobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface JobRoleGuideTemplateJobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: JobRoleGuideTemplateJobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface JobRoleGuideTemplateJobRoleGuideTemplate_jobRoleGuideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface JobRoleGuideTemplateJobRoleGuideTemplate_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  contentTemplate: JobRoleGuideTemplateJobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate | null;
  emailTemplate: JobRoleGuideTemplateJobRoleGuideTemplate_jobRoleGuideTemplateStages_emailTemplate | null;
}

export interface JobRoleGuideTemplateJobRoleGuideTemplate_guideTemplate_department {
  __typename: "ATSDepartment";
  id: string;
  name: string;
}

export interface JobRoleGuideTemplateJobRoleGuideTemplate_guideTemplate {
  __typename: "GuideTemplate";
  id: string;
  name: string;
  isGlobal: boolean;
  department: JobRoleGuideTemplateJobRoleGuideTemplate_guideTemplate_department | null;
}

export interface JobRoleGuideTemplateJobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  jobRoleGuideTemplateStages: JobRoleGuideTemplateJobRoleGuideTemplate_jobRoleGuideTemplateStages[];
  guideTemplate: JobRoleGuideTemplateJobRoleGuideTemplate_guideTemplate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobRoleGuideTemplateStagePreviewJobRoleGuideTemplate
// ====================================================

export interface JobRoleGuideTemplateStagePreviewJobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate_modulesInflated {
  __typename: "StageResolvedModule";
  id: string;
  type: string;
  position: number;
  data: GraphQL_JSON;
}

export interface JobRoleGuideTemplateStagePreviewJobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate {
  __typename: "ContentTemplate";
  id: string;
  name: string;
  /**
   * Returns the Modules for this ContentTemplate inflated 
   *     with data from the database that is NOT contextual to the 
   *     preview or actual guide being rendered.
   */
  modulesInflated: JobRoleGuideTemplateStagePreviewJobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate_modulesInflated[];
}

export interface JobRoleGuideTemplateStagePreviewJobRoleGuideTemplate_jobRoleGuideTemplateStages_emailTemplate {
  __typename: "EmailTemplate";
  id: string;
  name: string;
  subject: string;
  body: string;
}

export interface JobRoleGuideTemplateStagePreviewJobRoleGuideTemplate_jobRoleGuideTemplateStages {
  __typename: "JobRoleGuideTemplateStage";
  id: string;
  name: string;
  description: string;
  position: number;
  contentTemplate: JobRoleGuideTemplateStagePreviewJobRoleGuideTemplate_jobRoleGuideTemplateStages_contentTemplate | null;
  emailTemplate: JobRoleGuideTemplateStagePreviewJobRoleGuideTemplate_jobRoleGuideTemplateStages_emailTemplate | null;
}

export interface JobRoleGuideTemplateStagePreviewJobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  jobRoleGuideTemplateStages: JobRoleGuideTemplateStagePreviewJobRoleGuideTemplate_jobRoleGuideTemplateStages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OpenJobForTableFragment
// ====================================================

export interface OpenJobForTableFragment {
  __typename: "OpenJob";
  id: string;
  setupStatus: JobRoleGuideTemplateSetupStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BulkRequestProfileUpdateProfile
// ====================================================

export interface BulkRequestProfileUpdateProfile {
  __typename: "Interviewer";
  id: string;
  lastRequestedUserUpdateAt: GraphQL_DateTime | null;
  lastUserUpdatedAt: GraphQL_DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RequestProfileUpdateProfile
// ====================================================

export interface RequestProfileUpdateProfile {
  __typename: "Interviewer";
  id: string;
  lastRequestedUserUpdateAt: GraphQL_DateTime | null;
  lastUserUpdatedAt: GraphQL_DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageStepStageFragment
// ====================================================

export interface StageStepStageFragment_feedbackSubmissionData_feedbackSubmission {
  __typename: "CandidateFeedback";
  id: string;
}

export interface StageStepStageFragment_feedbackSubmissionData_surveySubmission {
  __typename: "CandidateSurvey";
  id: string;
}

export interface StageStepStageFragment_feedbackSubmissionData {
  __typename: "StageFeedbackSubmissionData";
  needsFeedback: boolean;
  feedbackSubmission: StageStepStageFragment_feedbackSubmissionData_feedbackSubmission | null;
  surveySubmission: StageStepStageFragment_feedbackSubmissionData_surveySubmission | null;
}

export interface StageStepStageFragment {
  __typename: "Stage";
  id: string;
  title: string;
  description: string | null;
  feedbackSubmissionData: StageStepStageFragment_feedbackSubmissionData | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageStepperGuideFragment
// ====================================================

export interface StageStepperGuideFragment_currentStage {
  __typename: "Stage";
  id: string;
}

export interface StageStepperGuideFragment_sortedVisibleStages_feedbackSubmissionData_feedbackSubmission {
  __typename: "CandidateFeedback";
  id: string;
}

export interface StageStepperGuideFragment_sortedVisibleStages_feedbackSubmissionData_surveySubmission {
  __typename: "CandidateSurvey";
  id: string;
}

export interface StageStepperGuideFragment_sortedVisibleStages_feedbackSubmissionData {
  __typename: "StageFeedbackSubmissionData";
  needsFeedback: boolean;
  feedbackSubmission: StageStepperGuideFragment_sortedVisibleStages_feedbackSubmissionData_feedbackSubmission | null;
  surveySubmission: StageStepperGuideFragment_sortedVisibleStages_feedbackSubmissionData_surveySubmission | null;
}

export interface StageStepperGuideFragment_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  position: number;
  title: string;
  hidden: boolean;
  description: string | null;
  feedbackSubmissionData: StageStepperGuideFragment_sortedVisibleStages_feedbackSubmissionData | null;
}

export interface StageStepperGuideFragment {
  __typename: "Guide";
  currentStage: StageStepperGuideFragment_currentStage | null;
  sortedVisibleStages: StageStepperGuideFragment_sortedVisibleStages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FetchGuideFragmentGuide
// ====================================================

export interface FetchGuideFragmentGuide_currentStage_modules {
  __typename: "StageResolvedModule";
  id: string;
  position: number;
  data: GraphQL_JSON;
  type: string;
}

export interface FetchGuideFragmentGuide_currentStage {
  __typename: "Stage";
  id: string;
  position: number;
  title: string;
  /**
   * Returns the Content Modules for this stage contextualized
   *        to the stage's Guide. Today, the content modules come from a combination of 
   *        stamped modules (the interview scheduler) and the associated ContentTemplate's modules
   */
  modules: FetchGuideFragmentGuide_currentStage_modules[];
}

export interface FetchGuideFragmentGuide_candidate_organization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface FetchGuideFragmentGuide_candidate_organization_customer_plan {
  __typename: "CustomerPlan";
  id: string;
  name: string;
}

export interface FetchGuideFragmentGuide_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  trialDuration: number | null;
  trialStart: GraphQL_DateTime | null;
  name: string;
  plan: FetchGuideFragmentGuide_candidate_organization_customer_plan;
}

export interface FetchGuideFragmentGuide_candidate_organization {
  __typename: "Organization";
  id: string;
  companyLogoUrl: string;
  theme: FetchGuideFragmentGuide_candidate_organization_theme | null;
  showTrim: boolean;
  hideLogoAndTrackerInGuides: boolean;
  disableChatGlobally: boolean;
  name: string;
  createdAt: GraphQL_DateTime;
  zeusStatus: ZeusStatusEnum;
  planPricingModel: PlanPricingModelEnum | null;
  accountStatus: AccountStatusEnum | null;
  employeeCountEnum: CompanyEmployeeSizeEnum | null;
  talentTeamCountEnum: CompanyTalentTeamSizeEnum | null;
  contractStartDate: GraphQL_DateTime | null;
  customer: FetchGuideFragmentGuide_candidate_organization_customer;
}

export interface FetchGuideFragmentGuide_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  organization: FetchGuideFragmentGuide_candidate_organization;
}

export interface FetchGuideFragmentGuide_sortedVisibleStages_feedbackSubmissionData_feedbackSubmission {
  __typename: "CandidateFeedback";
  id: string;
}

export interface FetchGuideFragmentGuide_sortedVisibleStages_feedbackSubmissionData_surveySubmission {
  __typename: "CandidateSurvey";
  id: string;
}

export interface FetchGuideFragmentGuide_sortedVisibleStages_feedbackSubmissionData {
  __typename: "StageFeedbackSubmissionData";
  needsFeedback: boolean;
  feedbackSubmission: FetchGuideFragmentGuide_sortedVisibleStages_feedbackSubmissionData_feedbackSubmission | null;
  surveySubmission: FetchGuideFragmentGuide_sortedVisibleStages_feedbackSubmissionData_surveySubmission | null;
}

export interface FetchGuideFragmentGuide_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  position: number;
  title: string;
  hidden: boolean;
  description: string | null;
  feedbackSubmissionData: FetchGuideFragmentGuide_sortedVisibleStages_feedbackSubmissionData | null;
}

export interface FetchGuideFragmentGuide_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  defaultGuideVersion: number;
}

export interface FetchGuideFragmentGuide {
  __typename: "Guide";
  id: string;
  atsUrl: string;
  chatEnabled: boolean;
  interviewProcessEnabled: boolean;
  jobRoleGuideTemplateId: string | null;
  isDemo: boolean;
  currentStage: FetchGuideFragmentGuide_currentStage | null;
  candidate: FetchGuideFragmentGuide_candidate;
  sortedVisibleStages: FetchGuideFragmentGuide_sortedVisibleStages[];
  guideVersion: number;
  jobRoleGuideTemplate: FetchGuideFragmentGuide_jobRoleGuideTemplate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StageDetailsStage
// ====================================================

export interface StageDetailsStage_guide_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  defaultGuideVersion: number;
}

export interface StageDetailsStage_guide_stages {
  __typename: "Stage";
  id: string;
  hidden: boolean;
  status: StageStatus;
  configured: boolean;
}

export interface StageDetailsStage_guide_candidate_organization_customer_atsIntegration {
  __typename: "AtsIntegration";
  id: string;
  atsType: string | null;
  displayName: string | null;
}

export interface StageDetailsStage_guide_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  atsIntegration: StageDetailsStage_guide_candidate_organization_customer_atsIntegration | null;
}

export interface StageDetailsStage_guide_candidate_organization_interviewers {
  __typename: "Interviewer";
  id: string;
  name: string;
  email: string | null;
  displayImageUrl: string;
}

export interface StageDetailsStage_guide_candidate_organization {
  __typename: "Organization";
  id: string;
  customer: StageDetailsStage_guide_candidate_organization_customer;
  customVariableOptions: string[];
  interviewers: StageDetailsStage_guide_candidate_organization_interviewers[];
}

export interface StageDetailsStage_guide_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
  organization: StageDetailsStage_guide_candidate_organization;
  currentTimezone: string;
}

export interface StageDetailsStage_guide_nextStage {
  __typename: "Stage";
  id: string;
}

export interface StageDetailsStage_guide_currentStage {
  __typename: "Stage";
  id: string;
}

export interface StageDetailsStage_guide {
  __typename: "Guide";
  id: string;
  mergesyncApplicationId: string | null;
  jobRoleGuideTemplate: StageDetailsStage_guide_jobRoleGuideTemplate | null;
  guideVersion: number;
  status: GuideStatusEnum;
  stages: StageDetailsStage_guide_stages[];
  candidate: StageDetailsStage_guide_candidate;
  publicUrl: string;
  stageSyncStatus: StageSyncStatus;
  nextStage: StageDetailsStage_guide_nextStage | null;
  currentStage: StageDetailsStage_guide_currentStage | null;
}

export interface StageDetailsStage_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface StageDetailsStage_customHiringTeam {
  __typename: "Interviewer";
  id: string;
  name: string;
  email: string | null;
}

export interface StageDetailsStage_interviews_event_interviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  title: string;
  imageUrl: string;
}

export interface StageDetailsStage_interviews_event_interviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  hidden: boolean;
  personalProfile: StageDetailsStage_interviews_event_interviewParticipants_EventInterviewer_personalProfile;
}

export interface StageDetailsStage_interviews_event_interviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export type StageDetailsStage_interviews_event_interviewParticipants = StageDetailsStage_interviews_event_interviewParticipants_EventInterviewer | StageDetailsStage_interviews_event_interviewParticipants_Candidate;

export interface StageDetailsStage_interviews_event {
  __typename: "Event";
  id: string;
  hidden: boolean;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
  displayTitle: string;
  interviewParticipants: StageDetailsStage_interviews_event_interviewParticipants[];
  rawTitle: string;
  replacementData: GraphQL_JSON;
  conferenceUrl: string | null;
}

export interface StageDetailsStage_interviews_eventTemplate {
  __typename: "EventTemplate";
  id: string;
  atsEventTemplateName: string;
}

export interface StageDetailsStage_interviews_schedulerConfig_availability {
  __typename: "SchedulerAvailability";
  id: string;
  slug: string;
}

export interface StageDetailsStage_interviews_schedulerConfig {
  __typename: "EventTemplateInstallationSchedulerConfig";
  availability: StageDetailsStage_interviews_schedulerConfig_availability;
}

export interface StageDetailsStage_interviews_defaultInterviewParticipants_Candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
}

export interface StageDetailsStage_interviews_defaultInterviewParticipants_EventInterviewer_personalProfile {
  __typename: "Interviewer";
  id: string;
  name: string;
  imageUrl: string;
}

export interface StageDetailsStage_interviews_defaultInterviewParticipants_EventInterviewer {
  __typename: "EventInterviewer";
  id: string;
  personalProfile: StageDetailsStage_interviews_defaultInterviewParticipants_EventInterviewer_personalProfile;
}

export type StageDetailsStage_interviews_defaultInterviewParticipants = StageDetailsStage_interviews_defaultInterviewParticipants_Candidate | StageDetailsStage_interviews_defaultInterviewParticipants_EventInterviewer;

export interface StageDetailsStage_interviews {
  __typename: "EventTemplateInstallation";
  id: string;
  event: StageDetailsStage_interviews_event | null;
  title: string;
  description: string;
  hidden: boolean;
  replacementData: GraphQL_JSON;
  eventTemplate: StageDetailsStage_interviews_eventTemplate;
  schedulerConfig: StageDetailsStage_interviews_schedulerConfig | null;
  defaultInterviewParticipants: StageDetailsStage_interviews_defaultInterviewParticipants[];
}

export interface StageDetailsStage_emailActivities_sender {
  __typename: "User";
  id: string;
  imageUrl: string;
  fullName: string;
}

export interface StageDetailsStage_emailActivities {
  __typename: "EmailActivity";
  id: string;
  createdAt: GraphQL_DateTime;
  sentTo: string;
  sentFrom: string;
  subject: string;
  body: string;
  sender: StageDetailsStage_emailActivities_sender | null;
}

export interface StageDetailsStage {
  __typename: "Stage";
  id: string;
  configured: boolean;
  guide: StageDetailsStage_guide;
  hidden: boolean;
  status: StageStatus;
  stageTemplateInstallationId: string | null;
  position: number;
  title: string;
  isAtsCurrent: boolean;
  atsStage: StageDetailsStage_atsStage | null;
  customVariables: GraphQL_JSON;
  customHiringTeam: StageDetailsStage_customHiringTeam[];
  interviews: StageDetailsStage_interviews[];
  emailActivities: StageDetailsStage_emailActivities[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FetchGuideOverviewGuide
// ====================================================

export interface FetchGuideOverviewGuide_jobRoleGuideTemplate_atsJob {
  __typename: "ATSJobRole";
  id: string;
  name: string;
}

export interface FetchGuideOverviewGuide_jobRoleGuideTemplate {
  __typename: "JobRoleGuideTemplate";
  id: string;
  defaultGuideVersion: number;
  atsJob: FetchGuideOverviewGuide_jobRoleGuideTemplate_atsJob | null;
}

export interface FetchGuideOverviewGuide_candidate_organization_customer_atsIntegration {
  __typename: "AtsIntegration";
  id: string;
  displayName: string | null;
}

export interface FetchGuideOverviewGuide_candidate_organization_customer {
  __typename: "Customer";
  id: string;
  domains: string[];
  atsIntegration: FetchGuideOverviewGuide_candidate_organization_customer_atsIntegration | null;
}

export interface FetchGuideOverviewGuide_candidate_organization_theme {
  __typename: "Theme";
  primaryColor: string;
  secondaryColor: string | null;
}

export interface FetchGuideOverviewGuide_candidate_organization {
  __typename: "Organization";
  id: string;
  name: string;
  customer: FetchGuideOverviewGuide_candidate_organization_customer;
  defaultAvatarImageUrl: string;
  theme: FetchGuideOverviewGuide_candidate_organization_theme | null;
  guideDefaultBannerImageUrl: string;
}

export interface FetchGuideOverviewGuide_candidate {
  __typename: "Candidate";
  id: string;
  name: string;
  imageUrl: string;
  organization: FetchGuideOverviewGuide_candidate_organization;
  firstName: string;
  lastName: string;
}

export interface FetchGuideOverviewGuide_stages_atsStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
}

export interface FetchGuideOverviewGuide_stages_guide {
  __typename: "Guide";
  id: string;
  status: GuideStatusEnum;
}

export interface FetchGuideOverviewGuide_stages {
  __typename: "Stage";
  id: string;
  configured: boolean;
  title: string;
  status: StageStatus;
  isAtsCurrent: boolean;
  hidden: boolean;
  hasAutomation: boolean;
  position: number;
  atsStage: FetchGuideOverviewGuide_stages_atsStage | null;
  guide: FetchGuideOverviewGuide_stages_guide;
  stageTemplateInstallationId: string | null;
  description: string | null;
  needsFeedback: boolean;
}

export interface FetchGuideOverviewGuide_sortedVisibleStages {
  __typename: "Stage";
  id: string;
  hasAutomation: boolean;
  configured: boolean;
  title: string;
  status: StageStatus;
  position: number;
  hidden: boolean;
  description: string | null;
  needsFeedback: boolean;
}

export interface FetchGuideOverviewGuide_defaultContent {
  __typename: "GuideContent";
  data: GraphQL_JSON;
}

export interface FetchGuideOverviewGuide_nextStage {
  __typename: "Stage";
  id: string;
}

export interface FetchGuideOverviewGuide_currentStage {
  __typename: "Stage";
  id: string;
}

export interface FetchGuideOverviewGuide_emailActivities_sender {
  __typename: "User";
  id: string;
  imageUrl: string;
  fullName: string;
}

export interface FetchGuideOverviewGuide_emailActivities {
  __typename: "EmailActivity";
  id: string;
  createdAt: GraphQL_DateTime;
  sentTo: string;
  sentFrom: string;
  subject: string;
  body: string;
  sender: FetchGuideOverviewGuide_emailActivities_sender | null;
}

export interface FetchGuideOverviewGuide_hiringTeam_interviewer {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  imageUrl: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface FetchGuideOverviewGuide_hiringTeam {
  __typename: "HiringTeamMember";
  id: GraphQL_UUID;
  role: HiringTeamRoleEnum;
  isPointOfContact: boolean;
  hidden: boolean;
  interviewer: FetchGuideOverviewGuide_hiringTeam_interviewer;
}

export interface FetchGuideOverviewGuide_posts_senderUser {
  __typename: "GuidePostUser";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  isCandidate: boolean;
}

export interface FetchGuideOverviewGuide_posts_lastCandidateEmailEvent {
  __typename: "EmailEvent";
  type: string;
  happenedAt: GraphQL_DateTime;
  email: string;
}

export interface FetchGuideOverviewGuide_posts_replies_senderUser {
  __typename: "GuidePostUser";
  id: string;
  isCandidate: boolean;
}

export interface FetchGuideOverviewGuide_posts_replies {
  __typename: "GuidePost";
  id: string;
  readAt: GraphQL_DateTime | null;
  senderUser: FetchGuideOverviewGuide_posts_replies_senderUser;
}

export interface FetchGuideOverviewGuide_posts {
  __typename: "GuidePost";
  id: string;
  title: string;
  createdAt: GraphQL_DateTime;
  senderUser: FetchGuideOverviewGuide_posts_senderUser;
  isEmailTrackingEnabled: boolean;
  lastCandidateEmailEvent: FetchGuideOverviewGuide_posts_lastCandidateEmailEvent | null;
  replies: FetchGuideOverviewGuide_posts_replies[];
  readAt: GraphQL_DateTime | null;
}

export interface FetchGuideOverviewGuide_upcomingInterviewsV2_prominentInstructions {
  __typename: "ProminentInstruction";
  id: string;
  url: string;
  label: string;
}

export interface FetchGuideOverviewGuide_upcomingInterviewsV2_interviewers {
  __typename: "Interviewer";
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  title: string;
  biography: string;
  websiteUrls: string[];
  videoIntroductionUrl: string | null;
}

export interface FetchGuideOverviewGuide_upcomingInterviewsV2_location {
  __typename: "Location";
  id: string;
  name: string;
  address: string;
}

export interface FetchGuideOverviewGuide_upcomingInterviewsV2 {
  __typename: "Event";
  id: string;
  eventTemplateInstallationId: string | null;
  replacementData: GraphQL_JSON;
  title: string;
  description: string;
  startTime: GraphQL_DateTime;
  prominentInstructions: FetchGuideOverviewGuide_upcomingInterviewsV2_prominentInstructions[];
  hidden: boolean;
  endTime: GraphQL_DateTime;
  conferenceUrl: string | null;
  interviewers: FetchGuideOverviewGuide_upcomingInterviewsV2_interviewers[];
  conferencePhone: string | null;
  location: FetchGuideOverviewGuide_upcomingInterviewsV2_location | null;
}

export interface FetchGuideOverviewGuide {
  __typename: "Guide";
  id: string;
  guideVersion: number;
  startedAt: GraphQL_DateTime | null;
  candidateOpens: string[];
  jobRoleGuideTemplate: FetchGuideOverviewGuide_jobRoleGuideTemplate | null;
  candidate: FetchGuideOverviewGuide_candidate;
  stages: FetchGuideOverviewGuide_stages[];
  status: GuideStatusEnum;
  sortedVisibleStages: FetchGuideOverviewGuide_sortedVisibleStages[];
  roleName: string;
  bannerImageUrl: string | null;
  defaultContent: FetchGuideOverviewGuide_defaultContent | null;
  needsStageConfiguration: boolean;
  nextStage: FetchGuideOverviewGuide_nextStage | null;
  stageSyncStatus: StageSyncStatus;
  currentStage: FetchGuideOverviewGuide_currentStage | null;
  publicUrl: string;
  emailActivities: FetchGuideOverviewGuide_emailActivities[];
  invitedAt: GraphQL_DateTime | null;
  inviteOpenedAt: GraphQL_DateTime | null;
  hiringTeam: FetchGuideOverviewGuide_hiringTeam[];
  posts: FetchGuideOverviewGuide_posts[];
  upcomingInterviewsV2: FetchGuideOverviewGuide_upcomingInterviewsV2[];
  jobRoleGuideTemplateId: string | null;
  statusV2: GuideStatusV2Enum;
  hiringDecisionAt: GraphQL_DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DefaultInterviewsEventTemplate
// ====================================================

export interface DefaultInterviewsEventTemplate_atssyncGreenhouseJobStage {
  __typename: "ATSJobRoleStage";
  id: string;
  name: string;
  position: number | null;
}

export interface DefaultInterviewsEventTemplate_interviewTemplate {
  __typename: "InterviewTemplate";
  id: string;
  name: string;
  title: GraphQL_JSON | null;
  description: GraphQL_JSON | null;
}

export interface DefaultInterviewsEventTemplate {
  __typename: "EventTemplate";
  id: string;
  title: string;
  position: number;
  description: string;
  hidden: boolean;
  atsEventTemplateId: string;
  atsEventTemplateName: string;
  atssyncGreenhouseJobStage: DefaultInterviewsEventTemplate_atssyncGreenhouseJobStage | null;
  interviewTemplate: DefaultInterviewsEventTemplate_interviewTemplate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: logisticGroupFragmentPrisma
// ====================================================

export interface logisticGroupFragmentPrisma_options {
  __typename: "Logistic";
  id: string;
  emoji: string;
  title: string;
  description: string;
  type: string;
  logisticGroupId: GraphQL_UUID;
}

export interface logisticGroupFragmentPrisma {
  __typename: "LogisticGroup";
  id: string;
  name: string;
  type: string;
  options: logisticGroupFragmentPrisma_options[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AvailabilityPageFragmentLogisticGroup
// ====================================================

export interface AvailabilityPageFragmentLogisticGroup_options {
  __typename: "Logistic";
  id: string;
  emoji: string;
  title: string;
  description: string;
  type: string;
  logisticGroupId: GraphQL_UUID;
}

export interface AvailabilityPageFragmentLogisticGroup {
  __typename: "LogisticGroup";
  id: string;
  name: string;
  type: string;
  options: AvailabilityPageFragmentLogisticGroup_options[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UpdateOrganizationSettingsFragmentOrganization
// ====================================================

export interface UpdateOrganizationSettingsFragmentOrganization_domainJoinRole {
  __typename: "Role";
  id: string;
  name: string;
}

export interface UpdateOrganizationSettingsFragmentOrganization {
  __typename: "Organization";
  id: string;
  disableChatGlobally: boolean;
  enableNPS: boolean;
  enableICSFiles: boolean;
  domainJoinEnabled: boolean;
  domainJoinRole: UpdateOrganizationSettingsFragmentOrganization_domainJoinRole | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AnalyticsMetadataOrganization
// ====================================================

export interface AnalyticsMetadataOrganization_customer_plan {
  __typename: "CustomerPlan";
  id: string;
  name: string;
}

export interface AnalyticsMetadataOrganization_customer {
  __typename: "Customer";
  id: string;
  trialDuration: number | null;
  trialStart: GraphQL_DateTime | null;
  name: string;
  plan: AnalyticsMetadataOrganization_customer_plan;
}

export interface AnalyticsMetadataOrganization {
  __typename: "Organization";
  companyLogoUrl: string;
  id: string;
  name: string;
  createdAt: GraphQL_DateTime;
  zeusStatus: ZeusStatusEnum;
  planPricingModel: PlanPricingModelEnum | null;
  accountStatus: AccountStatusEnum | null;
  employeeCountEnum: CompanyEmployeeSizeEnum | null;
  talentTeamCountEnum: CompanyTalentTeamSizeEnum | null;
  contractStartDate: GraphQL_DateTime | null;
  customer: AnalyticsMetadataOrganization_customer;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountStatusEnum {
  ACTIVE = "ACTIVE",
  CHURNED = "CHURNED",
  LOST = "LOST",
  PAUSED = "PAUSED",
  PROSPECT = "PROSPECT",
}

export enum AtssyncAccountType {
  ASHBY = "ASHBY",
  GREENHOUSE = "GREENHOUSE",
  LEVER = "LEVER",
}

export enum CollectionFieldChangeStatusEnum {
  ADDED = "ADDED",
  REMOVED = "REMOVED",
  REORDERED = "REORDERED",
}

export enum CompanyEmployeeSizeEnum {
  ENTERPRISE5001PLUS = "ENTERPRISE5001PLUS",
  HUGE1001TO5000 = "HUGE1001TO5000",
  LARGE201TO500 = "LARGE201TO500",
  MEDIUM51TO100 = "MEDIUM51TO100",
  NONE0 = "NONE0",
  SMALL11TO50 = "SMALL11TO50",
  TINY1TO10 = "TINY1TO10",
  VERYLARGE501TO1000 = "VERYLARGE501TO1000",
}

export enum CompanyTalentTeamSizeEnum {
  ENTERPRISE51PLUS = "ENTERPRISE51PLUS",
  HUGE21TO50 = "HUGE21TO50",
  LARGE11TO20 = "LARGE11TO20",
  MEDIUM6TO10 = "MEDIUM6TO10",
  NONE0 = "NONE0",
  SMALL1TO5 = "SMALL1TO5",
}

export enum ConnectedAccountTypeEnum {
  GOOGLE = "GOOGLE",
  OFFICE_365 = "OFFICE_365",
}

export enum ContentTemplateStatusEnum {
  ACTIVE = "ACTIVE",
  ALL = "ALL",
  ARCHIVED = "ARCHIVED",
}

export enum EmailTemplateStatusEnum {
  ACTIVE = "ACTIVE",
  ALL = "ALL",
  ARCHIVED = "ARCHIVED",
}

export enum GuideCollectionPropagationChangesTypeEnum {
  Interviews = "Interviews",
  Stages = "Stages",
}

export enum GuidePropagationChangesEnum {
  bannerImgUrl = "bannerImgUrl",
  chatEnabled = "chatEnabled",
  defaultContentTemplateId = "defaultContentTemplateId",
  defaultEmailTemplateId = "defaultEmailTemplateId",
  guideContentId = "guideContentId",
  interviewTrackerEnabled = "interviewTrackerEnabled",
}

export enum GuideStatusEnum {
  DISABLED = "DISABLED",
  HIRED = "HIRED",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_STARTED = "NOT_STARTED",
  PAUSED = "PAUSED",
  REJECTED = "REJECTED",
}

export enum GuideStatusV2Enum {
  ACTIVE = "ACTIVE",
  HIRED = "HIRED",
  OFFER = "OFFER",
  REJECTED = "REJECTED",
}

export enum GuideTemplateStatusEnum {
  ACTIVE = "ACTIVE",
  ALL = "ALL",
  ARCHIVED = "ARCHIVED",
}

export enum HiringTeamRoleEnum {
  interviewer = "interviewer",
  recruiter = "recruiter",
}

export enum InboxMessageAvailabilityStatusEnum {
  AVAILABILITY_REQUESTED = "AVAILABILITY_REQUESTED",
  AVAILABILITY_SUBMITTED = "AVAILABILITY_SUBMITTED",
  AVAILABILITY_UPDATED = "AVAILABILITY_UPDATED",
  CONFIRMATION_SENT = "CONFIRMATION_SENT",
}

export enum InboxMessageStatusEnum {
  BOUNCED = "BOUNCED",
  REPLIED = "REPLIED",
  SENT = "SENT",
  VIEWED = "VIEWED",
}

export enum IntegrationEnum {
  GREENHOUSE = "GREENHOUSE",
  GREENHOUSE_MERGE = "GREENHOUSE_MERGE",
  LEVER = "LEVER",
  SLACK = "SLACK",
}

export enum InterviewTemplateStatusEnum {
  ACTIVE = "ACTIVE",
  ALL = "ALL",
  ARCHIVED = "ARCHIVED",
}

export enum JobRoleGuideTemplateSetupStatus {
  NOT_SETUP = "NOT_SETUP",
  SETUP_COMPLETE = "SETUP_COMPLETE",
  SETUP_IN_PROGRESS = "SETUP_IN_PROGRESS",
}

export enum JourneyStatusEnum {
  ALL = "ALL",
  SETUP_COMPLETE = "SETUP_COMPLETE",
  UNCONFIGURED = "UNCONFIGURED",
}

export enum OrderByArg {
  asc = "asc",
  desc = "desc",
}

export enum OrganizationGuidesAtsApplicationStatus {
  ACTIVE = "ACTIVE",
  HIRED = "HIRED",
  PROSPECT = "PROSPECT",
  REJECTED = "REJECTED",
}

export enum PersonaEnum {
  HEADOFTALENT = "HEADOFTALENT",
  HIRINGMANAGER = "HIRINGMANAGER",
  INTERVIEWER = "INTERVIEWER",
  RECRUITER = "RECRUITER",
  RECRUITINGCOORDINATOR = "RECRUITINGCOORDINATOR",
  RECRUITINGMANAGER = "RECRUITINGMANAGER",
  RECRUITINGOPERATIONS = "RECRUITINGOPERATIONS",
  SOURCER = "SOURCER",
  TALENTEXECUTIVE = "TALENTEXECUTIVE",
}

export enum PlanPricingModelEnum {
  DEMO = "DEMO",
  FREE = "FREE",
  PAID = "PAID",
}

export enum PostTemplateStatusEnum {
  ACTIVE = "ACTIVE",
  ALL = "ALL",
  ARCHIVED = "ARCHIVED",
}

export enum StagePropagationChangesEnum {
  contentTemplateId = "contentTemplateId",
  description = "description",
  emailTemplateId = "emailTemplateId",
  name = "name",
  postTemplateId = "postTemplateId",
}

export enum StageStatus {
  AVAILABLE = "AVAILABLE",
  COMPLETED = "COMPLETED",
  CURRENT = "CURRENT",
  DISABLED = "DISABLED",
  LOCKED = "LOCKED",
  NOT_STARTED = "NOT_STARTED",
}

export enum StageSyncStatus {
  AHEAD = "AHEAD",
  BEHIND = "BEHIND",
  SYNCED = "SYNCED",
  UNSET = "UNSET",
}

export enum StageTemplateStatusEnum {
  ACTIVE = "ACTIVE",
  ALL = "ALL",
  ARCHIVED = "ARCHIVED",
}

export enum TeammateTypeFilterEnum {
  ADMIN = "ADMIN",
  ALL = "ALL",
  ALL_ACTIVE = "ALL_ACTIVE",
  EXTERNAL = "EXTERNAL",
  INACTIVE = "INACTIVE",
  INTERVIEWER = "INTERVIEWER",
  INVITED = "INVITED",
  MANAGER = "MANAGER",
  MEMBER = "MEMBER",
  REMOVED = "REMOVED",
  UNINVITED = "UNINVITED",
}

export enum ZeusStatusEnum {
  LEGACY = "LEGACY",
  ZEUS = "ZEUS",
  ZEUS_TRANSITION = "ZEUS_TRANSITION",
}

export interface ActivateStageTemplateInstallationInput {
  stageTemplateInstallationId: GraphQL_UUID;
}

export interface AddPostTemplateToJobRoleGuideTemplateStageInput {
  jobRoleGuideTemplateStageId: string;
  postTemplateId: string;
}

export interface ApplyInterviewTemplateToEventInput {
  eventId: string;
  interviewTemplateId: string;
}

export interface ApplyInterviewTemplateToEventTemplateInstallationInput {
  eventTemplateInstallationId: string;
  interviewTemplateId: string;
}

export interface ArchiveContentTemplateInput {
  contentTemplateId: string;
}

export interface ArchiveEmailTemplateInput {
  emailTemplateId: GraphQL_UUID;
}

export interface ArchiveGuideTemplateInput {
  id: string;
}

export interface ArchiveInterviewTemplateInput {
  interviewTemplateId: GraphQL_UUID;
}

export interface ArchiveLocationMutationInput {
  locationId: GraphQL_UUID;
}

export interface ArchivePostTemplateInput {
  id: string;
}

export interface AssignInterviewTemplateToJobInterviewsInput {
  interviewTemplateId: GraphQL_UUID;
  jobInterviewIds: GraphQL_UUID[];
}

export interface AtsApplicationOrderByArg {
  lastActivityAt?: OrderByArg | null;
}

export interface AtsStageWithJobRoleGuideTemplateIdsInput {
  jobRoleGuideTemplateIds: string[];
  search?: string | null;
}

export interface Attachment {
  filename: string;
  id: string;
}

export interface BulkRequestProfileUpdateInput {
  ids: GraphQL_UUID[];
}

export interface BulkUpdateEventTemplatesInput {
  atsEventTemplateIds: string[];
  description?: string | null;
  hidden?: boolean | null;
  title?: string | null;
}

export interface BulkUpdateStageTemplateInstallationsInput {
  activatedAt?: GraphQL_DateTime | null;
  atsStageIds: string[];
  contentTemplateId?: string | null;
  displayName?: string | null;
  emailActionBCC?: string | null;
  emailActionCC?: string | null;
  emailTemplateId?: string | null;
  jobRoleGuideTemplateIds: string[];
}

export interface CalendarEventMutationInput {
  allDay: boolean;
  end: GraphQL_DateTime;
  id?: string | null;
  start: GraphQL_DateTime;
  title: string;
}

export interface CandidateOrderByArg {
  firstName?: OrderByArg | null;
}

export interface ConfigureStageTemplateInstallationInputPrismaProxy {
  atsJobStageRemoteId: string;
  emailTemplateId?: GraphQL_UUID | null;
  guideTemplateId: GraphQL_UUID;
  stageTemplateId: GraphQL_UUID;
}

export interface ConsumeMagicLinkInput {
  token: string;
}

export interface CopySettingsToJobInput {
  fromJobRoleGuideTemplateId: string;
  jobSetupStatus?: JobRoleGuideTemplateSetupStatus | null;
  toJobRoleGuideTemplateId: string;
}

export interface CreateAvailabilityPageTemplateModuleInput {
  availabilityPageTemplateId: string;
  position: number;
  stageModuleId: string;
  type: string;
}

export interface CreateContentTemplateInput {
  copyFromContentTemplateId?: GraphQL_UUID | null;
  name: string;
}

export interface CreateContentTemplateModuleInstallationInput {
  contentTemplateId: string;
  position: number;
  stageModuleType: string;
}

export interface CreateCustomLogisticMutationInput {
  description: string;
  emoji: string;
  organizationId: GraphQL_UUID;
  title: string;
}

export interface CreateEmailTemplateInput {
  body: string;
  name: string;
  subject: string;
}

export interface CreateGuidePostInput {
  candidateTimezone?: string | null;
  content?: GraphQL_JSON | null;
  guideId: string;
  hiringDecision?: GuideStatusV2Enum | null;
  notify?: boolean | null;
  recipients?: GuidePostRecipientId[] | null;
  senderId?: string | null;
  stageId?: string | null;
  threadId?: string | null;
  title?: string | null;
  to?: string | null;
}

export interface CreateGuideTemplateInput {
  atssyncDepartmentId?: string | null;
  isDepartmentDefault?: boolean | null;
  name: string;
}

export interface CreateGuideTemplateStageInput {
  guideTemplateId: string;
  name: string;
}

export interface CreateInterviewTemplateInput {
  departmentId?: string | null;
  description?: GraphQL_JSON | null;
  name: string;
  title?: GraphQL_JSON | null;
}

export interface CreateJobRoleGuideTemplateStageInput {
  atsJobStageId?: string | null;
  description?: string | null;
  jobRoleGuideTemplateId: string;
  name: string;
}

export interface CreateLocationMutationInput {
  address: string;
  arrivalInstructions: string;
  coordinates?: LocationCoordinatesInput | null;
  name: string;
}

export interface CreatePitchPageTemplateModuleInstallationInput {
  data: GraphQL_JSON;
  pitchPageTemplateId: GraphQL_UUID;
  position: number;
  stageModuleId: GraphQL_UUID;
  type: string;
}

export interface CreateValueInput {
  description: string;
  title: string;
}

export interface CreateValueMutationInput {
  description: string;
  organizationId: GraphQL_UUID;
  title: string;
}

export interface DeactivateStageTemplateInstallationInput {
  id: string;
}

export interface DeleteAvailabilityInputPrismaProxy {
  id: GraphQL_UUID;
}

export interface DeleteCustomLogisticMutationInput {
  logisticGroupId: GraphQL_UUID;
}

export interface DeleteGuidePostInput {
  guidePostId: string;
}

export interface DeleteMergeAccountInputPrismaProxy {
  accountId: string;
}

export interface DeletePitchPageTemplateModuleInstallationInput {
  id: GraphQL_UUID;
}

export interface DeleteValueMutationInput {
  valueId: GraphQL_UUID;
}

export interface DetachGuideTemplateInput {
  jobRoleGuideTemplateId: string;
}

export interface DisableGuideInput {
  guideId: GraphQL_UUID;
}

export interface DisconnectAtssyncAccountInput {
  id: string;
}

export interface DuplicateGuideTemplateInput {
  departmentId?: string | null;
  guideTemplateId: GraphQL_UUID;
  isDepartmentDefault?: boolean | null;
  name: string;
  toOrganization?: string | null;
}

export interface EditInterviewTemplateInput {
  description?: GraphQL_JSON | null;
  interviewTemplateId: GraphQL_UUID;
  title?: GraphQL_JSON | null;
}

export interface EmailTemplateOrderByInput {
  createdAt?: OrderByArg | null;
  name?: OrderByArg | null;
}

export interface EnableGuideInput {
  guideId: GraphQL_UUID;
}

export interface EventTemplateWhereInput {
  search?: string | null;
}

export interface FeedbackForOrganizationInputPrismaProxy {
  departmentIds?: GraphQL_UUID[] | null;
  jobRoleId?: GraphQL_UUID | null;
  jobRoleIds?: GraphQL_UUID[] | null;
  stageNames?: string[] | null;
  startTimeRange?: string | null;
}

export interface FixZeusGuideInput {
  guideId: string;
}

export interface ForcePropagateGuideTemplateToGuidesInput {
  guideTemplateId: string;
}

export interface ForcePropagateJobRoleGuideTemplateToGuidesInput {
  jobRoleGuideTemplateId: string;
}

export interface GenerateCandidateFeedbackExportInputPrismaProxy {
  departmentIds?: GraphQL_UUID[] | null;
  format: string;
  jobRoleIds?: GraphQL_UUID[] | null;
  stageNames?: string[] | null;
  startTimeRange?: string | null;
}

export interface GenerateMergeLinkTokenInputPrismaProxy {
  integration: string;
}

export interface GeneratedGuidePostHtmlInput {
  content: GraphQL_JSON;
  guideId: string;
}

export interface GetCalendarsV2WhereInput {
  search?: string | null;
}

export interface GreenhouseCandidateWhereUniqueInput {
  applicationId: string;
  candidateId: string;
}

export interface GuideApplicationCandidateWhereUniqueInput {
  applicationId: string;
  candidateId?: string | null;
}

export interface GuideLookupWhereInput {
  greenhouseApplicationId?: string | null;
  id?: string | null;
}

export interface GuideMergeCandidateApplicationWhereUniqueInput {
  applicationRemoteId: string;
  atsType: string;
}

export interface GuidePostRecipientId {
  interviewerId?: string | null;
  isCandidate: boolean;
}

export interface GuideStageForUpdate {
  description?: string | null;
  id: string;
  title: string;
}

export interface GuideTemplateStageForUpdate {
  description?: string | null;
  id: string;
  postTemplateIds: GraphQL_UUID[];
  title: string;
}

export interface GuideTemplateStageInput {
  atsStageNames: string[];
  id: string;
}

export interface GuideWhereUniqueInput {
  id: GraphQL_UUID;
}

export interface HideEventInput {
  eventId: GraphQL_UUID;
}

export interface HideEventInterviewerInput {
  eventInterviewerId: GraphQL_UUID;
}

export interface HideEventTemplateInstallationInput {
  eventTemplateInstallationId: GraphQL_UUID;
}

export interface HideJobRoleGuideTemplateStageInput {
  id: string;
}

export interface HideStageInput {
  stageId: GraphQL_UUID;
}

export interface IdFilter {
  _in?: GraphQL_UUID[] | null;
  notIn?: GraphQL_UUID[] | null;
}

export interface InsertGreenhouseStageTriggerInputPrismaProxy {
  atsJobStageId: GraphQL_UUID;
  emailTemplateId?: GraphQL_UUID | null;
  guideTemplateId: GraphQL_UUID;
  stageTemplateId: GraphQL_UUID;
}

export interface InterviewPlanItemInput {
  description?: string | null;
  id?: string | null;
  title: string;
}

export interface InterviewPlanItemWithPostTemplatesInput {
  description?: string | null;
  id?: string | null;
  postTemplateIds: GraphQL_UUID[];
  title: string;
}

export interface InterviewTemplateInstallationSimpleViewOrderByInput {
  jobName?: OrderByArg | null;
  name?: OrderByArg | null;
  stageName?: OrderByArg | null;
}

export interface JobNameOrderByArg {
  name?: OrderByArg | null;
}

export interface JobRoleGuideTemplateStageForUpdate {
  description?: string | null;
  id: string;
  postTemplateIds: GraphQL_UUID[];
  title: string;
}

export interface JobRoleWhereInput {
  id?: IdFilter | null;
  isArchived?: boolean | null;
}

export interface LinkInput {
  imageUrl?: string | null;
  label: string;
  url: string;
}

export interface LocationByIdInput {
  id: GraphQL_UUID;
}

export interface LocationCoordinatesInput {
  latitude: number;
  longitude: number;
}

export interface MapGuideTemplateInput {
  guideTemplateId: string;
  guideTemplateStages: GuideTemplateStageInput[];
  jobRoleGuideTemplateIds: string[];
  jobSetupStatus?: JobRoleGuideTemplateSetupStatus | null;
}

export interface MarkGuidePostAsReadInput {
  guidePostId: string;
}

export interface MergeApplicationRawInput {
  applicationRemoteId: string;
  atsType: string;
}

export interface MetabaseDashboardInput {
  dashboardId: number;
}

export interface MoveCandidateToStageInput {
  guideId: string;
  stageId: string;
}

export interface MoveCandidateToStageWithConfirmationInput {
  body: string;
  fullAttachments: Attachment[];
  guideId: string;
  sendFromAddress: string;
  sendToAddress: string;
  sendToBcc?: string[] | null;
  sendToCc?: string[] | null;
  stageId: string;
  subject: string;
}

export interface MoveGuideToStageInput {
  guideId: string;
  interviewPlanItemId: string;
}

export interface OpenJobOrderByInput {
  configuredStageCount?: OrderByArg | null;
  name?: OrderByArg | null;
  sentGuideCount?: OrderByArg | null;
}

export interface OrganizationContentTemplatesOrderByInput {
  createdAt?: OrderByArg | null;
  name?: OrderByArg | null;
}

export interface OrganizationGuideTemplatesOrderByInput {
  department?: OrderByArg | null;
  name?: OrderByArg | null;
  updatedAt?: OrderByArg | null;
  usage?: OrderByArg | null;
}

export interface OrganizationGuidesOrderByInput {
  atsApplication?: AtsApplicationOrderByArg | null;
  candidate?: CandidateOrderByArg | null;
  denormLastEmailActivityAt?: OrderByArg | null;
  jobRole?: JobNameOrderByArg | null;
  startedAt?: OrderByArg | null;
}

export interface OrganizationInterviewTemplateOrderByInput {
  lastEditedAt?: OrderByArg | null;
  name?: OrderByArg | null;
  usage?: OrderByArg | null;
}

export interface OrganizationInterviewTemplateWhereInput {
  department?: GraphQL_UUID[] | null;
  search?: string | null;
  status?: InterviewTemplateStatusEnum | null;
}

export interface OrganizationJobRoleGuideTemplateOrderByInput {
  name?: OrderByArg | null;
}

export interface OrganizationJobRoleGuideTemplateWhereInput {
  atsDepartmentIds?: string[] | null;
  atsOfficeIds?: string[] | null;
  atsUserIds?: string[] | null;
  eventTemplateSearch?: string | null;
  guideTemplateId?: string | null;
  id?: IdFilter | null;
  isArchived?: boolean | null;
  search?: string | null;
  status?: JourneyStatusEnum | null;
}

export interface OrganizationPostTemplatesOrderByInput {
  name?: OrderByArg | null;
  updatedAt?: OrderByArg | null;
}

export interface OrganizationPostTemplatesWhereInput {
  createdByMembershipId?: GraphQL_UUID | null;
  departmentIds?: GraphQL_UUID[] | null;
  includeAll?: boolean | null;
  includeGlobalOwner?: boolean | null;
  postTemplateIds?: string[] | null;
  search?: string | null;
  status?: PostTemplateStatusEnum | null;
  type?: string | null;
}

export interface RemoveAvailabilityPageTemplateModuleInput {
  availabilityPageTemplateModuleId: string;
}

export interface RemoveContentTemplateModuleInstallationInput {
  stageTemplateModuleInstallationId: string;
}

export interface RemoveGuideTemplateStageContentTemplateInput {
  id: string;
}

export interface RemoveGuideTemplateStageEmailTemplateInput {
  id: string;
}

export interface RemoveGuideTemplateStageInput {
  id: string;
}

export interface RemoveGuideTemplateStagePostTemplateInput {
  id: string;
}

export interface RemoveJobRoleGuideTemplateStageContentTemplateInput {
  id: string;
}

export interface RemoveJobRoleGuideTemplateStageEmailTemplateInput {
  id: string;
}

export interface RemoveJobRoleGuideTemplateStageInput {
  id: string;
}

export interface RemoveJobRoleGuideTemplateStagePostTemplateInput {
  id: string;
}

export interface RemoveStageTemplateInstallationEmailTemplateInput {
  id: string;
}

export interface RemoveUserMembershipInput {
  userMembershipId: GraphQL_UUID;
}

export interface ReorderContentTemplateModuleInstallationsInput {
  contentTemplateId: string;
  modules: string[];
}

export interface ReorderGuideTemplateStageInput {
  guideTemplateId: string;
  ids: string[];
}

export interface ReorderJobRoleGuideTemplateStageInput {
  ids: string[];
}

export interface RequestProfileUpdateInput {
  id: GraphQL_UUID;
}

export interface RestoreEventTemplateInstallationDetailsMutationInput {
  eventTemplateInstallationId: string;
}

export interface RestoreGlobalJobRoleGuideTemplateChangesInput {
  jobRoleGuideTemplateId: string;
}

export interface RestoreJobRoleGuideTemplateChangesInput {
  changes: GuidePropagationChangesEnum[];
  jobRoleGuideTemplateId: string;
}

export interface RestoreJobRoleGuideTemplateStageChangesInput {
  changes: StagePropagationChangesEnum[];
  jobRoleGuideTemplateStageId: string;
}

export interface RestoreUserMembershipInput {
  userMembershipId: GraphQL_UUID;
}

export interface RevokeUserInvitationsInput {
  invitationIds: GraphQL_UUID[];
}

export interface SchedulerAvailabilityWhereUniqueInput {
  id?: GraphQL_UUID | null;
  slug?: string | null;
}

export interface SendCandidateConfirmationInput {
  body: string;
  fullAttachments: Attachment[];
  guideId: string;
  sendFromAddress: string;
  sendToAddress: string;
  sendToBcc?: string[] | null;
  sendToCc?: string[] | null;
  stageId?: string | null;
  subject: string;
}

export interface SendGuidePostTestEmailInput {
  content?: GraphQL_JSON | null;
  guideId: string;
  senderId: string;
  stageId?: string | null;
  threadId?: string | null;
  title?: string | null;
  to: string;
}

export interface SendPremiumAddonRequestEmailInput {
  addonType: string;
}

export interface SendTestConfirmationInput {
  body: string;
  guideId: string;
  sendFromAddress: string;
  stageId?: string | null;
  subject: string;
}

export interface SendUserInvitationsByInterviewerIdInput {
  ids: GraphQL_UUID[];
  roleId: string;
}

export interface SendUserInvitationsInput {
  emailAddresses: string[];
  roleId: string;
}

export interface SendViaSendWithUsInput {
  ccEmails: string;
  html: string;
  plainText: string;
  preheader: string;
  recipientEmail: string;
  recipientName: string;
  senderEmail: string;
  senderName: string;
  senderReplyTo: string;
  subject: string;
}

export interface SetGuidePostRecipientsInput {
  id: string;
  recipients: GuidePostRecipientId[];
}

export interface SetOrganizationValuesInput {
  values: CreateValueInput[];
}

export interface SetPointOfContactInput {
  interviewerId: string;
  jobRoleGuideTemplateId: string;
}

export interface SetPrimaryEmailOfCandidateInput {
  id: string;
  primaryEmail: string;
}

export interface SetPrimaryPhoneOfCandidateInput {
  id: string;
  primaryPhone: string;
}

export interface SetTimezoneOfCandidateInput {
  id: string;
  timezone: string;
}

export interface SetUpStreamForGuideInput {
  guideId: GraphQL_UUID;
}

export interface ShowEventInput {
  eventId: GraphQL_UUID;
}

export interface ShowEventInterviewerInput {
  eventInterviewerId: GraphQL_UUID;
}

export interface ShowEventTemplateInstallationInput {
  eventTemplateInstallationId: GraphQL_UUID;
}

export interface ShowJobRoleGuideTemplateStageInput {
  id: string;
}

export interface ShowStageInput {
  stageId: GraphQL_UUID;
}

export interface StartGuideInput {
  guideId: GraphQL_UUID;
}

export interface StoreMergeAccountTokenInputPrismaProxy {
  accountId: string;
  publicToken: string;
}

export interface SubmitCandidateFeedbackForLastInterviewInput {
  data: GraphQL_JSON;
  guideId: GraphQL_UUID;
  lastInterviewId: GraphQL_UUID;
  stageId?: GraphQL_UUID | null;
}

export interface SubmitCandidateFeedbackForStageInput {
  data: GraphQL_JSON;
  stageId: GraphQL_UUID;
}

export interface SyncCandidateApplicationInterviewsInputPrismaProxy {
  applicationId: string;
}

export interface SyncGuideTemplateInputPrismaProxy {
  guideTemplateId: GraphQL_UUID;
}

export interface TeammateOrderByInput {
  joinedAt?: OrderByArg | null;
  lastUserUpdatedAt?: OrderByArg | null;
  name?: OrderByArg | null;
  role?: OrderByArg | null;
}

export interface UnarchiveContentTemplateInput {
  contentTemplateId: string;
}

export interface UnarchiveEmailTemplateInput {
  emailTemplateId: GraphQL_UUID;
}

export interface UnarchiveGuideTemplateInput {
  id: string;
}

export interface UnarchiveInterviewTemplateInput {
  interviewTemplateId: GraphQL_UUID;
}

export interface UnarchiveLocationMutationInput {
  locationId: GraphQL_UUID;
}

export interface UnarchivePostTemplateInput {
  id: string;
}

export interface UpdateAssessmentStageTemplateModuleInstallationInput {
  defaultAssessmentLabel?: string | null;
  defaultAssessmentUrl?: string | null;
  descriptionHtml: string;
  header: string;
  stageTemplateModuleInstallationId: GraphQL_UUID;
}

export interface UpdateAvailabilityInputPrismaProxy {
  id: GraphQL_UUID;
  name: string;
}

export interface UpdateAvailabilityRequestInput {
  allowSelectionOutsideSuggestions: boolean;
  calendarEvents: CalendarEventMutationInput[];
  calendars: string[];
  createMultipleEventsOnDrag: boolean;
  description?: string | null;
  duration: number;
  forceResponsesInIncrements: boolean;
  id: string;
  overlayCalendars: boolean;
  timezone?: string | null;
  title: string;
}

export interface UpdateCandidateTimezoneInput {
  candidateId: GraphQL_UUID;
  timezone: string;
}

export interface UpdateContentTemplateNameInput {
  id: string;
  name: string;
}

export interface UpdateCustomLogisticMutationInput {
  description: string;
  emoji: string;
  logisticId: GraphQL_UUID;
  title: string;
}

export interface UpdateCustomerGreenhouseInput {
  apiKey?: string | null;
  boardToken?: string | null;
  maildropAddress?: string | null;
  onBehalfOfUserId?: GraphQL_UUID | null;
  subdomain?: string | null;
}

export interface UpdateEmailTemplateInput {
  body?: string | null;
  id: string;
  name?: string | null;
  subject?: string | null;
}

export interface UpdateEventConferencePhoneInput {
  conferencePhone: string;
  eventId: string;
}

export interface UpdateEventConferenceUrlInput {
  conferenceUrl: string;
  eventId: string;
}

export interface UpdateEventDescriptionInput {
  description: GraphQL_JSON[];
  eventId: string;
}

export interface UpdateEventInput {
  description: string;
  id: GraphQL_UUID;
  prominentInstructions: string[];
  title: string;
}

export interface UpdateEventLocationInput {
  eventId: string;
  locationId: string;
}

export interface UpdateEventProminentInstructionsInput {
  eventId: GraphQL_UUID;
  prominentInstructions: string[];
}

export interface UpdateEventTemplateInput {
  description: string;
  hidden: boolean;
  id: GraphQL_UUID;
  interviewTemplateId: GraphQL_UUID;
  title: string;
}

export interface UpdateEventTemplateInstallationDescriptionInput {
  description: GraphQL_JSON[];
  eventTemplateInstallationId: string;
}

export interface UpdateEventTemplateInstallationTitleInput {
  eventTemplateInstallationId: string;
  title: GraphQL_JSON[];
}

export interface UpdateEventTitleInput {
  eventId: string;
  title: GraphQL_JSON[];
}

export interface UpdateGuideBannerInput {
  bannerUrl: string;
  guideId: string;
}

export interface UpdateGuideOverviewContentInput {
  content: GraphQL_JSON;
  guideId: string;
}

export interface UpdateGuidePlanInput {
  guideId: string;
  saveChangesToParent: boolean;
  stages: GuideStageForUpdate[];
}

export interface UpdateGuidePostContentInput {
  data: GraphQL_JSON;
  id: string;
}

export interface UpdateGuideTemplateBannerInput {
  bannerUrl: string;
  guideTemplateId: string;
}

export interface UpdateGuideTemplateInput {
  atssyncDepartmentId?: string | null;
  chatEnabled?: boolean | null;
  id: GraphQL_UUID;
  interviewProcessEnabled?: boolean | null;
  isDepartmentDefault?: boolean | null;
  name?: string | null;
}

export interface UpdateGuideTemplateInterviewPlanInput {
  id: GraphQL_UUID;
  stages: GuideTemplateStageForUpdate[];
}

export interface UpdateGuideTemplateOverviewContentInput {
  content: GraphQL_JSON;
  guideTemplateId: string;
}

export interface UpdateGuideTemplateStageInput {
  contentTemplateId?: string | null;
  description?: string | null;
  emailTemplateId?: string | null;
  id: string;
  postTemplateId?: string | null;
  title?: string | null;
}

export interface UpdateInterviewSchedulerInput {
  data: GraphQL_JSON;
  id: string;
  schedulerAvailabilityId: string;
}

export interface UpdateInterviewTeamStageTemplateModuleInstallationInput {
  header: string;
  manualSelection?: boolean | null;
  stageTemplateModuleInstallationId: GraphQL_UUID;
}

export interface UpdateInterviewTemplateInput {
  departmentId?: string | null;
  interviewTemplateId: GraphQL_UUID;
  name: string;
}

export interface UpdateInterviewerBioInput {
  biography: string;
  interviewerId: GraphQL_UUID;
}

export interface UpdateInterviewerImageUrlInput {
  imageUrl?: string | null;
  interviewerId: GraphQL_UUID;
}

export interface UpdateInterviewerNameInput {
  interviewerId: GraphQL_UUID;
  name: string;
}

export interface UpdateInterviewerTitleInput {
  interviewerId: GraphQL_UUID;
  title: string;
}

export interface UpdateInterviewerUrlsInput {
  interviewerId: GraphQL_UUID;
  websiteUrls: string[];
}

export interface UpdateInvitationRoleInput {
  roleId: string;
  userInvitationId: GraphQL_UUID;
}

export interface UpdateJobRoleGuideTemplateBannerInput {
  bannerUrl: string;
  jobRoleGuideTemplateId: string;
}

export interface UpdateJobRoleGuideTemplateInput {
  chatEnabled?: boolean | null;
  defaultGuideVersion?: number | null;
  id: GraphQL_UUID;
  interviewProcessEnabled?: boolean | null;
  jobRoleNameOverride?: string | null;
  linkToJobDescription?: string | null;
  setupStatus?: JobRoleGuideTemplateSetupStatus | null;
}

export interface UpdateJobRoleGuideTemplateInterviewPlanInput {
  id: GraphQL_UUID;
  stages: JobRoleGuideTemplateStageForUpdate[];
}

export interface UpdateJobRoleGuideTemplateOverviewContentInput {
  content: GraphQL_JSON;
  jobRoleGuideTemplateId: string;
}

export interface UpdateJobRoleGuideTemplateStageContentTemplateInput {
  contentTemplateId?: string | null;
  jobRoleGuideTemplateStageId: string;
}

export interface UpdateJobRoleGuideTemplateStageEmailTemplateInput {
  emailTemplateId?: string | null;
  jobRoleGuideTemplateStageId: string;
}

export interface UpdateJobRoleGuideTemplateStageInput {
  atsJobStageId?: string | null;
  description?: string | null;
  id: string;
  name?: string | null;
}

export interface UpdateJobRoleGuideTemplateStagePostTemplateInput {
  jobRoleGuideTemplateStageId: string;
  postTemplateId?: string | null;
}

export interface UpdateJobRoleGuideTemplateStageRecipientsInput {
  emailActionBCC: string[];
  emailActionCC: string[];
  id: string;
}

export interface UpdateLegacyGuideStatusInput {
  guideId: string;
  status: GuideStatusV2Enum;
}

export interface UpdateLinksStageTemplateModuleInstallationInput {
  header: string;
  links: LinkInput[];
  stageTemplateModuleInstallationId: GraphQL_UUID;
}

export interface UpdateLocationMutationInput {
  address?: string | null;
  arrivalInstructions?: string | null;
  coordinates?: LocationCoordinatesInput | null;
  locationId: GraphQL_UUID;
  name?: string | null;
}

export interface UpdateLocationStageTemplateModuleInstallationInput {
  header: string;
  locationId: string;
  stageTemplateModuleInstallationId: GraphQL_UUID;
}

export interface UpdateLogisticsStageTemplateModuleInstallationInput {
  header: string;
  logisticIds: string[];
  stageTemplateModuleInstallationId: GraphQL_UUID;
}

export interface UpdateMediaStageTemplateModuleInstallationInput {
  header: string;
  mediaUrl: string;
  stageTemplateModuleInstallationId: GraphQL_UUID;
}

export interface UpdateMissionStageTemplateModuleInstallationInput {
  header: string;
  stageTemplateModuleInstallationId: GraphQL_UUID;
}

export interface UpdateOfferStageTemplateModuleInstallationInput {
  descriptionHtml: string;
  header: string;
  stageTemplateModuleInstallationId: GraphQL_UUID;
}

export interface UpdateOrganizationBrandingInput {
  companyLogoUrl: string;
  missionStatement: string;
  name: string;
  theme: UpdateOrganizationThemeInput;
}

export interface UpdateOrganizationDefaultGuideContentTemplateInput {
  data: GraphQL_JSON;
}

export interface UpdateOrganizationInterviewerImageInput {
  defaultAvatarImageUrl: string;
}

export interface UpdateOrganizationLogoInput {
  companyLogoUrl: string;
}

export interface UpdateOrganizationMissionStatementInput {
  missionStatement: string;
}

export interface UpdateOrganizationShowTrimInput {
  showTrim: boolean;
}

export interface UpdateOrganizationThemeInput {
  primaryColor?: string | null;
  secondaryColor?: string | null;
}

export interface UpdatePersonalProfileImageUrlInput {
  imageUrl?: string | null;
  personalProfileId: GraphQL_UUID;
}

export interface UpdatePersonalProfileInput {
  biography: string;
  imageUrl?: string | null;
  name: string;
  personalProfileId: GraphQL_UUID;
  pronoun?: string | null;
  title: string;
  videoIntroductionUrl?: string | null;
  websiteUrls: string[];
}

export interface UpdatePersonalProfilePronounsInput {
  personalProfileId: GraphQL_UUID;
  pronouns?: string | null;
}

export interface UpdatePersonalProfileVideoIntroductionUrlInput {
  personalProfileId: GraphQL_UUID;
  videoIntroductionUrl?: string | null;
}

export interface UpdatePitchPageTemplateInput {
  id: GraphQL_UUID;
  name?: string | null;
}

export interface UpdatePitchPageTemplateModuleInstallationDataInput {
  data: GraphQL_JSON;
  id: string;
}

export interface UpdateRichTextStageTemplateModuleInstallationInput {
  header: string;
  rawHtml: string;
  stageTemplateModuleInstallationId: GraphQL_UUID;
}

export interface UpdateScheduleStageTemplateModuleInstallationInput {
  header: string;
  stageTemplateModuleInstallationId: GraphQL_UUID;
}

export interface UpdateSchedulerAvailabilityPageTemplateModuleInstallationDataInput {
  data: GraphQL_JSON;
  id: string;
}

export interface UpdateSlackInput {
  auth0SlackUserId?: string | null;
  autopopulateInterviewer?: boolean | null;
  receiveNotifications?: boolean | null;
}

export interface UpdateStageCustomHiringTeamInput {
  customHiringTeamIds: GraphQL_UUID[];
  stageId: GraphQL_UUID;
}

export interface UpdateStageCustomVariablesInput {
  customVariables: GraphQL_JSON;
  stageId: GraphQL_UUID;
}

export interface UpdateStageTemplateInstallationContentTemplateIdInput {
  contentTemplateId: string;
  id: string;
}

export interface UpdateStageTemplateInstallationDescriptionInput {
  description?: string | null;
  id: string;
}

export interface UpdateStageTemplateInstallationDisplayNameInput {
  displayName: string;
  id: string;
}

export interface UpdateStageTemplateInstallationEmailActionMetadataInput {
  emailActionBCC?: string | null;
  emailActionCC?: string | null;
  id: string;
}

export interface UpdateStageTemplateInstallationEmailTemplateIdInput {
  emailTemplateId: string;
  id: string;
}

export interface UpdateTeamPhotoStageTemplateModuleInstallationInput {
  header?: string | null;
  photoUrl: string;
  stageTemplateModuleInstallationId: GraphQL_UUID;
}

export interface UpdateTimeStageTemplateModuleInstallationInput {
  header: string;
  stageTemplateModuleInstallationId: GraphQL_UUID;
}

export interface UpdateUserMembershipRoleInput {
  roleId: string;
  userMembershipId: GraphQL_UUID;
}

export interface UpdateValueMutationInput {
  description: string;
  title: string;
  valueId: GraphQL_UUID;
}

export interface UpdateValuesStageTemplateModuleInstallationInput {
  header: string;
  stageTemplateModuleInstallationId: GraphQL_UUID;
}

export interface UpsertAtssyncAccountInput {
  apiKey: string;
  type: AtssyncAccountType;
}

export interface UpsertGuideInterviewPlanInput {
  guideId: string;
  items: InterviewPlanItemInput[];
}

export interface UpsertGuideTemplateInterviewPlanInput {
  guideTemplateId: string;
  items: InterviewPlanItemWithPostTemplatesInput[];
}

export interface UpsertInterviewSchedulerConfigInput {
  availabilityId?: string | null;
  eventTemplateId?: string | null;
  schedulerInterviewersData: UpsertInterviewSchedulerConfigInterviewerInput[];
  stageTemplateInstallationId: string;
}

export interface UpsertInterviewSchedulerConfigInterviewerInput {
  interviewerId: string;
  schedulerConfigId?: string | null;
}

export interface UpsertJobRoleGuideTemplateInterviewPlanInput {
  items: InterviewPlanItemWithPostTemplatesInput[];
  jobRoleGuideTemplateId: string;
}

export interface UpsertJobRoleGuideTemplateStageInterviewSchedulerConfigInput {
  availabilityId?: string | null;
  eventTemplateId?: string | null;
  jobRoleGuideTemplateStageId: string;
  schedulerInterviewersData: UpsertJobRoleGuideTemplateStageInterviewSchedulerConfigInterviewerInput[];
}

export interface UpsertJobRoleGuideTemplateStageInterviewSchedulerConfigInterviewerInput {
  interviewerId: string;
}

export interface UpsertPostTemplateInput {
  data?: GraphQL_JSON | null;
  departmentId?: string | null;
  id?: string | null;
  name?: string | null;
  title?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
