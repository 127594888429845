/* eslint-disable @typescript-eslint/no-explicit-any */

/** @deprecated Just don't use it lol. */
export function pickProps(props: any, targetKeys: string[]) {
  const picked: any = {};
  targetKeys.forEach((targetKey) => {
    if (targetKey in props) picked[targetKey] = props[targetKey];
  });
  return picked;
}

/** @deprecated Just don't use it lol. */
export function excludeProps(props: any, targetKeys: string[]) {
  const rest: any = {};
  Object.keys(props).forEach((key) => {
    if (!targetKeys.includes(key)) rest[key] = props[key];
  });
  return rest;
}
