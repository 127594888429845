import { AtlasLinkProps, Link } from "@resource/atlas";
import useExtension from "react-hooks/useExtension";
import useExtensionLink from "react-hooks/useExtensionLink";

type Props = Omit<
  AtlasLinkProps<"a"> &
    AtlasLinkProps<"button"> & {
      href: string;
    },
  "as"
>;

function ExtensionLink({ children, href, ...props }: Props) {
  const { installed } = useExtension();
  const extensionLink = useExtensionLink();

  if (installed) {
    return (
      <Link href={extensionLink.generateHref(href)} {...props} as="a">
        {children}
      </Link>
    );
  }

  return (
    <Link
      {...props}
      onClick={(e) => {
        props.onClick?.(e);
        extensionLink.navigate(href, props.target);
      }}
      as="button"
    >
      {children}
    </Link>
  );
}

export default ExtensionLink;
