import { TextField } from "@resource/atlas";
import { atlasSearch } from "@resource/atlas/icons";
import { useLogEvent } from "analytics";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useAsyncDebounce } from "react-table";

interface GlobalFilterProps {
  placeholder: string;
  globalFilter: string;
  setGlobalFilter(globalFilter: string): void;
  className?: string;
  ariaLabel?: string;
}

function GlobalFilter({
  placeholder,
  globalFilter,
  setGlobalFilter,
  className,
  ariaLabel,
}: GlobalFilterProps) {
  const [filterValue, setFilterValue] = useState(globalFilter);
  const logEvent = useLogEvent({
    component: "GlobalFilter",
  });
  useEffect(() => {
    setFilterValue(globalFilter);
  }, [setFilterValue, globalFilter]);

  const setAsyncSetGlobalFilter = useAsyncDebounce(setGlobalFilter, 300);

  const onChange = (newValue: string) => {
    setFilterValue(newValue);
    setAsyncSetGlobalFilter(newValue);
  };

  const onBlur = () => {
    if (!filterValue) {
      return;
    }
    logEvent("Filter Guides by Keyword");
  };

  return (
    <TextField
      icon={atlasSearch}
      className={clsx("min-w-[16rem] max-w-xs", className)}
      type="search"
      value={filterValue}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      aria-label={ariaLabel}
    />
  );
}

export default GlobalFilter;
