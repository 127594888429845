/* eslint-disable import/prefer-default-export */

import { useMemo } from "react";

/** @deprecated Use Ariakit instead. */
export function useDisabledLinkProps({
  isLink,
  isDisabled,
}: {
  isLink?: boolean;
  isDisabled?: boolean;
}) {
  return useMemo(() => {
    if (!isLink) return {};

    const props = {
      // links don't support the 'disabled' attribute
      disabled: undefined,
    };

    const disabledProps = isDisabled && {
      // make links stop working by removing "href"
      href: undefined,
      // express the ARIA role, which is lost without a href
      role: "link",
      // indicate the disabled state
      "aria-disabled": true,
      // disable focus
      tabIndex: -1,
    };

    return { ...props, ...disabledProps };
  }, [isDisabled, isLink]);
}
