import { Item } from "@react-stately/collections";
import { ReactElement } from "react";

import { DeprecatedAtlasSelectOptionProps } from "./types";

/**
 * @deprecated Use the new `Select` component instead.
 */
const Option = Item as (
  props: DeprecatedAtlasSelectOptionProps
) => ReactElement;

export default Option;
