import { gql } from "@apollo/client";
import {
  getActionMenuColumn,
  getColumnSortHeadCellProps,
  getRowInteractionRowProps,
  Hovercard,
  Link,
  Table,
} from "@resource/atlas";
import { strings } from "@resource/common";
import { useLogEvent } from "analytics";
import GuideCompanyAvatars from "components/GuideCompanyAvatars";
import { Pagination } from "components/Pagination/Pagination";
import GlobalFilter from "components/Table/GlobalFilter";
import useQueryParamsState from "components/Table/hooks/useQueryParamState";
import NoTableResults from "components/Table/NoTableResults";
import UserTimeAgoTableCell from "components/UserTimeAgoTableCell";
import {
  GuideTableRowGuide,
  GuideTableRowOrganizationFragment as Organization,
  OrganizationGuidesOrderByInput,
} from "generated/schemaTypes";
import _ from "lodash";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useMemo } from "react";
import useExtensionLink from "react-hooks/useExtensionLink";
import {
  CellProps,
  Column,
  SortingRule,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
  UseTableCellProps,
} from "react-table";
import { formatEntity } from "shared/constants/entities";

import GuideStageCell from "./Guides/GuideStageCell";
import UpcomingInterviewsHovercard from "./Guides/UpcomingInterviewsHovercard";
import CandidateViewChart from "./legacy/Activity/CandidateViewChart";

const GUIDE_TABLE_ROW_FRAGMENT = gql`
  fragment GuideTableRowGuide on Guide {
    id
    atsUrl
    publicUrl
    roleName
    stageCount
    currentStagePosition
    upcomingInterviews {
      ...UpcomingInterviewsHovercardFragment
    }
    candidate {
      id
      firstName
      lastName
      imageUrl
    }
    currentStage {
      id
      title
      position
    }
    jobRoleGuideTemplateId
    lastEmailActivityAt
    candidateOpens
  }

  ${UpcomingInterviewsHovercard.fragments.interview}
`;

const GUIDE_TABLE_ROW_ORGANIZATION_FRAGMENT = gql`
  fragment GuideTableRowOrganizationFragment on Organization {
    id
    defaultAvatarImageUrl
    theme {
      primaryColor
    }
    name
  }
`;

interface InitialVariables {
  limit: number;
  jobRoleGuideTemplateId?: string;
  offset: number;
  search: string;
  orderBy: OrganizationGuidesOrderByInput[];
}

export interface GuidesTableProps {
  records: GuideTableRowGuide[];
  totalGuidesCount: number;
  loading: boolean;
  organization?: Organization;
  initialVariables: InitialVariables;
  viewingMultipleJobs?: boolean;
  sortBy: SortingRule<GuideTableRowGuide>[];
  noResultsChildren?: React.ReactNode;
}

function GuidesTable({
  noResultsChildren,
  records,
  totalGuidesCount,
  organization,
  loading,
  initialVariables,
  sortBy,
  viewingMultipleJobs = false,
}: GuidesTableProps) {
  const router = useRouter();
  const extensionLink = useExtensionLink();
  const logEvent = useLogEvent<{ guideId: string }>({
    component: "GuidesTable",
    project: viewingMultipleJobs
      ? "View & filter guides"
      : "View & filter guides for a job's candidates",
  });
  const logSortEvent = useLogEvent({
    component: "GuidesTable",
  });

  const columns = useMemo<Column<GuideTableRowGuide>[]>(
    () => [
      {
        id: viewingMultipleJobs ? "title" : "name",
        width: 288,
        Header: viewingMultipleJobs ? "Title" : "Name",
        accessor: (record: GuideTableRowGuide) => ({
          firstName: _.capitalize(record.candidate?.firstName),
          lastName: _.capitalize(record.candidate?.lastName),
          imageUrl: record.candidate?.imageUrl,
        }),
        Cell: ({ row }: UseTableCellProps<GuideTableRowGuide>) => {
          const { roleName, candidate, jobRoleGuideTemplateId } = row.original;
          const { firstName, lastName } = candidate;
          const fullName = strings.joinNames(firstName, lastName);
          const company = {
            name: organization?.name ?? "",
            imageUrl: organization?.defaultAvatarImageUrl,
            primaryColor: organization?.theme?.primaryColor ?? "",
          };

          return (
            <div className="flex items-center gap-2">
              <GuideCompanyAvatars candidate={candidate} company={company} />
              <div className="flex flex-col overflow-hidden">
                <div className=" text-dark text-body-md truncate">
                  {fullName}
                </div>
                {viewingMultipleJobs && (
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                  <div
                    onClick={(e) => e.stopPropagation()}
                    onPointerDown={(e) => e.stopPropagation()}
                    onKeyDown={(e) => e.stopPropagation()}
                    className="truncate text-subtle"
                  >
                    <NextLink href={`/jobs/${jobRoleGuideTemplateId}`}>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <Link variant="subtle">{roleName}</Link>
                    </NextLink>
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        id: "stage",
        width: 320,
        Header: "Guide stage",
        disableSortBy: true,
        accessor: (record: GuideTableRowGuide) => ({
          currentStagePosition: record.currentStagePosition,
          currentStage: record.currentStage,
          stageCount: record.stageCount,
        }),
        Cell: GuideStageCell,
      },
      {
        id: "views",
        width: 60,
        Header: "Views",
        disableSortBy: true,
        accessor: (record: GuideTableRowGuide) => record.candidateOpens,
        Cell: ({
          row: {
            original: { candidateOpens, id },
          },
        }: UseTableCellProps<GuideTableRowGuide>) => (
          <>
            {candidateOpens?.length > 0 ? (
              <Hovercard.Root
                setOpen={(open) => {
                  if (open) {
                    logEvent("Candidate Opens Hovered", {
                      guideId: id,
                    });
                  }
                }}
              >
                <Hovercard.Anchor>
                  <div>{candidateOpens.length}</div>
                </Hovercard.Anchor>
                <Hovercard.Content>
                  <Hovercard.Heading className="text-body-md-heavy text-dark mb-4">
                    Candidate views over last 7 days
                  </Hovercard.Heading>
                  <div className="w-80">
                    <CandidateViewChart
                      candidateOpens={candidateOpens}
                      label=""
                    />
                  </div>
                </Hovercard.Content>
              </Hovercard.Root>
            ) : (
              <p className="text-body-md">-</p>
            )}
          </>
        ),
      },
      {
        id: "lastSentAt",
        width: 100,
        Header: "Updated",
        accessor: (record: GuideTableRowGuide) => ({
          date: Date.parse(record.lastEmailActivityAt),
        }),
        Cell: UserTimeAgoTableCell,
      },
      getActionMenuColumn({
        getItems: ({ row }: CellProps<GuideTableRowGuide>) => [
          {
            _type: "item",
            children: "View",
            key: "view",
            size: "compact",
            onSelect: () => {
              logEvent("Menu View Clicked", {
                guideId: row.original.id,
              });
              window.open(row.original.publicUrl);
            },
          },
          {
            _type: "item",
            children: "Manage",
            key: "manage",
            size: "compact",
            onSelect: () => {
              logEvent("Menu Manage Clicked", {
                project: [
                  "Check and prompt Extension install",
                  viewingMultipleJobs
                    ? "View & filter guides"
                    : "View & filter guides for a job's candidates",
                ],
                guideId: row.original.id,
              });
              extensionLink.navigate(row.original.atsUrl);
            },
          },
          {
            _type: "item",
            children: "Edit",
            key: "edit",
            size: "compact",
            onSelect: () => {
              logEvent("Menu Edit Clicked", {
                guideId: row.original.id,
              });
              router.push(
                `/jobs/${row.original.jobRoleGuideTemplateId}/stages`
              );
            },
          },
        ],
        getExtraButton: ({ row }: CellProps<GuideTableRowGuide>) => ({
          children: "Manage",
          onClick: () => {
            logEvent("Manage Button Clicked", {
              project: [
                "Check and prompt Extension install",
                viewingMultipleJobs
                  ? "View & filter guides"
                  : "View & filter guides for a job's candidates",
              ],
              guideId: row.original.id,
            });
            extensionLink.navigate(row.original.atsUrl);
          },
        }),
      }),
      {
        id: "state",
        width: 0,
        hidden: true,
      },
      {
        id: "status",
        width: 0,
        hidden: true,
      },
    ],
    // addingn log event here causing infinite rendering, not sure why yet
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      viewingMultipleJobs,
      organization?.name,
      organization?.defaultAvatarImageUrl,
      organization?.theme?.primaryColor,
      router,
    ]
  );

  const data = useMemo(() => records, [records]);
  const totalCount = useMemo(() => totalGuidesCount, [totalGuidesCount]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    setGlobalFilter,
    state: tableState,
    prepareRow,
    previousPage,
    nextPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      getRowId: ({ id }) => id,
      initialState: {
        hiddenColumns: ["state", "status"],
        globalFilter: initialVariables.search,
        isLoading: loading,
        pageSize: initialVariables.limit,
        pageIndex: initialVariables.offset / initialVariables.limit,
        sortBy,
      },
      manualPagination: true,
      disableMultiSort: true,
      pageCount: Math.ceil(totalCount / initialVariables.limit),
      manualGlobalFilter: true,
      manualSortBy: true,
      disableSortRemove: true,
      // autoResetPage: true,
      // autoResetSortBy: true,
    },
    useGlobalFilter,
    useQueryParamsState,
    useSortBy,
    usePagination,
    useFlexLayout,
    useResizeColumns
  );

  const { globalFilter, pageSize, pageIndex } = tableState;
  const isFiltered = !!globalFilter;

  const hasResults = !!rows.length;

  if (!loading && !hasResults && !isFiltered) {
    return (
      <div className="my-32 flex justify-center w-full">
        <div className="w-1/2 flex items-center text-center flex-col">
          <h3 className="text-h4">
            No {formatEntity("guide", { plural: true })}
          </h3>
          <p className="text-body-md text-subtle mt-2 max-w-md">
            {viewingMultipleJobs
              ? "You haven't created any guides yet. Get started by selecting a job and sending guides to candidates."
              : "Create guides for candidates for this job to coordinate interviews and share updates."}
          </p>
          <Link
            className="text-body-md mt-4"
            href="https://support.guide.co/en/collections/3458885-sending-guides"
            target="blank"
          >
            Learn how to send {formatEntity("guide", { plural: true })} -&gt;
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center w-full">
        <GlobalFilter
          placeholder="Search"
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <Table.Root {...getTableProps()}>
        <Table.Head role="rowgroup">
          {_.map(headerGroups, (headerGroup) => (
            <Table.HeadRow {...headerGroup.getHeaderGroupProps()}>
              {_.map(headerGroup.headers, (column) => (
                <Table.HeadCell
                  onClick={() =>
                    logSortEvent("Sort Guides by Header", {
                      column: column.Header?.toString(),
                    })
                  }
                  {...column.getHeaderProps()}
                  {...getColumnSortHeadCellProps(column)}
                >
                  {column.render("Header")}
                </Table.HeadCell>
              ))}
            </Table.HeadRow>
          ))}
        </Table.Head>
        <Table.Body {...getTableBodyProps()}>
          {_.map(rows, (row) => {
            prepareRow(row);
            return (
              <Table.Row
                {...row.getRowProps()}
                className="group"
                {...getRowInteractionRowProps(
                  { state: tableState, row },
                  {
                    onClick: () => {
                      logEvent("Table Row Clicked", {
                        guideId: row.original.id,
                      });
                      window.open(row.original.publicUrl, "_blank");
                    },
                  }
                )}
              >
                {row.cells.map((cell) => (
                  <Table.Cell {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </Table.Cell>
                ))}
              </Table.Row>
            );
          })}
          <NoTableResults
            loading={loading}
            hasResults={hasResults}
            isFiltered={isFiltered}
            recordDisplayType={formatEntity("guide", { plural: true })}
            clearFilters={() => setGlobalFilter("")}
          >
            {noResultsChildren}
          </NoTableResults>
        </Table.Body>
        {hasResults ? (
          <Pagination
            totalCount={totalCount}
            pageSize={pageSize}
            pageIndex={pageIndex}
            isLoading={loading}
            onPrevPage={previousPage}
            onNextPage={nextPage}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            onPageSizeChange={(newPageSize: number) => {
              setPageSize(newPageSize);
            }}
          />
        ) : null}
      </Table.Root>
    </div>
  );
}

GuidesTable.fragments = {
  guide: GUIDE_TABLE_ROW_FRAGMENT,
  organization: GUIDE_TABLE_ROW_ORGANIZATION_FRAGMENT,
};

export default GuidesTable;
