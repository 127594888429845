import _ from "lodash";
import { useRouter } from "next/router";
import { Filters, Hooks, SortingRule, TableInstance } from "react-table";
import { useQueryStringValue } from "utils/next";

export const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_PAGE_INDEX = 0;
interface StateParams<T extends object> {
  globalFilter: string;
  pageSize: number;
  pageIndex: number;
  filters: Filters<T>;
  sortBy: SortingRule<T>[];
}

const setDefaults = <T extends object>(
  params: StateParams<T>,
  defaults?: Partial<StateParams<T>>
): StateParams<T> => ({
  globalFilter: params.globalFilter || defaults?.globalFilter || "",
  pageSize: params.pageSize || defaults?.pageSize || DEFAULT_PAGE_SIZE,
  pageIndex: params.pageIndex || defaults?.pageIndex || DEFAULT_PAGE_INDEX,
  filters: params.filters || defaults?.filters,
  sortBy: params.sortBy || defaults?.sortBy || [{ id: "name", desc: false }],
});

export const useCurrentQueryParamStateWithDefaults = <T extends object>(
  defaults?: Partial<StateParams<T>>
) => {
  const pageIndexParam = useQueryStringValue("pageIndex");
  const sortByParam = useQueryStringValue("sortBy");
  const filtersParam = useQueryStringValue("filters");
  const pageSizeParam = useQueryStringValue("pageSize");
  const globalFilter = useQueryStringValue("globalFilter");

  const pageIndex = Number(pageIndexParam);
  const pageSize = Number(pageSizeParam);
  const sortBy = sortByParam ? JSON.parse(sortByParam) : undefined;
  const filters = filtersParam ? JSON.parse(filtersParam) : undefined;
  return setDefaults(
    {
      globalFilter,
      pageSize,
      pageIndex,
      filters,
      sortBy,
    },
    defaults
  );
};

const useInstance = <T extends object>(instance: TableInstance<T>) => {
  const router = useRouter();
  const { query } = router;
  const existingQuery = useCurrentQueryParamStateWithDefaults();

  const {
    state: { globalFilter, filters, pageSize, pageIndex, sortBy },
  } = instance;
  const newQuery = setDefaults({
    globalFilter,
    filters,
    sortBy,
    pageSize,
    pageIndex,
  });
  if (!_.isEqual(existingQuery, newQuery)) {
    router.replace(
      {
        query: {
          ...query,
          ..._.pickBy(
            {
              ...newQuery,
              filters: JSON.stringify(newQuery.filters),
              sortBy: JSON.stringify(newQuery.sortBy),
            },
            _.negate(_.isNil)
          ),
        },
      },
      undefined,
      { shallow: true }
    );
  }
};

const useQueryParamsState = <T extends object>(hooks: Hooks<T>) => {
  hooks.useInstance.push(useInstance);
};

export default useQueryParamsState;
