/* eslint-disable import/prefer-default-export */

import { forwardRef, ReactElement } from "react";

/** @deprecated Use polymorphic types from `utils/html` instead. */
export function overloadedForwardRef<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderFunction: (props: any, ref: any) => ReactElement | null
) {
  return forwardRef(renderFunction) as unknown as T;
}
