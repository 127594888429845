import { Button, LoadingIndicator } from "@resource/atlas";
import clsx from "clsx";
import React from "react";

type NoTableResultsProps = {
  hasResults: boolean;
  loading: boolean;
  isFiltered: boolean;
  clearFilters(): void;
  recordDisplayType?: string;
  children?: React.ReactNode;
  filteredHeading?: string;
  fileredDescription?: string;
  notFilteredHeading?: string;
  notFileredDescription?: string;
  className?: string;
};

function NoTableResults({
  hasResults,
  loading,
  isFiltered,
  clearFilters,
  recordDisplayType = "results",
  children,
  filteredHeading,
  fileredDescription = "Try adjusting your filters to see more results.",
  notFilteredHeading,
  notFileredDescription = "No results found",
  className,
}: NoTableResultsProps) {
  return !hasResults || loading ? (
    <div
      className={clsx(
        "py-32 flex justify-center items-center w-full",
        className
      )}
    >
      {loading ? (
        <LoadingIndicator />
      ) : (
        <div className="w-1/2 flex items-center text-center flex-col">
          {!hasResults && isFiltered && (
            <>
              <h3 className="text-h3">
                {filteredHeading || `No ${recordDisplayType} found`}
              </h3>
              <p className="text-body-md text-subtle mt-2 max-w-xs">
                {fileredDescription}
              </p>
              {isFiltered && (
                <Button className="mt-6" onClick={clearFilters}>
                  Clear filters
                </Button>
              )}
            </>
          )}
          {!hasResults && !isFiltered
            ? children ?? (
                <>
                  <h3 className="text-h3">
                    {notFilteredHeading || `No ${recordDisplayType} found`}
                  </h3>
                  <p className="text-body-md text-subtle mt-2 max-w-xs">
                    {notFileredDescription}
                  </p>
                </>
              )
            : null}
        </div>
      )}
    </div>
  ) : null;
}

export default NoTableResults;
