import { Avatar } from "@resource/atlas";
import moment from "moment-timezone";
import { UseTableCellProps } from "react-table";

function UserTimeAgoTableCell({
  value,
}: UseTableCellProps<
  Record<string, unknown>,
  {
    date: string;
    user?: { name: string; imageUrl?: string };
  }
>) {
  const humanizedTime = moment
    .duration(moment().diff(moment(value.date)))
    .humanize();
  return (
    <div className="flex items-center space-x-2">
      {value.user && (
        <Avatar
          name={value.user.name}
          image={value.user.imageUrl}
          size="small"
          className="flex-shrink-0"
        />
      )}
      <div className="flex-shrink truncate">
        <div className="truncate">
          {value.date ? `${humanizedTime} ago` : "-"}
        </div>
      </div>
    </div>
  );
}

export default UserTimeAgoTableCell;
