import { makeStyles, Typography } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { ResourceCustomTheme } from "styles/config";

const useStyles = makeStyles((theme: ResourceCustomTheme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .chart": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      padding: "10px",
      paddingBottom: "35px",
      height: "100px",
      overflow: "hidden",
      "& .legend": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        borderRight: `${theme.colors.KarlGray(600)} 1px solid`,
        paddingRight: "10px",
        "& .number": {
          marginTop: "-6px",
          lineHeight: "4px",
        },
      },
      "& .chartBar": {
        width: "12.5%",
        background: theme.colors.RoyalPurple(300),
        position: "relative",
        borderBottom: `${theme.colors.KarlGray(600)} 1px solid`,
        "& .dayLabel": {
          bottom: "-27px",
          position: "absolute",
          left: 1,
          transform: "rotate(-45deg)",
          minWidth: "100%",
        },
      },
    },
  },
}));

type CandidateViewChartProps = {
  candidateOpens: string[];
  label?: string;
};

const CandidateViewChart: React.FC<CandidateViewChartProps> = ({
  candidateOpens,
  label = "Candidate Views over last 7 days",
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {label && <Typography variant="subtitle2">{label}</Typography>}
      <div className="chart">
        <div className="legend">
          <Typography className="number" variant="subtitle2">
            10
          </Typography>
          <Typography className="number" variant="subtitle2">
            5
          </Typography>
          <Typography className="number" variant="subtitle2">
            0
          </Typography>
        </div>
        {_.times(7, (i) => {
          const day = moment().subtract(6 - i, "days");
          const dayStart = day.startOf("day").toISOString();
          const dayEnd = day.endOf("day").toISOString();
          const numberOfViews = _.filter(candidateOpens, (date) => {
            // All views before first day
            if (i === 0) {
              return moment(date).isBefore(dayEnd);
            }
            return moment(date).isBetween(dayStart, dayEnd);
          }).length;
          return (
            <div
              key={day.toISOString()}
              className="chartBar"
              style={{
                height: `${numberOfViews * 7}px`,
              }}
            >
              <Typography className="dayLabel" variant="subtitle2">
                {i === 0 && "Older"}
                {i === 6 && "Today"}
                {i < 6 && i > 0 && day.format("ddd")}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CandidateViewChart;
