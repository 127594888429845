/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  PressHookProps,
  PressResult,
  usePress as usePressOriginal,
} from "@react-aria/interactions";
import { useMemo } from "react";

import { excludeProps, pickProps } from "./props";

const PRESS_EVENTS = [
  "onPress",
  "onPressStart",
  "onPressEnd",
  "onPressChange",
  "onPressUp",
];

const HOOK_PROPS = [
  "onPress",
  "onPressChange",
  "onPressStart",
  "onPressEnd",
  "onPressUp",
  "isDisabled",
  "isPressed",
  "preventFocusOnPress",
  "shouldCancelOnPointerExit",
];

function isEventHandler(key: string) {
  return !!key.match(/^on[A-Z]/);
}

function isPressEventHandler(name: string) {
  return PRESS_EVENTS.includes(name);
}

function isNonPressEventHandler(name: string) {
  return isEventHandler(name) && !isPressEventHandler(name);
}

function hasPressEventHandlers(props: any) {
  return Object.keys(props).some(isPressEventHandler);
}

function hasNonPressEventHandlers(props: any) {
  return Object.keys(props).some(isNonPressEventHandler);
}

function patchEventHandler<H>(handler: H): H {
  return ((event: Event) => {
    // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-empty-function
    if (event.stopPropagation) event.stopPropagation = () => {};
    // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-empty-function
    if (event.preventDefault) event.preventDefault = () => {};
    return (handler as unknown as (e: Event) => void)(event);
  }) as unknown as H;
}

function patchPressProps<T>(props: any, pressProps: T): T {
  let copy: Record<string, unknown>;
  if (hasPressEventHandlers(props)) {
    if (hasNonPressEventHandlers(props))
      // eslint-disable-next-line no-console
      console.warn(
        "[usePress] Mixing press event handlers with other event handlers causes buggy behavior and should be avoided."
      );
    copy = { ...pressProps } as any;
  } else {
    const { onClick, ...otherPressProps } = pressProps as any;
    copy = otherPressProps;
    Object.entries(copy).forEach(([key, value]) => {
      if (isEventHandler(key)) copy[key] = patchEventHandler(value);
    });
  }
  return copy as T;
}

function pickPressProps(props: any) {
  return pickProps(props, HOOK_PROPS);
}

/** Exclude the props needed by "usePress" from a set of props.
 *
 * @deprecated Just don't.
 */
export function excludePressProps(props: any) {
  return excludeProps(props, HOOK_PROPS);
}

/**
 * @deprecated Just don't.
 */
export function usePress(props: PressHookProps): PressResult {
  const hookProps = pickPressProps(props);
  const { isPressed, pressProps } = usePressOriginal(hookProps);
  const patchedPressProps = useMemo(
    () => patchPressProps(props, pressProps),
    [pressProps, props]
  );
  return { isPressed, pressProps: patchedPressProps };
}
