import {
  Button,
  DeprecatedSelect,
  DeprecatedSelectOption,
  Tooltip,
} from "@resource/atlas";
import { atlasChevronLeft, atlasChevronRight } from "@resource/atlas/icons";

const RIGHT_ICON = atlasChevronRight;
const LEFT_ICON = atlasChevronLeft;

export interface PaginationProps {
  totalCount: number;
  pageSize: number;
  pageIndex: number;
  isLoading: boolean;
  onPrevPage: () => void;
  onNextPage: () => void;
  canNextPage: boolean;
  canPreviousPage: boolean;
  onPageSizeChange: (pageSize: number) => void;
}

export function Pagination({
  totalCount,
  pageSize,
  pageIndex,
  isLoading,
  onPrevPage,
  onNextPage,
  canNextPage,
  canPreviousPage,
  onPageSizeChange,
}: PaginationProps) {
  const prevPageTooltip = "Previous page";
  const nextPageTooltip = "Next page";

  const lowerLimit = Math.max(pageSize * pageIndex + 1, 1);
  const upperLimit = Math.min(pageSize * (pageIndex + 1), totalCount);

  return (
    <div className="flex justify-between items-center">
      <div className="text-body-md">
        {lowerLimit} - {upperLimit} of {totalCount}
      </div>
      <div className="flex">
        <DeprecatedSelect
          selectedKey={pageSize.toString()}
          isGhost
          onSelectionChange={(newPageSize) =>
            onPageSizeChange(parseInt(newPageSize as string, 10))
          }
        >
          <DeprecatedSelectOption key={25}>25 per page</DeprecatedSelectOption>
          <DeprecatedSelectOption key={50}>50 per page</DeprecatedSelectOption>
          <DeprecatedSelectOption key={100}>
            100 per page
          </DeprecatedSelectOption>
        </DeprecatedSelect>
        <Tooltip content={prevPageTooltip}>
          <Button
            className="ml-4"
            aria-label={prevPageTooltip}
            disabled={!canPreviousPage || isLoading}
            isGhost
            icon={LEFT_ICON}
            onClick={onPrevPage}
          />
        </Tooltip>
        <Tooltip content={nextPageTooltip}>
          <Button
            aria-label={nextPageTooltip}
            disabled={!canNextPage || isLoading}
            isGhost
            icon={RIGHT_ICON}
            onClick={onNextPage}
          />
        </Tooltip>
      </div>
    </div>
  );
}
