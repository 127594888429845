import clsx from "clsx";
import { forwardRef, HTMLAttributes, useRef } from "react";
import mergeRefs from "react-merge-refs";

import { atlasCaretDown, atlasCaretUp } from "../../icons";
import { Icon } from "../icon";
import { el, ROOT, setTreeDisplayName } from "./shared";
import { AtlasTableHeadCellProps, AtlasTableRowProps } from "./types";

// config
// ------

const SORT_ASC_ICON = atlasCaretUp;
const SORT_DESC_ICON = atlasCaretDown;

// root
// ----

/** Equivalent to `<table>`. */
export const Root = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} {...props} className={clsx(ROOT, className)} />
  )
);
setTreeDisplayName(Root, "Root");

// head cell
// ---------

/** Equivalent to `<th>`. */
export const HeadCell = forwardRef<HTMLDivElement, AtlasTableHeadCellProps>(
  (
    { isSortable, sort, sortButtonProps, children, className, ...props },
    ref
  ) => {
    const content = (
      <>
        {children}
        {isSortable && (
          <Icon
            className={el`sort-icon`}
            content={sort === "desc" ? SORT_DESC_ICON : SORT_ASC_ICON}
          />
        )}
      </>
    );

    const cellClassName = [
      el`head-cell`,
      {
        [`sort-${sort}`]: sort,
        isSortable,
      },
      className,
    ];

    return (
      <div ref={ref} {...props} className={clsx(!isSortable && cellClassName)}>
        {isSortable ? (
          <button
            type="button"
            {...sortButtonProps}
            className={clsx(
              isSortable && cellClassName,
              sortButtonProps?.className
            )}
          >
            {content}
          </button>
        ) : (
          content
        )}
      </div>
    );
  }
);
setTreeDisplayName(HeadCell, "HeadCell");

// row
// ---

/** Equivalent to `<tr>` inside a `<tbody>`. */
export const Row = forwardRef<HTMLDivElement, AtlasTableRowProps>(
  (
    {
      isSelectable,
      isClickable,
      isSelected,
      onToggleSelect,
      onClick,
      children,
      className,
      ...props
    },
    ref
  ) => {
    const rowRef = useRef<HTMLDivElement>(null);

    function moveFocus(direction: "up" | "down") {
      if (!rowRef.current) return;
      let element: HTMLElement | null;
      if (direction === "up")
        element = rowRef.current.previousElementSibling as HTMLElement | null;
      else element = rowRef.current.nextElementSibling as HTMLElement | null;

      if (element?.classList.contains(el`row`)) {
        element?.scrollIntoView?.({ block: "nearest" });
        element?.focus?.({ preventScroll: true });
      }
    }

    return (
      // should already receive a role ("row"), and with tabIndex={0} it should
      // be good enough for accessibility
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        ref={mergeRefs([ref, rowRef])}
        {...props}
        title={isSelectable ? "Toggle row selected" : undefined}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={isSelectable || isClickable ? 0 : undefined}
        onClick={(e) => {
          if (isSelectable) onToggleSelect?.();
          else if (isClickable) onClick?.(e);
        }}
        onKeyDown={(e) => {
          // keyboard accessible "onClick"
          if ([" ", "Spacebar", "Enter"].includes(e.key)) {
            if ([" ", "Spacebar"].includes(e.key)) e.preventDefault(); // prevent scroll
            if (isSelectable) onToggleSelect?.();
            else if (isClickable) e.currentTarget.click();
          }

          // arrow navigation
          if (
            (isSelectable || isClickable) &&
            (e.key === "ArrowUp" || e.key === "ArrowDown") &&
            (e.target as Element | null)?.classList?.contains(el`row`)
          ) {
            moveFocus(e.key === "ArrowUp" ? "up" : "down");
            e.preventDefault();
          }
        }}
        className={clsx(
          el`row`,
          {
            "is-selectable": isSelectable,
            "is-clickable": isClickable,
            "is-selected": isSelected,
          },
          className
        )}
      >
        {children}
      </div>
    );
  }
);
setTreeDisplayName(Row, "Row");

// simple components
// -----------------

function generateComponent(elementName: string) {
  return forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => (
      <div ref={ref} {...props} className={clsx(el(elementName), className)} />
    )
  );
}

// these components don't do anything special, they are plain divs with a semantic
// displayName, and a className that might be used as part of the styling

/** Equivalent to `<thead>`. */
export const Head = generateComponent("head");
setTreeDisplayName(Head, "Head");

/** Equivalent to `<tr>` inside a `<thead>`. */
export const HeadRow = generateComponent("head-row");
setTreeDisplayName(HeadRow, "HeadRow");

/** Equivalent to `<tbody>`. */
export const Body = generateComponent("body");
setTreeDisplayName(Body, "Body");

/** Equivalent to `<td>`. */
export const Cell = generateComponent("cell");
setTreeDisplayName(Cell, "Cell");
