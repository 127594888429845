import { Avatar } from "@resource/atlas";
import { strings } from "@resource/common";
import clsx from "clsx";

type GuideCompanyAvatarsProps = {
  candidate?: {
    firstName: string;
    lastName: string;
    imageUrl: string;
  };
  company: {
    imageUrl?: string;
    primaryColor: string;
    name: string;
  };
  size?: "medium" | "xl" | "2xl";
};

export default function GuideCompanyAvatars({
  candidate,
  company,
  size = "medium",
}: GuideCompanyAvatarsProps) {
  const sizeClass = clsx({
    "w-12 h-12": size === "medium",
    "w-[4.5rem] h-[4.5rem]": size === "xl",
    "w-24 h-24": size === "2xl",
  });
  const avatarPosition = clsx({
    // default
    "left-4 bottom-0 mr-4": size === "medium",
    "left-[1.375rem] bottom-0 mr-[1.375rem]": size === "xl",
    "left-8 bottom-0 mr-8": size === "2xl",
  });

  return (
    <div className={clsx("relative flex-shrink-0", sizeClass)}>
      <Avatar
        image={company.imageUrl}
        name={company.imageUrl ? undefined : company.name}
        color={company.imageUrl ? undefined : company.primaryColor}
        size={size}
      />
      <Avatar
        className={clsx(
          "rounded-full ring-[.125rem] ring-white absolute",
          avatarPosition
        )}
        name={
          candidate
            ? strings.joinNames(candidate.firstName, candidate.lastName)
            : undefined
        }
        image={candidate ? candidate.imageUrl : undefined}
        size={size}
      />
    </div>
  );
}
