import * as Radix from "@radix-ui/react-dropdown-menu";
import { useFocusRing } from "@react-aria/focus";
import { useHover } from "@react-aria/interactions";
import { mergeProps } from "@react-aria/utils";
import { useSelectState } from "@react-stately/select";
import clsx from "clsx";
import { ForwardedRef, forwardRef, ReactElement, useRef } from "react";

import { atlasCaretDown, atlasCheck } from "../../../icons";
import {
  getPropsWithDefaults,
  useOptionalRef,
} from "../../__utils/__deprecated";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../__utils/atlas";
import { Icon } from "../../icon";
import { DeprecatedAtlasSelectProps } from "./types";

// config
// ------

const ROOT = makeRootClassName("Select");
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  size: "medium",
  isGhost: false,
  align: "end",
} as const;

const DROPDOWN_ICON = atlasCaretDown;
const SELECTED_ICON = atlasCheck;

// helper components
// -----------------

interface OptionComponentProps {
  value: string;

  isDisabled: boolean;

  isSelected: boolean;

  handleSelect: () => void;
}

function OptionComponent({
  value,
  isDisabled,
  isSelected,
  handleSelect,
}: OptionComponentProps): ReactElement {
  const ref = useRef(null);

  // behavior

  const { hoverProps, isHovered } = useHover({ isDisabled });
  const { focusProps, isFocusVisible } = useFocusRing();
  const behaviorProps = mergeProps(hoverProps, focusProps);

  // rendering

  return (
    <Radix.Item
      ref={ref}
      {...behaviorProps}
      onSelect={handleSelect}
      disabled={isDisabled}
      textValue={value}
      className={clsx(el`option`, {
        "is-hovered": isHovered,
        "is-focus-visible": isFocusVisible,
        "is-disabled": isDisabled,
        "is-selected": isSelected,
      })}
    >
      <span>{value}</span>
      {isSelected && (
        <Icon content={SELECTED_ICON} className={el`selected-icon`} />
      )}
    </Radix.Item>
  );
}

// pattern taken from Spectrum
function SelectComponent<T extends object>(
  props: DeprecatedAtlasSelectProps<T>,
  ref: ForwardedRef<HTMLElement>
): ReactElement {
  const p = getPropsWithDefaults(props, DEFAULT_PROPS);
  const domRef = useOptionalRef(ref);

  // state

  const state = useSelectState(p);

  // behavior

  const { hoverProps, isHovered } = useHover({ isDisabled: p.isDisabled });
  const { focusProps, isFocusVisible } = useFocusRing();
  const behaviorProps = mergeProps(hoverProps, focusProps);

  // rendering

  return (
    <div {...p.containerProps}>
      {(p.label || p.helpText) && (
        <div className={el`input-header`}>
          {p.label && (
            <label className={el`label`}>
              <span>{p.label}</span>
            </label>
          )}
          {p.helpText && <span className={el`help-text`}>{p.helpText}</span>}
        </div>
      )}
      <Radix.Root
        defaultOpen={p.defaultOpen}
        open={p.open}
        onOpenChange={p.onOpenChange}
      >
        <Radix.Trigger
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={domRef}
          disabled={p.isDisabled}
          {...behaviorProps}
          className={clsx([
            `${ROOT} size-${p.size}`,
            {
              "is-disabled": p.isDisabled,
              "is-hovered": isHovered,
              "is-focus-visible": isFocusVisible,
              "has-trailing-icon": p.trailingIcon,
              "is-ghost": p.isGhost,
            },
            p.className,
          ])}
        >
          {p.leadingIcon && (
            <Icon content={p.leadingIcon} className={el`leading-icon`} />
          )}
          <span className={el`text`}>
            {state.selectedItem ? state.selectedItem.rendered : p.placeholder}
          </span>
          {p.trailingIcon && (
            <Icon content={p.trailingIcon} className={el`trailing-icon`} />
          )}
          <Icon content={DROPDOWN_ICON} className={el`dropdown-icon`} />
        </Radix.Trigger>
        <Radix.Content align={p.align} className={el`menu`}>
          {Array.from(state.collection).map((item) => (
            <OptionComponent
              key={item.key}
              value={item.rendered as string}
              isDisabled={state.disabledKeys.has(item.key)}
              isSelected={state.selectionManager.isSelected(item.key)}
              handleSelect={() => state.setSelectedKey(item.key)}
            />
          ))}
        </Radix.Content>
      </Radix.Root>
    </div>
  );
}

/**
 * An input that allows a user to select one of a number of options
 * from a menu that appears in a popover.
 *
 * @deprecated Use the new `Select` component instead.
 */
const Select = forwardRef<HTMLElement, DeprecatedAtlasSelectProps<"select">>(
  SelectComponent
);

export default Select;
