import { Icon, Link } from "@resource/atlas";
import { atlasCandidateGuide } from "@resource/atlas/icons";
import { useLogEvent } from "analytics";
import ExtensionLink from "components/Generic/ExtensionLink";
import {
  GuideForCandidatesTableRowFragment as Guide,
  GuideStatusEnum,
} from "generated/schemaTypes";
import { MouseEvent } from "react";
import { analytics } from "react-hooks/useAnalytics";
import { UseTableCellProps } from "react-table";

import UpcomingInterviewsHovercard from "./UpcomingInterviewsHovercard";

export default function GuideStageCell({
  row,
  value,
}: UseTableCellProps<Guide>) {
  const {
    stageCount,
    currentStage,
    currentStagePosition,
    status,
    atsUrl,
    publicUrl,
    upcomingInterviews,
    candidate,
    id,
  } = row.original;

  const logEvent = useLogEvent({ component: "GuideStageCell" });

  if (status === GuideStatusEnum.NOT_STARTED) {
    return (
      <ExtensionLink
        href={atsUrl}
        target="_blank"
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          logEvent("Create Guide Clicked", {
            project: [
              "Check and prompt Extension install",
              "Create a guide and share with candidate",
            ],
            candidateId: candidate.id,
            customerSuccess: true,
          });
        }}
        disabled={!value?.enabled}
      >
        Create guide
      </ExtensionLink>
    );
  }

  const cell =
    currentStage && currentStagePosition !== null ? (
      <div className="flex items-center gap-2 pr-2">
        <Link
          href={publicUrl}
          target="_blank"
          variant="monochrome"
          className="truncate"
          onClick={(e: MouseEvent) => {
            analytics.track("Guide Stage Clicked", {
              candidateId: candidate.id,
              guideId: id,
            });
            e.stopPropagation();
          }}
        >
          <span className="text-body-md flex gap-2 items-center">
            <Icon
              className="text-subtle"
              content={atlasCandidateGuide}
              size="medium"
            />
            <span className="truncate">{currentStage.title}</span>
          </span>
        </Link>
        <span className="text-subtle whitespace-nowrap">
          {` (${currentStagePosition + 1} of ${stageCount})`}
        </span>
        <UpcomingInterviewsHovercard
          upcomingInterviews={upcomingInterviews}
          setOpen={(open) => {
            if (open) {
              analytics.track("Upcoming Interviews Hovered", {
                guideId: id,
              });
            }
          }}
        />
      </div>
    ) : (
      <p className="text-body-md">-</p>
    );
  return cell;
}
