import { ReactNode } from "react";
import {
  CellProps,
  Column,
  TableToggleCommonProps,
  UseRowSelectRowProps,
  UseRowSelectState,
  UseSortByColumnProps,
} from "react-table";

import { atlasEllipsisHorizontal } from "../../icons";
import { AtlasButtonProps, Button, ButtonGroup } from "../button";
import { Checkbox } from "../checkbox";
import { AtlasCheckboxProps } from "../checkbox/types";
import { Menu } from "../menu";
import { AtlasMenuContentItem, AtlasMenuContentProps } from "../menu/types";
import { el } from "./shared";
import { AtlasTableHeadCellProps, AtlasTableRowProps } from "./types";

// config
// ------

const ACTION_MENU_ICON = atlasEllipsisHorizontal;

// stop propagation util
// ---------------------

/** Stops event propagation for `onClick` and `onKeyDown` handlers. */
function StopPropagation({ children }: { children?: ReactNode }) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onClick={(e) => e.stopPropagation()}
      onPointerDown={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      {children}
    </div>
  );
}

// action menu column
// ------------------

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ActionMenuColumnOptions<D extends object, V = any> = {
  getItems?: (props: CellProps<D, V>) => AtlasMenuContentItem[];
  getDisabled?: (props: CellProps<D, V>) => boolean;
  getExtraButton?: (props: CellProps<D, V>) => AtlasButtonProps;
  menuContentProps?: Partial<AtlasMenuContentProps>;
  triggerAriaLabel?: string;
};

/** Generates an action menu column. Depends on usage of a layout
 * plugin, `useResizeColumns`, and `useSortBy`. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getActionMenuColumn<D extends object, V = any>({
  getItems,
  getDisabled,
  getExtraButton,
  menuContentProps,
  triggerAriaLabel,
}: ActionMenuColumnOptions<D, V>): Column<D> {
  return {
    id: "action-menu",
    width: getExtraButton ? 120 : 40,
    disableResizing: true,
    disableSortBy: true,
    Cell: (props: CellProps<D, V>) => (
      <StopPropagation>
        <ButtonGroup
          isGhost
          variant="dark"
          size="small"
          disabled={getDisabled?.(props)}
        >
          <Menu.Root>
            {getExtraButton ? (
              <Button
                {...getExtraButton(props)}
                className={el`action-menu-trigger`}
              />
            ) : null}
            <Menu.Trigger>
              <Button
                className={el`action-menu-trigger`}
                icon={ACTION_MENU_ICON}
                aria-label={triggerAriaLabel ?? "Row actions"}
              />
            </Menu.Trigger>
            <Menu.Content
              {...menuContentProps}
              portal
              UNSAFE_disableDynamicCollectionWarnings
              items={getItems?.(props)}
            />
          </Menu.Root>
        </ButtonGroup>
      </StopPropagation>
    ),
  };
}

// selection column and row props
// ------------------------------

/** TODO: docs. */
function getSelectionColumnCheckboxProps({
  indeterminate,
  checked,
  ...checkboxProps
}: TableToggleCommonProps): AtlasCheckboxProps {
  return {
    ...checkboxProps,
    checked: indeterminate ? "mixed" : checked,
  };
}

/** TODO: docs. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getSelectionColumn<D extends object, V = any>(): Column<D> {
  return {
    id: "selection",
    width: 26,
    disableResizing: true,
    disableSortBy: true,
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <StopPropagation>
        <Checkbox
          {...getSelectionColumnCheckboxProps(getToggleAllRowsSelectedProps())}
          title="Toggle all rows selected"
          color="primary"
        />
      </StopPropagation>
    ),
    // The cell can use the individual row's getToggleRowSelectedProps method
    // to the render a checkbox
    Cell: ({ row }: CellProps<D, V>) => (
      <StopPropagation>
        <Checkbox
          {...getSelectionColumnCheckboxProps(row.getToggleRowSelectedProps())}
          title="Toggle row selected"
          color="primary"
        />
      </StopPropagation>
    ),
  };
}

/** TODO: docs. */
function checkIfAnySelected<D extends object>(state: UseRowSelectState<D>) {
  return state.selectedRowIds && Object.keys(state.selectedRowIds).length > 0;
}

/** TODO: docs. */
export function getRowInteractionRowProps<D extends object>(
  {
    state,
    row,
  }: {
    state: UseRowSelectState<D>;
    row: UseRowSelectRowProps<D>;
  },
  opt: { onClick?: AtlasTableRowProps["onClick"] } = {}
): AtlasTableRowProps {
  return {
    isSelectable: checkIfAnySelected(state),
    isClickable: Boolean(opt.onClick),
    isSelected: row.isSelected,
    onToggleSelect: row.toggleRowSelected,
    onClick: opt.onClick,
  };
}

// column sort head cell props
// ---------------------------

/** Obtains the value for the `sort` prop of `<Table.HeadCell>`,
 * from a `react-table` column. Depends on the `useSortBy` plugin. */
export function getColumnSortValue<D extends object>(
  column: UseSortByColumnProps<D>
): AtlasTableHeadCellProps["sort"] {
  if (!column.isSorted) return undefined;
  return column.isSortedDesc ? "desc" : "asc";
}

/** Obtains the value for the `isSortable` prop of `<Table.HeadCell>`,
 * from a `react-table` column. Depends on the `useSortBy` plugin. */
export function getColumnIsSortableValue<D extends object>(
  column: UseSortByColumnProps<D>
): AtlasTableHeadCellProps["isSortable"] {
  return column.canSort;
}

/** Obtains the sort-related props for `<Table.HeadCell>`, from a
 * `react-table` column. Depends on the `useSortBy` plugin. */
export function getColumnSortHeadCellProps<D extends object>(
  column: UseSortByColumnProps<D>
): AtlasTableHeadCellProps {
  return {
    sort: getColumnSortValue(column),
    isSortable: getColumnIsSortableValue(column),
    sortButtonProps: column.getSortByToggleProps(),
  };
}
